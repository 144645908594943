import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApolloError, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Mutation,
  MutationUpdatePasswordArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { UPDATE_PASSWORD_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';

export const usePasswordManagementForm = () => {
  const { showCancelConfirm, visibilityHandler } = useProfileSettings();

  const [updatePassword] = useMutation<
    Pick<Mutation, 'updatePassword'>,
    MutationUpdatePasswordArgs
  >(UPDATE_PASSWORD_MUTATION);

  const { t } = useTranslation(['user']);

  const passwordManagementSchema = useMemo(
    () =>
      z
        .object({
          currentPassword: z.string({
            required_error: t(
              'user:profileSettings.sections.passwordManagement.fields.currentPassword.error.required',
            ),
          }),
          newPassword: z
            .string({
              required_error: t(
                'user:profileSettings.sections.passwordManagement.fields.newPassword.error.required',
              ),
            })
            .regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/, {
              message: t(
                'user:profileSettings.sections.passwordManagement.fields.currentPassword.error.invalid',
              ),
            }),
          confirmNewPassword: z.string({
            required_error: t(
              'user:profileSettings.sections.passwordManagement.fields.confirmNewPassword.error.required',
            ),
          }),
        })
        .refine((data) => data.newPassword !== data.currentPassword, {
          message: t(
            'user:profileSettings.sections.passwordManagement.fields.newPassword.error.match',
          ),
          path: ['newPassword'],
        })
        .refine((data) => data.newPassword === data.confirmNewPassword, {
          message: t(
            'user:profileSettings.sections.passwordManagement.fields.confirmNewPassword.error.match',
          ),
          path: ['confirmNewPassword'],
        }),
    [t],
  );

  type PasswordManagementFormValues = z.infer<typeof passwordManagementSchema>;

  const form = useForm<PasswordManagementFormValues>({
    resolver: zodResolver(passwordManagementSchema),
  });

  const {
    formState: { isDirty },
    reset,
    setError,
  } = form;

  const showNotification = useShowNotification();

  const onSubmit: SubmitHandler<PasswordManagementFormValues> = useCallback(
    ({ confirmNewPassword, ...rest }) => {
      void updatePassword({
        variables: {
          input: rest,
        },
        onCompleted: () => {
          visibilityHandler.close();
          showNotification({
            type: 'success',
            title: t('user:profileSettings.sections.passwordManagement.toasts.success'),
          });
        },
        onError: ({ networkError }: ApolloError) => {
          const [, errorCode] =
            networkError?.['result']?.errors?.[0]?.extensions?.code?.split(':') || [];

          if (errorCode === 'INVALID_CREDENTIALS') {
            setError('currentPassword', {
              type: 'custom',
              message: t(
                'user:profileSettings.sections.passwordManagement.fields.currentPassword.error.incorrect',
              ),
            });
          }

          showNotification({
            type: 'error',
            title: t('user:profileSettings.sections.passwordManagement.toasts.error'),
          });
        },
      });
    },
    [t, updatePassword, visibilityHandler, showNotification, setError],
  );

  const onReset = () => showCancelConfirm(isDirty, reset);

  return { form, onReset, onSubmit };
};

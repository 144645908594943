import React, { memo, useState, useEffect } from 'react';
import { useServiceSelector } from 'react-service-locator';
import Badge from 'antd/lib/badge';
import Col from 'antd/lib/col';
import AntdCollapse from 'antd/lib/collapse';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { CompanyInterface } from '@lgg/isomorphic';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { InstitutionItem } from 'src/components/layout/authenticated-layout/sidebar/institution-chooser/institution-item';
import {
  InstitutionAvatar,
  InstitutionNameTextStyle,
} from 'src/components/layout/authenticated-layout/sidebar/institution-chooser/shared';
import { StyledCollapse } from 'src/components/layout/authenticated-layout/sidebar/institution-chooser/styled-collapse';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useSidebar } from 'src/hooks/use-sidebar';
import { AuthorizationService } from 'src/services/authorization.service';

const ArrowIconCol = styled(Col)`
  font-size: 5px;
  margin-right: 2px;

  .lgg-icon {
    color: rgba(255, 255, 255, 0.5);
    transition: transform 300ms, color 300ms;
  }

  .ant-collapse-item-active .ant-collapse-header & .lgg-icon {
    transform: rotate(180deg);
  }

  .ant-collapse-item.ant-collapse-item-active & .lgg-icon {
    color: ${({ theme }) => theme.colors.lightBlue064};
  }
`;

const InstitutionName = styled.span`
  ${InstitutionNameTextStyle};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.medium};
  transition: color 300ms;

  .ant-collapse-item.ant-collapse-item-active & {
    color: ${({ theme }) => theme.colors.carbonBlue};
  }
`;

const CheckedBadge = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accentGreen};
  border-radius: 50%;
  border: solid 1.5px ${({ theme }) => theme.colors.lightGrey2};
  display: flex;
  height: 16px;
  justify-content: center;
  padding: 5.5px 4px 4.8px 4.6px;
  width: 16px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

const StyledScrollbar = styled(Scrollbar)`
  & {
    max-height: 204px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 0 20px;
    position: relative;
  }
`;

export const InstitutionChooser = memo(() => {
  const [isActive, setIsActive] = useState(false);
  const { isSidebarCollapsed, openSidebar } = useSidebar();
  const { hasPermission } = useAuthorization();

  const preCompanies = useServiceSelector(
    AuthorizationService,
    (s) => s.state.concreteInstitutionSiblings,
  );
  const companies = hasPermission('organization:access') ? preCompanies : [];
  const currentInstitution = useCurrentInstitution();
  const collapseOnChange = (collapse: string | string[]) => {
    const isActiveCalc = !Array.isArray(collapse) || collapse.length > 0;

    // Open sidebar if it is closed
    if (isSidebarCollapsed && isActiveCalc) {
      openSidebar();
    }

    setIsActive(isActiveCalc);
  };

  const institutionItems = companies.filter(
    (company: CompanyInterface) => company && company.id !== currentInstitution.id,
  );

  useEffect(() => {
    if (isSidebarCollapsed) {
      setIsActive(false);
    }
  }, [isSidebarCollapsed]);

  return (
    <StyledCollapse
      activeKey={isSidebarCollapsed ? [] : isActive && institutionItems.length ? [1] : []}
      onChange={collapseOnChange}
      expandIcon={() => null}
    >
      <AntdCollapse.Panel
        key="1"
        header={
          <Row
            align="middle"
            wrap={false}
            data-lgg-id={`institution-chooser-${isActive ? 'active' : 'inactive'}`}
          >
            <Col style={{ marginRight: '10px' }}>
              <Badge
                count={
                  isActive ? (
                    <CheckedBadge>
                      <Icon type="checkSave" style={{ color: 'white' }} />
                    </CheckedBadge>
                  ) : null
                }
              >
                <InstitutionAvatar />
              </Badge>
            </Col>
            {!isSidebarCollapsed && (
              <Col flex="auto" style={{ minWidth: 0 }}>
                <Tooltip placement="top" title={currentInstitution.name}>
                  <InstitutionName>{currentInstitution.name}</InstitutionName>
                </Tooltip>
              </Col>
            )}
            {Boolean(institutionItems.length) && (
              <ArrowIconCol>
                {!isSidebarCollapsed && <Icon type="arrowdown" />}
              </ArrowIconCol>
            )}
          </Row>
        }
      >
        {institutionItems.length > 0 && (
          <StyledScrollbar onClick={() => setIsActive(false)}>
            {institutionItems.map((item) => (
              <InstitutionItem key={item.id} institution={item} />
            ))}
          </StyledScrollbar>
        )}
      </AntdCollapse.Panel>
    </StyledCollapse>
  );
});

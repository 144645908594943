import React from 'react';
import AntIcon from '@ant-design/icons';
import { icons } from 'src/components/general/icon/icons';

type IconProps = {
  type: string;
  className?: string;
  style?: {};
  viewBox?: string;
  title?: string;
  onClick?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  lggTestId?: string;
};

export const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  ({ type, title, style, onClick, className, lggTestId, ...rest }, ref) => {
    const IconComponent: any = icons[type] || type;

    return (
      <AntIcon
        ref={ref}
        title={title}
        data-icon={type}
        style={style}
        onClick={onClick}
        className={`lgg-icon ${className || ''}`}
        data-lgg-id={lggTestId}
        {...rest}
        component={() => (
          <IconComponent preserveAspectRatio="xMidYMin meet" fill="currentColor" />
        )}
      />
    );
  },
);

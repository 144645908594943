import { useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { GET_CONTACT, useRefreshContact } from 'src/hooks/gql/use-refresh-contact';
import { useRefreshContactNotes } from 'src/hooks/gql/use-refresh-contact-notes';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';

export const GET_TASK_DUE_TO_REFRESH = gql`
  query GetTaskDueToRefresh($taskId: Int!) {
    task(id: $taskId) {
      id
      title
      status
      description
      dueAt
      createdAt
      assignedTo {
        id
        fullName
      }
    }
  }
`;

export const GET_APPOINTMENT_DUE_TO_REFRESH = gql`
  query GetAppointmentDueToRefresh($institutionId: Int!, $appointmentId: Float!) {
    schedules(institutionId: $institutionId, where: { id: { _eq: $appointmentId } }) {
      edges {
        node {
          id
          title
          status
          description
          startAt
          createdAt
          attendees {
            ... on ScheduleAttendee {
              id
            }
            ... on ScheduleContactAttendee {
              contact {
                label
              }
            }
            ... on ScheduleUserAttendee {
              user {
                fullName
              }
            }
            ... on ScheduleEmailAttendee {
              email
            }
          }
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const GET_CONTACT_NOTE_DUE_TO_REFRESH = gql`
  query GetContactNoteDueToRefresh($companyId: Int!, $contactNoteId: Float!) {
    contactNotes(institutionId: $companyId, where: { id: { _eq: $contactNoteId } }) {
      edges {
        cursor
        node {
          id
          note
          createdAt
        }
      }
    }
  }
`;

export const useLegacyEntityChanges = () => {
  const [getTask] = useLazyQuery(GET_TASK_DUE_TO_REFRESH);
  const [getAppointment] = useLazyQuery(GET_APPOINTMENT_DUE_TO_REFRESH);
  const [getContactNote] = useLazyQuery(GET_CONTACT_NOTE_DUE_TO_REFRESH);
  const [getContact] = useLazyQuery(GET_CONTACT);
  const { id: institutionId } = useCurrentInstitution();

  const onRefreshTaskHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id, action } = params;

        if (action === 'update') {
          await getTask({
            variables: {
              taskId: id,
            },
          });
        }
      },
    };
  }, [getTask]);

  const onRefreshAppointmentHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id, action } = params;

        if (action === 'update') {
          await getAppointment({
            variables: {
              institutionId,
              appointmentId: id,
            },
          });
        }
      },
    };
  }, [getAppointment, institutionId]);

  const onRefreshContactNoteHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id: noteId, action } = params;

        if (action === 'update') {
          await getContactNote({
            variables: {
              companyId: institutionId,
              contactNoteId: noteId,
            },
          });
        }
      },
    };
  }, [getContactNote, institutionId]);

  const onRefreshContactHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id, action } = params;

        if (action === 'update') {
          await getContact({
            variables: {
              id,
            },
          });
        }
      },
    };
  }, [getContact]);

  useRefreshTask(onRefreshTaskHandler);
  useRefreshAppointment(onRefreshAppointmentHandler);
  useRefreshContactNotes(onRefreshContactNoteHandler);
  useRefreshContact(onRefreshContactHandler);
};

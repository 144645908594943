import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { LegacyFilters } from 'src/components/pages/legacy/components/legacy-filters';
import { LegacyViewContent } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/content';
import { useLegacyFormSubmission } from 'src/components/pages/legacy/hooks/use-legacy-form-submission';
import { useRunLegacyScripts } from 'src/components/pages/legacy/hooks/use-run-legacy-scripts';
import { useSetLegacyBreadcrumbs } from 'src/components/pages/legacy/hooks/use-set-legacy-breadcrumbs';
import { useSetLegacyHeaderActions } from 'src/components/pages/legacy/hooks/use-set-legacy-header-actions';
import { LegacyData } from 'src/components/pages/legacy/legacy-data.type';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';

type LegacyViewProps = {
  viewData: string;
  setLegacyRequestPromise: (promise: Promise<LegacyData>) => void;
};

export const LegacyView = memo<LegacyViewProps>(
  ({ viewData, setLegacyRequestPromise }) => {
    const unmountedContainerElementRef = useRef<HTMLDivElement>();
    const [elements, setElements] = useState<{
      breadcrumbs: HTMLDivElement | null;
      headerActions: HTMLDivElement | null;
      content: HTMLDivElement | null;
      filter: HTMLDivElement | null;
      breadcrumbActions: HTMLDivElement | null;
      scripts: HTMLScriptElement[] | null;
    }>({
      breadcrumbs: null,
      headerActions: null,
      content: null,
      filter: null,
      breadcrumbActions: null,
      scripts: null,
    });
    useLayoutEffect(() => {
      if (!unmountedContainerElementRef.current) {
        unmountedContainerElementRef.current = document.createElement('div');
      }
      const { current: containerElement } = unmountedContainerElementRef;
      containerElement.innerHTML = viewData;
      setElements({
        breadcrumbs: containerElement.querySelector<HTMLDivElement>(
          '#ym-legacy-section-breadcrumb',
        ),
        headerActions: containerElement.querySelector<HTMLDivElement>(
          '#ym-legacy-section-header-actions',
        ),
        content: containerElement.querySelector<HTMLDivElement>(
          '#ym-legacy-section-view',
        ),
        filter: containerElement.querySelector<HTMLDivElement>(
          '#ym-legacy-section-filters',
        ),
        breadcrumbActions: containerElement.querySelector<HTMLDivElement>(
          '#ym-legacy-section-breadcrumb-actions',
        ),
        scripts: Array.from(
          containerElement.querySelectorAll<HTMLScriptElement>('script'),
        ),
      });
    }, [viewData]);

    useSetLegacyBreadcrumbs(elements.breadcrumbs);
    useSetLegacyHeaderActions(elements.headerActions);
    useRunLegacyScripts(elements.scripts); // order is important
    useLegacyFormSubmission(setLegacyRequestPromise, viewData);

    return (
      <>
        {elements.filter && <LegacyFilters content={elements.filter} />}
        {elements.content && (
          <>
            <LegacyViewContent
              content={elements.content}
              breadcrumbActions={elements.breadcrumbActions}
            />
            <SafeAreaBottom />
          </>
        )}
      </>
    );
  },
);

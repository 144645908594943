import React, { memo, MouseEventHandler, ReactElement } from 'react';
import {
  DrawerBaseOption,
  BottomDrawerBase,
  BottomDrawer,
} from 'src/components/general/drawer/bottom/bottom-drawer';
import { BottomDrawerItemsRenderer } from 'src/components/general/drawer/bottom/bottom-drawer-item-renderer';

export type DrawerOption = DrawerBaseOption & {
  onClick?: MouseEventHandler;
  to?: string;
  render?: (optionItem: ReactElement) => JSX.Element;
};

export type OptionsBottomDrawerProps = BottomDrawerBase & {
  options: DrawerOption[];
  topItem?: React.ReactNode;
  bottomItem?: React.ReactNode;
};

export const OptionsBottomDrawer = memo<OptionsBottomDrawerProps>(
  ({ options, onClose, topItem, bottomItem, ...rest }) => {
    return (
      <BottomDrawer {...rest} onClose={onClose} mode="options">
        {topItem}
        <BottomDrawerItemsRenderer
          options={options}
          onClose={onClose}
          selectedValue={null}
        />
        {bottomItem}
      </BottomDrawer>
    );
  },
);

type OptionsBottomDrawerWithDetailsProps = BottomDrawerBase & {
  options: DrawerOption[];
  details: JSX.Element;
};

export const OptionsBottomDrawerWithDetails = memo<OptionsBottomDrawerWithDetailsProps>(
  ({ options, onClose, details, ...rest }) => {
    return (
      <BottomDrawer {...rest} onClose={onClose} mode="options">
        {details}
        <BottomDrawerItemsRenderer
          options={options}
          onClose={onClose}
          selectedValue={null}
        />
      </BottomDrawer>
    );
  },
);

type DrawerOptionWithoutIcon = Omit<DrawerOption, 'icon'>;

type OptionsWithoutIconBottomProps = BottomDrawerBase & {
  options: DrawerOptionWithoutIcon[];
};
export const OptionsWithoutIconBottomDrawer = memo<OptionsWithoutIconBottomProps>(
  ({ options, onClose, ...rest }) => {
    return (
      <BottomDrawer {...rest} onClose={onClose} mode="options">
        <BottomDrawerItemsRenderer
          options={options}
          onClose={onClose}
          selectedValue={null}
        />
      </BottomDrawer>
    );
  },
);

import { match, P } from 'ts-pattern';
import { ConversationItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';

export const getContactInteractionCreationDate = (interaction: ConversationItemUnion) => {
  return match(interaction)
    .with(
      { __typename: P.union('ContactNote', 'ConversationNote') },
      (note) => note.createdAt,
    )
    .otherwise((interaction) => interaction.occurredAt);
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { useUrls } from 'src/hooks/use-urls';

export const ViewProfile = styled.span`
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1;
  margin-top: 8px;
  text-align: center;
`;

type ViewFullContactProfileLinkProps = {
  contactId: Contact['id'];
};

export const ViewFullContactProfileLink = memo<ViewFullContactProfileLinkProps>(
  ({ contactId, ...rest }) => {
    const { t } = useTranslation(['contacts']);
    const { getContactModalUrl } = useUrls();

    return (
      <Link {...rest} to={getContactModalUrl(contactId)}>
        <ViewProfile>{t('contacts:information.viewFullProfile')}</ViewProfile>
      </Link>
    );
  },
);

import React, { memo, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  Contact,
  ContactInteractionAttachmentFile,
  ContactInteractionEmail,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionEmailExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  BubbleDivider,
  BubbleMessageHeader,
  InteractionTextContent,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { FileAttachment } from 'src/components/pages/conversations/components/general/file-attachment';

const Subject = styled.p`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 17px;
  margin-bottom: 0;
  text-align: left;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReplyOption = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: keep-all;
`;

const ReplyOptionLabel = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.flint};
  padding: 0;
  margin: 0;
`;

const ReplyOptionIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-left: 5px;

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:empty {
    display: none;
  }
`;

type EmailItemProps = {
  contactInteraction: ContactInteractionEmail;
  contactEmail: Contact['primaryEmail'];
  conversationId: string;
};

export const EmailItem = memo<EmailItemProps>(
  ({ conversationId, contactInteraction, contactEmail }) => {
    const {
      id,
      direction,
      subject,
      bodyText,
      occurredAt,
      registrationType,
      description,
      campaign,
      attachments,
      source,
      department,
      contactAddress,
      resourceAddress,
    } = contactInteraction;
    const { t } = useTranslation(['conversations']);

    const buildManualInteractionTitle = (): ReactElement => {
      const transComponents = { strong: <strong /> };

      return matchContactInteractionEmailExhaustive(contactInteraction, {
        outbound: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.email.outbound"
            components={transComponents}
          />
        ),
        inboundWithParticipatingEntity: ({ participatingEntity }) => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.email.inboundWithParticipatingEntity"
            components={transComponents}
            values={{ participatingEntityLabel: participatingEntity.label }}
          />
        ),
        inboundWithoutParticipatingEntity: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.email.inboundWithoutParticipatingEntity"
            components={transComponents}
          />
        ),
      });
    };

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-email"
        direction={direction}
        registrationType={registrationType}
        details={{
          interactionId: id,
          campaign,
          source,
          department,
          conversationId,
          contactAddress,
          resourceAddress,
          message:
            registrationType === 'AUTOMATIC'
              ? [subject, bodyText].filter(Boolean).join('\n')
              : description,
        }}
        createdAt={occurredAt}
      >
        {match(contactInteraction)
          .with(
            {
              registrationType: 'AUTOMATIC',
              bodyText: P.not(P.nullish),
            },
            ({ subject, bodyText }) => {
              return (
                <>
                  <CardHeader>
                    <Subject>{subject}</Subject>
                    <ReplyOption
                      href={`mailto:${contactEmail}`}
                      data-lgg-id="contact-interaction-email-reply"
                    >
                      <ReplyOptionIcon type="forward" />
                      <ReplyOptionLabel>
                        {t('conversations:contactInteractionBubble.email.reply')}
                      </ReplyOptionLabel>
                    </ReplyOption>
                  </CardHeader>
                  <BubbleDivider direction={direction} />
                  <InteractionTextContent direction={direction}>
                    <TextFormatter>{bodyText}</TextFormatter>
                  </InteractionTextContent>
                </>
              );
            },
          )
          .otherwise(() => {
            return (
              <>
                <BubbleMessageHeader
                  contactInteraction={contactInteraction}
                  data-lgg-id="contact-interaction-email-card-title"
                  title={buildManualInteractionTitle()}
                />
                <BubbleDivider direction={direction} />
                <InteractionTextContent direction={direction}>
                  {description}
                </InteractionTextContent>
              </>
            );
          })}
        <AttachmentsContainer>
          {(
            [...attachments]
              .reverse()
              .filter(
                (attachment) =>
                  attachment.__typename === 'ContactInteractionAttachmentFile',
              ) as ContactInteractionAttachmentFile[]
          ).map((attachment) => (
            <FileAttachment
              key={attachment.id}
              attachment={attachment}
              allowPreview={attachment.type !== 'DOCUMENT'}
            />
          ))}
        </AttachmentsContainer>
      </MessageBubbleWithDirection>
    );
  },
);

import { ApolloError } from '@apollo/client/errors';
import {
  TriggerErrorOptions,
  useErrorHandling,
} from 'src/components/error-boundary/error-boundary';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import { AuthorizationError } from 'src/utils/errors/authorization-error';
import { GenericGraphQlError } from 'src/utils/errors/generic-graphql-error';
import { NetworkError } from 'src/utils/errors/network-error';
import { NotFoundError } from 'src/utils/errors/not-found-error';
import { ServerError } from 'src/utils/errors/server-error';

export const useHandleGraphQLError = () => {
  const { triggerError } = useErrorHandling();

  return (error: ApolloError, options?: TriggerErrorOptions) => {
    const networkError = error.networkError;

    if (networkError) {
      // If the error has no `statusCode` we assume it is a connection error
      if (!('statusCode' in networkError)) {
        return triggerError(new NetworkError(networkError), options);
      }

      // Handle specific `statusCodes`
      switch (networkError['statusCode']) {
        case 401:
          return triggerError(new AuthenticationError(), options);
        case 403:
          return triggerError(new AuthorizationError(), options);
      }

      return triggerError(new ServerError(networkError), options);
    }

    const mappedErrors = error.graphQLErrors
      .map((error) => {
        const code = error.extensions?.code;

        if (code?.endsWith(':NOT_FOUND')) {
          return new NotFoundError();
        } else if (code?.startsWith('AUTHENTICATION:')) {
          return new AuthenticationError();
        } else if (code?.startsWith('AUTHORIZATION:')) {
          return new AuthorizationError();
        }

        return null;
      })
      .filter(Boolean);

    return triggerError(
      mappedErrors.length ? mappedErrors[0] : new GenericGraphQlError(error),
      options,
    );
  };
};

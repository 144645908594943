import { useTranslation } from 'react-i18next';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

export const useFormatSnoozeConversationMessage = () => {
  const { t } = useTranslation(['conversations']);
  const { formatRelativeDate, formatDate } = useFormatDate();
  const { getDatePrefix, startOfDay, addDays, isBefore } = useDateHelpers();

  return (date: Date) => {
    const prefix = getDatePrefix(date);
    const dayAfterTomorrow = startOfDay(addDays(new Date(), 2));
    const formattedDate = isBefore(date, dayAfterTomorrow)
      ? formatRelativeDate(date)
      : formatDate(date, 'MMM DD');

    return `${t('conversations:conversationStatus.snoozed')} ${
      prefix ? `${prefix} ` : ''
    }${formattedDate}!`;
  };
};

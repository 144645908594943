import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { NotificationFeedItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { NotificationsList } from 'src/components/domain/notifications/notifications-list';

const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
      }

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-ink-bar {
          background-color: ${({ theme }) => theme.colors.gogo};
        }

        .ant-tabs-tab {
          flex: 1;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 13px 0;

          ${up('md')} {
            padding-top: 10px;
            padding-bottom: 11px;
          }

          > .ant-tabs-tab-btn {
            font-family: ${({ theme }) => theme.font.regular};
            font-size: 14px;
            line-height: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: ${({ theme }) => theme.colors.flint};
            transition: none;

            ${up('md')} {
              font-size: 13px;
              line-height: 15px;
            }
          }

          &.ant-tabs-tab-active > .ant-tabs-tab-btn {
            color: ${({ theme }) => theme.colors.gogo};
            transition: none;
          }
        }
      }

      .ant-tabs-nav-operations {
        display: none;
      }
    }
  }
`;

export enum NotificationTabKeys {
  New = 'NEW',
  Cleared = 'CLEARED',
}

type NotificationsTabsProps = {
  newNotifications?: NotificationFeedItemUnion[];
  clearedNotifications?: NotificationFeedItemUnion[];
  handleNotificationUpdated: (notification: NotificationFeedItemUnion) => Promise<void>;
  newNotificationsOnLoadMore: VoidFunction;
  clearedNotificationsOnLoadMore: VoidFunction;
  newNotificationIsLoadingMore: boolean;
  clearedNotificationIsLoadingMore: boolean;
  newNotificationsFirstItemIndex: number;
  clearedNotificationsFirstItemIndex: number;
  hasNewNotification?: boolean;
  areNotificationsVisible?: boolean;
  activeTabKey: NotificationTabKeys;
  onTabClick: (key: string) => void;
};

export const NotificationsTabs = memo<NotificationsTabsProps>(
  ({
    clearedNotifications = [],
    newNotifications = [],
    handleNotificationUpdated,
    newNotificationsOnLoadMore,
    clearedNotificationsOnLoadMore,
    newNotificationIsLoadingMore,
    clearedNotificationIsLoadingMore,
    newNotificationsFirstItemIndex,
    clearedNotificationsFirstItemIndex,
    hasNewNotification,
    areNotificationsVisible,
    activeTabKey,
    onTabClick,
  }) => {
    const { t } = useTranslation(['notifications']);

    return (
      <StyledTabs
        activeKey={activeTabKey}
        data-lgg-id="notification-feed-items-tabs"
        onTabClick={onTabClick}
      >
        <Tabs.TabPane tab={t('notifications:tabs.new')} key={NotificationTabKeys.New}>
          <NotificationsList
            areNotificationsVisible={areNotificationsVisible}
            hasNewNotification={hasNewNotification}
            loadingMore={newNotificationIsLoadingMore}
            onLoadMore={newNotificationsOnLoadMore}
            firstItemIndex={newNotificationsFirstItemIndex}
            notifications={newNotifications}
            handleNotificationUpdated={handleNotificationUpdated}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('notifications:tabs.cleared')}
          key={NotificationTabKeys.Cleared}
        >
          <NotificationsList
            loadingMore={clearedNotificationIsLoadingMore}
            firstItemIndex={clearedNotificationsFirstItemIndex}
            onLoadMore={clearedNotificationsOnLoadMore}
            notifications={clearedNotifications}
            handleNotificationUpdated={handleNotificationUpdated}
          />
        </Tabs.TabPane>
      </StyledTabs>
    );
  },
);

import { useCallback, useMemo, useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionPhoneCallDetail,
  ContactInteractionTranscriptionPhrase,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ReactComponent as NeutralSentimentsIllustration } from 'src/assets/images/neutral_sentiments.svg';
import { ReactComponent as NoTranscriptIllustration } from 'src/assets/images/no_transcript.svg';
import { LggAudioPlayer } from 'src/components/general/display/lgg-audio-player';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { FlexColumn } from 'src/components/layout/flex-column';
import { CallTranscriptionPhrase } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/components/call-transcription-phrase';

const { TabPane } = Tabs;

const CallDetailsTabs = styled(Tabs)`
  .ant-tabs-nav {
    height: 38px;
    margin: 0;

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        .ant-tabs-tab-btn {
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
          color: ${({ theme }) => theme.colors.flint};
          font-family: ${({ theme }) => theme.font.regular};
          font-size: 13px;
          line-height: 15px;
          text-align: left;
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${({ theme }) => theme.colors.gogo};
            font-family: ${({ theme }) => theme.font.medium};

            ${up('md')} {
              letter-spacing: -0.13px;
            }
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: ${({ theme }) => theme.colors.gogo};
      height: 2px;
    }
  }

  .ant-tabs-content {
    height: 100%;
  }
`;

const SectionTitle = styled.p`
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  letter-spacing: -0.26px;
  line-height: 15px;
  margin: 0 10px 10px;
  text-align: left;
`;

const SectionContainer = styled(FlexColumn)`
  padding: 15px 10px 20px;
`;

const CallSummary = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  letter-spacing: normal;
  line-height: 1.54;
  margin: 0 10px;
  text-align: left;
`;

const SectionDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.koala};
  height: 1px;
  margin: 0;
`;

const NeutralSentimentsNoticeContainer = styled(FlexColumn)`
  align-items: center;
`;

const NeutralSentimentsNoticeTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 22px;
  margin: 20px 0 10px;
  text-align: center;
`;

const NeutralSentimentsNoticeDescription = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  margin: 0;
  max-width: 335px;
  text-align: center;
`;

const StyledLggAudioPlayer = styled(LggAudioPlayer)`
  .rhap_container {
    background-color: ${({ theme }) => theme.colors.alabaster};
    border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.koala};

    .player-play-button,
    .player-pause-button {
      background-color: ${({ theme }) => theme.colors.casper};
    }

    ${up('md')} {
      height: 64px;
      padding: 14px 15px 15px;
    }
  }
`;

const TabContent = styled(Scrollbar)`
  height: 100%;
  margin: 0;
  overflow-y: scroll;

  ${up('md')} {
    padding: 0;
  }
`;

const illustrationsStyles = css`
  height: 131px;
  width: 135px;

  ${up('md')} {
    width: 174px;
    height: 179px;
  }
`;

const StyledNeutralSentimentsIllustration = styled(NeutralSentimentsIllustration)`
  ${illustrationsStyles};
`;

const StyledNoTranscriptIllustration = styled(NoTranscriptIllustration)`
  margin-top: 15px;
  ${illustrationsStyles};
`;

const CallDetailsContainer = styled(FlexColumn)`
  height: 100%;

  ${up('md')} {
    height: calc(100% - 80px);
  }
`;

const SectionPlaceholderContent = (props: {
  title: string;
  description: string;
  illustration: React.ReactNode;
}) => {
  const { title, description, illustration, ...rest } = props;

  return (
    <NeutralSentimentsNoticeContainer {...rest}>
      {illustration}
      <NeutralSentimentsNoticeTitle>{title}</NeutralSentimentsNoticeTitle>
      <NeutralSentimentsNoticeDescription>
        {description}
      </NeutralSentimentsNoticeDescription>
    </NeutralSentimentsNoticeContainer>
  );
};

const NoTranscriptContainer = styled(SectionPlaceholderContent)`
  margin: 0 36px;
`;

const NeutralSentimentsNotice = () => {
  const { t } = useTranslation(['conversations']);

  return (
    <SectionPlaceholderContent
      data-lgg-id="neutral-sentiment-notice"
      illustration={<StyledNeutralSentimentsIllustration />}
      title={t(
        'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.overview.callSentiments.neutralSentimentsNotice.title',
      )}
      description={t(
        'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.overview.callSentiments.neutralSentimentsNotice.description',
      )}
    />
  );
};

export type CallAnalysisDetailsTabKey = 'overview' | 'transcript';

type CallAnalysisDetailsProps = {
  details: ContactInteractionPhoneCallDetail;
  recordingSrc: string;
  defaultSelectedTab?: CallAnalysisDetailsTabKey;
};

export const CallAnalysisDetails = ({
  details,
  recordingSrc,
  defaultSelectedTab = 'overview',
}: CallAnalysisDetailsProps) => {
  const { t } = useTranslation(['conversations']);
  const [selectedTab, setSelectedTab] =
    useState<CallAnalysisDetailsTabKey>(defaultSelectedTab);
  const playerRef = useRef<H5AudioPlayer>(null);

  const { summary, sentimentAnalysis, transcription } = details;

  const transcriptionContact = useMemo(
    () =>
      transcription?.__typename === 'ContactInteractionTranscription'
        ? transcription.contact
        : null,
    [transcription],
  );

  const transcriptionUser = useMemo(
    () =>
      transcription?.__typename === 'ContactInteractionTranscription'
        ? transcription.user
        : null,
    [transcription],
  );

  const setPlayerTime = async (time: number) => {
    const playerAudioComponent = playerRef.current?.audio.current;

    if (playerAudioComponent) {
      playerAudioComponent.currentTime = time;

      await playerAudioComponent.play();
    }
  };

  const renderPhrasesList = useCallback(
    (phrases: ContactInteractionTranscriptionPhrase[]) => {
      return phrases.map((phrase) => (
        <CallTranscriptionPhrase
          key={phrase.id}
          details={{
            ...phrase,
            contact: transcriptionContact,
            user: transcriptionUser,
          }}
          onClick={async () => {
            await setPlayerTime(phrase.start);
          }}
        />
      ));
    },
    [transcriptionContact, transcriptionUser],
  );

  const summaryValue = useMemo(() => {
    return match(summary)
      .with({ __typename: 'ContactInteractionSummary' }, (summary) => summary.text)
      .otherwise(() => null);
  }, [summary]);

  const transcriptionContent = useMemo(() => {
    return match(transcription)
      .with({ __typename: 'ContactInteractionTranscription' }, ({ phrases }) => {
        if (!phrases.edges.length) {
          return (
            <NoTranscriptContainer
              data-lgg-id="no-transcript-placeholder"
              title={t(
                'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.transcript.noTranscriptPlaceholder.title',
              )}
              description={t(
                'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.transcript.noTranscriptPlaceholder.description',
              )}
              illustration={<StyledNoTranscriptIllustration />}
            />
          );
        }

        return renderPhrasesList(phrases.edges.map(({ node }) => node));
      })
      .otherwise(() => null);
  }, [renderPhrasesList, t, transcription]);

  const sentimentAnalysisContent = useMemo(() => {
    return match(sentimentAnalysis)
      .with(
        { __typename: 'ContactInteractionSentimentAnalysis' },
        (sentimentAnalysis) => {
          const hasSentiments = sentimentAnalysis.phrases.length > 0;

          return hasSentiments ? (
            renderPhrasesList(sentimentAnalysis.phrases)
          ) : (
            <NeutralSentimentsNotice />
          );
        },
      )
      .otherwise(() => null);
  }, [renderPhrasesList, sentimentAnalysis]);

  const showOverviewTab =
    sentimentAnalysis?.__typename !== 'SentimentAnalysisDisabled' ||
    summary?.__typename !== 'ContactInteractionSummaryDisabled';

  return (
    <CallDetailsContainer>
      <StyledLggAudioPlayer
        url={recordingSrc}
        playerRef={playerRef}
        testId="call-analysis-details-recording"
      />
      <CallDetailsTabs
        activeKey={selectedTab}
        onChange={(key) => {
          setSelectedTab(key as CallAnalysisDetailsTabKey);
        }}
      >
        {showOverviewTab ? (
          <TabPane
            key="overview"
            tab={
              <span data-lgg-id="overview-tab">
                {t(
                  'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.overview.title',
                )}
              </span>
            }
          >
            <TabContent>
              {summaryValue ? (
                <SectionContainer data-lgg-id="call-summary-section">
                  <SectionTitle>
                    {t(
                      'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.overview.summary.title',
                    )}
                  </SectionTitle>
                  <CallSummary data-lgg-id="call-summary-value">
                    {summaryValue}
                  </CallSummary>
                </SectionContainer>
              ) : null}
              <SectionDivider />
              {sentimentAnalysisContent ? (
                <SectionContainer data-lgg-id="sentiment-analysis-section">
                  <SectionTitle>
                    {t(
                      'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.overview.callSentiments.title',
                    )}
                  </SectionTitle>
                  {sentimentAnalysisContent}
                </SectionContainer>
              ) : null}
            </TabContent>
          </TabPane>
        ) : null}
        {transcriptionContent ? (
          <TabPane
            key="transcript"
            tab={
              <span data-lgg-id="transcript-tab">
                {t(
                  'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.tabs.transcript.title',
                )}
              </span>
            }
          >
            <TabContent>
              <SectionContainer data-lgg-id="transcript-section">
                {transcriptionContent}
              </SectionContainer>
            </TabContent>
          </TabPane>
        ) : null}
      </CallDetailsTabs>
    </CallDetailsContainer>
  );
};

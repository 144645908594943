import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const LexicalContainer = styled.div`
  background: transparent;
  border-radius: 10px 10px 2px 2px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  height: max-content;
  line-height: 20px;
  padding: 0;
  position: relative;
  text-align: left;

  &.full-height {
    justify-content: space-between;
    flex: 1;
  }

  .editor-input {
    margin: 0 20px;
    outline: none;
  }

  .editor-placeholder {
    color: ${({ theme }) => theme.colors.flint};
    display: inline-block;
    font-size: 14px;
    left: 20px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    user-select: none;
  }

  .editor-paragraph {
    color: ${({ theme }) => theme.colors.smalt};
    font-size: 14px;
    margin: 0;
    position: relative;

    ${up('md')} {
      max-height: 120px;
    }
  }
`;

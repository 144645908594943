import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericHttpErrorPage } from 'src/components/pages/errors/generic-http-error-page';

export const ForbiddenErrorPage = memo(() => {
  const { t } = useTranslation(['errors']);

  return (
    <GenericHttpErrorPage
      data-lgg-id="error-page-403"
      errorCode={403}
      title={t('errors:httpErrors.403.title')}
      message={t('errors:httpErrors.403.message')}
    />
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { BaseButton } from 'src/components/general/button/lgg-button';
import {
  ConversationBanner,
  ConversationBannerIcon,
} from 'src/components/pages/conversations/components/contact-interactions/banners/shared';
import { useFormatSnoozeConversationMessage } from 'src/components/pages/conversations/hooks/use-format-snooze-conversation-message';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

const SnoozedConversationBannerContainer = styled(ConversationBanner)`
  padding: 6px 20px;
  max-height: 26px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryGold10};
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  z-index: 5;

  .left-rail {
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
  }
`;

const SnoozedConversationBannerDiscardSnoozeButton = styled(BaseButton)`
  background: transparent;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 13px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.flint};
  cursor: pointer;
  width: max-content;
  height: max-content;
  padding: 0;
`;

type SnoozedConversationBannerProps = {
  snoozedUntil: Date;
  loading: boolean;
  discardSnoozeHandler: VoidFunction;
};

export const SnoozedConversationBanner = ({
  snoozedUntil,
  loading,
  discardSnoozeHandler,
}: SnoozedConversationBannerProps) => {
  const { ensureDate } = useDateHelpers();
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['conversations']);
  const formatSnoozeMessage = useFormatSnoozeConversationMessage();

  return (
    <SnoozedConversationBannerContainer data-lgg-id="snoozed-conversation-banner">
      <div className="left-rail">
        <ConversationBannerIcon type="snooze" color="gold" />
        {formatSnoozeMessage(ensureDate(snoozedUntil))}
      </div>
      <SnoozedConversationBannerDiscardSnoozeButton
        loading={loading}
        onClick={discardSnoozeHandler}
        data-lgg-id="snoozed-conversation-banner-discard-snooze-button"
      >
        {breakpointUpMd
          ? t('conversations:snoozedConversationBanner.discard')
          : t('conversations:snoozedConversationBanner.discardMobile')}
      </SnoozedConversationBannerDiscardSnoozeButton>
    </SnoozedConversationBannerContainer>
  );
};

import React, { memo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LoadingIndicator } from 'src/components/loading-fallback';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { DesktopOnlyPageError } from 'src/utils/errors/desktop-only-page-error';

export const InstagramAuthPage = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { triggerError } = useErrorHandling();

  /*
  
    Los oAuth redirect URLs de Instagram son estáticos (https://app.lgglocal.com/instagram/auth/). 
    No podemos pasarle wildcards como https://app.lgglocal.com/organization/#/i/company/xxx.

    Lo que hace esta ruta, es decirle a Instagram que nos re-dirija a ella, 
    mostramos un loading state por un corto tiempo, mientras se cierra el modal 
    y se dispara la acción de activar la cuenta.

    La función de la ruta, es solo tomar el código que nos brinda instagram en el URL,
     y devolverlo al handler que está haciendo la llamada desde el window padre.

    */

  useEffect(() => {
    if (!breakpointUpMd) {
      triggerError(new DesktopOnlyPageError());
    }
  }, [breakpointUpMd, triggerError]);

  if (!breakpointUpMd) {
    return <Redirect to="/" />;
  }

  return <LoadingIndicator />;
});

import { gql, useQuery } from '@apollo/client';
import {
  ContactSource,
  Query,
  QuerySourcesArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const GET_SOURCES = gql`
  query GetSources($institutionId: Int!, $where: ContactSourceWhereInput) {
    sources(institutionId: $institutionId, where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type UseContactSourcesReturn = {
  loading: boolean;
  sources: ContactSource[];
};

export const useContactSources = (): UseContactSourcesReturn => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { loading, data } = useQuery<Pick<Query, 'sources'>, Partial<QuerySourcesArgs>>(
    GET_SOURCES,
    {
      variables: {
        institutionId: currentInstitution.id,
        where: {
          isActive: { _eq: true },
        },
      },
      onError: handleGraphQLError,
    },
  );

  return {
    sources: getNodesFromConnection(data?.sources),
    loading,
  };
};

import React, { memo } from 'react';
import styled from 'styled-components';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggUserPopover } from 'src/components/domain/users/components/lgg-user-popover';
import { Avatar } from 'src/components/general/display/avatar';
import { LggUserAvatar } from 'src/components/general/display/user-avatar';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const AddAssigneeOptionContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: max-content;
`;

const ContactAssigneeContainer = styled.div`
  width: 100%;
`;

const RoundedDashedContainer = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.casper};
  width: max-content;
  border-radius: 50%;
  position: relative;
  padding: 2px;
`;

const AddAssigneeAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.colors.koala};

  .lgg-icon {
    svg {
      height: 14px;
      width: 14px;

      path {
        fill: ${({ theme }) => theme.colors.flint};
      }
    }
  }
`;

const AddAssigneeExtraIconContainer = styled(RoundedDashedContainer)`
  position: absolute;
  top: -1px;
  right: -3px;
  background: white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UpperRoundedIcon = styled(Icon)`
  border-radius: 50%;

  svg {
    height: 6px;
    width: 6px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const AssigneeInfoContainer = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
`;

const AssigneeName = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin-left: 10px;
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

type AssigneeLabelProps = {
  assignee?: User;
  onClick?: VoidFunction;
};

export const AssigneeLabel = memo(
  ({ assignee, onClick, ...rest }: AssigneeLabelProps) => {
    return (
      <ContactAssigneeContainer {...rest}>
        {assignee ? (
          <LggUserPopover user={assignee}>
            <AssigneeInfoContainer onClick={onClick}>
              <LggUserAvatar
                data-lgg-id={`assignee-label-avatar-${assignee.avatar.initials}`}
                user={assignee}
              />
              <AssigneeName data-lgg-id="assignee-label-name">
                {assignee.fullName}
              </AssigneeName>
            </AssigneeInfoContainer>
          </LggUserPopover>
        ) : (
          <AddAssigneeOptionContainer
            data-lgg-id="contact-add-assignee-container"
            onClick={onClick}
          >
            <RoundedDashedContainer>
              <AddAssigneeAvatar icon={<Icon type="contact" />} />
              <AddAssigneeExtraIconContainer>
                <UpperRoundedIcon type="plus" />
              </AddAssigneeExtraIconContainer>
            </RoundedDashedContainer>
          </AddAssigneeOptionContainer>
        )}
      </ContactAssigneeContainer>
    );
  },
);

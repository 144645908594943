import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { inputFocusBorder, labelTextStyle } from 'src/theme/sub-themes/inputs-theme';

const StyledInputLabel = styled.span`
  ${labelTextStyle}
`;

const InputHolder = styled.div`
  -webkit-transition: border 150ms, box-shadow 150ms;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: solid 1px #98a9bc40;
  color: ${({ theme }) => theme.colors.smalt};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  height: 38px;
  letter-spacing: normal;
  line-height: 15px;
  outline: none;
  padding: 11px;
  position: relative;
  transition: border 150ms, box-shadow 150ms;
  width: 100%;

  ${up('md')} {
    padding: 8px 10px 8px 10px;
    height: 34px;
    max-width: 390px;
  }

  &.active {
    ${inputFocusBorder};
  }

  strong {
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: 500;
  }
`;

type Props = {
  label: string;
  onClick: VoidFunction;
  active: boolean;
  value: JSX.Element;
  'data-lgg-id'?: string;
};

export const GenericInput = ({ label, onClick, active, value, ...rest }: Props) => {
  return (
    <div>
      <StyledInputLabel data-lgg-id="generic-input-label">{label}</StyledInputLabel>
      <InputHolder
        onClick={onClick}
        className={c({ active: active })}
        data-lgg-id={rest['data-lgg-id']}
      >
        {value}
      </InputHolder>
    </div>
  );
};

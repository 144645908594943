import React, { memo } from 'react';
import AntDrawer, { DrawerProps } from 'antd/lib/drawer';
import styled from 'styled-components';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexRow } from 'src/components/layout/flex-row';
import { disabledUserSelectGlobalStyle } from 'src/theme/globals/user-select-global-style';

const StyledDrawerHeader = styled(FlexRow)`
  display: flex;
  height: 81px;
  align-items: center;
  padding: 0 20px;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
`;

const DrawerTitle = styled.span`
  font-size: 24px;
  line-height: 29px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.carbon};
  letter-spacing: -0.48px;
`;

const StyledCloseIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

type DrawerHeaderProps = {
  onClose?: VoidFunction;
  extraContent?: React.ReactNode;
  title: string;
};

const DrawerHeader = memo<DrawerHeaderProps>(({ onClose, title, extraContent }) => {
  return (
    <StyledDrawerHeader>
      <DrawerTitle>{title}</DrawerTitle>
      <Expand />
      {extraContent}
      <StyledCloseIcon onClick={onClose} type="close" lggTestId="drawer-close-icon" />
    </StyledDrawerHeader>
  );
});

const StyledDrawer = styled(AntDrawer)`
  ${disabledUserSelectGlobalStyle}
  .ant-drawer-header,
  .ant-drawer-body,
  .ant-drawer-footer {
    padding: 0;
    border: none;
  }

  .ant-drawer-mask {
    background: none;
  }
`;

type RightDrawerProps = Omit<DrawerProps, 'title' | 'onClose'> & {
  children?: React.ReactNode;
  onClose: VoidFunction;
  title: string;
  headerExtraContent?: React.ReactNode;
  addScrollbarToBody?: boolean;
};

export const RightDrawer = memo<RightDrawerProps>(
  ({
    title,
    addScrollbarToBody = true,
    children,
    onClose,
    headerExtraContent,
    ...props
  }) => {
    return (
      <StyledDrawer
        {...props}
        width="375"
        onClose={onClose}
        closable
        title={
          <DrawerHeader
            title={title}
            onClose={onClose}
            extraContent={headerExtraContent}
          />
        }
        closeIcon={null}
        placement="right"
      >
        {addScrollbarToBody ? <Scrollbar>{children}</Scrollbar> : children}
      </StyledDrawer>
    );
  },
);

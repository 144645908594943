import React, { memo, useEffect } from 'react';
import { up } from 'styled-breakpoints';
import { Footer } from 'src/components/filters/footer';
import { Header } from 'src/components/filters/header';
import { useBottomDrawerHeight } from 'src/components/general/drawer/bottom/bottom-drawer';
import { LegacyDrawer } from 'src/components/general/drawer/legacy-drawer';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

export const Filters = memo<{
  onSave: () => void;
  onReset: () => void;
  filters: string[];
  children: React.ReactNode;
}>(({ filters, onSave, onReset, children }) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { visible, close, show } = useVisible();
  const height = useBottomDrawerHeight({ fullHeight: true });

  useEffect(() => {
    window.addEventListener('openLegacyFilters', show);

    return () => window.removeEventListener('openLegacyFilters', show);
  }, [show]);

  return (
    <LegacyDrawer
      className="filters-drawer"
      placement={breakpointUpMd ? 'right' : 'bottom'}
      title={<Header filters={filters} onReset={onReset} />}
      footer={
        <>
          <Footer onSave={onSave} onReset={onReset} />
          <SafeAreaBottom />
        </>
      }
      closable={true}
      width={330}
      visible={visible}
      height={height}
      onClose={close}
    >
      {children}
    </LegacyDrawer>
  );
});

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import NewCustomerIllustration from 'src/assets/images/new_customer_illustration.svg?url';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';

const Title = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 22px;
  margin: 0 0 5px;
  letter-spacing: -0.44px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.smalt};
`;

const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.flint};
`;

const NewCustomerImage = styled.img`
  max-width: 160px;
  margin: 6px 0 20px;
`;

const StyledModal = styled(Modal)`
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    width: 100%;
    max-width: 335px;
    height: 260px;
    border-radius: 6px;
    height: 260px;
    margin: 0 auto;

    ${up('md')} {
      max-width: 370px;
      height: 265px;
    }

    .ant-modal-close {
      .ant-modal-close-x {
        position: relative;
        right: 2px;
        top: 2px;
      }
    }
  }
`;

const StyledFlexColumn = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
`;

const ModalCloseIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

type ContactItemProps = {
  visible: boolean;
  onClose: VoidFunction;
  afterClose: VoidFunction;
};

export const NewCustomerNotificationModal = memo<ContactItemProps>(
  ({ visible, onClose, afterClose }) => {
    const { t } = useTranslation('contacts');

    return (
      <StyledModal
        visible={visible}
        onCancel={onClose}
        afterClose={afterClose}
        destroyOnClose
        footer={null}
        centered
        closeIcon={<ModalCloseIcon type="close" />}
      >
        <StyledFlexColumn data-lgg-id="new-customer-notification-modal">
          <NewCustomerImage src={NewCustomerIllustration} />
          <Title>{`${t('newCustomerNotification.title')}!`}</Title>
          <Subtitle>{`${t('newCustomerNotification.subtitle')}!`}</Subtitle>
        </StyledFlexColumn>
      </StyledModal>
    );
  },
);

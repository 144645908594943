import React, { memo } from 'react';
import styled from 'styled-components';

export type BaseChipProps = { size: 'small' | 'medium' | 'large' };
type StyledImageChip = BaseChipProps & { url: string };
type StyledTextChip = BaseChipProps & { color: string };

const StyledChip = styled.div<StyledImageChip | StyledTextChip>`
  align-items: center;
  border-radius: 50%;
  border: solid 1px #cbd4dd99;
  color: ${({ theme }) => theme.colors.geyser};
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: ${({ size }) =>
    size === 'small' ? '6px' : size === 'medium' ? '8px' : '12px'};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: ${({ size }) =>
    size === 'small' ? '16px' : size === 'medium' ? '20px' : '30px'};
  justify-content: center;
  letter-spacing: -0.27px;
  line-height: 11;
  margin-right: ${({ size }) =>
    size === 'small' ? '5px' : size === 'medium' ? '9px' : '11px'};
  text-align: center;
  width: ${({ size }) =>
    size === 'small' ? '16px' : size === 'medium' ? '20px' : '30px'};
  ${(props) => {
    if ('color' in props) {
      return `background-color: ${props.color};`;
    } else if ('url' in props) {
      return `
        background-image: url("${props.url}");
        background-size: cover;
        background-repeat: no-repeat;
      `;
    }
  }}
`;

export type TextChip = {
  text: string;
  color: string;
};

export type ImageChip = {
  url: string;
};

type ChipProps = BaseChipProps & (TextChip | ImageChip);

export const Chip = memo<ChipProps>((props) => {
  if ('url' in props) {
    return <StyledChip size={props.size} url={props.url} />;
  } else {
    return (
      <StyledChip size={props.size} color={props.color}>
        {props.text}
      </StyledChip>
    );
  }
});

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Divider } from 'antd';
import styled from 'styled-components';
import {
  Contact,
  Mutation,
  MutationConversationSetDepartmentArgs,
  Query,
  QueryContactArgs,
  QueryConversationArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactRowAssignee } from 'src/components/domain/contacts/contact-details/contact-row-assignee';
import {
  EditIcon,
  EmptyValue,
  FlexColumn,
  IconCircle,
  Label,
  Row,
  StyledIcon,
  Value,
} from 'src/components/domain/contacts/contact-details/shared';
import { ViewFullContactProfileLink } from 'src/components/domain/contacts/contact-details/view-full-profile-link';
import { ContactStatusElement } from 'src/components/domain/contacts/contact-status-element';
import { useActiveDepartmentSelectOptions } from 'src/components/domain/departments/hooks/use-active-department-select-options';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { Select } from 'src/components/general/inputs/select/select';
import { Center } from 'src/components/layout/center';
import { Expand } from 'src/components/layout/expand';
import { ContactCopyMenu } from 'src/components/pages/contacts/components/contact-copy-menu';
import { LegacyContactTagsSelector } from 'src/components/pages/conversations/components/contact-information/legacy-contact-tags-selector';
import { useFormatLastInteractionDate } from 'src/components/pages/conversations/hooks/use-format-last-interaction-date';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { GET_CONTACT } from 'src/hooks/gql/use-refresh-contact';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';

export const CONVERSATION = gql`
  query GetConversation($id: String!) {
    conversation(id: $id) {
      id
      department {
        id
        name
      }
    }
  }
`;

const CONVERSATION_SET_DEPARTMENT = gql`
  mutation ConversationSetDepartment($conversationId: String!, $departmentId: Int) {
    conversationSetDepartment(
      conversationId: $conversationId
      departmentId: $departmentId
    ) {
      conversation {
        id
        department {
          id
          name
        }
      }
    }
  }
`;

const Name = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.36px;
  line-height: 23px;
  margin-bottom: 2px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const LastInteraction = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-bottom: 8px;
  text-align: center;
`;

const Header = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
`;

const StyledRow = styled(Row)`
  width: 100%;
  padding: 0;
`;

export const StyledEditIcon = styled(EditIcon)`
  svg {
    height: 16px;
    width: 16px;
  }
`;

const ContactDetailsHeaderSkeleton = styled(Skeleton)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 22px 30px;

  .ant-skeleton-title {
    height: 20px;
    margin: 0 0 22px;
  }

  .ant-skeleton-paragraph {
    margin: 0;
  }
`;

const ContactDetailsItemSkeleton = styled(Skeleton)`
  padding: 30px;

  .ant-skeleton-paragraph li {
    margin: 0 0 44px;
  }
`;

const ContactDetailsSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactDetailsSkeleton = memo(() => {
  return (
    <ContactDetailsSkeletonWrapper>
      <ContactDetailsHeaderSkeleton
        paragraph={{ rows: 1 }}
        title={{ width: '100%' }}
        active
      />
      <ContactDetailsItemSkeleton
        paragraph={{ rows: 6, width: '100%' }}
        title={false}
        active
      />
    </ContactDetailsSkeletonWrapper>
  );
});

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.28px;
  line-height: 17px;
  padding: 15px 20px;
  text-align: left;
`;

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.colors.porcelain};
  margin-bottom: 0;
  margin-top: 15px;
`;

const StyledContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ScrollableSection = styled(Scrollbar)`
  padding-bottom: 15px;
`;

const ContactDetailInfoContainer = styled(FlexColumn)`
  overflow: hidden;
`;

type ContactDetailsProps = {
  contact: Contact;
};

export const ContactDetails = memo<ContactDetailsProps>(({ contact }) => {
  const {
    id: contactId,
    interest,
    assignee,
    primaryPhone,
    primaryEmail,
    tags,
    stage,
    status,
    fullAddress,
  } = contact;
  const { t } = useTranslation(['contacts', 'common']);
  const { getLegacyContactInterestModalUrl } = useUrls();
  const { getFeatureFlag } = useAuthorization();
  const showInterestModal = () => {
    openRemoteModalWrapper(getLegacyContactInterestModalUrl(contactId));
  };
  const hasCrmFF = getFeatureFlag('crm');

  const informationRow = (props: {
    icon: string;
    label: string;
    value: React.ReactNode;
  }) => {
    const { icon, label, value } = props;

    return (
      <Row>
        <IconCircle>
          <StyledIcon type={icon} />
        </IconCircle>
        <ContactDetailInfoContainer>
          <Label>{label}</Label>
          {value}
        </ContactDetailInfoContainer>
      </Row>
    );
  };

  return (
    <>
      {hasCrmFF && (
        <>
          {informationRow({
            icon: 'status',
            label: t('contacts:information.status'),
            value: (
              <ContactStatusElement stage={stage} status={status} contactId={contactId} />
            ),
          })}
          <Row>
            <IconCircle>
              <StyledIcon type="interest" />
            </IconCircle>
            <ContactDetailInfoContainer>
              <Label>{t('contacts:information.interest')}</Label>
              {interest ? (
                <Value data-value={interest} data-lgg-id="contact-details-interest">
                  {interest}
                </Value>
              ) : (
                <EmptyValue onClick={showInterestModal}>{t('common:add')}</EmptyValue>
              )}
            </ContactDetailInfoContainer>
            <Expand />
            <Center>
              <EditIcon
                type="edit"
                onClick={showInterestModal}
                lggTestId="interest-legacy-modal-trigger"
              />
            </Center>
          </Row>
        </>
      )}
      <ContactRowAssignee assignee={assignee} contactId={contactId} />
      {primaryPhone &&
        informationRow({
          icon: 'phone',
          label: t('contacts:information.phoneNumber'),
          value: (
            <ContactCopyMenu
              data-lgg-id="contact-details-phone"
              contactId={contactId}
              config={{ phone: primaryPhone }}
            />
          ),
        })}
      {primaryEmail &&
        informationRow({
          icon: 'email',
          label: t('contacts:information.email'),
          value: (
            <ContactCopyMenu
              data-lgg-id="contact-details-email"
              contactId={contactId}
              config={{ email: primaryEmail }}
            />
          ),
        })}
      {fullAddress &&
        informationRow({
          icon: 'location',
          label: t('contacts:information.address'),
          value: (
            <Value data-value={fullAddress} data-lgg-id="contact-details-full-address">
              {fullAddress}
            </Value>
          ),
        })}
      {informationRow({
        icon: 'tags',
        label: 'Tags',
        value: <LegacyContactTagsSelector selectedTags={tags} contactId={contactId} />,
      })}
    </>
  );
});

type ConversationContactDetailsProps = {
  contactId: number;
  conversationId: string;
  showHeader?: boolean;
};

export const ConversationContactDetails = memo<ConversationContactDetailsProps>(
  ({ contactId, conversationId, showHeader = true, ...rest }) => {
    const { t } = useTranslation(['conversations', 'contacts', 'common']);
    const { formatLastInteractionDate } = useFormatLastInteractionDate();
    const handleGraphQLError = useHandleGraphQLError();
    const contactQuery = useQuery<Pick<Query, 'contact'>, QueryContactArgs>(GET_CONTACT, {
      variables: {
        id: contactId,
      },
    });

    const conversationQuery = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
      onError: handleGraphQLError,
    });

    const { loadingDepartmentOptions, departmentOptions } =
      useActiveDepartmentSelectOptions();
    const [conversationSetDepartment] = useMutation<
      Pick<Mutation, 'conversationSetDepartment'>,
      MutationConversationSetDepartmentArgs
    >(CONVERSATION_SET_DEPARTMENT);

    const { getLegacyContactEditUrl } = useUrls();
    const showEditContactModal = () => {
      openRemoteModalWrapper(getLegacyContactEditUrl(contactId));
    };

    // When executing create tag mutation, this query data is re-fetched as the data is not already in the cache,
    // the query executes again to sync the data in the cache, so we check if we already
    // had data to know if it is fetching due to initial load or due to create tag mutation
    const isFirstLoad = contactQuery.loading && !contactQuery.previousData;

    if (isFirstLoad || loadingDepartmentOptions || conversationQuery.loading) {
      return <ContactDetailsSkeleton />;
    }

    if (!contactQuery.data) return null;

    const { contact } = contactQuery.data;
    const { lastContactInteraction, label } = contact;

    const lastInteractionDate = lastContactInteraction?.occurredAt;

    return (
      <StyledContactDetails data-lgg-id="contact-details" {...rest}>
        {showHeader && (
          <Header>
            <StyledRow>
              <Name data-value={label} data-lgg-id="contact-details-label">
                {label}
              </Name>
              <StyledEditIcon
                type="edit"
                onClick={showEditContactModal}
                lggTestId="edit-contact-legacy-modal-trigger"
              />
            </StyledRow>
            <LastInteraction>
              {lastInteractionDate
                ? formatLastInteractionDate(lastInteractionDate)
                : t('common:noInteractionLogged')}
            </LastInteraction>
            <ViewFullContactProfileLink contactId={contactId} />
          </Header>
        )}
        <ScrollableSection>
          <SectionTitle>{t('contacts:information.title')}</SectionTitle>
          <ContactDetails contact={contact} />
          <StyledDivider />
          <SectionTitle>{t('conversations:conversationDetails.title')}</SectionTitle>
          <Row>
            <IconCircle>
              <StyledIcon type="department" />
            </IconCircle>
            <ContactDetailInfoContainer>
              <Label>{t('conversations:conversationDetails.department')}</Label>
              <Select
                name="contact-details-department"
                placeholder={t('common:selectOne')}
                mobileLabel={t('conversations:conversationDetails.department')}
                isLoading={loadingDepartmentOptions}
                value={
                  conversationQuery.data?.conversation?.department &&
                  entityToSelectOptionMapper(
                    conversationQuery.data?.conversation.department,
                  )
                }
                options={departmentOptions}
                onChange={(option) => {
                  const departmentId = option?.value;

                  void conversationSetDepartment({
                    variables: {
                      conversationId,
                      departmentId: departmentId ?? null,
                    },
                    onError: handleGraphQLError,
                  });
                }}
              />
            </ContactDetailInfoContainer>
          </Row>
        </ScrollableSection>
      </StyledContactDetails>
    );
  },
);

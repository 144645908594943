import React from 'react';
import 'src/i18n/init';
import 'src/configure-sentry';
import 'react-h5-audio-player/lib/styles.css';
import 'antd/dist/antd.css';
import 'src/assets/fonts/fonts.css';
import { ServiceContainer } from 'react-service-locator';
import { createGlobalStyle } from 'styled-components';
import { LoadingFallback } from 'src/components/loading-fallback';
import { LggThemeProvider } from 'src/components/providers/lgg-theme-provider';
import { SafeAreaInsetsProvider } from 'src/components/providers/safe-area-insets-provider';
import { MainRouter } from 'src/components/routing/main-router';
import { antd4OverrideGlobalStyle } from 'src/theme/globals/antd4-override-global-style';
import { emojiPickerGlobalStyle } from 'src/theme/globals/emoji-picker-global-style';
import { legacyModalGlobalStyle } from 'src/theme/globals/legacy-modal-global-style';

const GlobalStyle = createGlobalStyle`
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: flex;
    color: #636e7b;
    font-family: "SailecRegular";
    font-size: 13px;
    line-height: 1.42857;
    margin: 0;

    > #root {
      display: flex;
      flex: 1;
    }
  }

  a, a:hover {
    color: #2d98da;
  }

  // Fix problem with fonts and select boxes in firefox
  // https://stackoverflow.com/questions/39884774/css-font-family-support-dropped-for-select-in-firefox
  select, option, optgroup {
    font: -moz-pull-down-menu;
  }

  h1, h2, h3, h4, h5, h6 {
    color: inherit
  }

  ${emojiPickerGlobalStyle}
  ${antd4OverrideGlobalStyle}
  ${legacyModalGlobalStyle}
`;

function App() {
  console.info('vite mode', import.meta.env.MODE);
  console.info('vite deployment env', import.meta.env.VITE_DEPLOYMENT_ENVIRONMENT);

  return (
    <ServiceContainer>
      <LggThemeProvider>
        <SafeAreaInsetsProvider>
          <GlobalStyle />
          <LoadingFallback>
            <MainRouter />
          </LoadingFallback>
        </SafeAreaInsetsProvider>
      </LggThemeProvider>
    </ServiceContainer>
  );
}

export default App;

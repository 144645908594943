import React, { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Landing } from 'src/components/routing/landing/landing';

export const MainRouter = memo(() => {
  return (
    <Router>
      <Landing />
    </Router>
  );
});

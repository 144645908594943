import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ContactStageSlug } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useFormatDate } from 'src/hooks/use-format-date';

const ReactionInfo = styled(FlexColumn)`
  margin-left: 10px;
  margin-right: 5px;
  flex: 1;
  overflow: hidden;

  ${up('md')} {
    margin-left: 8px;
  }
`;

const ReactionContactLabel = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
  text-align: left;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;

  ${up('md')} {
    font-size: 13px;
    line-height: 15px;
  }
`;

const ReactionDate = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
  }
`;

const ReactionPreview = styled.div`
  font-size: 24px;
`;

const StyledStageIcon = styled(ContactStageIcon)`
  height: 36px;
  width: 36px;

  svg {
    height: 18px;
    width: 18px;
  }

  ${up('md')} {
    height: 32px;
    width: 32px;
    margin-right: 0;

    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const PreviewRow = styled(FlexRow)`
  padding: 8px 5px 10px 5px;
  align-items: center;

  ${up('md')} {
    padding: 15px;
    width: 300px;
  }
`;

type ReactionPreviewRowProps = {
  stageSlug: ContactStageSlug;
  statusName: string;
  contactLabel: string;
  creationDate: string;
  emoji: string;
};

export const ReactionPreviewRow = memo<ReactionPreviewRowProps>(
  ({ stageSlug, statusName, creationDate, contactLabel, emoji }) => {
    const { formatDate } = useFormatDate();

    return (
      <PreviewRow>
        <StyledStageIcon slug={stageSlug} name={statusName} />
        <ReactionInfo>
          <ReactionContactLabel data-lgg-id="message-reaction-contact-label">
            {contactLabel}
          </ReactionContactLabel>
          <ReactionDate data-lgg-id="message-reaction-creation-date">
            {formatDate(creationDate, 'h:mm a')}
          </ReactionDate>
        </ReactionInfo>
        <ReactionPreview data-lgg-id="message-reaction-emoji">{emoji}</ReactionPreview>
      </PreviewRow>
    );
  },
);

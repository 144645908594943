import { Service } from 'react-service-locator';

@Service()
export class DocumentTitleService {
  public setTitle = (newTitle: string) => {
    if (newTitle && newTitle !== 'loading') {
      document.title = `${newTitle} - Leadgogo`;
    }
  };
}

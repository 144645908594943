import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { MediaPreviewModal } from 'src/components/general/display/media-preview-modal';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { ConversationPromotionalVideoEmbed } from 'src/components/pages/conversations/components/general/conversations-promotional-video-embed';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { BreadcrumbsService } from 'src/services/breadcrumbs.service';

const Container = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryMint10};
  color: ${({ theme }) => theme.colors.secondaryMintDark};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 30px;
  letter-spacing: normal;
  line-height: 13px;
  padding: 0 20px;
  text-align: left;

  strong {
    font-family: ${({ theme }) => theme.font.bold};
    margin: 0 2px;
  }

  ${up('lg')} {
    border-radius: 6px;
    border: solid 1px #36c99a80;
    height: 24px;
    margin-left: 11px;
    padding: 0 8px 0 6px;
  }
`;

const PlayIcon = styled(Icon)`
  margin-right: 5px;
  width: 16px;
  border-radius: 50%;
  border: 1.5px solid rgba(45, 152, 218, 0.5);
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const MessageContainer = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

const WatchVideoOption = styled(FlexRow)`
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.gogo};
  align-items: center;
  font-size: 11px;
  line-height: 13px;
`;

const StyledPreviewModal = styled(MediaPreviewModal)`
  .ant-modal-content {
    margin: 0 -8px;

    ${up('md')} {
      margin: 0 -16px;
    }
  }
`;

export const FreeWhileInBetaBanner = memo(() => {
  const breakpointUpLg = useBreakpoint(up('lg'));
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['common']);
  const {
    visible: conversationVideoVisible,
    setVisible: setConversationVideoVisibility,
  } = useVisible();
  const breadcrumbRoutes = useServiceSelector(BreadcrumbsService, (s) => s.state.routes);

  const component = (
    <Container data-lgg-id="free-while-in-beta-banner">
      {breakpointUpLg ? (
        <Trans
          i18nKey="conversations:freeWhileBetaBanner.text.short"
          components={{ strong: <strong /> }}
        />
      ) : (
        <>
          <MessageContainer>
            <span style={{ display: 'block' }}>
              <Trans
                i18nKey="conversations:freeWhileBetaBanner.text.long"
                components={{ strong: <strong /> }}
              />
            </span>
            <WatchVideoOption
              onClick={() => {
                setConversationVideoVisibility(true);
              }}
            >
              <PlayIcon type="play" />
              {t('common:watchVideo')}
            </WatchVideoOption>
          </MessageContainer>
          <StyledPreviewModal
            visible={conversationVideoVisible}
            onClose={() => {
              setConversationVideoVisibility(false);
            }}
            width="100%"
          >
            <ConversationPromotionalVideoEmbed
              height={breakpointUpMd ? '292px' : '280px'}
            />
          </StyledPreviewModal>
        </>
      )}
    </Container>
  );

  if (breakpointUpLg) {
    const headerExtraContentElement = document.getElementById('header-extra-content');

    if (breadcrumbRoutes.length === 0 || !headerExtraContentElement) {
      return null;
    }

    return ReactDOM.createPortal(component, headerExtraContentElement);
  } else {
    return component;
  }
});

import { css } from 'styled-components';

export const disableAnimationsStyle = css`
  *,
  *:before,
  *:after {
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
`;

export const disableTransitionsStyle = css`
  *,
  *:before,
  *:after {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
  }
`;

import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
  Mutation,
  MutationSetStatusNotificationFeedItemsArgs,
  NotificationFeedItemStatus,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

export const NOTIFICATION_SET_STATUS = gql`
  mutation setStatusNotificationFeedItems($input: NotificationFeedItemSetClearedInput!) {
    setStatusNotificationFeedItems(input: $input) {
      success
    }
  }
`;

type NotificationSetStatusVariables = {
  notificationIds?: string[];
  status: NotificationFeedItemStatus;
  onCompleted?: VoidFunction;
  onError?: (error: ApolloError) => void;
};

export const useNotificationSetStatus = () => {
  const handleGraphQLError = useHandleGraphQLError();
  const [mutate] = useMutation<
    Pick<Mutation, 'setStatusNotificationFeedItems'>,
    MutationSetStatusNotificationFeedItemsArgs
  >(NOTIFICATION_SET_STATUS);

  return async ({
    status,
    notificationIds = [],
    onCompleted,
    onError,
  }: NotificationSetStatusVariables) => {
    await mutate({
      variables: { input: { status, notificationIds } },
      onError: (error) => {
        handleGraphQLError(error);
        onError?.(error);
      },
      onCompleted: () => {
        onCompleted?.();
      },
    });
  };
};

type NotificationSetClearedVariables = {
  notificationIds?: string[];
  isCleared: boolean;
  onCompleted?: VoidFunction;
};

export const useNotificationSetClearedStatus = () => {
  const notificationSetStatus = useNotificationSetStatus();
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();
  const { t } = useTranslation(['notifications']);

  return async ({
    isCleared,
    notificationIds = [],
    onCompleted,
  }: NotificationSetClearedVariables) => {
    const isUpdatingSingleNotification = notificationIds.length === 1;

    await notificationSetStatus({
      status: isCleared ? 'CLEARED' : 'INTERACTED',
      notificationIds,
      onError: (error) => {
        const message = isUpdatingSingleNotification
          ? t('notifications:operationMessages.error.single')
          : t('notifications:operationMessages.error.all');

        handleGraphQLError(error);
        showNotification({
          type: 'error',
          title: message,
        });
      },
      onCompleted: () => {
        onCompleted?.();
        let message;

        if (isCleared) {
          message = isUpdatingSingleNotification
            ? t('notifications:operationMessages.success.clear.single')
            : t('notifications:operationMessages.success.clear.all');
        } else {
          message = isUpdatingSingleNotification
            ? t('notifications:operationMessages.success.unclear.single')
            : t('notifications:operationMessages.success.unclear.all');
        }

        showNotification({
          type: 'success',
          title: t(message),
        });
      },
    });
  };
};

import { Service, StatefulService } from 'react-service-locator';

@Service()
export class GlobalLoadingService extends StatefulService<{
  isLoading: boolean;
}> {
  constructor() {
    super({ isLoading: false });
  }

  setLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };
}

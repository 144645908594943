import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { FacebookSettingSection } from 'src/components/domain/users/components/profile-settings-modal/components/facebook-setting-section';
import { LanguagePreferenceForm } from 'src/components/domain/users/components/profile-settings-modal/components/language-preference-form';
import { PasswordManagementForm } from 'src/components/domain/users/components/profile-settings-modal/components/password-management-form';
import { PersonalInformationForm } from 'src/components/domain/users/components/profile-settings-modal/components/personal-information-form';
import { useGetCurrentUser } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-get-current-user';
import { Icon } from 'src/components/general/icon';
import { LoadingIndicator } from 'src/components/loading-fallback';

const CustomIcon = styled(Icon)`
  svg {
    height: 20px;
    width: 20px;
  }

  ${up('md')} {
    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

export type SettingsOptionsSection = {
  icon: ReactElement;
  title: string;
  content: ReactElement;
};

export type SettingsOptions = {
  personalInformation: SettingsOptionsSection;
  languagePreference: SettingsOptionsSection;
  passwordManagement: SettingsOptionsSection;
  facebookSetting?: SettingsOptionsSection;
  menu?: SettingsOptionsSection;
};

export type SettingsOptionsKeys = keyof SettingsOptions;

export const useProfileSettingsOptions = (initialView?: SettingsOptionsKeys) => {
  const { loading, currentUser } = useGetCurrentUser();

  const [currentView, setCurrentView] = useState<SettingsOptionsKeys | undefined>(
    initialView,
  );

  const { t } = useTranslation(['user']);

  const formId = `${currentView}Form`;

  const settingsOptions: SettingsOptions = useMemo(
    () => ({
      personalInformation: {
        icon: <CustomIcon type="contact" />,
        title: t('user:profileSettings.options.personalInformation'),
        content:
          loading || !currentUser ? (
            <LoadingIndicator />
          ) : (
            <PersonalInformationForm
              formId="personalInformationForm"
              currentUser={currentUser}
            />
          ),
      },
      languagePreference: {
        icon: <CustomIcon type="world" />,
        title: t('user:profileSettings.options.languagePreference'),
        content: <LanguagePreferenceForm formId="languagePreferenceForm" />,
      },
      passwordManagement: {
        icon: <CustomIcon type="lock" />,
        title: t('user:profileSettings.options.passwordManagement'),
        content: <PasswordManagementForm formId="passwordManagementForm" />,
      },
      ...(currentUser?.isFacebookLinked
        ? {
            facebookSetting: {
              icon: <CustomIcon type="facebook" />,
              title: t('user:profileSettings.options.facebookSettings'),
              content: <FacebookSettingSection />,
            },
          }
        : {}),
    }),
    [t, loading, currentUser],
  );

  return {
    currentUser,
    currentView,
    setCurrentView,
    formId,
    settingsOptions,
  };
};

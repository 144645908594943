import { gql, useQuery } from '@apollo/client';
import {
  QueryDepartmentsArgs,
  DepartmentConnection,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

export const DEPARTMENTS = gql`
  query GetDepartments(
    $institutionId: Int!
    $orderBy: DepartmentOrderByInput
    $where: DepartmentWhereInput
  ) {
    departments(institutionId: $institutionId, orderBy: $orderBy, where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const useActiveDepartmentsQuery = () => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();

  return useQuery<{ departments: DepartmentConnection }, Partial<QueryDepartmentsArgs>>(
    DEPARTMENTS,
    {
      variables: {
        institutionId: currentInstitution.id,
        orderBy: {
          name: 'ASC',
        },
        where: {
          isActive: { _eq: true },
        },
      },
      onError: handleGraphQLError,
    },
  );
};

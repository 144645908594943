import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Icon } from 'src/components/general/icon';

export const ConversationBanner = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 13px;
  min-height: 26px;

  ${up('md')} {
    min-height: 30px;
  }
`;

export const ConversationBannerIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  margin-right: 10px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ color }) => color};
    }
  }
`;

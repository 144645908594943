import { memo, useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { PreviousLocationContext } from 'src/components/routing/lgg-switch';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useUrls } from 'src/hooks/use-urls';

type LegacyModalRedirectProps = {
  backgroundUrl: string;
  resolveUrl: (
    params: ReturnType<typeof useParams>,
    urls: ReturnType<typeof useUrls>,
  ) => string;
};

export const LegacyModalRedirect = memo<LegacyModalRedirectProps>(
  ({ resolveUrl, backgroundUrl }) => {
    const history = useHistory();
    const institutionUrl = useInstitutionUrl();
    const urls = useUrls();
    const previousLocation = useContext(PreviousLocationContext);
    const params = useParams();
    const url = useMemo(() => resolveUrl(params, urls), [urls, params, resolveUrl]);

    useEffect(() => {
      if (previousLocation) {
        history.goBack();
        openRemoteModalWrapper(url);
      } else {
        history.push(backgroundUrl);
        openRemoteModalWrapper(url);
      }
    }, [backgroundUrl, history, institutionUrl, previousLocation, url]);

    return null;
  },
);

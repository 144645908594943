import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeValue } from 'react-select';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { difference, uniqBy } from 'lodash';
import {
  ContactTag,
  Mutation,
  MutationAddContactTagArgs,
  MutationRemoveContactTagArgs,
  Query,
  QueryContactTagsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useContactTagListForSelect } from 'src/components/domain/contacts/hooks/use-contact-tag-list-for-select';
import {
  CONTACT_TAGS,
  ContactTagRefetchType,
} from 'src/components/domain/contacts/hooks/use-contact-tags';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const ADD_CONTACT_TAG = gql`
  mutation AddContactTag($input: AddContactTagInput!) {
    addContactTag(input: $input) {
      contact {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

const REMOVE_CONTACT_TAG = gql`
  mutation RemoveContactTag($input: RemoveContactTagInput!) {
    removeContactTag(input: $input) {
      contact {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

type TagsSelectorProps = {
  contactId: number;
  selectedTags: ContactTag[];
};

type ContactTagSelectorProps<IsMulti extends boolean> = {
  selectedTagsIds: number[];
  isMulti: IsMulti;
  onChange: ValueChanged<OnChangeValue<SelectOption<number>, IsMulti>>;
  isCreatable?: boolean;
  name: string;
  placeholder?: string;
  mobileLabel?: string;
  label?: string;
  isLoading?: boolean;
  refetchRef?: (refetch: ContactTagRefetchType) => void;
};

export const ContactTagSelect = <IsMulti extends boolean>(
  props: ContactTagSelectorProps<IsMulti>,
) => {
  const currentInstitution = useCurrentInstitution();
  const { isMulti, selectedTagsIds, isLoading, isCreatable, refetchRef, ...rest } = props;
  const [cachedTagOptions, setCachedTagOptions] = useState<SelectOption<number>[]>([]);
  const [fetchExtraTags] = useLazyQuery<
    Pick<Query, 'contactTags'>,
    Partial<QueryContactTagsArgs>
  >(CONTACT_TAGS);

  // On first render we load the selected tags, for cases where the input first query
  // does not return the already selected tags
  useEffect(() => {
    void (async () => {
      if (!selectedTagsIds.length) {
        return;
      }

      const { data } = await fetchExtraTags({
        variables: {
          institutionId: currentInstitution.id,
          where: { id: { _in: selectedTagsIds }, isActive: { _eq: true } },
        },
      });

      const nodes = getNodesFromConnection(data?.contactTags);

      if (nodes) {
        setCachedTagOptions((prevState) => {
          return uniqBy(
            [...prevState, ...nodes.map(entityToSelectOptionMapper)],
            'value',
          );
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tagOptions, loadingTagOptions, refetch, loadMore, hasNextPage } =
    useContactTagListForSelect();

  useEffect(() => {
    if (tagOptions.length) {
      setCachedTagOptions((prevState) => {
        return uniqBy([...prevState, ...tagOptions], 'value');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTagOptions]);

  useEffect(() => {
    refetchRef?.(refetch);
  }, [refetch, refetchRef]);

  return (
    <Select
      {...rest}
      isSearchable
      isMulti={isMulti}
      isTag
      onChange={(value) => {
        rest?.onChange(value);
      }}
      onMenuClose={async () => {
        await refetch({
          where: {
            isActive: { _eq: true },
          },
        });
      }}
      onSearch={async (name) => {
        await refetch({ where: { name: { _contains: name }, isActive: { _eq: true } } });
      }}
      canLoadMore={hasNextPage}
      onLoadMore={loadMore}
      isLoading={loadingTagOptions || isLoading}
      isCreatable={isCreatable}
      value={
        selectedTagsIds
          .map((id) => {
            return cachedTagOptions.find((tag) => tag.value === id);
          })
          .filter(Boolean) as SelectOption<number>[]
      }
      options={tagOptions}
    />
  );
};

export const LegacyContactTagsSelector: React.FC<TagsSelectorProps> = ({
  contactId,
  selectedTags,
  ...rest
}) => {
  const { id: institutionId } = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { t } = useTranslation(['contacts']);
  const [loading, setLoading] = useState(false);
  const refetchRef = useRef<ContactTagRefetchType | null>(null);
  const [addContactTag] = useMutation<
    Pick<Mutation, 'addContactTag'>,
    MutationAddContactTagArgs
  >(ADD_CONTACT_TAG);
  const [removeContactTag] = useMutation<
    Pick<Mutation, 'removeContactTag'>,
    MutationRemoveContactTagArgs
  >(REMOVE_CONTACT_TAG);

  const handleChange = useCallback(
    async (values: ReadonlyArray<string | number>) => {
      const selectedTagIds = selectedTags.map((tag) => tag.id);

      let addTagRequest: null | (() => Promise<void>) = null;
      let removeTagRequest: null | (() => Promise<void>) = null;
      let refetchAfterAddingNewTag: boolean = false;

      const tagsToAdd = difference(values, selectedTagIds);

      if (tagsToAdd.length) {
        const tags = tagsToAdd.map((tag) => {
          if (typeof tag === 'string') {
            refetchAfterAddingNewTag = true;
            return `new-tag-${tag}`;
          }
          return tag.toString();
        });
        addTagRequest = async () => {
          await addContactTag({
            variables: {
              input: {
                contactId,
                tags,
                institutionId,
              },
            },
            onError: handleGraphQLError,
          });
        };
      }

      const tagsToRemove = difference(selectedTagIds, values);

      if (tagsToRemove.length) {
        removeTagRequest = async () => {
          await removeContactTag({
            variables: {
              input: {
                contactId,
                tagsId: tagsToRemove.map((t) =>
                  typeof t === 'string' ? parseInt(t) : t,
                ),
                institutionId,
              },
            },
            onError: handleGraphQLError,
          });
        };
      }

      if (addTagRequest || removeTagRequest) {
        setLoading(true);
        await addTagRequest?.();
        refetchAfterAddingNewTag && (await refetchRef.current?.()); // Makes created tags show in the list
        await removeTagRequest?.();
        setLoading(false);
      }
    },
    [
      addContactTag,
      contactId,
      handleGraphQLError,
      institutionId,
      removeContactTag,
      selectedTags,
    ],
  );

  return (
    <ContactTagSelect
      {...rest}
      isMulti
      name="contact-details-tags"
      placeholder={t('contacts:information.tags')}
      mobileLabel={t('contacts:information.tags')}
      isLoading={loading}
      isCreatable
      refetchRef={(refetch) => (refetchRef.current = refetch)}
      selectedTagsIds={selectedTags.map((tag) => tag.id)}
      onChange={(options) => handleChange(options.map(({ value }) => value))}
    />
  );
};

import React, { memo } from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';

const StyledNewItemBubble = styled.div<Pick<NewItemBubbleProps, 'direction'>>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 17px;
  box-shadow: 0 10px 20px 0 #727d8e1a;
  border: solid 1px ${({ theme }) => theme.colors.secondaryMint60};
  background-color: ${({ theme }) => theme.colors.secondaryMint10};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.smalt};
  z-index: 10;

  ${({ direction }) => (direction === 'TOP' ? 'top: 15px' : 'bottom: 21px')};

  svg {
    margin-right: 10px;
  }
`;

type NewItemBubbleProps = {
  text: string;
  direction: 'TOP' | 'BOTTOM';
  onClick: VoidFunction;
};

export const NewItemBubble = memo<NewItemBubbleProps>(({ onClick, text, direction }) => {
  return (
    <StyledNewItemBubble onClick={onClick} direction={direction}>
      <Icon type={direction === 'TOP' ? 'arrowPrevious' : 'arrowNext'} />
      {text}
    </StyledNewItemBubble>
  );
});

import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  LggSelectableOptionsDropdownButton,
  LggDropdownButtonWithoutOverlay,
} from 'src/components/general/button/dropdown-button';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { conversationDropdownButtonStyles } from 'src/components/pages/conversations/components/filters/shared';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { ConversationStatus } from 'src/services/conversation.service';

const DesktopDropDownButton = styled(LggSelectableOptionsDropdownButton)`
  ${conversationDropdownButtonStyles};
`;

const MobileDropDownButton = styled(LggDropdownButtonWithoutOverlay)`
  ${conversationDropdownButtonStyles};
`;

type ConversationStatusFilterProps = {
  selectedConversationStatus: ConversationStatus;
  onApply: (filters: { conversationStatus: ConversationStatus }) => void;
};

export const ConversationStatusFilter = memo<ConversationStatusFilterProps>(
  ({ onApply, selectedConversationStatus }) => {
    const { t } = useTranslation(['conversations']);
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));

    const statusValues: Array<{
      value: ConversationStatus;
      label: string;
      icon: string;
      'data-lgg-id': string;
    }> = useMemo(
      () => [
        {
          value: 'IS_OPEN',
          label: t('conversations:statusFilters.allOpen'),
          icon: 'conversationsAll',
          'data-lgg-id': 'status-option-is-open',
        },
        {
          value: 'IS_REPLY_PENDING',
          label: t('conversations:statusFilters.notReplied'),
          icon: 'conversationsNotReplied',
          'data-lgg-id': 'status-option-is-reply-pending',
        },
        {
          value: 'IS_MARKED',
          label: t('conversations:statusFilters.marked'),
          icon: 'opportunity',
          'data-lgg-id': 'status-option-is-marked',
        },
        {
          value: 'IS_CLOSED',
          label: t('conversations:statusFilters.closed'),
          icon: 'circularCheck',
          'data-lgg-id': 'status-option-is-closed',
        },
        {
          value: 'IS_SNOOZED',
          label: t('conversations:statusFilters.snoozed'),
          icon: 'time',
          'data-lgg-id': 'status-option-is-snoozed',
        },
      ],
      [t],
    );

    const selectedItem = statusValues.find(
      ({ value }) => value === selectedConversationStatus,
    );

    const handleChange = (value: ConversationStatus) => {
      onApply({ conversationStatus: value });
      visibilityHandler.close();
    };

    const statusOptions = statusValues.map((option) => ({
      ...option,
      onClick: () => handleChange(option.value),
    }));

    if (breakpointUpMd) {
      return (
        <DesktopDropDownButton
          data-lgg-id="status-filters-trigger-button"
          onClick={!breakpointUpMd ? visibilityHandler.show : undefined}
          size="small"
          variant="defaultGhost"
          options={statusOptions}
          selectedValue={selectedItem?.value ?? null}
          visibilityHandler={visibilityHandler}
          customDropdownProps={{
            overlayStyle: { width: '161px' },
          }}
        >
          {selectedItem?.label}
        </DesktopDropDownButton>
      );
    }

    return (
      <>
        <MobileDropDownButton
          isActive={visibilityHandler.visible}
          size="regular"
          variant="defaultWhite"
          onClick={() => visibilityHandler.setVisible(!visibilityHandler.visible)}
        >
          {selectedItem?.label}
        </MobileDropDownButton>
        <SelectableOptionsDrawer
          title={t('conversations:statusFilters.title')}
          visible={visibilityHandler.visible}
          onClose={visibilityHandler.close}
          selectedValue={selectedItem?.value ?? null}
          options={statusOptions}
        />
      </>
    );
  },
);

import React, { memo } from 'react';
import styled from 'styled-components';
import { ContactInteractionDirection } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import { Center } from 'src/components/layout/center';
import {
  AttachmentOverlay,
  IsomorphicAttachmentFileItemProps,
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
  PlayIcon,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import {
  ItemCaption,
  RepliedMessageRenderer,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { AttachmentPreviewModal } from 'src/components/pages/conversations/components/general/attachment-preview-modal';
import { useVisible } from 'src/hooks/use-visible';

const PlaceholderVideo = styled.video<{ direction: ContactInteractionDirection }>`
  width: 100%;
  pointer-events: none;
  overflow: hidden;
`;

const VideoAttachmentBubble = styled(BubbleTypeAttachmentContainer)`
  padding: 4px 4px 10px 4px;
  max-width: 280px;
`;

const StyledAttachmentDateAndStatus = styled(AttachmentDateAndStatus)`
  margin-left: 5px;
`;

const StyledRepliedMessageRenderer = styled(RepliedMessageRenderer)`
  margin: -5px -5px 10px;
`;

const StyledCenter = styled(Center)<{ direction: ContactInteractionDirection }>`
  position: relative;
  overflow: hidden;
  border-radius: ${(props) =>
    props.direction === 'INBOUND' ? '6px 6px 6px 0' : '6px 6px 0 6px'};
`;

export const VideoAttachmentItem = memo<
  IsomorphicAttachmentFileItemProps & {
    onOpenInteractionDetailsModalVisibility: VoidFunction;
    showDeliveryStatus: boolean;
  }
>(
  ({
    attachment,
    attachmentDate,
    onOpenInteractionDetailsModalVisibility,
    showDeliveryStatus,
    contactInteraction,
  }) => {
    const {
      visible: isAttachmentPreviewVisible,
      show: showPreview,
      close: closePreview,
    } = useVisible();
    const {
      url,
      filename = '',
      type,
      mime: mimeType,
      status = null,
      caption,
    } = attachment;
    const { direction } = contactInteraction;

    return (
      <VideoAttachmentBubble direction={direction}>
        <StyledRepliedMessageRenderer contactInteraction={contactInteraction} />
        <StyledCenter className="attachment" direction={direction}>
          <PlayIcon type="play" />
          <PlaceholderVideo direction={direction}>
            <source src={url} data-lgg-id="video-attachment-item" />
          </PlaceholderVideo>
          <AttachmentOverlay onClick={showPreview} />
        </StyledCenter>
        {caption && (
          <ItemCaption data-lgg-id="item-caption" direction={direction}>
            <TextFormatter>{caption}</TextFormatter>
          </ItemCaption>
        )}
        <StyledAttachmentDateAndStatus
          attachmentDate={attachmentDate}
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
          floating={false}
        />
        <AttachmentPreviewModal
          type={type}
          url={url}
          mimeType={mimeType}
          name={filename}
          visible={isAttachmentPreviewVisible}
          onClose={closePreview}
          onShowDetails={onOpenInteractionDetailsModalVisibility}
          testId="conversations-attachment-preview"
        />
      </VideoAttachmentBubble>
    );
  },
);

import React, { memo } from 'react';
import { Col, Row, Space } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Skeleton, SkeletonButton } from 'src/components/general/feedback/skeleton';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const TableBodyPlaceholderContainer = styled(Row)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  width: auto;
`;

const TableLayoutPlaceholderContainer = styled.div`
  background: ${({ theme }) => theme.colors.porcelain};
  padding: 30px 0;
`;

const TableColumnSkeleton = styled(Skeleton)`
  &.ant-skeleton {
    display: flex;
    width: 100%;

    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 0;
    }

    .ant-skeleton-title {
      max-width: 100%;
    }

    .ant-skeleton-paragraph li,
    .ant-skeleton-title {
      border-radius: 4px;
    }

    .ant-skeleton-paragraph {
      display: flex;
      flex-direction: column;
      margin: 0;

      li {
        height: 16px;
        margin: 16px 0 12px;
        width: 100%;

        ${up('md')} {
          max-width: 350px;
        }
      }
    }
  }
`;

const TableHeaderSkeleton = styled(Skeleton)`
  &.ant-skeleton {
    .ant-skeleton-title {
      border-radius: 4px;
    }
  }
`;

const TableFooterBottomSkeleton = styled(SkeletonButton)`
  &.ant-skeleton-button {
    width: 100px;
  }
`;

const TableFilterBottomSkeleton = styled(SkeletonButton)`
  &.ant-skeleton-button {
    width: 50px;

    ${up('md')} {
      width: 100px;
    }
  }
`;

const TableColumnContainer = styled.div`
  flex: 1;
  max-width: 29%;
`;

const BaseContainer = styled(Row)`
  padding: 0 20px;

  ${up('md')} {
    padding: 0;
  }
`;

const TableFooterPlaceholderContainer = styled(BaseContainer)`
  margin-top: 30px;
`;

const TableHeaderPlaceholderContainer = styled(BaseContainer)`
  margin-bottom: 30px;
`;

const TableHeadPlaceholder = memo(() => (
  <TableHeaderPlaceholderContainer align="middle">
    <Col flex="auto">
      <TableHeaderSkeleton title={{ width: 200 }} paragraph={false} active />
    </Col>
    <Col>
      <TableFilterBottomSkeleton active size="small" />
    </Col>
  </TableHeaderPlaceholderContainer>
));

export const TableBodyPlaceholder = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <TableBodyPlaceholderContainer
      align="middle"
      data-lgg-id="table-body-placeholder-container"
    >
      {breakpointUpMd ? (
        <>
          {[...Array(3)].map((_, index) => (
            <TableColumnContainer key={index}>
              <TableColumnSkeleton
                title={{
                  width: 200,
                }}
                paragraph={{
                  rows: 9,
                  width: '100%',
                }}
                active
              />
            </TableColumnContainer>
          ))}
        </>
      ) : (
        <TableColumnSkeleton
          title={false}
          paragraph={{
            rows: 6,
            width: '100%',
          }}
          active
        />
      )}
    </TableBodyPlaceholderContainer>
  );
});

const TableFooterPlaceholder = memo(() => {
  return (
    <TableFooterPlaceholderContainer align="middle">
      <Space>
        <TableFooterBottomSkeleton active size="small" />
        <TableFooterBottomSkeleton active size="small" />
      </Space>
    </TableFooterPlaceholderContainer>
  );
});

export const TableLayoutPlaceholder = memo(() => {
  return (
    <TableLayoutPlaceholderContainer>
      <TableHeadPlaceholder />
      <TableBodyPlaceholder />
      <TableFooterPlaceholder />
    </TableLayoutPlaceholderContainer>
  );
});

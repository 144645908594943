import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { PopoverV2, PopoverV2Props } from 'src/components/general/display/popover';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactBlockedBadge } from 'src/components/pages/contacts/components/contact-block';
import { ContactPreview } from 'src/components/pages/contacts/components/contact-preview';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';

const StyledStageIcon = styled(ContactStageIcon)<{ marginRight: number }>`
  height: 18px;
  margin-right: 5px;
  min-width: 18px;
  width: 18px;

  svg {
    height: 8px;
    width: 8px;
  }

  ${up('md')} {
    height: 30px;
    margin-right: ${({ marginRight }) => marginRight}px;
    width: 30px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

const ContactLabelContainer = styled(FlexRow)`
  align-items: center;
  width: 100%;
`;

const ContactLabelStyle = css`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 45px);
`;

const ContactLabelTextLink = styled(Link)`
  ${ContactLabelStyle};
  cursor: pointer;

  ${up('md')} {
    p:hover {
      color: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const ContactLabelSpan = styled.span`
  ${ContactLabelStyle};
`;

const ContactLabelTextColumn = styled(FlexColumn)`
  ${ContactLabelStyle};
`;

const ContactLabelText = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 13px;
    line-height: 16px;
  }
`;

type ContactLabelPros = {
  contact: Contact | null;
  testId?: string;
  marginSize?: number;
  popoverPlacement?: PopoverV2Props['placement'];
  disableInteractions?: boolean;
  showBlockedBadge?: boolean;
};

export const ContactLabel = memo<ContactLabelPros>((props) => {
  const {
    contact,
    testId,
    marginSize = 15,
    popoverPlacement = 'bottom',
    disableInteractions,
    showBlockedBadge,
    ...rest
  } = props;
  const { getFeatureFlag } = useAuthorization();
  const { getContactModalUrl } = useUrls();
  const hasCrmFeatureFlag = getFeatureFlag('crm');
  const breakpointUpMd = useBreakpoint(up('md'));
  const textElement = useMemo(() => {
    if (!contact) {
      return null;
    }

    const contactLabel = (
      <ContactLabelText className="contact-label_text" data-lgg-id={testId}>
        {contact.label}
      </ContactLabelText>
    );

    if (disableInteractions) {
      return contactLabel;
    }

    return (
      <PopoverV2
        placement={popoverPlacement}
        destroyTooltipOnHide
        content={<ContactPreview contact={contact} />}
        visible={!breakpointUpMd ? false : undefined}
        trigger="hover"
      >
        {contactLabel}
      </PopoverV2>
    );
  }, [breakpointUpMd, contact, disableInteractions, popoverPlacement, testId]);

  if (!contact) {
    return null;
  }

  return (
    <ContactLabelContainer {...rest}>
      {hasCrmFeatureFlag && (
        <StyledStageIcon
          slug={contact.stage.slug}
          name={contact.stage.name}
          marginRight={marginSize}
        />
      )}
      <ContactLabelTextColumn>
        {disableInteractions ? (
          <ContactLabelSpan>{textElement}</ContactLabelSpan>
        ) : (
          <ContactLabelTextLink
            to={getContactModalUrl(contact.id)}
            onClick={(e) => {
              if (!breakpointUpMd) {
                e.preventDefault();
              }
            }}
          >
            {textElement}
          </ContactLabelTextLink>
        )}
        {showBlockedBadge && contact.isBlocked && <ContactBlockedBadge />}
      </ContactLabelTextColumn>
    </ContactLabelContainer>
  );
});

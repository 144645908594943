import { gql, useQuery } from '@apollo/client';
import { Query, QueryUserArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { useFormatDate } from 'src/hooks/use-format-date';
import { SNOOZED_UNTIL_DATE_FORMAT } from 'src/utils/snooze-utils';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID!) {
    user(id: $id) {
      id
      receiveMobileNotificationsAt
    }
  }
`;

export const usePushNotificationSnoozedUntil = () => {
  const userId = useCurrentUserId();
  const { formatDate } = useFormatDate();
  const { data, loading, error } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(
    GET_CURRENT_USER,
    {
      variables: {
        id: userId.toString(),
      },
    },
  );

  const snoozedUntil = data?.user.receiveMobileNotificationsAt;

  if (loading || error || !snoozedUntil) {
    return null;
  }

  return formatDate(snoozedUntil, SNOOZED_UNTIL_DATE_FORMAT);
};

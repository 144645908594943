import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => theme.cardsTheme.default};
  display: flex;
  flex-direction: column;

  ${up('md')} {
    margin: 0 auto;
    max-width: 570px;
  }
`;

import React, { memo } from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { LegacyPage } from 'src/components/pages/legacy/legacy.page';

export const AgencyPage = memo(() => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={`${url.replace(/\/+$/, '')}/organizations`} />
      </Route>
      <Route path="*">
        <LegacyPage />
      </Route>
    </Switch>
  );
});

import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled, { createGlobalStyle } from 'styled-components';
import { Schedule, User } from '@lgg/isomorphic/types/__generated__/graphql';
import { AppointmentStatusSelector } from 'src/components/domain/appointments/appointment-status-selector';
import { ContactLabel } from 'src/components/domain/contacts/contact-label';
import { AssigneeLabel } from 'src/components/general/display/assignee-label';
import { ItemDescription } from 'src/components/general/display/item-description';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import { TableLayoutDate } from 'src/components/general/display/table-layout-date';
import { Icon } from 'src/components/general/icon';
import {
  getDefaultSortOrder,
  TableColumnTitle,
  TableSortData,
} from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { AppointmentPriorityPicker } from 'src/components/pages/appointments/components/appointment-priority-picker';
import { AppointmentOptionsBottomDrawer } from 'src/components/pages/appointments/components/appointments-options-bottom-drawer';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const AppointmentTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 5px;

  ${up('md')} {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const MobileTableItemMainContent = styled(FlexColumn)`
  width: 100%;
`;

const TableArrowIcon = styled(Icon)`
  align-items: center;
  display: flex;
  margin-left: 10px;

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const MobileTableItemBottomContent = styled(FlexRow)`
  align-items: center;
  margin-top: 5px;

  & > * {
    min-width: max-content;
  }
`;

const AppointmentStatusDrawerSelectorStyles = createGlobalStyle`
  .ant-dropdown.context-menu-v2.appointment-status-selector-overlay {
    min-width: 163px !important;
  }
`;

type AppointmentsTableProps = {
  appointments: Schedule[];
  refetchQueriesHandler: VoidFunction;
  sortData: TableSortData;
  onChange: TableProps<Schedule>['onChange'];
};

export const AppointmentsTable = memo(
  ({
    appointments,
    refetchQueriesHandler,
    sortData,
    onChange,
  }: AppointmentsTableProps) => {
    const { t } = useTranslation(['appointments', 'common']);
    const breakpointUpMd = useBreakpoint(up('md'));
    const { getScheduleModalUrl } = useUrls();
    const mobileStatusOptionsVisibilityHandler = useVisible(false);
    const appointmentOptionsVisibilityHandler = useVisible(false);
    const [selectedAppointment, setSelectedAppointment] = useState<Schedule | null>(null);
    const { hasPermission } = useAuthorization();
    const isManager = hasPermission('cms.schedule.access.view.all');
    const { key: sortKey, direction: sortDirection } = sortData;
    const { ensureDate } = useDateHelpers();
    const mobilePriorityOptionsVisibilityHandler = useVisible();
    const history = useHistory();
    const { isPast } = useDateHelpers();

    const getAppointmentStatusSelector = useCallback(
      (appointment: Schedule) => {
        const show = () => {
          mobileStatusOptionsVisibilityHandler.show();
          setSelectedAppointment(appointment);
        };

        const close = () => {
          mobileStatusOptionsVisibilityHandler.close();
          setSelectedAppointment(null);
        };

        const customVisibilityHandler = {
          show,
          close,
          visible:
            selectedAppointment?.id === appointment.id &&
            mobileStatusOptionsVisibilityHandler.visible,
          setVisible: (visible) => (visible ? show() : close()),
        };

        return (
          <AppointmentStatusSelector
            appointment={appointment}
            visibilityHandler={customVisibilityHandler}
            onCompleted={() => {
              void refetchQueriesHandler();
            }}
          />
        );
      },
      [
        selectedAppointment?.id,
        mobileStatusOptionsVisibilityHandler,
        refetchQueriesHandler,
      ],
    );

    const getAppointmentDate = (appointment: Schedule) => {
      const { startAt, status, isAllDay = false } = appointment;
      const isOverdue =
        ['SCHEDULED', 'RESCHEDULED'].includes(status) &&
        isPast(ensureDate(startAt)) &&
        !isAllDay;

      return (
        <TableLayoutDate
          date={startAt}
          isAllDay={isAllDay}
          isOverdue={isOverdue}
          testId="appointments-table-cell-date"
        />
      );
    };

    const getPriorityPicker = useCallback(
      (appointment: Schedule) => {
        return (
          <AppointmentPriorityPicker
            appointmentId={appointment.id}
            priority={appointment.priority}
            mobileVisibilityHandler={mobilePriorityOptionsVisibilityHandler}
            isMobileTargetAppointment={selectedAppointment?.id === appointment.id}
            onPriorityUpdated={async () => {
              await refetchQueriesHandler();
            }}
          />
        );
      },
      [
        mobilePriorityOptionsVisibilityHandler,
        refetchQueriesHandler,
        selectedAppointment?.id,
      ],
    );

    const columns: TableColumns<Schedule>[] = breakpointUpMd
      ? [
          {
            title: (
              <TableColumnTitle data-lgg-id="appointments-table-column-header-title">
                {t('appointments:tableTitles.appointment')}
              </TableColumnTitle>
            ),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            showSorterTooltip: false,
            width: '100%',
            contentMaxWidth: '450px',
            contentMinWidth: '350px',
            defaultSortOrder: getDefaultSortOrder(sortKey === 'title', sortDirection),
            render: (title, appointment) => (
              <FlexRow>
                {getPriorityPicker(appointment)}
                <AppointmentTitle
                  data-lgg-id="appointments-table-cell-title"
                  as={Link}
                  to={getScheduleModalUrl(appointment.id)}
                >
                  {title}
                </AppointmentTitle>
              </FlexRow>
            ),
          },
          {
            title: (
              <TableColumnTitle data-lgg-id="appointments-table-column-header-date">
                {t('appointments:tableTitles.date')}
              </TableColumnTitle>
            ),
            dataIndex: 'startAt',
            key: 'startAt',
            contentMaxWidth: '65px',
            contentMinWidth: '65px',
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: getDefaultSortOrder(sortKey === 'startAt', sortDirection),
            render: (startAt, appointment) => getAppointmentDate(appointment),
          },
          {
            title: (
              <TableColumnTitle data-lgg-id="appointments-table-column-header-contact">
                {t('appointments:tableTitles.contact')}
              </TableColumnTitle>
            ),
            dataIndex: 'contact',
            key: 'contact',
            contentMaxWidth: '300px',
            contentMinWidth: '200px',
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: getDefaultSortOrder(sortKey === 'contact', sortDirection),
            render: (contact, appointment, index) => (
              <ContactLabel
                contact={contact}
                testId="appointments-table-cell-contact"
                popoverPlacement={index === 0 ? 'bottomLeft' : 'topLeft'}
                marginSize={10}
              />
            ),
          },
          ...(isManager
            ? ([
                {
                  title: (
                    <TableColumnTitle data-lgg-id="appointments-table-column-header-assignee">
                      {t('appointments:tableTitles.assignee')}
                    </TableColumnTitle>
                  ),
                  dataIndex: 'user',
                  key: 'user',
                  contentMaxWidth: '300px',
                  contentMinWidth: '200px',
                  sorter: true,
                  showSorterTooltip: false,
                  defaultSortOrder: getDefaultSortOrder(
                    sortKey === 'user',
                    sortDirection,
                  ),
                  render: (user: User, appointment) => (
                    <AssigneeLabel
                      assignee={user}
                      onClick={() => history.push(getScheduleModalUrl(appointment.id))}
                      data-lgg-id="appointments-table-cell-assignee"
                    />
                  ),
                },
              ] as TableColumns<Schedule>[])
            : []),

          {
            title: (
              <TableColumnTitle data-lgg-id="appointments-table-column-header-status">
                {t('appointments:tableTitles.status')}
              </TableColumnTitle>
            ),
            dataIndex: 'status',
            key: 'status',
            contentMaxWidth: '153px',
            contentMinWidth: '153px',
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: getDefaultSortOrder(sortKey === 'status', sortDirection),
            render: (status, appointment) => getAppointmentStatusSelector(appointment),
          },
        ]
      : [
          {
            title: '',
            dataIndex: '',
            wrapContent: true,
            width: '100%',
            contentMaxWidth: '100%',
            key: 'title',
            render: (appointment: Schedule) => {
              const { title, contact, user } = appointment;

              return (
                <FlexRow
                  onClick={() => {
                    setSelectedAppointment(appointment);
                    appointmentOptionsVisibilityHandler.show();
                  }}
                >
                  <MobileTableItemMainContent>
                    <AppointmentTitle>{title}</AppointmentTitle>
                    {isManager && (
                      <ItemDescription
                        title={t('appointments:tableTitles.assignedTo')}
                        description={user?.fullName}
                      />
                    )}
                    <FlexRow>
                      {getPriorityPicker(appointment)}
                      <ItemDescription
                        title={t('appointments:tableTitles.date')}
                        description={getAppointmentDate(appointment)}
                      />
                    </FlexRow>
                    <MobileTableItemBottomContent>
                      {getAppointmentStatusSelector(appointment)}
                      {contact && <ContactLabel contact={contact} />}
                    </MobileTableItemBottomContent>
                  </MobileTableItemMainContent>
                  <TableArrowIcon type="arrowNextNoPadding" />
                </FlexRow>
              );
            },
          },
        ];

    return (
      <>
        <Table
          rowKey="id"
          data-lgg-id="appointments-table"
          columns={columns}
          tableLayout="auto"
          loading={false}
          dataSource={appointments}
          pagination={false}
          showHeader={breakpointUpMd}
          scroll={{ x: breakpointUpMd ? 1098 : undefined }}
          onChange={onChange}
          rowClassName={(record, index) =>
            `appointments-table-row appointment-row-${
              record.id
            } appointment-row-position-${index + 1}`
          }
        />
        <AppointmentOptionsBottomDrawer
          onClose={appointmentOptionsVisibilityHandler.close}
          selectedAppointment={selectedAppointment}
          visible={appointmentOptionsVisibilityHandler.visible}
          mobileStatusOptionsVisibilityHandler={mobileStatusOptionsVisibilityHandler}
          mobilePriorityOptionsVisibilityHandler={mobilePriorityOptionsVisibilityHandler}
        />
        <AppointmentStatusDrawerSelectorStyles />
      </>
    );
  },
);

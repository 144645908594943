import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { Fab } from 'src/components/general/button/icon-button';
import { AddNewContextMenu } from 'src/components/general/display/add-new-context-menu';
import { ContextMenuItem } from 'src/components/general/display/context-menu';
import { Icon } from 'src/components/general/icon';
import { AddButtonProps } from 'src/components/layout/authenticated-layout/header/add-new';
import { FlexRow } from 'src/components/layout/flex-row';
import { useVisible } from 'src/hooks/use-visible';
import { overlayShapeStyles } from 'src/theme/sub-themes/overlays-theme';

const PopoverTitle = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.32px;
  margin: 10px 6px 10px;
  width: 100%;
`;

const CloseButton = styled(Icon)`
  background-color: transparent;
  position: relative;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
  position: relative;
  border-radius: 26px;
  color: ${(props) => props.theme.colors.base.lightBlueGrey};
  border: none;
  line-height: 26px;
  outline: none;
  padding: 0;
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -ms-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -ms-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -ms-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);

  svg {
    display: block;
    width: 14px;
    height: 14px;
  }
`;

const ContextMenuStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  .ant-dropdown.context-menu {
    ${overlayShapeStyles};

    &.header-add-new-popover {
      min-width: 220px;
      padding: 0;
    }
  }
`;

export const AddButtonDesktop = memo(({ menuActions, extraActions }: AddButtonProps) => {
  const { t } = useTranslation(['header']);
  const { show, close, visible, setVisible } = useVisible();

  const ButtonWithRef = React.forwardRef(() => (
    <Fab
      onClick={show}
      icon="plus"
      size="big"
      data-lgg-id="layout-header-add-new-overlay-menu-trigger"
    />
  ));

  const getUpdatedMenuItems = useCallback(
    (menuItem: ContextMenuItem): ContextMenuItem => ({
      ...menuItem,
      onClick: (e) => {
        menuItem.onClick?.(e);
        close();
      },
    }),
    [close],
  );

  return (
    <>
      <ContextMenuStyle />
      <Dropdown
        overlayClassName="context-menu header-add-new-popover"
        overlay={
          <AddNewContextMenu
            groups={[
              menuActions.map(getUpdatedMenuItems),
              extraActions.map(getUpdatedMenuItems),
            ]}
            header={
              <FlexRow data-lgg-id="layout-header-add-new-overlay-menu">
                <PopoverTitle>{t('header:addNew.title')}:</PopoverTitle>
                <CloseButton
                  onClick={close}
                  type="plus"
                  data-lgg-id="layout-header-add-new-overlay-menu-close-icon"
                />
              </FlexRow>
            }
          />
        }
        trigger={['click']}
        onVisibleChange={setVisible}
        visible={visible}
        align={{
          offset: [0, -44],
        }}
        placement="bottomRight"
      >
        <ButtonWithRef />
      </Dropdown>
    </>
  );
});

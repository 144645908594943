import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { match } from 'ts-pattern';
import {
  ContactStage,
  ContactWhereInput,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  AdvancedDateFilterType,
  matchAdvancedDateInputTypeExhaustive,
} from '@lgg/isomorphic/utils/match-advanced-date-input';
import { BirthdayInputDateType } from '@lgg/isomorphic/utils/match-birthday-input';
import { BasePageQueryParams, TableSortData } from 'src/components/general/table-helpers';
import { ContactFiltersFormValues } from 'src/components/pages/contacts/components/contact-filters';
import {
  CONTACT_BLOCKING_FIELDS,
  CORE_PAGE_INFO_FIELDS,
} from 'src/components/providers/apollo-provider-provider';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import {
  LEGACY_DATE_PARAM_FORMAT,
  viewCodeQueryParamKey,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { ensureArray } from 'src/utils/ensure-array';

export const CONTACTS = gql`
  ${CORE_PAGE_INFO_FIELDS}
  ${CONTACT_BLOCKING_FIELDS}
  query GetContacts(
    $institutionId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: ContactOrderByInput
    $where: ContactWhereInput
  ) {
    contacts(
      institutionId: $institutionId
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      where: $where
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          label
          primaryEmail
          ...ContactBlockingFieldsFragment
          primaryPhone {
            national
            e164
          }
          lastContactInteraction {
            id
            occurredAt
          }
          tags {
            id
            name
            isActive
          }
          interest
          assignee {
            id
            fullName
            avatar {
              color
              initials
            }
            role {
              id
              name
            }
          }
          status {
            id
            name
          }
          stage {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const CONTACTS_QUERY_PAGE_SIZE = 50;

export const sortInputResolver = (sortData: TableSortData) => {
  const { key, direction = 'DESC' } = sortData;

  switch (key) {
    case 'lastContactInteraction': {
      return {
        lastContactInteraction: {
          occurredAt: direction,
        },
      };
    }
    case 'assignee': {
      return {
        assignee: {
          firstName: direction,
        },
      };
    }
    case 'status': {
      return {
        stage: {
          id: direction,
        },
      };
    }
    default: {
      return {
        [key]: direction,
      };
    }
  }
};

export type ContactsPageAdvanceQueryParams = {
  interest?: string;
  city?: string;
  assigned_unassigned?: string;
  contact?: string;
  agent?: string;
  date_from?: string;
  date_to?: string;
  last_interaction?: number | 'NEVER';
  lead_stage?: string[];
  lead_status?: string[];
  campaign?: string;
  tag?: string[];
  source?: string;
  last_interaction_at?: {
    type: AdvancedDateFilterType;
    time_unit?: string;
    from?: string;
    to?: string;
  };
  birthday?: {
    type?: BirthdayInputDateType;
    month?: string;
    day?: string;
  };
  blocked?: string;
};

export type ContactsPageQueryParams = BasePageQueryParams<
  'custom' | 'default',
  ContactsPageAdvanceQueryParams
>;

export const useViewCodeResolver = (
  viewCode: ContactsPageQueryParams['view-code'],
  contactStages: ContactStage[],
) => {
  return (rawQueryParams: ContactsPageQueryParams): ContactsPageQueryParams => {
    const viewCode = rawQueryParams[viewCodeQueryParamKey];

    if (viewCode === 'default') {
      const defaultContactStatusIds = contactStages.flatMap((stage) =>
        stage.statuses.filter((s) => s.winPercentage !== 0).map((s) => s.id),
      );

      return {
        ...rawQueryParams,
        q: {
          advanced: {
            lead_status: defaultContactStatusIds,
          },
        },
      };
    }

    return rawQueryParams;
  };
};

export const useWhereInputResolver = (viewCode: ContactsPageQueryParams['view-code']) => {
  const { subHours, startOfDay, endOfDay, format, parseISO } = useDateHelpers();

  const nowDate = useMemo(
    () => new Date(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewCode],
  );

  return (params: ContactsPageQueryParams) => {
    if (!params.q?.advanced) {
      return undefined;
    }

    const whereClauses: ContactWhereInput[] = [];
    const { advanced } = params.q;

    if (advanced.interest) {
      whereClauses.push({ interest: { _contains: advanced.interest } });
    }

    if (advanced.city) {
      whereClauses.push({ city: { _contains: advanced.city } });
    }

    if (advanced.assigned_unassigned) {
      if (advanced.assigned_unassigned === '1') {
        whereClauses.push({ assignee: { _eq: null } });
      } else if (advanced.assigned_unassigned === '0') {
        whereClauses.push({ assignee: { _ne: null } });
      }
    }

    if (advanced.contact) {
      whereClauses.push({
        matchKeywords: {
          _all: advanced.contact,
        },
      });
    }

    if (advanced.date_from) {
      whereClauses.push({
        createdAt: { _gte: startOfDay(parseISO(advanced.date_from)) },
      });
    }

    if (advanced.date_to) {
      whereClauses.push({
        createdAt: { _lte: endOfDay(parseISO(advanced.date_to)) },
      });
    }

    if (advanced.agent) {
      whereClauses.push({ assignee: { id: { _eq: parseInt(advanced.agent) } } });
    }

    if (advanced.last_interaction) {
      const lastInteractionFilterValue = advanced.last_interaction;

      if (lastInteractionFilterValue === 'NEVER') {
        whereClauses.push({
          lastContactInteraction: {
            createdAt: {
              _eq: null,
            },
          },
        });
      } else {
        whereClauses.push({
          lastContactInteraction: {
            createdAt: {
              _lte: format(
                subHours(nowDate, lastInteractionFilterValue),
                LEGACY_DATE_PARAM_FORMAT,
              ),
            },
          },
        });
      }
    }

    if (advanced.campaign) {
      whereClauses.push({ campaign: { id: { _eq: parseInt(advanced.campaign) } } });
    }

    if (advanced.source) {
      whereClauses.push({ source: { id: { _eq: parseInt(advanced.source) } } });
    }

    if (advanced.lead_stage) {
      whereClauses.push({
        stage: {
          id: {
            _in: ensureArray<string>(advanced.lead_stage),
          },
        },
      });
    }

    if (advanced.lead_status) {
      whereClauses.push({
        status: {
          id: {
            _in: ensureArray<string>(advanced.lead_status),
          },
        },
      });
    }

    if (advanced.tag) {
      whereClauses.push({
        tags: {
          id: {
            _in: ensureArray<string>(advanced.tag).map((val) => parseInt(val)),
          },
        },
      });
    }

    if (advanced.last_interaction_at?.type) {
      const {
        type: filterValue,
        from: fromDate,
        to: toDate,
        time_unit: timeUnit,
      } = advanced.last_interaction_at;

      matchAdvancedDateInputTypeExhaustive(filterValue, {
        range: () => {
          if (fromDate && toDate) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  _isBetween: {
                    from: startOfDay(parseISO(fromDate)),
                    to: endOfDay(parseISO(toDate)),
                  },
                },
              },
            });
          }
        },
        exact: () => {
          if (fromDate) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  _isExactDate: parseISO(fromDate),
                },
              },
            });
          }
        },
        relativeWithUnit: (value) => {
          if (timeUnit) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  [value]: Number(timeUnit),
                },
              },
            });
          }
        },
        relative: (value) => {
          whereClauses.push({
            lastContactInteraction: {
              createdAt: {
                [value]: true,
              },
            },
          });
        },
        empty: () => {},
      });
    }

    if (advanced.birthday?.type) {
      const { type: filterValue, day: exactDay, month: exactMonth } = advanced.birthday;

      match(filterValue)
        .with('_isExactDay', () => {
          if (exactDay && exactMonth) {
            whereClauses.push({
              birthdate: {
                _isExactDay: {
                  day: Number(exactDay),
                  month: Number(exactMonth),
                },
              },
            });
          }
        })
        .with('_isExactMonth', () => {
          if (exactMonth) {
            whereClauses.push({
              birthdate: {
                _isExactMonth: Number(exactMonth),
              },
            });
          }
        })
        .otherwise((type) => {
          whereClauses.push({
            birthdate: {
              [type]: true,
            },
          });
        });
    }

    if (advanced.blocked) {
      if (advanced.blocked === '1') {
        whereClauses.push({ isBlocked: { _eq: true } });
      } else if (advanced.blocked === '0') {
        whereClauses.push({ isBlocked: { _eq: false } });
      }
    }

    return whereClauses.length ? { _and: whereClauses } : undefined;
  };
};

export const filtersStateResolver = (
  queryParams: ContactsPageQueryParams,
): Partial<ContactFiltersFormValues> | undefined => {
  if (!queryParams.q?.advanced) {
    return undefined;
  }

  const { advanced } = queryParams.q;
  const values: Partial<ContactFiltersFormValues> = {};

  if (advanced.interest) {
    values.interest = advanced.interest;
  }

  if (advanced.city) {
    values.city = advanced.city;
  }

  if (advanced.assigned_unassigned) {
    values.assigned_unassigned = parseInt(advanced.assigned_unassigned);
  }

  if (advanced.agent) {
    values.agent = parseInt(advanced.agent);
  }

  if (advanced.date_from) {
    values.date_from = advanced.date_from;
  }

  if (advanced.date_to) {
    values.date_to = advanced.date_to;
  }

  if (advanced.contact) {
    values.contact = advanced.contact;
  }

  if (advanced.last_interaction) {
    values.last_interaction =
      advanced.last_interaction === 'NEVER'
        ? advanced.last_interaction
        : Number(advanced.last_interaction);
  }

  if (advanced.campaign) {
    values.campaign = parseInt(advanced.campaign);
  }

  if (advanced.source) {
    values.source = parseInt(advanced.source);
  }

  if (advanced.lead_stage) {
    values.lead_stage = [...advanced.lead_stage];
  }

  if (advanced.lead_status) {
    values.lead_status = [...advanced.lead_status];
  }

  if (advanced.tag) {
    values.tag = [...advanced.tag];
  }

  if (advanced.last_interaction_at) {
    values.last_interaction_at = {
      type: advanced.last_interaction_at.type,
    };

    if (advanced.last_interaction_at.time_unit) {
      values.last_interaction_at.time_unit = Number(
        advanced.last_interaction_at.time_unit,
      );
    }

    if (advanced.last_interaction_at.from) {
      values.last_interaction_at.from = advanced.last_interaction_at.from;
    }

    if (advanced.last_interaction_at.to) {
      values.last_interaction_at.to = advanced.last_interaction_at.to;
    }
  }

  if (advanced.birthday) {
    values.birthday = {
      type: advanced.birthday.type,
    };

    if (advanced.birthday.day) {
      values.birthday.day = Number(advanced.birthday.day);
    }

    if (advanced.birthday.month) {
      values.birthday.month = Number(advanced.birthday.month);
    }
  }

  if (advanced.blocked) {
    values.blocked = parseInt(advanced.blocked);
  }

  return values;
};

export const CONTACT_STAGES = gql`
  query GetContactStagesForContactPage {
    contactStages {
      id
      name
      slug
      statuses {
        id
        name
        winPercentage
      }
    }
  }
`;

export const useContactStages = () => {
  const handleGraphQLError = useHandleGraphQLError();

  return useQuery<Pick<Query, 'contactStages'>>(CONTACT_STAGES, {
    onError: handleGraphQLError,
  });
};

import { ContactStage } from '@lgg/isomorphic/types/__generated__/graphql';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { GroupedSelectOption } from 'src/components/general/inputs/select/select';

export const stageToStatusSelectGroupOptionMapper = (
  stage: ContactStage,
): GroupedSelectOption<string> => {
  return {
    label: stage.name,
    options: stage.statuses.map((status) => entityToSelectOptionMapper(status)),
  };
};

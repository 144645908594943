import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationDeleteTaskArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const DELETE_TASK_MUTATION = gql`
  mutation DeleteTaskMutation($input: DeleteTaskInput!) {
    deleteTask(input: $input) {
      success
    }
  }
`;

export const useDeleteTask = () => {
  const handleGraphQLError = useHandleGraphQLError();

  return useMutation<Pick<Mutation, 'deleteTask'>, MutationDeleteTaskArgs>(
    DELETE_TASK_MUTATION,
    {
      onError: handleGraphQLError,
    },
  );
};

import React, { memo, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useHandleActionButtons } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/table/use-handle-action-buttons';
import { useHandleDataTable } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/table/use-handle-data-table';
import { useHandlePagination } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/table/use-handle-pagination';
import { LegacyApiService } from 'src/services/http/legacy-api.service';

const Container = styled.div`
  ${up('md')} {
    margin: 30px;
  }

  .table-top {
    width: 100%;
  }
`;

export const LegacyViewContent = memo<{
  content: HTMLDivElement;
  breadcrumbActions: HTMLDivElement | null;
}>(({ content, breadcrumbActions }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const legacyApiService = useService(LegacyApiService);

  useLayoutEffect(() => {
    if (content) {
      const div = containerRef.current;
      if (!div) {
        throw new Error('view element not present');
      }
      content.style.display = 'block';

      const interceptLinkClicks = async (e: MouseEvent) => {
        //Finds an link node. Will return itself or the matching ancestor.
        //Example use case: <a href="/i/company/1/accounts/groups/"><span>Manage Groups</span></a>
        const linkTarget = (e.target as HTMLElement)?.closest('a');

        if (
          linkTarget &&
          !e.defaultPrevented &&
          linkTarget.closest('div[id^="ym-legacy-section-"]') !== null
        ) {
          const url = new URL(linkTarget.href, new URL(window.location.href).origin);
          const path = `${url.pathname}${url.search}${url.hash}`;

          //If the anchor link is empty then stop handling the event
          if (!linkTarget.href) {
            return;
          }

          //If the target has the download attribute then stop handling the event
          if (linkTarget.hasAttribute('download')) {
            if (
              url.host === new URL(window.location.href).host ||
              url.host === new URL(import.meta.env.VITE_USER_WEB_APP_BASE_URL).host
            ) {
              e.preventDefault();

              const result = await legacyApiService.get(path, { responseType: 'blob' });

              const blob = result.data;

              // Create blob link to download
              const blobUrl = window.URL.createObjectURL(
                new Blob([blob], { type: blob?.type }),
              );
              const blobLink = document.createElement('a');
              blobLink.href = blobUrl;
              blobLink.style.display = 'none';
              blobLink.setAttribute(
                'download',
                url.pathname.substring(url.pathname.lastIndexOf('/') + 1),
              );

              // Append to html link element page
              document.body.appendChild(blobLink);

              // Start download
              blobLink.click();

              //Releases the object URL
              URL.revokeObjectURL(blobUrl);

              // Clean up and remove the link
              document.body.removeChild(blobLink);
            }

            return;
          }

          //If the target is blank then stop handling the event
          if (linkTarget.target?.toLowerCase() === '_blank') {
            return;
          }

          if (
            url.host !== new URL(window.location.href).host &&
            url.host !== new URL(import.meta.env.VITE_USER_WEB_APP_BASE_URL).host
          ) {
            //If the link host is different from browser URL then stop handling the event
            return;
          }

          history.push(path);
          e.preventDefault();
        }
      };
      document.addEventListener('click', interceptLinkClicks);

      div.appendChild(content);

      requestAnimationFrame(() => {
        YAMMON.WidgetManager.reload(); //Reload yammon widgets javascripts
        window.onTableInit(); //Reload yammon tables javascripts
      });

      return () => {
        document.removeEventListener('click', interceptLinkClicks);
        div.removeChild(content);
      };
    }
  }, [content, history, legacyApiService]);

  useHandleActionButtons(content, breadcrumbActions);
  useHandleDataTable(content);
  useHandlePagination(content);

  return (
    <>
      <Container data-lgg-id="legacy-view-container" ref={containerRef} />
    </>
  );
});

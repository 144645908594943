import React, { memo, Suspense } from 'react';
import Spin from 'antd/lib/spin';
import styled from 'styled-components';
import { Center } from 'src/components/layout/center';
import { Expand } from 'src/components/layout/expand';

const StyledExpand = styled(Expand)`
  height: 100%;
`;

export const LoadingIndicator = memo(({ ...rest }) => {
  return (
    <StyledExpand {...rest}>
      <Center>
        <Spin tip="Loading..." />
      </Center>
    </StyledExpand>
  );
});

export const LoadingFallback = memo(({ children }) => {
  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
});

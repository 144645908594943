import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';

const ModalErrorIcon = styled(Icon)`
  align-items: center;
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  display: flex;
  height: 90px;
  justify-content: center;
  margin-bottom: 30px;
  width: 90px;

  svg {
    height: 30px;
    width: 30px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const ModalErrorTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 27px;
  margin: 0 0 10px;
  text-align: center;
`;

const ModalErrorDescription = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  text-align: center;
`;

const ModalErrorContainer = styled(FlexColumn)`
  align-items: center;
`;

type ModalErrorContentProps = {
  title: string;
  description: string;
  icon: string;
};
export const ModalErrorContent = ({
  icon,
  title,
  description,
}: ModalErrorContentProps) => {
  return (
    <ModalErrorContainer>
      <ModalErrorIcon type={icon} />
      <ModalErrorTitle>{title}</ModalErrorTitle>
      <ModalErrorDescription>{description}</ModalErrorDescription>
    </ModalErrorContainer>
  );
};

import React, { forwardRef, memo, useCallback, useEffect, useRef } from 'react';
import { primaryInput as devicePrimaryInput } from 'detect-it';
import PerfectScrollbar from 'perfect-scrollbar';
import styled from 'styled-components';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

type ScrollbarProps = WithClassname & {
  children: React.ReactNode;
  containerRef?: (container: HTMLElement) => void;
  onClick?: VoidFunction;
};

const StyledNativeScrollbar = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;

export const Scrollbar = memo<ScrollbarProps>(({ children, ...props }) => {
  const Component = devicePrimaryInput === 'touch' ? NativeScrollbar : VirtualScrollbar;

  return (
    <Component className={`scrollbar ${props.className || ''}`} {...props}>
      {children}
    </Component>
  );
});

export const NativeScrollbar = memo<ScrollbarProps>(
  ({ children, containerRef, ...props }) => {
    const scrollbarDOMRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (containerRef) {
        containerRef(scrollbarDOMRef.current as HTMLElement);
      }
    }, [containerRef]);

    return (
      <StyledNativeScrollbar {...props} ref={scrollbarDOMRef}>
        {children}
      </StyledNativeScrollbar>
    );
  },
);

type PerfectScrollbarRef = { updateScroll: VoidFunction };

export const VirtualScrollbar = memo<ScrollbarProps>(({ children, ...props }) => {
  const scrollbarRef = useRef<PerfectScrollbarRef | null>(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      window.requestAnimationFrame(() => {
        scrollbarRef.current?.updateScroll();
      });
    }
  });

  return (
    <StyledReactPerfectScrollBar {...props} ref={(ref) => (scrollbarRef.current = ref)}>
      {children}
    </StyledReactPerfectScrollBar>
  );
});

const handlerNameByEvent = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd',
};
Object.freeze(handlerNameByEvent);

interface ReactPerfectScrollBarProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * class name on container
   */
  className?: string;

  /**
   * style on container
   */
  style?: React.CSSProperties;

  /**
   * perfect-scrollbar init options
   */
  options?: PerfectScrollbar.Options;

  /**
   * get the container ref
   */
  containerRef?: (container: HTMLElement) => void;

  /**
   * fires when the y-axis is scrolled in either direction.
   */
  onScrollY?: (container: HTMLElement) => void;

  /**
   * fires when the x-axis is scrolled in either direction.
   */
  onScrollX?: (container: HTMLElement) => void;

  /**
   * fires when scrolling upwards.
   */
  onScrollUp?: (container: HTMLElement) => void;

  /**
   * fires when scrolling downwards.
   */
  onScrollDown?: (container: HTMLElement) => void;

  /**
   * fires when scrolling to the left.
   */
  onScrollLeft?: (container: HTMLElement) => void;

  /**
   * fires when scrolling to the right.
   */
  onScrollRight?: (container: HTMLElement) => void;

  /**
   * fires when scrolling reaches the start of the y-axis.
   */
  onYReachStart?: (container: HTMLElement) => void;

  /**
   * fires when scrolling reaches the end of the y-axis (useful for infinite scroll).
   */
  onYReachEnd?: (container: HTMLElement) => void;

  /**
   * fires when scrolling reaches the start of the x-axis.
   */
  onXReachStart?: (container: HTMLElement) => void;

  /**
   * fires when scrolling reaches the end of the x-axis.
   */
  onXReachEnd?: (container: HTMLElement) => void;

  /**
   * component name
   */
  component?: string;

  /**
   * fires when the perfect-scrollbar is synced
   */
  onSync?: (ps: PerfectScrollbar) => void;
}

const ReactPerfectScrollBar = forwardRef<PerfectScrollbarRef, ReactPerfectScrollBarProps>(
  (props, ref) => {
    const {
      className = '',
      style,
      options,
      containerRef = () => {},
      onScrollY,
      onScrollX,
      onScrollUp,
      onScrollDown,
      onScrollLeft,
      onScrollRight,
      onYReachStart,
      onYReachEnd,
      onXReachStart,
      onXReachEnd,
      component = 'div',
      onSync = (ps) => ps.update(),
      children,
      ...remainProps
    } = props;
    const Comp: any = component;

    const _container = useRef<HTMLDivElement | null>(null);
    const _ps = useRef<PerfectScrollbar | null>(null);
    const _handlerByEvent = useRef({});

    const handleRef = (ref) => {
      _container.current = ref;
      containerRef?.(ref);
    };

    const updateScroll = useCallback(() => {
      if (!_ps.current) {
        return;
      }

      onSync?.(_ps.current);
    }, [onSync]);

    const _updateEventHook = useCallback(
      (prevProps = {}) => {
        Object.keys(handlerNameByEvent).forEach((key) => {
          const callback = props[handlerNameByEvent[key]];
          const prevCallback = prevProps[handlerNameByEvent[key]];
          if (callback !== prevCallback) {
            if (prevCallback) {
              const prevHandler = _handlerByEvent.current[key];
              _container.current?.removeEventListener(key, prevHandler, false);
              _handlerByEvent.current[key] = null;
            }
            if (callback) {
              const handler = () => callback(_container.current);
              _container.current?.addEventListener(key, handler, false);
              _handlerByEvent.current[key] = handler;
            }
          }
        });
      },
      [props],
    );

    const _updateClassName = useCallback(() => {
      const className = props.className;

      const psClassNames = _container.current?.className
        .split(' ')
        .filter((name) => name.match(/^ps([-_].+|)$/))
        .join(' ');

      if (_container.current) {
        _container.current.className = `scrollbar-container${
          className ? ` ${className}` : ''
        }${psClassNames ? ` ${psClassNames}` : ''}`;
      }
    }, [props.className]);

    useEffect(() => {
      const options = props.options || {};

      // Prevent issue with horizontal scroll on Firefox because of decimal width
      if (options.scrollXMarginOffset === undefined) {
        options.scrollXMarginOffset = 1;
      }

      // Prevent issue with horizontal scroll on Firefox because of decimal height
      if (options.scrollYMarginOffset === undefined) {
        options.scrollYMarginOffset = 1;
      }

      if (!_container.current) {
        return;
      }

      _ps.current = new PerfectScrollbar(_container.current, options);

      if (typeof ref === 'function') {
        ref?.({
          updateScroll,
        });
      }

      // hook up events
      _updateEventHook();
      _updateClassName();
      // eslint-disable-next-line react-hooks/exhaustive-deps

      return () => {
        Object.keys(_handlerByEvent.current).forEach((key) => {
          const value = _handlerByEvent.current[key];

          if (value) {
            _container.current?.removeEventListener(key, value, false);
          }
        });
        _handlerByEvent.current = {};
        _ps.current?.destroy();
      };
    }, [ref, updateScroll, _updateClassName, _updateEventHook, props.options]);

    useEffect(() => {
      _updateEventHook(props);

      updateScroll();

      if (props.className !== className) {
        _updateClassName();
      }
    }, [_updateClassName, _updateEventHook, className, props, updateScroll]);

    return (
      <Comp style={style} ref={handleRef} {...remainProps}>
        {children}
      </Comp>
    );
  },
);

const StyledReactPerfectScrollBar = styled(ReactPerfectScrollBar)`
  position: relative;
  height: 100%;

  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    background-color: rgba(233, 233, 233, 0.7);
    opacity: 1;
  }
`;

import PubSub from 'pubsub-js';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';

export type ConversationUpdatedData = {
  id: string;
};

export type ShowUnblockContactModal = {
  contact: Contact;
};

export type EventCallback<T extends keyof EventDataMapping> = (
  topic: T,
  data: EventDataMapping[T],
) => void;

export type EventDataMapping = {
  CONVERSATION_UPDATED: ConversationUpdatedData;
  SHOW_UNBLOCK_CONTACT_MODAL: ShowUnblockContactModal;
};

export const publishEvent = <T extends keyof EventDataMapping>(
  topic: T,
  data: EventDataMapping[T],
) => {
  PubSub.publish(topic, data);
};

export const subscribeToEvent = PubSub.subscribe;
export const unsubscribeFromEvent = PubSub.unsubscribe;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'src/components/general/icon';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(Icon)`
  animation: ${rotate} 1s linear infinite;
`;

export const RotatingLoadingIcon = (props) => (
  <RotatingIcon {...props} type="iconLoader" />
);

import React, { memo } from 'react';
import AntSkeleton, { SkeletonProps } from 'antd/lib/skeleton';
import { SkeletonButtonProps } from 'antd/lib/skeleton/Button';
import styled from 'styled-components';

const StyledSkeleton = styled(AntSkeleton)`
  &.ant-skeleton {
    .ant-skeleton-title,
    .ant-skeleton-paragraph li {
      border-radius: 8px;
    }

    &.ant-skeleton-active {
      .ant-skeleton-header .ant-skeleton-avatar,
      .ant-skeleton-content .ant-skeleton-title,
      .ant-skeleton-content .ant-skeleton-paragraph li {
        background: linear-gradient(
          90deg,
          ${({ theme }) => theme.colors.koala} 25%,
          ${({ theme }) => theme.colors.alabaster} 37%,
          ${({ theme }) => theme.colors.koala} 63%
        );
        background-size: 400% 100%;
      }
    }
  }
`;

const StyledSkeletonButton = styled(StyledSkeleton.Button)`
  &.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.koala} 25%,
      ${({ theme }) => theme.colors.alabaster} 37%,
      ${({ theme }) => theme.colors.koala} 63%
    );
    background-size: 400% 100%;
  }
`;

export const Skeleton = memo<SkeletonProps>(({ children, ...rest }) => {
  return <StyledSkeleton {...rest}>{children}</StyledSkeleton>;
});

export const SkeletonButton = memo<SkeletonButtonProps>(({ children, ...rest }) => {
  return <StyledSkeletonButton {...rest}>{children}</StyledSkeletonButton>;
});

import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const TableLayoutPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 100%;

  ${up('md')} {
    padding: 30px 30px 0;
  }
`;

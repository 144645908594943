import React, { useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import { Query, QueryUsersArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  UsersRefetchType,
  COMPANY_USERS,
} from 'src/components/domain/users/hooks/use-company-users';
import { useCompanyUsersListForSelect } from 'src/components/domain/users/hooks/use-company-users-list-for-select';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

type CompanyUserSelectProps<IsMulti extends boolean> = {
  selectedUsersIds: number[];
  isMulti: IsMulti;
  onChange: ValueChanged<OnChangeValue<SelectOption<number>, IsMulti>>;
  isCreatable?: boolean;
  name: string;
  placeholder?: string;
  mobileLabel?: string;
  label?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  refetchRef?: (refetch: UsersRefetchType) => void;
};

export const CompanyUsersSelect = <IsMulti extends boolean>(
  props: CompanyUserSelectProps<IsMulti>,
) => {
  const currentInstitution = useCurrentInstitution();
  const {
    isMulti,
    selectedUsersIds,
    isLoading,
    isCreatable,
    isDisabled,
    refetchRef,
    ...rest
  } = props;

  const [fetchExtraUsers] = useLazyQuery<Pick<Query, 'users'>, Partial<QueryUsersArgs>>(
    COMPANY_USERS,
  );
  const [cachedUserOptions, setCachedUserOptions] = useState<SelectOption<number>[]>([]);

  // On first render we load the selected users, for cases where the input first query
  // does not return the already selected users
  useEffect(() => {
    void (async () => {
      if (!selectedUsersIds.length) {
        return;
      }

      const { data } = await fetchExtraUsers({
        variables: {
          institutionId: currentInstitution.id,
          where: {
            id: { _in: selectedUsersIds },
            isActive: { _eq: true },
          },
        },
      });

      const nodes = getNodesFromConnection(data?.users);

      if (nodes) {
        setCachedUserOptions((prevState) => {
          return uniqBy(
            [
              ...prevState,
              ...nodes.map((node) =>
                entityToSelectOptionMapper({
                  id: node.id,
                  name: node.fullName,
                }),
              ),
            ],
            'value',
          );
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    options: userOptions,
    groupedOptions,
    loading: loadingUserOptions,
    refetch,
    loadMore,
    hasNextPage,
  } = useCompanyUsersListForSelect({
    isActive: {
      _eq: true,
    },
  });

  useEffect(() => {
    if (userOptions.length) {
      setCachedUserOptions((prevState) => {
        return uniqBy([...prevState, ...userOptions], 'value');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUserOptions]);

  useEffect(() => {
    refetchRef?.(refetch);
  }, [refetch, refetchRef]);

  return (
    <Select
      {...rest}
      isSearchable
      isMulti={isMulti}
      onChange={(value) => {
        rest?.onChange(value);
      }}
      onMenuClose={async () => {
        await refetch({
          where: {
            isActive: { _eq: true },
          },
        });
      }}
      onSearch={async (name) => {
        await refetch({
          where: {
            matchKeywords: { _all: name },
            isActive: { _eq: true },
          },
        });
      }}
      canLoadMore={hasNextPage}
      onLoadMore={loadMore}
      isLoading={loadingUserOptions || isLoading}
      isCreatable={isCreatable}
      isDisabled={isDisabled}
      value={
        selectedUsersIds
          .map((id) => {
            return cachedUserOptions.find((option) => option.value === id);
          })
          .filter(Boolean) as SelectOption<number>[]
      }
      options={groupedOptions}
    />
  );
};

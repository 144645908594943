import React, { memo } from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { icons } from 'src/components/general/icon/icons';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';

const IconsContainer = styled(FlexRow)`
  flex-wrap: wrap;
  width: 100%;

  > * {
    padding: 20px;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #000000;
    }
  }
`;

const IconColumn = styled(FlexColumn)`
  width: 15%;
  align-items: center;
`;

export const DevIconsPage = memo(() => {
  return (
    <IconsContainer>
      {Object.keys(icons).map((icon) => {
        return (
          <IconColumn>
            <>
              <StyledIcon type={icon} />
              {icon}
            </>
          </IconColumn>
        );
      })}
    </IconsContainer>
  );
});

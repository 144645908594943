import { useLayoutEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';

export function DesktopSendKeyboardShortcutPlugin({
  onPressed,
}: {
  onPressed: (editor: LexicalEditor) => void;
}) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onPressed(editor);
      }
    };

    return editor.registerRootListener(
      (rootElement: HTMLElement | null, prevRootElement: HTMLElement | null) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('keydown', onKeyDown, true);
        }
        if (rootElement !== null) {
          rootElement.addEventListener('keydown', onKeyDown, true);
        }
      },
    );
  }, [editor, onPressed]);

  return null;
}

import React, { memo, MouseEventHandler } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ConversationItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';

const BaseOptionIcon = styled(Icon)`
  svg {
    width: 20px;
    height: 20px;
  }

  ${up('md')} {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ContactActionsIcon = styled(BaseOptionIcon)`
  cursor: pointer;
  padding: 5px;
`;

export const MoreOptionsIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
  margin-right: -10px;
  padding: 0 10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
  margin-right: -10px;
  padding: 0 10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

export const PhoneIcon = styled(BaseOptionIcon)`
  cursor: pointer;
  padding: 5px;

  path {
    fill: ${({ theme }) => theme.colors.monk};
  }
`;

export const ContactName = styled.span<{ clickable?: boolean }>`
  color: ${({ theme }) => theme.colors.carbon};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 26px;
  margin-right: 20px;
  min-width: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.smalt};
  }

  ${up('lg')} {
    margin-left: 0;
  }
`;

const MenuOptionLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;
  flex: 1;
`;

export const MenuOptionContainer = styled.div`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0 15px;
  transition: background-color 150ms;
  position: relative;

  &.selected {
    background-color: ${({ theme }) => theme.colors.secondaryTopaz10};

    ${MenuOptionLabel} {
      color: ${({ theme }) => theme.colors.gogo};
    }
  }

  ${up('md')} {
    &:not(.selected):hover {
      background-color: ${({ theme }) => theme.colors.porcelain};
    }
  }

  & + & {
    margin-top: 4px;
  }
`;

const MenuOptionIcon = styled(Icon)`
  margin-right: 10px;
  flex: 0;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

const MenuOptionSelectedIcon = styled(Icon)`
  display: flex;
  justify-content: end;
  margin-left: 14px;
  flex: 0;

  svg {
    position: relative;
    right: -5px;
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

export type MenuOptionProps = {
  icon: string;
  label: string;
  selected?: boolean;
  onClick?: MouseEventHandler;
};

export const MenuOption = memo<MenuOptionProps>(
  ({ label, icon, onClick, selected, ...props }) => {
    return (
      <MenuOptionContainer {...props} className={c({ selected })} onClick={onClick}>
        {icon && <MenuOptionIcon type={icon} />}
        <MenuOptionLabel>{label}</MenuOptionLabel>
        {selected && <MenuOptionSelectedIcon type="checkSave" />}
      </MenuOptionContainer>
    );
  },
);

export const ContactInteractionsListContext = React.createContext<{
  contactInteractions: ConversationItemUnion[];
  conversationId: string;
  virtuosoHandle: VirtuosoHandle | null;
  virtuosoScroller: HTMLElement | Window | null;
}>({
  contactInteractions: [],
  virtuosoHandle: null,
  virtuosoScroller: null,
  conversationId: '',
});

import React, { memo, ReactElement, useCallback, Fragment, useMemo } from 'react';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionFacebookMessenger,
  ContactInteractionInstagram,
  ContactInteractionSms,
  ContactInteractionWhatsapp,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { contactInteractionPatterns } from '@lgg/isomorphic/utils/match-contact-interaction';
import { TextFormatter } from 'src/components/general/text-formatter';
import { AttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/attachment-item';
import {
  BubbleDivider,
  BubbleMessageHeader,
  MessageBubbleWithDirection,
  FailedMessageIndicator,
  RepliedMessageRenderer,
  ReferralItemRenderer,
  ItemMessage,
  InteractionTextContent,
  AutomatedMessageBubbleHeader,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import {
  getFirstReactionFromContactInteractionComponent,
  getMessageComponentReaction,
  ReactionsWrapper,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reactions-wrapper';
import { ContactInteractionDetailsCaretVariant } from './contact-interaction-details';

export type ContactInteractionsWithAttachmentUnion =
  | ContactInteractionFacebookMessenger
  | ContactInteractionSms
  | ContactInteractionWhatsapp
  | ContactInteractionInstagram;

type TextItemProps = {
  contactInteraction: ContactInteractionsWithAttachmentUnion;
  titleBuilder: () => ReactElement;
  testId: string;
  conversationId: string;
  messageSanitizer?: (message: string) => string;
};

export const TextItem = memo<TextItemProps>(
  ({ contactInteraction, conversationId, titleBuilder, testId, messageSanitizer }) => {
    const {
      direction,
      message,
      occurredAt,
      registrationType,
      description,
      attachments,
      id,
      campaign,
      source,
      department,
      participatingEntity,
    } = contactInteraction;

    const fromResource = match(contactInteraction)
      .with(
        P.union(contactInteractionPatterns.sms, contactInteractionPatterns.whatsapp),
        (interaction) => interaction.resourceAddress,
      )
      .otherwise(() => null);

    const contactAddress = match(contactInteraction)
      .with(
        P.union(contactInteractionPatterns.sms, contactInteractionPatterns.whatsapp),
        (interaction) => interaction.contactAddress,
      )
      .otherwise(() => null);

    const showDeliveryStatus = contactInteraction.direction === 'OUTBOUND';
    const showFailedMessageError = useCallback(
      (entity) => showDeliveryStatus && entity?.status === 'FAILED',
      [showDeliveryStatus],
    );

    const messageReaction = getMessageComponentReaction(contactInteraction);
    const baseDetails = useMemo(() => {
      return {
        interactionId: id,
        campaign,
        source,
        conversationId,
        department,
        contactAddress,
        resourceAddress: fromResource,
      };
    }, [campaign, contactAddress, conversationId, department, fromResource, id, source]);

    const renderDescription = (
      params: Pick<ContactInteractionsWithAttachmentUnion, 'direction' | 'description'>,
    ) => {
      const { direction, description } = params;

      return (
        <InteractionTextContent direction={direction}>
          {description}
        </InteractionTextContent>
      );
    };

    const isAutomatedMessage = useMemo(() => {
      return match(participatingEntity)
        .with({ type: 'CALLFLOW' }, () => true)
        .otherwise(() => false);
    }, [participatingEntity]);

    const getAutomatedMessageBubbleTitle = useCallback(() => {
      return match(participatingEntity)
        .with({ type: 'CALLFLOW' }, ({ label }) => (
          <AutomatedMessageBubbleHeader icon="callflow" title={label} />
        ))
        .otherwise(() => null);
    }, [participatingEntity]);

    const renderMessageContent = (
      params: Pick<ContactInteractionsWithAttachmentUnion, 'direction' | 'message'>,
    ) => {
      const { direction, message } = params;

      return (
        <>
          {isAutomatedMessage ? (
            <>
              {getAutomatedMessageBubbleTitle()}
              <BubbleDivider direction={direction} />
            </>
          ) : null}
          <ItemMessage data-lgg-id="text-item-message" direction={direction}>
            {message?.content && (
              <TextFormatter>
                {messageSanitizer ? messageSanitizer(message.content) : message.content}
              </TextFormatter>
            )}
          </ItemMessage>
        </>
      );
    };

    return (
      <Fragment key={contactInteraction.id}>
        {message?.content || registrationType === 'MANUAL' ? (
          <Fragment key={contactInteraction.id}>
            <ReactionsWrapper
              reactions={messageReaction ? [messageReaction] : messageReaction}
              direction={contactInteraction.direction}
            >
              <MessageBubbleWithDirection
                testId={testId}
                direction={direction}
                registrationType={registrationType}
                createdAt={occurredAt}
                details={{ ...baseDetails, message: message?.content ?? description }}
                deliveryStatus={showDeliveryStatus ? message?.status : undefined}
                isNarrow={Boolean(message?.referral)}
                backgroundColor={isAutomatedMessage ? 'secondaryMint20' : undefined}
                caretVariantOverride={
                  isAutomatedMessage
                    ? ContactInteractionDetailsCaretVariant.CLEAR
                    : undefined
                }
              >
                {registrationType === 'MANUAL' ? (
                  <>
                    <BubbleMessageHeader
                      contactInteraction={contactInteraction}
                      title={titleBuilder()}
                    />
                    <BubbleDivider direction={direction} />
                    {renderDescription({ direction, description })}
                  </>
                ) : (
                  <>
                    <RepliedMessageRenderer contactInteraction={contactInteraction} />
                    <ReferralItemRenderer contactInteraction={contactInteraction} />
                    {renderMessageContent({ direction, message })}
                  </>
                )}
              </MessageBubbleWithDirection>
            </ReactionsWrapper>
            {showFailedMessageError(message) && <FailedMessageIndicator {...message} />}
          </Fragment>
        ) : null}
        {[...attachments].reverse().map((attachment) => {
          const reaction = getFirstReactionFromContactInteractionComponent(attachment);

          return (
            <Fragment key={attachment.id}>
              <ReactionsWrapper
                reactions={reaction ? [reaction] : reaction}
                direction={contactInteraction.direction}
              >
                <AttachmentItem
                  contactInteraction={contactInteraction}
                  attachment={attachment}
                  detailsPopoverProps={baseDetails}
                  showDeliveryStatus={showDeliveryStatus}
                />
              </ReactionsWrapper>
              {showFailedMessageError(attachment) && (
                <FailedMessageIndicator {...attachment} />
              )}
            </Fragment>
          );
        })}
      </Fragment>
    );
  },
);

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericHttpErrorPage } from 'src/components/pages/errors/generic-http-error-page';

export const NotFoundPage = memo(() => {
  const { t } = useTranslation(['errors']);

  return (
    <GenericHttpErrorPage
      data-lgg-id="error-page-404"
      errorCode={404}
      title={t('errors:httpErrors.404.title')}
      message={t('errors:httpErrors.404.message')}
    />
  );
});

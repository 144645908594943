import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
`;
export const Value = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.25;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmptyValue = styled(Value)`
  color: ${({ theme }) => theme.colors.gogo};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
`;

export const EditIcon = styled(Icon)`
  margin-left: 26px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }
`;

export const IconCircle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-right: 15px;
  min-width: 34px;
`;

export const StyledIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;
export const FlexColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Row = styled.div`
  display: flex;
  padding: 0 20px;

  ${FlexColumn} {
    flex: 1;
  }

  & + & {
    margin-top: 20px;
  }
`;

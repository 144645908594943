import React, { memo, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const menuItemBaseStyle = css`
  align-items: center;
  background: transparent;
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  opacity: 0.7;
  transition: background 150ms, font-family 150ms;
  height: 54px;
  line-height: 54px;
  margin: 0;
  padding: 0 22px !important;
  width: 100%;
`;

const selectedMenuItemStyle = css`
  opacity: 1;
  font-family: ${({ theme }) => theme.font.medium};
  background: #3ca0dd;
`;

const selectedItemBorderStyle = css`
  border-right: 3px solid ${({ theme }) => theme.colors.accentBlue};
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 150ms;
`;

const iconStyle = css`
  line-height: 0;
  margin-right: 14px;
`;

const arrowIconStyle = css`
  position: absolute;
  line-height: 0;
  top: 50%;
  right: 0;
  margin-top: -3px;
  margin-right: 20px !important;
`;

const StyledMenuItem = styled(Menu.Item)`
  // Top level MenuItems
  &&&.ant-menu-item {
    ${menuItemBaseStyle};

    &:hover {
      opacity: 1;
    }

    &.ant-menu-item-selected {
      ${selectedMenuItemStyle};
    }

    &::before {
      ${selectedItemBorderStyle};
    }

    &::after {
      border-right: none;
    }

    &.ant-menu-item-selected::before {
      opacity: 1;
    }

    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  // MenuItems inside a SubMenu
  .ant-menu-submenu &&& {
    &.ant-menu-item {
      font-size: 13px;
      padding-left: 65px !important;
      height: 44px;
      line-height: 44px;
    }

    &.ant-menu-item-selected {
      font-family: ${({ theme }) => theme.font.regular};
      background: transparent;
    }

    &.ant-menu-item-selected::before {
      opacity: 0;
    }
  }

  // MenuItems inside of Popup menu (Only appears on desktop with the sidebar collapsed)
  .ant-menu-submenu.ant-menu-submenu-popup &&&.ant-menu-item {
    border-radius: 4px;
    font-family: ${({ theme }) => theme.font.regular};
    letter-spacing: -0.14px;
    line-height: 21px;
    margin: 5px 0 !important;
    padding: 0 10px !important;
    height: 40px;
    opacity: 1;

    &:hover {
      background: ${({ theme }) => theme.colors.lightGrey2};
    }

    span {
      color: ${({ theme }) => theme.colors.lightBlue};
    }

    &.ant-menu-item-selected {
      background: rgba(117, 207, 236, 0.13) !important;

      span {
        color: ${({ theme }) => theme.colors.globalBlue};
      }
    }

    &.ant-menu-item-selected::before {
      opacity: 0;
    }
  }

  .anticon.lgg-icon:not(.lgg-arrow) {
    ${iconStyle};
  }

  .anticon.lgg-icon.lgg-arrow {
    ${arrowIconStyle};
  }
`;

const StyledMenuItemContent = styled.span`
  display: flex;
  align-items: center;
`;

type SidebarMenuItemProps = WithClassname & {
  icon?: string;
  to: string;
  label: string;
  key: string;
  extraContent?: React.ReactNode;
};

// The `key` prop must be set in `SidebarMenuItem` usages instead of antd's `Menu.Item` component, when using this wrapper
// otherwise, the selected state would not be triggered based https://github.com/ant-design/ant-design/issues/4853
export const SidebarMenuItem = memo<SidebarMenuItemProps>(
  ({ icon, to, label, extraContent, ...rest }) => {
    const breakpointUpMd = useBreakpoint(up('md'));

    return (
      <StyledMenuItem
        {...rest}
        icon={icon ? <Icon type={icon} /> : null}
        // https://ant.design/components/menu#menuitemtype
        // if breakpointUpMd uses the default behavior, otherwise use null as title for collapsed item
        title={breakpointUpMd ? undefined : null}
      >
        <NavLink to={to}>
          <StyledMenuItemContent>
            {label}
            {extraContent}
          </StyledMenuItemContent>
        </NavLink>
      </StyledMenuItem>
    );
  },
);

const StyledSubMenu = styled(Menu.SubMenu)<{
  children: ReactNode | undefined;
}>`
  &.ant-menu-submenu {
    .ant-menu-submenu-title {
      ${menuItemBaseStyle};

      &:hover {
        opacity: 1;
      }

      &::before {
        ${selectedItemBorderStyle};
      }

      span {
        color: ${({ theme }) => theme.colors.white};
      }

      .anticon.lgg-icon:not(.lgg-arrow) {
        ${iconStyle};
      }

      .anticon.lgg-icon.lgg-arrow {
        ${arrowIconStyle};
      }
    }

    &.ant-menu-submenu-selected .ant-menu-submenu-title {
      ${selectedMenuItemStyle};

      &::before {
        opacity: 1;
      }
    }
  }

  .ant-menu-sub {
    background: transparent;
  }
`;

type SidebarSubMenuProps = {
  key: string;
  to?: string;
  icon: string;
  title: string | React.ReactNode;
  subMenuSelectedKeys: string[];
};

export const SidebarSubMenu: React.FC<SidebarSubMenuProps> = ({
  children,
  to,
  icon,
  subMenuSelectedKeys,
  title,
  ...props
}) => {
  // Computing the selected state of the menu item due to https://github.com/ant-design/ant-design/issues/4853
  const selected = (Array.isArray(children) ? children : [children]).some((child) => {
    if (React.isValidElement(child)) {
      return child.key ? subMenuSelectedKeys?.includes(child.key.toString()) : false;
    }

    return false;
  });

  return (
    // new typescript error after updating ts to 4.6. might need to update styled-components
    // eslint-disable-next-line
    // @ts-ignore
    <StyledSubMenu
      {...props}
      key={to}
      className={selected ? 'ant-menu-submenu-selected' : ''}
      icon={<Icon type={icon} />}
      title={typeof title === 'string' ? <span>{title}</span> : title}
    >
      {children}
    </StyledSubMenu>
  );
};

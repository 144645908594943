import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from 'src/components/layout/page-containers';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';

export const AddNewWhatsAppPhonePage: React.FC = () => {
  const { t } = useTranslation(['whatsappChannel']);

  useAddBreadcrumb(t('whatsappChannel:pages.addNewPhone.breadcrumb'));

  return (
    <PageContainer data-lgg-id="whatsapp-channel-add-phone-page">Add New</PageContainer>
  );
};

import { useEffect } from 'react';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { isRefreshDataEventType } from 'src/types/type-guards';

export const useRefreshAppointment = ({ onRefresh }: UseRefreshProps) => {
  useEffect(() => {
    const handleRefresh = (e, body) => {
      if (isRefreshDataEventType<Schedule>(body, 'schedule')) {
        const { id, action, schedule } = body.entity;
        const title = schedule?.title;

        onRefresh({ id, action, entityTitle: title });
      }
    };

    window.jQuery(window).on('refreshData', handleRefresh);

    return () => window.jQuery(window).off('refreshData', handleRefresh);
  }, [onRefresh]);
};

/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import {
  Query,
  QueryWhatsappResourcesArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { baseButtonV2Styles } from 'src/components/general/button/lgg-button';
import { isomorphicColorButtonStyles } from 'src/components/general/button/shared';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { TableLayoutPlaceholder } from 'src/components/general/feedback/loading-placeholders';
import { TableLayoutHeader } from 'src/components/general/table-helpers';
import {
  PageContainer,
  TableLayoutPageContainer,
} from 'src/components/layout/page-containers';
import { StyledSyncIcon } from 'src/components/pages/whatsapp-channel/components/shared';
import AddNewResourceButton from 'src/components/pages/whatsapp-channel/components/whatsapp-add-new-button';
import { WhatsAppResourcesTable } from 'src/components/pages/whatsapp-channel/components/whatsapp-resources-table';
import { useMetaResourceActivation } from 'src/components/pages/whatsapp-channel/shared/hooks';
import { WHATSAPP_RESOURCES_QUERY } from 'src/components/pages/whatsapp-channel/shared/queries';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';

const StyledConnectButton = styled(AddNewResourceButton)`
  ${baseButtonV2Styles};
  ${isomorphicColorButtonStyles};
`;

// TODO: Improve this & sortData logic when connecting to backend data
const doChange = (pagination, filters, sorter) => {};

const MetaWorkflowButton = styled.button`
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
`;

export const WhatsAppResourcesPage = () => {
  const { t } = useTranslation(['whatsappChannel']);
  const { id: institutionId } = useCurrentInstitution();
  const { getFeatureFlag } = useAuthorization();

  const { launchWhatsAppSignUp, isActivatingResource } = useMetaResourceActivation();

  const handleCallback = useCallback((callbackObject) => {
    console.log('callbackObject', callbackObject);
  }, []);

  const { data, loading } = useQuery<
    Pick<Query, 'whatsappResources'>,
    QueryWhatsappResourcesArgs
  >(WHATSAPP_RESOURCES_QUERY, {
    variables: {
      institutionId,
      first: 30,
      after: null,
      before: null,
      last: null,
    },
  });

  if (!data || loading) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  const { totalCount, edges } = data.whatsappResources;

  const whatsappResources = edges.map((edge) => edge.node);
  const hasMetaSignupFlag = getFeatureFlag(
    'temp_lggdev-1944_embed-meta-signup-script-on-whatsapp-resource-adding-workflow',
  );

  return (
    <PageContainer data-lgg-id="whatsapp-channel-page">
      <TableLayoutHeader
        leftContent={
          <PaginationInfo
            total={totalCount}
            testId="whatsapp-channels-page-pagination-info"
            entityName={t('whatsappChannel:pages.channel.count', { count: totalCount })}
          />
        }
        rightContent={
          hasMetaSignupFlag ? (
            isActivatingResource ? (
              <StyledSyncIcon type="sync" $rotating={true} />
            ) : (
              <MetaWorkflowButton onClick={launchWhatsAppSignUp}>
                {t('whatsappChannel:pages.channel.addNewPhoneButton')}
              </MetaWorkflowButton>
            )
          ) : (
            <StyledConnectButton
              variant="primary"
              partnerId={import.meta.env.VITE_WHATSAPP_360_PARTNER_ID}
              callback={handleCallback}
              label={t('whatsappChannel:pages.channel.addNewPhoneButton')}
              queryParameters={{ plan_selection: 'basic' }}
              data-lgg-id="add-new-phone-number-button"
            />
          )
        }
      />
      <WhatsAppResourcesTable
        data={whatsappResources}
        sortData={{ key: 'status', direction: 'DESC' }}
        onChange={doChange}
      />
    </PageContainer>
  );
};

import { Inject, Service } from 'react-service-locator';
import * as Sentry from '@sentry/react';
import { SessionService } from 'src/services/session.service';
import { SuspenseService } from 'src/services/suspense.service';

export const sentryIdSymbol = Symbol('reported');

@Service()
export class ErrorService {
  @Inject(SuspenseService)
  private readonly suspenseService!: SuspenseService;

  @Inject(SessionService)
  private readonly sessionService: SessionService;

  suspendAndInitialize = () => {
    this.suspenseService.suspendWith(() =>
      this.sessionService.initializationPromise.then(() => {
        const user = this.sessionService.state?.sessionData?.user;
        if (user) {
          Sentry.setUser({
            id: user.id.toString(),
            email: user.email,
          });
        }
      }),
    );
  };

  reportError = (error: any): string => {
    if (!error[sentryIdSymbol]) {
      const id = Sentry.captureException(error);
      error[sentryIdSymbol] = id;
      return id;
    }
    return error[sentryIdSymbol];
  };
}

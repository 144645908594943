import { Service, StatefulService } from 'react-service-locator';

@Service()
export class CalendarService extends StatefulService<{ selectedDay: Date | null }> {
  constructor() {
    super({ selectedDay: null });
  }

  public setSelectedDay = (day: Date) => {
    this.setState({ selectedDay: day });
  };

  public clearSelectedDay = () => {
    this.setState({ selectedDay: null });
  };
}

import { NetworkStatus } from '@apollo/client';
import { useActiveDepartmentsQuery } from 'src/components/domain/departments/hooks/use-active-departments-query';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

type UseActiveDepartmentSelectOptionsReturn = {
  loadingDepartmentOptions: boolean;
  departmentOptions: SelectOption<number>[];
  networkStatus: NetworkStatus;
};

export const useActiveDepartmentSelectOptions =
  (): UseActiveDepartmentSelectOptionsReturn => {
    const { loading, data, networkStatus } = useActiveDepartmentsQuery();

    return {
      networkStatus,
      loadingDepartmentOptions: loading,
      departmentOptions: getNodesFromConnection(data?.departments).map(
        entityToSelectOptionMapper,
      ),
    };
  };

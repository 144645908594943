import { useService } from 'react-service-locator';
import {
  ConversationService,
  ConversationsFilters,
} from 'src/services/conversation.service';

type UseConversationReturn = {
  visible: boolean;
  selectedConversationId: string | null;
  selectedContactId: number | null;
  selectConversation: ConversationService['selectConversation'];
  clearConversationSelection: VoidFunction;
  hide: VoidFunction;
  filters: ConversationsFilters;
  showContactInformation: boolean;
  setShowContactInformation: ValueChanged<boolean>;
  setFilters: ValueChanged<Partial<ConversationsFilters>>;
  bulkSelectionMap: Map<string, boolean>;
  bulkSelectionEnabled: boolean;
  bulkSelectAllEnabled: boolean;
  toggleBulkSelectionItemId: (id: string) => void;
  toggleBulkSelectionMode: VoidFunction;
  toggleBulkSelectAllMode: (value: boolean) => void;
};

export const useConversation = (): UseConversationReturn => {
  const {
    state: {
      selectedConversationId,
      selectedContactId,
      visible,
      showContactInformation,
      filters,
      bulkSelectionEnabled,
      bulkSelectionMap,
      bulkSelectAllEnabled,
    },
    selectConversation,
    clearConversationSelection,
    hide,
    setFilters,
    setShowContactInformation,
    toggleBulkSelectionMode,
    toggleBulkSelectionItemId,
    toggleBulkSelectAllMode,
  } = useService(ConversationService, (s) => [
    s.state.selectedConversationId,
    s.state.selectedContactId,
    s.state.visible,
    s.state.showContactInformation,
    s.state.filters,
    s.state.bulkSelectionEnabled,
    s.state.bulkSelectionMap,
    s.state.bulkSelectAllEnabled,
  ]);

  return {
    selectedConversationId,
    selectedContactId,
    selectConversation,
    clearConversationSelection,
    visible,
    hide,
    filters: filters as ConversationsFilters,
    showContactInformation,
    setShowContactInformation,
    setFilters,
    bulkSelectionEnabled,
    bulkSelectionMap: bulkSelectionMap as Map<string, boolean>,
    bulkSelectAllEnabled,
    toggleBulkSelectionMode,
    toggleBulkSelectionItemId,
    toggleBulkSelectAllMode,
  };
};

import React from 'react';
import { range } from 'lodash';
import styled from 'styled-components';
import { Skeleton } from 'src/components/general/feedback/skeleton';

const StyledSkeleton = styled(Skeleton)`
  padding: 30px 27px 0 18px;

  .ant-skeleton-header {
    padding-right: 29px;
  }

  .ant-skeleton-avatar {
    height: 26px;
    width: 26px;
  }

  &.ant-skeleton-with-avatar .ant-skeleton-content {
    .ant-skeleton-title {
      border-radius: 4px;
      margin: 0;
    }

    .ant-skeleton-paragraph {
      margin-top: 7px;
    }

    .ant-skeleton-paragraph li {
      border-radius: 4px;
    }
  }
`;

export const CalendarListLoadingIndicator = () => (
  <>
    {range(3).map((value) => {
      return (
        <StyledSkeleton
          key={value}
          loading={true}
          active
          avatar
          paragraph={{ rows: 1 }}
          title={{ width: '20%' }}
        />
      );
    })}
  </>
);

import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationConversationSetClosedArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { publishEvent } from 'src/utils/events/pub-sub';

const CONVERSATION_SET_CLOSED = gql`
  mutation ConversationSetClosed($conversationId: String!, $isClosed: Boolean!) {
    conversationSetClosed(conversationId: $conversationId, isClosed: $isClosed) {
      conversation {
        id
        isOpen
      }
    }
  }
`;

type ConversationSetClosedVariables = { conversationId: string; isClosed: boolean };

export const useConversationSetClosed = () => {
  const showNotification = useShowNotification();
  const { t } = useTranslation(['conversations']);
  const [mutate] = useMutation<
    Pick<Mutation, 'conversationSetClosed'>,
    MutationConversationSetClosedArgs
  >(CONVERSATION_SET_CLOSED);

  return async ({ isClosed, conversationId }: ConversationSetClosedVariables) => {
    await mutate({
      variables: { conversationId, isClosed },
      onError: () => {
        const message = isClosed
          ? 'conversations:notifications.mutations.changeOpenStatus.toClosed.error'
          : 'conversations:notifications.mutations.changeOpenStatus.toOpen.error';

        showNotification({
          type: 'error',
          title: t(message),
        });
      },
      onCompleted: () => {
        publishEvent('CONVERSATION_UPDATED', { id: conversationId });

        const message = isClosed
          ? 'conversations:notifications.mutations.changeOpenStatus.toClosed.success'
          : 'conversations:notifications.mutations.changeOpenStatus.toOpen.success';

        showNotification({
          type: 'success',
          title: t(message),
        });
      },
    });
  };
};

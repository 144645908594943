import React, { memo } from 'react';
import styled from 'styled-components';
import { Center } from 'src/components/layout/center';

const DateGroupItem = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.cosmo};
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  height: 19px;
  min-width: 85px;
  justify-content: center;
  letter-spacing: -0.22px;
  margin-top: 15px;
  padding: 3px 10px;
  z-index: 5;
`;

type GroupHeaderProps = {
  children: string;
};

export const GroupHeader = memo<GroupHeaderProps>(({ children }) => {
  return (
    <Center>
      <DateGroupItem>{children}</DateGroupItem>
    </Center>
  );
});

type InlineGroupHeaderProps = GroupHeaderProps & {
  visible: boolean;
};

export const InlineGroupHeader = memo<InlineGroupHeaderProps>(({ children, visible }) => {
  return (
    <div
      className="inverted-virtuoso-group-header"
      data-group={children}
      style={!visible ? { visibility: 'hidden' } : {}}
    >
      <GroupHeader>{children}</GroupHeader>
    </div>
  );
});

import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { RcFile } from 'antd/es/upload/interface';
import { match } from 'ts-pattern';
import {
  Mutation,
  MutationDeleteProfilePhotoArgs,
  MutationUpdateProfilePhotoArgs,
  MutationUserGeneratePresignedUrlArgs,
  UserAvatar,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  DELETE_PROFILE_PHOTO_MUTATION,
  GENERATE_PRESIGNED_URL,
  UPDATE_PROFILE_PHOTO_MUTATION,
} from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { SessionService } from 'src/services/session.service';
import { uploadFileToS3 } from 'src/utils/upload-file-to-s3';

type Avatar = Pick<UserAvatar, 'initials' | 'avatarUrl'>;

const fallbackAvatar: Avatar = {
  initials: 'N/A',
  avatarUrl: null,
};

export const useUploadPhoto = (initialAvatar?: Avatar) => {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<Avatar>(initialAvatar || fallbackAvatar);
  const userId = useServiceSelector(SessionService, ({ data: { user } }) => user.id);

  useEffect(() => {
    if (initialAvatar) {
      setAvatar(initialAvatar);
    }
  }, [initialAvatar]);

  const [generatePresignedUrl] = useMutation<
    Pick<Mutation, 'userGeneratePresignedUrl'>,
    MutationUserGeneratePresignedUrlArgs
  >(GENERATE_PRESIGNED_URL);

  const [updateProfilePhoto] = useMutation<
    Pick<Mutation, 'updateProfilePhoto'>,
    MutationUpdateProfilePhotoArgs
  >(UPDATE_PROFILE_PHOTO_MUTATION);

  const [deleteProfilePhoto] = useMutation<
    Pick<Mutation, 'deleteProfilePhoto'>,
    MutationDeleteProfilePhotoArgs
  >(DELETE_PROFILE_PHOTO_MUTATION);

  const showNotification = useShowNotification();

  const { t } = useTranslation(['user']);

  const handleFileUpload = useCallback(
    async (file: RcFile) => {
      await generatePresignedUrl({
        variables: {
          input: {
            filename: file.name,
            mimeType: file.type,
            sizeBytes: file.size,
          },
        },
        onCompleted: async ({ userGeneratePresignedUrl }) => {
          const uploadedUrl = await uploadFileToS3(file, userGeneratePresignedUrl);

          void updateProfilePhoto({
            variables: {
              input: {
                id: userId,
                avatarUrl: uploadedUrl,
              },
            },
            onCompleted: () => {
              setAvatar((prevState) => ({ ...prevState, avatarUrl: uploadedUrl }));

              showNotification({
                type: 'success',
                title: t(
                  'user:profileSettings.sections.personalInformation.fields.photo.toasts.success.upload',
                ),
              });

              setLoading(false);
            },
            onError: () => {
              showNotification({
                type: 'error',
                title: t(
                  'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.upload',
                ),
              });

              setLoading(false);
            },
          });
        },
        onError: (error: ApolloError) => {
          const [, errorCode] = error.graphQLErrors?.[0]?.extensions?.code?.split(':');

          const errorMessage = match(errorCode)
            .with('PRESIGNED_URL_GENERATION_FAILED', () =>
              t(
                'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.uploadStart',
              ),
            )
            .with('MIME_TYPE_NOT_ALLOWED', () =>
              t(
                'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.unsupportedFileType',
              ),
            )
            .with('FILE_SIZE_EXCEEDED', () =>
              t(
                'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.fileTooLarge',
              ),
            )
            .otherwise(() =>
              t(
                'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.upload',
              ),
            );

          showNotification({
            type: 'error',
            title: errorMessage,
          });

          setLoading(false);
        },
      });
    },
    [t, generatePresignedUrl, updateProfilePhoto, showNotification, userId],
  );

  const uploadPhoto = useCallback(
    (options: any) => {
      const { file } = options;
      const rcFile = file as RcFile;

      setLoading(true);
      void handleFileUpload(rcFile);
    },
    [handleFileUpload],
  );

  const removePhoto = useCallback(() => {
    void deleteProfilePhoto({
      variables: {
        input: {
          id: userId,
        },
      },
      onCompleted: () => {
        setAvatar((prevState) => ({
          ...prevState,
          avatarUrl: null,
        }));

        showNotification({
          type: 'success',
          title: t(
            'user:profileSettings.sections.personalInformation.fields.photo.toasts.success.delete',
          ),
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          title: t(
            'user:profileSettings.sections.personalInformation.fields.photo.toasts.error.delete',
          ),
        });
      },
    });
  }, [t, deleteProfilePhoto, showNotification, userId]);

  return useMemo(
    () => ({
      loading,
      avatar,
      removePhoto,
      uploadPhoto,
    }),
    [loading, avatar, uploadPhoto, removePhoto],
  );
};

import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationDeleteScheduleArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const DELETE_APPOINTMENT_MUTATION = gql`
  mutation DeleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      success
    }
  }
`;

export const useDeleteAppointment = () => {
  const handleGraphQLError = useHandleGraphQLError();

  return useMutation<Pick<Mutation, 'deleteSchedule'>, MutationDeleteScheduleArgs>(
    DELETE_APPOINTMENT_MUTATION,
    {
      onError: handleGraphQLError,
    },
  );
};

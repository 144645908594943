import React, { memo, MouseEventHandler } from 'react';
import {
  DrawerBaseOption,
  BottomDrawerBase,
  BottomDrawer,
} from 'src/components/general/drawer/bottom/bottom-drawer';
import { BottomDrawerItemsRenderer } from 'src/components/general/drawer/bottom/bottom-drawer-item-renderer';

export type DrawerSelectableOption = DrawerBaseOption & {
  onClick: MouseEventHandler;
  value: string;
};

type SelectableOptionsDrawerProps = BottomDrawerBase & {
  options: DrawerSelectableOption[] | Record<string, DrawerSelectableOption[]>;
  selectedValue: string | null;
};

export const SelectableOptionsDrawer = memo<SelectableOptionsDrawerProps>(
  ({ options, onClose, selectedValue, ...rest }) => {
    return (
      <BottomDrawer {...rest} onClose={onClose} mode="options" height="100%">
        <BottomDrawerItemsRenderer
          options={options}
          onClose={onClose}
          selectedValue={selectedValue}
          iconColorOverride="flint"
        />
      </BottomDrawer>
    );
  },
);

type DrawerOptionWithoutIcon = Omit<DrawerSelectableOption, 'icon'>;

type SelectableOptionsWithoutIconBottomProps = BottomDrawerBase & {
  options: DrawerOptionWithoutIcon[];
  selectedValue: string | null;
};

export const SelectableOptionsWithoutIconDrawer =
  memo<SelectableOptionsWithoutIconBottomProps>(
    ({ onClose, options, selectedValue, ...rest }) => {
      return (
        <BottomDrawer {...rest} onClose={onClose} mode="options">
          <BottomDrawerItemsRenderer
            options={options}
            onClose={onClose}
            selectedValue={selectedValue}
          />
        </BottomDrawer>
      );
    },
  );

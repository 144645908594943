import { css } from 'styled-components';

export type ButtonSize = 'regular' | 'small';
export type ButtonVariant =
  | 'primary'
  | 'cta'
  | 'ctaGhost'
  | 'default'
  | 'defaultGhost'
  | 'defaultWhite'
  | 'delete';

// `DefaultWhiteButton` appearance style
const defaultWhiteColors = css`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.flint};
  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const defaultWhiteButtonStyle = css`
  ${defaultWhiteColors};
  &:focus {
    ${defaultWhiteColors};
  }
  &:hover {
    ${defaultWhiteColors};
    color: ${({ theme }) => theme.colors.steel};
    box-shadow: 0 2px 4px 0 #97a3ae1a;
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:active {
    ${defaultWhiteColors};
    box-shadow: 0 1px 2px 0 #4b68821a;
    color: ${({ theme }) => theme.colors.steel};
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:disabled {
    ${defaultWhiteColors};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
    span {
      opacity: 0.35;
    }
  }
`;

// `PrimaryButton` appearance style
const primaryColors = css`
  background-color: ${({ theme }) => theme.colors.monk};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const primaryButtonStyle = css`
  ${primaryColors};
  &:focus {
    ${primaryColors};
  }
  &:hover {
    ${primaryColors};
    background-color: #83d4ee;
    box-shadow: 0 2px 4px 0 #66a5ba33;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:active {
    ${primaryColors};
    background-color: #6fc4e0;
    box-shadow: 0 1px 2px 0 #5798ad33;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:disabled {
    ${primaryColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

// `CTAButton` appearance style
const ctaColors = css`
  background-color: ${({ theme }) => theme.colors.cosmo};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const ctaButtonStyle = css`
  ${ctaColors};
  &:focus {
    ${ctaColors};
  }
  &:hover {
    ${ctaColors};
    background-color: #4bcfa4;
    box-shadow: 0 2px 4px 0 #389c7a33;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:active {
    ${ctaColors};
    background-color: #33bf92;
    box-shadow: 0 1px 2px 0 #268c6c33;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:disabled {
    ${ctaColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

// `CTAGhostButton` appearance style
const ctaGhostColors = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.cosmo};
  color: ${({ theme }) => theme.colors.smalt};
  path {
    fill: ${({ theme }) => theme.colors.smalt};
  }
`;

const ctaGhostButtonStyle = css`
  ${ctaGhostColors};
  &:focus {
    ${ctaGhostColors};
  }
  &:hover {
    ${ctaGhostColors};
    background-color: ${({ theme }) => theme.colors.secondaryMint10};
    border: 1px solid #73dab9;
    box-shadow: 0 2px 4px 0 #389c7a1a;
    color: ${({ theme }) => theme.colors.smalt};
    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
  &:active {
    ${ctaGhostColors};
    background-color: ${({ theme }) => theme.colors.secondaryMint10};
    border: 1px solid #33bf92;
    box-shadow: 0 2px 4px 0 #268c6c33;
    color: ${({ theme }) => theme.colors.smalt};
    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
  &:disabled {
    ${ctaGhostColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    border: 1px solid ${({ theme }) => theme.colors.koala};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

// `DefaultButton` appearance style
const defaultColors = css`
  background-color: ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.flint};
  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const defaultButtonStyle = css`
  ${defaultColors};
  &:focus {
    ${defaultColors};
  }
  &:hover {
    ${defaultColors};
    background-color: #f4f6f8;
    color: ${({ theme }) => theme.colors.steel};
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:active {
    ${defaultColors};
    background-color: ${({ theme }) => theme.colors.koala};
    color: ${({ theme }) => theme.colors.steel};
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:disabled {
    ${defaultColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

// `DefaultGhostButton` appearance style
const defaultGhostColors = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.flint};
  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const defaultGhostButtonStyle = css`
  ${defaultGhostColors};
  &:focus {
    ${defaultGhostColors};
  }
  &:hover {
    ${defaultGhostColors};
    background-color: ${({ theme }) => theme.colors.alabaster};
    border: 1px solid ${({ theme }) => theme.colors.koala};
    color: ${({ theme }) => theme.colors.steel};
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:active {
    ${defaultGhostColors};
    background-color: ${({ theme }) => theme.colors.alabaster};
    border: 1px solid #e4e9ed;
    color: ${({ theme }) => theme.colors.steel};
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
  &:disabled {
    ${defaultGhostColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    border: 1px solid ${({ theme }) => theme.colors.koala};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

// `DELETEButton` appearance style
const deleteColors = css`
  background-color: ${({ theme }) => theme.colors.secondaryCoral};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const deleteButtonStyle = css`
  ${deleteColors};
  &:focus {
    ${deleteColors};
  }
  &:hover {
    ${deleteColors};
    background-color: #ff6971;
    box-shadow: 0 2px 4px 0 rgba(218, 87, 94, 0.2);
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:active {
    ${deleteColors};
    background-color: #eb5c64;
    box-shadow: 0 1px 2px 0 rgba(218, 87, 94, 0.2);
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:disabled {
    ${deleteColors};
    background-color: ${({ theme }) => theme.colors.porcelain};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    span {
      opacity: 0.35;
    }
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

export const isomorphicColorButtonStyles = css<{ variant: ButtonVariant }>`
  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return primaryButtonStyle;
      case 'cta':
        return ctaButtonStyle;
      case 'ctaGhost':
        return ctaGhostButtonStyle;
      case 'default':
        return defaultButtonStyle;
      case 'defaultGhost':
        return defaultGhostButtonStyle;
      case 'defaultWhite':
        return defaultWhiteButtonStyle;
      case 'delete':
        return deleteButtonStyle;
    }
  }}
`;

import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import { HeaderDesktop } from 'src/components/layout/authenticated-layout/header/desktop';
import { HeaderMobile } from 'src/components/layout/authenticated-layout/header/mobile';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export const Header = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return breakpointUpMd ? <HeaderDesktop /> : <HeaderMobile />;
});

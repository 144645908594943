import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DateRepresentation, useDateHelpers } from 'src/hooks/use-date-helpers';

export type Duration = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const useFormatDate = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['common']);
  const enActive = language.startsWith('en');
  const esActive = language.startsWith('es');
  const {
    isToday,
    isTomorrow,
    isYesterday,
    ensureDate,
    format,
    differenceInDays,
    isSameYear,
  } = useDateHelpers();
  const getEffectiveDate = useCallback(
    (date: DateRepresentation) => ensureDate(date),
    [ensureDate],
  );

  const getEffectiveBaseDate = useCallback(
    (baseDate?: DateRepresentation) => {
      return baseDate ? getEffectiveDate(baseDate) : new Date();
    },
    [getEffectiveDate],
  );

  const formatDate = useCallback(
    (
      date: DateRepresentation,
      dateFormat: string,
      useTimezoneAndLanguageContext: boolean = true,
    ) => {
      const effectiveDate = getEffectiveDate(date);

      return format(effectiveDate, dateFormat, useTimezoneAndLanguageContext);
    },
    [format, getEffectiveDate],
  );

  const formatRelativeDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);

      if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.yesterday');
      }

      if (isTomorrow(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.tomorrow');
      }

      let formatString: string;

      if (isToday(effectiveDate, effectiveBaseDate)) {
        formatString = 'hh:mm A';
      } else if (differenceInDays(effectiveBaseDate, effectiveDate) < 7) {
        formatString = 'dddd';
      } else {
        formatString = 'MM/D/YY';
      }

      return formatDate(effectiveDate, formatString);
    },
    [
      differenceInDays,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isToday,
      isTomorrow,
      isYesterday,
      t,
    ],
  );

  const formatRelativeGroupDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);

      if (isToday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.today');
      } else if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.yesterday');
      } else if (isSameYear(effectiveBaseDate, effectiveDate)) {
        return formatDate(effectiveDate, enActive ? 'ddd, MMM D' : 'ddd, MMM. D');
      } else {
        return formatDate(effectiveDate, enActive ? 'MMM D, YY' : 'MMM. D, YY');
      }
    },
    [
      enActive,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isSameYear,
      isToday,
      isYesterday,
      t,
    ],
  );

  const formatSimpleRelativeDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);

      if (isToday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.today');
      } else if (isTomorrow(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.tomorrow');
      } else if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.yesterday');
      } else if (isSameYear(effectiveBaseDate, effectiveDate)) {
        return formatDate(effectiveDate, esActive ? 'D [de] MMM.' : 'MMM D');
      } else {
        return formatDate(effectiveDate, esActive ? 'D [de] MMM. [de] Y' : 'll');
      }
    },
    [
      esActive,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isSameYear,
      isToday,
      isTomorrow,
      isYesterday,
      t,
    ],
  );

  const formatSimpleTime = useCallback(
    (date: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);

      return formatDate(effectiveDate, 'hh:mm A');
    },
    [formatDate, getEffectiveDate],
  );

  const formatSimpleTimeRange = useCallback(
    (startDate: DateRepresentation, endDate?: DateRepresentation) => {
      const startDateMeridiem = formatDate(startDate, 'a');

      if (endDate) {
        const endDateMeridiem = endDate ? formatDate(endDate, 'a') : undefined;

        if (startDateMeridiem !== endDateMeridiem) {
          return `${formatSimpleTime(startDate)} - ${formatSimpleTime(endDate)}`;
        }

        return `${formatDate(startDate, 'hh:mm')} - ${formatSimpleTime(endDate)}`;
      }

      return formatSimpleTime(startDate);
    },
    [formatDate, formatSimpleTime],
  );

  const formatEventDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);

      if (isToday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.today');
      } else if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.yesterday');
      } else if (isTomorrow(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.tomorrow');
      } else {
        return formatDate(effectiveDate, enActive ? 'MMM D, YYYY' : 'MMM. D, YYYY');
      }
    },
    [
      enActive,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isToday,
      isTomorrow,
      isYesterday,
      t,
    ],
  );

  // Log date format:
  // Current Day: "10:10 AM"
  // Yesterday: "Yesterday" | "Ayer"
  // Same year: "Oct 5" | "5 de Oct."
  // Different year: "Oct 5, 2018" | "5 de Oct. 2018"
  const formatLogDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);

      if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return t('common:pointInTime.yesterday');
      }

      let formatString: string;

      if (isToday(effectiveDate, effectiveBaseDate)) {
        formatString = 'hh:mm A';
      } else if (isSameYear(effectiveBaseDate, effectiveDate)) {
        formatString = enActive ? 'MMM D' : 'D [de] MMM.';
      } else {
        formatString = enActive ? 'MMM D, YYYY' : 'D [de] MMM. [de] YYYY';
      }

      return formatDate(effectiveDate, formatString);
    },
    [
      enActive,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isSameYear,
      isToday,
      isYesterday,
      t,
    ],
  );

  // Due date format:
  // Next Day: "Tomorrow 10:10 AM" | "Mañana 10:10 AM"
  // Current Day: "Today 10:10 AM" | "Hoy 10:10 AM"
  // Yesterday: "Yesterday 10:10 AM" | "Ayer 10:10 AM"
  // Same year: "Oct 5 10:10 AM" | "5 de Oct. 10:10 AM"
  // Different year: "Oct 5, 2018 10:10 AM" | "5 de Oct. 2018 10:10 AM"
  const formatDueDate = useCallback(
    (date: DateRepresentation, baseDate?: DateRepresentation) => {
      const effectiveDate = getEffectiveDate(date);
      const effectiveBaseDate = getEffectiveBaseDate(baseDate);
      const dateFormat = 'hh:mm A';

      if (isTomorrow(effectiveDate, effectiveBaseDate)) {
        return `${_.capitalize(t('common:pointInTime.tomorrow'))} ${formatDate(
          effectiveDate,
          dateFormat,
        )}`;
      } else if (isToday(effectiveDate, effectiveBaseDate)) {
        return `${t('common:pointInTime.today')} ${formatDate(
          effectiveDate,
          dateFormat,
        )}`;
      } else if (isYesterday(effectiveDate, effectiveBaseDate)) {
        return `${t('common:pointInTime.yesterday')} ${formatDate(
          effectiveDate,
          dateFormat,
        )}`;
      } else if (isSameYear(effectiveBaseDate, effectiveDate)) {
        return formatDate(
          effectiveDate,
          enActive ? `MMM D ${dateFormat}` : `D [de] MMM. ${dateFormat}`,
        );
      } else {
        return formatDate(
          effectiveDate,
          enActive ? `MMM D, YYYY ${dateFormat}` : `D [de] MMM. [de] YYYY ${dateFormat}`,
        );
      }
    },
    [
      enActive,
      formatDate,
      getEffectiveBaseDate,
      getEffectiveDate,
      isSameYear,
      isToday,
      isTomorrow,
      isYesterday,
      t,
    ],
  );

  const formatDuration = useCallback(
    (duration: Duration) => {
      if (duration.days) {
        return t('common:timeUnits.day', { count: duration.days });
      } else if (duration.hours) {
        return t('common:timeUnits.hour', { count: duration.hours });
      } else if (duration.minutes) {
        return t('common:timeUnits.minute', { count: duration.minutes });
      } else {
        return t('common:timeUnits.second', { count: duration.seconds });
      }
    },
    [t],
  );

  return {
    formatDate,
    formatEventDate,
    formatSimpleRelativeDate,
    formatSimpleTime,
    formatSimpleTimeRange,
    formatRelativeDate,
    formatRelativeGroupDate,
    formatLogDate,
    formatDueDate,
    formatDuration,
  };
};

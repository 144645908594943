import { Service, StatefulService } from 'react-service-locator';

@Service()
export class ConnectionService extends StatefulService<{ isOnline: boolean }> {
  constructor() {
    super({ isOnline: window.navigator.onLine });

    this.init();
  }

  private init = () => {
    window.addEventListener('online', () => this.setState({ isOnline: true }));
    window.addEventListener('offline', () => this.setState({ isOnline: false }));
  };
}

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from '../../layout/flex-row';

const PaginationButton = styled(LggButton)`
  padding: 10px 15px;
  width: unset;

  & + & {
    margin-left: 10px;
  }
`;

const PaginationText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 17px;
  text-align: left;
`;

const PrevIcon = styled(Icon)`
  margin-right: 10px;
`;

const NextIcon = styled(Icon)`
  margin-left: 10px;
`;

const PaginationContainer = styled(FlexRow)`
  height: max-content;
  padding: 20px;

  ${up('md')} {
    padding: 30px 0;
  }
`;

type PaginationHandlersProps = {
  onLoadPreviousPage: VoidFunction;
  onLoadNextPage: VoidFunction;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export const TablePagination = memo<PaginationHandlersProps>(
  ({ onLoadPreviousPage, onLoadNextPage, hasNextPage, hasPreviousPage }) => {
    const { t } = useTranslation(['common']);

    return (
      <PaginationContainer>
        <PaginationButton
          data-lgg-id="prev-page-button"
          onClick={onLoadPreviousPage}
          disabled={!hasPreviousPage}
          variant="defaultWhite"
        >
          <PrevIcon type="arrowLeftWide" />
          <PaginationText>{t('common:previous')}</PaginationText>
        </PaginationButton>
        <PaginationButton
          data-lgg-id="next-page-button"
          onClick={onLoadNextPage}
          disabled={!hasNextPage}
          variant="defaultWhite"
        >
          <PaginationText>{t('common:next')}</PaginationText>
          <NextIcon type="arrowRightWide" />
        </PaginationButton>
      </PaginationContainer>
    );
  },
);

import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { css } from 'styled-components';
import Curves from 'src/assets/images/curve_art_bg.svg?url';
import { ReactComponent as DesktopLggLogo } from 'src/assets/images/leadgogo_logo.svg';
import { ReactComponent as MobileLggLogo } from 'src/assets/images/lgg_logo_mobile.svg';
import { ReactComponent as BottomCurves } from 'src/assets/images/sign_in_curves.svg';
import { ReactComponent as WhiteCurves } from 'src/assets/images/white_bg_curves.svg';
import { Center } from 'src/components/layout/center';
import { FlexColumn } from 'src/components/layout/flex-column';
import { useGetVisualViewport } from 'src/hooks/use-get-visual-viewport';

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => `${37 + theme.insets.top}px auto 40px;`};

  ${up('xl')} {
    margin-top: 156px;
  }
`;

const Main = styled.div`
  background-image: linear-gradient(
    156deg,
    ${({ theme }) => theme.colors.globalBlue} 28%,
    ${({ theme }) => theme.colors.accentBlue} 111%
  );
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  overflow: auto;
  width: 100%;

  ${up('md')} {
    background-image: url(${Curves}),
      linear-gradient(
        126deg,
        ${({ theme }) => theme.colors.globalBlue} 31%,
        ${({ theme }) => theme.colors.accentBlue} 108%
      );
  }
`;

const termsAndConditionsTextStyle = css`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.31;
  text-align: center;
`;

const TermsAndConditionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto;

  a {
    ${termsAndConditionsTextStyle};

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  span {
    ${termsAndConditionsTextStyle};
    display: block;
    margin: 0 3px;
  }
`;

const Logo = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return breakpointUpMd ? <DesktopLggLogo /> : <MobileLggLogo />;
});

export const HollowLayout = memo(({ children, ...rest }) => {
  const { t } = useTranslation(['common']);

  return (
    <Main {...rest}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      {children}
      <TermsAndConditionsContainer>
        <a href="https://leadgogo.com/terms-and-conditions/">
          {t('common:termsAndConditions')}
        </a>
        <span>|</span>
        <a href="https://leadgogo.com/privacy-policy/">{t('common:privacy')}</a>
      </TermsAndConditionsContainer>
    </Main>
  );
});

const MobileMain = styled(FlexColumn)<{ minHeight: string }>`
  height: 100%;
  overflow: scroll;
  min-height: ${({ minHeight }) => minHeight};
  width: 100%;
`;

const TopCurve = styled.div`
  background: linear-gradient(
    298deg,
    ${({ theme }) => theme.colors.gogo},
    ${({ theme }) => theme.colors.monk}
  );
  background-size: cover;
  height: ${({ theme }) => 190 + theme.insets.top}px;
  width: 100%;
`;

const TopContainer = styled(Center)`
  position: relative;
`;

const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 169px;
  position: relative;
  width: 100%;
`;

const MobileLogo = styled(MobileLggLogo)`
  height: 73px;
  position: absolute;
  top: ${({ theme }) => 54 + theme.insets.top}px;
  width: 227px;
`;

const StyledBottomCurves = styled(BottomCurves)`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 169px;
  position: absolute;
  width: 100%;
`;

const FooterContainer = styled.div`
  bottom: 30px;
  padding: 0 30px;
  position: absolute;
  text-align: center;
  white-space: pre-line;
`;

const StyledWhiteCurves = styled(WhiteCurves)`
  position: absolute;
  top: ${({ theme }) => 148 + theme.insets.top}px;
  width: 100%;
`;

export const MobileHollowLayout = memo(({ children }) => {
  const { visualViewportHeight } = useGetVisualViewport();

  return (
    <MobileMain minHeight={`${visualViewportHeight}px`}>
      <TopContainer>
        <TopCurve />
        <MobileLogo />
        <StyledWhiteCurves />
      </TopContainer>
      {children}
      <BottomContainer>
        <StyledBottomCurves />
        <FooterContainer>
          <Trans
            i18nKey="account:signInPage.mobileFooter"
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href="https://leadgogo.com/terms-and-conditions/" />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href="https://leadgogo.com/privacy-policy/" />,
            ]}
          />
        </FooterContainer>
      </BottomContainer>
    </MobileMain>
  );
});

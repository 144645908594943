import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { dayjs } from '@lgg/isomorphic/dayjs';
import { FlexColumn } from 'src/components/layout/flex-column';
import { ContactInteractionsReplyModeInfoMessage } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-reply-mode-message';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';

const Message = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-style: normal;
  line-height: 15px;

  & strong {
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: 500;
  }
`;

const Cta = styled.a`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-top: 10px;
  text-decoration: none;

  &,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

const SUPPORT_EMAIL = 'support@leadgogo.com';

export const SmsResourceNotLinkedBanner = memo(() => {
  const { t } = useTranslation(['smsChannel']);
  const { hasPermission } = useAuthorization();
  const isManager = hasPermission('company.manage');

  return (
    <ContactInteractionsReplyModeInfoMessage
      lggId="sms-resource-not-linked-banner"
      title={t('smsChannel:campaignRegistry.notVerifiedMessages.title')}
      body={
        <FlexColumn>
          <Message>
            {isManager
              ? t('smsChannel:campaignRegistry.notVerifiedMessages.message.manager')
              : t('smsChannel:campaignRegistry.notVerifiedMessages.message.agent')}
          </Message>
          {isManager && (
            <Cta href={`mailto:${SUPPORT_EMAIL}`}>
              {t('smsChannel:campaignRegistry.notVerifiedMessages.cta')}
            </Cta>
          )}
        </FlexColumn>
      }
    />
  );
});

export const SmsLimitReachedBanner = memo(() => {
  const { t } = useTranslation(['smsChannel']);
  const { formatDate } = useFormatDate();

  return (
    <ContactInteractionsReplyModeInfoMessage
      lggId="sms-limit-reached-banner"
      color="secondaryGold"
      title={t('smsChannel:smsLimitReachedBanner.title')}
      body={
        <Message>
          <Trans
            i18nKey="smsChannel:smsLimitReachedBanner.message"
            components={{ strong: <strong /> }}
            values={{
              resetHour: formatDate(
                dayjs.tz(dayjs(), 'America/Los_Angeles').startOf('day').toDate(),
                'h:mm a',
                true,
              ),
            }}
          />
        </Message>
      }
    />
  );
});

export const SmsOptOutBanner = memo(() => {
  const { t } = useTranslation(['smsChannel']);

  return (
    <ContactInteractionsReplyModeInfoMessage
      lggId="sms-opt-out-banner"
      color="secondaryGold"
      title={t('smsChannel:smsOptOutBanner.title')}
      body={
        <Message>
          <Trans i18nKey="smsChannel:smsOptOutBanner.message" />
        </Message>
      }
    />
  );
});

import React from 'react';
import AntdCheckbox, { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox';
import styled from 'styled-components';

type CheckboxProps = AntdCheckboxProps;

const StyledCheckBox = styled(AntdCheckbox)`
  &.ant-checkbox-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.accentGreen};
    border-color: ${({ theme }) => theme.colors.accentGreen};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.accentGreen};
  }

  span:not(.ant-checkbox) {
    ${({ theme }) => theme.checkboxTheme.textStyle};
    align-self: flex-end;
    padding-left: 10px;
  }

  span.ant-checkbox-disabled {
    ${({ theme }) => theme.checkboxTheme.disabledTextStyle};
  }
`;

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <StyledCheckBox {...props} />;
};

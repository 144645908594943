import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';

const ContactInteractionDetailItemContainer = styled.span`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${up('md')} {
    height: 26px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
`;

const ContactInteractionDetailItemDescription = styled.span`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .title {
    font-family: ${({ theme }) => theme.font.medium};
    color: ${({ theme }) => theme.colors.smalt};
  }

  ${up('md')} {
    font-size: 12px;
  }
`;

const ContactInteractionInfoEditIcon = styled(Icon)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }

  ${up('md')} {
    margin-right: 5px;
  }
`;

export const ContactInteractionDetailsMenuTitle = styled.p`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.carbon};
  margin: 10px 15px 15px;
`;

export const ContactInteractionDetailsContextMenu = styled(FlexColumn)`
  padding: 5px 0;
  width: 270px;
`;

type ContactInteractionDetailItemProps = {
  title: string;
  value: React.ReactNode;
  onEdit?: VoidFunction;
};

export const ContactInteractionDetailItem = memo<ContactInteractionDetailItemProps>(
  ({ title, value, onEdit, ...rest }) => {
    return (
      <ContactInteractionDetailItemContainer {...rest}>
        <ContactInteractionDetailItemDescription>
          <span className="title">{`${title}: `}</span>
          {value}
        </ContactInteractionDetailItemDescription>
        {onEdit && (
          <ContactInteractionInfoEditIcon
            type="edit"
            onClick={onEdit}
            lggTestId="contact-interaction-detail-edit-button"
          />
        )}
      </ContactInteractionDetailItemContainer>
    );
  },
);

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import {
  LggDropdownButton,
  LggDropdownButtonWithoutOverlay,
} from 'src/components/general/button/dropdown-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Radio } from 'src/components/general/inputs/radio';
import { conversationDropdownButtonStyles } from 'src/components/pages/conversations/components/filters/shared';
import { ButtonsBar } from 'src/components/pages/conversations/components/general/buttons-bar';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const StyledBottomDrawer = styled(BottomDrawer)`
  .scrollbar {
    padding: 20px;
  }

  & .scrollbar label:not(:last-of-type) {
    margin-bottom: 28px !important;
  }
`;

const StyledSelect = styled(CompanyUsersSelect)`
  margin-left: 30px;
  margin-top: 4px;
  width: calc(100% - 30px);
` as typeof CompanyUsersSelect;

const FiltersOverlay = styled.div`
  padding: 20px 20px 15px;
  width: 296px;

  label:not(:last-of-type) {
    margin-bottom: 28px !important;
  }
`;

const OverlayTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  display: block;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 28px;
  text-align: left;
`;
const StyledButtonsBar = styled(ButtonsBar)`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};

  ${up('md')} {
    border-top: unset;
    justify-content: flex-end;
    padding: 15px 0 0;

    > * {
      font-size: 12px;
      padding: 0 15px;
      width: unset;
    }

    > * + * {
      margin-left: 15px;
    }
  }
`;

const DesktopDropdownButton = styled(LggDropdownButton)`
  ${conversationDropdownButtonStyles};
`;

const MobileDropdownButton = styled(LggDropdownButtonWithoutOverlay)`
  ${conversationDropdownButtonStyles};
`;

type AssigneesFilterProps = {
  selectedAssignees: number[];
  selectedAssigneeType: string;
  onApply: (filters: { assignees: number[]; assigneeType: string }) => void;
};

export const AssigneesFilter = memo<AssigneesFilterProps>(
  ({ onApply, selectedAssigneeType, selectedAssignees }) => {
    const { hasPermission } = useAuthorization();
    const isManager = hasPermission('lead.access.view.all');
    const { t } = useTranslation(['conversations', 'common']);
    const assigneesTypes: ReadonlyArray<{
      value: string;
      testId: string;
      label: string;
    }> = [
      {
        value: 'everyone',
        testId: 'assignee-radio-everyone',
        label: t('conversations:assigneesFilters.everyone'),
      },
      {
        value: 'only_me',
        testId: 'assignee-radio-only_me',
        label: t('conversations:assigneesFilters.onlyMe'),
      },
      {
        value: 'unassigned',
        testId: 'assignee-radio-unassigned',
        label: t('conversations:assigneesFilters.unassigned'),
      },
      {
        value: 'custom',
        testId: 'assignee-radio-custom',
        label: t('conversations:assigneesFilters.custom'),
      },
    ].filter((assigneeType) => {
      if (!isManager) {
        return ['only_me', 'unassigned'].includes(assigneeType.value);
      }
      return true;
    });
    const effectiveAssigneeType = selectedAssigneeType;
    const defaultAssigneeTypeFilter = assigneesTypes[0].value;
    const [assigneeType, setAssigneeType] = useState(effectiveAssigneeType);
    const visibilityHandler = useVisible();
    const [assignees, setAssignees] = useState<number[]>(selectedAssignees);
    const breakpointUpMd = useBreakpoint(up('md'));
    const selectedItem = assigneesTypes.find(
      ({ value }) => value === effectiveAssigneeType,
    );
    const handleRadioChange = (value: string) => setAssigneeType(value);
    const handleReset = () => {
      const defaultAssignees = [];
      setAssignees(defaultAssignees);
      setAssigneeType(defaultAssigneeTypeFilter);
      onApply({ assignees: defaultAssignees, assigneeType: defaultAssigneeTypeFilter });
      visibilityHandler.close();
    };
    const handleApply = () => {
      onApply({ assignees, assigneeType });
      visibilityHandler.close();
    };
    useEffect(() => {
      if (assigneeType !== 'custom' && assignees.length) {
        setAssignees([]);
      }
    }, [assigneeType, assignees.length]);

    const handleCustomAssigneeChange = useCallback((options) => {
      const validUserIds = options
        .map((option) => {
          const id = Number(option.value);

          return Number.isNaN(id) ? null : id;
        })
        .filter((id): id is number => id !== null);

      setAssignees(validUserIds);
    }, []);

    const buttonsBar = (
      <StyledButtonsBar
        primaryCallback={handleApply}
        secondaryCallback={handleReset}
        primaryText={t('common:filters.actionsApply')}
        secondaryText={t('common:filters.actionReset')}
        primaryButtonTestId="assignees-filters-apply"
        secondaryButtonTestId="assignees-filters-reset"
      />
    );
    const formInputs = (
      <>
        {assigneesTypes.map(({ value, label, testId }) => {
          return (
            <Radio
              key={value}
              value={value}
              text={label}
              testId={testId}
              name="assignees"
              onChange={handleRadioChange}
              checked={assigneeType === value}
            />
          );
        })}
        {isManager && (
          <StyledSelect
            name="assignees-filter-custom-select"
            isDisabled={assigneeType !== 'custom'}
            isMulti
            mobileLabel={t('conversations:assigneesFilters.title')}
            placeholder={t('conversations:assigneesFilters.assigneesPlaceholder')}
            selectedUsersIds={assignees}
            onChange={handleCustomAssigneeChange}
          />
        )}
      </>
    );

    if (breakpointUpMd) {
      return (
        <>
          <DesktopDropdownButton
            size="small"
            icon="everyone"
            data-lgg-id="assignees-filters-trigger-button"
            variant="defaultGhost"
            customDropdownProps={{ placement: 'bottomRight' }}
            overlay={
              <FiltersOverlay data-lgg-id="conversations-filters-overlay">
                <OverlayTitle>{t('conversations:assigneesFilters.title')}</OverlayTitle>
                {formInputs}
                {buttonsBar}
              </FiltersOverlay>
            }
            visibilityHandler={visibilityHandler}
          >
            {selectedItem?.label}
          </DesktopDropdownButton>
        </>
      );
    }

    return (
      <>
        <MobileDropdownButton
          variant="defaultWhite"
          size="regular"
          icon="everyone"
          isActive={visibilityHandler.visible}
          onClick={() => visibilityHandler.setVisible(!visibilityHandler.visible)}
        >
          {selectedItem?.label}
        </MobileDropdownButton>
        {!breakpointUpMd && (
          <StyledBottomDrawer
            fullHeight
            title={t('conversations:assigneesFilters.title')}
            visible={visibilityHandler.visible}
            onClose={visibilityHandler.close}
            footer={buttonsBar}
          >
            {formInputs}
          </StyledBottomDrawer>
        )}
      </>
    );
  },
);

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  Contact,
  QueryConversationArgs,
  Conversation,
  Query,
  QueryContactArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import { ContactAddNewDrawer } from 'src/components/domain/contacts/contact-add-new/contact-add-new';
import { ConversationContactDetails } from 'src/components/domain/contacts/contact-details/contact-details';
import { FlexColumn } from 'src/components/domain/contacts/contact-details/shared';
import {
  ViewFullContactProfileLink,
  ViewProfile,
} from 'src/components/domain/contacts/contact-details/view-full-profile-link';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BlockContactModal,
  BlockIcon,
  useContactBlock,
} from 'src/components/pages/contacts/components/contact-block';
import { ContactInteractionsList } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-list';
import {
  ContactInteractionListHeaderSkeleton,
  CONVERSATION,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-list-header';
import {
  ContactActionsIcon,
  ContactName,
  MoreOptionsIcon,
} from 'src/components/pages/conversations/components/contact-interactions/shared';
import { SnoozeConversationModal } from 'src/components/pages/conversations/components/conversations/snooze-conversation-modal/snooze-conversation-modal';
import {
  CustomTitleBottomDrawer,
  DrawerHeaderBackIcon,
} from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { OpenLegacyCallModalIcon } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';
import { useConversationSetClosed } from 'src/components/pages/conversations/hooks/use-conversation-set-closed';
import { useConversationSetMarked } from 'src/components/pages/conversations/hooks/use-conversation-set-marked';
import { useConversationUnsnooze } from 'src/components/pages/conversations/hooks/use-conversation-unsnooze';
import { useFormatLastInteractionDate } from 'src/components/pages/conversations/hooks/use-format-last-interaction-date';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { SafeAreaInsetsContext } from 'src/components/providers/safe-area-insets-provider';
import { GET_CONTACT } from 'src/hooks/gql/use-refresh-contact';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 20px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const OptionsContainer = styled(FlexRow)`
  align-items: center;

  ${ContactActionsIcon} {
    margin-left: 10px;
  }
`;

type ConversationModalHeaderProps = {
  conversationId: string;
  onClose: VoidFunction;
  globalMode?: boolean;
  onMoreOptionsClick: VoidFunction;
  onContactActionsClick: VoidFunction;
};

const ConversationModalHeader = memo<ConversationModalHeaderProps>(
  ({
    conversationId,
    onClose,
    onMoreOptionsClick,
    onContactActionsClick,
    globalMode,
  }) => {
    const { setShowContactInformation } = useConversation();
    const { data, loading } = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
    });

    if (loading)
      return (
        <ContactInteractionListHeaderSkeleton
          loading
          paragraph={{ rows: 0 }}
          title
          active
          round
        />
      );

    if (!data?.conversation) return null;

    const {
      conversation: { contact },
    } = data;

    const { label, primaryPhone } = contact;

    return (
      <div style={{ display: 'flex', alignItems: 'center', flex: 1, minWidth: 0 }}>
        {!globalMode && <DrawerHeaderBackIcon onClick={onClose} />}
        <ContactName
          clickable={!globalMode}
          onClick={!globalMode ? () => setShowContactInformation(true) : undefined}
        >
          {label}
        </ContactName>
        <Expand />
        <OptionsContainer>
          {primaryPhone && <OpenLegacyCallModalIcon contact={contact} />}
          <ContactActionsIcon type="contactActions" onClick={onContactActionsClick} />
          <MoreOptionsIcon type="moreOptions" onClick={onMoreOptionsClick} />
          {globalMode && <CloseIcon onClick={onClose} type="close" />}
        </OptionsContainer>
      </div>
    );
  },
);

const ConversationModalBottomDrawer = styled(CustomTitleBottomDrawer)`
  background-color: rgba(114, 125, 142, 0.7);
`;

const StyledDrawerTitle = styled(FlexRow)`
  align-items: center;
  width: 100%;
`;

const ContactLabel = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 26px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`;

const LastInteractionLabel = styled.label`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;

const ContactLabelSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    height: 24px;
    margin: 0;
  }
`;

const LastInteractionLabelSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    height: 15px;
    margin: 1px 0 0;
  }
`;

type DrawerTitleProps = {
  contactId: Contact['id'];
  onClose: VoidFunction;
};

const DrawerTitle = memo<DrawerTitleProps>(({ contactId, onClose }) => {
  const { formatLastInteractionDate } = useFormatLastInteractionDate();
  const handleGraphQLError = useHandleGraphQLError();
  const { data, loading } = useQuery<Pick<Query, 'contact'>, QueryContactArgs>(
    GET_CONTACT,
    {
      variables: {
        id: contactId,
      },
      onError: handleGraphQLError,
    },
  );

  return (
    <StyledDrawerTitle>
      <FlexColumn style={{ flex: 1, minWidth: 0 }}>
        {loading || !data ? (
          <>
            <ContactLabelSkeleton active paragraph={false} title={{ width: 146 }} />
            <LastInteractionLabelSkeleton
              active
              paragraph={false}
              title={{ width: 166 }}
            />
          </>
        ) : (
          <>
            <ContactLabel>
              {loading || !data ? <Skeleton /> : data.contact.label}
            </ContactLabel>
            {data.contact.lastContactInteraction && (
              <LastInteractionLabel>
                {formatLastInteractionDate(
                  data.contact.lastContactInteraction.occurredAt,
                )}
              </LastInteractionLabel>
            )}
          </>
        )}
      </FlexColumn>
      <CloseIcon type="close" onClick={onClose} />
    </StyledDrawerTitle>
  );
});

const StyledViewFullContactProfileLink = styled(ViewFullContactProfileLink)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  font-size: 14px;
  padding: 15px 20px;

  ${ViewProfile} {
    color: ${({ theme }) => theme.colors.gogo};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: ${({ theme }) => theme.font.medium};
    letter-spacing: normal;
    line-height: 17px;
    text-align: left;
  }
`;

type ContactDetailDrawerProps = {
  visible: boolean;
  conversationId: Conversation['id'];
  onClose: VoidFunction;
};

const ContactDetailDrawer = memo<ContactDetailDrawerProps>(
  ({ conversationId, onClose, visible }) => {
    const { data, loading } = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
    });

    if (loading) return <div>Loading</div>;
    if (!data?.conversation) return null;

    const {
      conversation: {
        contact: { id: contactId },
      },
    } = data;

    return (
      <CustomTitleBottomDrawer
        fullHeight
        onClose={onClose}
        push={false}
        title={<DrawerTitle contactId={contactId} onClose={onClose} />}
        visible={visible}
      >
        <>
          <StyledViewFullContactProfileLink contactId={contactId} />
          <ConversationContactDetails
            contactId={contactId}
            showHeader={false}
            conversationId={conversationId}
          />
        </>
      </CustomTitleBottomDrawer>
    );
  },
);

type ContactActionsDrawerProps = {
  visible: boolean;
  conversationId: Conversation['id'];
  onClose: VoidFunction;
};

const ContactActionsDrawer = memo<ContactActionsDrawerProps>(
  ({ conversationId, onClose, visible }) => {
    const { data, loading } = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
    });

    if (loading) return <div>Loading</div>;
    if (!data?.conversation) return null;

    const {
      conversation: {
        contact: { id: contactId },
      },
    } = data;

    return (
      <ContactAddNewDrawer visible={visible} onClose={onClose} contactId={contactId} />
    );
  },
);

type GetConversationStateDrawerOptionsProps = {
  conversation?: Conversation;
  setClosedClickHandler: (conversationId: string, isOpen: boolean) => void;
  setMarkedClickHandler: (conversationId: string, isMarked: boolean) => void;
  setUnsnoozeClickHandler: (conversationId: string, isSnoozed: boolean) => void;
};

export const useGetConversationStateDrawerOptions = ({
  conversation,
  setClosedClickHandler,
  setMarkedClickHandler,
  setUnsnoozeClickHandler,
}: GetConversationStateDrawerOptionsProps) => {
  const { t } = useTranslation(['conversations']);

  if (!conversation) {
    return null;
  }

  const { id, isOpen, isMarked, isSnoozed } = conversation;

  return [
    {
      onClick: () => setClosedClickHandler(id, isOpen),
      label: isOpen
        ? t('conversations:conversationOptions.close')
        : t('conversations:conversationOptions.reopen'),
      icon: isOpen ? 'circularCheck' : 'openConversation',
    } as DrawerOption,
    {
      onClick: () => setMarkedClickHandler(id, isMarked),
      label: isMarked
        ? t('conversations:conversationOptions.unmark')
        : t('conversations:conversationOptions.mark'),
      icon: isMarked ? 'unmark' : 'opportunity',
    } as DrawerOption,
    {
      label: isSnoozed
        ? t('conversations:snoozedConversationBanner.discard')
        : t('conversations:conversationOptions.snooze'),
      icon: isSnoozed ? 'discardSnooze' : 'snooze',
      onClick: () => setUnsnoozeClickHandler(id, isSnoozed),
    } as DrawerOption,
  ];
};

type ConversationOptionsDrawerProps = {
  visible: boolean;
  onClose: VoidFunction;
  onShowContactInformation: VoidFunction;
  conversationId: Conversation['id'];
  globalMode?: boolean;
};

const ConversationOptionsDrawer = memo<ConversationOptionsDrawerProps>(
  ({ visible, globalMode, onClose, onShowContactInformation, conversationId }) => {
    const { t } = useTranslation(['header', 'conversations', 'contacts']);
    const conversationSetClosed = useConversationSetClosed();
    const conversationSetMarked = useConversationSetMarked();
    const { unsnoozeConversation } = useConversationUnsnooze();
    const { getLegacyContactEditUrl } = useUrls();
    const blockContactModalVisibility = useVisible();
    const { getFeatureFlag } = useAuthorization();

    const {
      visible: snoozeConversationModalVisible,
      show: showSnoozeConversationModal,
      close: closeSnoozeConversationModal,
    } = useVisible();
    const { data, loading } = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
    });

    const contact = data?.conversation?.contact;
    const { optionLabel: contactBlockOptionLabel, changeBlockStatus } = useContactBlock({
      contact,
    });

    const conversationOptions = useGetConversationStateDrawerOptions({
      conversation: data?.conversation ?? undefined,
      setClosedClickHandler: async (id, isOpen) => {
        await conversationSetClosed({ conversationId: id, isClosed: isOpen });
      },
      setMarkedClickHandler: (id, isMarked) => {
        void conversationSetMarked({
          conversationId: id,
          isMarked: !isMarked,
        });
      },
      setUnsnoozeClickHandler: async (id, isSnoozed) => {
        if (isSnoozed) {
          await unsnoozeConversation({
            variables: {
              conversationId: id,
            },
          });
        } else {
          void showSnoozeConversationModal();
        }
      },
    });

    const getOptions = useCallback(
      (contactId: number) => {
        const showEditContactModal = () => {
          openRemoteModalWrapper(getLegacyContactEditUrl(contactId));
        };

        const options: DrawerOption[] = conversationOptions ?? [];

        if (!globalMode) {
          options.push(
            {
              onClick: onShowContactInformation,
              label: t('conversations:conversationOptions.viewDetails'),
              icon: 'details',
            },
            {
              label: t('conversations:conversationOptions.editContact'),
              icon: 'edit',
              onClick: showEditContactModal,
            },
          );
        }

        if (getFeatureFlag('block-contacts')) {
          options.push({
            onClick: async () => await changeBlockStatus(blockContactModalVisibility),
            label: contactBlockOptionLabel,
            icon: <BlockIcon type="block" />,
          });
        }

        return options;
      },
      [
        blockContactModalVisibility,
        changeBlockStatus,
        contactBlockOptionLabel,
        conversationOptions,
        getFeatureFlag,
        getLegacyContactEditUrl,
        globalMode,
        onShowContactInformation,
        t,
      ],
    );

    if (loading) return <div>Loading</div>;
    if (!data?.conversation) return null;

    const {
      conversation: {
        contact: { id: contactId },
      },
    } = data;

    return (
      <>
        <OptionsBottomDrawer
          visible={visible}
          zIndex={ModalsZIndex}
          title={t('conversations:conversationOptions.title')}
          onClose={onClose}
          options={getOptions(contactId)}
        />
        <SnoozeConversationModal
          zIndex={ModalsZIndex}
          visible={snoozeConversationModalVisible}
          onClose={closeSnoozeConversationModal}
          conversationId={conversationId}
        />
        <BlockContactModal
          visibilityHandler={blockContactModalVisibility}
          contact={data.conversation.contact}
          zIndex={ModalsZIndex}
        />
      </>
    );
  },
);

type ConversationModalProps = {
  close: VoidFunction;
  visible: boolean;
  selectedConversationId: string | null;
  globalMode?: boolean;
  zIndex?: number;
  contactInteractionId?: string | null;
  handleOnLoadConversation?: ValueChanged<Conversation>;
};

export const ConversationModal = memo<ConversationModalProps>(
  ({
    close,
    zIndex,
    contactInteractionId,
    visible,
    selectedConversationId,
    globalMode,
    handleOnLoadConversation,
  }) => {
    const insets = useContext(SafeAreaInsetsContext);
    const DEFAULT_MODAL_HEIGHT = useMemo(() => {
      return insets ? `calc(100% - ${insets.top}px - 5px)` : 'calc(100% - 5px)';
    }, [insets]);
    const {
      visible: optionsModalVisible,
      show: showOptionsModal,
      close: closeOptionsModal,
    } = useVisible();
    const {
      visible: contactActionsModalVisible,
      show: showContactActionsModal,
      close: closeContactActionsModal,
    } = useVisible();
    const { showContactInformation, setShowContactInformation } = useConversation();
    const breakpointUpMd = useBreakpoint(up('md'));
    const [modalHeight, setModalHeight] = useState<string>(DEFAULT_MODAL_HEIGHT);
    const winnerHeight = useCallback(() => {
      return window.innerHeight - (5 + (insets ? insets.top : 0));
    }, [insets]);

    useEffect(() => {
      const resizeHandler = () => {
        if (modalHeight !== DEFAULT_MODAL_HEIGHT) {
          const height = breakpointUpMd ? DEFAULT_MODAL_HEIGHT : `${winnerHeight()}px`;

          setModalHeight(height);
        }
      };

      const modalHeightHandler = () => {
        if (!breakpointUpMd) {
          const newHeight = `${winnerHeight()}px`;
          setModalHeight(newHeight);
        }
      };

      window.visualViewport!.addEventListener('resize', modalHeightHandler);
      window.addEventListener('resize', resizeHandler);

      return () => {
        window.visualViewport!.removeEventListener('resize', modalHeightHandler);
        window.removeEventListener('resize', resizeHandler);
      };
    }, [DEFAULT_MODAL_HEIGHT, breakpointUpMd, insets, modalHeight, winnerHeight]);

    if (!selectedConversationId) {
      return null;
    }

    return (
      <>
        <ConversationModalBottomDrawer
          height={modalHeight}
          push={false}
          zIndex={zIndex}
          onClose={close}
          title={
            <ConversationModalHeader
              onClose={close}
              globalMode={globalMode}
              onMoreOptionsClick={showOptionsModal}
              onContactActionsClick={showContactActionsModal}
              conversationId={selectedConversationId}
            />
          }
          visible={visible}
        >
          <ContactInteractionsList
            contactInteractionIdParam={contactInteractionId}
            conversationId={selectedConversationId}
            onLoadConversation={handleOnLoadConversation}
          />
        </ConversationModalBottomDrawer>
        <ConversationOptionsDrawer
          globalMode={globalMode}
          conversationId={selectedConversationId}
          visible={optionsModalVisible}
          onClose={closeOptionsModal}
          onShowContactInformation={() => {
            setShowContactInformation(true);
            closeOptionsModal();
          }}
        />
        <ContactActionsDrawer
          conversationId={selectedConversationId}
          visible={contactActionsModalVisible}
          onClose={closeContactActionsModal}
        />
        <ContactDetailDrawer
          visible={showContactInformation}
          conversationId={selectedConversationId}
          onClose={() => setShowContactInformation(false)}
        />
      </>
    );
  },
);

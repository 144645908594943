import React, { memo } from 'react';
import {
  ConversationContactDetails,
  ContactDetailsSkeleton,
} from 'src/components/domain/contacts/contact-details/contact-details';
import { ContactInformationTitle } from 'src/components/pages/conversations/components/contact-information/contact-information-title';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';

type Props = {
  showHeader?: boolean;
};

export const ContactInformationSection = memo(({ showHeader = true }: Props) => {
  const { selectedContactId, selectedConversationId } = useConversation();

  if (!selectedContactId || !selectedConversationId) {
    return <ContactDetailsSkeleton />;
  }

  return (
    <>
      {showHeader && <ContactInformationTitle />}
      <ConversationContactDetails
        contactId={selectedContactId}
        conversationId={selectedConversationId}
      />
    </>
  );
});

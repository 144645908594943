import { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

export function useBreakpoint(breakpoint: Function): boolean {
  const theme = useTheme();
  const mediaQuery = breakpoint({ theme }).replace(/^@media\s*/, '');
  const [isBreak, setIsBreak] = useState(window.matchMedia(mediaQuery).matches);
  const mq = useMemo(() => window.matchMedia(mediaQuery), [mediaQuery]);

  useEffect(() => {
    const handleChange = (event) => {
      setIsBreak(event.matches);
    };

    // Safari < 14 can't use addEventListener on a MediaQueryList
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
    if (!mq.addEventListener) {
      // Update the state whenever the media query match state changes
      mq.addListener(handleChange);

      // Clean up on unmount and if the query changes
      return () => {
        mq.removeListener(handleChange);
      };
    }
    mq.addEventListener('change', handleChange);

    return () => {
      mq.removeEventListener('change', handleChange);
    };
  }, [mq]);

  return isBreak;
}

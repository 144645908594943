import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Mutation } from '@lgg/isomorphic/types/__generated__/graphql';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { DEAUTHORIZE_FACEBOOK_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';

export const useFacebookSettings = () => {
  const { visibilityHandler } = useProfileSettings();

  const [deauthorizeFacebook] = useMutation<Pick<Mutation, 'deauthorizeFacebook'>>(
    DEAUTHORIZE_FACEBOOK_MUTATION,
  );

  const showNotification = useShowNotification();

  const { t } = useTranslation(['user']);

  const handleDeauthorization = useCallback(() => {
    void deauthorizeFacebook({
      onCompleted: () => {
        visibilityHandler.close();
        showNotification({
          type: 'success',
          title: t('user:profileSettings.sections.facebookSettings.toasts.success'),
        });
      },
      onError: () => {
        visibilityHandler.close();
        showNotification({
          type: 'error',
          title: t('user:profileSettings.sections.facebookSettings.toasts.error'),
        });
      },
    });
  }, [deauthorizeFacebook, visibilityHandler, showNotification, t]);

  return {
    handleDeauthorization,
  };
};

import { useService } from 'react-service-locator';
import { AuthorizationService } from 'src/services/authorization.service';

export function useCurrentInstitution() {
  const { currentInstitution } = useService(AuthorizationService, (s) => [
    s.currentInstitution,
  ]);

  return currentInstitution;
}

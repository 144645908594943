import React, { ComponentProps, memo } from 'react';
import Lottie from 'lottie-react';
import styled, { css } from 'styled-components';
import loadingAnimation from 'src/assets/animations/select_spinner.json';
import {
  BaseButtonIcon,
  IconNotificationProps,
} from 'src/components/general/button/lgg-button';
import {
  ButtonSize,
  ButtonVariant,
  isomorphicColorButtonStyles,
} from 'src/components/general/button/shared';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { smallAccentFab, bigAccentFab } from 'src/theme/sub-themes/buttons-theme';

type IconButtonProps = WithClassname & {
  onClick: VoidFunction;
  icon: string;
};

const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  outline: none !important;
  padding: 0;
`;

export const IconButton = memo<IconButtonProps>(({ onClick, icon, ...rest }) => {
  return (
    <StyledButton {...rest} onClick={onClick}>
      <Icon type={icon} />
    </StyledButton>
  );
});

export const Fab = styled(IconButton)<{ size: 'small' | 'big' }>`
  ${({ size }) => (size === 'small' ? smallAccentFab : bigAccentFab)}
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const ButtonIcon = styled(Icon)``;

const iconButtonV2BaseStyles = css<{ size: ButtonSize }>`
  align-items: center;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  ${({ size }) => {
    switch (size) {
      case 'regular':
        return `
          height: 38px;
          width: 38px;
          ${ButtonIcon} svg {
            height: 18px;
            width: 18px;
          }
        `;
      case 'small':
        return `
          height: 34px;
          width: 34px;
          ${ButtonIcon} svg {
            height: 16px;
            width: 16px;
          }
        `;
    }
  }}
`;

export const StyledIconButtonV2 = styled.button<{
  size: ButtonSize;
  variant: ButtonVariant;
}>`
  ${iconButtonV2BaseStyles};
  ${isomorphicColorButtonStyles};
`;

type ButtonTooltipProps = { children: React.ReactNode; title: string };

const ButtonTooltip = ({ children, title }: ButtonTooltipProps) => (
  <Tooltip
    title={title}
    overlay={null}
    className="button-tooltip"
    trigger="hover"
    arrowPointAtCenter
    align={{
      offset: [0, 6],
    }}
  >
    {children}
  </Tooltip>
);

const LoadingAnimation = styled(Lottie)`
  height: 18px;
  width: 18px;

  svg path {
    stroke: ${({ theme }) => theme.colors.flint};
  }
`;

type IconButtonV2Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size: ButtonSize;
  variant?: ButtonVariant;
  icon: ComponentProps<typeof Icon>['type'];
  notificationProps?: IconNotificationProps;
  tooltipText?: string;
  loading?: boolean;
};

export const IconButtonV2 = memo<IconButtonV2Props>(
  ({
    variant = 'primary',
    size,
    children,
    icon,
    notificationProps,
    tooltipText = '',
    disabled,
    loading,
    ...rest
  }) => {
    const iconButton = (
      <StyledIconButtonV2 {...rest} size={size} variant={variant} disabled={disabled}>
        {loading ? (
          <LoadingAnimation animationData={loadingAnimation} loop={true} />
        ) : (
          <BaseButtonIcon
            type={icon}
            $showNotification={notificationProps?.showNotification}
            $notificationColor={notificationProps?.notificationColor}
          />
        )}
      </StyledIconButtonV2>
    );
    return tooltipText ? (
      <ButtonTooltip title={tooltipText && !disabled ? tooltipText : ''}>
        {iconButton}
      </ButtonTooltip>
    ) : (
      iconButton
    );
  },
);

const fabV2BaseStyles = css`
  align-items: center;
  border-radius: 50%;
  border: none;
  display: flex;
  height: 46px;
  justify-content: center;
  padding: 10px;
  width: 46px;

  ${ButtonIcon} {
    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

export const StyledFabV2 = styled.button<{ variant: ButtonVariant }>`
  ${fabV2BaseStyles};
  ${isomorphicColorButtonStyles};
`;

type FabV2Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: ButtonVariant;
  icon: ComponentProps<typeof Icon>['type'];
  onClick?: VoidFunction;
};

export const FabV2 = memo<FabV2Props>(({ icon, children, ...rest }) => {
  return (
    <StyledFabV2 {...rest}>
      <ButtonIcon type={icon} />
    </StyledFabV2>
  );
});

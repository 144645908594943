import React, { memo, useState } from 'react';
import c from 'classnames';
import EmojiPicker, {
  EmojiStyle,
  SuggestionMode,
  EmojiClickData,
  SkinTones,
} from 'emoji-picker-react';
import styled from 'styled-components';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEY_DEFAULT_EMOJI_PICKER_SKIN_TONE,
  setLocalStorageItem,
} from 'src/utils/local-storage';

const EmojiPickerWrapper = styled.span`
  .EmojiPickerReact .epr-preview {
    display: none;
  }
`;

const getSkinToneByValue = (skinToneValue: string) => {
  const [skinTone] = Object.entries(SkinTones).find(
    ([, value]) => value === skinToneValue,
  ) || [SkinTones.NEUTRAL];

  return skinTone;
};

type LggEmojiPickerPros = {
  onEmojiClick: ValueChanged<EmojiClickData>;
};

export const LggEmojiPicker = memo(({ onEmojiClick }: LggEmojiPickerPros) => {
  const defaultSkinTonePreviousValue =
    getLocalStorageItem(LOCAL_STORAGE_KEY_DEFAULT_EMOJI_PICKER_SKIN_TONE) ?? '';
  const [unfocused, setUnfocused] = useState<boolean>(true);

  return (
    <EmojiPickerWrapper
      className={c({ unfocused })}
      onMouseEnter={(e) => {
        setUnfocused(false);
      }}
    >
      <EmojiPicker
        onEmojiClick={(emojiData) => {
          onEmojiClick(emojiData);

          if (defaultSkinTonePreviousValue !== emojiData.activeSkinTone) {
            setLocalStorageItem(
              LOCAL_STORAGE_KEY_DEFAULT_EMOJI_PICKER_SKIN_TONE,
              emojiData.activeSkinTone,
            );
          }
        }}
        defaultSkinTone={
          SkinTones[getSkinToneByValue(defaultSkinTonePreviousValue)] ?? SkinTones.NEUTRAL
        }
        suggestedEmojisMode={SuggestionMode.RECENT}
        width={284}
        height={326}
        emojiStyle={EmojiStyle.NATIVE}
        previewConfig={{
          defaultCaption: '',
        }}
      />
    </EmojiPickerWrapper>
  );
});

import React, { memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { UnDecoratedTextInput } from 'src/components/general/inputs/text-input';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useContactSetBlocked } from 'src/components/pages/contacts/hooks/use-contact-set-blocked';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useSubscribeToEvent } from 'src/hooks/use-subscribe-to-event';
import { useVisible, VisibilityHandler } from 'src/hooks/use-visible';

export const BlockIcon = styled(Icon)`
  svg {
    height: 18px;
    width: 18px;
  }
`;

const BlockedLabel = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.secondaryCoral};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-top: 1px;
  text-align: left;

  ${BlockIcon} {
    margin-right: 5px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  ${up('md')} {
    align-items: center;
    display: contents;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const useContactBlock = ({ contact }: { contact?: Contact | null }) => {
  const { t } = useTranslation(['contacts']);
  const { formatDate } = useFormatDate();
  const contactSetBlocked = useContactSetBlocked();
  const tooltipVisibilityHandler = useVisible();

  const isBlocked = contact?.isBlocked ?? false;
  const optionLabel = isBlocked
    ? t('contacts:blockContact.options.unblock')
    : t('contacts:blockContact.options.block');
  const blockingDate = contact?.blockedAt
    ? formatDate(new Date(contact.blockedAt), 'MMM D')
    : null;
  const canNotInteractElement = (
    <span style={{ marginLeft: '2px' }}>
      {t('contacts:blockContact.blockMessages.canNotInteract')}
    </span>
  );

  const message = contact?.blockReason ? (
    <Trans
      data-lgg-id="block-reason"
      i18nKey="contacts:blockContact.blockMessages.withReason"
      values={{
        userFullName: contact.blockedBy?.fullName,
        blockingDate,
        reason: contact.blockReason,
      }}
    />
  ) : (
    <Trans
      i18nKey="contacts:blockContact.blockMessages.general"
      values={{
        userFullName: contact?.blockedBy?.fullName,
        blockingDate,
      }}
    />
  );

  const unblockContact = async () => {
    if (!contact?.id) {
      throw new Error('Contact id is required');
    }

    await contactSetBlocked({ contactId: contact.id, isBlocked: false });
  };

  const blockLabel = isBlocked ? t('contacts:blockContact.blocked') : null;

  return {
    optionLabel,
    blockedIndicator: blockLabel && (
      <Tooltip
        trigger={['hover']}
        title={message}
        visible={tooltipVisibilityHandler.visible}
        placement="bottomLeft"
        overlayInnerStyle={{ textAlign: 'center' }}
      >
        <span
          onMouseEnter={tooltipVisibilityHandler.show}
          onMouseLeave={tooltipVisibilityHandler.close}
        >
          <BlockedLabel data-lgg-id="blocked-label">
            <BlockIcon type="block" />
            {blockLabel}
          </BlockedLabel>
        </span>
      </Tooltip>
    ),
    replyInputBox: {
      title: t('contacts:blockContact.replyInputBox.title'),
      message: (
        <>
          {message}
          {canNotInteractElement}
        </>
      ),
      actionButton: t('contacts:blockContact.replyInputBox.actionButton'),
    },
    changeBlockStatus: async (modalVisibilityHandler: VisibilityHandler) => {
      if (contact?.isBlocked) {
        await unblockContact();
      } else {
        modalVisibilityHandler.show();
      }
    },
    unblockContact,
  };
};

const StyledModal = styled(LggModal)`
  margin: 0 auto;
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    max-width: unset;
    width: 100%;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    height: 100%;
  }

  ${up('md')} {
    max-width: 470px;
  }
`;

type BlockContactModalProps = {
  visibilityHandler: VisibilityHandler;
  contact: Contact;
  zIndex?: number;
};

const BlockContactModalTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  flex: 1;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 24px;
  margin-right: 10px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${up('md')} {
    font-size: 24px;
    line-height: 29px;
  }
`;

const BlockContactModalHeader = styled(FlexRow)`
  align-items: center;
  margin-bottom: 10px;
`;

const BlockContactModalSection = styled(FlexColumn)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 20px;
`;

const BlockContactModalMessage = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 17px;
  text-align: left;

  & + & {
    margin-top: 17px;
  }
`;

const CloseIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;
  }

  path {
    fill: ${({ theme }) => theme.colors.casper};
  }
`;

const ReasonTitle = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 17px;
  margin-bottom: 10px;
  text-align: left;

  strong {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: 500;
  }
`;

const SubmitButton = styled(ButtonV2)<{ $color: ColorPaletteItem }>`
  margin-left: 15px;

  &,
  &:focus,
  &:hover,
  &:active {
    background-color: ${({ theme, $color }) => theme.colors[$color]};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const UnblockContactModalMessage = styled(BlockContactModalMessage)`
  margin-bottom: 32px;

  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const ButtonsSection = styled(FlexRow)`
  justify-content: flex-end;
  padding: 15px 20px;
`;

export const BlockContactModal = memo<BlockContactModalProps>(
  ({ visibilityHandler, contact, zIndex }) => {
    const { t } = useTranslation(['contacts', 'common']);
    const [reason, setReason] = useState('');
    const contactSetBlocked = useContactSetBlocked();
    const breakpointUpMd = useBreakpoint(up('md'));

    return (
      <StyledModal
        data-lgg-id="block-contact-modal"
        visible={visibilityHandler.visible}
        zIndex={zIndex ?? 1040}
        closeIcon={null}
        onClose={visibilityHandler.close}
        width={breakpointUpMd ? '470px' : 'calc(100% - 40px)'}
        maskTransitionName=""
      >
        <BlockContactModalSection>
          <BlockContactModalHeader>
            <BlockContactModalTitle data-lgg-id="block-contact-modal-title">
              {t('contacts:blockContact.modal.title', {
                contactLabel: contact.label,
              })}
            </BlockContactModalTitle>
            <CloseIcon
              data-lgg-id="close-icon"
              type="close"
              onClick={visibilityHandler.close}
            />
          </BlockContactModalHeader>
          <BlockContactModalMessage>
            {t('contacts:blockContact.modal.messages.first')}
          </BlockContactModalMessage>
          <BlockContactModalMessage>
            {t('contacts:blockContact.modal.messages.second')}
          </BlockContactModalMessage>
        </BlockContactModalSection>
        <BlockContactModalSection>
          <ReasonTitle>
            <Trans
              i18nKey="contacts:blockContact.modal.messages.blockingReasonTitle"
              components={{ strong: <strong /> }}
            />
          </ReasonTitle>
          <UnDecoratedTextInput
            autoCapitalize="off"
            value={reason}
            data-lgg-id="block-contact-modal-reason-input"
            placeholder={t('contacts:blockContact.modal.inputPlaceholder')}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
        </BlockContactModalSection>
        <ButtonsSection>
          <ButtonV2 variant="default" size="regular" onClick={visibilityHandler.close}>
            {t('common:cancel')}
          </ButtonV2>
          <SubmitButton
            $color="secondaryCoral"
            data-lgg-id="block-contact-modal-submit-button"
            variant="default"
            size="regular"
            onClick={async () => {
              await contactSetBlocked({
                contactId: contact.id,
                isBlocked: true,
                blockingReason: reason,
              });

              visibilityHandler.close();
            }}
          >
            {t('contacts:blockContact.modal.submitButton')}
          </SubmitButton>
        </ButtonsSection>
      </StyledModal>
    );
  },
);

export const UnblockContactModal = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['contacts', 'common']);
  const [contact, setContact] = useState<Contact | null>(null);
  const { unblockContact } = useContactBlock({ contact });

  const handleShow = (contact: Contact) => setContact(contact);

  const handleClose = () => setContact(null);
  const visible = contact !== null;

  useSubscribeToEvent({
    topic: 'SHOW_UNBLOCK_CONTACT_MODAL',
    callback: (_, data) => {
      handleShow(data.contact);
    },
  });

  if (!contact) {
    return null;
  }

  return (
    <StyledModal
      data-lgg-id="unblock-contact-modal"
      visible={visible}
      zIndex={ModalsZIndex}
      closeIcon={null}
      onClose={handleClose}
      width={breakpointUpMd ? '420px' : 'calc(100% - 40px)'}
      maskTransitionName=""
    >
      <BlockContactModalSection>
        <BlockContactModalHeader>
          <BlockContactModalTitle data-lgg-id="unblock-contact-modal-title">
            {t('contacts:blockContact.unblockForCallingModal.title', {
              contactLabel: contact.label,
            })}
          </BlockContactModalTitle>
          <CloseIcon data-lgg-id="close-icon" type="close" onClick={handleClose} />
        </BlockContactModalHeader>
        <UnblockContactModalMessage>
          {t('contacts:blockContact.unblockForCallingModal.message')}
        </UnblockContactModalMessage>
      </BlockContactModalSection>
      <ButtonsSection>
        <ButtonV2 variant="default" size="regular" onClick={handleClose}>
          {t('common:cancel')}
        </ButtonV2>
        <SubmitButton
          $color="cosmo"
          data-lgg-id="unblock-contact-modal-submit-button"
          variant="default"
          size="regular"
          onClick={async () => {
            await unblockContact();

            handleClose();
          }}
        >
          {t('contacts:blockContact.unblockForCallingModal.submitButton')}
        </SubmitButton>
      </ButtonsSection>
    </StyledModal>
  );
});

const StyledBlockedContactBanner = styled(FlexRow)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryCoral60};
  box-shadow: 0 5px 10px 0 #727d8e0f;
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin: 15px 20px;
  padding: 15px;
  text-align: left;

  ${BlockIcon} {
    margin-right: 10px;
  }

  strong {
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: 500;
  }
`;

const Title = styled.strong`
  margin-bottom: 5px;
`;

const Cta = styled.strong`
  color: ${({ theme }) => theme.colors.gogo};
  cursor: pointer;
  margin-top: 10px;
`;

export const BlockedContactBanner = ({ contact }: { contact: Contact }) => {
  const { replyInputBox } = useContactBlock({ contact });
  const contactSetBlocked = useContactSetBlocked();

  return (
    <StyledBlockedContactBanner>
      <BlockIcon type="block" />
      <FlexColumn>
        <Title>{replyInputBox.title}</Title>
        <span data-lgg-id="contact-blocked-banner-message">{replyInputBox.message}</span>
        <Cta
          data-lgg-id="contact-blocked-banner-unblock-button"
          onClick={() => {
            void contactSetBlocked({ contactId: contact.id, isBlocked: false });
          }}
        >
          {replyInputBox.actionButton}
        </Cta>
      </FlexColumn>
    </StyledBlockedContactBanner>
  );
};

export const StyledContactBlockedBadge = styled(FlexRow)`
  height: 18px;
  padding: 3px 5px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  color: ${({ theme }) => theme.colors.secondaryCoral};
  align-items: center;
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryCoral60};
  width: fit-content;

  ${BlockIcon} {
    margin-right: 5px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export const ContactBlockedBadge = memo((props) => {
  const { t } = useTranslation(['contacts']);

  return (
    <StyledContactBlockedBadge {...props}>
      <BlockIcon type="block" />
      <span>{t('contacts:blockContact.blocked')}</span>
    </StyledContactBlockedBadge>
  );
});

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled, { createGlobalStyle, css } from 'styled-components';
import {
  ChangeSchedulePriorityInput,
  Mutation,
  Schedule,
  SchedulePriority,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggSelectableOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useVisible, VisibilityHandler } from 'src/hooks/use-visible';

const CHANGE_APPOINTMENT_PRIORITY_MUTATION = gql`
  mutation changeSchedulePriority(
    $scheduleId: Int!
    $priority: SchedulePriority!
    $institutionId: Int!
  ) {
    changeSchedulePriority(
      input: {
        scheduleId: $scheduleId
        priority: $priority
        institutionId: $institutionId
      }
    ) {
      schedule {
        id
        status
      }
    }
  }
`;

const PriorityIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }

  &[data-value='LOW'] {
    background-color: ${({ theme }) => theme.colors.secondaryTopaz80};
  }

  &[data-value='NORMAL'] {
    background-color: ${({ theme }) => theme.colors.secondaryGold80};
  }

  &[data-value='HIGH'] {
    background-color: ${({ theme }) => theme.colors.secondaryCoral80};
  }
`;

const selectedPriorityOptionStyles = css`
  .selected.has-custom-icons {
    .adaptive-option-label {
      color: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

export const PriorityOptionsDrawerStyle = createGlobalStyle`
  .ant-drawer.appointments-priority-options-drawer {
    ${PriorityIcon} {
      width: 18px;
      height: 18px;
    }

    ${selectedPriorityOptionStyles}
  }

  .ant-dropdown.appointments-priority-options-dropdown {
    ${selectedPriorityOptionStyles}
  }
`;

enum PriorityIconMap {
  LOW = 'priorityLow',
  NORMAL = 'priorityNormal',
  HIGH = 'priorityHigh',
}

type AppointmentPriorityPickerProps = {
  appointmentId: Schedule['id'];
  priority?: Schedule['priority'];
  mobileVisibilityHandler: VisibilityHandler;
  isMobileTargetAppointment: boolean;
  onPriorityUpdated: VoidFunction;
};

export const AppointmentPriorityPicker = ({
  appointmentId,
  priority,
  mobileVisibilityHandler,
  isMobileTargetAppointment,
  onPriorityUpdated,
}: AppointmentPriorityPickerProps) => {
  const { t } = useTranslation(['appointments']);
  const desktopVisibilityHandler = useVisible(false);
  const breakpointUpMd = useBreakpoint(up('md'));
  const { id: institutionId } = useCurrentInstitution();
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();

  const [updatePriority] = useMutation<
    Pick<Mutation, 'changeSchedulePriority'>,
    ChangeSchedulePriorityInput
  >(CHANGE_APPOINTMENT_PRIORITY_MUTATION);

  const changeAppointmentPriority = useCallback(
    async (priority: SchedulePriority) => {
      await updatePriority({
        variables: {
          institutionId: institutionId,
          priority,
          scheduleId: appointmentId,
        },
        onCompleted: () => {
          showNotification({
            type: 'success',
            title: t('appointments:appointmentUpdatedMessages.success'),
          });

          onPriorityUpdated();
        },
        onError: handleGraphQLError,
      });
    },
    [
      appointmentId,
      handleGraphQLError,
      institutionId,
      onPriorityUpdated,
      showNotification,
      t,
      updatePriority,
    ],
  );

  if (!priority) return null;

  const triggerElement = (
    <PriorityIcon
      type={PriorityIconMap[priority]}
      data-value={priority}
      data-lgg-id="appointment-priority-picker"
    />
  );

  const options = [
    {
      label: t('appointments:priorityPicker.options.low'),
      'data-lgg-id': 'appointment-priority-option-low',
      icon: <PriorityIcon type={PriorityIconMap.LOW} data-value="LOW" />,
      value: 'LOW',
      onClick: async () => {
        await changeAppointmentPriority('LOW');
      },
    },
    {
      label: t('appointments:priorityPicker.options.normal'),
      'data-lgg-id': 'appointment-priority-option-normal',
      icon: <PriorityIcon type={PriorityIconMap.NORMAL} data-value="NORMAL" />,
      value: 'NORMAL',
      onClick: async () => {
        await changeAppointmentPriority('NORMAL');
      },
    },
    {
      label: t('appointments:priorityPicker.options.high'),
      'data-lgg-id': 'appointment-priority-option-high',
      icon: <PriorityIcon type={PriorityIconMap.HIGH} data-value="HIGH" />,
      value: 'HIGH',
      onClick: async () => {
        await changeAppointmentPriority('HIGH');
      },
    },
  ];

  if (breakpointUpMd) {
    return (
      <LggSelectableOptionsDropdownButtonWithCustomTrigger
        options={options}
        overlayClassName="appointments-priority-options-dropdown"
        customDropdownProps={{
          overlayStyle: {
            minWidth: '161px',
          },
        }}
        visibilityHandler={desktopVisibilityHandler}
        selectedValue={priority}
        size="small"
        variant="default"
      >
        {triggerElement}
      </LggSelectableOptionsDropdownButtonWithCustomTrigger>
    );
  }

  return (
    <>
      {triggerElement}
      <SelectableOptionsDrawer
        className="appointments-priority-options-drawer"
        visible={isMobileTargetAppointment && mobileVisibilityHandler.visible}
        title={t('appointments:priorityPicker.title')}
        onClose={mobileVisibilityHandler.close}
        options={options}
        selectedValue={priority}
      />
    </>
  );
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ChangeScheduleStatusInput,
  Mutation,
  Schedule,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  LggSelectableOptionsDropdownButton,
  LggSelectableOptionsDropdownButtonProps,
} from 'src/components/general/button/dropdown-button';
import { StatusTag } from 'src/components/general/display/status-tag';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { VisibilityHandler } from 'src/hooks/use-visible';

const CHANGE_APPOINTMENT_STATUS_MUTATION = gql`
  mutation ChangeScheduleStatus(
    $scheduleId: Int!
    $status: ScheduleStatus!
    $institutionId: Int!
  ) {
    changeScheduleStatus(
      input: { scheduleId: $scheduleId, status: $status, institutionId: $institutionId }
    ) {
      schedule {
        id
        status
      }
    }
  }
`;

const StatusDropdownButton = styled(LggSelectableOptionsDropdownButton)`
  display: flex;
  justify-content: flex-start;
  min-width: 153px;
`;

type AppointmentStatusSelectorProps = {
  appointment: Schedule;
  onCompleted?: VoidFunction;
  visibilityHandler: VisibilityHandler;
  desktop?: {
    buttonVariant: LggSelectableOptionsDropdownButtonProps['variant'];
  };
};

export const AppointmentStatusSelector = memo<AppointmentStatusSelectorProps>(
  ({ appointment, onCompleted, visibilityHandler, desktop, ...rest }) => {
    const { t } = useTranslation(['appointments', 'common']);
    const breakpointUpMd = useBreakpoint(up('md'));
    const { id: institutionId } = useCurrentInstitution();
    const showNotification = useShowNotification();
    const [changeAppointmentStatus] = useMutation<
      Pick<Mutation, 'changeScheduleStatus'>,
      ChangeScheduleStatusInput
    >(CHANGE_APPOINTMENT_STATUS_MUTATION);
    const appointmentStatusBaseOptions = [
      {
        value: 'SCHEDULED',
        label: t('appointments:appointmentStatusPicker.options.scheduled'),
        'data-lgg-id': 'appointment-status-option-scheduled',
        icon: 'appointmentScheduled',
      },
      {
        value: 'COMPLETED',
        label: t('appointments:appointmentStatusPicker.options.completed'),
        'data-lgg-id': 'appointment-status-option-completed',
        icon: 'appointmentCompleted',
      },
      {
        value: 'RESCHEDULED',
        label: t('appointments:appointmentStatusPicker.options.rescheduled'),
        'data-lgg-id': 'appointment-status-option-rescheduled',
        icon: 'appointmentRescheduled',
      },
      {
        value: 'CANCELLED',
        label: t('appointments:appointmentStatusPicker.options.cancelled'),
        'data-lgg-id': 'appointment-status-option-cancelled',
        icon: 'appointmentCancelled',
      },
      {
        value: 'NO_SHOW',
        label: t('appointments:appointmentStatusPicker.options.noShow'),
        'data-lgg-id': 'appointment-status-option-no-show',
        icon: 'appointmentNoShow',
      },
    ];

    const getStatusOptions = (selectedValue: string, appointmentId: number) => {
      return appointmentStatusBaseOptions.map((statusOption) => ({
        ...statusOption,
        selected: selectedValue === statusOption.value,
        onClick: async () => {
          await changeAppointmentStatus({
            variables: {
              scheduleId: appointmentId,
              institutionId,
              status: statusOption.value as Schedule['status'],
            },
            onCompleted: async () => {
              // TODO: ADD OVERDUE APPOINTMENTS REFETCH CALL
              onCompleted?.();
              showNotification({
                type: 'success',
                title: t('appointments:appointmentUpdatedMessages.success'),
              });
            },
            onError: async () => {
              showNotification({
                type: 'error',
                title: t('appointments:appointmentUpdatedMessages.error'),
              });
            },
          });
        },
      }));
    };

    const appointmentStatusOptions = getStatusOptions(
      appointment?.status ?? '',
      Number(appointment.id),
    );

    const appointmentStatusData = appointmentStatusOptions.find(
      (option) => option.value === appointment.status,
    );

    if (!appointmentStatusData) {
      return null;
    }

    if (breakpointUpMd) {
      return (
        <StatusDropdownButton
          data-lgg-id="appointment-status-selector"
          options={appointmentStatusOptions}
          selectedValue={appointment.status}
          size="small"
          icon={appointmentStatusData.icon}
          variant={desktop?.buttonVariant ?? 'defaultGhost'}
          visibilityHandler={visibilityHandler}
          data-selected-value={appointment.status}
          customDropdownProps={{
            overlayClassName: 'appointment-status-selector-overlay',
          }}
          {...rest}
        >
          {appointmentStatusData.label}
        </StatusDropdownButton>
      );
    }

    return (
      <>
        <StatusTag
          onClick={visibilityHandler.show}
          icon={appointmentStatusData.icon}
          label={appointmentStatusData.label}
        />
        <SelectableOptionsDrawer
          visible={visibilityHandler.visible}
          title={t('appointments:appointmentStatusPicker.title')}
          onClose={visibilityHandler.close}
          options={appointmentStatusOptions}
          selectedValue={appointment.status}
        />
      </>
    );
  },
);

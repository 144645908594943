import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/lib/tabs';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Card } from 'src/components/general/display/card';
import { HollowLayout, MobileHollowLayout } from 'src/components/layout/hollow-layout';
import { RecoverUsernameForm } from 'src/components/pages/account/recover/components/recover-username-form';
import { ResetPasswordForm } from 'src/components/pages/account/recover/components/reset-password-form';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const { TabPane } = Tabs;

const StyledCard = styled(Card)`
  border-radius: unset;
  box-shadow: none;
  padding: 50px 30px 30px;
  z-index: 1;
  height: 100%;

  ${up('sm')} {
    margin: 0 auto;
    max-width: 315px;
    padding: 24px 0 51px;
    width: 100%;
  }

  ${up('md')} {
    ${({ theme }) => theme.cardsTheme.default};
    margin: 0 auto;
    max-width: 570px;
    padding: 54px 0 112px;
    height: auto;
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 29.5px;

    .ant-tabs-nav-operations {
      display: none;
    }
  }

  .ant-tabs-tab {
    align-items: center;
    border-bottom: solid 1px #2526310d;
    color: ${({ theme }) => theme.colors.lightBlue};
    flex: 1;
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    padding-top: 0;
    text-shadow: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${({ theme }) => theme.colors.globalBlue};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    line-height: 1.43;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.globalBlue};
  }

  .ant-tabs-nav-list {
    justify-content: center;
    width: 100%;
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.globalBlue};
  }

  ${up('md')} {
    .ant-tabs-nav {
      margin-bottom: 49px;
    }

    .ant-tabs-nav-list {
      padding: 0 105px;
    }

    .ant-tabs-tab {
      font-size: 16px;
      max-width: unset;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 16px;
    }
  }
`;

export const RecoverCredentialsPage = memo(() => {
  const { t } = useTranslation(['account']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const card = (
    <StyledCard>
      <StyledTabs defaultActiveKey="1">
        <TabPane tab={t('account:recoverPage.resetPassword')} key="1">
          <ResetPasswordForm />
        </TabPane>
        <TabPane tab={t('account:recoverPage.recoverUsername')} key="2">
          <RecoverUsernameForm />
        </TabPane>
      </StyledTabs>
    </StyledCard>
  );

  if (breakpointUpMd) {
    return <HollowLayout>{card}</HollowLayout>;
  }

  return <MobileHollowLayout>{card}</MobileHollowLayout>;
});

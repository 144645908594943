import { match } from 'ts-pattern';

export type RelativeBirthdayInputDateType =
  | '_isToday'
  | '_isTomorrow'
  | '_isYesterday'
  | '_isThisWeek'
  | '_isThisMonth'
  | '_isLastWeek'
  | '_isLastMonth'
  | '_isNextWeek'
  | '_isNextMonth';

export type BirthdayInputDateType =
  | RelativeBirthdayInputDateType
  | '_isExactDay'
  | '_isExactMonth';

const birthdayInputDateInputTypePatterns = {
  today: '_isToday' as const,
  tomorrow: '_isTomorrow' as const,
  yesterday: '_isYesterday' as const,
  thisWeek: '_isThisWeek' as const,
  thisMonth: '_isThisMonth' as const,
  lastWeek: '_isLastWeek' as const,
  lastMonth: '_isLastMonth' as const,
  nextWeek: '_isNextWeek' as const,
  nextMonth: '_isNextMonth' as const,
  exactDay: '_isExactDay' as const,
  exactMonth: '_isExactMonth' as const,
};

type MatchBirthdayDateInputTypeHandler<Result> = {
  today: (type: RelativeBirthdayInputDateType) => Result;
  tomorrow: (type: RelativeBirthdayInputDateType) => Result;
  yesterday: (type: RelativeBirthdayInputDateType) => Result;
  thisWeek: (type: RelativeBirthdayInputDateType) => Result;
  thisMonth: (type: RelativeBirthdayInputDateType) => Result;
  lastWeek: (type: RelativeBirthdayInputDateType) => Result;
  lastMonth: (type: RelativeBirthdayInputDateType) => Result;
  nextWeek: (type: RelativeBirthdayInputDateType) => Result;
  nextMonth: (type: RelativeBirthdayInputDateType) => Result;
  exactDay: (type: BirthdayInputDateType) => Result;
  exactMonth: (type: BirthdayInputDateType) => Result;
};

export const matchInputBirthdayTypeExhaustive = <Result>(
  type: BirthdayInputDateType,
  handler: MatchBirthdayDateInputTypeHandler<Result>,
) => {
  return match(type)
    .with(birthdayInputDateInputTypePatterns.today, handler.today)
    .with(birthdayInputDateInputTypePatterns.tomorrow, handler.tomorrow)
    .with(birthdayInputDateInputTypePatterns.yesterday, handler.yesterday)
    .with(birthdayInputDateInputTypePatterns.thisWeek, handler.thisWeek)
    .with(birthdayInputDateInputTypePatterns.thisMonth, handler.thisMonth)
    .with(birthdayInputDateInputTypePatterns.lastWeek, handler.lastWeek)
    .with(birthdayInputDateInputTypePatterns.lastMonth, handler.lastMonth)
    .with(birthdayInputDateInputTypePatterns.nextWeek, handler.nextWeek)
    .with(birthdayInputDateInputTypePatterns.nextMonth, handler.nextMonth)
    .with(birthdayInputDateInputTypePatterns.exactDay, handler.exactDay)
    .with(birthdayInputDateInputTypePatterns.exactMonth, handler.exactMonth)
    .run();
};

import React, { ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

const StyledContainer = styled.div<{ maxWidth?: number }>`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.storm};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};

  .total {
    color: ${({ theme }) => theme.colors.gogo};
    margin-right: 3px;
  }

  ${up('md')} {
    font-size: 15px;
    max-width: unset;
    line-height: 18px;
  }
`;

type PaginationInfoProps = {
  total: number;
  entityName?: string;
  entityComponent?: ReactElement;
  maxWidth?: number;
  testId?: string;
};

export const PaginationInfo = memo<PaginationInfoProps>(
  ({ total, entityName, entityComponent, testId = '', maxWidth }) => {
    const { t } = useTranslation(['common']);

    return (
      <StyledContainer data-lgg-id={testId} maxWidth={maxWidth}>
        {`${t('common:showing')} `}
        <span className="total">{new Intl.NumberFormat().format(total)}</span>
        {entityName ? entityName : null}
        {entityComponent ? entityComponent : null}
      </StyledContainer>
    );
  },
);

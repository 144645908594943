import { useServiceSelector } from 'react-service-locator';
import { useQuery } from '@apollo/client';
import { Query, QueryUserArgs, User } from '@lgg/isomorphic/types/__generated__/graphql';
import { GET_USER_QUERY } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { SessionService } from 'src/services/session.service';

export const useGetCurrentUser = (): {
  loading: boolean;
  currentUser: User | undefined;
} => {
  const userId = useServiceSelector(SessionService, ({ data: { user } }) => user.id);

  const handleGraphQLError = useHandleGraphQLError();

  const { data, loading } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(GET_USER_QUERY, {
    variables: {
      id: userId.toString(),
    },
    onError: handleGraphQLError,
  });

  return {
    loading,
    currentUser: data?.user,
  };
};

import React, { memo } from 'react';
import { ReactComponent as Error400 } from 'src/assets/error/400.svg';
import { ReactComponent as Error403 } from 'src/assets/error/403.svg';
import { ReactComponent as Error404 } from 'src/assets/error/404.svg';
import { ReactComponent as Error500 } from 'src/assets/error/500.svg';
import { GenericErrorPage } from 'src/components/pages/errors/generic-error-page';

const ErrorImages: { [key in number]: React.ComponentType } = {
  400: Error400,
  403: Error403,
  404: Error404,
  500: Error500,
};

type GenericErrorPageProps = {
  errorCode: number;
  errorId?: string;
  message: string;
  title: string;
};

export const GenericHttpErrorPage = memo<GenericErrorPageProps>(
  ({ errorCode, errorId, message, title, ...rest }) => {
    const ErrorImage = ErrorImages[errorCode] || Error500;

    return (
      <GenericErrorPage
        {...rest}
        title={title}
        image={<ErrorImage />}
        message={message}
        headline={errorCode}
        errorId={errorId}
      />
    );
  },
);

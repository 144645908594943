import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { User, UserWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { getSortedUserSelectOptions } from 'src/components/domain/users/hooks/helpers';
import {
  UseCompanyUsersReturn,
  useCompanyUsers,
} from 'src/components/domain/users/hooks/use-company-users';
import { userToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/user-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseUserListForSelectReturn = {
  groupedOptions: { label: string; options: SelectOption<number>[] }[];
  groupedUsers: { label: string; users: User[] }[];
  loading: boolean;
  options: SelectOption<number>[];
  users: User[];
} & Pick<UseCompanyUsersReturn, 'refetch' | 'loadMore' | 'hasNextPage'>;

export const useCompanyUsersListForSelect = (
  where?: UserWhereInput,
): UseUserListForSelectReturn => {
  const { t } = useTranslation(['common']);
  const { users, loading, refetch, loadMore, hasNextPage } = useCompanyUsers(where);

  const activeUsersByRole = groupBy(
    users.filter(({ isActive }) => isActive),
    (user) => user.role.id,
  );
  const roles = Object.keys(activeUsersByRole).sort((a, b) => a.localeCompare(b));

  const groupedRoles = groupBy(roles, (role) => {
    if (role === 'company.agent') {
      return 'companyAgentRole';
    } else if (role.includes('company')) {
      return 'companyRoles';
    } else {
      return 'others';
    }
  });

  const sortedRoles = [
    ...(groupedRoles['companyAgentRole'] ?? []),
    ...(groupedRoles['companyRoles'] ?? []),
    ...(groupedRoles['others'] ?? []),
  ].filter(Boolean) as string[];

  const groupedUsers = useMemo(
    () =>
      sortedRoles.map((role) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`common:roles.${role}`) as string,
        users: activeUsersByRole[role],
      })),
    [activeUsersByRole, sortedRoles, t],
  );

  const groupedOptions = useMemo(() => {
    const options = groupedUsers.map(({ label, users }) => ({
      label,
      options: users.map(userToSelectOptionMapper),
    }));

    return options;
  }, [groupedUsers]);

  const userOptions = getSortedUserSelectOptions(users);

  return {
    groupedOptions,
    groupedUsers,
    hasNextPage,
    loading: loading,
    loadMore,
    options: userOptions,
    refetch,
    users,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ConversationBanner,
  ConversationBannerIcon,
} from 'src/components/pages/conversations/components/contact-interactions/banners/shared';

const ClosedConversationBannerContainer = styled(ConversationBanner)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cosmo};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.regular};
  justify-content: center;
  text-align: left;
`;

export const ClosedConversationBanner = () => {
  const { t } = useTranslation(['conversations']);

  return (
    <ClosedConversationBannerContainer data-lgg-id="closed-conversation-banner">
      <ConversationBannerIcon type="circularCheck" color="white" />
      {t('conversations:conversationStatus.closed')}
    </ClosedConversationBannerContainer>
  );
};

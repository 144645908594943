import { useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { LegacyData } from 'src/components/pages/legacy/legacy-data.type';
import { LegacyApiService } from 'src/services/http/legacy-api.service';

export const useLegacyFormSubmission = (
  setLegacyRequestPromise: (promise: Promise<LegacyData>) => void,
  viewData: string,
) => {
  const history = useHistory();
  const legacyApiService = useService(LegacyApiService);

  const onSubmit = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;

      if (
        target.tagName === 'FORM' &&
        !e.defaultPrevented &&
        target.closest('div[id^="ym-legacy-section-"]') !== null
      ) {
        const form = target as HTMLFormElement;
        const formMethod = form.getAttribute('method')?.toUpperCase();
        const formAction = form.getAttribute('action') || window.location.href;
        const formActionUrl = new URL(formAction, new URL(window.location.href).origin);
        const formData = new FormData(form);

        //If the form action url host is different from browser URL them stop handling the form submission
        if (
          formActionUrl.host !== new URL(window.location.href).host &&
          formActionUrl.host !== new URL(import.meta.env.VITE_USER_WEB_APP_BASE_URL).host
        ) {
          return;
        }

        e.preventDefault();

        if (formMethod === 'POST') {
          const request = async () => {
            const { data } = await legacyApiService.post<LegacyData>(
              `${formActionUrl.pathname}${formActionUrl.search}`,
              formData,
            );

            return data;
          };

          setLegacyRequestPromise(request());
        }
        //GET as default method
        else {
          const formDataAsParams = new URLSearchParams(formData as any);

          //Generate filter URL
          const path = `${formActionUrl.pathname}?${decodeURI(
            formDataAsParams.toString(),
          )}`;

          history.push(path);
        }
      }
    },
    [history, legacyApiService, setLegacyRequestPromise],
  );

  useLayoutEffect(() => {
    document.addEventListener('submit', onSubmit);

    return () => {
      document.removeEventListener('submit', onSubmit);
    };
  }, [onSubmit, viewData]);
};

import { Service, StatefulService } from 'react-service-locator';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';

export type BuildInfo = {
  version: string;
  date: string;
};

type AppStatusRequestPayload = {
  latestBuild: BuildInfo;
};

@Service()
export class AppStatusService extends StatefulService<{
  newVersionAvailable: boolean;
}> {
  public currentBuild: BuildInfo;

  constructor() {
    super();
    // this needs to identify the build when the page was loaded.
    // we should not update this when polling
    this.currentBuild = {
      version: import.meta.env.VITE_BUILD_VERSION,
      date: import.meta.env.VITE_BUILD_DATE,
    };
    this.state = {
      newVersionAvailable: false,
    };

    void this.initAppStatusPolling();
  }

  private async initAppStatusPolling() {
    if (Capacitor.isNativePlatform()) {
      return;
    }

    if (['production', 'test'].includes(import.meta.env.MODE)) {
      const appStatusRefreshInterval = import.meta.env
        .VITE_APP_STATUS_REFRESH_INTERVAL_MS;

      while (true) {
        try {
          await axios.get<AppStatusRequestPayload>(`/app-status`).then(({ data }) => {
            const latestBuildVersion = data.latestBuild.version;
            if (
              this.currentBuild.version !== latestBuildVersion &&
              !this.state.newVersionAvailable
            ) {
              this.setState({
                // do not update this.currentBuild
                newVersionAvailable: true,
              });
            }
          });
        } catch {}
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            appStatusRefreshInterval ? Number(appStatusRefreshInterval) : 60_000,
          ),
        );
      }
    }
  }
}

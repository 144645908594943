import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { LggDropdownButtonWithoutOverlay } from 'src/components/general/button/dropdown-button';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';

export const SelectContainer = styled(FlexColumn)`
  ${up('md')} {
    align-items: center;
    flex-direction: row;
  }

  & + & {
    margin-top: 18px;

    ${up('md')} {
      margin-top: 15px;
    }
  }
`;

export const SelectText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  margin-bottom: 1px;
  margin-right: 2px;
  text-align: left;

  ${up('md')} {
    flex: 1;
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    line-height: 17px;
  }
`;

export const SelectExcerpt = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  text-align: left;

  ${up('md')} {
    font-family: ${({ theme }) => theme.font.regular};
  }
`;

export const ResourceSelector = styled(LggDropdownButtonWithoutOverlay)`
  flex: 1;
  min-height: 34px;
  padding: 0 12px;
  width: 100%;

  .dropdown-label {
    color: ${({ theme }) => theme.colors.storm};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 17px;
    opacity: 0.8;
    text-align: left;
  }

  .lgg-icon {
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  ${up('md')} {
    max-width: 160px;
  }
`;

export const LabelContainer = styled(FlexRow)`
  ${up('md')} {
    flex: 1;
    flex-direction: column;
  }
`;

export type PhoneNumberSelectProps = {
  label: string;
  excerpt?: string;
  options: DrawerSelectableOption[];
  selectedResource: string;
  ['data-lgg-id']?: string;
};

import React, { memo, MouseEventHandler, FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { DrawerBaseOption } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexColumn } from 'src/components/layout/flex-column';

const AdaptiveOptionItemIcon = styled(Icon)<{ $iconColorOverride?: string }>`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme, $iconColorOverride }) =>
        $iconColorOverride ? theme.colors[$iconColorOverride] : theme.colors.smalt};
    }

    ${up('md')} {
      height: 16px;
      width: 16px;

      path {
        fill: ${({ theme }) => theme.colors.smalt};
      }
    }
  }

  .selected & {
    svg path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const AdaptiveOptionItemLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  line-height: 18px;
  text-align: left;

  .has-icon & {
    margin-left: 20px;
  }

  .selected:not(.disabled) & {
    color: ${({ theme }) => theme.colors.gogo};
  }

  .selected.has-custom-icons:not(.disabled) & {
    color: ${({ theme }) => theme.colors.smalt};
  }

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.12px;

    .has-icon & {
      margin-left: 10px;
    }
  }
`;

const AdaptiveOptionItemSubTitle = styled.span`
  line-height: 15px;
  margin-top: 2px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.flint};

  ${up('md')} {
    margin-top: 1px;
    line-height: 12px;
    font-size: 10px;
  }
`;

const DrawerSelectedItemIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }

    ${up('md')} {
      height: 12px;
      width: 12px;
      margin-left: 10px;
    }
  }
`;

const sharedStyles = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 38px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 10px;

  &.has-subtitle {
    height: 55px;
  }

  &.adaptive-option-item + .adaptive-option-item {
    margin-top: 3px;
  }

  ${up('md')} {
    height: 32px;
    padding: 0 10px 0 15px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;

    &:hover {
      ${AdaptiveOptionItemLabel} {
        color: ${({ theme }) => theme.colors.smalt};
      }
    }

    &.has-subtitle {
      height: 42px;
    }

    & + & {
      margin-top: 3px;
    }

    &.selected {
      background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
    }

    &:not(.selected):hover {
      background-color: ${({ theme }) => theme.colors.porcelain};
    }
  }
`;

const StyledAdaptiveOptionLink = styled(Link)`
  ${sharedStyles}
`;

const StyledAdaptiveOptionItem = styled.div`
  ${sharedStyles}
`;

const AdaptiveOptionItemIconContainer = styled.div`
  align-items: center;
  display: flex;

  .disabled & {
    svg path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

type WrapperProps = {
  className: string;
  to?: string;
  onClick?: MouseEventHandler;
  children: ReactElement;
  render?: (optionItem: ReactElement) => JSX.Element;
};

const WrapperContainer: FC<WrapperProps> = ({ to, children, render, ...rest }) => {
  const optionItem = to ? (
    <StyledAdaptiveOptionLink to={to} {...rest}>
      {children}
    </StyledAdaptiveOptionLink>
  ) : (
    <StyledAdaptiveOptionItem {...rest}>{children}</StyledAdaptiveOptionItem>
  );

  return render ? render(optionItem) : optionItem;
};

export type AdaptiveOptionItemProps = DrawerBaseOption & {
  value?: string;
  selected?: boolean;
  onClick: MouseEventHandler;
  useDefaultSelectedStyle?: boolean;
  subTitle?: string;
  iconColorOverride?: string;
  render?: (optionItem: ReactElement) => JSX.Element;
};

export const AdaptiveOptionItem = memo<AdaptiveOptionItemProps>(
  ({
    label,
    icon,
    selected = false,
    onClick,
    disabled,
    trailing,
    subTitle,
    iconColorOverride,
    ...rest
  }) => {
    const hasCustomIcons = icon && !(typeof icon === 'string');
    const hasSubtitle = Boolean(subTitle);

    return (
      <WrapperContainer
        className={c({
          selected,
          'adaptive-option-item': true,
          'has-icon': Boolean(icon),
          'has-custom-icons': hasCustomIcons,
          'has-subtitle': hasSubtitle,
          disabled,
        })}
        onClick={disabled ? undefined : onClick}
        {...rest}
      >
        <>
          <AdaptiveOptionItemIconContainer className="option-icon-container">
            {icon && typeof icon === 'string' ? (
              <AdaptiveOptionItemIcon
                type={icon}
                $iconColorOverride={iconColorOverride}
              />
            ) : (
              icon
            )}
          </AdaptiveOptionItemIconContainer>
          <FlexColumn>
            <AdaptiveOptionItemLabel className="adaptive-option-label">
              {label}
            </AdaptiveOptionItemLabel>
            {hasSubtitle && (
              <AdaptiveOptionItemSubTitle>{subTitle}</AdaptiveOptionItemSubTitle>
            )}
          </FlexColumn>
          <Expand />
          {trailing && trailing}
          {selected && !disabled && <DrawerSelectedItemIcon type="checkSave" />}
        </>
      </WrapperContainer>
    );
  },
);

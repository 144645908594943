import React from 'react';
import AntDropdown from 'antd/lib/dropdown';
import AntMenu from 'antd/lib/menu';
import styled from 'styled-components';

export const Dropdown: typeof AntDropdown = AntDropdown;

export const StyledMenu = styled(AntMenu)`
  padding: 10px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 15px 34px #0000001a;
  border: 1px solid #98a9bc2e;
  border-radius: 4px;
`;

export function DropdownMenu(props: any) {
  return <StyledMenu {...props} />;
}

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { VisibilityHandler } from 'src/hooks/use-visible';
import { ResponsiveButton } from './lgg-button';

type FiltersButtonProps = {
  filtersVisibilityHandler: VisibilityHandler;
  hasActiveFilters: boolean;
};

export const FiltersButton = memo<FiltersButtonProps>(
  ({ filtersVisibilityHandler, hasActiveFilters, ...rest }) => {
    const { t } = useTranslation(['common']);

    return (
      <ResponsiveButton
        size="regular"
        icon="filters"
        variant="defaultWhite"
        onClick={filtersVisibilityHandler.show}
        active={hasActiveFilters}
        iconNotificationProps={{
          showNotification: hasActiveFilters,
        }}
        {...rest}
      >
        {hasActiveFilters ? t('common:filters.activeFilters') : t('common:filters.title')}
      </ResponsiveButton>
    );
  },
);

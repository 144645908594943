import React from 'react';
import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';
import { InternalNoteMention } from 'src/components/pages/conversations/components/contact-interactions/items/internal-note-mention';
import { SerializedMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';

export class UserMentionNodeResolver extends DecoratorNode<JSX.Element> {
  __mention: string;
  __userId: string;

  static getType(): string {
    return 'user-mention';
  }

  static clone(node: UserMentionNodeResolver): UserMentionNodeResolver {
    return new UserMentionNodeResolver(node.__mention, node.__userId);
  }

  constructor(mention: string, userId: string, key?: NodeKey) {
    super(key);

    this.__mention = mention;
    this.__userId = userId;
  }

  createDOM(): HTMLElement {
    const div = document.createElement('div');
    div.style.display = 'contents';
    return div;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return <InternalNoteMention label={this.__mention} userId={this.__userId} />;
  }

  static importJSON(serializedNode: SerializedMentionNode): LexicalNode {
    return new UserMentionNodeResolver(serializedNode.text, serializedNode.userId);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      userId: this.__userId,
      mention: this.__mention,
    };
  }
}

import React, { memo, useState } from 'react';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { Icon } from 'src/components/general/icon';
import { Checkbox } from 'src/components/general/inputs/checkbox';
import { LongPressWrapper } from 'src/components/general/long-press-wrapper';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { ConversationContactInteractionPreview } from 'src/components/pages/conversations/components/conversations/conversation-contact-interaction-preview';
import { ConversationStatus } from 'src/components/pages/conversations/components/conversations/conversation-status';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';

const StyledConversationItem = styled(FlexRow)<{ active?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  cursor: pointer;
  min-height: 75px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  transition: background-color 150ms;

  ${up('lg')} {
    ${({ active, theme }) => active && `background-color: ${theme.colors.topaz10}`};

    &:hover {
      background-color: ${({ theme }) => theme.colors.alabaster};
      ${({ active, theme }) => active && `background-color: ${theme.colors.topaz10}`};
    }
  }

  ${up('md')} {
    min-height: 73px;
  }
`;

const ContactName = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MainColumn = styled(FlexColumn)`
  flex: 1;
  margin-right: 20px;
  min-width: 0;
  justify-content: space-between;
  height: 32px;
`;

const RightColumn = styled(MainColumn)`
  flex: unset;
  margin-right: 0;
`;

const ContactInteractionDate = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: right;

  ${up('md')} {
    font-size: 11px;
    line-height: 13px;
  }
`;

const ArrowIcon = styled(Icon)`
  align-self: center;
  margin-left: 20px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  ${up('md')} {
    display: none;
  }
`;

const Marker = styled.div`
  background-color: ${({ theme }) => theme.colors.gold60};
  height: 16px;
  left: -8px;
  position: absolute;
  top: -8px;
  transform: rotate(45deg);
  width: 16px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
  width: max-content;
  position: absolute;
  top: 2px;

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;

    ${up('md')} {
      width: 20px;
      height: 20px;
    }

    &::after {
      left: 30%;
    }
  }
`;

const StyledContactStageIcon = styled(ContactStageIcon)`
  position: absolute;
`;

const LeftColumn = styled.span`
  position: relative;
  width: 41px;
  overflow: hidden;

  ${up('md')} {
    width: 40px;
  }

  ${StyledCheckbox}, ${StyledContactStageIcon} {
    transition: all 0.2s ease;
  }

  ${StyledCheckbox} {
    opacity: 0;
    left: -20px;
    pointer-events: none;
  }

  ${StyledContactStageIcon} {
    opacity: 1;
    left: 0;
  }

  &.bulk-mode-state {
    ${StyledCheckbox} {
      opacity: 1;
      left: 0;
      pointer-events: all;
    }

    ${StyledContactStageIcon} {
      opacity: 0;
      left: 20px;
    }
  }
`;

export type ConversationItemProps = {
  conversation: Conversation;
  onClick: VoidFunction;
  active?: boolean;
  onSelect: VoidFunction;
  isCheckable: boolean;
  isChecked: boolean;
  disabled?: boolean;
};

export const ConversationItem = memo<ConversationItemProps>((props) => {
  const {
    conversation: {
      id,
      isMarked,
      isReplyPending,
      lastContactInteraction,
      contact: {
        stage: { slug },
        label,
        status: contactStatus,
      },
    },
    onClick,
    active,
    isCheckable,
    isChecked,
    onSelect,
    disabled,
  } = props;

  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { formatLogDate } = useFormatDate();
  const breakpointUpMd = useBreakpoint(up('md'));

  const selectConversationItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onSelect();
  };

  return (
    <LongPressWrapper
      onLongPress={() => {
        if (!disabled) onSelect();
      }}
      onClick={onClick}
    >
      <StyledConversationItem
        data-conversation-contact={`conversation-${label}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        active={active}
        data-lgg-id={`conversation-item-${id}`}
      >
        {isMarked && <Marker data-lgg-id="MARKED" />}
        <LeftColumn
          className={c({
            'bulk-mode-state': isCheckable || (breakpointUpMd && isHovering),
          })}
        >
          <StyledCheckbox
            disabled={disabled}
            data-lgg-id="conversation-bulk-select-item-checkbox"
            checked={isChecked}
            onClick={selectConversationItem}
          />
          <StyledContactStageIcon
            slug={slug}
            name={contactStatus.name}
            onClick={selectConversationItem}
          />
        </LeftColumn>
        <MainColumn>
          <ContactName data-lgg-id="conversation-contact-name">{label}</ContactName>
          <ConversationContactInteractionPreview
            contactInteraction={lastContactInteraction}
          />
        </MainColumn>
        <RightColumn>
          {lastContactInteraction?.occurredAt && (
            <ContactInteractionDate data-lgg-id="conversation-last-interaction-date">
              {formatLogDate(lastContactInteraction.occurredAt)}
            </ContactInteractionDate>
          )}
          <ConversationStatus isReplyPending={isReplyPending} />
        </RightColumn>
        <ArrowIcon type="arrowRightWide" />
      </StyledConversationItem>
    </LongPressWrapper>
  );
});

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useLegacyOpenModalByHash = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.hash?.substr(0, 2) === '#/') {
      openRemoteModal({ data: { url: location.hash.substr(1) } });
    }
  }, [history, location.hash, location.pathname]);
};

import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import { HollowLayout, MobileHollowLayout } from 'src/components/layout/hollow-layout';
import { SignInForm } from 'src/components/pages/account/sign-in/components/sign-in-form';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export const SignInPage = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));

  if (breakpointUpMd) {
    return (
      <HollowLayout>
        <SignInForm />
      </HollowLayout>
    );
  }

  return (
    <MobileHollowLayout>
      <SignInForm />
    </MobileHollowLayout>
  );
});

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLabel = styled.span`
  color: ${({ theme }) => theme.colors.cosmo};
`;

const Separator = styled.span`
  margin: 0 3px;
`;

export const InProgressCallIndicator = () => {
  const { t } = useTranslation(['common']);
  const label = useMemo(() => <StyledLabel>{t('common:inProgress')}</StyledLabel>, [t]);

  return (
    <span>
      <Separator>·</Separator>
      {label}
    </span>
  );
};

import { useLayoutEffect } from 'react';
import { renderButtonContent } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/table/render-button-content';

export function useHandlePagination(content: HTMLDivElement) {
  useLayoutEffect(() => {
    content.querySelectorAll('.pagination .next, .pagination .previous').forEach((el) => {
      el.innerHTML = el.innerHTML.replace('« ', '').replace(' »', '');
      el.classList.remove('btn-default');
      el.classList.remove('btn-primary');
      el.classList.add('lgg-btn-white');
      renderButtonContent(el);
    });

    content.querySelectorAll('.pagination span').forEach((el) => {
      el.setAttribute('disabled', 'disabled');
    });
  }, [content]);
}

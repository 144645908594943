import { memo } from 'react';
import Badge, { BadgeProps } from 'antd/lib/badge';
import styled from 'styled-components';

const StyledBadge = styled(Badge)`
  .ant-badge-dot {
    background-color: ${({ theme }) => theme.colors.monk};
    box-shadow: none;
    height: 10px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    width: 10px;
  }
`;

type ActiveOptionBadgeProps = BadgeProps & {
  children: React.ReactElement;
};

export const ActiveOptionBadge = memo(({ children, ...rest }: ActiveOptionBadgeProps) => {
  return <StyledBadge {...rest}>{children}</StyledBadge>;
});

import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';
import { VisibilityHandler } from 'src/hooks/use-visible';

type AppointmentOptionsBottomDrawerProps = {
  onClose: VoidFunction;
  selectedAppointment: Schedule | null;
  visible: boolean;
  mobileStatusOptionsVisibilityHandler: VisibilityHandler;
  mobilePriorityOptionsVisibilityHandler: VisibilityHandler;
};

export const AppointmentOptionsBottomDrawer = memo<AppointmentOptionsBottomDrawerProps>(
  ({
    onClose,
    selectedAppointment,
    visible,
    mobileStatusOptionsVisibilityHandler,
    mobilePriorityOptionsVisibilityHandler,
  }) => {
    const { t } = useTranslation(['appointments']);
    const { getContactModalUrl, getScheduleModalUrl } = useUrls();
    const history = useHistory();
    const breakpointUpMd = useBreakpoint(up('md'));
    const options = useMemo(() => {
      const options = [
        {
          onClick: () => {
            if (selectedAppointment) {
              history.push(getScheduleModalUrl(selectedAppointment.id));
              onClose();
            }
          },
          label: t('appointments:appointmentOptions.view'),
          icon: 'schedule18',
          'data-lgg-id': 'appointment-option-view',
        },
        {
          onClick: mobileStatusOptionsVisibilityHandler.show,
          label: t('appointments:appointmentOptions.updateStatus'),
          icon: 'updateStatus',
          'data-lgg-id': 'appointment-option-update-status',
        },
        {
          onClick: mobilePriorityOptionsVisibilityHandler.show,
          label: t('appointments:appointmentOptions.updatePriority'),
          icon: 'priority',
          'data-lgg-id': 'appointment-option-update-priority',
        },
      ];

      if (selectedAppointment?.contact) {
        options.push({
          onClick: () => {
            if (selectedAppointment.contact) {
              history.push(getContactModalUrl(selectedAppointment.contact.id));
            }
          },
          label: t('appointments:appointmentOptions.viewContact'),
          icon: 'contact',
          'data-lgg-id': 'appointment-option-view-contact',
        });
      }

      return options;
    }, [
      getContactModalUrl,
      getScheduleModalUrl,
      history,
      mobilePriorityOptionsVisibilityHandler.show,
      mobileStatusOptionsVisibilityHandler.show,
      onClose,
      selectedAppointment,
      t,
    ]);

    return (
      <OptionsBottomDrawer
        onClose={onClose}
        title={t('appointments:appointmentOptions.title')}
        visible={
          !breakpointUpMd && visible && !mobileStatusOptionsVisibilityHandler.visible
        }
        options={options}
      />
    );
  },
);

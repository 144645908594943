import styled from 'styled-components';

export const Expand = styled.div.attrs({ 'data-component': 'Expand' })`
  flex: 1;

  display: flex;
  > * {
    flex: 1;
  }
`;

import React, { memo } from 'react';
import styled from 'styled-components';
import { ContactStage } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { Center } from 'src/components/layout/center';

const ContactIcon = styled(Icon)`
  svg {
    height: 14px;
    width: 14px;
  }
`;

const IconContainer = styled(Center)<{ contactStage: ContactStage['slug'] }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 10px;
  width: 32px;
  border: 1px solid
    ${({ contactStage, theme }) => {
      const { gold40, mint40, topaz40 } = theme.colors;
      return contactStage === 'LEAD'
        ? gold40
        : contactStage === 'OPPORTUNITY'
        ? topaz40
        : mint40;
    }};

  ${ContactIcon} svg path {
    fill: ${({ contactStage, theme }) => {
      const { gold, mint, topaz } = theme.colors;
      return contactStage === 'LEAD'
        ? gold
        : contactStage === 'OPPORTUNITY'
        ? topaz
        : mint;
    }};
  }
`;

type ContactStageIconProps = {
  slug: ContactStage['slug'];
  name: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
export const ContactStageIcon = memo<ContactStageIconProps>(
  ({ slug, name, children, onClick, ...rest }) => {
    return (
      <IconContainer
        {...rest}
        className={`${rest?.className} contact-stage-icon_container`}
        onClick={onClick}
        data-lgg-id="stage-icon"
        contactStage={slug}
        data-stage={`${slug}-${name}`}
      >
        <ContactIcon type="contact" className="contact-stage-icon_icon" />
      </IconContainer>
    );
  },
);

import { gql, useQuery } from '@apollo/client';
import {
  ContactStage,
  ContactStatus,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';

const CONTACT_STAGES_OPTIONS = gql`
  query GetContactStagesOptions {
    contactStages {
      id
      name
      slug
      statuses {
        id
        name
      }
    }
  }
`;

type UseContactStageStatusesReturn = {
  loading: boolean;
  stages?: ContactStage[];
  statuses?: ContactStatus[];
};

export const useContactStageStatuses = (): UseContactStageStatusesReturn => {
  const { data, loading } =
    useQuery<Pick<Query, 'contactStages'>>(CONTACT_STAGES_OPTIONS);

  if (loading) return { loading };

  const stages = data?.contactStages || [];
  const statuses = stages.flatMap((stage) => stage.statuses);

  return {
    stages,
    statuses,
    loading,
  };
};

import { useLayoutEffect } from 'react';

const runScriptTypes = [
  'application/javascript',
  'application/ecmascript',
  'application/x-ecmascript',
  'application/x-javascript',
  'text/ecmascript',
  'text/javascript',
  'text/javascript1.0',
  'text/javascript1.1',
  'text/javascript1.2',
  'text/javascript1.3',
  'text/javascript1.4',
  'text/javascript1.5',
  'text/jscript',
  'text/livescript',
  'text/x-ecmascript',
  'text/x-javascript',
];

export function useRunLegacyScripts(scripts: HTMLScriptElement[] | null) {
  useLayoutEffect(() => {
    if (scripts) {
      const filtered = scripts.filter(
        (script) => !script.type || runScriptTypes.includes(script.type),
      );
      // if not cloned, scripts will not run dude to "already started" state
      const clones: HTMLScriptElement[] = filtered.map((script) => {
        const clone = document.createElement('script');
        clone.type = script.type;
        if (script.src) {
          clone.src = script.src;
        } else {
          clone.textContent = script.textContent;
        }
        document.head.appendChild(clone);
        return clone;
      });
      return () => {
        for (const script of clones) {
          document.head.removeChild(script);
        }
      };
    }
  }, [scripts]);
}

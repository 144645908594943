import React, { memo, useContext, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { SetLggThemeContext } from 'src/components/providers/lgg-theme-provider';

export type SafeAreaInsetsProviderProps = Pick<
  SafeAreaInsets['insets'],
  'top' | 'bottom'
> | null;

export const SafeAreaInsetsContext =
  React.createContext<SafeAreaInsetsProviderProps>(null);

export const SafeAreaInsetsProvider = memo(({ children }) => {
  const [value, setValue] = useState<SafeAreaInsetsProviderProps>(null);
  const setTheme = useContext(SetLggThemeContext);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      SafeArea.getSafeAreaInsets()
        .then(({ insets }) => {
          setValue(insets);
          setTheme((theme) => ({
            ...theme,
            insets,
          }));
        })
        .catch(() => {
          // Not a problem, just means we're not on a device with a notch
        });
    }
  }, [setTheme]);

  return (
    <SafeAreaInsetsContext.Provider value={value}>
      {children}
    </SafeAreaInsetsContext.Provider>
  );
});

export const SafeAreaTop = memo(() => {
  const insets = useContext(SafeAreaInsetsContext);

  if (!insets) {
    return null;
  }

  return (
    <div
      style={{ height: `${insets.top}px`, minHeight: `${insets.top}px`, width: '100%' }}
    />
  );
});

export const SafeAreaBottom = memo(() => {
  const insets = useContext(SafeAreaInsetsContext);

  if (!insets) {
    return null;
  }

  return (
    <div
      style={{
        height: `${insets.bottom}px`,
        minHeight: `${insets.bottom}px`,
        width: '100%',
      }}
    />
  );
});

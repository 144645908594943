import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';

const StyleContactInformationTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.smalt};
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  height: 64px;
  letter-spacing: -0.4px;
  padding: 0 20px;
  text-align: left;

  ${up('md')} {
    height: 65px;
  }
`;

const CloseIcon = styled(Icon)`
  align-self: center;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

type ContactInformationTitleProps = {};

export const ContactInformationTitle = memo<ContactInformationTitleProps>(() => {
  const { setShowContactInformation } = useConversation();
  const { t } = useTranslation(['contacts']);

  return (
    <StyleContactInformationTitle>
      {t('contacts:information.title')}
      <Expand />
      <CloseIcon onClick={() => setShowContactInformation(false)} type="close" />
    </StyleContactInformationTitle>
  );
});

import React, { memo } from 'react';
import Modal from 'antd/lib/modal';
import { up, down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const PreviewModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
  }

  ${down('md')} {
    animation-duration: 0s;
  }

  .ant-modal-mask {
    background: red;
  }

  .ant-modal-content {
    margin: 0 12px;
  }

  .ant-modal-body {
    padding: 0;
    line-height: 0;

    > * {
      max-width: 100%;
    }
  }

  .ant-modal-close {
    position: fixed;
    top: ${({ theme }) => `${theme.insets.top + 20}px`};
    right: 20px;

    .ant-modal-close-x {
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }

  ${up('lg')} {
    .ant-modal-content {
      margin: 0 50px;
    }

    .ant-modal-close {
      position: absolute;
      top: -38px;
      right: -38px;
    }
  }
`;

const ModalCloseIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const ShowDetailsIcon = styled(Icon)`
  padding: 0 7px;
  position: fixed;
  top: ${({ theme }) => `${theme.insets.top + 20}px`};
  right: 51px;

  svg {
    width: 4px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

type MediaPreviewModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  onShowDetails?: VoidFunction;
  children: React.ReactNode;
  width?: string;
  className?: string;
};

export const MediaPreviewModal = memo<MediaPreviewModalProps>(
  ({ onClose, onShowDetails, visible, width = 'auto', children, className }) => {
    const breakpointUpMd = useBreakpoint(up('md'));

    return (
      <PreviewModal
        visible={visible}
        closeIcon={<ModalCloseIcon type="close" lggTestId="media-preview-close-icon" />}
        destroyOnClose
        data-lgg-id="media-preview-modal"
        onCancel={onClose}
        footer={null}
        maskClosable
        centered
        width={width}
        zIndex={breakpointUpMd ? ModalsZIndex : undefined}
        className={className}
      >
        {!breakpointUpMd && onShowDetails && (
          <ShowDetailsIcon
            onClick={onShowDetails}
            type="moreOptions"
            lggTestId="media-preview-more-options-icon"
          />
        )}
        {children}
      </PreviewModal>
    );
  },
);

import React, { memo } from 'react';
import styled from 'styled-components';
import { useFormatDate } from 'src/hooks/use-format-date';

export const MessageTime = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  line-height: 12px;
`;

type InteractionTimeProps = {
  createdAt: string;
};

export const InteractionTime = memo<InteractionTimeProps>(({ createdAt, ...rest }) => {
  const { formatDate } = useFormatDate();

  return (
    <MessageTime {...rest} data-lgg-id="contact-interactions-interaction-time">
      {formatDate(new Date(createdAt), 'h:mm a')}
    </MessageTime>
  );
});

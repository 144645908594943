import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { matchScheduleAttendeeExhaustive } from '@lgg/isomorphic/utils/match-schedule-attendee';
import { getAppointmentStatusTransKey } from 'src/components/domain/appointments/helpers/get-appointment-status-trans-key';
import { getTaskCategoryTransKey } from 'src/components/domain/tasks/helpers/get-task-category-trans-key';
import { getTaskReminderTransKey } from 'src/components/domain/tasks/helpers/get-task-reminder-trans-key';
import { getTaskStatusTransKey } from 'src/components/domain/tasks/helpers/get-task-status-trans-key';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';
import { useUrls } from 'src/hooks/use-urls';

const EventPopoverDetailItemContainer = styled.div`
  font-size: 13px;
  line-height: 15px;
  padding: 8px 8px 7px 20px;
  text-align: left;

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
    padding: 7px 10px 5px;
  }

  &:first-child {
    margin-top: 15px;

    ${up('md')} {
      margin-top: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;

    ${up('md')} {
      margin-bottom: 3px;
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    text-transform: capitalize;
  }

  .value {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};

    a {
      font-family: ${({ theme }) => theme.font.medium};
      color: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const EventPopoverDetailItem = ({
  title,
  value,
  ...rest
}: {
  title: string;
  value: ReactElement | string;
  ['data-lgg-id']?: string;
}) => {
  return (
    <EventPopoverDetailItemContainer {...rest}>
      <span className="title">{`${title}: `}</span>
      <span className="value">{value}</span>
    </EventPopoverDetailItemContainer>
  );
};

export const TaskEventDetails = (props: { task: TaskEvent }) => {
  const { task } = props;
  const { t } = useTranslation(['tasks', 'calendar']);

  const categoryTransKey = getTaskCategoryTransKey(task.category);
  const reminderTransKey = getTaskReminderTransKey(new Date(task.dueAt), task.reminderAt);
  const { getContactModalUrl } = useUrls();

  return (
    <>
      {task.contact && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.contact')}
          data-lgg-id="task-event-detail-contact"
          value={
            <Link to={getContactModalUrl(task.contact.id)}>{task.contact.label}</Link>
          }
        />
      )}
      <EventPopoverDetailItem
        title={t('calendar:eventPopover.status')}
        data-lgg-id="task-event-detail-status"
        value={t(getTaskStatusTransKey(task.taskStatus))}
      />
      {task.assignedTo?.fullName && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.owner')}
          data-lgg-id="task-event-detail-owner"
          value={task.assignedTo?.fullName}
        />
      )}
      {categoryTransKey && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.category')}
          data-lgg-id="task-event-detail-category"
          value={t(categoryTransKey)}
        />
      )}
      {reminderTransKey && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.reminder')}
          data-lgg-id="task-event-detail-reminder"
          value={t(reminderTransKey)}
        />
      )}
    </>
  );
};

export const ScheduleEventDetails = (props: { schedule: Schedule }) => {
  const { schedule } = props;
  const { t } = useTranslation(['appointments', 'calendar']);
  const { getContactModalUrl } = useUrls();

  return (
    <>
      {schedule.attendees.length > 0 && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.attendees')}
          data-lgg-id="schedule-event-detail-attendees"
          value={
            <>
              {schedule.attendees.map((attendee, index) => {
                const lastItem = index === schedule.attendees.length - 1;
                const suffix = lastItem ? '' : ', ';

                return (
                  <Fragment key={attendee.id}>
                    {matchScheduleAttendeeExhaustive(attendee, {
                      contactAttendee: (attendee) => (
                        <Link to={getContactModalUrl(attendee.contact.id)}>
                          {attendee.contact.label}
                        </Link>
                      ),
                      userAttendee: (attendee) => <>{attendee.user.fullName}</>,
                      emailAttendee: (attendee) => <>{attendee.email}</>,
                    })}
                    {suffix}
                  </Fragment>
                );
              })}
            </>
          }
        />
      )}
      <EventPopoverDetailItem
        title={t('calendar:eventPopover.status')}
        data-lgg-id="schedule-event-detail-status"
        value={t(getAppointmentStatusTransKey(schedule.status))}
      />
      {schedule.user?.fullName && (
        <EventPopoverDetailItem
          title={t('calendar:eventPopover.owner')}
          data-lgg-id="schedule-event-detail-owner"
          value={schedule.user?.fullName}
        />
      )}
    </>
  );
};

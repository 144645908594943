import { match } from 'ts-pattern';
import { Task } from '@lgg/isomorphic/types/__generated__/graphql';

export const getTaskStatusTransKey = (status: Task['status']) => {
  return match(status)
    .with('IN_PROGRESS', () => 'tasks:status.inProgress' as const)
    .with('YET_TO_START', () => 'tasks:status.yetToStart' as const)
    .with('COMPLETED', () => 'tasks:status.completed' as const)
    .exhaustive();
};

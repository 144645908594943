import React, { memo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import Modal from 'antd/lib/modal';
import c from 'classnames';
import styled from 'styled-components';
import {
  Mutation,
  MutationConversationSnoozeArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggButton } from 'src/components/general/button/lgg-button';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { SnoozeConversationDatePicker } from 'src/components/pages/conversations/components/conversations/snooze-conversation-modal/snooze-conversation-date-picker';
import { useFormatSnoozeConversationMessage } from 'src/components/pages/conversations/hooks/use-format-snooze-conversation-message';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { publishEvent } from 'src/utils/events/pub-sub';
import { useSnoozeUntilDates } from 'src/utils/snooze-utils';

const CONVERSATION_SNOOZE = gql`
  mutation ConversationSnooze($conversationId: String!, $date: DateTime!) {
    conversationSnooze(conversationId: $conversationId, date: $date) {
      conversation {
        id
        isSnoozed
        snoozedUntil
        snoozedBy {
          id
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  max-width: 350px;

  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-header {
      border-radius: inherit;
      border: none;
      padding: 20px 20px 0;

      .ant-modal-title {
        color: ${({ theme }) => theme.colors.carbon};
        font-family: ${({ theme }) => theme.font.regular};
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: -0.48px;
        line-height: 29px;
        text-align: left;
      }
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      border-top-color: ${({ theme }) => theme.colors.koala};
      padding: 15px 20px;
    }
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 15px;
  }
`;

const ModalCloseIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const FooterButton = styled(LggButton)`
  background: ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 14px;
  max-height: 34px;
  padding: 10px 15px 11px;
  text-align: left;
  width: max-content;

  &.primary {
    background: ${({ theme }) => theme.colors.monk};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled {
    pointer-events: none;
  }
`;

const SnoozeConversationOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 20px 0;
`;

const SnoozeConversationOption = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.flint};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.regular};
  height: 90px;
  justify-content: center;
  margin-bottom: 20px;
  width: 90px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.alabaster};
  }

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.monk};
    border: none;
    box-shadow: 0 6px 10px 0 rgba(149, 195, 210, 0.21);
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.medium};

    svg path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const SnoozeConversationOptionIcon = styled(Icon)`
  margin-bottom: 10px;
`;

const SnoozeConversationOptionLabel = styled.p`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 14px;
  margin: 0 3px;
  text-align: center;
`;

type SnoozeOption = {
  key: string;
  icon: string;
  label: string;
  onClick: VoidFunction;
};

type SnoozeConversationModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  zIndex?: number;
  conversationId?: string;
  onSetDate?: ValueChanged<Date>;
};

const useSnoozeConversationMutation = (input: { onClose: VoidFunction }) => {
  const { onClose } = input;
  const showNotification = useShowNotification();
  const formatSnoozeConversationMessage = useFormatSnoozeConversationMessage();
  const { ensureDate } = useDateHelpers();
  const { t } = useTranslation(['conversations']);

  return useMutation<
    Pick<Mutation, 'conversationSnooze'>,
    MutationConversationSnoozeArgs
  >(CONVERSATION_SNOOZE, {
    onCompleted: ({ conversationSnooze: { conversation } }) => {
      const { id, snoozedUntil } = conversation;

      publishEvent('CONVERSATION_UPDATED', { id });

      showNotification({
        type: 'success',
        customIcon: 'snooze',
        title: formatSnoozeConversationMessage(ensureDate(snoozedUntil)),
      });
      onClose();
    },
    onError: () => {
      showNotification({
        type: 'error',
        customIcon: 'snooze',
        title: t(
          'conversations:notifications.mutations.changeSnoozeStatus.toSnoozed.error',
        ),
      });
    },
  });
};

const useSnoozeOptions = (input: {
  setSnoozeUntilDate: ValueChanged<Date | null>;
  customDatePickerDate: Date | null;
  setShowCustomDatePicker: ValueChanged<boolean>;
}): SnoozeOption[] => {
  const { setSnoozeUntilDate, customDatePickerDate, setShowCustomDatePicker } = input;
  const { t } = useTranslation(['conversations']);
  const {
    getSnoozeDateUntil30Minutes,
    getSnoozeDateUntilNextWeek,
    getSnoozeDateUntilNHours,
    getSnoozeDateUntilTomorrow,
  } = useSnoozeUntilDates();

  return [
    {
      key: 'snooze30Min',
      icon: 'snooze',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.thirtyMinutes'),
      onClick: () => {
        setSnoozeUntilDate(getSnoozeDateUntil30Minutes());
      },
    },
    {
      key: 'snoozeThreeHours',
      icon: 'snoozeThreeHours',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.threeHours'),
      onClick: () => {
        setSnoozeUntilDate(getSnoozeDateUntilNHours(3));
      },
    },
    {
      key: 'snoozeLaterToday',
      icon: 'snoozeLaterToday',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.laterToday'),
      onClick: () => {
        setSnoozeUntilDate(getSnoozeDateUntilNHours(6));
      },
    },
    {
      key: 'snoozeTomorrow',
      icon: 'snoozeTomorrow',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.tomorrow'),
      onClick: () => {
        setSnoozeUntilDate(getSnoozeDateUntilTomorrow());
      },
    },
    {
      key: 'snoozeNextWeek',
      icon: 'snoozeNextWeek',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.nextWeek'),
      onClick: () => {
        setSnoozeUntilDate(getSnoozeDateUntilNextWeek());
      },
    },
    {
      key: 'snoozePickDay',
      icon: 'snoozePickDay',
      label: t('conversations:conversationSnoozeModal.snoozeOptions.pickDay'),
      onClick: () => {
        setSnoozeUntilDate(customDatePickerDate);
        setShowCustomDatePicker(true);
      },
    },
  ];
};

export const SnoozeConversationModal = memo<SnoozeConversationModalProps>(
  ({ onClose, visible, zIndex, conversationId, onSetDate }) => {
    const { set, isFuture, startOfToday } = useDateHelpers();
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [snoozeUntilDate, setSnoozeUntilDate] = useState<Date | null>(null);
    const [showCustomDatePicker, setShowCustomDatePicker] = useState<boolean>(false);
    const [customDatePickerDate, setCustomDatePickerDate] = useState<Date>(
      set(startOfToday(), { hours: 8, minutes: 0, seconds: 0, useTimezone: true }),
    );
    const { t } = useTranslation(['conversations']);
    const showNotification = useShowNotification();
    const [snoozeConversation, { loading: snoozingConversation }] =
      useSnoozeConversationMutation({ onClose });

    const options = useSnoozeOptions({
      setSnoozeUntilDate,
      customDatePickerDate,
      setShowCustomDatePicker,
    });

    useEffect(() => {
      setSelectedOption(null);
      setShowCustomDatePicker(false);
    }, [visible]);

    const isSelectedDateValid =
      selectedOption !== null && snoozeUntilDate !== null && isFuture(snoozeUntilDate);

    const handleSubmit = useCallback(() => {
      if (!isSelectedDateValid) {
        showNotification({
          type: 'error',
          customIcon: 'snooze',
          title: `${t(
            'conversations:notifications.mutations.changeSnoozeStatus.toSnoozed.error',
          )}`,
        });

        return;
      }

      if (onSetDate) {
        onSetDate(snoozeUntilDate);
      } else if (conversationId) {
        void snoozeConversation({
          variables: {
            conversationId,
            date: snoozeUntilDate,
          },
        });
      }
    }, [
      conversationId,
      isSelectedDateValid,
      onSetDate,
      showNotification,
      snoozeConversation,
      snoozeUntilDate,
      t,
    ]);

    return (
      <StyledModal
        visible={visible}
        destroyOnClose
        title={t('conversations:conversationSnoozeModal.title')}
        wrapProps={{
          'data-lgg-id': 'snooze-conversation-modal',
        }}
        onCancel={onClose}
        zIndex={zIndex ?? 1040}
        closeIcon={<ModalCloseIcon type="close" />}
        maskClosable
        centered
        footer={
          <StyledModalFooter>
            <FooterButton
              variant="tertiary"
              className="tertiary"
              onClick={onClose}
              data-lgg-id="snooze-conversation-cancel-modal-option"
            >
              {t('conversations:conversationSnoozeModal.cancelOption')}
            </FooterButton>
            <FooterButton
              data-lgg-id="snooze-conversation-set-snooze-modal-option"
              loading={snoozingConversation}
              className={c({ primary: isSelectedDateValid })}
              onClick={handleSubmit}
              disabled={!isSelectedDateValid}
            >
              {t('conversations:conversationSnoozeModal.setSnoozeOption')}
            </FooterButton>
          </StyledModalFooter>
        }
      >
        {showCustomDatePicker ? (
          <SnoozeConversationDatePicker
            backArrowHandler={() => {
              setShowCustomDatePicker(false);
              setSelectedOption(null);
            }}
            datePickerDate={customDatePickerDate}
            setDatePickerDate={(date) => {
              setCustomDatePickerDate(date);
              setSnoozeUntilDate(date);
            }}
          />
        ) : (
          <SnoozeConversationOptionsContainer>
            {options.map(({ icon, label, onClick, key }) => (
              <SnoozeConversationOption
                key={key}
                data-lgg-id={`snooze-conversation-option-${key}`}
                onClick={() => {
                  setSelectedOption(key);
                  onClick();
                }}
                className={c({ selected: selectedOption === key })}
              >
                <SnoozeConversationOptionIcon type={icon} />
                <SnoozeConversationOptionLabel>{label}</SnoozeConversationOptionLabel>
              </SnoozeConversationOption>
            ))}
          </SnoozeConversationOptionsContainer>
        )}
      </StyledModal>
    );
  },
);

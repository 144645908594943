import { orderBy } from 'lodash';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortAppointments = (appointment: Schedule[]) => {
  return orderBy(
    appointment,
    [
      (appointment) => {
        return new Date(appointment.createdAt).getTime();
      },
      (appointment) => {
        return appointment.id;
      },
    ],
    ['desc', 'asc'],
  );
};

import styled from 'styled-components';

export const StyledCollapseButton = styled.button`
  width: 16px;
  height: 18px;
  padding: 0;
  background: transparent;
  border: none;
  z-index: 999;
  color: ${({ theme }) => theme.colors.white};

  &:focus {
    outline: none;
  }
`;

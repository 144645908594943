import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactInteractionAttachmentLocation,
  ContactInteractionDirection,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ReactComponent as LocationIllustration } from 'src/assets/images/conversations/location-attachment-illustration.svg';
import { FlexColumn } from 'src/components/layout/flex-column';
import {
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import { RepliedMessageRenderer } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { ContactInteractionsWithAttachmentUnion } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';

type LocationAttachmentProps = {
  direction: ContactInteractionDirection;
  attachment: ContactInteractionAttachmentLocation;
  attachmentDate: string;
  showDeliveryStatus: boolean;
  contactInteraction: ContactInteractionsWithAttachmentUnion;
};

const LocationName = styled.a`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin: 0;

  &,
  &:visited {
    ${({ theme }) => theme.colors.gogo};
  }
`;

const LocationAddress = styled.p`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
`;

const LocationInformationContainer = styled(FlexColumn)`
  max-width: 260px;
  margin-top: 10px;
`;

const StyledLocationIllustration = styled(LocationIllustration)<{
  direction: ContactInteractionDirection;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  width: 100%;
  max-width: 260px;
  height: 100px;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.porcelain};

    path {
      fill: ${({ theme }) => theme.colors.porcelain};
    }
  }

  ${({ direction }) => {
    if (direction === 'OUTBOUND') {
      return 'border-bottom-right-radius: 0px;';
    } else {
      return 'border-bottom-left-radius: 0px;';
    }
  }}
`;

const buildGoogleMapsUrl = (lat: number, long: number) => {
  return `https://www.google.com/maps?q=${lat},${long}`;
};

export const LocationAttachmentItem = memo<LocationAttachmentProps>(
  ({ attachment, direction, attachmentDate, showDeliveryStatus, contactInteraction }) => {
    const { address, title, lat, long, status = null } = attachment;
    const locationUrl = buildGoogleMapsUrl(lat, long);
    const showLocationInformation = title || address;

    return (
      <BubbleTypeAttachmentContainer direction={direction}>
        <RepliedMessageRenderer contactInteraction={contactInteraction} />
        <a rel="noopener noreferrer" target="_blank" href={locationUrl}>
          <StyledLocationIllustration
            direction={direction}
            data-lgg-id="location-map-illustration"
          />
        </a>
        {showLocationInformation ? (
          <LocationInformationContainer>
            {title && (
              <LocationName
                rel="noopener"
                target="_blank"
                href={locationUrl}
                data-lgg-id="location-title"
              >
                {title}
              </LocationName>
            )}
            {address && (
              <LocationAddress data-lgg-id="location-address">{address}</LocationAddress>
            )}
          </LocationInformationContainer>
        ) : null}
        <AttachmentDateAndStatus
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
          attachmentDate={attachmentDate}
        />
      </BubbleTypeAttachmentContainer>
    );
  },
);

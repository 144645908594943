import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { getManageTemplatesUrl } from 'src/components/pages/whatsapp-channel/shared/helpers';

export const StyledSyncIcon = styled(Icon)<{ $rotating: boolean }>`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  svg {
    ${({ $rotating }) => $rotating && 'animation: spin 1s linear infinite;'};
  }
`;

const SyncWithMetaButton = styled(LggButton)`
  background: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.koala};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  height: 34px;
  margin-right: 10px;
  padding: 5px 10px;
  width: max-content;

  span {
    display: none;
  }

  &:hover,
  &:focus,
  &:visited,
  &:active {
    background: ${({ theme }) => theme.colors.white};
  }

  ${up('md')} {
    margin-right: 15px;
    padding: 10px 15px;

    span {
      display: block;
      height: 14px;
      margin-right: 5px;
      width: 14px;
    }
  }
`;

type WhatsappSyncButtonProps = {
  onClick: VoidFunction;
  isSyncing?: boolean;
  testId: string;
};

export const WhatsappSyncButton = ({
  onClick,
  isSyncing = false,
  testId,
}: WhatsappSyncButtonProps) => {
  const { t } = useTranslation(['whatsappChannel']);

  return (
    <SyncWithMetaButton data-lgg-id={testId} variant="tertiary" onClick={onClick}>
      <StyledSyncIcon type="sync" $rotating={isSyncing} />
      {t('whatsappChannel:syncWithMetaButton.label')}
    </SyncWithMetaButton>
  );
};

const ManageTemplatesButtonIcon = styled(Icon)`
  margin-left: 10px;

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const ManageTemplatesButton = styled(LggButton)`
  background: ${({ theme }) => theme.colors.porcelain};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  height: 34px;
  padding: 10px 15px;
  width: max-content;
`;

type ManageWhatsappTemplatesButtonProps = {
  isLoading: boolean;
  wabaId?: string;
};

export const ManageWhatsappTemplatesButton = ({
  isLoading,
  wabaId,
}: ManageWhatsappTemplatesButtonProps) => {
  const { t } = useTranslation(['whatsappChannel']);

  const manageTemplatesUrl = useMemo(() => {
    return wabaId ? getManageTemplatesUrl(wabaId) : '';
  }, [wabaId]);

  return (
    <ExternalWhatsappLinkButton
      url={manageTemplatesUrl}
      isLoading={isLoading}
      text={t('whatsappChannel:manageTemplatesButton.label')}
      testId="manage-templates-link"
    />
  );
};

type ExternalWhatsappLinkButtonProps = {
  url?: string;
  isLoading: boolean;
  text: string;
  variant?: 'primary' | 'defaultWhite' | 'tertiary' | 'secondary';
  testId: string;
};

export const ExternalWhatsappLinkButton = ({
  isLoading,
  text,
  url,
  variant = 'tertiary',
  testId,
}: ExternalWhatsappLinkButtonProps) => {
  return (
    <Link
      to={{
        pathname: url,
      }}
      data-lgg-id={testId}
      target="_blank"
    >
      <ManageTemplatesButton variant={variant} loading={isLoading}>
        {text}
        <ManageTemplatesButtonIcon type="externalLink" />
      </ManageTemplatesButton>
    </Link>
  );
};

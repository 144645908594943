import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import { Button as RadioButton, Group as RadioGroup } from 'antd/lib/radio';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { CalendarDatePicker } from 'src/components/general/inputs/calendar-date-picker';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

const CalendarHeaderArrowIcon = styled(Icon)`
  cursor: pointer;

  svg {
    width: 15px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const CustomTimePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const CustomDatePickerContainer = styled.div`
  padding: 10px 20px 15px;
`;

const CustomDatePickerTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomDatePickerTitle = styled.p`
  margin: 0 0 0 10px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.smalt};
`;

const CustomTimePickerTitle = styled.p`
  height: 13px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 13px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.raven};
  margin: 0 0 1px 0;
`;

const MeridiemSelector = styled(RadioGroup)`
  display: flex;
  align-items: center;
`;

const MeridiemSelectorButton = styled(RadioButton)`
  display: flex;
  align-items: baseline;

  &.ant-radio-button-wrapper {
    height: 38px;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 11px;
    border: none;
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.flint};
    border: 1px solid ${({ theme }) => theme.colors.koala};

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.ant-radio-button-wrapper-checked {
      box-shadow: 0 6px 10px 0 rgba(149, 195, 210, 0.21);
      border-color: ${({ theme }) => theme.colors.monk};
      background: ${({ theme }) => theme.colors.monk};
      color: ${({ theme }) => theme.colors.white};

      &:not(
          [class*=' ant-radio-button-wrapper-disabled']
        ).ant-radio-button-wrapper:first-child {
        border-right-color: ${({ theme }) => theme.colors.monk};
      }

      &::before {
        background: none;
      }
    }
  }
`;

const CustomTimeDropdownButton = styled.div`
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 90px;
  padding-left: 15px;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  height: 38px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.smalt};
`;

type Props = {
  backArrowHandler: VoidFunction;
  setDatePickerDate: (date: any) => void;
  datePickerDate: Date;
};

export const SnoozeConversationDatePicker = memo<Props>(
  ({ backArrowHandler, setDatePickerDate, datePickerDate }) => {
    const [meridiem, setMeridiem] = useState<'AM' | 'PM'>('AM');
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(8);
    const { t } = useTranslation(['conversations']);
    const { set, isPast, isToday } = useDateHelpers();

    const getHourWithMeridiem = (hour) => {
      const meridiemStep = 12;

      return meridiem === 'AM'
        ? hour >= meridiemStep
          ? hour - meridiemStep
          : hour
        : hour + meridiemStep;
    };

    const minutesDropdownMenu = (
      <Menu>
        {[...new Array(4)].map((_, index) => {
          const value = index * 15;
          const displayValue = `${value}m`;
          const possibleDate = set(datePickerDate, { minutes: value });

          return (
            <Menu.Item
              key={displayValue}
              disabled={isPast(possibleDate)}
              onClick={() => {
                setMinutes(value);
                const updatedDate = set(datePickerDate, { minutes: value });
                setDatePickerDate(updatedDate);
              }}
            >
              {displayValue}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const hoursDropdownMenu = (
      <Menu>
        {[...new Array(12)].map((_, index) => {
          const value = index + 1;
          const displayValue = `${value}h`;

          return (
            <Menu.Item
              key={displayValue}
              disabled={isPast(
                set(datePickerDate, { hours: getHourWithMeridiem(value), minutes: 45 }),
              )}
              onClick={() => {
                setHours(value);
                const updatedDate = set(datePickerDate, {
                  hours: getHourWithMeridiem(value),
                  minutes,
                });

                setDatePickerDate(updatedDate);
              }}
            >
              {displayValue}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <CustomDatePickerContainer data-lgg-id="custom-date-picker">
        <CustomDatePickerTitleContainer>
          <CalendarHeaderArrowIcon type="arrowBackNoPadding" onClick={backArrowHandler} />
          <CustomDatePickerTitle>{`${t(
            'conversations:conversationSnoozeModal.subTitle',
          )}:`}</CustomDatePickerTitle>
        </CustomDatePickerTitleContainer>
        <CalendarDatePicker
          disabledDate={(date) => !isToday(date.toDate()) && isPast(date.toDate())}
          onSelect={(date) => {
            const updatedDate = set(date, { minutes, hours: getHourWithMeridiem(hours) });

            setDatePickerDate(updatedDate);
          }}
          datePickerDate={datePickerDate}
          setDatePickerDate={setDatePickerDate}
        />
        <CustomTimePickerTitle>
          {t('conversations:conversationSnoozeModal.customTimePickerTitle')}
        </CustomTimePickerTitle>
        <CustomTimePickersContainer>
          <Dropdown overlay={hoursDropdownMenu} trigger={['click']}>
            <CustomTimeDropdownButton>
              {`${hours}h`}
              <Icon type="arrowdown" />
            </CustomTimeDropdownButton>
          </Dropdown>
          <Dropdown overlay={minutesDropdownMenu} trigger={['click']}>
            <CustomTimeDropdownButton>
              {`${minutes}m`}
              <Icon type="arrowdown" />
            </CustomTimeDropdownButton>
          </Dropdown>
          <MeridiemSelector
            size="small"
            value={meridiem}
            onChange={(e) => {
              setMeridiem(e.target.value);

              const updatedDate = set(datePickerDate, {
                hours: getHourWithMeridiem(hours),
              });

              setDatePickerDate(updatedDate);
            }}
          >
            <MeridiemSelectorButton value="AM">AM</MeridiemSelectorButton>
            <MeridiemSelectorButton value="PM">PM</MeridiemSelectorButton>
          </MeridiemSelector>
        </CustomTimePickersContainer>
      </CustomDatePickerContainer>
    );
  },
);

import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'src/components/general/icon';

export const InstitutionNameTextStyle = css`
  display: block;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  line-height: 1.38;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InstitutionAvatarContainer = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey1};
  border-radius: 4px;
  flex: 0 0 30px;
  height: 30px;
  line-height: 34px;
  position: relative;
  text-align: center;
  width: 30px;

  .lgg-icon {
    color: rgb(152, 169, 188) !important;
  }
`;

export const InstitutionAvatar = memo(() => {
  return (
    <InstitutionAvatarContainer>
      <Icon type="organization" />
    </InstitutionAvatarContainer>
  );
});

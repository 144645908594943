import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggDropdownButton } from 'src/components/general/button/dropdown-button';
import {
  AddNewContextMenu,
  AddNewOptionIcon,
} from 'src/components/general/display/add-new-context-menu';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

export const useContactAddNewOptions = (props: { contactId: Contact['id'] }) => {
  const { contactId } = props;
  const { t } = useTranslation(['contacts']);
  const { getFeatureFlag } = useAuthorization();
  const {
    getTaskModalEditForContactUrl,
    getLegacyContactAddNoteUrl,
    getScheduleModalEditForContactUrl,
    getLegacyContactAddInteractionUrl,
  } = useUrls();

  const options: DrawerOption[] = [];

  if (getFeatureFlag('crm')) {
    options.push({
      'data-lgg-id': 'contact-add-new-contact-interaction',
      label: t('contacts:actionsOptions.interaction'),
      icon: (
        <AddNewOptionIcon
          backgroundColor="secondaryTopaz20"
          iconColor="secondaryTopazDark"
          icon="interaction18"
        />
      ),
      onClick: () => {
        openRemoteModalWrapper(getLegacyContactAddInteractionUrl(contactId));
      },
    });

    options.push({
      'data-lgg-id': 'contact-add-new-task',
      label: t('contacts:actionsOptions.task'),
      icon: (
        <AddNewOptionIcon
          backgroundColor="secondaryMint20"
          iconColor="secondaryMintDark"
          icon="task18"
        />
      ),
      to: getTaskModalEditForContactUrl(contactId),
    });

    options.push({
      'data-lgg-id': 'contact-add-new-schedule',
      label: t('contacts:actionsOptions.schedule'),
      icon: (
        <AddNewOptionIcon
          backgroundColor="secondaryPeriwinkle20"
          iconColor="secondaryPeriwinkleDark"
          icon="schedule18"
        />
      ),
      to: getScheduleModalEditForContactUrl(contactId),
    });

    options.push({
      'data-lgg-id': 'contact-add-new-note',
      label: t('contacts:actionsOptions.note'),
      icon: (
        <AddNewOptionIcon
          backgroundColor="secondaryFlower20"
          iconColor="secondaryFlowerDark"
          icon="tabNotes"
        />
      ),
      onClick: () => {
        openRemoteModalWrapper(getLegacyContactAddNoteUrl(contactId));
      },
    });
  }

  return options.length ? options : null;
};

type ContactAddNewDrawerProps = {
  visible: boolean;
  contactId: Contact['id'];
  onClose: VoidFunction;
};

export const ContactAddNewDrawer = memo<ContactAddNewDrawerProps>(
  ({ contactId, onClose, visible }) => {
    const { t } = useTranslation(['common']);
    const contactActionsOptions = useContactAddNewOptions({ contactId });

    if (!contactActionsOptions) {
      return null;
    }

    return (
      <OptionsBottomDrawer
        visible={visible}
        title={t('common:actions.addNew')}
        onClose={onClose}
        options={contactActionsOptions}
      />
    );
  },
);

export const ContactAddNewDropdownTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  margin: 10px 6px 14px;
`;

type ContactAddNewDropdownProps = {
  contactId: Contact['id'];
};

export const ContactAddNewDropdown = memo<ContactAddNewDropdownProps>(
  ({ contactId, ...rest }) => {
    const { t } = useTranslation(['common']);
    const visibilityHandler = useVisible();
    const contactActionsOptions = useContactAddNewOptions({ contactId });

    if (!contactActionsOptions) {
      return null;
    }

    return (
      <LggDropdownButton
        {...rest}
        icon="contactActions"
        overlay={
          <AddNewContextMenu
            groups={[
              contactActionsOptions.map((option) => {
                const testId = option['data-lgg-id'] ?? '';

                return {
                  ...option,
                  testId,
                  key: testId,
                  onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
                    option.onClick?.(e);
                    visibilityHandler.close();
                  },
                };
              }),
            ]}
          />
        }
        size="regular"
        variant="cta"
        visibilityHandler={visibilityHandler}
      >
        {t('common:actions.addNew')}
      </LggDropdownButton>
    );
  },
);

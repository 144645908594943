import { memo } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ParagraphNode } from 'lexical';
import { TextNodeResolver } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/text-node-resolver';
import { UserMentionNodeResolver } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node-resolver';
import { LexicalContainer } from 'src/components/pages/conversations/components/general/lexical-editor/shared';
import { CustomParagraphNode } from './nodes/custom-paragraph-resolver';

const lexicalViewerConfig = {
  namespace: 'LggLexicalViewer',
  theme: {
    ltr: 'ltr',
    rtl: 'rtl',
  },
  onError(error) {
    throw error;
  },
};

type LexicalViewerProps = {
  jsonString: string;
};

export const LexicalViewer = memo<LexicalViewerProps>(({ jsonString }) => {
  return (
    <LexicalComposer
      initialConfig={{
        ...lexicalViewerConfig,
        nodes: [
          UserMentionNodeResolver,
          TextNodeResolver,
          CustomParagraphNode,
          {
            replace: ParagraphNode,
            with: () => new CustomParagraphNode(),
          },
        ],
        editable: false,
        editorState: jsonString,
      }}
    >
      <LexicalContainer>
        <PlainTextPlugin
          placeholder={<></>}
          contentEditable={<ContentEditable />}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </LexicalContainer>
    </LexicalComposer>
  );
});

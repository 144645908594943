import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { ProfileSettingsModalDesktopView } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal.desktop-view';
import { ProfileSettingsModalMobileView } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal.mobile-view';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export type BaseFormProps = {
  formId: string;
};

export const ProfileSettingsModal = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { visibilityHandler } = useProfileSettings();

  return (
    <>
      {visibilityHandler.visible &&
        (breakpointUpMd ? (
          <ProfileSettingsModalDesktopView />
        ) : (
          <ProfileSettingsModalMobileView />
        ))}
    </>
  );
});

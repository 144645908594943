import React, { memo, RefObject } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  EmptyListMessage,
  EmptyListPlaceholder,
  EmptyListTitle,
} from 'src/components/general/feedback/empty-list-placeholder';
import { Center } from 'src/components/layout/center';
import { LoadingIndicator } from 'src/components/loading-fallback';

export type ContactModalTabProps = {
  contactId: number;
  virtuosoRef?: RefObject<VirtuosoHandle>;
};

const CenteredFullSpaceContainer = styled(Center)`
  height: 100%;
  width: 100%;
`;

export const ContactModalLoadingView = memo((props) => {
  return (
    <CenteredFullSpaceContainer {...props}>
      <LoadingIndicator />
    </CenteredFullSpaceContainer>
  );
});

export const ContactModalTabEmptyView = styled(EmptyListPlaceholder)`
  ${EmptyListTitle} {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.36px;
    line-height: 22px;
    text-align: center;

    ${up('md')} {
      font-size: 18px;
      letter-spacing: -0.36px;
      line-height: 22px;
    }
  }

  ${EmptyListMessage} {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 18.5px;
    text-align: center;
    max-width: unset;
    padding: 0 20px;

    ${up('md')} {
      font-size: 14px;
      letter-spacing: normal;
      line-height: 18.5px;
      padding: 0;
      max-width: 300px;
    }
  }
`;

import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AntDrawer from 'antd/lib/drawer';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionDirection,
  ContactInteractionPhoneCallDetail,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { CallAnalysisBadge } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/components/call-analysis-badge';
import {
  CallAnalysisDetails,
  CallAnalysisDetailsTabKey,
} from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/components/call-analysis-details';
import { InteractionTextContent } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const MainContainer = styled(FlexColumn)`
  padding: 15px 0 0;
`;

const TitleContainer = styled(FlexRow)`
  margin-bottom: 15px;
`;

const Title = styled.span`
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  letter-spacing: -0.26px;
  line-height: 15px;
`;

const TitleIcon = styled(Icon)`
  margin-right: 5px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const AnalysisInProgressMessageDivider = styled.div`
  background-image: linear-gradient(
    to right,
    rgba(152, 169, 188, 0),
    ${({ theme }) => theme.colors.flint}
  );
  display: none;
  height: 1px;
  opacity: 0.5;
  width: 60px;

  ${up('md')} {
    display: block;
  }
`;

const AnalysisInProgressMessageContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.alabaster};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: center;
  margin-bottom: 10px;
  padding: 14px 27px;

  ${up('md')} {
    padding: 14px 10px;

    ${AnalysisInProgressMessageDivider} {
      &:last-child {
        transform: rotate(180deg);
      }
    }
  }
`;

const AnalysisInProgressMessageText = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  letter-spacing: -0.24px;
  line-height: 14px;

  ${up('md')} {
    margin: 0 10px;
  }
`;

const InProgressMessageStrongText = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.medium};
`;

const CallAnalysisBadgesContainer = styled(FlexRow)`
  flex-wrap: wrap;
`;

const MoreDetailsOption = styled.div`
  color: ${({ theme }) => theme.colors.gogo};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
`;

const CallBrief = styled(InteractionTextContent)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-family: ${({ theme }) => theme.font.regular};
  letter-spacing: unset;
  margin: 0 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;

  ${up('md')} {
    -webkit-line-clamp: 2;
    font-size: 13px;
    letter-spacing: unset;
    line-height: 15px;
  }
`;

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const DrawerTitleIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 19px;
  }
`;

const DrawerTitleText = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  letter-spacing: -0.48px;
  line-height: 29px;
  text-align: left;
`;

const DrawerTitleContainer = styled(FlexRow)`
  align-items: center;
  height: 80px;
  justify-content: space-between;
  padding: 0 20px;
`;

const DrawerCloseIcon = styled(Icon)`
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const AnalysisInProgressMessage = () => {
  return (
    <AnalysisInProgressMessageContainer data-lgg-id="analysis-in-progress-message">
      <AnalysisInProgressMessageDivider />
      <AnalysisInProgressMessageText>
        <Trans
          i18nKey="conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.summaryInProgress"
          components={{ strong: <InProgressMessageStrongText /> }}
        />
      </AnalysisInProgressMessageText>
      <AnalysisInProgressMessageDivider />
    </AnalysisInProgressMessageContainer>
  );
};

type PhoneCallAnalysisProps = {
  details: ContactInteractionPhoneCallDetail;
  recordingSrc: string;
  direction: ContactInteractionDirection;
};

export const PhoneCallAnalysis = ({
  details,
  recordingSrc,
  direction,
}: PhoneCallAnalysisProps) => {
  const { t } = useTranslation(['conversations']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const callAnalysisDetailsVisibilityHandler = useVisible();
  const { brief, sentimentAnalysis, summary, transcription } = details;
  const [detailsSelectedTabKey, setDetailsSelectedTabKey] =
    useState<CallAnalysisDetailsTabKey>('overview');

  const openDetailsAtOverviewTab = useCallback(() => {
    setDetailsSelectedTabKey('overview');
    callAnalysisDetailsVisibilityHandler.show();
  }, [callAnalysisDetailsVisibilityHandler]);

  const hasAnalysisToShow = useMemo(() => {
    return [brief, sentimentAnalysis, summary, transcription].some((operation) => {
      const isOperationDoneOrPending = match(operation)
        .with(
          P.union(
            { __typename: 'SentimentAnalysisDisabled' },
            { __typename: 'ContactInteractionBriefDisabled' },
            { __typename: 'ContactInteractionSummaryDisabled' },
            { __typename: 'ContactInteractionTranscriptionDisabled' },
          ),
          () => false,
        )
        .otherwise(() => true);

      return operation && isOperationDoneOrPending;
    });
  }, [brief, sentimentAnalysis, summary, transcription]);

  const hasFinishedAnalysis = useMemo(() => {
    return [brief, sentimentAnalysis, summary, transcription].every((operation) => {
      const isOperationDone = match(operation)
        .with({ __typename: 'AsyncStatusPending' }, () => false)
        .otherwise(() => true);

      return isOperationDone === true;
    });
  }, [brief, sentimentAnalysis, summary, transcription]);

  const briefComponent = useMemo(() => {
    if (!brief) return null;

    return match(brief)
      .with({ __typename: 'ContactInteractionBrief' }, (brief) => (
        <CallBrief data-lgg-id="call-analysis-brief" direction={direction}>
          {brief.text}
        </CallBrief>
      ))
      .with(
        P.union(
          { __typename: 'ContactInteractionBriefDisabled' },
          { __typename: 'AsyncStatusPending' },
        ),
        () => null,
      )
      .exhaustive();
  }, [brief, direction]);

  const sentimentAnalysisBadges = useMemo(() => {
    if (!sentimentAnalysis) return null;

    return match(sentimentAnalysis)
      .with({ __typename: 'AsyncStatusPending' }, () => (
        <CallAnalysisBadge
          icon="neutralEmoji"
          title={t(
            'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.sentiments',
          )}
          type="neutral"
          inProgress
          data-lgg-id="sentiment-analysis-pending-badge"
          onClick={openDetailsAtOverviewTab}
        />
      ))
      .with(
        { __typename: 'ContactInteractionSentimentAnalysis' },
        ({ totalPositives, totalNegatives }) => {
          const sentimentsFound = totalPositives > 0 || totalNegatives > 0;

          if (sentimentsFound) {
            return (
              <>
                {totalPositives ? (
                  <CallAnalysisBadge
                    icon="happyEmoji"
                    title={t(
                      'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.positiveSentiment',
                      { count: totalPositives },
                    )}
                    type="positive"
                    data-lgg-id="sentiment-analysis-positives-badge"
                    onClick={openDetailsAtOverviewTab}
                  />
                ) : null}
                {totalNegatives ? (
                  <CallAnalysisBadge
                    icon="sadEmoji"
                    title={t(
                      'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.negativeSentiment',
                      { count: totalNegatives },
                    )}
                    type="negative"
                    data-lgg-id="sentiment-analysis-negatives-badge"
                    onClick={openDetailsAtOverviewTab}
                  />
                ) : null}
              </>
            );
          }

          if (
            transcription?.__typename === 'ContactInteractionTranscription' &&
            transcription.phrases.edges.length > 0
          ) {
            return (
              <CallAnalysisBadge
                icon="neutralEmoji"
                title={t(
                  'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.neutralSentiment',
                )}
                type="neutral"
                data-lgg-id="sentiment-analysis-neutral-badge"
                onClick={openDetailsAtOverviewTab}
              />
            );
          }

          return null;
        },
      )
      .with({ __typename: 'SentimentAnalysisDisabled' }, () => null)
      .exhaustive();
  }, [openDetailsAtOverviewTab, sentimentAnalysis, t, transcription]);

  const transcriptBadge = useMemo(() => {
    if (!transcription) return null;

    return match(transcription)
      .with({ __typename: 'ContactInteractionTranscriptionDisabled' }, () => null)
      .with(
        P.union(
          { __typename: 'AsyncStatusPending' },
          { __typename: 'ContactInteractionTranscription' },
        ),
        (transcription) => {
          const isInProgress = transcription.asyncStatus === 'PENDING';
          const hasTranscript =
            transcription.__typename === 'ContactInteractionTranscription' &&
            transcription.phrases.edges.length > 0;

          const badgeBuilder = (isInProgress: boolean) => {
            return (
              <CallAnalysisBadge
                icon="transcript"
                title={t(
                  'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.transcript',
                )}
                type="neutral"
                inProgress={isInProgress}
                data-lgg-id="call-transcription-badge"
                onClick={() => {
                  if (!isInProgress) {
                    setDetailsSelectedTabKey('transcript');
                    callAnalysisDetailsVisibilityHandler.show();
                  }
                }}
              />
            );
          };

          if (isInProgress) {
            return badgeBuilder(isInProgress);
          }

          if (!hasTranscript) {
            return null;
          }

          return badgeBuilder(false);
        },
      )
      .exhaustive();
  }, [callAnalysisDetailsVisibilityHandler, t, transcription]);

  const summaryBadge = useMemo(() => {
    if (!summary) return null;

    return match(summary)
      .with({ __typename: 'ContactInteractionSummaryDisabled' }, () => null)
      .with(
        P.union(
          { __typename: 'AsyncStatusPending' },
          { __typename: 'ContactInteractionSummary' },
        ),
        (summary) => {
          const isInProgress = summary.asyncStatus === 'PENDING';

          return (
            <CallAnalysisBadge
              icon="summary"
              title={t(
                'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.summary',
              )}
              type="neutral"
              inProgress={isInProgress}
              data-lgg-id="call-summary-badge"
              onClick={() => {
                if (!isInProgress) {
                  openDetailsAtOverviewTab();
                }
              }}
            />
          );
        },
      )
      .exhaustive();
  }, [openDetailsAtOverviewTab, summary, t]);

  const callAnalysisDetails = useMemo(
    () => (
      <CallAnalysisDetails
        details={details}
        recordingSrc={recordingSrc}
        defaultSelectedTab={detailsSelectedTabKey}
      />
    ),
    [details, detailsSelectedTabKey, recordingSrc],
  );

  if (!hasAnalysisToShow) return null;

  return (
    <MainContainer data-lgg-id="phone-call-analysis">
      <TitleContainer>
        <TitleIcon type="iconAi" />
        <Title>
          {t('conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.title')}
        </Title>
      </TitleContainer>
      {hasFinishedAnalysis ? briefComponent : <AnalysisInProgressMessage />}
      <CallAnalysisBadgesContainer>
        {sentimentAnalysisBadges}
        {transcriptBadge}
        {summaryBadge}
      </CallAnalysisBadgesContainer>
      {hasFinishedAnalysis ? (
        <MoreDetailsOption
          onClick={callAnalysisDetailsVisibilityHandler.show}
          data-lgg-id="call-details-link-button"
        >
          {t(
            'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.seeMoreDetails',
          )}
        </MoreDetailsOption>
      ) : null}
      {breakpointUpMd ? (
        <StyledDrawer
          closable={false}
          data-lgg-id="call-details-drawer"
          height="100%"
          maskClosable={false}
          maskStyle={{
            background: 'transparent',
          }}
          onClose={callAnalysisDetailsVisibilityHandler.close}
          placement="right"
          visible={callAnalysisDetailsVisibilityHandler.visible}
          width={375}
          destroyOnClose
        >
          <DrawerTitleContainer>
            <span>
              <DrawerTitleIcon type="iconAi" />
              <DrawerTitleText>
                {t(
                  'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.title',
                )}
              </DrawerTitleText>
            </span>
            <DrawerCloseIcon
              type="close"
              onClick={callAnalysisDetailsVisibilityHandler.close}
            />
          </DrawerTitleContainer>
          {callAnalysisDetails}
        </StyledDrawer>
      ) : (
        <BottomDrawer
          fullHeight
          onClose={callAnalysisDetailsVisibilityHandler.close}
          leading={<DrawerTitleIcon type="iconAi" />}
          title={t(
            'conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.moreDetailsDrawer.title',
          )}
          visible={callAnalysisDetailsVisibilityHandler.visible}
          destroyOnClose
        >
          {callAnalysisDetails}
        </BottomDrawer>
      )}
    </MainContainer>
  );
};

import { ContactInteractionUnion } from '@lgg/isomorphic/types/__generated__/graphql';

type GetActivityIconProps = {
  type: ContactInteractionUnion['__typename'];
  isUnansweredCall?: boolean;
};

export const getActivityIcon = ({
  type,
  isUnansweredCall = false,
}: GetActivityIconProps) => {
  switch (type) {
    case 'ContactInteractionPhoneCall': {
      if (isUnansweredCall) {
        return 'missedCall';
      }

      return 'phone';
    }
    case 'ContactInteractionSms': {
      return 'sms';
    }
    case 'ContactInteractionFacebookMessenger': {
      return 'facebookMessenger';
    }
    case 'ContactInteractionEmail': {
      return 'email';
    }
    case 'ContactInteractionFacebookAd': {
      return 'facebook';
    }
    case 'ContactInteractionInPerson': {
      return 'inPerson';
    }
    case 'ContactInteractionWhatsapp': {
      return 'whatsapp';
    }
    case 'ContactInteractionInstagram': {
      return 'instagramAlt';
    }
    case 'ContactInteractionUnknown':
    default: {
      return 'interaction';
    }
  }
};

import React, {
  Dispatch,
  SetStateAction,
  useRef,
  RefObject,
  InputHTMLAttributes,
  memo,
  Fragment,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Upload } from 'antd';
import { base64StringToBlob } from 'blob-util';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { NotificationContext } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { InputUploadFile } from './contact-interaction-input-area-attachment-options';

const StyledUpload = styled(Upload)`
  display: none;
`;

const GhostInput = styled.input`
  &.ghost-input {
    display: none;
  }
`;

type AttachmentGhostInputProps = InputHTMLAttributes<HTMLInputElement> & {
  ref: RefObject<HTMLInputElement>;
};

const AttachmentGhostInput = React.forwardRef<
  HTMLInputElement,
  AttachmentGhostInputProps
>(({ className = '', ...props }, ref) => (
  <GhostInput {...props} ref={ref} className={`ghost-input ${className}`} />
));

type ContactInteractionFileUploadInputProps = {
  inputRef: RefObject<HTMLInputElement>;
  setAttachmentList: Dispatch<SetStateAction<InputUploadFile[]>>;
};

export const ContactInteractionFileUploadInput =
  memo<ContactInteractionFileUploadInputProps>(({ inputRef, setAttachmentList }) => {
    return (
      <StyledUpload
        openFileDialogOnClick={true}
        itemRender={() => null}
        iconRender={() => null}
        data-lgg-id="contact-interaction-file-upload-input"
        multiple
        children={<AttachmentGhostInput ref={inputRef} />}
        onChange={(e) => {
          const uploadFile = e.file.originFileObj;

          // This gets fire multiple times per each file upload, so we make sure to only add the file
          // to s3 once
          if (uploadFile && e.file.percent === 0) {
            setAttachmentList((list) => {
              return [
                ...list,
                {
                  file: uploadFile,
                  id: uploadFile.uid,
                  url: null,
                  attachmentType: null,
                  status: 'VALIDATING',
                  isRecentFile: false,
                },
              ];
            });
          }
        }}
      />
    );
  });

const SettingsTextButton = styled.span`
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.38;
  text-align: left;
`;

type ContactInteractionCameraUploadInputProps = {
  onChangeHandler: (file: File) => void;
};

export const ContactInteractionCameraUploadInput =
  memo<ContactInteractionCameraUploadInputProps>(({ onChangeHandler }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { openNotification } = useContext(NotificationContext);
    const { t } = useTranslation(['common']);
    const { triggerError } = useErrorHandling();

    const cameraButton = useMemo(() => {
      return match(Capacitor.isNativePlatform())
        .with(true, () => {
          const takePicture = async () => {
            const permissionState = (await Camera.requestPermissions()).camera;

            if (permissionState !== 'granted') {
              openNotification({
                title: t('common:cameraPermissionToast.title'),
                message: t('common:cameraPermissionToast.description'),
                type: 'warning',
                actionElements: (
                  <SettingsTextButton
                    onClick={() => {
                      void NativeSettings.open({
                        optionAndroid: AndroidSettings.ApplicationDetails,
                        optionIOS: IOSSettings.App,
                      });
                    }}
                  >
                    {t('common:cameraPermissionToast.action')}
                  </SettingsTextButton>
                ),
              });

              return;
            }

            let image: Photo | Error;

            try {
              image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Camera,
              });
            } catch (e) {
              image = e;
            }

            match(image)
              .with(P.instanceOf(Error), () => {
                // Camera app cancelled without taking photo, can be ignored
              })
              .otherwise((image) => {
                if (!image.base64String) {
                  triggerError(
                    new Error(`Could not get image representation from camera, ${image}`),
                  );
                  return;
                }

                const blob = base64StringToBlob(
                  image.base64String,
                  `image/${image.format}`,
                );

                const now = Date.now();
                const file = new File(
                  [blob],
                  `camera-file-${now.toString()}.${image.format}`,
                  {
                    lastModified: Date.now(),
                    type: blob.type,
                  },
                );

                onChangeHandler(file);
              });
          };

          return (
            <>
              <Icon type="camera" onClick={takePicture} />
            </>
          );
        })
        .otherwise(() => (
          <Fragment>
            <AttachmentGhostInput
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              ref={inputRef}
              onChange={(e) => {
                const file = e.target.files?.[0];

                if (file) {
                  onChangeHandler(file);
                }
              }}
            />
            <Icon type="camera" onClick={() => inputRef.current?.click()} />
          </Fragment>
        ));
    }, [openNotification, t, onChangeHandler, triggerError]);

    return <FlexColumn>{cameraButton}</FlexColumn>;
  });

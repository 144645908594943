import { Injectable, StatefulService } from 'react-service-locator';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEY_IS_SIDEBAR_FIXED,
  setLocalStorageItem,
} from 'src/utils/local-storage';

@Injectable()
export class SidebarService extends StatefulService<{
  isCollapsed: boolean;
  isFixed: boolean;
}> {
  constructor() {
    const isSidebarFixed =
      getLocalStorageItem(LOCAL_STORAGE_KEY_IS_SIDEBAR_FIXED) === 'true';

    const isSidebarCollapsed = !isSidebarFixed;

    super({ isCollapsed: isSidebarCollapsed, isFixed: isSidebarFixed });
  }

  toggleSidebar = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
      isFixed: false,
    });
  };

  openSidebar = () => {
    this.setState({
      isCollapsed: false,
    });
  };

  fixSideBar = () => {
    setLocalStorageItem(LOCAL_STORAGE_KEY_IS_SIDEBAR_FIXED, true);

    this.setState({
      isCollapsed: false,
      isFixed: true,
    });
  };

  closeSidebar = () => {
    setLocalStorageItem(LOCAL_STORAGE_KEY_IS_SIDEBAR_FIXED, false);

    this.setState({
      isCollapsed: true,
      isFixed: false,
    });
  };
}

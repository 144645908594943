import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactTimelineUnion,
  Contact,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useContactTimelineDetails } from 'src/components/domain/contacts/hooks/use-contact-timeline-details';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';

const StyledContactTimelineItem = styled(FlexRow)`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  justify-content: flex-start;
  line-height: 15px;
  padding: 12px 22px 12px 20px;

  a,
  span.link {
    color: ${({ theme }) => theme.colors.gogo};
    cursor: pointer;
    display: inline;
    font-family: ${({ theme }) => theme.font.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.smalt};
  }

  ${up('md')} {
    font-size: 14px;
    line-height: 17px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const StyledIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  margin-right: 15px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme, color }) => theme.colors[color]};
    }
  }
`;

const CreationDate = styled.span`
  && {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 14px;
    margin-top: 3px;
    text-align: left;

    ${up('md')} {
      margin-left: 20px;
      white-space: nowrap;
    }
  }
`;

const ContentContainer = styled(FlexColumn)`
  flex: 1;

  ${up('md')} {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

type ContactModalTimelineItemProps = {
  contactTimeline: ContactTimelineUnion;
  contact: Contact;
};

export const ContactModalTimelineItem = memo<ContactModalTimelineItemProps>(
  ({ contactTimeline, contact }) => {
    const { iconProps, text, formattedDueDate } = useContactTimelineDetails(
      contactTimeline,
      contact,
    );

    return (
      <StyledContactTimelineItem data-lgg-id="contact-modal-timeline-item">
        {iconProps && <StyledIcon {...iconProps} data-lgg-id="icon" />}
        <ContentContainer>
          <TextWrapper data-lgg-id="text">{text}</TextWrapper>
          <CreationDate data-lgg-id="creation-date">{formattedDueDate}</CreationDate>
        </ContentContainer>
      </StyledContactTimelineItem>
    );
  },
);

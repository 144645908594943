import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { LexicalEditor as LexicalEditorType } from 'lexical';
import {
  Mutation,
  ContactInteractionChannelResourceUnion,
  ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability,
  MutationConversationSendWhatsappMessageArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';
import { WhatsappMessageClosedWindowContent } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp';
import {
  AddWhatsappTemplateOption,
  SendTemplateOptionIcon,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { CustomEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/custom-editor';
import {
  CustomEditorProps,
  mapChannelResourcesToOptions,
  useHandleReplyError,
  useMapContactAddressesToOptions,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/helpers';
import { CONVERSATION_SEND_WHATSAPP_MESSAGE } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { ReplyWindowBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/reply-window-bar';
import { ResourceSelectorBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/resource-selector-bar';
import { ContactPhoneOption } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { AddContactInteractionModal } from 'src/components/pages/conversations/components/contact-interactions/dev-tools/add-contact-interaction-modal';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

export const WhatsappEditor = memo<CustomEditorProps>(
  ({ handleFetchNewMessageCompleted, channelAvailability, availabilityMessageRef }) => {
    const {
      messageValues,
      attachmentList,
      contactPhoneNumbers,
      conversationId,
      hasFocus,
    } = useContext(ContactInteractionInputManagerContext);

    const [selectedResource, setSelectedResource] =
      useState<ContactInteractionChannelResourceUnion | null>(null);
    const [selectedContactAddress, setSelectedContactAddress] =
      useState<ContactPhoneOption | null>(null);

    const [selectedResourceAvailability, setSelectedResourceAvailability] = useState<
      | ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability
      | undefined
    >(undefined);
    const { mapContactAddressesToOptions } = useMapContactAddressesToOptions();
    const { t } = useTranslation(['errors', 'conversations', 'common', 'smsChannel']);
    const handleReplyError = useHandleReplyError();
    const { getFeatureFlag } = useAuthorization();
    const isAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-265_como-usuario-quiero-poder-adjuntar-archivos-de-modo-que-pueda-compartir-con-mi-cliente-detalles-rele',
    );
    const isWhatsappTemplatesFeatureEnabled = getFeatureFlag(
      'temp_lggdev-1353_mostrar-un-mensaje-de-ventana-de-contacto-cerrada-al-pasar-las-24-horas-y-seleccionar-el-canal-de-wh',
    );
    const isAiToolsFeatureEnabled = getFeatureFlag('AI');
    const { parseISO, isFuture } = useDateHelpers();

    useEffect(() => {
      if (!channelAvailability) {
        return;
      }

      const { resources, lastUsedResourceId, lastInteractionContactAddress } =
        channelAvailability;

      setSelectedResourceAvailability(
        channelAvailability.resourceToContactAddressAvailability?.find(
          (resource) =>
            resource.resourceId === selectedResource?.id &&
            resource.contactAddress === selectedContactAddress?.e164,
        ),
      );

      if (!selectedResource) {
        const defaultSelectedResource =
          resources.find(({ id }) => id === lastUsedResourceId) ?? resources[0] ?? null;

        setSelectedResource(defaultSelectedResource);
      }

      if (!selectedContactAddress) {
        const defaultSelectedContactAddress: ContactPhoneOption | null =
          contactPhoneNumbers.find(
            (phone) => phone.e164 === lastInteractionContactAddress,
          ) ??
          contactPhoneNumbers[0] ??
          null;

        setSelectedContactAddress(defaultSelectedContactAddress);
      }
    }, [
      contactPhoneNumbers,
      channelAvailability,
      selectedResource?.id,
      selectedContactAddress?.e164,
      selectedResource,
      selectedContactAddress,
    ]);

    const isContactWindowOpen = useMemo(
      () =>
        !!channelAvailability?.isAvailable &&
        !!selectedResourceAvailability?.expiresAt &&
        isFuture(parseISO(selectedResourceAvailability.expiresAt)),
      [
        channelAvailability?.isAvailable,
        isFuture,
        parseISO,
        selectedResourceAvailability?.expiresAt,
      ],
    );

    const [sendWhatsappMessage, { loading: isSendingWhatsappMessage }] = useMutation<
      Pick<Mutation, 'conversationSendWhatsappMessage'>,
      MutationConversationSendWhatsappMessageArgs
    >(CONVERSATION_SEND_WHATSAPP_MESSAGE, {
      onError: handleReplyError,
    });

    const handleSendMessage = useCallback(
      async (editor: LexicalEditorType) => {
        if (
          !conversationId ||
          !selectedResourceAvailability ||
          isSendingWhatsappMessage
        ) {
          return;
        }

        let commonVariables: Pick<
          MutationConversationSendWhatsappMessageArgs,
          'conversationId' | 'message' | 'attachments'
        > = {
          conversationId,
          message: messageValues.message ?? '',
          attachments: [],
        };

        const attachments = attachmentList.map(({ url }) => ({
          s3UploadedUrl: url ?? '',
        }));

        if (isAttachmentsFeatureEnabled && attachments.length > 0) {
          commonVariables = {
            ...commonVariables,
            attachments,
          };
        }

        if (!selectedResourceAvailability) {
          return;
        }

        await sendWhatsappMessage({
          variables: {
            ...commonVariables,
            contactPhoneNumber:
              selectedContactAddress?.e164 ?? selectedResourceAvailability.contactAddress,
            resourceId: selectedResourceAvailability.resourceId,
          },
          onCompleted: async ({ conversationSendWhatsappMessage: data }) => {
            const interactionId = data.interaction?.id;

            if (!interactionId) {
              return;
            }

            await handleFetchNewMessageCompleted({
              editor,
              where: {
                contactInteractionId: {
                  eq: Number(interactionId),
                },
              },
            });
          },
        });
      },
      [
        isSendingWhatsappMessage,
        selectedResourceAvailability,
        conversationId,
        messageValues.message,
        attachmentList,
        isAttachmentsFeatureEnabled,
        sendWhatsappMessage,
        selectedContactAddress?.e164,
        handleFetchNewMessageCompleted,
      ],
    );

    const isInputEnabled = useMemo(() => isContactWindowOpen, [isContactWindowOpen]);

    const showWhatsappTemplateOption = useMemo(
      () => isWhatsappTemplatesFeatureEnabled && selectedResource,
      [isWhatsappTemplatesFeatureEnabled, selectedResource],
    );

    const getContactPhoneSelectorBar = useCallback(() => {
      return selectedContactAddress && contactPhoneNumbers.length > 0 ? (
        <ResourceSelectorBar
          label={t('common:to')}
          selectedResource={selectedContactAddress}
          options={mapContactAddressesToOptions({
            phoneOptions: contactPhoneNumbers,
            onClick: setSelectedContactAddress,
          })}
          data-lgg-id="contact-phone-selector-dropdown-button"
        />
      ) : null;
    }, [contactPhoneNumbers, mapContactAddressesToOptions, selectedContactAddress, t]);

    const getPhoneResourceSelectorBar = useCallback(
      ({ testIdPrefix }: { testIdPrefix: string }) => {
        const resources = channelAvailability?.resources ?? [];

        return selectedResource && resources.length > 0 ? (
          <ResourceSelectorBar
            label={t('common:from')}
            selectedResource={selectedResource}
            options={mapChannelResourcesToOptions({
              resources,
              testIdGenerator: (id) => `${testIdPrefix}-option-${id}`,
              onClick: setSelectedResource,
            })}
            data-lgg-id={`${testIdPrefix}-selector-dropdown-button`}
          />
        ) : null;
      },
      [channelAvailability?.resources, selectedResource, t],
    );

    const selectedResourcePhoneNumber = useMemo(
      () =>
        selectedResource?.__typename === 'ContactInteractionChannelPhoneResource'
          ? selectedResource.phoneNumber
          : undefined,
      [selectedResource],
    );

    const topContent = useMemo(() => {
      if (!selectedResource || !selectedContactAddress || !conversationId) {
        return null;
      }

      const showResourceSelector = hasFocus || !isContactWindowOpen;

      return (
        <FlexColumn>
          {showResourceSelector ? (
            <>
              {getPhoneResourceSelectorBar({
                testIdPrefix: 'whatsapp-resource',
              })}
              {getContactPhoneSelectorBar()}
            </>
          ) : null}

          <WhatsappMessageClosedWindowContent
            handleFetchCreatedItem={async (where) => {
              await handleFetchNewMessageCompleted({
                where,
              });
            }}
            resourceId={selectedResource?.id}
            resourcePhoneNumber={selectedResourcePhoneNumber?.national}
            contactPhoneNumber={selectedContactAddress.e164}
            conversationId={conversationId}
            isWindowOpen={isContactWindowOpen}
          />
        </FlexColumn>
      );
    }, [
      conversationId,
      getContactPhoneSelectorBar,
      getPhoneResourceSelectorBar,
      handleFetchNewMessageCompleted,
      hasFocus,
      isContactWindowOpen,
      selectedContactAddress,
      selectedResource,
      selectedResourcePhoneNumber?.national,
    ]);

    const replyWindowBar = useMemo(() => {
      return isContactWindowOpen && availabilityMessageRef?.current
        ? createPortal(
            <ReplyWindowBar
              expirationDate={selectedResourceAvailability?.expiresAt}
              type="WHATSAPP"
            />,
            availabilityMessageRef.current,
          )
        : null;
    }, [
      availabilityMessageRef,
      isContactWindowOpen,
      selectedResourceAvailability?.expiresAt,
    ]);

    const extraOptions = useMemo(() => {
      if (!conversationId) {
        return null;
      }

      const showTemplateOption =
        showWhatsappTemplateOption && selectedResource && selectedContactAddress;

      return (
        <>
          {showTemplateOption ? (
            <AddWhatsappTemplateOption
              handleFetchCreatedItem={(where) =>
                handleFetchNewMessageCompleted({
                  where,
                })
              }
              resourceData={{
                resourceId: selectedResource.id,
                phoneNumber: selectedResourcePhoneNumber?.national,
              }}
              contactPhoneNumber={selectedContactAddress.e164}
              conversationId={conversationId}
              renderTrigger={({ visible, showModal }) => (
                <SendTemplateOptionIcon visible={visible} showModal={showModal} />
              )}
            />
          ) : null}
          {getFeatureFlag('testing_toolkit') ? (
            <AddContactInteractionModal
              channelAvailability={channelAvailability}
              conversationId={conversationId}
            />
          ) : null}
        </>
      );
    }, [
      channelAvailability,
      conversationId,
      getFeatureFlag,
      handleFetchNewMessageCompleted,
      selectedContactAddress,
      selectedResource,
      selectedResourcePhoneNumber?.national,
      showWhatsappTemplateOption,
    ]);

    return (
      <>
        {replyWindowBar}
        <CustomEditor
          handleSubmission={handleSendMessage}
          isSubmitting={isSendingWhatsappMessage}
          bottomContent={{
            leading: extraOptions,
            visible: true,
          }}
          topContent={topContent}
          pluginConfig={{
            emojiPicker: true,
            messageAttachments: isAttachmentsFeatureEnabled,
            aiTools: isAiToolsFeatureEnabled,
          }}
          isInputEnabled={isInputEnabled}
        />
      </>
    );
  },
);

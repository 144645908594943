import { useServiceSelector } from 'react-service-locator';
import { SessionService } from 'src/services/session.service';

export function useCurrentUser() {
  return useServiceSelector(SessionService, (s) => s.data.user);
}

export function useCurrentUserId() {
  return useCurrentUser().id;
}

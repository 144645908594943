import React, { memo, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import c from 'classnames';
import _, { debounce } from 'lodash';
import { down, up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { ReactComponent as LogoIsoType } from 'src/assets/isotype.svg';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { InstitutionChooser } from 'src/components/layout/authenticated-layout/sidebar/institution-chooser/institution-chooser';
import { Menu } from 'src/components/layout/authenticated-layout/sidebar/menu/menu';
import { StyledCollapseButton } from 'src/components/layout/authenticated-layout/sidebar/styled-collapse-button';
import { StyledSider } from 'src/components/layout/authenticated-layout/sidebar/styled-sider';
import { UserControls } from 'src/components/layout/authenticated-layout/sidebar/user-controls';
import { SafeAreaTop } from 'src/components/providers/safe-area-insets-provider';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCalculatedUISizes } from 'src/hooks/use-calculated-ui-sizes';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useInstitutionPathPrefix } from 'src/hooks/use-institution-path-prefix';
import { useSidebar } from 'src/hooks/use-sidebar';
import { slideLeftAnimation } from 'src/theme/animations';

type UseDebouncedChangeHoverStateHandlerProps = {
  openSidebarHandler: VoidFunction;
  closeSidebarHandler: VoidFunction;
};

// Used to avoid excessive calls and conflicts when un-pining the sidebar while hovering
const useDebouncedChangeHoverStateHandler = ({
  openSidebarHandler,
  closeSidebarHandler,
}: UseDebouncedChangeHoverStateHandlerProps) => {
  const debouncedChangeHoverStateHandler = useMemo(
    () =>
      debounce((mouseIn: boolean) => {
        if (mouseIn) {
          openSidebarHandler();
        } else {
          closeSidebarHandler();
        }
      }, 100),
    [closeSidebarHandler, openSidebarHandler],
  );

  useEffect(
    () => () => {
      debouncedChangeHoverStateHandler.cancel();
    },
    [debouncedChangeHoverStateHandler],
  );

  return debouncedChangeHoverStateHandler;
};

const SideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gogo};
`;

export const Sidebar = memo(() => {
  const institution = useCurrentInstitution();
  const { prefix: institutionUrl } = useInstitutionPathPrefix(institution);

  const {
    isSidebarCollapsed,
    toggleSidebar,
    closeSidebar,
    isSidebarFixed,
    fixSideBar,
    openSidebar,
  } = useSidebar();
  const { hasPermission } = useAuthorization();
  const breakpointUpMd = useBreakpoint(up('md'));
  const breakpointUpXl = useBreakpoint(up('xl'));
  const sideBarIsFloating = breakpointUpMd && !isSidebarFixed;
  const { sidebarCollapsedSize, sidebarSize } = useCalculatedUISizes();
  const onMenuItemClicked = useCallback(() => {
    // We click the `body` to make sure legacy dropdowns are closed when navigating
    document.querySelector('body')?.click();

    if (!breakpointUpMd) {
      closeSidebar();
    }
  }, [breakpointUpMd, closeSidebar]);

  const { pathname } = useLocation();

  const menuKeys = useMemo(() => {
    const possibleMenuKeys: string[] = [];
    const stack: string[] = [];
    for (const pathnamePart of pathname.split('/')) {
      stack.push(pathnamePart);
      possibleMenuKeys.push(stack.join('/') + '/');
    }
    return possibleMenuKeys;
  }, [pathname]);

  const latestOpenKeys = useRef<string[]>(menuKeys);
  const [openKeys, setOpenKeys] = useState<string[]>(isSidebarCollapsed ? [] : menuKeys);

  useEffect(() => {
    if (!isSidebarCollapsed && !breakpointUpMd) {
      //We need this to prevent rc-menu from showing the submenu popup animation for 1 second
      const requestId = window.requestAnimationFrame(() => {
        setOpenKeys(latestOpenKeys.current);
      });

      //Cancel async task when the component is unmounted
      return () => window.cancelAnimationFrame(requestId);
    }
  }, [isSidebarCollapsed, breakpointUpMd]);

  const scrollBarRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTop = 0;
    }
  }, [isSidebarCollapsed]);

  const handleHoverStateChanged = useDebouncedChangeHoverStateHandler({
    openSidebarHandler: openSidebar,
    closeSidebarHandler: closeSidebar,
  });

  const sidebarContainerRef = useRef<HTMLDivElement | null>(null);
  const removeFloatingClass = useCallback(() => {
    if (sidebarContainerRef.current) {
      sidebarContainerRef.current?.classList.remove('sidebar-floating');
    }
  }, []);

  const sidebarAnimationTimoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  return (
    <SideBarContainer ref={sidebarContainerRef}>
      {sideBarIsFloating && <div style={{ width: '70px' }} />}
      <OpenSidebarButton
        visible={isSidebarCollapsed}
        onClick={() => {
          if (breakpointUpXl) {
            fixSideBar();
          } else {
            openSidebar();
          }
        }}
      >
        <Icon type="arrowright" />
      </OpenSidebarButton>
      <StyledSider
        width={isSidebarCollapsed ? sidebarCollapsedSize.width : sidebarSize.width}
        collapsedWidth={breakpointUpMd ? sidebarCollapsedSize.width : 0}
        collapsed={isSidebarCollapsed}
        onCollapse={toggleSidebar}
        trigger={null}
        collapsible
        data-lgg-id="lgg-sidebar"
        className={c({
          'fixed-sidebar': isSidebarFixed,
          'sidebar-collapsed': isSidebarCollapsed,
        })}
        onMouseEnter={() => {
          if (breakpointUpXl && isSidebarCollapsed) {
            if (sidebarAnimationTimoutId.current) {
              clearTimeout(sidebarAnimationTimoutId.current);
            }

            handleHoverStateChanged(true);

            if (sidebarContainerRef.current) {
              sidebarContainerRef.current?.classList.add('sidebar-floating');
            }
          }
        }}
        onMouseLeave={() => {
          if (breakpointUpXl && !isSidebarFixed) {
            handleHoverStateChanged(false);
            sidebarAnimationTimoutId.current = setTimeout(removeFloatingClass, 500);
          }
        }}
      >
        {breakpointUpMd ? (
          <SidebarHeader className="sidebar-header">
            <LogoLink
              to={institutionUrl}
              className={c({
                'sidebar-header-logo': true,
                collapsed: isSidebarCollapsed,
              })}
            >
              <LogoIsoType className="collapsed-logo" />
              <Logo className="open-logo" />
            </LogoLink>
            {!isSidebarCollapsed && (
              <StyledCollapseButton
                className={c({
                  'fixed-sidebar': isSidebarFixed,
                })}
                onClick={() => {
                  if (isSidebarFixed || !breakpointUpXl) {
                    closeSidebar();
                  } else {
                    fixSideBar();
                    removeFloatingClass();
                  }
                }}
              >
                {isSidebarFixed || !breakpointUpXl ? (
                  <CloseSidebarIcon
                    type="closesidebar"
                    lggTestId="close-sidebar-option"
                  />
                ) : (
                  <PinSidebarIcon type="pin" lggTestId="pin-sidebar-option" />
                )}
              </StyledCollapseButton>
            )}
          </SidebarHeader>
        ) : (
          <>
            <SafeAreaTop />
            <UserControls />
          </>
        )}
        {institution.type === 'Company' && hasPermission('organization:access') && (
          <InstitutionChooser />
        )}
        <MenuContainer containerRef={(ref) => (scrollBarRef.current = ref)}>
          <Menu
            mode="inline"
            type={institution.type}
            openKeys={openKeys}
            defaultSelectedKeys={menuKeys}
            selectedKeys={menuKeys}
            className="sidebar-menu"
            onClick={onMenuItemClicked}
            onOpenChange={(currentOpenKeys): void => {
              const openKeys = _.difference(currentOpenKeys, latestOpenKeys.current);

              if (!isSidebarCollapsed) {
                latestOpenKeys.current = openKeys as string[];
              }

              setOpenKeys(openKeys as string[]);
            }}
          />
        </MenuContainer>
      </StyledSider>
      <GlobalStyle />
    </SideBarContainer>
  );
});

const OpenSidebarButton = styled.button<{ visible: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGrey5};
  border-radius: 0 4px 4px 0;
  border: none;
  color: ${({ theme }) => theme.colors.lightBlue064};
  display: none;
  height: 40px;
  justify-content: center;
  left: 70px;
  line-height: 13px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 0;
  position: absolute;
  top: 19px;
  transition: opacity 300ms;
  width: 15px;
  // This z-index value should be less than sidebar's z-index.
  z-index: 949;

  &:focus {
    outline: none;
  }

  path {
    fill: ${({ theme }) => theme.colors.lightBlue064};
  }

  ${up('md')} {
    display: flex;
  }
`;

const PinSidebarIcon = styled(Icon)`
  padding: 6px;
  border: solid 1px rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  position: relative;
  top: -5px;
  right: 8px;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: rgba(255, 255, 255, 0.71);
    }
  }
`;

const LogoLink = styled(NavLink)`
  height: 29px;
  margin-top: 2px;
  position: relative;

  .open-logo,
  .collapsed-logo {
    position: absolute;
    transition: all 0.3s ease;
  }

  .open-logo {
    opacity: 1;
    left: 0;
    pointer-events: all;
  }

  .collapsed-logo {
    opacity: 0;
    left: 20px;
    pointer-events: none;
  }

  &.collapsed {
    .open-logo {
      opacity: 0;
      left: -20px;
      pointer-events: all;
    }

    .collapsed-logo {
      opacity: 1;
      left: 0;
      pointer-events: none;
    }
  }
`;

const SidebarHeader = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.02) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  height: 82px;
  justify-content: space-between;
  min-height: 82px;
  padding-left: 15px;
  padding-right: 12.71px;
`;

const MenuContainer = styled(Scrollbar)`
  flex-grow: 1;
`;

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  ${down('sm')} {
    .ant-menu-submenu-popup .ant-menu-sub {
      display: none;
    }
  }

  .ant-menu-submenu-popup .ant-menu-sub {
    padding: 0 5px;
    box-shadow: rgb(91 101 112 / 10%) 0 20px 40px;
    border: 1px solid rgba(152, 169, 188, 0.18);
    border-radius: 4px;
  }
`;

const CloseSidebarIcon = styled(Icon)`
  ${slideLeftAnimation}
`;

export const colorPalette = {
  // TODO: Remove deprecated color names
  globalBlue: '#2D98DA',
  accentBlue: '#75CFEC',
  accentBlue030: '#75cfec4d',
  lightAccentBlue: '#83d4ee',
  darkAccentBlue: '#6fc4e0',
  accentGreen: '#36C99A',
  lightAccentGreen: '#4bcfa4',
  darkAccentGreen: '#33bf92',
  darkCarbon: '#304457',
  carbonBlue: '#57728A',
  lightBlue: '#98A9BC',
  lightBlue025: '#98a9bc40',
  lightBlue040: '#98a9bc66',
  lightBlue050: '#98a9bc80',
  lightBlue064: '#98a9bca3',
  lightBlue070: '#98a9bce6',
  darkGrey: '#727D8E',
  lightGrey1: '#E9EEF2',
  lightGrey2: '#F3F5F7',
  lightGrey3: '#727d8eb3',
  lightGrey4: '#eef2f5',
  lightGrey5: '#ebeef2',
  lightGrey6: '#4b6882',
  lightGrey6050: '#4b688280',
  complementaryColor1: '#FFB900',
  complementaryDarkColor1: '#CC9600',
  complementaryColor2: '#1AAFD0',
  complementaryDarkColor2: '#13859E',
  complementaryColor3: '#2ED47A',
  complementaryDarkColor3: '#23A15C',
  complementaryColor4: '#6A67CE',
  complementaryDarkColor4: '#504E9C',
  complementaryColor5: '#FE4D97',
  complementaryDarkColor5: '#CC3D79',
  complementaryColor6: '#FC636B',
  complementaryColor7: '#fdb0b4',
  complementaryColor8: '#7a8896',
  halfWhite: 'rgba(255, 255, 255, 0.5)',
  black: '#000000',
  customFacebook: '#458afa',
  flower20: '#fedbea',
  flowerDark: '#cc3d79',
  gold40: '#ffe399',
  gold60: '#ffd566',
  gold: '#ffb900',
  mint20: '#d5f6e4',
  mint40: '#abedc9',
  mint: '#2ed47a',
  periWinkle20: '#e1e0f5',
  periWinkleDark: '#504e9c',
  topaz10: '#eff9fb',
  topaz20: '#d1eff5',
  topaz40: '#a3dfec',
  topaz60: '#75cfe2',
  topaz: '#1aafd0',
  topazDark: '#13859e',
  // New color palette
  monk: '#75cfec',
  gogo: '#2d98da',
  cosmo: '#36c99a',
  carbon: '#304457',
  smalt: '#57728a',
  steel: '#788ea1',
  storm: '#727d8e',
  raven: '#7a8896',
  flint: '#98a9bc',
  geyser: '#b6c2d0',
  casper: '#cbd4dd',
  koala: '#e9eef2',
  porcelain: '#f3f5f7',
  porcelain2: '#edf0f2',
  alabaster: '#f9fafb',
  white: '#ffffff',
  secondaryGold: '#ffb900',
  secondaryGoldDark: '#cc9600',
  secondaryGold80: '#ffc733',
  secondaryGold60: '#ffd566',
  secondaryGold40: '#ffe399',
  secondaryGold30: '#ffe9b1',
  secondaryGold20: '#fff1cc',
  secondaryGold10: '#fff7e1',
  secondaryTopaz: '#1aafd0',
  secondaryTopazDark: '#13859e',
  secondaryTopaz80: '#47bfd9',
  secondaryTopaz60: '#75cfe2',
  secondaryTopaz40: '#a3dfec',
  secondaryTopaz30: '#b9e7f1',
  secondaryTopaz20: '#d1eff5',
  secondaryTopaz10: '#eff9fb',
  secondaryMint: '#2ed47a',
  secondaryMintDark: '#23a15c',
  secondaryMint80: '#57dc94',
  secondaryMint60: '#81e5af',
  secondaryMint40: '#abedc9',
  secondaryMint30: '#c0f2d7',
  secondaryMint20: '#d5f6e4',
  secondaryMint10: '#f0fbf5',
  secondaryPeriwinkle: '#6a67ce',
  secondaryPeriwinkleDark: '#504e9c',
  secondaryPeriwinkle80: '#8785d7',
  secondaryPeriwinkle60: '#a5a3e1',
  secondaryPeriwinkle40: '#c3c2eb',
  secondaryPeriwinkle30: '#d2d1f0',
  secondaryPeriwinkle20: '#e1e0f5',
  secondaryPeriwinkle10: '#f4f4fb',
  secondaryFlower: '#fe4d97',
  secondaryFlowerDark: '#cc3d79',
  secondaryFlower80: '#fe70ab',
  secondaryFlower60: '#fe94c0',
  secondaryFlower40: '#feb7d5',
  secondaryFlower30: '#ffc9e0',
  secondaryFlower20: '#fedbea',
  secondaryFlower10: '#fef2f7',
  secondaryCoral: '#fc636b',
  secondaryCoralDark: '#c14c52',
  secondaryCoral80: '#fc8288',
  secondaryCoral60: '#fda1a6',
  secondaryCoral40: '#fdc0c3',
  secondaryCoral30: '#fed0d2',
  secondaryCoral20: '#fedfe1',
  secondaryCoral10: '#fef3f4',
  mediumRedViolet: '#c13584',
};

export type ColorPaletteItem = keyof typeof colorPalette;

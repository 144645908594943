export const LOCAL_STORAGE_KEY_IS_SIDEBAR_FIXED = 'sidebar-fixed';
export const LOCAL_STORAGE_KEY_DEFAULT_EMOJI_PICKER_SKIN_TONE = 'epr-skin-tone';
export const LOCAL_STORAGE_KEY_API_TOKEN = 'api-token';
export const LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID = 'call-contact-resource-id';

export const setLocalStorageItem = (key: string, value: string | boolean) => {
  window.localStorage.setItem(key, typeof value === 'string' ? value : value.toString());
};

export const getLocalStorageItem = (key: string): string | null => {
  return window.localStorage.getItem(key);
};

export const removeLocalStorageItem = (key: string) => {
  return window.localStorage.removeItem(key);
};

import React, { memo } from 'react';
import { useService } from 'react-service-locator';
import Col from 'antd/lib/col';
import Layout from 'antd/lib/layout';
import Row from 'antd/lib/row';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { NotificationsButton } from 'src/components/domain/notifications/notifications-button/notifications-button';
import { Avatar } from 'src/components/general/display/avatar';
import { Icon } from 'src/components/general/icon';
import {
  AddButton,
  useHasAddButtonAuthorization,
} from 'src/components/layout/authenticated-layout/header/add-new';
import { Breadcrumbs } from 'src/components/layout/authenticated-layout/header/breadcrumbs';
import { Dropdown } from 'src/components/layout/authenticated-layout/header/dropdown';
import { UserAccountMenu } from 'src/components/layout/authenticated-layout/user-account-menu';
import { VerticalDivider } from 'src/components/layout/vertical-divider';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useHeaderActions } from 'src/hooks/use-header-actions';
import { useVisible } from 'src/hooks/use-visible';
import { AuthorizationService } from 'src/services/authorization.service';
import { SessionService } from 'src/services/session.service';

export const UserName = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
`;

export const UserRole = styled.span`
  color: ${(props) => props.theme.colors.base.lightBlueGrey};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  line-height: 14px;
`;

export const DropDownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.lightGrey6050};
`;

const Header = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: rgb(152 169 188 / 30%) 0px 0px 5px 0px;
  z-index: 1;
  height: 80px;
  padding: 0 20px 0 28px;
  position: relative;

  ${up('lg')} {
    padding-right: 20px;
  }
`;

const DesktopBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 0;
  padding-left: 0;
`;

export const HeaderDesktop = memo<WithClassname>(({ className }) => {
  const breakpointUpLg = useBreakpoint(up('lg'));
  const {
    data: {
      user: { firstName, lastName },
    },
  } = useService(SessionService, (s) => [s.data.user.email]);
  const {
    state: { role },
  } = useService(AuthorizationService, (s) => [s.state.role]);
  const hasAddButtonAuthorization = useHasAddButtonAuthorization();
  const { getFeatureFlag } = useAuthorization();
  const extraActions = useHeaderActions();
  const displayAddButton = extraActions.length || hasAddButtonAuthorization;
  const userFullName = `${firstName} ${lastName}`;
  const visibilityHandler = useVisible();

  return (
    <Header>
      <Row style={{ height: '80px' }} align="middle">
        <Col>
          <DesktopBreadcrumbs data-lgg-id="page-breadcrumb" />
        </Col>
        <Col>
          <div id="header-extra-content" />
        </Col>
        <Col flex="auto" />
        {displayAddButton && (
          <Col>
            <AddButton />
          </Col>
        )}
        {getFeatureFlag('ux-redesign-v2.5') && <NotificationsButton />}
        <VerticalDivider />
        <Col data-lgg-id="user-account-menu" style={{ cursor: 'pointer' }}>
          <Dropdown
            overlay={<UserAccountMenu onMenuItemClick={visibilityHandler.close} />}
            onVisibleChange={visibilityHandler.setVisible}
            visible={visibilityHandler.visible}
          >
            <Row align="middle">
              <Col>
                <Avatar icon={<Icon type="userAvatar" />} />
              </Col>
              {breakpointUpLg && (
                <>
                  <Col style={{ marginLeft: '14px' }}>
                    <Row>
                      <UserName>{userFullName}</UserName>
                    </Row>
                    <Row>
                      <UserRole>{role.name}</UserRole>
                    </Row>
                  </Col>
                  <Col style={{ display: 'inline-flex', marginLeft: '20px' }}>
                    <DropDownIcon type="dropdown" />
                  </Col>
                </>
              )}
            </Row>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
});

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  EditIcon,
  EmptyValue,
  FlexColumn,
  IconCircle,
  Label,
  Row,
  StyledIcon,
  Value,
} from 'src/components/domain/contacts/contact-details/shared';
import { Center } from 'src/components/layout/center';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useUrls } from 'src/hooks/use-urls';

type ContactRowAssigneeProps = {
  assignee: Contact['assignee'];
  contactId: Contact['id'];
};

export const ContactRowAssignee = memo<ContactRowAssigneeProps>(
  ({ assignee, contactId }) => {
    const { t } = useTranslation(['contacts']);
    const { hasPermission } = useAuthorization();
    const { getLegacyContactAssigneeModalUrl } = useUrls();
    const openAssigneeModal = () => {
      openRemoteModalWrapper(getLegacyContactAssigneeModalUrl(contactId));
    };

    return (
      <Row>
        <IconCircle>
          <StyledIcon type="assignedto" />
        </IconCircle>
        <FlexColumn>
          <Label>{t('contacts:information.assignedTo')}</Label>
          {assignee ? (
            <Value data-value={assignee.fullName} data-lgg-id="contact-details-assignee">
              {assignee.fullName}
            </Value>
          ) : (
            <EmptyValue onClick={openAssigneeModal}>
              {t('contacts:information.assign')}
            </EmptyValue>
          )}
        </FlexColumn>
        {(hasPermission('lead.manage.assign_account') || assignee === null) && (
          <Center>
            <EditIcon
              type="edit"
              onClick={openAssigneeModal}
              lggTestId="assignee-legacy-modal-trigger"
            />
          </Center>
        )}
      </Row>
    );
  },
);

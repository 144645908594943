import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  TextTransformationInput,
} from '@lgg/isomorphic/types/__generated__/graphql';

const APPLY_TEXT_TRANSFORMATION_MUTATION = gql`
  mutation ApplyTextTransformation(
    $institutionId: Int!
    $formatType: TextTransformationFormatType!
    $message: String!
  ) {
    applyTextTransformation(
      input: { institutionId: $institutionId, message: $message, formatType: $formatType }
    ) {
      appliedFormat
      originalMessage
      transformedMessage
    }
  }
`;

export const useApplyAiTextTransformation = () => {
  return useMutation<Pick<Mutation, 'applyTextTransformation'>, TextTransformationInput>(
    APPLY_TEXT_TRANSFORMATION_MUTATION,
  );
};

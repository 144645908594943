import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

type Props<T extends FieldValues> = {
  defaultValues: T;
  setValue: UseFormSetValue<T>;
};

export const useSetFormFieldValue = <T extends FieldValues>({
  defaultValues,
  setValue,
}: Props<T>) => {
  return {
    setFormFieldValue: <A extends Path<T>>(fieldName: A, value: PathValue<T, A>) => {
      setValue<A>(fieldName, value ?? defaultValues[fieldName]);
    },
  };
};

import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { UnDecoratedTextInput } from 'src/components/general/inputs/text-input';

export const conversationDropdownButtonStyles = css`
  font-size: 12px;
  padding: 10px;

  [data-element='leading-icon'] {
    margin-right: 5px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const ConversationTextFilterInput = styled(UnDecoratedTextInput)`
  .lgg-icon {
    left: 15px;

    ${up('md')} {
      left: 11px;
    }
  }

  input {
    height: 32px;
    padding: 8px 8px 8px 40px;

    ${up('md')} {
      padding-left: 37px;
    }

    font-size: 13px;
    color: ${({ theme }) => theme.colors.smalt};
    line-height: 15px;
    caret-color: rgba(152, 169, 188, 0.35);

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      font-size: 14px;
      line-height: 17px;
      font-family: ${({ theme }) => theme.font.regular};
      color: ${({ theme }) => theme.colors.flint};
    }

    &,
    &:hover,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  svg path {
    fill: ${({ theme }) => theme.colors.casper};
  }
`;

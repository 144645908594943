import React, { memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useService } from 'react-service-locator';
import Modal from 'antd/lib/modal';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import { ConversationModal } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-modal';
import { ContactInteractionsSection } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-section';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHistoryChange } from 'src/hooks/use-history-change';
import { useModalUrl } from 'src/hooks/use-modal-url';
import { useVisible } from 'src/hooks/use-visible';
import { GlobalLoadingService } from 'src/services/global-loading.service';

const StyledConversationModal = styled(Modal)`
  max-width: 1038px;
  padding-bottom: 0;
  width: 70vw !important;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 6px;
    overflow: hidden;
  }

  .ant-modal-close {
    display: none;
  }
`;

export const EventTriggeredConversationModal = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { visible, close, show } = useVisible();
  const [conversationInfo, setConversationInfo] = useState<{
    conversationId: Conversation['id'];
    contactInteractionId: string | null;
  } | null>();
  const handleClose = useCallback(() => {
    setConversationInfo(null);
    close();
  }, [close]);

  useHistoryChange(handleClose);
  useEffect(() => {
    const handleShowConversation = (e) => {
      if (!e?.detail) {
        return;
      }

      show();
      setConversationInfo({
        conversationId: e.detail.conversationId,
        contactInteractionId: e.detail.contactInteractionId,
      });
    };

    window.addEventListener('showConversation', handleShowConversation);

    return () => window.removeEventListener('showConversation', handleShowConversation);
  }, [show]);

  if (!conversationInfo) {
    return null;
  }

  const { contactInteractionId, conversationId } = conversationInfo;

  return (
    <>
      {conversationId && visible && !breakpointUpMd && (
        <ConversationModal
          close={close}
          zIndex={ModalsZIndex}
          globalMode
          visible={visible}
          selectedConversationId={conversationId}
          contactInteractionId={contactInteractionId}
        />
      )}
      {breakpointUpMd && conversationId && (
        <StyledConversationModal
          visible={visible}
          onCancel={handleClose}
          transitionName="ant-fade"
          destroyOnClose
          closable={false}
          footer={null}
          centered
          maskClosable={false}
          zIndex={ModalsZIndex}
        >
          <ContactInteractionsSection
            onClose={handleClose}
            conversationId={conversationId}
            contactInteractionId={contactInteractionId}
          />
        </StyledConversationModal>
      )}
    </>
  );
});

export const ConversationUrlModal = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { id: institutionId } = useCurrentInstitution();
  const {
    setLoading,
    state: { isLoading },
  } = useService(GlobalLoadingService);
  const { conversationId, contactInteractionId } = useParams<{
    conversationId: Conversation['id'];
    contactInteractionId?: string;
  }>();
  const { goBack } = useModalUrl({
    backgroundLocation: `/i/company/${institutionId}/conversations`,
  });

  useEffect(() => {
    return () => {
      if (isLoading) {
        setLoading(false);
      }
    };
  }, [isLoading, setLoading]);

  return (
    <>
      {conversationId && !breakpointUpMd && (
        <ConversationModal
          close={goBack}
          zIndex={ModalsZIndex}
          globalMode={true}
          visible={true}
          selectedConversationId={conversationId}
          contactInteractionId={contactInteractionId}
        />
      )}
      {breakpointUpMd && conversationId && (
        <StyledConversationModal
          visible={true}
          onCancel={goBack}
          transitionName="ant-fade"
          destroyOnClose
          maskStyle={isLoading ? { backgroundColor: 'transparent' } : {}}
          closable={false}
          footer={null}
          centered
          maskClosable={false}
          zIndex={ModalsZIndex}
        >
          <ContactInteractionsSection
            onClose={goBack}
            conversationId={conversationId}
            contactInteractionId={contactInteractionId}
          />
        </StyledConversationModal>
      )}
    </>
  );
});

import React, { memo } from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { useServiceSelector } from 'react-service-locator';
import { LegacyPage } from 'src/components/pages/legacy/legacy.page';
import { useAuthorization } from 'src/hooks/use-authorization';
import { AuthorizationService } from 'src/services/authorization.service';
import { getInstitutionPathPrefix } from 'src/utils/get-institution-path-prefix';

export const OrganizationPage = memo(() => {
  const { path, url } = useRouteMatch();

  const { requirePermission } = useAuthorization();

  requirePermission('organization:access');

  const companies = useServiceSelector(
    AuthorizationService,
    (s) => s.state.concreteInstitutionSiblings,
  );

  return (
    <Switch>
      <Route
        path={path}
        exact
        render={() => {
          if (companies.length === 1) {
            return (
              <Redirect to={`${getInstitutionPathPrefix('Company', companies[0].id)}`} />
            );
          } else {
            return <Redirect to={`${url.replace(/\/+$/, '')}/companies`} />;
          }
        }}
      />
      <Route path="*">
        <LegacyPage />
      </Route>
    </Switch>
  );
});

type Dimension = {
  width: number;
  height: number;
};

const getPopupOffset = ({ width, height }: Dimension): { top: number; left: number } => {
  const wLeft = window.screenLeft || window.screenX;
  const wTop = window.screenTop || window.screenY;

  const left = wLeft + (window.innerWidth - width) / 2;
  const top = wTop + (window.innerHeight - height) / 2;

  return { top, left };
};

const getPopupDimensions = ({ width, height }: Dimension): string => {
  const { top, left } = getPopupOffset({ width, height });

  return `width=${width},height=${height},top=${top},left=${left}`;
};

export const openPopup = ({
  width,
  height,
  url,
  name,
}: Dimension & {
  url: string;
  name: string;
}): Window | null => {
  const settings =
    'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no';

  return window.open(url, name, `${settings},${getPopupDimensions({ width, height })}`);
};

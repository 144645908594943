import { useEffect, useState } from 'react';

const canceled = Symbol('canceled');

type PromiseBuilderOptions<T> = {
  promise: Promise<T> & { [canceled]?: boolean };
  waiting: () => JSX.Element;
  resolved: (result: T) => JSX.Element;
  rejected?: (error: unknown) => JSX.Element;
};

export function usePromiseBuilder<T>(options: PromiseBuilderOptions<T>): JSX.Element {
  const { promise, waiting, resolved, rejected } = options;
  const [state, setState] = useState<{
    waiting: boolean;
    result: T | null;
    error: unknown | null;
  }>({
    waiting: true,
    result: null,
    error: null,
  });
  useEffect(() => {
    setState({
      waiting: true,
      result: null,
      error: null,
    });
    promise
      .then((result) => {
        if (!promise[canceled]) {
          setState({
            waiting: false,
            result,
            error: null,
          });
        }
      })
      .catch((error) => {
        if (!promise[canceled]) {
          setState({
            waiting: false,
            result: null,
            error,
          });
        }
      });
    return () => {
      promise[canceled] = true;
    };
  }, [promise]);
  if (state.waiting) {
    return waiting();
  }
  if (state.error) {
    if (typeof rejected !== 'undefined') {
      return rejected(state.error);
    }
    throw state.error;
  }
  if (state.result) {
    return resolved(state.result);
  }
  throw new Error('Inconsistent state.');
}

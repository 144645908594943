import { Inject, Service } from 'react-service-locator';
import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { AppStatusService } from 'src/services/app-status.service';
import { requestIdentifyingHeaders } from 'src/services/http/helpers';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import { AuthorizationError } from 'src/utils/errors/authorization-error';
import { BadRequestError } from 'src/utils/errors/bad-request-error';
import { NetworkError } from 'src/utils/errors/network-error';
import { NotFoundError } from 'src/utils/errors/not-found-error';
import { ServerError } from 'src/utils/errors/server-error';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEY_API_TOKEN,
} from 'src/utils/local-storage';

export class GenericHttpError<R = unknown> extends Error {
  constructor(public statusCode: number, public response: R) {
    super();
  }
}

type RequestOptions = {
  headers?: Record<string, string>;
  signal?: AbortSignal;
  responseType?: 'blob' | 'text' | 'stream' | 'json';
};

type RequestWithBodyOptions = RequestOptions & {
  asForm?: boolean;
};

@Service()
export class GenericHttpService {
  @Inject(AppStatusService)
  private readonly appStatusService: AppStatusService;

  private axiosInstance: AxiosInstance;
  protected additionalHeaders?: Record<string, string>;

  constructor() {
    this.axiosInstance = axios.create({
      withCredentials: true,
      headers: this.additionalHeaders,
    });
  }

  protected normalizePath(path: string) {
    path = path.startsWith('/') ? path : `/${path}`;
    return path;
  }

  protected url(path: string) {
    return path;
  }

  private async request<T>(
    method: 'get' | 'post',
    path: string,
    options: {
      data?: any;
    } & RequestOptions,
  ): Promise<AxiosResponse<T>> {
    try {
      const { data, headers, signal, responseType = 'json' } = options;
      const token = getLocalStorageItem(LOCAL_STORAGE_KEY_API_TOKEN);
      const authorizationHeader: Record<string, string> = token
        ? { Authorization: `Bearer ${token}` }
        : {};

      const response = await this.axiosInstance.request<T>({
        method,
        url: this.url(this.normalizePath(path)),
        data,
        responseType,
        headers: {
          ...requestIdentifyingHeaders({
            build: this.appStatusService.currentBuild,
          }),
          ...this.additionalHeaders,
          ...headers,
          ...authorizationHeader,
        },
        signal,
      });
      return response;
    } catch (error) {
      if (error.isAxiosError) {
        const axiosError: AxiosError = error;
        const response = axiosError.response;
        const statusCode = Number(response?.status ?? 0);
        const data = response?.data ?? null;

        switch (statusCode) {
          case 0:
            throw new NetworkError(data);
          case 400:
            throw new BadRequestError(data);
          case 401:
            throw new AuthenticationError(data);
          case 403:
            throw new AuthorizationError(data);
          case 404:
            throw new NotFoundError(data);
          default: {
            if (statusCode >= 500 && statusCode <= 599) {
              throw new ServerError(data);
            }

            throw new GenericHttpError(statusCode, data);
          }
        }
      } else {
        throw error;
      }
    }
  }

  async get<R = any>(
    path: string,
    options?: RequestOptions,
  ): Promise<{ data: R; headers: Record<string, any> }> {
    const { data, headers } = await this.request<R>('get', path, {
      ...options,
    });
    return { data, headers };
  }

  async post<R = any, D extends Object = any>(
    path: string,
    data: D,
    options?: RequestWithBodyOptions,
  ): Promise<{ data: R; headers: Record<string, any> }> {
    let finalData: any;
    const { asForm, ...restOptions } = options ?? {};
    if (asForm) {
      const formData = new URLSearchParams();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      finalData = formData;
    } else {
      finalData = data;
    }
    const result = await this.request<R>('post', path, {
      ...restOptions,
      data: finalData,
    });
    return { data: result.data, headers: result.headers };
  }
}

import React, { memo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import {
  Contact,
  Query,
  QueryContactArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { PhoneIcon } from 'src/components/pages/conversations/components/contact-interactions/shared';
import {
  OpenLegacyRemoteModalLink,
  openRemoteModalWrapper,
} from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { GET_CONTACT_BLOCKED } from 'src/hooks/gql/use-refresh-contact';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';
import { publishEvent } from 'src/utils/events/pub-sub';

const StyledOpenLegacyModalLink = styled(OpenLegacyRemoteModalLink)`
  display: flex;
`;

const StyledLink = styled(Link)`
  display: flex;
`;

type OpenLegacyCallModalIconProps = {
  contact: Contact;
};

export const OpenLegacyCallModalIcon = memo<OpenLegacyCallModalIconProps>(
  ({ contact, ...rest }) => {
    const { getFeatureFlag } = useAuthorization();
    const { id: contactId } = contact;
    const { getContactCallUrl, getLegacyContactCallUrl } = useUrls();
    const hasCallContactModalRedesignFF = getFeatureFlag(
      'lggdev-1744-call-contact-modal-redesign',
    );

    const hasFFUxRedesignV2_5 = getFeatureFlag('ux-redesign-v2.5');

    if (contact.isBlocked) {
      return (
        <PhoneIcon
          onClick={() => publishEvent('SHOW_UNBLOCK_CONTACT_MODAL', { contact })}
          type="phone"
        />
      );
    }

    if (hasCallContactModalRedesignFF && hasFFUxRedesignV2_5) {
      return (
        <StyledLink {...rest} to={getContactCallUrl(contactId)}>
          <PhoneIcon type="phone" />
        </StyledLink>
      );
    }

    return (
      <StyledOpenLegacyModalLink {...rest} url={getLegacyContactCallUrl(contactId)}>
        <PhoneIcon type="phone" />
      </StyledOpenLegacyModalLink>
    );
  },
);

export const useOpenCallContactModal = () => {
  const { getFeatureFlag } = useAuthorization();
  const history = useHistory();
  const { getContactCallUrl, getLegacyContactCallUrl } = useUrls();
  const hasCallContactModalRedesignFF = getFeatureFlag(
    'lggdev-1744-call-contact-modal-redesign',
  );
  const handleGraphQLError = useHandleGraphQLError();
  const [fetchContact] = useLazyQuery<Pick<Query, 'contact'>, QueryContactArgs>(
    GET_CONTACT_BLOCKED,
    { onError: handleGraphQLError },
  );

  const hasFFUxRedesignV2_5 = getFeatureFlag('ux-redesign-v2.5');

  return async (contactId: number) => {
    const { data } = await fetchContact({
      variables: { id: contactId },
    });

    const contact = data?.contact;

    if (contact?.isBlocked) {
      publishEvent('SHOW_UNBLOCK_CONTACT_MODAL', { contact });
      return;
    }

    if (hasCallContactModalRedesignFF && hasFFUxRedesignV2_5) {
      history.push(getContactCallUrl(contactId));
    } else {
      openRemoteModalWrapper(getLegacyContactCallUrl(contactId));
    }
  };
};

import React, { memo } from 'react';
import styled from 'styled-components';

const ItemDetailDescription = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
  margin: 0;

  .title {
    color: ${({ theme }) => theme.colors.storm};
  }

  &:not(:last-child) {
    margin: 0 0 5px;
  }
`;

type ItemDescriptionProps = {
  title: string;
  description: React.ReactNode;
};

export const ItemDescription = memo<ItemDescriptionProps>(({ title, description }) => {
  return (
    <ItemDetailDescription>
      <span className="title">{`${title}: `}</span>
      {description}
    </ItemDetailDescription>
  );
});

import imageCompression from 'browser-image-compression';
import { P, match } from 'ts-pattern';

export const compressImage = async (
  originalFile: File,
  maxSizeMb?: number,
): Promise<File> => {
  const unsupportedTypes = ['image/gif'];
  const originalFileType = originalFile.type;

  if (unsupportedTypes.includes(originalFileType)) {
    return originalFile;
  }

  const outputType = match(originalFileType)
    .with(P.union('image/png', 'image/webp'), () => 'image/png')
    .otherwise(() => 'image/jpeg');

  try {
    const compressedFile = await imageCompression(originalFile, {
      maxSizeMB: maxSizeMb,
      maxWidthOrHeight: 4096,
      initialQuality: 0.8,
      fileType: outputType,
    });

    return compressedFile;
  } catch {}

  return originalFile;
};

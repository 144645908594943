import React from 'react';
import Row from 'antd/lib/row';

export function AlignMiddle(props: any) {
  return (
    <Row {...props} align="middle" justify={props.center ? 'center' : 'start'}>
      {props.children}
    </Row>
  );
}

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import styled from 'styled-components';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { NewItemBubble } from 'src/components/general/feedback/new-item-bubble';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { ConversationItem } from 'src/components/pages/conversations/components/conversations/conversation-item';
import { NoResults } from 'src/components/pages/conversations/components/conversations/no-results';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import {
  ConversationService,
  ConversationsFilters,
} from 'src/services/conversation.service';

const StyledConversationsList = styled.div<{ showBottomShadow }>`
  flex: 1;
  overflow: auto;
  position: relative;

  &::after {
    background-image: linear-gradient(
      to bottom,
      #fff0,
      ${({ theme }) => theme.colors.white} 88%
    );
    bottom: 0;
    content: '';
    height: 69px;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity 300ms;

    ${({ showBottomShadow }) => showBottomShadow && 'opacity: 1'};
  }
`;

const ShadowPlaceholder = styled.div`
  height: 69px;
`;

const ConversationListSkeletonContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding: 20px 30px;
  width: 100%;
`;

const ConversationListItemSkeleton = styled(Skeleton)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .ant-skeleton-header {
    padding-right: 21px;
  }

  .ant-skeleton-avatar {
    width: 30px;
    height: 30px;
  }

  .ant-skeleton-paragraph {
    display: none;
  }
`;

const ConversationListSkeleton = () => (
  <ConversationListSkeletonContainer>
    {[...Array(5)].map((_, index) => (
      <ConversationListItemSkeleton
        key={index}
        loading={true}
        title={{ width: '100%' }}
        active
        avatar
        paragraph={{ rows: 0 }}
        round
      />
    ))}
  </ConversationListSkeletonContainer>
);

type ConversationsListProps = {
  conversations: Conversation[];
  filters: ConversationsFilters;
  loading: boolean;
  loadingMore: boolean;
  firstItemIndex: number;
  onLoadMore: VoidFunction;
  hasNewConversations: boolean;
  selectConversation: ConversationService['selectConversation'];
  selectedConversationId: string | null;
  onNewConversationsBubbleClick: VoidFunction;
  bulkSelectionEnabled: boolean;
  bulkSelectionMap: Map<string, boolean>;
  toggleBulkSelectionItemId: (id: string) => void;
  bulkSelectAllEnabled: boolean;
};

const List = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <div {...props} ref={ref} data-lgg-id="conversation-list-virtual-scroller" />;
});

export const ConversationsList = memo<ConversationsListProps>(
  ({
    conversations,
    filters,
    loading,
    loadingMore,
    onLoadMore,
    selectedConversationId,
    firstItemIndex,
    bulkSelectionEnabled,
    bulkSelectionMap,
    toggleBulkSelectionItemId,
    bulkSelectAllEnabled,
    hasNewConversations,
  }) => {
    const bulkActionSelectionLimit = 200;
    const displayList = conversations.length > 0;
    const institutionUrl = useInstitutionUrl();
    const { t } = useTranslation(['conversations']);
    const history = useHistory();
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const [showNewNotification, setShowNewNotification] = useState(false);
    const firstItemIndexRef = useRef(firstItemIndex);
    const virtuosoScrollerRef = useRef<HTMLElement | Window | null>();
    const showNotification = useShowNotification();
    const handleNewConversationBubbleOnClick = useCallback(() => {
      setShowNewNotification(false);
      const virtuosoHandle = virtuosoRef.current;

      if (virtuosoHandle) {
        virtuosoHandle.scrollToIndex(0);
      }
    }, [setShowNewNotification]);

    useEffect(() => {
      if (!hasNewConversations || showNewNotification) {
        return;
      }

      const scrollerElement = virtuosoScrollerRef.current as HTMLDivElement;

      setTimeout(() => {
        const scrollerIsScrolled =
          firstItemIndex !== firstItemIndexRef.current && scrollerElement.scrollTop >= 1;

        if (scrollerIsScrolled) {
          setShowNewNotification(true);
        }
      }, 500);
    }, [firstItemIndex, hasNewConversations, showNewNotification]);

    return (
      <StyledConversationsList
        data-lgg-id="conversations-list"
        showBottomShadow={!loadingMore}
      >
        {loading ? (
          <ConversationListSkeleton />
        ) : displayList ? (
          <>
            <Virtuoso
              scrollerRef={(ref) => (virtuosoScrollerRef.current = ref)}
              data={conversations}
              ref={virtuosoRef}
              atTopStateChange={(atTop) => {
                if (atTop && showNewNotification) {
                  setShowNewNotification(false);
                }
              }}
              endReached={onLoadMore}
              initialItemCount={1}
              firstItemIndex={firstItemIndex}
              initialTopMostItemIndex={0}
              components={{
                List,
                Footer: () => {
                  if (loadingMore) {
                    return (
                      <ListLoadingItem
                        data-lgg-id="conversations-list-loading-more"
                        visible={loadingMore}
                      />
                    );
                  }

                  return <ShadowPlaceholder />;
                },
              }}
              itemContent={(index, conversation) => {
                const isConversationItemChecked =
                  bulkSelectAllEnabled || bulkSelectionMap.has(conversation.id);

                const handleToggleItem = () => {
                  if (
                    bulkSelectionMap.size + (isConversationItemChecked ? -1 : 1) <=
                    bulkActionSelectionLimit
                  ) {
                    toggleBulkSelectionItemId(conversation.id);
                  } else {
                    showNotification({
                      title: t(
                        'conversations:conversationsBulkActions.maxSelectionNumberReachedMessage',
                      ),
                      type: 'info',
                    });
                  }
                };

                return (
                  <ConversationItem
                    key={conversation.id}
                    isCheckable={bulkSelectionEnabled}
                    isChecked={isConversationItemChecked}
                    onSelect={handleToggleItem}
                    disabled={bulkSelectAllEnabled}
                    conversation={conversation}
                    active={selectedConversationId === conversation.id}
                    onClick={() => {
                      if (bulkSelectionEnabled) {
                        handleToggleItem();
                      } else {
                        history.push(`${institutionUrl}conversations/${conversation.id}`);
                      }
                    }}
                  />
                );
              }}
            />
            {showNewNotification && (
              <NewItemBubble
                text={t('conversations:newItemsBubble.newConversations')}
                direction="TOP"
                onClick={handleNewConversationBubbleOnClick}
              />
            )}
          </>
        ) : (
          <NoResults filters={filters} />
        )}
      </StyledConversationsList>
    );
  },
);

import React, { memo, useCallback, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Filters } from 'src/components/filters';
import { useLegacyFiltersFromUrl } from 'src/components/pages/legacy/hooks/use-legacy-filters-from-url';

const LegacyFiltersContent = memo<{ content: HTMLDivElement }>(({ content }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const targetEl = contentRef.current;
    if (targetEl) {
      content.style.display = 'block';
      targetEl.appendChild(content);
      YAMMON.WidgetManager.reload(); //Reload yammon widgets javascripts
    }
    return () => {
      if (targetEl) {
        targetEl.removeChild(content);
      }
    };
  }, [content]);

  return <div ref={contentRef} />;
});

export const LegacyFilters = memo<{ content: HTMLDivElement }>(({ content }) => {
  const filters = useLegacyFiltersFromUrl();
  const history = useHistory();

  const onSave = useCallback(() => {
    content.getElementsByTagName('form')[0].requestSubmit();
  }, [content]);

  const onReset = useCallback(() => {
    const clearAnchor = content.getElementsByClassName('search-clear-button')[0];
    const url = clearAnchor?.getAttribute('href');

    if (url) {
      const parsedUrl = new URL(url, new URL(window.location.href).origin);
      const path = `${parsedUrl.pathname}${parsedUrl.search}`;
      history.push(path);
    }
  }, [content, history]);

  return (
    <Filters filters={filters} onSave={onSave} onReset={onReset}>
      <LegacyFiltersContent content={content} />
    </Filters>
  );
});

import { Inject, Service, StatefulService } from 'react-service-locator';
import { DocumentTitleService } from 'src/services/document-title.service';

export type BreadcrumbRoute = {
  breadcrumbName: string;
  path?: string;
  render?: () => JSX.Element;
};

@Service()
export class BreadcrumbsService extends StatefulService<{
  routes: BreadcrumbRoute[];
}> {
  @Inject(DocumentTitleService)
  private readonly documentTitleService!: DocumentTitleService;

  constructor() {
    super({ routes: [] });
  }

  public add = (route: BreadcrumbRoute) => {
    const last = this.state.routes[this.state.routes.length - 1];

    if (typeof last?.path === 'undefined' || last?.path !== route.path) {
      const routesCopy = this.state.routes.slice();
      routesCopy.push(route);
      routesCopy.sort((a, b) => a.path?.localeCompare(b.path ?? '') ?? 0);

      this.set(routesCopy);
    }
  };

  public remove = (route: BreadcrumbRoute) => {
    const { routes } = this.state;
    const index = routes.findIndex((r) => r.path === route.path);
    if (index > -1) {
      const newRoutes = routes.slice();
      newRoutes.splice(index, 1);
      this.set(newRoutes);
    }
  };

  public set = (routes: BreadcrumbRoute[]) => {
    const lastRoute = routes[routes.length - 1];
    this.documentTitleService.setTitle(lastRoute?.breadcrumbName ?? 'Leadgogo');
    this.setState({ routes });
  };

  public clear = () => {
    this.setState({ routes: [] });
  };
}

import React, { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { $getRoot, LexicalEditor as LexicalEditorType } from 'lexical';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import {
  LexicalEditor,
  LexicalEditorProps,
} from 'src/components/pages/conversations/components/general/lexical-editor/lexical-editor';
import { RESET_EDITOR_COMMAND } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/custom-clear-editor-plugin';
type CustomEditorProps = Pick<
  LexicalEditorProps,
  | 'pluginConfig'
  | 'isInputEnabled'
  | 'rightRailLeadingContent'
  | 'topContent'
  | 'bottomContent'
> & {
  handleSubmission: (editor: LexicalEditorType) => Promise<void>;
  isSubmitting: boolean;
  placeholderOverride?: string;
  forceFocus?: boolean;
};

export const CustomEditor = memo<CustomEditorProps>(
  ({
    handleSubmission,
    isSubmitting,
    bottomContent,
    topContent,
    placeholderOverride,
    rightRailLeadingContent,
    pluginConfig,
    isInputEnabled,
    forceFocus = false,
  }) => {
    const showNotification = useShowNotification();
    const { t } = useTranslation(['conversations']);
    const {
      messageValues,
      setMessageValues,
      attachmentList,
      setAttachmentList,
      hasFocus,
      setHasFocus,
      clearMessageInputStates,
      selectedInteraction,
      conversationId,
      setInputAreaMode,
      inputAreaMode,
      selectedChannelSlug,
    } = useContext(ContactInteractionInputManagerContext);

    const hasValidAttachments = useMemo(
      () =>
        attachmentList.length > 0 &&
        attachmentList.some((attachment) =>
          ['UPLOADING', 'UPLOADED'].includes(attachment.status),
        ),
      [attachmentList],
    );

    const isUploadingFiles = useMemo(
      () =>
        attachmentList.some(
          (attachment) => attachment.status !== 'ERROR' && attachment.url === null,
        ),
      [attachmentList],
    );

    const canSendMessage = useMemo(
      () =>
        !!conversationId &&
        (!!messageValues.message || hasValidAttachments || isUploadingFiles),
      [conversationId, hasValidAttachments, isUploadingFiles, messageValues.message],
    );

    return (
      <LexicalEditor
        selectedChannel={selectedChannelSlug}
        setHasFocus={setHasFocus}
        setInputAreaMode={setInputAreaMode}
        selectedInteraction={selectedInteraction}
        pluginConfig={pluginConfig}
        isInputEnabled={isInputEnabled}
        canSendMessage={canSendMessage && !isSubmitting}
        handleSendMessage={async (editor) => {
          if (isUploadingFiles) {
            showNotification({
              title: t(
                'conversations:messageInput.options.attachments.errorMessages.fileStillUploading',
              ),
              type: 'warning',
            });

            return;
          }

          await handleSubmission(editor);
        }}
        hasFocus={forceFocus || hasFocus || hasValidAttachments}
        isFullHeight={false}
        bottomContent={bottomContent}
        topContent={topContent}
        onChange={(editorState) => {
          editorState.read(() => {
            const root = $getRoot();

            setMessageValues({
              message: root.getTextContent(),
              rawMessage: JSON.stringify(editorState.toJSON()),
            });
          });
        }}
        inputAreaMode={inputAreaMode}
        resetEditorStateHandler={(editor) => {
          editor.dispatchCommand(RESET_EDITOR_COMMAND, clearMessageInputStates);
        }}
        placeholder={
          placeholderOverride ??
          t('conversations:messageInput.modes.reply.inputPlaceholder')
        }
        onFocus={() => setHasFocus(true)}
        attachmentList={attachmentList}
        setAttachmentList={setAttachmentList}
        rightRailLeadingContent={hasFocus ? rightRailLeadingContent : null}
      />
    );
  },
);

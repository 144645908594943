import { useEffect } from 'react';
import { Inject, Service, StatefulService, useService } from 'react-service-locator';
import {
  LGGFeatureFlags,
  SessionInstitutionAuthorizationDataOutputDto,
} from '@lgg/isomorphic';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { ApiService } from 'src/services/http/api.service';
import { SuspenseService } from 'src/services/suspense.service';
import { AuthorizationError } from 'src/utils/errors/authorization-error';

@Service()
export class AuthorizationService extends StatefulService<SessionInstitutionAuthorizationDataOutputDto> {
  @Inject(ApiService)
  private readonly apiService!: ApiService;

  @Inject(SuspenseService)
  private readonly suspenseService!: SuspenseService;

  constructor() {
    super(null as any);
  }

  private updateAuthorizationData = async (
    institutionId: number,
    abortSignal?: AbortSignal,
  ) => {
    const { data: authorizationData } =
      await this.apiService.get<SessionInstitutionAuthorizationDataOutputDto>(
        `/session/institution-authorization-data/${institutionId}`,
        {
          signal: abortSignal,
        },
      );
    if (!abortSignal || !abortSignal.aborted) {
      this.setState(authorizationData);
    }
  };

  suspendAndInitialize = (institutionId: number) => {
    this.suspenseService.suspendWith(async () => {
      await this.updateAuthorizationData(institutionId);
    }, [institutionId]);
  };

  startPollingForUpdates = (onError: (e: Error) => void) => {
    const {
      currentInstitution: { id: institutionId },
    } = this;
    const abortController = new AbortController();
    const stopPollingForUpdates = () => {
      abortController.abort();
    };
    void (async () => {
      while (true) {
        // delay at start
        await new Promise((resolve) => setTimeout(resolve, 60_000));

        if (abortController.signal.aborted) {
          break;
        }

        try {
          await this.updateAuthorizationData(institutionId, abortController.signal);
        } catch (e) {
          onError(e);
        }
      }
    })();
    return { stopPollingForUpdates };
  };

  get currentInstitution() {
    const { institution } = this.state;
    if (!institution) {
      throw new Error('No institution set');
    }
    return institution;
  }

  getTimezone() {
    const state = this?.state;

    if (!state?.concreteInstitution?.timezone) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return state.concreteInstitution.timezone;
  }

  hasPermission = (permission: string | string[]) => {
    const permissions = Array.isArray(permission) ? permission : [permission];
    return permissions.every((f) => this.state.permissions.includes(f));
  };

  requirePermission = (permission: string | string[]) => {
    if (!this.hasPermission(permission)) {
      throw new AuthorizationError(null);
    }
  };

  getFeatureFlag = <F extends keyof LGGFeatureFlags>(flag: F): LGGFeatureFlags[F] => {
    return this.state?.featureFlags?.[flag];
  };
}

export function useInitializeAuthorization(institutionId: number) {
  const { triggerError } = useErrorHandling();
  const authorizationService = useService(AuthorizationService);
  authorizationService.suspendAndInitialize(institutionId);
  useEffect(() => {
    const { stopPollingForUpdates } =
      authorizationService.startPollingForUpdates(triggerError);
    return stopPollingForUpdates;
  }, [authorizationService, institutionId, triggerError]);
}

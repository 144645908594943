import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ContactInteractionDirection } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const DirectionIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.koala};
  border-radius: 50%;
  height: max-content;
  outline: 1px solid ${({ theme }) => theme.colors.casper};
  padding: 3px;
  position: absolute;
  right: -3px;
  top: 0;
  width: max-content;
  display: block;

  &[data-direction='OUTBOUND'] {
    svg {
      height: 6px;
      transform: rotate(180deg);
      width: 6px;
    }
  }

  ${up('md')} {
    outline: 2px solid ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.koala};
  }
`;

const ActivityTypeIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const ActivityIconContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 36px;
  min-height: 36px;
  background-color: ${({ theme }) => theme.colors.koala};
  margin: 0 15px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.casper};
  border-radius: 50%;

  ${up('md')} {
    border: none;
    margin: 0 18px 0 0;
    background-color: ${({ theme }) => theme.colors.koala};
  }
`;

type ActivityIconWithDirectionProps = {
  icon: string;
  direction?: ContactInteractionDirection;
};

export const ActivityIconWithDirection = memo<ActivityIconWithDirectionProps>(
  ({ icon, direction }) => {
    return (
      <ActivityIconContainer>
        <ActivityTypeIcon type={icon} />
        {direction && (
          <DirectionIcon
            type="contactInteractionDirection"
            data-direction={direction}
            lggTestId="activity-icon-direction-icon"
          />
        )}
      </ActivityIconContainer>
    );
  },
);

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactPhoneType,
  PhoneNumber,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactModalZIndex } from 'src/components/constants';
import {
  useCallContactMutation,
  useGetContactForCallModal,
  useGetResourcesForCallingContact,
  useGetUserForCallModal,
} from 'src/components/domain/contacts/call-contact-modal/queries';
import { PhoneNumberSelect } from 'src/components/domain/contacts/call-contact-modal/selects/phone-number-select';
import { ContactLabel } from 'src/components/domain/contacts/contact-label';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { NotificationContext } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { Checkbox } from 'src/components/general/inputs/checkbox';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useModalUrl } from 'src/hooks/use-modal-url';
import { useUrls } from 'src/hooks/use-urls';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/local-storage';
import { useProfileSettings } from '../../users/components/profile-settings-modal/context/profile-settings-provider';
import { ResourceFromPhoneNumberSelect } from './selects/resource-from-phone-number-select';

const StyledModal = styled(LggModal)`
  margin: 0 auto;
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    max-width: unset;
    width: 100%;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    height: 100%;
  }

  ${up('md')} {
    max-width: 420px;
  }
`;

const ModalHeader = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 70px;
  padding: 0 20px;
  text-align: left;
`;

const HeaderTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  flex: 1;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.48px;
  line-height: 29px;
  margin-right: 10px;
  min-width: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseIcon = styled(Icon)`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const StyledContactLabel = styled(ContactLabel)`
  margin-bottom: 20px;

  .contact-label_text {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 2;
    text-align: left;
  }

  .contact-stage-icon_container {
    height: 30px;
    margin-right: 10px;
    width: 30px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

const CallInfoContainer = styled(FlexColumn)`
  padding: 20px 20px 23px 20px;

  ${up('md')} {
    padding-bottom: 20px;
  }
`;

const FooterMessage = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.smalt};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  padding: 20px;
  text-align: left;

  strong {
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: normal;
  }
`;

const FooterAction = styled(FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 15px 20px;
`;

const FooterActionMessage = styled.span`
  &&& {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 16px;
    padding: 0;
    text-align: left;
  }
`;

const ButtonsContainer = styled(FlexRow)`
  justify-content: flex-end;
  padding: 15px;
`;

const StyledButton = styled(ButtonV2)`
  height: 34px;

  & + & {
    margin-left: 10px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
  width: max-content;
  top: 2px;

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.casper};
  }

  &.ant-checkbox-wrapper {
    align-items: flex-start;
  }

  &.ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-wrapper
    .ant-checkbox-checked
    .ant-checkbox-input:focus
    + .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.accentGreen};
    border-color: ${({ theme }) => theme.colors.accentGreen};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 18px;
`;

const EditButton = styled.button`
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 18px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export const CallContactModal = memo(() => {
  const { id: institutionId } = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { triggerError } = useErrorHandling();
  const { getLegacyEditUserUrl } = useUrls();
  const { t } = useTranslation(['errors', 'contacts', 'common']);
  const { goBack } = useModalUrl({
    backgroundLocation: `/i/company/${institutionId}/contacts`,
  });
  const userId = useCurrentUserId();
  const [rememberCallerID, setRememberCallerID] = useState(
    getLocalStorageItem(LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID) !== null,
  );
  const [selectedFromResource, setSelectedFromResource] = useState<number | null>(null);
  const [selectedToPhoneType, setSelectedToPhoneType] = useState<ContactPhoneType | null>(
    null,
  );
  const [callContact] = useCallContactMutation();
  const { contactId } = useParams<{ contactId: string }>();
  const contactQuery = useGetContactForCallModal({
    contactId,
    goBack,
  });

  const { openNotification, closeNotification } = useContext(NotificationContext);

  const { visibilityHandler } = useProfileSettings();
  const { getFeatureFlag } = useAuthorization();
  const isProfileSettingsEnabled = getFeatureFlag(
    'temp_lggdev-1979_como-usuario-deseo-poder-configurar-las-preferencias-de-mi-cuenta-de-modo-que-pueda-gestionar-y-visu',
  );

  const userQuery = useGetUserForCallModal({
    userId,
    goBack,
  });

  const contact = contactQuery.data?.contact;
  const user = userQuery.data?.user;

  const resourcesQuery = useGetResourcesForCallingContact({
    skip: !contact?.id,
    contactId,
  });

  const resourcesData = resourcesQuery.data?.resourcesForCallingContact;

  const fromResourcesOptions = useMemo(() => {
    if (!resourcesData) {
      return [];
    }

    const resourceOptions = resourcesData.resources.map((resource) => {
      const { id, address, alias, label, phoneNumber, campaign } = resource;

      return {
        label: phoneNumber?.national ?? address,
        onClick: () => setSelectedFromResource(id),
        subTitle: alias ?? label ?? campaign.name,
        value: id.toString(),
        'data-lgg-id': `call-contact-modal-resource-option-${id}`,
      };
    });

    const rememberedCallerID = getLocalStorageItem(
      LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID,
    );

    if (!selectedFromResource) {
      const selectedId = rememberedCallerID
        ? Number(rememberedCallerID)
        : resourcesData.lastUsedResourceId
        ? resourcesData.lastUsedResourceId
        : resourcesData.defaultResourceId
        ? resourcesData.defaultResourceId
        : resourcesData.resources[0].id;

      setSelectedFromResource(selectedId);
    }

    return resourceOptions;
  }, [resourcesData, selectedFromResource]);

  const contactPhoneOptions = useMemo(() => {
    if (!contact) {
      return [];
    }

    const primaryPhone = contact.primaryPhone;

    if (!primaryPhone) {
      goBack();
      triggerError(new Error(`Primary phone not found for contact ${contact.id}`));
      return [];
    }

    if (!selectedToPhoneType) {
      setSelectedToPhoneType('PRIMARY');
    }

    const options: DrawerSelectableOption[] = [];
    const getContactPhoneOption = (
      phone: PhoneNumber,
      contactPhoneType: ContactPhoneType,
    ) => {
      return {
        label: phone.national,
        onClick: () => setSelectedToPhoneType(contactPhoneType),
        value: contactPhoneType,
        'data-lgg-id': `call-contact-modal-contact-phone-option-${contactPhoneType}`,
      };
    };

    options.push(getContactPhoneOption(primaryPhone, 'PRIMARY'));

    const secondaryPhone = contact?.secondaryPhone;

    if (secondaryPhone) {
      options.push(getContactPhoneOption(secondaryPhone, 'SECONDARY'));
    }

    return options;
  }, [contact, goBack, selectedToPhoneType, triggerError]);

  const handleCall = useCallback(
    (input: {
      fromResourceId: number;
      phoneType: ContactPhoneType;
      contactId: number;
    }) => {
      const { fromResourceId, phoneType, contactId } = input;

      goBack();

      if (rememberCallerID) {
        setLocalStorageItem(
          LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID,
          fromResourceId.toString(),
        );
      } else {
        removeLocalStorageItem(LOCAL_STORAGE_KEY_CALL_CONTACT_RESOURCE_ID);
      }

      void callContact({
        variables: {
          input: {
            contactId,
            fromResourceId: fromResourceId,
            contactPhoneType: phoneType,
          },
        },
        onError: handleGraphQLError,
      });
    },
    [callContact, goBack, handleGraphQLError, rememberCallerID],
  );

  useEffect(() => {
    if (user && !user.phone?.national) {
      const key = 'no-agent-number';

      openNotification({
        key,
        title: t('contacts:callContactModal.errors.noAgentNumber.title'),
        message: t('contacts:callContactModal.errors.noAgentNumber.message'),
        type: 'warning',
        actionElements: isProfileSettingsEnabled ? (
          <EditButton
            data-lgg-id="view-contact-notification-action"
            onClick={() => {
              visibilityHandler.show();
              closeNotification(key);
            }}
            aria-haspopup="dialog"
            aria-label="Open profile setting modal"
          >
            {t('contacts:callContactModal.errors.noAgentNumber.actionEdit')}
          </EditButton>
        ) : (
          <StyledLink
            onClick={() => closeNotification(key)}
            to={getLegacyEditUserUrl()}
            data-lgg-id="view-contact-notification-action"
          >
            {t('contacts:callContactModal.errors.noAgentNumber.actionEdit')}
          </StyledLink>
        ),
      });

      goBack();
    }
  }, [
    closeNotification,
    goBack,
    openNotification,
    t,
    user,
    getLegacyEditUserUrl,
    isProfileSettingsEnabled,
    visibilityHandler,
  ]);

  if (
    !selectedFromResource ||
    !selectedToPhoneType ||
    contactQuery.loading ||
    userQuery.loading ||
    resourcesQuery.loading
  ) {
    return <span>loading</span>;
  }

  return (
    <StyledModal
      data-lgg-id="call-contact-modal"
      visible={true}
      zIndex={ContactModalZIndex}
      closeIcon={null}
      onClose={goBack}
      width="calc(100% - 40px)"
      maskTransitionName=""
    >
      <ModalHeader>
        <HeaderTitle>{t('contacts:callContactModal.title')}</HeaderTitle>
        <CloseIcon type="close" onClick={goBack} />
      </ModalHeader>
      {contact && user?.phone?.national && (
        <>
          <CallInfoContainer>
            <StyledContactLabel contact={contact} disableInteractions={true} />
            <ResourceFromPhoneNumberSelect
              label={t('contacts:callContactModal.fromNumber.label')}
              excerpt={t('contacts:callContactModal.fromNumber.excerpt')}
              options={fromResourcesOptions}
              selectedResource={selectedFromResource.toString()}
              onChange={(selectedValue) => {
                setSelectedFromResource(Number(selectedValue));
              }}
              data-lgg-id="resource-from-phone-number-selector"
            />
            <PhoneNumberSelect
              label={t('contacts:callContactModal.toNumberLabel')}
              options={contactPhoneOptions}
              selectedResource={selectedToPhoneType}
              data-lgg-id="contact-phone-number-selector"
            />
          </CallInfoContainer>
          <FooterMessage>
            <Trans
              i18nKey="contacts:callContactModal.footerMessage"
              values={{
                agentNumber: user.phone.national,
              }}
            />
          </FooterMessage>
          <FooterAction>
            <StyledCheckbox
              data-lgg-id="remember-caller-id"
              checked={rememberCallerID}
              onChange={() => setRememberCallerID(!rememberCallerID)}
            >
              <FooterActionMessage>
                {t('contacts:callContactModal.footerAction')}
              </FooterActionMessage>
            </StyledCheckbox>
          </FooterAction>
          <ButtonsContainer>
            <StyledButton
              variant="default"
              onClick={goBack}
              size="regular"
              data-lgg-id="call-contact-modal-cancel-button"
            >
              {t('common:cancel')}
            </StyledButton>
            <StyledButton
              variant="primary"
              data-lgg-id="call-contact-modal-call-button"
              onClick={() =>
                handleCall({
                  contactId: contact.id,
                  phoneType: selectedToPhoneType,
                  fromResourceId: selectedFromResource,
                })
              }
              size="regular"
            >
              {t('contacts:contactOptions.call')}
            </StyledButton>
          </ButtonsContainer>
        </>
      )}
    </StyledModal>
  );
});

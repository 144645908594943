import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { AlignMiddle } from 'src/components/general/align-middle';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export const Header = memo<{ onReset: () => void; filters: string[] }>((props) => {
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <StyledHeader>
      <StyledAlignMiddle>
        <Icon type="filters" className="lgg-icon-left" />
        {t('common:filters.title')}{' '}
        {props.filters.length > 0 && <Badge>{props.filters.length}</Badge>}
        {breakpointUpMd && (
          <>
            <Expand />
            <ResetButton variant="tertiary" onClick={props.onReset}>
              {t('common:filters.actionReset')}
            </ResetButton>
          </>
        )}
      </StyledAlignMiddle>
    </StyledHeader>
  );
});

const StyledAlignMiddle = styled(AlignMiddle)`
  ${up('md')} {
    margin-top: 58px;
    margin-bottom: 2px;
  }
`;

const ResetButton = styled(LggButton)`
  ${up('md')} {
    width: unset;
    padding: 0 15px;
  }
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  background-color: #75cfec;
  font-family: SailecRegular;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 8px;
  border-radius: 50%;
  text-align: center;
`;

const StyledHeader = styled.div`
  color: #304457;
  font-size: 24px;

  ${down('sm')} {
    font-size: 20px;
  }

  .lgg-btn-default {
    position: absolute;
    right: 20px;
  }

  .lgg-icon {
    color: #98a9bc;
  }
`;

import React, { memo } from 'react';
import { AgencyMenu } from 'src/components/layout/authenticated-layout/sidebar/menu/agency';
import { CompanyMenu } from 'src/components/layout/authenticated-layout/sidebar/menu/company';
import { OrganizationMenu } from 'src/components/layout/authenticated-layout/sidebar/menu/organization';
import { SidebarMenuProps } from 'src/components/layout/authenticated-layout/sidebar/menu/shared';

type MenuProps = SidebarMenuProps & {
  type: string | null;
};

export const Menu = memo<MenuProps>((props) => {
  const { type, ...institutionMenuProps } = props;

  switch (type) {
    case 'Agency':
      return <AgencyMenu {...institutionMenuProps} />;
    case 'Organization':
      return <OrganizationMenu {...institutionMenuProps} />;
    case 'Company':
      return <CompanyMenu {...institutionMenuProps} />;
    default:
      return null;
  }
});

import { useLocation } from 'react-router-dom';

const institutionUrlRegex = /^(\/i\/\w*\/\d*\/?)/;

export const useInstitutionUrl = (params?: { includeOrigin: boolean }): string => {
  const { pathname } = useLocation();
  const match = pathname.match(institutionUrlRegex);

  if (!match) {
    throw new Error(`Could not get institution url from: ${pathname}`);
  }

  const firstMatch = match[0];
  const normalizedUrl = firstMatch.endsWith('/') ? firstMatch : firstMatch + '/';

  if (!params?.includeOrigin) {
    return normalizedUrl;
  } else {
    const origin = new URL(window.location.href).origin;
    return `${origin}${normalizedUrl}`;
  }
};

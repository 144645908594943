import React, { useCallback, useState, useEffect, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { up } from 'styled-breakpoints';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LggOptionsDropdownButton } from 'src/components/general/button/dropdown-button';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { LegacyApiService } from 'src/services/http/legacy-api.service';

type ExportUrlsPayload = {
  export: {
    download: string;
    share: string;
  };
};

type ExportResultsButtonProps = {
  requestUrl: string;
  params?: string;
  hideShareOption?: boolean;
  testId?: string;
};

export const ExportResultsButton = memo<ExportResultsButtonProps>(
  ({ params, requestUrl, hideShareOption = false, testId = '' }) => {
    const { t } = useTranslation(['common']);
    const legacyApiService = useService(LegacyApiService);
    const { triggerError } = useErrorHandling();
    const useBreakpointUpMd = useBreakpoint(up('md'));
    const visibilityHandler = useVisible();
    const [exportLinks, setExportLinks] = useState<{
      shareLink?: string;
      downloadLink?: string;
    } | null>(null);
    const exportRequestUrl = useMemo(
      () => `${requestUrl}${params ? `?${params}` : ''}`,
      [params, requestUrl],
    );

    useEffect(() => {
      const getExportUrls = async () => {
        setExportLinks(null);

        try {
          const {
            data: {
              export: { download = undefined, share = undefined },
            },
          } = await legacyApiService.get<ExportUrlsPayload>(exportRequestUrl, {
            headers: {
              'lgg-legacy-hybrid-data': 'true',
            },
          });

          setExportLinks({
            shareLink: share,
            downloadLink: download,
          });
        } catch (e) {
          triggerError(e);
        }
      };

      void getExportUrls();
    }, [exportRequestUrl, legacyApiService, triggerError]);

    const handleShare = useCallback(() => {
      if (exportLinks?.shareLink) openRemoteModalWrapper(exportLinks.shareLink);
    }, [exportLinks]);

    const handleDownload = useCallback(() => {
      if (exportLinks?.downloadLink) openRemoteModalWrapper(exportLinks.downloadLink);
    }, [exportLinks]);

    const options = useMemo(() => {
      const options: DrawerOption[] = [];

      if (!hideShareOption) {
        options.push({
          label: t('common:actions.share'),
          icon: 'share',
          onClick: handleShare,
          'data-lgg-id': `${testId}-option-share`,
        });
      }

      options.push({
        label: t('common:actions.download'),
        icon: 'download',
        onClick: handleDownload,
        'data-lgg-id': `${testId}-option-download`,
      });

      return options;
    }, [handleDownload, handleShare, hideShareOption, t, testId]);

    const lggTestId = `${testId}-button`;
    const loading = exportLinks === null;
    const text = t('common:actions.export');

    if (useBreakpointUpMd) {
      return (
        <LggOptionsDropdownButton
          variant="defaultWhite"
          size="regular"
          icon="exportbtn"
          loading={loading}
          visibilityHandler={visibilityHandler}
          options={options}
          data-lgg-id={lggTestId}
        >
          {text}
        </LggOptionsDropdownButton>
      );
    }

    return (
      <>
        <IconButtonV2
          variant="defaultWhite"
          size="regular"
          icon="exportbtn"
          loading={loading}
          data-lgg-id={lggTestId}
          onClick={visibilityHandler.show}
        />
        <OptionsBottomDrawer
          visible={visibilityHandler.visible}
          title={text}
          onClose={visibilityHandler.close}
          options={options}
        />
      </>
    );
  },
);

import { MutableRefObject, useLayoutEffect } from 'react';

export const useAdjustScrollOnHeightChange = (params: {
  scrollerRef: MutableRefObject<HTMLElement | Window | null>;
  isScrollingRef: MutableRefObject<boolean>;
  firstItemIndex: number;
}) => {
  const { scrollerRef, isScrollingRef, firstItemIndex } = params;
  const adjustScrollOnHeightChange = (scrollerElement: HTMLDivElement) => {
    const virtuosoListElement = scrollerElement.querySelector(
      '[data-test-id="virtuoso-item-list"]',
    );

    if (!virtuosoListElement) {
      return;
    }

    const scrollTopBeforePrepend = scrollerElement.scrollTop;
    const scrollHeightBeforePrepend = virtuosoListElement.getBoundingClientRect().height;

    // If the scroll top is "0", then scroll to "1" to prevent visual jump
    if (scrollTopBeforePrepend === 0) {
      scrollerElement.scrollTo({
        top: 1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        behavior: 'instant',
      });
    }

    const observer = new ResizeObserver((entries) => {
      const newHeight = entries[0].borderBoxSize?.[0]?.blockSize;

      if (scrollHeightBeforePrepend === newHeight) {
        return;
      }

      const adjustTop = scrollTopBeforePrepend + newHeight - scrollHeightBeforePrepend;

      if (adjustTop >= 0) {
        scrollerElement.scrollTo({
          top: adjustTop,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          behavior: 'instant',
        });

        setTimeout(() => {
          observer.unobserve(virtuosoListElement);
        }, 50);
      }
    });

    observer.observe(virtuosoListElement);
  };

  useLayoutEffect(() => {
    if (!scrollerRef.current || isScrollingRef.current) {
      return;
    }

    adjustScrollOnHeightChange(scrollerRef.current as HTMLDivElement);
  }, [firstItemIndex, isScrollingRef, scrollerRef]);
};

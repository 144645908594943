import React, { memo } from 'react';
import styled from 'styled-components';
import { ContactStage, ContactStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { showContactStatusModal } from './contact-status-modal';

const StyledContactStageIcon = styled(ContactStageIcon)`
  & {
    height: 24px;
    width: 24px;
    min-width: 24px;
  }
`;

const ContactStatusContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 36px;
  padding-left: 6px;
  padding-right: 15px;
  width: 100%;
`;

const ContactStageName = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 9px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 11px;
  text-align: left;
  text-transform: uppercase;
`;

const ContactStatusName = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 9px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 11px;
  text-align: left;
`;

const ArrowIcon = styled(Icon)`
  svg {
    height: 10px;
    width: 10px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const StatusDescriptionContainer = styled(FlexColumn)`
  width: 100%;
`;

type ContactStatusElementProps = {
  stage: ContactStage;
  status: ContactStatus;
  contactId: number;
};

export const ContactStatusElement = memo<ContactStatusElementProps>(
  ({ stage, status, contactId }) => {
    return (
      <ContactStatusContainer
        data-lgg-id="status-modal-trigger"
        onClick={() => showContactStatusModal(contactId)}
      >
        <StyledContactStageIcon slug={stage.slug} name={status.name} />
        <StatusDescriptionContainer>
          <ContactStageName data-lgg-id="contact-details-stage">
            {stage.name}
          </ContactStageName>
          <ContactStatusName data-lgg-id="contact-details-status">
            {status.name}
          </ContactStatusName>
        </StatusDescriptionContainer>
        <ArrowIcon type="arrowRightWide" />
      </ContactStatusContainer>
    );
  },
);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { MenuItem } from 'src/components/domain/users/components/profile-settings-modal/components/menu-item';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import {
  SettingsOptionsKeys,
  SettingsOptionsSection,
  useProfileSettingsOptions,
} from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings-options';
import { ButtonV2, StyledButtonV2 } from 'src/components/general/button/lgg-button';
import {
  BottomDrawer,
  StyledDrawerHeader,
} from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';

const StyledDrawer = styled(BottomDrawer)`
  .ant-drawer-header {
    border-bottom: unset;
  }

  ${StyledDrawerHeader} {
    padding: 0 12px 0 20px;
  }
`;

const DrawerContent = styled.div`
  padding: 20px;
`;

const MenuList = styled.nav`
  list-style: none;
  margin: -5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BackIcon = styled(Icon)`
  display: flex;
  width: 34px;
  height: 34px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin: 0px 2px 0px -8px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};

  ${StyledButtonV2} {
    flex: 1;
  }
`;

export const ProfileSettingsModalMobileView = memo(() => {
  const { visibilityHandler } = useProfileSettings();
  const { currentView, setCurrentView, settingsOptions, formId } =
    useProfileSettingsOptions('menu');

  const { t } = useTranslation(['user']);

  const currentSection: SettingsOptionsSection = settingsOptions[currentView as string];

  return (
    <StyledDrawer
      fullHeight
      data-lgg-id="profile-settings-modal"
      visible={visibilityHandler.visible}
      title={currentSection?.title || t('user:profileSettings.title')}
      onClose={visibilityHandler.close}
      leading={
        currentView !== 'menu' && (
          <BackIcon
            type="iconBackTop"
            onClick={() => setCurrentView('menu')}
            aria-label="Back to menu"
          />
        )
      }
      footer={
        currentView !== 'menu' &&
        currentView !== 'facebookSetting' && (
          <FooterContainer>
            <ButtonV2
              size="regular"
              variant="default"
              data-lgg-id="cancel-button"
              form={formId}
              type="reset"
            >
              {t('user:profileSettings.buttons.cancel')}
            </ButtonV2>
            <ButtonV2
              size="regular"
              variant="primary"
              data-lgg-id="submit-button"
              form={formId}
              type="submit"
            >
              {t('user:profileSettings.buttons.save')}
            </ButtonV2>
          </FooterContainer>
        )
      }
      aria-label="Profile settings menu"
    >
      <DrawerContent>
        {match(currentView)
          .with('menu', () => (
            <MenuList aria-label="Profile settings options">
              {Object.entries(settingsOptions).map(([key, { icon, title }]) => (
                <MenuItem
                  key={key}
                  icon={icon}
                  title={title}
                  onClick={() => setCurrentView(key as SettingsOptionsKeys)}
                  rightIcon={<Icon type="chevronRight" />}
                />
              ))}
            </MenuList>
          ))
          .otherwise(() => currentSection?.content)}
      </DrawerContent>
    </StyledDrawer>
  );
});

import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { AddNewWhatsAppPhonePage } from 'src/components/pages/whatsapp-channel/add-new-whatsapp-phone-page';
import { WhatsappResourceDetailsPage } from 'src/components/pages/whatsapp-channel/whatsapp-resource-detail-page';
import { WhatsAppResourcesPage } from 'src/components/pages/whatsapp-channel/whatsapp-resources-page';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { DesktopOnlyPageError } from 'src/utils/errors/desktop-only-page-error';

export const WhatsappChannelPage = memo(() => {
  const { path } = useRouteMatch();
  const { t } = useTranslation(['whatsappChannel']);
  useAddBreadcrumb(t('whatsappChannel:pages.channel.breadcrumb'));
  const breakpointUpMd = useBreakpoint(up('md'));
  const { triggerError } = useErrorHandling();

  useEffect(() => {
    if (!breakpointUpMd) {
      triggerError(new DesktopOnlyPageError());
    }
  }, [breakpointUpMd, triggerError]);

  if (!breakpointUpMd) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/new-number`}>
        <AddNewWhatsAppPhonePage />;
      </Route>
      <Route exact path={`${path}/:resourceId`}>
        <WhatsappResourceDetailsPage />
      </Route>
      <Route exact path={path}>
        <WhatsAppResourcesPage />
      </Route>
    </Switch>
  );
});

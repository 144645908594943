import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

const MenuItemContainer = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;
  padding: 17px 15px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.smalt};
  border-radius: 4px;
  border: none;
  text-align: left;

  ${up('md')} {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.secondaryTopaz10 : 'transparent'};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.gogo : theme.colors.flint};
    padding: 10px 15px;
    min-height: 38px;
  }

  svg {
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.colors.gogo : theme.colors.flint};
    }
  }
`;

const Title = styled.span`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
  margin-right: 10px;

  ${up('md')} {
    font-size: 12px;
    line-height: 15px;
    margin-left: 0;
  }
`;

const RightIconContainer = styled.div`
  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

type MenuItemProps = {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
  isActive?: boolean;
  rightIcon?: React.ReactNode;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  onClick,
  isActive = false,
  rightIcon,
}) => {
  return (
    <MenuItemContainer isActive={isActive} onClick={onClick} role="menuitem">
      {icon}
      <Title>{title}</Title>
      {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
    </MenuItemContainer>
  );
};

import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkStatus, useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Query, QueryContactsArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactList } from 'src/components/domain/contacts/contact-list';
import { ExportResultsButton } from 'src/components/general/button/export-results-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { ResponsiveButton } from 'src/components/general/button/lgg-button';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { TablePagination } from 'src/components/general/display/table-pagination';
import {
  TableBodyPlaceholder,
  TableLayoutPlaceholder,
} from 'src/components/general/feedback/loading-placeholders';
import { FlexRow } from 'src/components/layout/flex-row';
import { TableLayoutPageContainer } from 'src/components/layout/table-layout-page-container';
import {
  ContactFilters,
  defaultContactFilters,
} from 'src/components/pages/contacts/components/contact-filters';
import { ContactTable } from 'src/components/pages/contacts/components/contact-table';
import {
  CONTACTS,
  CONTACTS_QUERY_PAGE_SIZE,
  sortInputResolver,
  ContactsPageAdvanceQueryParams,
  ContactsPageQueryParams,
  useContactStages,
  filtersStateResolver,
  useViewCodeResolver,
  useWhereInputResolver,
} from 'src/components/pages/contacts/contact-queries';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useRefreshContact } from 'src/hooks/gql/use-refresh-contact';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import {
  useLegacyParamsForDashboardQuery,
  usePatchRawQueryParams,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const BaseContainer = styled(FlexRow)`
  padding: 20px;

  ${up('md')} {
    padding: 0 0 30px;
  }
`;

const Header = styled(BaseContainer)`
  align-items: center;
  justify-content: space-between;
`;

const HeaderRightRailContainer = styled(FlexRow)`
  justify-content: end;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const ContactsPage = memo(() => {
  const { t } = useTranslation(['contacts', 'common']);
  useAddBreadcrumb(t('contacts:pageBreadcrumb'));
  const filtersVisibility = useVisible();
  const { id: institutionId } = useCurrentInstitution();
  const { getLegacyContactImportUrl } = useUrls();
  const institutionUrl = useInstitutionUrl();
  const { hasPermission, getFeatureFlag } = useAuthorization();
  const hasCrmFeatureFlag = getFeatureFlag('crm');
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const rawQueryParams = usePatchRawQueryParams<
    ContactsPageAdvanceQueryParams,
    ContactsPageQueryParams['view-code']
  >({ defaultViewCode: 'default' });

  const contactStagesQuery = useContactStages();
  const viewCode = rawQueryParams['view-code'];
  const viewCodeResolver = useViewCodeResolver(
    viewCode,
    contactStagesQuery.data?.contactStages ?? [],
  );
  const whereInputResolver = useWhereInputResolver(viewCode);

  const {
    variables,
    sortData,
    filters,
    hasActiveFilters,
    handleSortChange,
    handleNextPage,
    handlePreviousPage,
    legacyFiltersQueryString,
  } = useLegacyParamsForDashboardQuery({
    pageSize: CONTACTS_QUERY_PAGE_SIZE,
    defaultFilters: defaultContactFilters,
    rawQueryParams,
    dateFilterKeys: [
      'date_from',
      'date_to',
      'last_interaction_at.to',
      'last_interaction_at.from',
    ],
    defaultSortData: {
      key: 'createdAt',
      direction: 'DESC',
    },
    viewCodeResolver,
    whereInputResolver,
    sortInputResolver,
    filtersStateResolver,
  });

  const breakpointUpMd = useBreakpoint(up('md'));
  const handleGraphQLError = useHandleGraphQLError();

  const { data, error, loading, networkStatus, refetch } = useQuery<
    Pick<Query, 'contacts'>,
    Partial<QueryContactsArgs>
  >(CONTACTS, {
    variables: {
      ...variables,
      institutionId,
    },
    skip: contactStagesQuery.loading,
    onError: handleGraphQLError,
  });

  const onRefreshHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { action } = params;

        if (action === 'create') {
          await refetch();
        }
      },
    };
  }, [refetch]);

  useRefreshContact(onRefreshHandler);

  const isFetchingMore = networkStatus === NetworkStatus.setVariables;
  const isReFetching = networkStatus === NetworkStatus.refetch;

  useEffect(() => {
    if (isFetchingMore && pageContainerRef.current?.parentNode) {
      (pageContainerRef.current.parentNode as HTMLDivElement).scrollTop = 0;
    }
  }, [isFetchingMore, networkStatus]);

  if (loading && !isReFetching && !data) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  if (error) return <div>error</div>;
  if (!data) return null;

  const {
    contacts: {
      totalCount,
      pageInfo: { endCursor, startCursor, hasNextPage, hasPreviousPage },
    },
  } = data;

  const contacts = getNodesFromConnection(data.contacts);

  return (
    <TableLayoutPageContainer
      data-lgg-id="contacts-page-container"
      ref={pageContainerRef}
    >
      <Header>
        <PaginationInfo
          total={totalCount}
          entityName={t('contacts:pageBreadcrumb')}
          maxWidth={138}
        />
        <HeaderRightRailContainer>
          <FiltersButton
            filtersVisibilityHandler={filtersVisibility}
            hasActiveFilters={hasActiveFilters}
            data-lgg-id="contacts-page-filters-button"
          />
          {hasPermission('lead.access.view.all') && (
            <ResponsiveButton
              variant="defaultWhite"
              icon="import"
              size="regular"
              data-lgg-id="contacts-page-import-button"
              onClick={() => {
                openRemoteModalWrapper(getLegacyContactImportUrl());
              }}
            >
              {t('common:actions.import')}
            </ResponsiveButton>
          )}
          <ExportResultsButton
            testId="contacts-page-export"
            requestUrl={
              hasCrmFeatureFlag
                ? `${institutionUrl}cms/clients/`
                : `${institutionUrl}contacts/`
            }
            params={legacyFiltersQueryString}
          />
        </HeaderRightRailContainer>
      </Header>
      {isFetchingMore ? (
        <TableBodyPlaceholder />
      ) : (
        <>
          {breakpointUpMd ? (
            <ContactTable
              contacts={contacts}
              loading={false}
              sortKey={sortData?.key}
              sortDirection={sortData?.direction}
              onSort={(key, direction) =>
                handleSortChange({ columnKey: key, sortDirection: direction })
              }
            />
          ) : (
            <ContactList contacts={contacts} />
          )}
        </>
      )}
      <TablePagination
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        onLoadPreviousPage={() => startCursor && handlePreviousPage({ startCursor })}
        onLoadNextPage={() => endCursor && handleNextPage({ endCursor })}
      />
      <ContactFilters
        filters={filters}
        visible={filtersVisibility.visible}
        onClose={filtersVisibility.close}
      />
    </TableLayoutPageContainer>
  );
});

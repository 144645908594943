import React, { memo } from 'react';
import Col from 'antd/lib/col';
import Layout from 'antd/lib/layout';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { NotificationsButton } from 'src/components/domain/notifications/notifications-button/notifications-button';
import { IconButton } from 'src/components/general/button/icon-button';
import {
  AddButton,
  useHasAddButtonAuthorization,
} from 'src/components/layout/authenticated-layout/header/add-new';
import { Breadcrumbs } from 'src/components/layout/authenticated-layout/header/breadcrumbs';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHeaderActions } from 'src/hooks/use-header-actions';
import { useSidebar } from 'src/hooks/use-sidebar';

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
  background-color: ${({ theme }) => theme.colors.globalBlue};
  display: flex;
  height: 60px;
  padding-left: 0;
  padding-right: 15px;
  position: relative;
  padding-top: ${({ theme }) => theme.insets.top}px;
  height: ${({ theme }) => 60 + theme.insets.top}px;
`;

const HeaderRow = styled(Row)`
  width: 100%;
`;

const SidenavButton = styled(IconButton)`
  height: 60px;
  line-height: 14px;
  margin: 0;
  width: 60px;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const MobileBreadcrumbs = styled(Breadcrumbs)`
  .ant-breadcrumb-link {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 1.6;
    text-align: left;
  }
`;

const SidenavButtonCol = styled(Col)`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  line-height: 0;
  width: 60px;
`;

export const HeaderMobile = memo<{ className?: string }>(({ className }) => {
  const { isSidebarCollapsed, toggleSidebar } = useSidebar();
  const hasAddButtonAuthorization = useHasAddButtonAuthorization();
  const extraActions = useHeaderActions();
  const { getFeatureFlag } = useAuthorization();
  const displayAddButton = extraActions.length || hasAddButtonAuthorization;

  return (
    <StyledHeader>
      <HeaderRow align="middle">
        <SidenavButtonCol>
          <SidenavButton
            data-lgg-id="sidenav-button"
            icon={isSidebarCollapsed ? 'opensidebar' : 'closesidebar'}
            onClick={toggleSidebar}
          />
        </SidenavButtonCol>
        <Col flex="auto">
          <MobileBreadcrumbs />
        </Col>
        {displayAddButton && <AddButton />}
        {getFeatureFlag('ux-redesign-v2.5') && <NotificationsButton />}
      </HeaderRow>
    </StyledHeader>
  );
});

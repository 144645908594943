import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ConversationPromotionalVideoEmbed } from 'src/components/pages/conversations/components/general/conversations-promotional-video-embed';

const StyledEmptyList = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 130px 20px 0;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  line-height: 29px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.48px;
  text-align: center;
`;

const Message = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  margin-top: 11px;
  max-width: 311px;
  text-align: center;
  margin-bottom: 30px;
`;

const VideoContainer = styled.span`
  width: 100%;
  max-width: 520px;
  height: 292px;
`;

export const ConversationsHome = memo(() => {
  const { t } = useTranslation(['conversations']);

  return (
    <StyledEmptyList>
      <Title>{t('conversations:conversationsHome.welcomeMessage')}</Title>
      <Message>{t('conversations:conversationsHome.message')}</Message>
      <VideoContainer>
        <ConversationPromotionalVideoEmbed />
      </VideoContainer>
    </StyledEmptyList>
  );
});

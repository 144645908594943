import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useTimeHelpers = () => {
  const { t } = useTranslation(['common']);

  const convertSecondsToDuration = useCallback(
    (valueInSeconds: number) => {
      const minutes = Math.floor(valueInSeconds / 60).toString();
      const seconds = (valueInSeconds % 60).toString();

      const padStartValue = (value: string) => value.padStart(2, '0');

      const durationLabel =
        valueInSeconds >= 60
          ? t('common:timeUnits.minute_short')
          : t('common:timeUnits.second_short');

      return `${padStartValue(minutes)}:${padStartValue(seconds)} ${durationLabel}`;
    },
    [t],
  );

  return {
    convertSecondsToDuration,
  };
};

import { dayjs } from '../dayjs';

const addSeconds = (date: Date, seconds: number) =>
  dayjs(date).add(seconds, 'seconds').toDate();

const addMinutes = (date: Date, minutes: number) =>
  dayjs(date).add(minutes, 'minutes').toDate();

const addHours = (date: Date, hours: number) => dayjs(date).add(hours, 'hours').toDate();

const addDays = (date: Date, days: number) => dayjs(date).add(days, 'days').toDate();

const addWeeks = (date: Date, weeks: number) => dayjs(date).add(weeks, 'weeks').toDate();

const addMonths = (date: Date, months: number) =>
  dayjs(date).add(months, 'months').toDate();

const addYears = (date: Date, years: number) => dayjs(date).add(years, 'years').toDate();

const subSeconds = (date: Date, seconds: number) =>
  dayjs(date).subtract(seconds, 'seconds').toDate();

const subHours = (date: Date, hours: number) =>
  dayjs(date).subtract(hours, 'hours').toDate();

const subDays = (date: Date, days: number) => dayjs(date).subtract(days, 'days').toDate();

const subMonths = (date: Date, months: number) =>
  dayjs(date).subtract(months, 'months').toDate();

const subWeeks = (date: Date, weeks: number) =>
  dayjs(date).subtract(weeks, 'weeks').toDate();

const subYears = (date: Date, years: number) =>
  dayjs(date).subtract(years, 'years').toDate();

export {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  subDays,
  subHours,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  ContactBlockedBadge,
  StyledContactBlockedBadge,
} from 'src/components/pages/contacts/components/contact-block';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';

const StyledContactItem = styled(FlexRow)`
  align-items: start;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  min-height: 74px;
  padding: 20px 20px 18px;
`;

const ContactColumn = styled(FlexColumn)`
  flex: 1;
  min-width: 0;

  ${StyledContactBlockedBadge} {
    margin-top: 8px;
  }
`;

const LastInteractionColumn = styled(FlexColumn)``;

const ContactLabel = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 16px;
  margin-bottom: 3px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContactStageLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LastInteractionLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-bottom: 5px;
  text-align: right;
`;

const LastInteractionTime = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 14px;
  text-align: right;
`;

const StyledContactStageIcon = styled(ContactStageIcon)`
  height: 18px;
  margin-right: 5px;
  width: 18px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

const ArrowIcon = styled(Icon)`
  margin-left: 20px;
`;

type ContactItemProps = {
  contact: Contact;
  onClick: VoidFunction;
};

export const ContactItem = memo<ContactItemProps>(({ contact, onClick }) => {
  const {
    label,
    stage: { name: stageName, slug },
    status: { name: statusName },
    lastContactInteraction,
  } = contact;
  const { getFeatureFlag } = useAuthorization();
  const hasCrmFeatureFlag = getFeatureFlag('crm');
  const { t } = useTranslation(['contacts', 'common']);
  const { formatSimpleRelativeDate } = useFormatDate();

  return (
    <StyledContactItem onClick={onClick}>
      <ContactColumn>
        <ContactLabel>{label}</ContactLabel>
        {hasCrmFeatureFlag && (
          <FlexRow style={{ alignItems: 'center' }}>
            <StyledContactStageIcon name={stageName} slug={slug} />
            <ContactStageLabel>{`${stageName} / ${statusName}`}</ContactStageLabel>
          </FlexRow>
        )}
        {contact.isBlocked && <ContactBlockedBadge />}
      </ContactColumn>
      {hasCrmFeatureFlag && (
        <FlexRow style={{ alignItems: 'center' }}>
          <LastInteractionColumn>
            <LastInteractionLabel>
              {t('contacts:information.lastInteraction')}
            </LastInteractionLabel>
            <LastInteractionTime>
              {lastContactInteraction
                ? formatSimpleRelativeDate(lastContactInteraction.occurredAt)
                : t('common:never')}
            </LastInteractionTime>
          </LastInteractionColumn>
          <ArrowIcon type="arrowRightWide" />
        </FlexRow>
      )}
    </StyledContactItem>
  );
});

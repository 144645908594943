import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFacebookSettings } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-facebook-settings';
import { ButtonV2 } from 'src/components/general/button/lgg-button';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h3`
  margin-bottom: unset;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.carbon};
`;

const Description = styled.p`
  margin-bottom: unset;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.smalt};
`;

const ActionContainer = styled.div`
  margin-top: 10px;
`;

export const FacebookSettingSection: React.FC = () => {
  const { handleDeauthorization } = useFacebookSettings();

  const { t } = useTranslation(['user']);

  return (
    <Section data-lgg-id="facebook-settings-section">
      <Title>{t('user:profileSettings.sections.facebookSettings.title')}</Title>
      <Description>
        {t('user:profileSettings.sections.facebookSettings.description')}
      </Description>
      <ActionContainer>
        <ButtonV2
          icon="unlink"
          size="small"
          variant="defaultGhost"
          data-lgg-id="unlink-button"
          type="button"
          onClick={handleDeauthorization}
        >
          {t('user:profileSettings.sections.facebookSettings.buttons.deauthorize')}
        </ButtonV2>
      </ActionContainer>
    </Section>
  );
};

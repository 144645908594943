import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { LexicalEditor as LexicalEditorType } from 'lexical';
import {
  Mutation,
  MutationConversationSendInstagramMessageArgs,
  ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability,
  ContactInteractionChannelResourceUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { ContactInteractionsReplyModeInfoMessage } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-reply-mode-message';
import { CustomEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/custom-editor';
import {
  CustomEditorProps,
  useHandleReplyError,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/helpers';
import { CONVERSATION_SEND_INSTAGRAM_MESSAGE } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { ReplyWindowBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/reply-window-bar';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

export const InstagramEditor = memo<CustomEditorProps>(
  ({ handleFetchNewMessageCompleted, channelAvailability, availabilityMessageRef }) => {
    const { messageValues, attachmentList, conversationId } = useContext(
      ContactInteractionInputManagerContext,
    );

    const handleReplyError = useHandleReplyError();
    const { getFeatureFlag } = useAuthorization();
    const isAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-265_como-usuario-quiero-poder-adjuntar-archivos-de-modo-que-pueda-compartir-con-mi-cliente-detalles-rele',
    );
    const { t } = useTranslation(['conversations']);
    const isAiToolsFeatureEnabled = getFeatureFlag('AI');
    const [selectedResource, setSelectedResource] =
      useState<ContactInteractionChannelResourceUnion | null>(null);
    const [selectedResourceAvailability, setSelectedResourceAvailability] = useState<
      | ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability
      | undefined
    >(undefined);
    const [sendInstagramMessage, { loading: isSendingInstagramMessage }] = useMutation<
      Pick<Mutation, 'conversationSendInstagramMessage'>,
      MutationConversationSendInstagramMessageArgs
    >(CONVERSATION_SEND_INSTAGRAM_MESSAGE, {
      onError: handleReplyError,
    });
    const { parseISO, isFuture } = useDateHelpers();

    const isContactWindowOpen = useMemo(
      () =>
        selectedResourceAvailability &&
        selectedResourceAvailability?.expiresAt &&
        isFuture(parseISO(selectedResourceAvailability.expiresAt)),
      [isFuture, parseISO, selectedResourceAvailability],
    );

    const isInputEnabled = useMemo(
      () =>
        isContactWindowOpen &&
        selectedResourceAvailability &&
        channelAvailability?.isAvailable,
      [
        channelAvailability?.isAvailable,
        isContactWindowOpen,
        selectedResourceAvailability,
      ],
    );

    useEffect(() => {
      if (channelAvailability) {
        setSelectedResourceAvailability(
          channelAvailability.resourceToContactAddressAvailability?.find(
            (resource) => resource.resourceId === selectedResource?.id,
          ),
        );
      }
    }, [channelAvailability, selectedResource?.id]);

    useEffect(() => {
      if (!channelAvailability) {
        return;
      }
      const { resources, lastUsedResourceId } = channelAvailability;

      const defaultSelectedResource =
        resources.find(({ id }) => id === lastUsedResourceId) ?? resources[0] ?? null;

      setSelectedResource(defaultSelectedResource);
    }, [channelAvailability]);

    const handleSendMessage = useCallback(
      async (editor: LexicalEditorType) => {
        if (
          !conversationId ||
          !selectedResourceAvailability ||
          isSendingInstagramMessage
        ) {
          return;
        }

        let commonVariables: Pick<
          MutationConversationSendInstagramMessageArgs,
          'conversationId' | 'message' | 'attachments'
        > = {
          conversationId,
          message: messageValues.message ?? '',
          attachments: [],
        };

        const attachments = attachmentList.map(({ url }) => ({
          s3UploadedUrl: url ?? '',
        }));

        if (isAttachmentsFeatureEnabled && attachments.length > 0) {
          commonVariables = {
            ...commonVariables,
            attachments,
          };
        }

        await sendInstagramMessage({
          variables: {
            ...commonVariables,
            resourceId: selectedResourceAvailability.resourceId,
          },
          onCompleted: async ({ conversationSendInstagramMessage: data }) => {
            const interactionId = data.interaction?.id;

            if (!interactionId) {
              return;
            }

            await handleFetchNewMessageCompleted({
              editor,
              where: {
                contactInteractionId: {
                  eq: Number(interactionId),
                },
              },
            });
          },
        });
      },
      [
        isSendingInstagramMessage,
        conversationId,
        messageValues.message,
        attachmentList,
        isAttachmentsFeatureEnabled,
        selectedResourceAvailability,
        sendInstagramMessage,
        handleFetchNewMessageCompleted,
      ],
    );

    const topContent = useMemo(() => {
      if (isContactWindowOpen) {
        return null;
      }

      return (
        <>
          <ContactInteractionsReplyModeInfoMessage
            title={t(
              'conversations:messageInput.modes.reply.messages.instagram.messageWindowClosed.title',
            )}
            body={t(
              'conversations:messageInput.modes.reply.messages.instagram.messageWindowClosed.body',
            )}
            lggId="instagram-message-window-closed-info-message"
          />
        </>
      );
    }, [isContactWindowOpen, t]);

    const replyWindowBar = useMemo(() => {
      return isContactWindowOpen && availabilityMessageRef?.current
        ? createPortal(
            <ReplyWindowBar
              expirationDate={selectedResourceAvailability?.expiresAt}
              type="INSTAGRAM"
            />,
            availabilityMessageRef.current,
          )
        : null;
    }, [
      availabilityMessageRef,
      isContactWindowOpen,
      selectedResourceAvailability?.expiresAt,
    ]);

    return (
      <>
        {replyWindowBar}
        <CustomEditor
          handleSubmission={handleSendMessage}
          isSubmitting={isSendingInstagramMessage}
          bottomContent={{
            visible: true,
          }}
          topContent={topContent}
          pluginConfig={{
            emojiPicker: true,
            messageAttachments: isAttachmentsFeatureEnabled,
            aiTools: isAiToolsFeatureEnabled,
          }}
          isInputEnabled={isInputEnabled}
        />
      </>
    );
  },
);

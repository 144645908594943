import { orderBy } from 'lodash';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortConversations = (conversations: Conversation[]) => {
  return orderBy(
    conversations,
    [
      (conversation) => {
        return new Date(conversation.lastContactInteraction?.occurredAt).getTime();
      },
      (conversation) => {
        return conversation.id;
      },
    ],
    ['desc', 'asc'],
  );
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import {
  LabelContainer,
  PhoneNumberSelectProps,
  ResourceSelector,
  SelectContainer,
  SelectExcerpt,
  SelectText,
} from 'src/components/domain/contacts/call-contact-modal/selects/shared';
import { LggSelectableOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

export const PhoneNumberSelect = memo<PhoneNumberSelectProps>(
  ({ excerpt, label, options, selectedResource, ...rest }) => {
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['contacts', 'common']);

    const dropdownButton = (
      <ResourceSelector
        className="resource-selector"
        onClick={visibilityHandler.show}
        isActive={visibilityHandler.visible}
        size="small"
        variant="default"
        children={options.find((option) => option.value === selectedResource)?.label}
        {...rest}
      />
    );

    return (
      <>
        <SelectContainer>
          <LabelContainer>
            <SelectText>{label}</SelectText>
            {excerpt && <SelectExcerpt>{excerpt}</SelectExcerpt>}
          </LabelContainer>
          {breakpointUpMd ? (
            <LggSelectableOptionsDropdownButtonWithCustomTrigger
              options={options}
              children={dropdownButton}
              selectedValue={selectedResource}
              size="small"
              variant="default"
              visibilityHandler={visibilityHandler}
            />
          ) : (
            <>
              {dropdownButton}
              <SelectableOptionsDrawer
                onClose={visibilityHandler.close}
                visible={visibilityHandler.visible}
                options={options}
                selectedValue={selectedResource}
                title={t('contacts:callContactModal.selectNumber')}
              />
            </>
          )}
        </SelectContainer>
      </>
    );
  },
);

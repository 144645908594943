import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationChangeTaskStatusArgs,
  Query,
  QueryTasksArgs,
  Task,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  ContactModalTabProps,
  ContactModalLoadingView,
  ContactModalTabEmptyView,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { TaskItem } from 'src/components/domain/contacts/contact-modal/tabs/tasks/contact-modal-task-item';
import { sortTasks } from 'src/components/domain/tasks/helpers/sort-tasks-items';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useDeleteTask } from 'src/hooks/gql/use-delete-task';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';

const CHANGE_TASK_STATUS_MUTATION = gql`
  mutation ChangeTaskStatus($input: ChangeTaskStatusInput!) {
    changeTaskStatus(input: $input) {
      task {
        id
        status
      }
    }
  }
`;

export const GET_CONTACT_TASKS_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  # noinspection GraphQLUnresolvedReference,GraphQLSchemaValidation
  query GetTasksInfiniteScroll(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $contactId: Float!
  ) {
    tasks(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: { contact: { id: { _eq: $contactId } } }
      orderBy: { createdAt: DESC }
    ) @connection(key: "infinite-scroll") {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          title
          status
          description
          dueAt
          completedAt
          createdAt
          assignedTo {
            id
            fullName
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 50;

type ContactModalTasksTabProps = ContactModalTabProps;

export const ContactModalTasksTab = memo<ContactModalTasksTabProps>(
  ({ contactId, virtuosoRef }) => {
    const handleGraphQLError = useHandleGraphQLError();
    const { id: companyId } = useCurrentInstitution();
    const { t } = useTranslation(['contacts']);

    const [changeTaskStatus] = useMutation<
      Pick<Mutation, 'changeTaskStatus'>,
      MutationChangeTaskStatusArgs
    >(CHANGE_TASK_STATUS_MUTATION, {
      onError: handleGraphQLError,
    });

    const [deleteTask] = useDeleteTask();

    const {
      handleLoadBottom,
      firstItemIndex,
      loadingMoreBottom,
      handleLoadTop,
      loading,
      nodes,
    } = useInfiniteListQuery<
      Pick<Query, 'tasks'>,
      Partial<QueryTasksArgs> & { contactId: number },
      Task
    >({
      queryOptions: {
        getNodeIdCallback: (notification) => notification?.id,
        query: GET_CONTACT_TASKS_QUERY,
        variables: {
          first: PAGE_SIZE,
          institutionId: companyId,
          contactId,
        },
        getEdgesCallback: (data) => data?.tasks.edges ?? [],
        getPageInfoCallback: (data) => data?.tasks.pageInfo,
        onError: handleGraphQLError,
        queryPageSize: PAGE_SIZE,
        sortNodesCallback: sortTasks,
      },
      pollingOptions: {
        interval: 30000,
      },
    });

    const onRefreshHandler = useMemo<UseRefreshProps>(() => {
      return {
        onRefresh: async (params) => {
          const { action } = params;

          if (action === 'create') {
            await handleLoadTop({ forceLoadMore: true });
          }
        },
      };
    }, [handleLoadTop]);

    useRefreshTask(onRefreshHandler);

    if (loading) {
      return <ContactModalLoadingView data-lgg-id="contact-modal-tasks-tab-loading" />;
    }

    if (!nodes.length) {
      return (
        <ContactModalTabEmptyView
          data-lgg-id="contact-modal-tasks-tab-empty"
          title={t('contacts:detailsModal.tabs.tasks.emptyList.title')}
          message={t('contacts:detailsModal.tabs.tasks.emptyList.message')}
          iconConfig={{ type: 'embedded', icon: 'tabTasks' }}
        />
      );
    }

    return (
      <Virtuoso
        ref={virtuosoRef}
        data-lgg-id="contact-modal-tasks-tab-list"
        data={nodes}
        endReached={() => handleLoadBottom()}
        firstItemIndex={firstItemIndex}
        components={{
          Footer: () => (
            <ListLoadingItem
              data-lgg-id="contact-modal-task-tab-loading-more"
              visible={loadingMoreBottom}
            />
          ),
        }}
        itemContent={(index, task) => {
          return (
            <TaskItem
              data-lgg-id="contact-modal-task-item"
              task={task}
              onTaskDelete={({ taskId }) => {
                void deleteTask({
                  variables: {
                    input: {
                      taskId,
                      institutionId: companyId,
                    },
                  },
                  update(cache) {
                    const normalizedId = cache.identify({
                      id: taskId,
                      __typename: 'Task',
                    });
                    cache.evict({ id: normalizedId });
                    cache.gc();
                  },
                });
              }}
              onTaskStatusChange={({ taskId, taskStatus }) => {
                void changeTaskStatus({
                  variables: {
                    input: {
                      institutionId: companyId,
                      taskId,
                      status: taskStatus,
                    },
                  },
                });
              }}
            />
          );
        }}
      />
    );
  },
);

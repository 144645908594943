import React, { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import styled from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { LinkButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactActionIcons } from 'src/components/pages/contacts/components/contact-action-icons';
import { ContactCopyMenu } from 'src/components/pages/contacts/components/contact-copy-menu';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

const ContactName = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  flex: 1;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.32px;
  line-height: 19px;
  min-width: 0;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Container = styled(FlexColumn)`
  width: 300px;
`;

const TopContainer = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 10px 15px;
  height: 44px;
`;

const MainContainer = styled(FlexColumn)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 20px 15px 16px;
`;

const EditIcon = styled(Icon)`
  margin-left: 10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const FieldValue = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 16px;
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ContactFieldIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

const StyledContactField = styled(FlexRow)`
  align-items: center;
  &:not(:first-child) {
    margin-top: 15px;
  }

  &.tags-container {
    margin-top: 11px;

    ${ContactFieldIcon} {
      height: 100%;
      position: relative;
      top: 4px;
    }
  }

  &:last-child:not(.tags-container) {
    margin-bottom: 4px;
  }
`;

const ContactTag = styled(Tag)`
  &.ant-tag {
    height: 22px;
    background: ${({ theme }) => theme.colors.secondaryMint20};
    border: none;
    border-radius: 11px;
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 11px;
    line-height: 13px;
    color: ${({ theme }) => theme.colors.secondaryMintDark};
    padding: 5px 6px 4px;
    margin: 0 4px 4px 0;
  }
`;

const ContactTagsContainer = styled(FlexRow)`
  flex-wrap: wrap;
`;

const ViewProfileButton = styled(LinkButton)`
  height: 30px;
  margin-right: 5px;
`;

const BottomOptionsContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const BottomContainer = styled(FlexRow)`
  padding: 10px 15px;

  ${ViewProfileButton} {
    flex: 1;
  }

  ${BottomOptionsContainer} {
    flex: 1;
  }
`;

const StyledContactStageIcon = styled(ContactStageIcon)`
  width: 24px;
  height: 24px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

type ContactFieldProps = {
  value: ReactNode;
  icon: string;
  className?: string;
  key: string;
};

const ContactField = memo<ContactFieldProps>(({ value, icon, ...rest }) => {
  return (
    <StyledContactField {...rest}>
      <ContactFieldIcon type={icon} />
      <FieldValue>{value}</FieldValue>
    </StyledContactField>
  );
});

type ContactPreviewProps = {
  contact: Contact;
};

export const ContactPreview = memo<ContactPreviewProps>(({ contact }) => {
  const {
    label,
    stage,
    primaryPhone,
    primaryEmail,
    lastContactInteraction,
    tags,
    interest,
  } = contact;
  const { t } = useTranslation(['contacts', 'common']);
  const { formatRelativeGroupDate } = useFormatDate();
  const { getLegacyContactEditUrl, getContactModalUrl } = useUrls();
  const { getFeatureFlag } = useAuthorization();
  const hasCrmFeatureFlag = getFeatureFlag('crm');

  const possibleFields: ContactFieldProps[] = [
    {
      key: 'contact-preview-detail-interest',
      icon: 'interest',
      value: interest,
    },
  ];

  if (hasCrmFeatureFlag) {
    possibleFields.push({
      key: 'contact-preview-detail-last-interaction',
      icon: 'interaction',
      value: lastContactInteraction
        ? `${t('contacts:information.lastInteraction')} ${formatRelativeGroupDate(
            lastContactInteraction.occurredAt,
            new Date(),
          )}`
        : t('common:noInteractionLogged'),
    });
  }

  possibleFields.push(
    {
      key: 'contact-preview-detail-phone',
      icon: 'phone',
      value: primaryPhone ? (
        <ContactCopyMenu contactId={contact.id} config={{ phone: primaryPhone }} />
      ) : null,
    },
    {
      key: 'contact-preview-detail-email',
      icon: 'email',
      value: primaryEmail ? (
        <ContactCopyMenu contactId={contact.id} config={{ email: primaryEmail }} />
      ) : null,
    },
    {
      key: 'contact-preview-detail-tags',
      icon: 'tags',
      className: 'tags-container',
      value: tags.length ? (
        <ContactTagsContainer>
          {tags.map((tag) => (
            <ContactTag key={tag.id}>{tag.name}</ContactTag>
          ))}
        </ContactTagsContainer>
      ) : null,
    },
  );

  const fields = possibleFields.filter(({ value }) =>
    Boolean(value),
  ) as ContactFieldProps[];
  const hasFields = Boolean(fields.length);

  return (
    <Container data-lgg-id="contact-preview-popover" onClick={(e) => e.stopPropagation()}>
      <TopContainer>
        {hasCrmFeatureFlag && (
          <StyledContactStageIcon name={stage.name} slug={stage.slug} />
        )}
        <ContactName data-lgg-id="contact-preview-detail-name">{label}</ContactName>
        <EditIcon
          lggTestId="contact-preview-edit-contact"
          type="edit"
          onClick={() => {
            openRemoteModalWrapper(getLegacyContactEditUrl(contact.id));
          }}
        />
      </TopContainer>
      {hasFields && (
        <MainContainer>
          {fields.map(({ value, icon, className, key }) => (
            <ContactField
              key={key}
              className={className}
              value={value}
              icon={icon}
              data-lgg-id={key}
            />
          ))}
        </MainContainer>
      )}
      <BottomContainer>
        <ViewProfileButton
          data-lgg-id="contact-preview-view-contact-profile"
          variant="tertiary"
          to={getContactModalUrl(contact.id)}
        >
          {t('contacts:information.viewFullProfile')}
        </ViewProfileButton>
        <BottomOptionsContainer>
          {!hasCrmFeatureFlag && <Expand />}
          <ContactActionIcons
            contactId={contact.id}
            contactPhone={contact.primaryPhone?.national}
          />
        </BottomOptionsContainer>
      </BottomContainer>
    </Container>
  );
});

import { useState, useEffect } from 'react';

export const useGetVisualViewport = () => {
  const getHeightValue = () =>
    window.visualViewport ? window.visualViewport.height : window.innerHeight;
  const getWidthValue = () =>
    window.visualViewport ? window.visualViewport.width : window.innerWidth;
  const [visualViewportInfo, setVisualViewportInfo] = useState<{
    visualViewportHeight: number;
    visualViewportWidth: number;
  }>({
    visualViewportHeight: getHeightValue(),
    visualViewportWidth: getWidthValue(),
  });

  useEffect(() => {
    const handleResize = () => {
      setVisualViewportInfo({
        visualViewportHeight: getHeightValue(),
        visualViewportWidth: getWidthValue(),
      });
    };

    window.addEventListener('resize', handleResize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return visualViewportInfo;
};

import React, { Fragment, ReactNode, memo } from 'react';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ContextMenuItem, Menu } from 'src/components/general/display/context-menu';
import { CircularAlignedIcon } from 'src/components/general/icon/circular-aligned-icon';

const { Item } = Menu;

const OptionLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  display: block;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;

  ${up('md')} {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.14px;
  }
`;

export const AddNewOptionIcon = styled(CircularAlignedIcon)`
  margin-left: 10px;

  ${up('md')} {
    margin: 0;
  }
`;

const StyledContainer = styled.div`
  margin-right: 20px;
`;
const AddNewMenu = styled(Menu)`
  &.ant-dropdown-menu {
    border: none;
    box-shadow: 0 20px 40px 0 rgba(91, 101, 112, 0.1);
    min-width: 180px;
    padding: 10px;

    .ant-dropdown-menu-item {
      align-items: end;
      color: ${({ theme }) => theme.colors.flint};
      font-family: ${({ theme }) => theme.font.regular};
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      height: 34px;
      letter-spacing: -0.14px;
      line-height: 17px;
      line-height: normal;

      a {
        display: flex;
        flex: 1;
        padding: 8px 15px;
      }

      svg {
        height: 18px;
        width: 18px;
      }

      &:hover {
        ${OptionLabel}, a {
          color: ${({ theme }) => theme.colors.smalt};
        }
      }

      .ant-dropdown-menu-item-icon {
        margin-right: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
` as unknown as typeof Menu;

const StyledItem = styled(Item)`
  padding: 8px 15px;
`;

const ExtraOptionsDivider = styled(Menu.Divider)`
  && {
    background-color: rgba(233, 238, 242, 0.5);
    height: 1px;
    margin: 0 0 5px 0;
  }

  ${up('md')} {
    && {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
`;

type AddNewContextMenuProps = {
  header?: ReactNode;
  groups: ContextMenuItem[][];
};

export const AddNewContextMenu = memo<AddNewContextMenuProps>(
  ({ groups, header, ...rest }) => {
    return (
      <AddNewMenu {...rest}>
        {header}
        {groups.map((itemGroup, index) => {
          const items = itemGroup.map((item) => {
            const { key, icon, onClick, testId, label, to } = item;

            return to ? (
              <Item key={key} data-lgg-id={testId}>
                <Link to={to} onClick={onClick}>
                  <StyledContainer>{icon}</StyledContainer>
                  <OptionLabel>{label}</OptionLabel>
                </Link>
              </Item>
            ) : (
              <StyledItem
                key={key}
                icon={icon}
                onClick={(e) => {
                  e.domEvent.stopPropagation();
                  onClick?.(e.domEvent);
                }}
                data-lgg-id={testId}
              >
                <OptionLabel>{label}</OptionLabel>
              </StyledItem>
            );
          });

          if (!items.length) {
            return <Fragment key={index} />;
          }

          return (
            <Fragment key={index}>
              {items}
              {groups[index + 1] ? <ExtraOptionsDivider /> : null}
            </Fragment>
          );
        })}
      </AddNewMenu>
    );
  },
);

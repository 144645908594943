import React from 'react';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';

export const MobileCalendarViewContext = React.createContext<{
  onEventDeleted: (event: Schedule | TaskEvent) => void;
  initialFetch: (input: { initialDate: Date }) => Promise<void>;
  currentMonth: Date;
  setCurrentMonth: ValueChanged<Date>;
  events: { [p: string]: (Schedule | TaskEvent)[] };
  scrollToDay: (input: { date: string; offset?: number }) => void;
}>({
  onEventDeleted: () => {},
  initialFetch: async () => {},
  currentMonth: new Date(),
  setCurrentMonth: (value) => {},
  events: {},
  scrollToDay: () => {},
});

import { useEffect } from 'react';
import { useService } from 'react-service-locator';
import { BreadcrumbsService } from 'src/services/breadcrumbs.service';

export function useSetLegacyBreadcrumbs(breadcrumbsElement: HTMLDivElement | null) {
  const breadcrumbsService = useService(BreadcrumbsService, () => []);
  useEffect(() => {
    if (breadcrumbsElement) {
      const text = breadcrumbsElement.innerText;
      const routes = JSON.parse(text) as { breadcrumbName: string; path: string }[];
      breadcrumbsService.set(routes);

      return () => breadcrumbsService.clear();
    }
  }, [breadcrumbsService, breadcrumbsElement]);
}

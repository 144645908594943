import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ConversationItemUnion,
  ContactInteractionInPerson,
  ContactInteractionSms,
  ContactInteractionPhoneCall,
  ContactInteractionEmail,
  ContactInteractionFacebookMessenger,
  ContactInteractionUnknown,
  ContactInteractionWhatsapp,
  ContactInteractionDirection,
  ContactInteractionInstagram,
  ContactInteractionParticipatingEntityEdge,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchParticipatingEntityExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction-participating-entity';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const StyledMessageGroupLabel = styled(FlexRow)`
  padding-top: 15px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubjectLabel = styled.span<{ direction: ContactInteractionDirection }>`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.smalt};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  ${({ direction }) => {
    if (direction === 'OUTBOUND') {
      return `
        margin-right: 5px;
        margin-left: auto;
      `;
    } else {
      return `
        margin-left: 5px;
        margin-right: auto;
      `;
    }
  }}
`;

const ChannelIcon = styled(Icon)`
  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

type ContactInteractionWithParticipatingEntity = (
  | ContactInteractionSms
  | ContactInteractionPhoneCall
  | ContactInteractionEmail
  | ContactInteractionFacebookMessenger
  | ContactInteractionInPerson
) & {
  participatingEntity: ContactInteractionParticipatingEntityEdge;
};

export const isOutboundInteraction = (
  interaction:
    | ContactInteractionInPerson
    | ContactInteractionSms
    | ContactInteractionPhoneCall
    | ContactInteractionEmail
    | ContactInteractionUnknown
    | ContactInteractionWhatsapp // TODO
    | ContactInteractionFacebookMessenger
    | ContactInteractionInstagram,
): interaction is ContactInteractionWithParticipatingEntity => {
  return (
    interaction.__typename === 'ContactInteractionInPerson' ||
    interaction.direction === 'OUTBOUND'
  );
};

type MessageGroupLabelProps = {
  contactInteraction: ConversationItemUnion;
  contactLabel: string;
};

export const MessageGroupLabel = memo<MessageGroupLabelProps>(
  ({ contactInteraction, contactLabel }) => {
    const interactionType = contactInteraction.__typename;
    const direction =
      'direction' in contactInteraction
        ? contactInteraction.direction
        : interactionType === 'ContactInteractionFacebookAd'
        ? 'INBOUND'
        : 'OUTBOUND';
    const isOutbound = direction === 'OUTBOUND';
    const { t } = useTranslation(['conversations']);

    const subject = useMemo(() => {
      return match(contactInteraction)
        .with(
          { __typename: 'ConversationNote' },
          (interaction) => interaction.createdBy.fullName,
        )
        .with({ __typename: 'ContactNote' }, (interaction) => interaction.user?.fullName)
        .with({ __typename: 'ContactInteractionFacebookAd' }, () => contactLabel)
        .with(
          P.union(
            { __typename: 'ContactInteractionInPerson' },
            { direction: 'OUTBOUND' },
          ),
          ({ participatingEntity }) => {
            if (!participatingEntity) {
              return null;
            }

            return matchParticipatingEntityExhaustive(participatingEntity, {
              agent: (participatingEntity) => participatingEntity.label,
              callflow: () => t('conversations:messageGroup.callflow.title'),
            });
          },
        )
        .otherwise(() => contactLabel);
    }, [contactInteraction, contactLabel, t]);

    const icon = useMemo(() => {
      return match(interactionType)
        .with('ContactInteractionInPerson', () => 'inPerson')
        .with('ContactInteractionFacebookMessenger', () => 'facebookMessenger')
        .with('ContactInteractionFacebookAd', () => 'facebook')
        .with('ContactInteractionEmail', () => 'email')
        .with('ContactInteractionSms', () => 'sms')
        .with('ContactInteractionPhoneCall', () => 'phone')
        .with('ContactInteractionWhatsapp', () => 'whatsapp')
        .with('ContactInteractionInstagram', () => 'instagramAlt')
        .with(P.union('ConversationNote', 'ContactNote'), () => 'note')
        .otherwise(() => null);
    }, [interactionType]);

    const iconElement = icon && (
      <ChannelIcon type={icon} lggTestId={`contact-interaction-group-icon-${icon}`} />
    );

    if (interactionType.startsWith('ContactTimeline')) {
      return null;
    }

    return (
      <StyledMessageGroupLabel
        data-lgg-id={`contact-interaction-message-group-label-${interactionType}`}
      >
        {!isOutbound && iconElement}
        <SubjectLabel direction={direction}>{subject}</SubjectLabel>
        {isOutbound && iconElement}
      </StyledMessageGroupLabel>
    );
  },
);

import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Dropdown from 'antd/lib/dropdown';
import { down, up, between } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import {
  Contact,
  QueryConversationArgs,
  Conversation,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import {
  ContactAddNewDropdownTitle,
  useContactAddNewOptions,
} from 'src/components/domain/contacts/contact-add-new/contact-add-new';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { LggButton } from 'src/components/general/button/lgg-button';
import { AddNewContextMenu } from 'src/components/general/display/add-new-context-menu';
import { Tooltip } from 'src/components/general/display/tooltip';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BlockContactModal,
  BlockIcon,
  useContactBlock,
} from 'src/components/pages/contacts/components/contact-block';
import {
  CloseIcon,
  ContactActionsIcon,
  ContactName,
  MoreOptionsIcon,
} from 'src/components/pages/conversations/components/contact-interactions/shared';
import { SnoozeConversationModal } from 'src/components/pages/conversations/components/conversations/snooze-conversation-modal/snooze-conversation-modal';
import { OpenLegacyCallModalIcon } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';
import { useConversationSetClosed } from 'src/components/pages/conversations/hooks/use-conversation-set-closed';
import { useConversationSetMarked } from 'src/components/pages/conversations/hooks/use-conversation-set-marked';
import { useConversationUnsnooze } from 'src/components/pages/conversations/hooks/use-conversation-unsnooze';
import { CONTACT_BLOCKING_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useVisible } from 'src/hooks/use-visible';

export const CONVERSATION = gql`
  ${CONTACT_BLOCKING_FIELDS}
  query GetConversation($id: String!) {
    conversation(id: $id) {
      id
      isOpen
      isMarked
      isSnoozed
      isStarted
      contact {
        id
        label
        ...ContactBlockingFieldsFragment
        primaryPhone {
          national
        }
      }
    }
  }
`;

export const ContactInteractionListHeaderSkeleton = styled(Skeleton)`
  align-items: center;
  display: flex;
  min-height: 65px;
  padding: 0;

  ${up('md')} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
    padding: 22px 30px;
  }

  .ant-skeleton-title {
    height: 20px;
    margin: 0;
  }

  .ant-skeleton-paragraph {
    display: none;
  }
`;

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  display: flex;
  min-height: 65px;
  padding: 0 20px;
`;

const CloseButtonText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-left: 10px;
  text-align: left;
  transition: color 200ms;
`;

const StyledChangeConversationOpenStatusButton = styled(LggButton)<{
  isOpen: boolean;
  heightOverride?: string;
}>`
  && {
    margin-left: 15px;
    padding-left: 15px;
    padding-right: 15px;
    transition: color 200ms;
    width: unset;
    ${({ heightOverride }) => `
      height: ${heightOverride ? heightOverride : '34px'};
    `}

    path {
      transition: fill 200ms;
    }

    ${({ isOpen, theme }) => `
      ${CloseButtonText} {
        color: ${isOpen ? theme.colors.white : theme.colors.flint};
      }

      path {
        fill: ${isOpen ? theme.colors.white : theme.colors.flint};
      }
    `}
  }
`;

const BackIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 15px;
`;

const ContactNameHint = styled.span`
  color: ${({ theme }) => theme.colors.geyser};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
`;

const ContactNameContainer = styled(FlexColumn)<{ clickable: boolean }>`
  min-width: 0;

  ${up('md')} {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`;

const HeaderOptionsContainer = styled(FlexRow)`
  align-items: center;

  & > * {
    margin-left: 10px;
  }
`;

const HeaderOptionIcon = styled(Icon)`
  margin-left: 10px;
  padding: 5px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const MarkConversationHeaderOptionIcon = styled(HeaderOptionIcon)<{ $isMarked: boolean }>`
  svg path {
    ${({ $isMarked }) =>
      $isMarked
        ? css`
            fill: ${({ theme }) => theme.colors.secondaryGold60};
          `
        : css`
            fill: ${({ theme }) => theme.colors.flint};
          `}
  }
`;

const HeaderOptionsSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.koala};
  height: 22px;
  margin: 0 10px;
  width: 1px;
`;

const StyledOpenLegacyCallModalIcon = styled(OpenLegacyCallModalIcon)`
  margin: 0;
`;

type ChangeConversationStatusButtonProps = {
  isOpen: Conversation['isOpen'];
  onClick: VoidFunction;
  heightOverride?: string;
};

export const ChangeConversationOpenStatusButton =
  memo<ChangeConversationStatusButtonProps>(({ isOpen, onClick, heightOverride }) => {
    const { t } = useTranslation(['conversations']);

    return (
      <StyledChangeConversationOpenStatusButton
        data-lgg-id="change-conversation-open-status-button"
        isOpen={isOpen}
        onClick={onClick}
        variant={isOpen ? 'secondary' : 'tertiary'}
        heightOverride={heightOverride}
      >
        <Icon type={isOpen ? 'circularCheck' : 'openConversation'} />
        <CloseButtonText>
          {isOpen
            ? t('conversations:conversationOptions.close')
            : t('conversations:conversationOptions.reopen')}
        </CloseButtonText>
      </StyledChangeConversationOpenStatusButton>
    );
  });

export const ConversationMoreOptionsDropdown = memo<{
  conversation: Conversation;
  addMarkAndSnoozeOptions?: boolean;
}>(({ conversation, addMarkAndSnoozeOptions = true }) => {
  const { t } = useTranslation(['conversations', 'contacts']);
  const breakpointBetweenMdAndXl = useBreakpoint(between('md', 'xl'));
  const { setShowContactInformation } = useConversation();
  const moreOptionsVisibilityHandler = useVisible();
  const { getFeatureFlag } = useAuthorization();
  const snoozeConversationVisibilityHandler = useVisible();
  const blockContactModalVisibility = useVisible();

  const conversationSetMarked = useConversationSetMarked();
  const { unsnoozeConversation } = useConversationUnsnooze();

  const { isMarked, isSnoozed, id: conversationId, contact } = conversation;
  const { optionLabel: contactBlockOptionLabel, changeBlockStatus } = useContactBlock({
    contact,
  });
  const options = useMemo(() => {
    const options: DrawerOption[] = [];

    if (breakpointBetweenMdAndXl) {
      options.push({
        label: t('conversations:conversationOptions.contactInformation'),
        icon: 'contact',
        onClick: () => setShowContactInformation(true),
      });
    }

    options.push(
      ...(addMarkAndSnoozeOptions
        ? [
            {
              'data-lgg-id': 'change-conversation-marked-status-option',
              label: isMarked
                ? t('conversations:conversationOptions.unmark')
                : t('conversations:conversationOptions.mark'),
              icon: isMarked ? 'unmark' : 'opportunity',
              onClick: () => {
                moreOptionsVisibilityHandler.close();
                void conversationSetMarked({
                  conversationId,
                  isMarked: !isMarked,
                });
              },
            },
            {
              'data-lgg-id': 'change-conversation-snooze-status-option',
              label: isSnoozed
                ? t('conversations:snoozedConversationBanner.discard')
                : t('conversations:conversationOptions.snooze'),
              icon: isSnoozed ? 'discardSnooze' : 'snooze',
              onClick: async () => {
                moreOptionsVisibilityHandler.close();

                if (isSnoozed) {
                  await unsnoozeConversation({
                    variables: {
                      conversationId: conversationId,
                    },
                  });
                } else {
                  snoozeConversationVisibilityHandler.setVisible(true);
                }
              },
            },
          ]
        : []),
      ...(getFeatureFlag('block-contacts')
        ? [
            {
              'data-lgg-id': 'block-contact-option',
              label: contactBlockOptionLabel,
              icon: <BlockIcon type="block" />,
              onClick: async () => {
                moreOptionsVisibilityHandler.close();

                await changeBlockStatus(blockContactModalVisibility);
              },
            },
          ]
        : []),
    );

    return options;
  }, [
    addMarkAndSnoozeOptions,
    blockContactModalVisibility,
    breakpointBetweenMdAndXl,
    changeBlockStatus,
    contactBlockOptionLabel,
    conversationId,
    conversationSetMarked,
    getFeatureFlag,
    isMarked,
    isSnoozed,
    moreOptionsVisibilityHandler,
    setShowContactInformation,
    snoozeConversationVisibilityHandler,
    t,
    unsnoozeConversation,
  ]);

  return options.length ? (
    <>
      <LggOptionsDropdownButtonWithCustomTrigger
        visibilityHandler={moreOptionsVisibilityHandler}
        options={options}
        customDropdownProps={{
          placement: 'bottomRight',
          align: {
            offset: [8, 11],
          },
        }}
        customTrigger={
          <MoreOptionsIcon
            type="moreOptions"
            lggTestId="conversation-more-options-menu"
          />
        }
      />
      {addMarkAndSnoozeOptions ? (
        <SnoozeConversationModal
          zIndex={ModalsZIndex}
          visible={snoozeConversationVisibilityHandler.visible}
          onClose={snoozeConversationVisibilityHandler.close}
          conversationId={conversationId}
        />
      ) : null}

      <BlockContactModal
        zIndex={ModalsZIndex}
        visibilityHandler={blockContactModalVisibility}
        contact={contact}
      />
    </>
  ) : null;
});

const HeaderOptionTooltip = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement;
}) => {
  return (
    <Tooltip title={title} align={{ offset: [0, 6] }}>
      {children}
    </Tooltip>
  );
};

type ContactInteractionListHeaderProps = {
  conversationId: string;
  showContactDetailsHint?: boolean;
  onClose?: VoidFunction;
};

export const ContactInteractionListHeader = memo<ContactInteractionListHeaderProps>(
  ({ conversationId, onClose, showContactDetailsHint = false }) => {
    const { t } = useTranslation(['conversations']);
    const breakpointDownLg = useBreakpoint(down('lg'));
    const { setShowContactInformation } = useConversation();
    const institutionUrl = useInstitutionUrl();
    const history = useHistory();
    const allowContactDetailsDisplay = showContactDetailsHint;
    const conversationSetMarked = useConversationSetMarked();
    const { unsnoozeConversation } = useConversationUnsnooze();
    const snoozeConversationVisibilityHandler = useVisible();

    const { data, loading } = useQuery<
      Pick<Query, 'conversation'>,
      QueryConversationArgs
    >(CONVERSATION, {
      variables: {
        id: conversationId,
      },
    });

    const conversationSetClosed = useConversationSetClosed();

    if (loading) {
      return (
        <ContactInteractionListHeaderSkeleton
          loading
          paragraph={{ rows: 0 }}
          title
          active
          round
        />
      );
    }

    if (!data?.conversation) {
      return null;
    }

    const { conversation } = data;
    const { contact, isOpen, isStarted, isMarked, isSnoozed } = conversation;
    const contactId = contact.id;

    return (
      <Header data-lgg-id="contact-interaction-list-header">
        {!onClose && breakpointDownLg && (
          <BackIcon
            onClick={() => history.push(`${institutionUrl}conversations`)}
            type="iconBackTop"
          />
        )}
        <ContactNameContainer
          clickable={allowContactDetailsDisplay}
          onClick={
            allowContactDetailsDisplay && !onClose
              ? () => setShowContactInformation(true)
              : undefined
          }
        >
          <ContactName data-lgg-id="contact-interaction-list-header-contact-label">
            {contact.label}
          </ContactName>
          {allowContactDetailsDisplay && (
            <ContactNameHint>
              {t('conversations:interactionsList.header.viewContactDetails')}
            </ContactNameHint>
          )}
        </ContactNameContainer>
        <Expand />
        <HeaderOptionsContainer>
          <HeaderOptionTooltip
            title={
              isMarked
                ? t('conversations:conversationOptions.unmark')
                : t('conversations:conversationOptions.mark')
            }
          >
            <MarkConversationHeaderOptionIcon
              data-lgg-id="change-conversation-marked-status-option"
              type={isMarked ? 'mark' : 'unmarkConversation'}
              $isMarked={isMarked}
              onClick={() => {
                void conversationSetMarked({
                  conversationId,
                  isMarked: !isMarked,
                });
              }}
            />
          </HeaderOptionTooltip>
          <HeaderOptionTooltip
            title={
              isSnoozed
                ? t('conversations:snoozedConversationBanner.discard')
                : t('conversations:conversationOptions.snooze')
            }
          >
            <HeaderOptionIcon
              data-lgg-id="change-conversation-snooze-status-option"
              type={isSnoozed ? 'discardSnooze' : 'snooze'}
              onClick={async () => {
                if (isSnoozed) {
                  await unsnoozeConversation({
                    variables: {
                      conversationId: conversationId,
                    },
                  });
                } else {
                  snoozeConversationVisibilityHandler.show();
                }
              }}
            />
          </HeaderOptionTooltip>
          <SnoozeConversationModal
            zIndex={ModalsZIndex}
            visible={snoozeConversationVisibilityHandler.visible}
            onClose={snoozeConversationVisibilityHandler.close}
            conversationId={conversationId}
          />
          <HeaderOptionsSeparator />
          {contact.primaryPhone && (
            <StyledOpenLegacyCallModalIcon
              contact={contact}
              data-lgg-id="contact-interaction-list-header-phone-call"
            />
          )}
          <ContactAddNewDropdownWithTitle contactId={contactId} />
          {isStarted && (
            <>
              <ChangeConversationOpenStatusButton
                isOpen={isOpen}
                onClick={async () => {
                  await conversationSetClosed({
                    conversationId: conversation.id,
                    isClosed: isOpen,
                  });
                }}
              />
              <ConversationMoreOptionsDropdown
                conversation={conversation}
                addMarkAndSnoozeOptions={false}
              />
            </>
          )}
          {onClose && <CloseIcon onClick={onClose} type="close" />}
        </HeaderOptionsContainer>
      </Header>
    );
  },
);

type ContactAddNewDropdownWithTitleProps = {
  contactId: Contact['id'];
};

export const ContactAddNewDropdownWithTitle = memo<ContactAddNewDropdownWithTitleProps>(
  ({ contactId }) => {
    const { t } = useTranslation(['common']);
    const options = useContactAddNewOptions({ contactId });
    const { visible, close, setVisible } = useVisible();

    if (!options) {
      return null;
    }

    return (
      <Dropdown
        overlayClassName="context-menu contact-actions-dropdown"
        trigger={['click']}
        overlay={
          <AddNewContextMenu
            header={
              <ContactAddNewDropdownTitle>
                {t('common:actions.addNew')}:
              </ContactAddNewDropdownTitle>
            }
            groups={[
              (options ?? [])?.map((option) => {
                const testId = option['data-lgg-id'] ?? '';

                return {
                  ...option,
                  testId,
                  key: testId,
                  onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
                    option.onClick?.(e);
                    close();
                  },
                };
              }),
            ]}
          />
        }
        visible={visible}
        onVisibleChange={setVisible}
        placement="bottomRight"
        align={{
          offset: [8, 11],
        }}
      >
        <ContactActionsIcon
          type="contactActions"
          lggTestId="contact-interaction-actions"
        />
      </Dropdown>
    );
  },
);

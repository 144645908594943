import React, { memo, ReactElement, useEffect, useLayoutEffect, useRef } from 'react';
import {
  matchPath,
  Route,
  RouteProps,
  Switch,
  SwitchProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Location } from 'history';
import { LoadingIndicator } from 'src/components/loading-fallback';

export type CustomLocationState = {
  background: Location<CustomLocationState> | null;
  modalRoutes: Location<CustomLocationState>[];
  modalDefaultBackground?: boolean;
};

type UseModalRoutesProps = {
  routeProps: RouteProps[];
};

const areLocationsDifferentByKeys = (params: {
  locationA: Location | null;
  locationB: Location | null;
  keys: ReadonlyArray<'pathname' | 'hash' | 'search'>;
}) => {
  const { locationA, locationB, keys } = params;

  for (const key of keys) {
    if (locationA?.[key] !== locationB?.[key]) {
      return true;
    }
  }

  return false;
};

const useModalRoutes = ({ routeProps }: UseModalRoutesProps) => {
  const history = useHistory<CustomLocationState>();
  const currentLocation = history.location;
  const prevLocationRef = useRef(currentLocation);

  // We need to keep this previous route ref updated while the user is navigating and not
  // hitting a modal route, so we do not end up with a previous route that is just a segment of the
  // desired route, like `i/company/1`, when the user navigates to `i/company/1`, it would be redirected
  // to `i/company/1/activity|conversations` depending on roles, without this code, previous route would stay as
  // `i/company/1` and because of that, the modal routes would not work as expected
  useLayoutEffect(() => {
    const isModalRoute = routeProps.some((routeProp) =>
      matchPath(currentLocation.pathname, routeProp),
    );

    if (!isModalRoute) {
      prevLocationRef.current = currentLocation;
    }
  }, [currentLocation, routeProps]);

  useLayoutEffect(() => {
    const modalUrlListener = (location, action) => {
      const isModalRoute = routeProps.some((routeProp) =>
        matchPath(location.pathname, routeProp),
      );

      if (!location.state) {
        location.state = { modalRoutes: [], background: null };
      }

      if (isModalRoute) {
        const otherModals = prevLocationRef.current.state?.modalRoutes ?? [];

        const pushModalLocation = () => {
          const locationWithoutState = { ...location, state: null };
          otherModals.push(locationWithoutState);
        };

        if (location.state) {
          let background =
            (location.state as CustomLocationState)?.background ||
            prevLocationRef.current.state?.background;

          if (!background && action === 'PUSH') {
            background = prevLocationRef.current;
          }

          if (background) {
            (location.state as CustomLocationState).background = background;
          }

          const previousModal = otherModals[otherModals.length - 2];

          if (
            !areLocationsDifferentByKeys({
              locationA: previousModal,
              locationB: location,
              keys: ['pathname'],
            })
          ) {
            otherModals.pop();

            if (
              areLocationsDifferentByKeys({
                locationA: previousModal,
                locationB: location,
                keys: ['search', 'hash'],
              })
            ) {
              pushModalLocation();
            }
          } else if (action === 'REPLACE' && otherModals.length !== 0) {
            otherModals.pop();

            // Handles the case where we replace to the same modal but with different search or hash
            // e.g. /modal/1?search=1 -> /modal/1?search=2
            if (
              !areLocationsDifferentByKeys({
                locationA: prevLocationRef.current,
                locationB: location,
                keys: ['pathname'],
              })
            ) {
              pushModalLocation();
            } else {
              pushModalLocation();
            }
          }
          // Prevent adding the same modal twice in a row, only add it if the push
          // route is different from the previous one, taking into account pathname, search and hash
          else if (
            areLocationsDifferentByKeys({
              locationA: prevLocationRef.current,
              locationB: location,
              keys: ['pathname', 'search', 'hash'],
            })
          ) {
            pushModalLocation();
          }
          // If there is no modals added, add the new one
          else if (otherModals.length === 0) {
            pushModalLocation();
          }

          (location.state as CustomLocationState).modalRoutes = otherModals;
        }
      } else if (location.state && (location.state as CustomLocationState).background) {
        (location.state as CustomLocationState).background = null;
      }

      prevLocationRef.current = location as Location<CustomLocationState>;

      return;
    };

    return history.block(modalUrlListener);
  }, [currentLocation, history, routeProps]);
};

const ModalsSwitch = memo<SwitchProps>(({ children }) => {
  const routeProps: UseModalRoutesProps['routeProps'] = [];

  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) return;

    routeProps.push({ ...element.props });
  });

  useModalRoutes({ routeProps });
  const history = useHistory<CustomLocationState>();

  // Need to call this hook to listen to new URL changes
  useLocation();

  const effectiveModalRoutes = [
    ...(history.location.state?.modalRoutes.slice(0, -1) ?? []),
    history.location,
  ];

  return (
    <>
      {effectiveModalRoutes.map((modal) => {
        let switchKey: string;

        // Find the modal route that matches the current route pathname
        const modalRoute = routeProps.find((routeProp) =>
          matchPath(modal.pathname, routeProp),
        );

        // If the route does not belong to any modal, use the pathname as the key
        if (!modalRoute?.path) {
          switchKey = modal.pathname;
        }
        // Otherwise, stringify the route path as the key, so we can have a unique key for each modal
        // even when the path is an array of multiple paths, with different params
        // e.g. ['/modal/contact/1', '/modal/contact/2/tasks'].
        // Doing this we avoid unmounting the modal when navigating between different routes
        // that belong to the same modal
        else {
          switchKey = JSON.stringify(modalRoute.path);
        }

        return (
          <Switch key={switchKey} location={modal}>
            {children}
          </Switch>
        );
      })}
    </>
  );
});

type ModalRouteProps = RouteProps;

export const ModalRoute = memo<ModalRouteProps>((props) => {
  return <Route {...props} />;
});

export const PreviousLocationContext = React.createContext<Location | null>(null);

const usePreviousLocation = () => {
  const location = useLocation();
  const previousLocation = useRef<Location>();

  useEffect(() => {
    previousLocation.current = location;
  }, [location]);

  return previousLocation.current;
};

export const LggSwitch = memo<SwitchProps>(({ children }) => {
  const previousLocation = usePreviousLocation();
  const modalRoutes: ReactElement[] = [];
  const otherChildren: ReactElement[] = [];
  let modalPaths: string[] = [];

  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) return;

    if (element.type === ModalRoute) {
      const path = (element.props as RouteProps).path;

      if (typeof path === 'string') {
        modalPaths.push(path);
      } else if (path) {
        modalPaths = modalPaths.concat(path);
      }

      modalRoutes.push(element);
    } else {
      otherChildren.push(element);
    }
  });

  return (
    <PreviousLocationContext.Provider value={previousLocation}>
      <Switch>
        <Route path={modalPaths} render={() => <LoadingIndicator />} />
        {otherChildren}
      </Switch>
      <ModalsSwitch>{modalRoutes}</ModalsSwitch>
    </PreviousLocationContext.Provider>
  );
});

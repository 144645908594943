import React, { memo, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { ContactInteractionFacebookMessenger } from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionFacebookMessengerExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { TextItem } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';

type FacebookMessengerItemProps = {
  contactInteraction: ContactInteractionFacebookMessenger;
  conversationId: string;
};

export const FacebookMessengerItem = memo<FacebookMessengerItemProps>(
  ({ contactInteraction, conversationId }) => {
    const buildManualInteractionTitle = (): ReactElement => {
      const transComponents = { strong: <strong /> };

      return matchContactInteractionFacebookMessengerExhaustive(contactInteraction, {
        inboundWithParticipatingEntity: ({ participatingEntity }) => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.facebookMessenger.inboundWithParticipatingEntity"
            components={transComponents}
            values={{ participatingEntityLabel: participatingEntity.label }}
          />
        ),
        inboundWithoutParticipatingEntity: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.facebookMessenger.inboundWithoutParticipatingEntity"
            components={transComponents}
          />
        ),
        outbound: () => (
          <Trans
            i18nKey="conversations:contactInteractionBubble.facebookMessenger.outbound"
            components={transComponents}
          />
        ),
      });
    };

    return (
      <TextItem
        conversationId={conversationId}
        testId="contact-interaction-facebook-messenger"
        contactInteraction={contactInteraction}
        titleBuilder={buildManualInteractionTitle}
      />
    );
  },
);

import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { BaseBadge } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/components/base-badge';

const CallAnalysisBadgeStrongText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
`;

const CallAnalysisBadgeContainer = styled(BaseBadge)<{
  $type: 'positive' | 'negative' | 'neutral';
}>`
  cursor: pointer;

  ${({ $type, theme }) =>
    $type === 'positive' &&
    `
  background-color: #f5fdf8;
  border-color: #c6ebd8;

  .badge-icon {
    svg path {
      fill: #7ec69f;
    }
  }

  .badge-content {
    ${CallAnalysisBadgeStrongText} {
      color: ${theme.colors.secondaryMintDark};
    }
  }
`};

  ${({ $type, theme }) =>
    $type === 'negative' &&
    `
    background-color: #fff7f8;
    border-color: #fee3e4;

    .badge-icon  {
      svg path {
        fill: ${theme.colors.secondaryCoralDark};
      }
    }

    .badge-content {
      ${CallAnalysisBadgeStrongText} {
        color: ${theme.colors.secondaryCoralDark};
      }
    
  }`};
`;

type CallAnalysisBadgeProps = {
  icon: string;
  title: string;
  type: 'positive' | 'negative' | 'neutral';
  inProgress?: boolean;
  onClick?: VoidFunction;
  'data-lgg-id'?: string;
};

export const CallAnalysisBadge = ({
  icon,
  title,
  type,
  inProgress = false,
  ...rest
}: CallAnalysisBadgeProps) => {
  const badgeContent = useMemo(() => {
    if (inProgress) {
      return (
        <Trans
          i18nKey="conversations:contactInteractionBubble.phoneCall.aiCallAnalysis.analyzing"
          values={{
            action: title,
          }}
          components={{
            strong: <CallAnalysisBadgeStrongText />,
          }}
        />
      );
    }

    return <CallAnalysisBadgeStrongText>{title}</CallAnalysisBadgeStrongText>;
  }, [inProgress, title]);

  return (
    <CallAnalysisBadgeContainer
      $type={type}
      icon={icon}
      badgeContent={badgeContent}
      {...rest}
    />
  );
};

import React, { memo } from 'react';
import { PageInfo } from '@lgg/isomorphic/types/__generated__/graphql';
import { TablePagination } from 'src/components/general/display/table-pagination';

type GraphqlTablePaginationProps = {
  nextPageHandler: (params: { endCursor: string }) => void;
  previousPageHandler: (params: { startCursor: string }) => void;
  pageInfo: PageInfo;
};

export const GraphqlTablePagination = memo<GraphqlTablePaginationProps>(
  ({ nextPageHandler, previousPageHandler, pageInfo, ...rest }) => {
    const { hasNextPage, hasPreviousPage, endCursor, startCursor } = pageInfo;

    return (
      <TablePagination
        {...rest}
        onLoadPreviousPage={() => startCursor && previousPageHandler({ startCursor })}
        onLoadNextPage={() => endCursor && nextPageHandler({ endCursor })}
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
      />
    );
  },
);

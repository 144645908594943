import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import c from 'classnames';
import { useLottie } from 'lottie-react';
import { up } from 'styled-breakpoints';
import {
  NotificationFeedItemUnion,
  Query,
  QueryNotificationFeedItemsArgs,
  NotificationFeedItemWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import animatedBell from 'src/assets/animations/animated_bell.json';
import { sortNotificationFeedItems } from 'src/components/domain/notifications/helpers/sort-notification-feed-items';
import {
  useNotificationSetClearedStatus,
  useNotificationSetStatus,
} from 'src/components/domain/notifications/hooks/use-notification-set-status';
import {
  NotificationsTabs,
  NotificationTabKeys,
} from 'src/components/domain/notifications/notifications-button/notification-tabs';
import {
  GET_NOTIFICATION_FEED_ITEMS,
  NOTIFICATION_FEED_ITEMS_QUERY_PAGE_SIZE,
} from 'src/components/domain/notifications/notifications-button/notifications-button-queries';
import {
  NotificationBadge,
  NotificationIconContainer,
  ExtraMobileHeaderIcon,
  ExtraDesktopHeaderIcon,
} from 'src/components/domain/notifications/notifications-button/notifications-button-styles';
import { PushNotificationBanner } from 'src/components/domain/notifications/notifications-button/push-notification-banner';
import { DoNotDisturbBottomDrawer } from 'src/components/domain/notifications/push-notification-dnd-bottom-drawer';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import {
  OptionsBottomDrawer,
  OptionsBottomDrawerProps,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { RightDrawer } from 'src/components/pages/conversations/components/drawer/right-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';
import { useVisible } from 'src/hooks/use-visible';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

export const NotificationsButton = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const {
    visible: isNotificationDrawerVisible,
    show: openNotificationDrawer,
    close: closeNotificationDrawer,
  } = useVisible();
  const doNotDisturbVisibilityHandler = useVisible();
  const extraContentVisibilityHandler = useVisible();
  const { t } = useTranslation(['common', 'notifications']);
  const notificationSetClearedStatus = useNotificationSetClearedStatus();
  const handleGraphQLError = useHandleGraphQLError();
  const title = t('common:notifications');
  const notificationIconSize = breakpointUpMd ? '20px' : '22px';
  const notificationSetStatus = useNotificationSetStatus();
  const [activeNotificationTabKey, setActiveNotificationTabKey] = useState(
    NotificationTabKeys.New,
  );

  const {
    View: notificationIcon,
    getDuration: getNotificationIconAnimationDuration,
    goToAndPlay: goToAndPlayNotificationIconAnimation,
  } = useLottie(
    {
      animationData: animatedBell,
      loop: false,
      autoplay: false,
    },
    {
      width: notificationIconSize,
      height: notificationIconSize,
    },
  );

  const createQueryOptions = useCallback(
    (where: NotificationFeedItemWhereInput) => {
      return {
        queryOptions: {
          getNodeIdCallback: (notification) => notification?.id,
          query: GET_NOTIFICATION_FEED_ITEMS,
          variables: {
            first: NOTIFICATION_FEED_ITEMS_QUERY_PAGE_SIZE,
            where,
          },
          getEdgesCallback: (data) => data?.notificationFeedItems.edges ?? [],
          getPageInfoCallback: (data) => data?.notificationFeedItems.pageInfo,
          onError: handleGraphQLError,
          queryPageSize: NOTIFICATION_FEED_ITEMS_QUERY_PAGE_SIZE,
          sortNodesCallback: sortNotificationFeedItems,
        },
        pollingOptions: {
          interval: 30000,
        },
      };
    },
    [handleGraphQLError],
  );

  const notificationsQuery = useInfiniteListQuery<
    Pick<Query, 'notificationFeedItems'>,
    Partial<QueryNotificationFeedItemsArgs>,
    NotificationFeedItemUnion
  >({
    ...createQueryOptions({
      status: {
        _ne: 'CLEARED',
      },
    }),
  });

  const { hasNewItem: hasNewNotification, setHasNewItem: setHasNewNotification } =
    notificationsQuery;

  useEffect(() => {
    if (hasNewNotification) {
      goToAndPlayNotificationIconAnimation(0);
      // The purpose of the timeout is to wait for the
      // bell animation to complete.
      const notificationIconAnimationTimeout = setTimeout(() => {
        setHasNewNotification(false);
      }, getNotificationIconAnimationDuration());

      return () => {
        clearTimeout(notificationIconAnimationTimeout);
      };
    }
  }, [
    getNotificationIconAnimationDuration,
    hasNewNotification,
    setHasNewNotification,
    goToAndPlayNotificationIconAnimation,
  ]);

  const clearedNotificationsQuery = useInfiniteListQuery<
    Pick<Query, 'notificationFeedItems'>,
    Partial<QueryNotificationFeedItemsArgs>,
    NotificationFeedItemUnion
  >(
    createQueryOptions({
      status: {
        _eq: 'CLEARED',
      },
    }),
  );

  const handleNotificationUpdated = useCallback(
    async (notification: NotificationFeedItemUnion) => {
      const whereClause: NotificationFeedItemWhereInput = {
        id: {
          _eq: `${notification.id}`,
        },
      };

      await notificationsQuery.fetchMore({
        variables: {
          where: {
            ...whereClause,
            status: {
              _ne: 'CLEARED',
            },
          },
        },
      });

      await clearedNotificationsQuery.fetchMore({
        variables: {
          where: {
            ...whereClause,
            status: {
              _eq: 'CLEARED',
            },
          },
        },
      });
    },
    [clearedNotificationsQuery, notificationsQuery],
  );

  const notifications = useMemo(
    () => getNodesFromConnection(notificationsQuery.data?.notificationFeedItems),
    [notificationsQuery.data],
  );

  const clearedNotifications = useMemo(
    () => getNodesFromConnection(clearedNotificationsQuery.data?.notificationFeedItems),
    [clearedNotificationsQuery.data],
  );

  const extraContentOptions = useMemo(() => {
    const options: OptionsBottomDrawerProps['options'] = [];

    if (Capacitor.isNativePlatform()) {
      options.push({
        icon: 'doNotDisturb',
        label: t('notifications:globalOptions.doNotDisturb.title'),
        'data-lgg-id': 'notifications-global-option-do-not-disturb',
        onClick: doNotDisturbVisibilityHandler.show,
      });
    }

    options.push({
      icon: 'doubleTick',
      label: t('notifications:globalOptions.clearAll.title'),
      'data-lgg-id': 'notifications-global-option-clear-all',
      onClick: async () => {
        if (notifications.length > 0) {
          await notificationSetClearedStatus({
            isCleared: true,
          });

          await clearedNotificationsQuery.refetch();
          await notificationsQuery.refetch();
        }
      },
    });

    return options;
  }, [
    clearedNotificationsQuery,
    doNotDisturbVisibilityHandler.show,
    notificationSetClearedStatus,
    notifications.length,
    notificationsQuery,
    t,
  ]);

  const headerExtraContent = breakpointUpMd ? (
    <LggOptionsDropdownButtonWithCustomTrigger
      options={extraContentOptions}
      visibilityHandler={extraContentVisibilityHandler}
      customTrigger={
        <ExtraDesktopHeaderIcon
          type="moreOptions"
          lggTestId="notification-global-options-dropdown-trigger"
        />
      }
    />
  ) : (
    <>
      <ExtraMobileHeaderIcon
        type="moreOptions"
        onClick={() =>
          extraContentVisibilityHandler.setVisible(!extraContentVisibilityHandler.visible)
        }
      />
      <OptionsBottomDrawer
        visible={extraContentVisibilityHandler.visible}
        title={t('common:options')}
        onClose={extraContentVisibilityHandler.close}
        options={extraContentOptions}
      />
      <DoNotDisturbBottomDrawer visibilityHandler={doNotDisturbVisibilityHandler} />
    </>
  );

  const notificationsIds = notifications.map((n) => n.id);
  const unSeenNotifications = notifications.filter((n) => n.status === 'NEW');
  const unSeenNotificationsIds = unSeenNotifications.map((n) => n.id);
  const unSeenNotificationsCount = unSeenNotifications.length;
  const interactedWithNotificationIds = notifications
    .filter((n) => n.status === 'INTERACTED')
    .map((n) => n.id);
  const hasUnInteractedWithNotifications =
    interactedWithNotificationIds.length !== notificationsIds.length;

  const showDot =
    unSeenNotificationsCount === 0 ? hasUnInteractedWithNotifications : false;

  const badgeOffset: [number, number] = useMemo(() => {
    if (showDot) {
      return breakpointUpMd ? [-3, 1] : [-4, 2];
    } else {
      return breakpointUpMd ? [1, 1] : [-2, 1];
    }
  }, [breakpointUpMd, showDot]);

  const onNotificationTabClick = useCallback(
    (key) => setActiveNotificationTabKey(key),
    [],
  );

  const notificationsTabs = useMemo(() => {
    return (
      <NotificationsTabs
        areNotificationsVisible={isNotificationDrawerVisible}
        hasNewNotification={hasNewNotification}
        newNotificationsFirstItemIndex={notificationsQuery.firstItemIndex}
        clearedNotificationsFirstItemIndex={clearedNotificationsQuery.firstItemIndex}
        newNotificationIsLoadingMore={notificationsQuery.loadingMoreBottom}
        clearedNotificationIsLoadingMore={clearedNotificationsQuery.loadingMoreBottom}
        handleNotificationUpdated={handleNotificationUpdated}
        newNotifications={notifications}
        clearedNotifications={clearedNotifications}
        newNotificationsOnLoadMore={notificationsQuery.handleLoadBottom}
        clearedNotificationsOnLoadMore={clearedNotificationsQuery.handleLoadBottom}
        onTabClick={onNotificationTabClick}
        activeTabKey={activeNotificationTabKey}
      />
    );
  }, [
    clearedNotifications,
    clearedNotificationsQuery.firstItemIndex,
    clearedNotificationsQuery.handleLoadBottom,
    clearedNotificationsQuery.loadingMoreBottom,
    handleNotificationUpdated,
    hasNewNotification,
    isNotificationDrawerVisible,
    notifications,
    notificationsQuery.firstItemIndex,
    notificationsQuery.handleLoadBottom,
    notificationsQuery.loadingMoreBottom,
    onNotificationTabClick,
    activeNotificationTabKey,
  ]);

  return (
    <>
      <NotificationBadge
        count={
          showDot ? 1 : unSeenNotificationsCount < 99 ? unSeenNotificationsCount : '+99'
        }
        offset={badgeOffset}
        showZero={false}
        dot={showDot}
        className={c({
          dot: showDot,
        })}
        data-lgg-id="user-notification-button"
        title="user-notification-count"
      >
        <NotificationIconContainer
          onClick={async () => {
            const updateSeenNotifications = async () => {
              setActiveNotificationTabKey(NotificationTabKeys.New);
              if (unSeenNotificationsCount > 0) {
                await notificationSetStatus({
                  notificationIds: [...unSeenNotificationsIds],
                  status: 'SEEN',
                });
                await notificationsQuery.fetchMore({
                  variables: {
                    where: {
                      id: {
                        _in: [...unSeenNotificationsIds],
                      },
                    },
                  },
                });
              }
            };

            void updateSeenNotifications();

            openNotificationDrawer();
          }}
        >
          {notificationIcon}
        </NotificationIconContainer>
      </NotificationBadge>
      {breakpointUpMd ? (
        <RightDrawer
          data-lgg-id="user-notification-drawer"
          title={title}
          visible={isNotificationDrawerVisible}
          onClose={closeNotificationDrawer}
          addScrollbarToBody={false}
          headerExtraContent={headerExtraContent}
        >
          {notificationsTabs}
        </RightDrawer>
      ) : (
        <BottomDrawer
          onClose={closeNotificationDrawer}
          title={title}
          fullHeight
          addScrollbarToBody={false}
          trailing={headerExtraContent}
          visible={isNotificationDrawerVisible}
        >
          <>
            {Capacitor.isNativePlatform() && (
              <PushNotificationBanner
                showDndBottomDrawer={doNotDisturbVisibilityHandler.show}
              />
            )}
            {notificationsTabs}
          </>
        </BottomDrawer>
      )}
    </>
  );
});

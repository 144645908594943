import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { Checkbox } from 'src/components/general/inputs/checkbox';
import { Expand } from 'src/components/layout/expand';
import { FlexRow } from 'src/components/layout/flex-row';
import { BulkOperationType } from 'src/components/pages/conversations/components/conversations/conversation-section/conversations-section';
import { SnoozeConversationModal } from 'src/components/pages/conversations/components/conversations/snooze-conversation-modal/snooze-conversation-modal';
import { AssigneesFilter } from 'src/components/pages/conversations/components/filters/assignees-filter';
import { ContactFilters } from 'src/components/pages/conversations/components/filters/contact-filters';
import { ConversationStatusFilter } from 'src/components/pages/conversations/components/filters/conversation-status-filter';
import { ConversationTextFilterInput } from 'src/components/pages/conversations/components/filters/shared';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { ConversationsFilters } from 'src/services/conversation.service';
import { slideDownAnimation, slideUpAnimation } from 'src/theme/animations';
import {
  disableAnimationsStyle,
  disableTransitionsStyle,
} from 'src/theme/globals/disable-transition-related-styles';

const StyledFiltersContainer = styled.div<{ animationsDisabled: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${({ animationsDisabled }) =>
    animationsDisabled &&
    `
  ${disableAnimationsStyle}
  ${disableTransitionsStyle}`}
  ${up('md')} {
    height: unset;
    padding: unset;
  }
`;

const TopSectionContainer = styled.span`
  width: 100%;

  ${up('md')} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  }
`;

const TopSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 20px;
  height: 78px;

  ${up('md')} {
    flex: unset;
    height: 64px;
    padding: 15px 20px;
  }
`;

const BottomSection = styled.div`
  display: flex;
  padding: 15px 20px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 65px;
`;

const BottomFiltersContainer = styled.div`
  ${slideUpAnimation};
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  border-radius: 4px;
  width: 100%;
  height: 34px;
`;

const BulkActionSelectAllCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;

    ${up('md')} {
      width: 20px;
      height: 20px;
    }

    &::after {
      left: 30%;
    }
  }

  .ant-checkbox {
    position: relative;
    top: 0;
  }

  span:not(.ant-checkbox) {
    display: flex;
    font-size: 13px;
    font-family: ${({ theme }) => theme.font.medium};
    height: 22px;
    align-items: center;
    line-height: normal;

    ${up('md')} {
      height: 20px;
    }
  }
`;

const EnableBulkActionModeButton = styled(IconButtonV2)`
  margin-left: 10px;
  height: 38px;
  width: 38px;

  ${up('md')} {
    width: 34px;
    height: 34px;
  }
`;

const DisableBulkActionModeButton = styled(ButtonV2)`
  padding: 10px;
  height: 38px;

  ${up('md')} {
    height: 34px;
  }

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const BulkActionOption = styled(IconButtonV2)`
  width: 34px;
  height: 34px;
  background: ${({ theme }) => theme.colors.koala};

  &:hover {
    background: ${({ theme }) => theme.colors.koala};

    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }

  ${up('md')} {
    width: 26px;
    height: 26px;
  }

  svg {
    width: 18px;
    height: 18px;

    ${up('md')} {
      width: 14px;
      height: 14px;
    }
  }
`;

const SelectedBulkActionItemsDescription = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 15px;
`;

const BulkActionMoreOptionsIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding-left: 10px;

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  ${up('md')} {
    height: 26px;
    padding: 0 8px;
  }
`;

const BulkSelectionOptionsContainer = styled(FlexRow)`
  ${slideDownAnimation};
  align-items: center;
  width: 100%;
`;

const BulkOptionsContainer = styled(TopSection)`
  ${slideDownAnimation}
`;

const FilterOptionsContainer = styled(TopSection)`
  ${slideUpAnimation}
`;

const FilterActionsContainer = styled(FlexRow)`
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const useDebouncedChangeKeyboardFilterHandler = (
  keywordChangeHandler: (props: { keyword: string }) => void,
) => {
  const debouncedChangeKeywordFilterHandler = useMemo(
    () =>
      debounce((keyword: string) => {
        keywordChangeHandler({ keyword });
      }, 300),
    [keywordChangeHandler],
  );

  useEffect(
    () => () => {
      debouncedChangeKeywordFilterHandler.cancel();
    },
    [debouncedChangeKeywordFilterHandler],
  );

  return debouncedChangeKeywordFilterHandler;
};

type FiltersContainerProps = {
  filters: ConversationsFilters;
  onFiltersChange: ValueChanged<Partial<ConversationsFilters>>;
  queryTotalCount?: number;
  bulkOperationResolver: (type: BulkOperationType, date?: Date) => VoidFunction;
};

export const FiltersContainer = memo<FiltersContainerProps>(
  ({ filters, queryTotalCount, onFiltersChange, bulkOperationResolver }) => {
    const { t } = useTranslation(['conversations', 'common']);
    const breakpointUpMd = useBreakpoint(up('md'));
    const bulkDropdownVisibilityHandler = useVisible();
    const {
      toggleBulkSelectionMode,
      bulkSelectionEnabled,
      bulkSelectionMap,
      bulkSelectAllEnabled,
      toggleBulkSelectAllMode,
    } = useConversation();
    const [keywordInputValue, setKeywordInputValue] = useState<string>(
      filters.keyword ?? '',
    );

    const {
      visible: isBulkSnoozeConversationModalVisible,
      setVisible: setBulkSnoozeConversationModalVisibility,
    } = useVisible();

    const debouncedChangeKeywordFilterHandler =
      useDebouncedChangeKeyboardFilterHandler(onFiltersChange);

    const handleClearKeywordFilter = () => {
      setKeywordInputValue('');
      onFiltersChange({ keyword: '' });
    };

    const [animationsDisabled, setAnimationsDisabled] = useState(true);

    const selectedConversationsCount = bulkSelectAllEnabled
      ? queryTotalCount || 0
      : bulkSelectionMap.size;

    const disableBulkOptions = selectedConversationsCount === 0;

    const handleKeywordFilterChange = useMemo(
      () => (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.value;

        setKeywordInputValue(keyword);
        debouncedChangeKeywordFilterHandler(keyword);
      },
      [debouncedChangeKeywordFilterHandler],
    );

    useEffect(() => {
      if (animationsDisabled && (bulkSelectionEnabled || bulkSelectAllEnabled)) {
        setAnimationsDisabled(false);
      }
    }, [animationsDisabled, bulkSelectAllEnabled, bulkSelectionEnabled]);

    const bulkOptions = [
      {
        icon: 'chatOpen',
        label: t('conversations:conversationsBulkActions.markAsOpen'),
        'data-lgg-id': 'conversation-bulk-open-option',
        onClick: () => {
          if (!disableBulkOptions) {
            bulkOperationResolver('open')();
          }
        },
      },
      {
        icon: 'discardSnooze',
        label: t('conversations:conversationsBulkActions.discardSnooze'),
        'data-lgg-id': 'conversation-bulk-discard-snooze-option',
        onClick: () => {
          if (!disableBulkOptions) {
            bulkOperationResolver('discardSnooze')();
          }
        },
      },
      {
        icon: 'unmark',
        label: t('conversations:conversationsBulkActions.unmark'),
        'data-lgg-id': 'conversation-bulk-unmark-option',
        onClick: () => {
          if (!disableBulkOptions) {
            bulkOperationResolver('unmark')();
          }
        },
      },
    ];

    const customTrigger = (
      <BulkActionMoreOptionsIcon
        className={disableBulkOptions ? 'disabled' : ''}
        type="moreOptions"
        lggTestId="conversation-bulk-more-options-trigger"
      />
    );

    const bulkOptionsDropDown = breakpointUpMd ? (
      <LggOptionsDropdownButtonWithCustomTrigger
        options={bulkOptions}
        customDropdownProps={{ disabled: disableBulkOptions, placement: 'bottomRight' }}
        customTrigger={customTrigger}
        visibilityHandler={bulkDropdownVisibilityHandler}
      />
    ) : (
      <>
        <div
          onClick={() => {
            if (disableBulkOptions) {
              return;
            }
            bulkDropdownVisibilityHandler.setVisible(
              !bulkDropdownVisibilityHandler.visible,
            );
          }}
        >
          {customTrigger}
        </div>
        <OptionsBottomDrawer
          visible={bulkDropdownVisibilityHandler.visible}
          title={t('common:moreOptions')}
          onClose={bulkDropdownVisibilityHandler.close}
          options={bulkOptions}
        />
      </>
    );

    return (
      <StyledFiltersContainer animationsDisabled={animationsDisabled}>
        <TopSectionContainer>
          {bulkSelectionEnabled ? (
            <BulkOptionsContainer>
              <BulkActionSelectAllCheckbox
                data-lgg-id="conversation-bulk-select-all-checkbox"
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  toggleBulkSelectAllMode(isChecked);
                }}
                checked={bulkSelectAllEnabled}
              >
                {t('common:selectAll')}
              </BulkActionSelectAllCheckbox>
              <Expand />
              <DisableBulkActionModeButton
                data-lgg-id="conversation-disable-bulk-selection-button"
                type="button"
                icon="close"
                variant={breakpointUpMd ? 'defaultGhost' : 'defaultWhite'}
                size="small"
                onClick={toggleBulkSelectionMode}
              >
                {t('common:cancel')}
              </DisableBulkActionModeButton>
            </BulkOptionsContainer>
          ) : (
            <FilterOptionsContainer>
              <ConversationStatusFilter
                selectedConversationStatus={filters.conversationStatus}
                onApply={onFiltersChange}
              />
              <Expand />
              <AssigneesFilter
                selectedAssignees={filters.assignees}
                selectedAssigneeType={filters.assigneeType as string}
                onApply={onFiltersChange}
              />
              <EnableBulkActionModeButton
                data-lgg-id="conversation-enable-bulk-selection-button"
                type="button"
                icon="bulkActions"
                variant={breakpointUpMd ? 'defaultGhost' : 'defaultWhite'}
                size="small"
                onClick={toggleBulkSelectionMode}
                tooltipText={t('common:selectMultiple')}
              />
            </FilterOptionsContainer>
          )}
        </TopSectionContainer>
        <BottomSection>
          {bulkSelectionEnabled ? (
            <BulkSelectionOptionsContainer>
              <SelectedBulkActionItemsDescription data-lgg-id="conversation-bulk-selected-items-description">
                {`${selectedConversationsCount?.toLocaleString()} ${
                  breakpointUpMd
                    ? t(
                        'conversations:conversationsBulkActions.conversationsSelected.desktop',
                      )
                    : t(
                        'conversations:conversationsBulkActions.conversationsSelected.mobile',
                      )
                }`}
              </SelectedBulkActionItemsDescription>
              <Expand />
              <FilterActionsContainer>
                <BulkActionOption
                  tooltipText={t('conversations:conversationsBulkActions.markAsClose')}
                  data-lgg-id="conversation-bulk-close-option"
                  disabled={disableBulkOptions}
                  type="button"
                  icon="circularCheck"
                  variant="default"
                  size="small"
                  onClick={() => {
                    bulkOperationResolver('close')();
                  }}
                />

                <BulkActionOption
                  tooltipText={t('conversations:conversationsBulkActions.snooze')}
                  data-lgg-id="conversation-bulk-snooze-option"
                  disabled={disableBulkOptions}
                  type="button"
                  icon="time"
                  variant="default"
                  size="small"
                  onClick={() => {
                    setBulkSnoozeConversationModalVisibility(true);
                  }}
                />
                <BulkActionOption
                  tooltipText={t(
                    'conversations:conversationsBulkActions.markConversation',
                  )}
                  data-lgg-id="conversation-bulk-mark-option"
                  disabled={disableBulkOptions}
                  type="button"
                  icon="star"
                  variant="default"
                  size="small"
                  onClick={() => {
                    bulkOperationResolver('mark')();
                  }}
                />
                {bulkOptionsDropDown}
              </FilterActionsContainer>
              <SnoozeConversationModal
                visible={isBulkSnoozeConversationModalVisible}
                onSetDate={(date) => {
                  setBulkSnoozeConversationModalVisibility(false);
                  bulkOperationResolver('snooze', date)();
                }}
                onClose={() => {
                  setBulkSnoozeConversationModalVisibility(false);
                }}
              />
            </BulkSelectionOptionsContainer>
          ) : (
            <BottomFiltersContainer data-lgg-id="conversation-keyword-contact-filters-container">
              <ConversationTextFilterInput
                data-lgg-id="conversation-keyword-filter-input"
                onChange={handleKeywordFilterChange}
                value={keywordInputValue}
                placeholder={t('conversations:search.searchConversation')}
                leadingIcon="magnifyingGlass"
                onClear={handleClearKeywordFilter}
              />
              <ContactFilters
                selectedStages={filters.contactStagesIds}
                selectedStatuses={filters.contactStatusesIds}
                selectedChannels={filters.channelsSlugs}
                selectedContactTags={filters.contactTagsIds}
                selectedDepartmentIds={filters.departmentIds}
                selectedCampaignIds={filters.contactCampaignsIds}
                onApply={onFiltersChange}
              />
            </BottomFiltersContainer>
          )}
        </BottomSection>
      </StyledFiltersContainer>
    );
  },
);

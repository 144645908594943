import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Mutation,
  MutationCallContactArgs,
  Query,
  QueryContactArgs,
  QueryResourcesForCallingContactArgs,
  QueryUserArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const GET_CONTACT_FOR_CALL_MODAL = gql`
  query GetContactForCallModal($id: Int!) {
    contact(id: $id) {
      id
      createdAt
      firstName
      fullName
      interest
      label
      lastName
      primaryEmail
      updatedAt
      lastContactInteraction {
        id
        occurredAt
      }
      lastConversation {
        id
      }
      primaryPhone {
        national
        e164
      }
      secondaryPhone {
        national
        e164
      }
      stage {
        id
        name
        slug
      }
      status {
        id
        name
      }
      tags {
        id
        name
        isActive
      }
      assignee {
        id
        fullName
        avatar {
          color
          initials
        }
      }
    }
  }
`;

type UseGetContactForCallModalInput = {
  contactId: string;
  goBack: VoidFunction;
};

export const useGetContactForCallModal = ({
  contactId,
  goBack,
}: UseGetContactForCallModalInput) => {
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();
  const { t } = useTranslation(['errors']);

  return useQuery<Pick<Query, 'contact'>, QueryContactArgs>(GET_CONTACT_FOR_CALL_MODAL, {
    variables: {
      id: parseInt(contactId),
    },
    onError: (error) => {
      const notFoundError = error.graphQLErrors?.find((error) => {
        const code = error.extensions?.code;

        return code?.endsWith(':NOT_FOUND');
      });

      if (notFoundError) {
        showNotification({
          type: 'warning',
          title: t('errors:resourceNotAvailable.contact.title'),
          message: t('errors:resourceNotAvailable.contact.message'),
        });
      } else {
        handleGraphQLError(error);
      }

      goBack();
    },
  });
};

const GET_USER_FOR_CALL_MODAL = gql`
  query GetUserForCallModal($id: ID!) {
    user(id: $id) {
      id
      phone {
        national
      }
    }
  }
`;

type useGetUserForCallModalInput = {
  userId: number;
  goBack: VoidFunction;
};

export const useGetUserForCallModal = ({
  userId,
  goBack,
}: useGetUserForCallModalInput) => {
  const handleGraphQLError = useHandleGraphQLError();

  return useQuery<Pick<Query, 'user'>, QueryUserArgs>(GET_USER_FOR_CALL_MODAL, {
    variables: {
      id: userId.toString(),
    },
    onError: (error) => {
      handleGraphQLError(error);
      goBack();
    },
  });
};

const GET_RESOURCES_FOR_CALLING_CONTACT = gql`
  query GetResourcesForCallingContact($id: Int!) {
    resourcesForCallingContact(id: $id) {
      id
      lastUsedResourceId
      defaultResourceId
      resources {
        id
        address
        alias
        label
        campaign {
          id
          name
        }
        phoneNumber {
          national
        }
      }
    }
  }
`;

type UseGetResourcesForCallingContactInput = {
  skip: boolean;
  contactId: string;
};

export const useGetResourcesForCallingContact = ({
  skip,
  contactId,
}: UseGetResourcesForCallingContactInput) => {
  const handleGraphQLError = useHandleGraphQLError();

  return useQuery<
    Pick<Query, 'resourcesForCallingContact'>,
    QueryResourcesForCallingContactArgs
  >(GET_RESOURCES_FOR_CALLING_CONTACT, {
    skip: skip,
    variables: {
      id: parseInt(contactId),
    },
    fetchPolicy: 'network-only',
    onError: handleGraphQLError,
  });
};

const CALL_CONTACT_MUTATION = gql`
  mutation CallContact($input: CallContactInput!) {
    callContact(input: $input) {
      success
    }
  }
`;

export const useCallContactMutation = () => {
  return useMutation<Pick<Mutation, 'callContact'>, MutationCallContactArgs>(
    CALL_CONTACT_MUTATION,
  );
};

import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import {
  Mutation,
  MutationSetPushNotificationSnoozeArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { FlexColumn } from 'src/components/layout/flex-column';
import { usePushNotificationSnoozedUntil } from 'src/hooks/push-notifications/use-push-notification-snoozed-until';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { VisibilityHandler } from 'src/hooks/use-visible';
import { SNOOZED_UNTIL_DATE_FORMAT, useSnoozeUntilDates } from 'src/utils/snooze-utils';

const SET_PUSH_NOTIFICATION_SNOOZE = gql`
  mutation SetPushNotificationSnooze($date: DateTime) {
    setPushNotificationSnooze(date: $date) {
      user {
        id
        receiveMobileNotificationsAt
      }
    }
  }
`;

const StyledDoNotDisturbBottomDrawer = styled(OptionsBottomDrawer)`
  .option-icon-container svg path {
    fill: ${({ theme }) => theme.colors.gogo};
  }
`;

const ActiveSnoozedContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  height: 81.5px;
  margin-bottom: 10px;
  padding: 5px 20px 15px;
`;

const ActiveSnoozedInnerContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  height: 100%;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  padding: 10px 15px;
  text-align: left;
`;

const TurnOff = styled.span`
  color: ${({ theme }) => theme.colors.secondaryCoral};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
`;

const SnoozeDateLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
`;

type DoNotDisturbBottomDrawerProps = {
  visibilityHandler: VisibilityHandler;
};

export const DoNotDisturbBottomDrawer = memo<DoNotDisturbBottomDrawerProps>(
  ({ visibilityHandler }) => {
    const { t } = useTranslation(['notifications']);
    const snoozedUntil = usePushNotificationSnoozedUntil();
    const showNotification = useShowNotification();
    const { formatDate } = useFormatDate();
    const handleGraphQLError = useHandleGraphQLError();
    const [mutation] = useMutation<
      Pick<Mutation, 'setPushNotificationSnooze'>,
      MutationSetPushNotificationSnoozeArgs
    >(SET_PUSH_NOTIFICATION_SNOOZE);
    const setPushNotificationSnoozed = useCallback(
      (date?: Date) =>
        mutation({
          variables: {
            date: date ? date.toISOString() : null,
          },
          onCompleted: (data) => {
            const { receiveMobileNotificationsAt } = data.setPushNotificationSnooze.user;
            const title = receiveMobileNotificationsAt
              ? t('notifications:globalOptions.doNotDisturb.success', {
                  date: formatDate(
                    receiveMobileNotificationsAt,
                    SNOOZED_UNTIL_DATE_FORMAT,
                  ),
                })
              : t('notifications:globalOptions.doNotDisturb.unmute');

            showNotification({
              title,
              iconColor: 'cosmo',
              customIcon: 'notificationMuted',
              type: 'success',
            });
          },
          onError: handleGraphQLError,
        }),
      [formatDate, handleGraphQLError, mutation, showNotification, t],
    );
    const {
      getSnoozeDateUntil30Minutes,
      getSnoozeDateUntilNextWeek,
      getSnoozeDateUntilNHours,
      getSnoozeDateUntilTomorrow,
    } = useSnoozeUntilDates();

    const getSnoozedUntilOptions = () => {
      const snoozeDateUntil30Minutes = getSnoozeDateUntil30Minutes();
      const snoozeDateUntil1Hour = getSnoozeDateUntilNHours(1);
      const snoozeDateUntil2Hours = getSnoozeDateUntilNHours(2);
      const snoozeDateUntil4Hours = getSnoozeDateUntilNHours(4);
      const snoozeDateUntilTomorrow = getSnoozeDateUntilTomorrow();
      const snoozeDateUntilNextWeek = getSnoozeDateUntilNextWeek();

      const hoursLabel = (date: Date) => {
        return <SnoozeDateLabel>{formatDate(date, 'h:mm A')}</SnoozeDateLabel>;
      };
      const dateLabel = (date: Date) => {
        return <SnoozeDateLabel>{formatDate(date, 'ddd, h:mm A')}</SnoozeDateLabel>;
      };

      return [
        {
          icon: 'time',
          label: t('notifications:globalOptions.doNotDisturb.options.30Minutes'),
          trailing: hoursLabel(snoozeDateUntil30Minutes),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-30-minutes',
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntil30Minutes);
          },
        },
        {
          icon: 'time1Hour',
          label: t('notifications:globalOptions.doNotDisturb.options.hour_one'),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-1-hour',
          trailing: hoursLabel(snoozeDateUntil1Hour),
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntil1Hour);
          },
        },
        {
          icon: 'time2Hours',
          label: t('notifications:globalOptions.doNotDisturb.options.hour_other', {
            count: 2,
          }),
          trailing: hoursLabel(snoozeDateUntil2Hours),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-2-hours',
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntil2Hours);
          },
        },
        {
          icon: 'time4Hours',
          label: t('notifications:globalOptions.doNotDisturb.options.hour_other', {
            count: 4,
          }),
          trailing: hoursLabel(snoozeDateUntil4Hours),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-4-hours',
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntil4Hours);
          },
        },
        {
          icon: 'snoozeTomorrow',
          label: t('notifications:globalOptions.doNotDisturb.options.untilTomorrow'),
          trailing: dateLabel(snoozeDateUntilTomorrow),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-until-tomorrow',
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntilTomorrow);
          },
        },
        {
          icon: 'snoozeNextWeek',
          label: t('notifications:globalOptions.doNotDisturb.options.untilNextWeek'),
          trailing: dateLabel(snoozeDateUntilNextWeek),
          'data-lgg-id': 'notifications-global-option-do-not-disturb-until-next-week',
          onClick: async () => {
            await setPushNotificationSnoozed(snoozeDateUntilNextWeek);
          },
        },
      ];
    };

    return (
      <StyledDoNotDisturbBottomDrawer
        visible={visibilityHandler.visible}
        title={t('notifications:globalOptions.doNotDisturb.title')}
        onClose={visibilityHandler.close}
        options={getSnoozedUntilOptions()}
        topItem={
          snoozedUntil && (
            <ActiveSnoozedContainer
              onClick={(e) => {
                e.stopPropagation();
                void setPushNotificationSnoozed();
                visibilityHandler.close();
              }}
            >
              <ActiveSnoozedInnerContainer>
                <TurnOff>{t('notifications:globalOptions.doNotDisturb.turnOff')}</TurnOff>
                {t('notifications:globalOptions.doNotDisturb.banner', {
                  date: formatDate(snoozedUntil, SNOOZED_UNTIL_DATE_FORMAT),
                })}
              </ActiveSnoozedInnerContainer>
            </ActiveSnoozedContainer>
          )
        }
      />
    );
  },
);

import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactItem } from 'src/components/pages/contacts/components/contact-item';
import { useOpenCallContactModal } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useUrls } from 'src/hooks/use-urls';
import { showContactStatusModal } from './contact-status-modal';

const EmptyContactList = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.storm};
  font-size: 13px;
  height: 38px;
  letter-spacing: 0;
  padding: 0 20px;
`;

type ContactListProps = {
  contacts: Contact[];
};

export const ContactList = memo<ContactListProps>(({ contacts }) => {
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const { getLegacyContactFollowUpUrl, getContactModalUrl } = useUrls();
  const { t } = useTranslation(['contacts', 'common']);
  const { getFeatureFlag } = useAuthorization();
  const hasCrmFeatureFlag = getFeatureFlag('crm');
  const openCallContactModal = useOpenCallContactModal();
  const history = useHistory();

  const bottomDrawerOptions = useMemo(() => {
    const options = [
      {
        label: t('contacts:contactOptions.view'),
        icon: 'magnifyingGlass',
        onClick: () => {
          if (selectedContact) {
            history.push(getContactModalUrl(selectedContact.id));
          }
        },
      },
    ];

    if (hasCrmFeatureFlag) {
      options.push({
        label: t('contacts:contactOptions.updateStatus'),
        icon: 'status',
        onClick: () => {
          if (selectedContact) {
            showContactStatusModal(selectedContact.id);
          }
        },
      });
    }

    if (selectedContact?.primaryPhone) {
      options.push({
        label: t('contacts:contactOptions.call'),
        icon: 'call',
        onClick: () => {
          if (!selectedContact) {
            return;
          }

          void openCallContactModal(selectedContact.id);
        },
      });
    }

    if (selectedContact?.primaryEmail) {
      options.push({
        label: t('contacts:contactOptions.sendEmail'),
        icon: 'email',
        onClick: () => {
          window.location.href = `mailto:${selectedContact.primaryEmail}`;
        },
      });
    }

    if (hasCrmFeatureFlag) {
      options.push({
        label: t('contacts:contactOptions.followUp'),
        icon: 'contactSetting',
        onClick: () => {
          if (selectedContact)
            openRemoteModalWrapper(getLegacyContactFollowUpUrl(selectedContact.id));
        },
      });
    }

    return options;
  }, [
    t,
    hasCrmFeatureFlag,
    selectedContact,
    history,
    getContactModalUrl,
    openCallContactModal,
    getLegacyContactFollowUpUrl,
  ]);

  return (
    <>
      {contacts?.length > 0 ? (
        contacts.map((contact) => (
          <ContactItem
            key={contact.id}
            contact={contact}
            onClick={() => {
              setSelectedContact(contact);
            }}
          />
        ))
      ) : (
        <EmptyContactList>{t('common:noResults')}</EmptyContactList>
      )}
      <OptionsBottomDrawer
        visible={selectedContact !== undefined}
        title={selectedContact?.label ?? ''}
        onClose={() => setSelectedContact(undefined)}
        options={bottomDrawerOptions}
      />
    </>
  );
});

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import styled from 'styled-components';
import { ReactComponent as LeadgogoIllustrationDefault } from 'src/assets/images/illustration_leadgogo_default.svg';
import { FlexColumn } from 'src/components/domain/contacts/contact-details/shared';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexRow } from 'src/components/layout/flex-row';
import { SessionService } from 'src/services/session.service';

const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  line-height: 26px;
  margin-top: 30px;
  text-align: center;
`;

const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.33;
  margin: 20px 10px 0;
  text-align: center;
`;

const FooterContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 -20px;
  padding-bottom: ${({ theme }) => theme.insets.bottom}px;
`;

const FooterText = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 19px;
  padding: 20px;
  text-align: center;
`;

const ButtonsContainer = styled(FlexRow)`
  padding: 15px 20px;
  width: 100%;

  button + button {
    margin-left: 20px;
  }
`;

const CloseIcon = styled(Icon)`
  align-self: flex-end;
  margin-top: 20px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.white + 80};
    }
  }
`;

const StyledLeadgogoIllustrationDefault = styled(LeadgogoIllustrationDefault)`
  align-self: center;
  margin-top: 59px;
`;

const PlanUpdateRequiredContainer = styled(FlexColumn)`
  background-image: linear-gradient(
    156deg,
    ${({ theme }) => theme.colors.gogo} 28%,
    ${({ theme }) => theme.colors.monk} 111%
  );
  height: 100%;
  left: 0;
  padding: ${({ theme }) => theme.insets.top}px 20px 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export const PlanUpdateRequiredPage = memo(() => {
  const { t } = useTranslation(['common']);
  const logout = useServiceSelector(SessionService, (s) => s.logout);

  return (
    <PlanUpdateRequiredContainer>
      <CloseIcon onClick={logout} type="close" />
      <StyledLeadgogoIllustrationDefault />
      <Title>{t('common:planUpdateRequired.title')}</Title>
      <Subtitle>{t('common:planUpdateRequired.subtitle')}</Subtitle>
      <Expand />
      <FooterContainer>
        <FooterText>{t('common:planUpdateRequired.footer')}</FooterText>
        <ButtonsContainer>
          <LggButton onClick={logout} variant="tertiary">
            {t('common:back')}
          </LggButton>
          <LggButton
            variant="primary"
            onClick={() => {
              window.location.href = `mailto:sales@leadgogo.com`;
            }}
          >
            {t('common:planUpdateRequired.contactSales')}
          </LggButton>
        </ButtonsContainer>
      </FooterContainer>
    </PlanUpdateRequiredContainer>
  );
});

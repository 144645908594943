import { Injectable } from 'react-service-locator';
import { GenericHttpService } from 'src/services/http/generic-http.service';

@Injectable()
export class LegacyApiService extends GenericHttpService {
  protected additionalHeaders = {
    'x-legacy-page': 'true',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  };

  protected url(path: string) {
    const baseUrl = import.meta.env.VITE_LEGACY_API_APP_BASE_URL;
    return `${baseUrl}${path}`;
  }
}

import React, { useLayoutEffect } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import { Icon } from 'src/components/general/icon';
import { renderButtonContent } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/table/render-button-content';
import { useLegacyFiltersFromUrl } from 'src/components/pages/legacy/hooks/use-legacy-filters-from-url';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export function useHandleActionButtons(
  content: HTMLDivElement,
  breadcrumbActions: HTMLDivElement | null,
) {
  const breakpointUpMd = useBreakpoint(up('md'));
  const filters = useLegacyFiltersFromUrl();
  const { t } = useTranslation(['common']);

  useLayoutEffect(() => {
    const summaryTable = content.getElementsByClassName('table-top')[0];

    if (!summaryTable) {
      return;
    }

    const summaryTableText = summaryTable.getElementsByClassName('table-top-summary')[0];
    const divContainer = document.createElement('div');
    divContainer.className = 'pull-left';
    divContainer.innerHTML = summaryTableText.innerHTML.replace(
      'Showing',
      '<div class="summary-table-showing">Showing</div>',
    );

    summaryTableText.innerHTML = divContainer.outerHTML;

    const tableActionsContainer = document.createElement('div');
    tableActionsContainer.className = 'pull-right';

    let filtersButtonAdded: HTMLButtonElement | null = null;
    if (!summaryTable.getElementsByClassName('filters-toggle').length) {
      const filterButton = document.createElement('button');

      filterButton.className =
        'btn filters-toggle lgg-btn-white' + (filters.length ? ' lgg-btn-active' : '');
      tableActionsContainer.appendChild(filterButton);
      tableActionsContainer
        .getElementsByClassName('filters-toggle')[0]
        .addEventListener('click', () => {
          window.dispatchEvent(new CustomEvent('openLegacyFilters'));
        });
      filtersButtonAdded = filterButton;
    }

    summaryTable.setAttribute('width', '100%');

    //Breadcrumb actions
    if (breadcrumbActions !== null) {
      Array.from(breadcrumbActions.children).forEach((el) => {
        tableActionsContainer.appendChild(el);
      });

      breadcrumbActions.remove();
    }

    const actionsElement = content.getElementsByClassName('actions')[0];

    if (actionsElement !== undefined) {
      Array.from(actionsElement.getElementsByClassName('btn')).forEach((btn) => {
        tableActionsContainer.appendChild(btn);
      });

      actionsElement.remove();
    }

    const actionsButtons = tableActionsContainer.getElementsByClassName('btn');

    if (actionsButtons.length) {
      Array.from(actionsButtons).forEach((button) => {
        button.classList.remove('pull-left');
        renderButtonContent(button);
      });

      const newActions = document.createElement('div');
      Array.from(actionsButtons).forEach((el: Element) => {
        if (!breakpointUpMd && el.classList.contains('btn-success')) {
          newActions.appendChild(el);
        }
      });
      newActions.classList.add('pull-right');
    }

    if (!breakpointUpMd) {
      const containerMobile = document.createElement('div');
      containerMobile.classList.add('pull-left');
      containerMobile.innerHTML =
        summaryTable.getElementsByClassName('table-top-summary')[0].innerHTML;

      summaryTable.getElementsByClassName('table-top-summary')[0].innerHTML =
        containerMobile.outerHTML;
    }

    summaryTable
      .getElementsByClassName('table-top-summary')[0]
      .appendChild(tableActionsContainer);

    if (filtersButtonAdded !== null) {
      render(
        <React.Fragment>
          <Icon type="filters" className="lgg-icon-left" />
          <span>
            {filters.length > 0
              ? t('common:filters.activeFilters')
              : t('common:filters.title')}
          </span>
        </React.Fragment>,
        filtersButtonAdded,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, breadcrumbActions]);
}

import React, { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useServiceSelector } from 'react-service-locator';
import AntBreadcrumb from 'antd/lib/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { only, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { BreadcrumbRoute, BreadcrumbsService } from 'src/services/breadcrumbs.service';

export const Breadcrumbs = memo<WithClassname>(({ className, ...rest }) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const routes = useServiceSelector(BreadcrumbsService, (s) => s.state.routes);

  const applicableRoutes = useMemo(() => {
    return breakpointUpMd ? routes : routes.slice(-1);
  }, [breakpointUpMd, routes]);

  return (
    <StyledBreadcrumb
      {...rest}
      className={className}
      itemRender={(route, params, routes, paths) => {
        const breadcrumbRoute: BreadcrumbRoute = route;
        if (breadcrumbRoute.render) {
          return breadcrumbRoute.render();
        }
        return BreadcrumbItem(route, params, routes);
      }}
      routes={applicableRoutes as any}
    />
  );
});

const StyledBreadcrumb = styled(AntBreadcrumb)`
  display: flex;
  align-items: middle;

  & > span {
    display: flex;
    align-items: middle;
  }

  .ant-breadcrumb-link {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    line-height: normal;
  }

  & > span:last-child .ant-breadcrumb-link {
    max-width: 400px;
  }

  .ant-breadcrumb-separator {
    display: inline-block;
  }

  ${only('sm')} {
    font-size: 20px;
    letter-spacing: -0.4px;

    & > span:last-child .ant-breadcrumb-link {
      max-width: 250px;
      line-height: 60px;
      color: #ffffff;
    }
  }

  ${up('md')} {
    line-height: normal;
    padding-left: 28px;
    font-size: 20px;
    font-family: ${(props) => props.theme.font.regular};
    letter-spacing: -0.4px;
    color: ${(props) => props.theme.colors.base.lightBlueGrey};

    .ant-breadcrumb-separator {
      font-family: ${(props) => props.theme.font.light};
      margin: 0 9px;
      color: ${(props) => props.theme.colors.base.lightBlueGrey};
    }

    a {
      color: ${(props) => props.theme.colors.base.lightBlueGrey};
    }

    a:hover {
      color: #2d98da;
    }

    & > span:last-child {
      color: #304457;
    }
  }
`;

export function BreadcrumbItem(route: Route, params: {}, routes: Route[]) {
  const isLast = routes.indexOf(route) === routes.length - 1;

  if (isLast) {
    return route.breadcrumbName;
  }
  return <NavLink to={route.path}>{route.breadcrumbName}</NavLink>;
}

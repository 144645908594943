/* eslint-disable no-restricted-globals */
import { Service } from 'react-service-locator';
import { GenericHttpService } from 'src/services/http/generic-http.service';
import { apiUrl } from 'src/services/http/helpers';

@Service()
export class ApiService extends GenericHttpService {
  protected url(path: string) {
    return `${apiUrl()}${path}`;
  }
}

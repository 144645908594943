import { orderBy } from 'lodash';
import { Task } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortTasks = (tasks: Task[]) => {
  return orderBy(
    tasks,
    [
      (task) => {
        return new Date(task.createdAt).getTime();
      },
      (task) => {
        return task.id;
      },
    ],
    ['desc', 'asc'],
  );
};

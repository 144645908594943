import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { BreadcrumbRoute, BreadcrumbsService } from 'src/services/breadcrumbs.service';

export function useAddBreadcrumb(label: string) {
  const { add, remove } = useService(BreadcrumbsService, () => []);
  const { url: path } = useRouteMatch();

  useEffect(() => {
    const route: BreadcrumbRoute = { breadcrumbName: label, path };
    add(route);
    return () => {
      remove(route);
    };
  }, [add, remove, label, path]);
}

import { Tag } from 'antd';
import Menu, { MenuProps } from 'antd/lib/menu';
import styled from 'styled-components';

export type SidebarMenuProps = Omit<MenuProps, 'theme' | 'selectedKeys'> & {
  selectedKeys: string[];
};

export const StyledMenu = styled(Menu)`
  background: transparent;
  border-right: 0;

  & .ant-menu-submenu-arrow {
    display: none;
  }
`;

export const MenuTag = styled(Tag)`
  &.ant-tag {
    height: 14px;
    font-family: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.white};
    font-size: 8px;
    line-height: 11px;
    margin-left: 5px;
    padding: 2px 3px;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.25);
    border: none;
  }
`;

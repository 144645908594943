import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { VirtuosoHandle } from 'react-virtuoso';
import { Tabs } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { Contact, Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactDetailsMobile } from 'src/components/domain/contacts/contact-modal/contact-modal.mobile-view';
import { ContactModalAppointmentsTab } from 'src/components/domain/contacts/contact-modal/tabs/appointments/contact-modal-appointments-tab';
import { ContactModalNotesTab } from 'src/components/domain/contacts/contact-modal/tabs/contact-notes/contact-modal-notes-tab';
import { ContactModalTasksTab } from 'src/components/domain/contacts/contact-modal/tabs/tasks/contact-modal-tasks-tab';
import { ContactModalTimelineTab } from 'src/components/domain/contacts/contact-modal/tabs/timeline/contact-modal-timeline-tab';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BlockIcon,
  useContactBlock,
} from 'src/components/pages/contacts/components/contact-block';
import { ContactInteractionsList } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-list';
import {
  ChangeConversationOpenStatusButton,
  ConversationMoreOptionsDropdown,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-list-header';
import { OpenLegacyCallModalIcon } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useConversationSetClosed } from 'src/components/pages/conversations/hooks/use-conversation-set-closed';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useUrls } from 'src/hooks/use-urls';
import { VisibilityHandler } from 'src/hooks/use-visible';
import { LegacyApiService } from 'src/services/http/legacy-api.service';

export type ContactModalViewProps = {
  contact?: Contact;
  loading: boolean;
  onClose: VoidFunction;
};

export type ContactOptionsDisplayProps = {
  visibilityHandler: VisibilityHandler;
  contact: Contact;
};

export const ContactModalOpenLegacyCallModalIcon = styled(OpenLegacyCallModalIcon)`
  svg {
    height: 20px;
    width: 20px;
  }

  ${up('md')} {
    margin: 0;

    .lgg-icon {
      margin: 0;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`;

export const MoreOptionsIcon = styled(Icon)`
  align-self: center;
  cursor: pointer;
  padding-left: 8px;
  margin-right: -7px;

  ${up('md')} {
    && {
      padding: 0;
      margin: 0 0 0 20px;
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

export const useContactOptions = (props: {
  contact: Contact;
  onBlockContactOptionClicked: VoidFunction;
}) => {
  const { contact, onBlockContactOptionClicked } = props;
  const { id: contactId } = contact;
  const [worksheetUrl, setWorksheetUrl] = useState<string | null>(null);
  const legacyApiService = useService(LegacyApiService);
  const institutionUrl = useInstitutionUrl();
  const { triggerError } = useErrorHandling();
  const { getFeatureFlag } = useAuthorization();
  const { getLegacyContactEditUrl } = useUrls();
  const breakpointUpMd = useBreakpoint(up('md'));
  const hasLFReportContactWorksheet = getFeatureFlag('crm');
  const { optionLabel: contactBlockOptionLabel } = useContactBlock({
    contact,
  });

  useEffect(() => {
    const getExportUrls = async () => {
      if (!hasLFReportContactWorksheet) {
        return;
      }

      setWorksheetUrl(null);

      try {
        const { data } = await legacyApiService.get<{ worksheet: string }>(
          `${institutionUrl}cms/_modals/client/lead_id:${contactId}`,
          {
            headers: {
              'lgg-legacy-hybrid-data': 'true',
              'X-Requested-With': 'xmlhttprequest',
            },
          },
        );

        setWorksheetUrl(data.worksheet);
      } catch (e) {
        triggerError(e);
      }
    };

    void getExportUrls();
  }, [
    contactId,
    hasLFReportContactWorksheet,
    institutionUrl,
    legacyApiService,
    triggerError,
  ]);

  const options: DrawerOption[] = [];

  if (!breakpointUpMd) {
    options.push({
      icon: 'edit',
      label: 'Edit',
      'data-lgg-id': 'contact-option-edit',
      onClick: async (e) => {
        e.stopPropagation();
        openRemoteModalWrapper(getLegacyContactEditUrl(contactId));
      },
    });
  }

  if (hasLFReportContactWorksheet) {
    options.push({
      icon: 'document',
      label: 'Worksheet',
      'data-lgg-id': 'contact-option-worksheet',
      onClick: async (e) => {
        e.stopPropagation();
        if (worksheetUrl) {
          openRemoteModalWrapper(worksheetUrl);
        }
      },
    });
  }

  if (getFeatureFlag('block-contacts')) {
    options.push({
      'data-lgg-id': 'block-contact-option',
      label: contactBlockOptionLabel,
      icon: <BlockIcon type="block" />,
      onClick: onBlockContactOptionClicked,
    });
  }

  return options.length ? options : null;
};

const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-content {
    height: 100%;
  }

  ${up('md')} {
    border-radius: 6px;
    width: 100%;
  }

  .ant-tabs-nav::before {
    border-bottom: 2px solid ${({ theme }) => theme.colors.koala};
  }

  .ant-tabs-nav {
    background-color: ${({ theme }) => theme.colors.white};
    height: 42px;
    margin-bottom: 0;

    ${up('md')} {
      height: 60px;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 17px;
    text-align: left;
    transition: none;

    &.ant-tabs-tab-active {
      transition: none;

      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.colors.gogo};
      }
    }
  }

  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.colors.gogo};
    height: 3px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 25px;

    ${up('lg')} {
      margin-left: 50px;
    }
  }

  .ant-tabs-tab:first-child {
    margin-left: 20px;
  }

  .ant-tabs-nav-list .ant-tabs-tab:nth-child(6) {
    margin-right: 20px;
  }

  ${up('md')} {
    .ant-tabs-nav-list .ant-tabs-tab:nth-child(5) {
      margin-right: 20px;
    }
  }

  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;

    ${up('md')} {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const TabIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active & {
    svg path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const ContactDetailsContainer = styled.div`
  padding-top: 10px;
`;

const ConversationLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.geyser};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

const ConversationOptionsContainer = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  height: 47px;
  justify-content: space-between;
  padding: 7px 20px;
`;

const RightContainer = styled(FlexRow)`
  align-items: center;
`;

type ConversationOptionsBarProps = {
  conversation: Conversation;
};

export const ConversationOptionsBar = ({ conversation }: ConversationOptionsBarProps) => {
  const { id: conversationId, isOpen, isStarted } = conversation;
  const conversationSetClosed = useConversationSetClosed();
  const { getConversationUrl } = useUrls();
  const { t } = useTranslation(['conversations']);

  return (
    <ConversationOptionsContainer data-lgg-id="conversation-state-options-bar">
      <ConversationLink
        to={getConversationUrl(conversationId)}
        data-lgg-id="view-in-conversations-link"
      >
        {t('conversations:interactionsList.viewInConversations')}
      </ConversationLink>
      {isStarted && (
        <RightContainer>
          <ChangeConversationOpenStatusButton
            isOpen={isOpen}
            heightOverride="32px"
            onClick={async () => {
              await conversationSetClosed({
                conversationId: conversationId,
                isClosed: isOpen,
              });
            }}
          />
          <ConversationMoreOptionsDropdown conversation={conversation} />
        </RightContainer>
      )}
    </ConversationOptionsContainer>
  );
};

type ContactModalTabsProps = {
  onChange?: ValueChanged<ContactModalTabKey>;
  contact: Contact;
};

export const tabsKeyMapping = {
  timeline: 1,
  'contact-details': 2,
  conversations: 3,
  tasks: 4,
  appointments: 5,
  notes: 6,
};

export type ContactModalTabKey = keyof typeof tabsKeyMapping;

export const ContactModalTabs = memo<ContactModalTabsProps>(({ onChange, contact }) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const breakpointUpXl = useBreakpoint(up('xl'));
  const history = useHistory();
  const { getContactModalUrl } = useUrls();
  const params = useParams<{
    tab?: string;
    contactInteractionId?: string;
    conversationId?: string;
  }>();

  const virtuosoTimelineRef = useRef<VirtuosoHandle>(null);
  const virtuosoTasksRef = useRef<VirtuosoHandle>(null);
  const virtuosoAppointmentsRef = useRef<VirtuosoHandle>(null);
  const virtuosoNotesRef = useRef<VirtuosoHandle>(null);
  const { getFeatureFlag } = useAuthorization();
  const hasCrmFeatureFlag = getFeatureFlag('crm');

  const tab = match(params)
    .with({ tab: 'tasks' }, () => (hasCrmFeatureFlag ? 'tasks' : 'timeline'))
    .with({ tab: 'appointments' }, () =>
      hasCrmFeatureFlag ? 'appointments' : 'timeline',
    )
    .with({ tab: 'contact-details' }, () => 'contact-details')
    .with({ tab: 'notes' }, () => 'notes')
    .with(
      P.union(
        { tab: 'conversations' },
        { conversationId: P.string },
        { contactInteractionId: P.string },
      ),
      () => 'conversations',
    )
    .otherwise(() => 'timeline') as ContactModalTabKey;

  const { t } = useTranslation(['contacts']);
  const renderTab = (key: ContactModalTabKey) => {
    switch (key) {
      case 'timeline': {
        const label = t('contacts:detailsModal.tabs.timeline.title');

        if (breakpointUpXl) {
          return (
            <>
              <TabIcon type="tabTimeline" />
              {label}
            </>
          );
        }

        return breakpointUpMd ? label : t('contacts:detailsModal.tabs.home.title');
      }
      case 'contact-details': {
        return t('contacts:detailsModal.tabs.details.title');
      }
      case 'conversations': {
        const label = t('contacts:detailsModal.tabs.conversation.title');

        if (breakpointUpXl) {
          return (
            <>
              <TabIcon type="conversations" />
              {label}
            </>
          );
        }

        return label;
      }
      case 'tasks': {
        const label = t('contacts:detailsModal.tabs.tasks.title');

        if (breakpointUpXl) {
          return (
            <span data-lgg-id="contact-modal-tab-tasks">
              <TabIcon type="tabTasks" />
              {label}
            </span>
          );
        }

        return label;
      }
      case 'appointments': {
        const label = t('contacts:detailsModal.tabs.schedules.title');

        if (breakpointUpXl) {
          return (
            <span data-lgg-id="contact-modal-tab-appointments">
              <TabIcon type="tabSchedules" />
              {label}
            </span>
          );
        }

        return label;
      }
      case 'notes': {
        const label = t('contacts:detailsModal.tabs.notes.title');

        if (breakpointUpXl) {
          return (
            <>
              <TabIcon type="tabNotes" />
              {label}
            </>
          );
        }

        return label;
      }
    }
  };

  return (
    <StyledTabs
      defaultActiveKey={tab}
      activeKey={tab}
      onChange={(key) => {
        const tabKey = key as ContactModalTabKey;

        if (key === tab) {
          const virtuosoRef = match(key)
            .with('timeline', () => virtuosoTimelineRef)
            .with('tasks', () => virtuosoTasksRef)
            .with('appointments', () => virtuosoAppointmentsRef)
            .with('notes', () => virtuosoNotesRef)
            .otherwise(() => null);

          const virtuosoHandle = virtuosoRef?.current;

          if (!virtuosoHandle) {
            return;
          }

          virtuosoHandle.getState((state) => {
            virtuosoHandle.scrollBy({
              top: -state.scrollTop,
              behavior: 'smooth',
            });
          });
        } else {
          history.replace(getContactModalUrl(contact.id, tabKey));
          onChange && onChange(tabKey);
        }
      }}
    >
      <Tabs.TabPane tab={renderTab('timeline')} key="timeline">
        <ContactModalTimelineTab contact={contact} virtuosoRef={virtuosoTimelineRef} />
      </Tabs.TabPane>
      {!breakpointUpMd && (
        <Tabs.TabPane tab={renderTab('contact-details')} key="contact-details">
          <ContactDetailsContainer>
            <ContactDetailsMobile contact={contact} display="full" />
          </ContactDetailsContainer>
        </Tabs.TabPane>
      )}
      <Tabs.TabPane tab={renderTab('conversations')} key="conversations">
        <ContactInteractionsList
          conversationId={params.conversationId ?? contact.lastConversation.id}
          contactInteractionIdParam={params.contactInteractionId}
          showOptionsBanner={breakpointUpMd}
        />
      </Tabs.TabPane>
      {hasCrmFeatureFlag && (
        <>
          <Tabs.TabPane tab={renderTab('tasks')} key="tasks">
            <ContactModalTasksTab contactId={contact.id} virtuosoRef={virtuosoTasksRef} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTab('appointments')} key="appointments">
            <ContactModalAppointmentsTab
              contactId={contact.id}
              virtuosoRef={virtuosoAppointmentsRef}
            />
          </Tabs.TabPane>
        </>
      )}
      <Tabs.TabPane tab={renderTab('notes')} key="notes">
        <ContactModalNotesTab contactId={contact.id} virtuosoRef={virtuosoNotesRef} />
      </Tabs.TabPane>
    </StyledTabs>
  );
});

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import H5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  LggOptionsDropdownButtonWithCustomTrigger,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import {
  DrawerSelectableOption,
  SelectableOptionsDrawer,
} from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { downloadFile } from 'src/utils/download-file';

const StyledAudioPlayer = styled(H5AudioPlayer)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: none;
  padding: 15px;

  ${up('md')} {
    background: ${({ theme }) => theme.colors.porcelain};
  }

  .rhap_current-time,
  .rhap_total-time {
    color: ${({ theme }) => theme.colors.smalt};
  }

  &.rhap_play-status--playing {
    .rhap_progress-section {
      .rhap_current-time {
        display: block;
        order: 3;
      }

      .rhap_total-time {
        display: none;
        order: 1;
      }
    }
  }

  &.rhap_play-status--paused {
    .rhap_progress-section {
      .rhap_current-time {
        display: none;
      }

      .rhap_total-time {
        display: block;
      }
    }
  }

  .rhap_controls-section {
    flex: unset;
    margin: 0;

    .rhap_additional-controls,
    .rhap_volume-controls {
      display: none;
    }

    .rhap_play-pause-button {
      display: flex;
      height: 36px;
      margin: 0;
      width: 36px;
    }
  }

  .rhap_progress-section {
    .rhap_current-time {
      order: 1;
    }

    .rhap_total-time {
      order: 3;
    }

    .rhap_current-time,
    .rhap_total-time {
      color: ${({ theme }) => theme.colors.flint};
      font-family: ${({ theme }) => theme.font.regular};
      font-size: 12px;
      letter-spacing: normal;
      line-height: 14px;
      text-align: left;
      white-space: nowrap;
      width: 32px;
    }

    .rhap_progress-container {
      margin: 0 10px;
      order: 2;

      .rhap_progress-indicator {
        background: ${({ theme }) => theme.colors.gogo};
        height: 10px;
        margin-left: -5px;
        top: -3px;
        width: 10px;
      }

      .rhap_progress-filled {
        background: ${({ theme }) => theme.colors.gogo};
        border-radius: 2px;
        opacity: 0.5;
      }

      .rhap_download-progress,
      .rhap_progress-bar {
        background: ${({ theme }) => theme.colors.casper};
        height: 4px;
      }
    }
  }
`;

const PlayPauseIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.casper};
  border-radius: 50%;
  display: flex;
  padding: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const MoreOptionsIcon = styled(Icon)`
  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const MoreOptionsContainer = styled.div`
  order: 3;
  margin-left: 10px;
`;

const PlayerOptionIcon = styled(Icon)`
  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }

  ${up('md')} {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const PlaybackSpeedContainer = styled(FlexRow)`
  margin-left: 30px;
  min-width: 59px;
  justify-content: flex-end;
  align-items: center;
`;

const PlaybackSpeedDescription = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.flint};

  ${up('md')} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const PlaybackSpeedCaretIcon = styled(Icon)`
  margin-left: 10px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }

  ${up('md')} {
    margin-left: 5px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export type LggAudioPlayerProps = {
  url: string;
  allowDownload?: boolean;
  testId?: string;
  playerRef?: React.RefObject<H5AudioPlayer> | undefined;
};

export const LggAudioPlayer = memo<LggAudioPlayerProps>(
  ({ url, testId, playerRef, allowDownload = false, ...rest }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const moreOptionsVisibilityHandler = useVisible();
    const { t } = useTranslation(['common']);
    const innerRef = useRef<H5AudioPlayer>(null);
    const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
    const playbackSpeedVisibilityHandler = useVisible();

    const selectedPlaybackSpeed = useMemo(() => {
      const description = match(playbackSpeed)
        .with(1, () => t('common:audioPlayer.playbackSpeedOptions.normal'))
        .otherwise((val) => val.toString());

      return (
        <PlaybackSpeedContainer>
          <PlaybackSpeedDescription>{description}</PlaybackSpeedDescription>
          <PlaybackSpeedCaretIcon type="arrowright" />
        </PlaybackSpeedContainer>
      );
    }, [playbackSpeed, t]);

    const refToBeUsed = useMemo(() => playerRef ?? innerRef, [playerRef]);
    const baseOptions = useMemo(() => range(0.25, 2.25, 0.25), []);

    const options: DrawerSelectableOption[] = useMemo(() => {
      return baseOptions.map((option) => {
        const stringValue = option.toString();

        return {
          label:
            option === 1
              ? t('common:audioPlayer.playbackSpeedOptions.normal')
              : stringValue,
          value: stringValue,
          'data-lgg-id': `audio-player-playback-speed-option-${stringValue}`,
          onClick: (e) => {
            e.stopPropagation();

            moreOptionsVisibilityHandler.close();
            setPlaybackSpeed(option);
            playbackSpeedVisibilityHandler.close();
          },
        };
      });
    }, [baseOptions, t, moreOptionsVisibilityHandler, playbackSpeedVisibilityHandler]);

    const moreOptionsIcon = useMemo(() => {
      const moreOptions: DrawerOption[] = [
        {
          label: t('common:audioPlayer.playbackSpeed'),
          icon: <PlayerOptionIcon type="playback" />,
          onClick: () => {
            playbackSpeedVisibilityHandler.show();
          },
          'data-lgg-id': 'audio-player-playback-speed-option',
          closeOnClick: !breakpointUpMd,
          trailing: (
            <div>
              {breakpointUpMd ? (
                <LggSelectableOptionsDropdownButtonWithCustomTrigger
                  options={options}
                  selectedValue={playbackSpeed.toString()}
                  size="regular"
                  variant="default"
                  customDropdownProps={{
                    overlayStyle: {
                      minWidth: '137px',
                    },
                    align: {
                      offset: [60, -20],
                    },
                  }}
                  visibilityHandler={playbackSpeedVisibilityHandler}
                >
                  {selectedPlaybackSpeed}
                </LggSelectableOptionsDropdownButtonWithCustomTrigger>
              ) : (
                <>
                  {selectedPlaybackSpeed}
                  <SelectableOptionsDrawer
                    onClose={playbackSpeedVisibilityHandler.close}
                    visible={playbackSpeedVisibilityHandler.visible}
                    options={options}
                    selectedValue={playbackSpeed.toString()}
                    title={t('common:audioPlayer.playbackSpeedMobileTitle')}
                  />
                </>
              )}
            </div>
          ),
        },
      ];

      if (allowDownload) {
        moreOptions.unshift({
          label: t('common:actions.download'),
          icon: <PlayerOptionIcon type="download" />,
          render: (optionItem) => (
            <div
              data-url={url}
              onClick={async () => {
                await downloadFile({
                  fileUrl: url,
                });
              }}
              data-lgg-id="audio-player-download-option"
            >
              {optionItem}
            </div>
          ),
        });
      }

      const triggerIcon = (
        <MoreOptionsIcon
          type="moreOptions"
          lggTestId="audio-player-more-options"
          onClick={() => {
            if (!breakpointUpMd) {
              moreOptionsVisibilityHandler.setVisible(
                !moreOptionsVisibilityHandler.visible,
              );
            }
          }}
        />
      );

      return (
        <MoreOptionsContainer>
          {breakpointUpMd ? (
            <LggOptionsDropdownButtonWithCustomTrigger
              options={moreOptions}
              customDropdownProps={{
                placement: 'bottomRight',
                align: {
                  offset: [5, 5],
                },
              }}
              visibilityHandler={moreOptionsVisibilityHandler}
              customTrigger={triggerIcon}
            />
          ) : (
            <>
              {triggerIcon}
              <OptionsBottomDrawer
                visible={moreOptionsVisibilityHandler.visible}
                title={t('common:options')}
                onClose={moreOptionsVisibilityHandler.close}
                options={moreOptions}
              />
            </>
          )}
        </MoreOptionsContainer>
      );
    }, [
      allowDownload,
      breakpointUpMd,
      moreOptionsVisibilityHandler,
      options,
      playbackSpeed,
      selectedPlaybackSpeed,
      t,
      url,
      playbackSpeedVisibilityHandler,
    ]);

    useEffect(() => {
      if (refToBeUsed) {
        const htmlAudioPlayer = refToBeUsed.current?.audio.current;

        if (htmlAudioPlayer) {
          htmlAudioPlayer.playbackRate = playbackSpeed;
        }
      }
    }, [playbackSpeed, refToBeUsed]);

    return (
      <div data-lgg-id={`${testId}-audio-player`} {...rest}>
        <StyledAudioPlayer
          src={url}
          layout="horizontal-reverse"
          showFilledVolume={false}
          showSkipControls={false}
          showJumpControls={false}
          ref={refToBeUsed}
          loop={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          timeFormat="mm:ss"
          customProgressBarSection={[
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.DURATION,
            RHAP_UI.CURRENT_TIME,
            moreOptionsIcon,
          ]}
          customIcons={{
            play: (
              <PlayPauseIcon
                lggTestId={`${testId}-player-play-button`}
                className="player-play-button"
                type="play"
              />
            ),
            pause: (
              <PlayPauseIcon
                lggTestId={`${testId}-player-pause-button`}
                className="player-play-button"
                type="pause"
              />
            ),
          }}
        />
      </div>
    );
  },
);

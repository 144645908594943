import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePasswordManagementForm } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-password-management-form';
import { BaseFormProps } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal';
import { TextInput } from 'src/components/general/inputs/text-input';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

type PasswordManagementFormProps = BaseFormProps;

export const PasswordManagementForm: React.FC<PasswordManagementFormProps> = ({
  formId,
}) => {
  const { form, onReset, onSubmit } = usePasswordManagementForm();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const { t } = useTranslation(['user']);

  return (
    <StyledForm
      data-lgg-id="password-management-form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
    >
      <Controller
        name="currentPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-currentPassword"
            label={t(
              'user:profileSettings.sections.passwordManagement.fields.currentPassword.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.passwordManagement.fields.currentPassword.placeholder',
            )}
            error={errors.currentPassword?.message}
            reserveErrorArea={false}
            showDot={false}
            type="password"
            {...field}
          />
        )}
      />
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-newPassword"
            label={t(
              'user:profileSettings.sections.passwordManagement.fields.newPassword.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.passwordManagement.fields.newPassword.placeholder',
            )}
            error={errors.newPassword?.message}
            reserveErrorArea={false}
            showDot={false}
            type="password"
            {...field}
          />
        )}
      />
      <Controller
        name="confirmNewPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-confirmNewPassword"
            label={t(
              'user:profileSettings.sections.passwordManagement.fields.confirmNewPassword.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.passwordManagement.fields.confirmNewPassword.placeholder',
            )}
            error={errors.confirmNewPassword?.message}
            reserveErrorArea={false}
            showDot={false}
            type="password"
            {...field}
          />
        )}
      />
    </StyledForm>
  );
};

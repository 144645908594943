/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Service, StatefulService } from 'react-service-locator';
import { AuthorizationService } from 'src/services/authorization.service';

export type ConversationStatus =
  | 'IS_MARKED'
  | 'IS_SNOOZED'
  | 'IS_REPLY_PENDING'
  | 'IS_CLOSED'
  | 'IS_OPEN';

export type ConversationsFilters = {
  keyword: string | null;
  assigneeType: string;
  assignees: number[];
  channelsSlugs: string[];
  contactStagesIds: string[];
  contactStatusesIds: string[];
  conversationStatus: ConversationStatus;
  contactTagsIds: number[];
  departmentIds: number[];
  contactCampaignsIds: number[];
};

export type ConversationServiceState = {
  selectedConversationId: string | null;
  bulkSelectionEnabled: boolean;
  bulkSelectionMap: Map<string, boolean>;
  bulkSelectAllEnabled: boolean;
  selectedContactId: number | null;
  visible: boolean;
  showContactInformation: boolean;
  filters: ConversationsFilters;
};

@Service()
export class ConversationService extends StatefulService<ConversationServiceState> {
  constructor(
    @Inject(AuthorizationService) private authorizationService: AuthorizationService,
  ) {
    super();

    this.state = {
      selectedConversationId: null,
      selectedContactId: null,
      visible: false,
      showContactInformation: false,
      bulkSelectionEnabled: false,
      bulkSelectAllEnabled: false,
      bulkSelectionMap: new Map(),
      filters: {
        assigneeType: this.authorizationService.hasPermission('lead.access.view.all')
          ? 'everyone'
          : 'only_me',
        keyword: null,
        assignees: [],
        channelsSlugs: [],
        contactStagesIds: [],
        contactStatusesIds: [],
        conversationStatus: 'IS_OPEN',
        contactTagsIds: [],
        departmentIds: [],
        contactCampaignsIds: [],
      },
    };
  }

  selectConversation = ({
    conversationId,
    contactId = null,
  }: {
    conversationId: string;
    contactId?: number | null;
  }) => {
    this.setState({
      selectedConversationId: conversationId,
      selectedContactId: contactId,
      visible: true,
    });
  };

  setShowContactInformation = (show: boolean) => {
    this.setState({
      showContactInformation: show,
    });
  };

  clearConversationSelection = () => {
    this.setState({
      selectedContactId: null,
      selectedConversationId: null,
    });
  };

  setFilters = (filters: Partial<ConversationsFilters>) => {
    this.setState({
      filters: {
        ...(this.state.filters as ConversationsFilters),
        ...filters,
      },
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  toggleBulkSelectionItemId = (id: string) => {
    const updatedSelectionMap = new Map(this.state.bulkSelectionMap);

    if (updatedSelectionMap.has(id)) {
      updatedSelectionMap.delete(id);
    } else {
      updatedSelectionMap.set(id, true);
    }

    this.setState({
      bulkSelectionMap: updatedSelectionMap,
      bulkSelectionEnabled: updatedSelectionMap.size > 0,
    });
  };

  toggleBulkSelectionMode = () => {
    this.setState({
      bulkSelectionEnabled: !this.state.bulkSelectionEnabled,
      bulkSelectionMap: new Map(),
      bulkSelectAllEnabled: false,
    });
  };

  toggleBulkSelectAllMode = (value: boolean) => {
    this.setState({
      bulkSelectAllEnabled: value,
      bulkSelectionMap: new Map(),
    });
  };
}

import React, { memo } from 'react';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';

const StyledButtonsBar = styled.div`
  display: flex;
  padding: 15px 20px;

  > * + * {
    margin-left: 20px;
  }
`;

type ButtonsBarProps = {
  primaryCallback: VoidFunction;
  primaryText: string;
  secondaryCallback: VoidFunction;
  secondaryText: string;
  primaryButtonTestId?: string;
  secondaryButtonTestId?: string;
};

export const ButtonsBar = memo<ButtonsBarProps>(
  ({
    primaryCallback,
    primaryText,
    secondaryCallback,
    secondaryText,
    primaryButtonTestId,
    secondaryButtonTestId,
    ...props
  }) => {
    return (
      <StyledButtonsBar {...props}>
        <LggButton
          variant="tertiary"
          onClick={secondaryCallback}
          data-lgg-id={secondaryButtonTestId}
        >
          {secondaryText}
        </LggButton>
        <LggButton
          variant="primary"
          onClick={primaryCallback}
          data-lgg-id={primaryButtonTestId}
        >
          {primaryText}
        </LggButton>
      </StyledButtonsBar>
    );
  },
);

import React, { memo, MouseEventHandler, ReactNode } from 'react';
import { Menu as AntMenu, MenuProps } from 'antd';
import styled from 'styled-components';

const { Item } = AntMenu;

export const Menu = styled(AntMenu)`
  &.ant-dropdown-menu {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    border: solid 1px ${({ theme }) => theme.colors.koala};
    box-shadow: 0 20px 40px 0 rgba(91, 101, 112, 0.1);
    min-width: 144px;
    padding: 5px;

    .ant-dropdown-menu-item {
      align-items: center;
      border-radius: 4px;
      display: flex;
      font-size: 12px;
      height: 32px;
      letter-spacing: -0.12px;
      line-height: 14px;

      &.ant-dropdown-menu-item-active {
        background-color: ${({ theme }) => theme.colors.porcelain};
      }

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }

    .ant-dropdown-menu-item.default {
      color: ${({ theme }) => theme.colors.flint};
      font-family: ${({ theme }) => theme.font.regular};

      .ant-dropdown-menu-item-icon {
        height: 16px;
        margin-right: 10px;
        width: 16px;

        svg {
          height: 100%;
          width: 100%;

          path {
            fill: ${({ theme }) => theme.colors.smalt};
          }
        }
      }
    }
  }
` as unknown as typeof AntMenu;

export type ContextMenuItem = {
  key: string;
  label: string;
  icon?: ReactNode;
  testId?: string;
  to?: string;
  onClick?: MouseEventHandler<Element | null>;
};

export type ContextMenuProps = MenuProps & {
  items: (ContextMenuItem & {
    testId: string;
  })[];
  useDefaultStyles?: boolean;
};

export const ContextMenu = memo<ContextMenuProps>(
  ({ items, useDefaultStyles = true, ...rest }) => {
    return (
      <Menu {...rest}>
        {items.map((item) => {
          return (
            <Item
              key={item.key}
              icon={item.icon}
              onClick={(e) => item.onClick?.(e.domEvent)}
              data-lgg-id={item.testId}
              className={`${useDefaultStyles ? 'default' : ''}`}
            >
              {item.label}
            </Item>
          );
        })}
      </Menu>
    );
  },
);

import React, { memo } from 'react';
import AntdAvatar, { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import styled from 'styled-components';

const StyledAvatar = styled(AntdAvatar)<AvatarProps>`
  align-items: center;
  background: ${({ theme }) => theme.colors.lightGrey4};
  box-sizing: content-box;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;
`;

type AvatarProps = AntdAvatarProps;

export const Avatar = memo<AvatarProps>((props) => {
  return <StyledAvatar {...props} />;
});

export const BorderedAvatar = styled(Avatar)`
  border: solid 2px ${({ theme }) => theme.colors.white};
`;

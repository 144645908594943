import { useService } from 'react-service-locator';
import { snakeCase, pascalCase } from 'change-case';
import { AuthorizationService } from 'src/services/authorization.service';
import { SessionService } from 'src/services/session.service';

function toSnakeCase(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [snakeCase(key), value]),
  );
}

export function useSetLggConfig() {
  const sessionService = useService(SessionService);
  const authorizationService = useService(AuthorizationService);

  const { institution, concreteInstitutionLineage } = authorizationService.state;

  window.lggLegacyXhrRequestBaseUrl = import.meta.env.VITE_LEGACY_API_APP_BASE_URL;

  window.lggConfig = {
    timezone: authorizationService.getTimezone(),
    Institution: toSnakeCase(institution),
    Institutions: [],
    ...Object.fromEntries(
      Object.entries(concreteInstitutionLineage).map(([key, value]) => [
        pascalCase(key),
        toSnakeCase(value),
      ]),
    ),
    User: toSnakeCase({
      ...sessionService.state.sessionData?.user,
      permissions: authorizationService.state.permissions,
      features: authorizationService.state.featureFlags,
    }),
  };
}

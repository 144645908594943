import React, { memo, PropsWithChildren } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import { Icon } from 'src/components/general/icon';

const DynamicDrawerCloseIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
    max-width: 472px;
    overflow: hidden;

    .ant-modal-close {
      height: 18px;
      position: absolute;
      right: 20px;
      top: 26px;
      width: 18px;

      ${up('md')} {
        top: 31px;
      }

      .ant-modal-close-x {
        align-items: center;
        display: flex;
        height: 20px;
        justify-content: center;
        width: 20px;
      }
    }

    .ant-modal-body {
      padding: 0;
    }
  }
`;

type LggModalProps = Omit<ModalProps, 'onCancel'> & {
  onClose: VoidFunction;
};

export const LggModal = memo<PropsWithChildren<LggModalProps>>((props) => {
  const { children, onClose, ...rest } = props;

  return (
    <StyledModal
      zIndex={ModalsZIndex}
      closeIcon={<DynamicDrawerCloseIcon type="close" lggTestId="modal-close-icon" />}
      onCancel={onClose}
      footer={null}
      maskClosable
      centered
      {...rest}
    >
      {children}
    </StyledModal>
  );
});

import React, { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { isMatching, match, P } from 'ts-pattern';
import { NotificationFeedItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  inboundPhoneCallInteractionPatterns,
  matchContactInteractionExhaustive,
} from '@lgg/isomorphic/utils/match-contact-interaction';
import { matchConversationAttachmentExhaustive } from '@lgg/isomorphic/utils/match-conversation-attachment';
import {
  useNotificationSetClearedStatus,
  useNotificationSetStatus,
} from 'src/components/domain/notifications/hooks/use-notification-set-status';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import {
  LggCircleIcon,
  LggCircleIconProps,
} from 'src/components/general/icon/circle-icon/lgg-circle-icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useOpenCallContactModal } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const MoreOptionsIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
  margin-top: 9px;
  max-height: 16px;
  padding-left: 10px;
  padding-right: 10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const StyledNotificationBasedItem = styled(FlexRow)<{ interactedWith: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 15px 10px 15px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.alabaster};
  }

  ${({ interactedWith }) =>
    !interactedWith &&
    `
    background-color: #2d98da12;
  `}
`;

const Message = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;

  strong {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: unset;
  }

  div,
  a {
    color: ${({ theme }) => theme.colors.gogo};
    cursor: pointer;
    display: inline;
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

const Date = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 5px;
  text-align: left;
`;

const MessageContainer = styled(FlexColumn)`
  flex: 1;
`;

const resolveIconProps = (
  notification: NotificationFeedItemUnion,
): LggCircleIconProps => {
  switch (notification.__typename) {
    case 'NotificationFeedItemContactAssigned':
    case 'NotificationFeedItemContactUnassigned':
    case 'NotificationFeedItemContactUpdated':
    case 'NotificationFeedItemContactStatusChanged':
      return {
        bgColor: 'secondaryGold20',
        iconColor: 'secondaryGoldDark',
        icon: 'contact',
      };
    case 'NotificationFeedItemCallMissed':
      return {
        bgColor: 'secondaryTopaz20',
        iconColor: 'secondaryTopazDark',
        icon: 'missedCall',
      };
    case 'NotificationFeedItemTaskAssigned':
    case 'NotificationFeedItemTaskReminder':
    case 'NotificationFeedItemTaskUpdated':
    case 'NotificationFeedItemTaskOverdue':
    case 'NotificationFeedItemTaskCompleted':
      return {
        bgColor: 'secondaryMint20',
        iconColor: 'secondaryMintDark',
        icon: 'task18',
      };
    case 'NotificationFeedItemScheduleCreated':
    case 'NotificationFeedItemScheduleAssigned':
    case 'NotificationFeedItemScheduleUpdated':
    case 'NotificationFeedItemScheduleCanceled':
      return {
        bgColor: 'periWinkle20',
        iconColor: 'periWinkleDark',
        icon: 'schedule18',
      };
    case 'NotificationFeedItemContactInteractionNew':
      if (!notification.contactInteraction) {
        return {
          bgColor: 'secondaryTopaz20',
          iconColor: 'secondaryTopazDark',
          icon: 'interaction',
        };
      }

      const icon = matchContactInteractionExhaustive(notification.contactInteraction, {
        sms: () => 'sms',
        whatsapp: () => 'whatsapp',
        facebookMessenger: () => 'facebookMessenger',
        phone: () => 'phone',
        instagram: () => 'instagramAlt',
        email: () => undefined,
        inPerson: () => undefined,
        facebookAd: () => undefined,
        unknown: () => undefined,
      });

      return {
        bgColor: 'secondaryTopaz20',
        iconColor: 'secondaryTopazDark',
        icon: icon ?? 'interaction',
      };
    case 'NotificationFeedItemConversationNoteNew':
    case 'NotificationFeedItemConversationNoteMention':
      return {
        bgColor: 'secondaryFlower20',
        iconColor: 'secondaryFlowerDark',
        icon: 'note',
      };
    case 'NotificationFeedItemMessageDeliveryFailurePayment':
      return {
        bgColor: 'secondaryTopaz20',
        iconColor: 'secondaryTopazDark',
        icon: 'whatsappProblem',
      };
    case 'NotificationFeedItemReportReady':
      return {
        bgColor: 'secondaryTopaz20',
        iconColor: 'secondaryTopazDark',
        icon: 'report',
      };
    case 'NotificationFeedItemFacebookPageAccessTokenExpired':
    case 'NotificationFeedItemFacebookPageAccessTokenMissingPermissions':
      return {
        bgColor: 'secondaryTopaz20',
        iconColor: 'secondaryTopazDark',
        icon: 'facebookIntegrationProblem',
        $width: '28px',
        $height: '22px',
      };
  }
};

const useNotificationMessage = (
  notification: NotificationFeedItemUnion,
  userId: number,
) => {
  const {
    getContactModalUrl,
    getScheduleModalUrl,
    getTaskModalUrl,
    getContactInteractionUrl,
    getInternalNoteUrl,
    getFacebookSettingsPageUrl,
  } = useUrls();
  const { formatDate } = useFormatDate();

  return useMemo(() => {
    switch (notification.__typename) {
      case 'NotificationFeedItemContactAssigned': {
        const { contact, assignedBy } = notification;

        if (assignedBy) {
          return (
            <Trans
              i18nKey="notifications:messages.contacts.contactAssigned"
              values={{
                accountFullName: assignedBy.fullName,
                contactLabel: contact.label,
              }}
              components={[<strong />, <Link to={getContactModalUrl(contact.id)} />]}
            />
          );
        } else {
          return (
            <Trans
              i18nKey="notifications:messages.contacts.contactAutomaticallyAssigned"
              values={{
                contactLabel: contact.label,
              }}
              components={[<Link to={getContactModalUrl(contact.id)} />]}
            />
          );
        }
      }
      case 'NotificationFeedItemContactUnassigned': {
        const { contact, unassignedBy } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.contacts.contactUnassigned"
            values={{
              accountFullName: unassignedBy.fullName,
              contactLabel: contact.label,
            }}
            components={[<strong />, <Link to={getContactModalUrl(contact.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemContactUpdated': {
        const { contact, originator } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.contacts.contactUpdated"
            values={{
              accountFullName: originator.fullName,
              contactLabel: contact.label,
            }}
            components={[<strong />, <Link to={getContactModalUrl(contact.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemContactStatusChanged': {
        const { originator, contact, newStatus, oldStatus } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.contacts.contactStatusChanged"
            values={{
              accountFullName: originator.fullName,
              contactLabel: contact.label,
              newStatus: newStatus.name,
              oldStatus: oldStatus.name,
            }}
            components={[<strong />, <Link to={getContactModalUrl(contact.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemTaskAssigned': {
        const { originator, task } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.tasks.taskAssigned"
            values={{
              accountFullName: originator.fullName,
              taskTitle: task.title,
            }}
            components={[<strong />, <Link to={getTaskModalUrl(task.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemTaskUpdated': {
        const { task, originator } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.tasks.taskUpdated"
            values={{
              accountFullName: originator.fullName,
              taskTitle: task.title,
            }}
            components={[<strong />, <Link to={getTaskModalUrl(task.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemReportReady': {
        const { report, downloadUrl } = notification;
        return (
          <Trans
            i18nKey="notifications:messages.job.reportReady"
            values={{
              reportName: report.title,
            }}
            components={[
              <a
                href={downloadUrl ?? ''}
                download
                target="_blank"
                children={undefined}
                rel="noreferrer"
              />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemTaskReminder': {
        const { task } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.tasks.taskReminder"
            values={{
              taskTitle: task.title,
              dueDate: formatDate(task.dueAt, 'MMM D, hh:mm A'),
            }}
            components={[<Link to={getTaskModalUrl(task.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemTaskCompleted': {
        const { task, originator } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.tasks.taskCompleted"
            values={{
              accountFullName: originator.fullName,
              taskTitle: task.title,
            }}
            components={[<strong />, <Link to={getTaskModalUrl(task.id)} />]}
          />
        );
      }
      case 'NotificationFeedItemTaskOverdue': {
        const { task } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.tasks.taskOverdue"
            values={{
              taskTitle: task.title,
            }}
            components={{ taskLink: <Link to={getTaskModalUrl(task.id)} /> }}
          />
        );
      }
      case 'NotificationFeedItemScheduleAssigned': {
        const { originator, schedule } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.schedule.scheduleAssigned"
            values={{
              accountFullName: originator.fullName,
              scheduleTitle: schedule.title,
            }}
            components={[
              <strong />,
              <Link to={getScheduleModalUrl(schedule.id, schedule.contact?.id)} />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemScheduleUpdated': {
        const { originator, schedule } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.schedule.scheduleUpdated"
            values={{
              accountFullName: originator.fullName,
              scheduleTitle: schedule.title,
            }}
            components={[
              <strong />,
              <Link
                data-lgg-id="link-to-appointment"
                to={getScheduleModalUrl(schedule.id, schedule.contact?.id)}
              />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemScheduleCanceled': {
        const { originator, schedule } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.schedule.scheduleDeleted"
            values={{
              accountFullName: originator.fullName,
              scheduleTitle: schedule.title,
            }}
            components={[
              <strong />,
              <Link
                data-lgg-id="link-to-appointment"
                to={getScheduleModalUrl(schedule.id, schedule.contact?.id)}
              />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemScheduleCreated': {
        const { originator, schedule } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.schedule.scheduleCreated"
            values={{
              accountFullName: originator.fullName,
              scheduleTitle: schedule.title,
            }}
            components={[
              <strong />,
              <Link to={getScheduleModalUrl(schedule.id, schedule.contact?.id)} />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemCallMissed': {
        const { contact, contactInteractionPhoneCall } = notification;
        const interactionUrl = getContactInteractionUrl(contactInteractionPhoneCall.id);

        if (contactInteractionPhoneCall?.recording?.url) {
          const interactionUrl = getContactInteractionUrl(contactInteractionPhoneCall.id);

          return (
            <Trans
              i18nKey="notifications:messages.call.callMissedWithVoicemail"
              values={{
                contactLabel: contact.label,
              }}
              components={[
                <Link to={interactionUrl} />,
                <Link to={getContactModalUrl(contact.id)} />,
              ]}
            />
          );
        }

        return (
          <Trans
            i18nKey="notifications:messages.call.callMissed"
            values={{
              contactLabel: contact.label,
            }}
            components={[
              <Link to={interactionUrl} />,
              <Link to={getContactModalUrl(contact.id)} />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemContactInteractionNew': {
        const { contact, contactInteraction, contactStage, contactAssignee } =
          notification;

        if (!contactInteraction) {
          return (
            <Trans
              i18nKey="notifications:messages.contactInteraction.deletedInteraction"
              values={{
                contactLabel: contact.label,
              }}
              components={[<Link to={getContactModalUrl(contact.id)} />]}
            />
          );
        }

        const isNewContact = contact.createdAt === contactInteraction.createdAt;
        const isPhoneCallInteraction =
          contactInteraction.__typename === 'ContactInteractionPhoneCall';
        const { participatingEntity } = contactInteraction;
        const interactionUrl = getContactInteractionUrl(contactInteraction.id);

        if (!isPhoneCallInteraction) {
          if (!contactAssignee || contactAssignee.id === userId) {
            return (
              <Trans
                i18nKey={
                  isNewContact
                    ? 'notifications:messages.contactInteraction.messageFromNewContact'
                    : 'notifications:messages.contactInteraction.messageFromExistingContact'
                }
                values={{
                  contactStage: contactStage.name,
                  contactLabel: contact.label,
                }}
                components={[
                  <Link to={interactionUrl} data-lgg-id="link-to-contact-interaction" />,
                  <strong />,
                  <Link to={getContactModalUrl(contact.id)} />,
                ]}
              />
            );
          } else {
            return (
              <Trans
                i18nKey={
                  isNewContact
                    ? 'notifications:messages.contactInteraction.thirdPersonMessageFromNewContact'
                    : 'notifications:messages.contactInteraction.thirdPersonMessageFromExistingContact'
                }
                values={{
                  userFullName: participatingEntity?.label ?? contactAssignee?.fullName,
                  contactStage: contactStage.name,
                  contactLabel: contact.label,
                }}
                components={[
                  <strong />,
                  <Link to={interactionUrl} />,
                  <strong />,
                  <Link to={getContactModalUrl(contact.id)} />,
                ]}
              />
            );
          }
        } else {
          if (
            isMatching(
              inboundPhoneCallInteractionPatterns.inboundOtherStatusDialStepVoicemailWithRecording,
              contactInteraction,
            )
          ) {
            return (
              <Trans
                i18nKey={
                  isNewContact
                    ? 'notifications:messages.call.newCallWithVoicemailFromNewContact'
                    : 'notifications:messages.call.newCallWithVoicemailFromExistingContact'
                }
                values={{
                  contactStage: contactStage.name,
                  contactLabel: contact.label,
                }}
                components={[
                  <Link to={interactionUrl} />,
                  <strong />,
                  <Link to={getContactModalUrl(contact.id)} />,
                ]}
              />
            );
          }

          const answeredBy = match(contactInteraction)
            .with(
              inboundPhoneCallInteractionPatterns.inboundAnsweredWithParticipatingEntity,
              (interaction) => interaction.participatingEntity?.label,
            )
            .otherwise(() => undefined);

          if (
            answeredBy &&
            !(participatingEntity?.type === 'AGENT' && participatingEntity.id === userId)
          ) {
            return (
              <Trans
                i18nKey={
                  isNewContact
                    ? 'notifications:messages.call.answeredCallFromNewContact'
                    : 'notifications:messages.call.answeredCallFromExistingContact'
                }
                values={{
                  userFullName: answeredBy,
                  contactStage: contactStage.name,
                  contactLabel: contact.label,
                }}
                components={[
                  <strong />,
                  <Link to={interactionUrl} />,
                  <strong />,
                  <Link to={getContactModalUrl(contact.id)} />,
                ]}
              />
            );
          } else {
            return (
              <Trans
                i18nKey={
                  isNewContact
                    ? 'notifications:messages.call.notAnsweredCallFromNewContact'
                    : 'notifications:messages.call.notAnsweredCallFromExistingContact'
                }
                values={{
                  contactStage: contactStage.name,
                  contactLabel: contact.label,
                }}
                components={[
                  <Link to={interactionUrl} />,
                  <strong />,
                  <Link to={getContactModalUrl(contact.id)} />,
                ]}
              />
            );
          }
        }
      }
      case 'NotificationFeedItemConversationNoteNew': {
        const { originator, contact, conversationNote } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.conversationNotes.new"
            values={{
              originatorFullName: originator.fullName,
              contactLabel: contact.label,
            }}
            components={[
              <strong />,
              <Link
                title="note-link"
                to={getInternalNoteUrl({
                  conversationId: conversationNote.conversation.id,
                  noteId: conversationNote.id,
                  noteType: 'CONVERSATION_NOTE',
                })}
              />,
              <Link to={getContactModalUrl(contact.id)} />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemConversationNoteMention': {
        const { originator, contact, conversationNote } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.conversationNotes.mention"
            values={{
              originatorFullName: originator.fullName,
              contactLabel: contact.label,
            }}
            components={[
              <strong />,
              <Link
                title="note-link"
                to={getInternalNoteUrl({
                  conversationId: conversationNote.conversation.id,
                  noteId: conversationNote.id,
                  noteType: 'CONVERSATION_NOTE',
                })}
              />,
              <Link to={getContactModalUrl(contact.id)} />,
            ]}
          />
        );
      }
      case 'NotificationFeedItemMessageDeliveryFailurePayment': {
        const { contactInteraction, waba } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.messageDeliveryFailure.whatsAppPayment"
            components={{
              interactionLink: contactInteraction ? (
                <Link to={getContactInteractionUrl(contactInteraction.id)} />
              ) : (
                <></>
              ),
              wabaLink: (
                <a
                  href={`https://business.facebook.com/billing_hub/payment_settings?asset_id=${waba}`}
                  target="_blank"
                  children={undefined}
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        );
      }
      case 'NotificationFeedItemFacebookPageAccessTokenMissingPermissions':
      case 'NotificationFeedItemFacebookPageAccessTokenExpired': {
        const { pageName } = notification;

        return (
          <Trans
            i18nKey="notifications:messages.facebookPage.accessTokenExpired"
            values={{
              pageName,
            }}
            components={{
              strong: <strong />,
              pageLink: <Link to={getFacebookSettingsPageUrl()} />,
            }}
          />
        );
      }
    }
  }, [
    formatDate,
    getContactInteractionUrl,
    getContactModalUrl,
    getFacebookSettingsPageUrl,
    getInternalNoteUrl,
    getScheduleModalUrl,
    getTaskModalUrl,
    notification,
    userId,
  ]);
};
type UseNotificationOptionsProps = {
  handleSetCleared: () => Promise<void>;
  isCleared: boolean;
  notification: NotificationFeedItemUnion;
};

const useNotificationOptions = ({
  handleSetCleared,
  isCleared,
  notification,
}: UseNotificationOptionsProps) => {
  const { t } = useTranslation(['common', 'notifications']);
  const { getInternalNoteUrl } = useUrls();
  const history = useHistory();
  const openCallContactModal = useOpenCallContactModal();

  return useMemo(() => {
    const options = [
      {
        icon: isCleared ? 'restart' : 'circularCheck',
        label: isCleared
          ? t('notifications:itemOptions.unclearNotification')
          : t('notifications:itemOptions.clearNotification'),
        'data-lgg-id': 'notification-item-option-toggle-cleared',
        onClick: async (e) => {
          e.stopPropagation();
          await handleSetCleared();
        },
      },
      // TODO: Remove these comments when mute notification type feature is available
      // {
      //   icon: 'notificationMuted',
      //   label: t('notifications:itemOptions.turnOffThisNotificationType'),
      //   testId: 'notifications-item-option-turn-off-type',
      //   onClick: () => {},
      // },
    ];

    if (
      notification.__typename === 'NotificationFeedItemCallMissed' ||
      (notification.__typename === 'NotificationFeedItemContactInteractionNew' &&
        notification.contactInteraction &&
        notification.contactInteraction.__typename === 'ContactInteractionPhoneCall')
    ) {
      options.push({
        icon: 'callBack',
        label: t('notifications:itemOptions.callBackContact'),
        'data-lgg-id': 'notification-item-option-call-back-contact',
        onClick: async (e) => {
          e.stopPropagation();
          void openCallContactModal(notification.contact.id);
        },
      });
    }

    if (notification.__typename === 'NotificationFeedItemConversationNoteMention') {
      options.push({
        icon: 'reply',
        label: t('notifications:itemOptions.reply'),
        'data-lgg-id': 'notification-item-option-reply-note',
        onClick: async (e) => {
          e.stopPropagation();
          history.push(
            getInternalNoteUrl({
              conversationId: notification.conversationNote.conversation.id,
              noteId: notification.conversationNote.id,
              isReplying: true,
              noteType: 'CONVERSATION_NOTE',
            }),
          );
        },
      });
    }

    return options;
  }, [
    openCallContactModal,
    getInternalNoteUrl,
    handleSetCleared,
    history,
    isCleared,
    notification,
    t,
  ]);
};

const ContactInteractionPreview = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain2};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.storm};
  cursor: pointer;
  display: inline-block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  letter-spacing: -0.18px;
  line-height: 21px;
  margin-top: 5px;
  max-width: 250px;
  overflow: hidden;
  padding: 6px 10px 8px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: ${({ theme }) => theme.colors.storm};
  }
`;

const ContactInteractionPreviewIconContainer = styled(ContactInteractionPreview)`
  display: inline-flex;
  align-items: center;
`;

const PreviewIcon = styled(Icon)`
  svg {
    width: 19px;
    height: 19px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const PreviewIconLabel = styled.span`
  margin-left: 10px;
`;

type NotificationItemProps = {
  userId: number;
  notification: NotificationFeedItemUnion;
  handleNotificationUpdated?: (notification: NotificationFeedItemUnion) => Promise<void>;
  interactedWith?: boolean;
};

export const NotificationItem = memo<NotificationItemProps>(
  ({ notification, handleNotificationUpdated, userId }) => {
    const circleProps = resolveIconProps(notification);
    const history = useHistory();
    const { formatLogDate } = useFormatDate();
    const { getContactInteractionUrl, getInternalNoteUrl } = useUrls();
    const { t } = useTranslation(['common', 'notifications', 'conversations']);
    const notificationMessage = useNotificationMessage(notification, userId);
    const setCleared = useNotificationSetClearedStatus();
    const setStatus = useNotificationSetStatus();
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));
    const interactedWith =
      notification.status === 'INTERACTED' || notification.status === 'CLEARED';
    const handleCleared = async () => {
      await setCleared({
        isCleared: !(notification.status === 'CLEARED'),
        notificationIds: [`${notification.id}`],
        onCompleted: async () => {
          await handleNotificationUpdated?.(notification);
        },
      });
    };
    const handleSetInteractedStatus = async () => {
      if (notification.status === 'CLEARED') {
        return;
      }
      await setStatus({
        status: 'INTERACTED',
        notificationIds: [`${notification.id}`],
        onCompleted: async () => {
          await handleNotificationUpdated?.(notification);
        },
      });
    };

    const previewMessage = useMemo(() => {
      return match(notification)
        .with(
          {
            __typename: 'NotificationFeedItemContactInteractionNew',
            contactInteraction: P.nullish,
          },
          () => {
            return (
              <div>
                <ContactInteractionPreview
                  data-lgg-id="notification-text-preview"
                  onClick={() => {}}
                >
                  <i>{t('conversations:contactInteractionPreview.deletedInteraction')}</i>
                </ContactInteractionPreview>
              </div>
            );
          },
        )
        .with(
          {
            __typename: 'NotificationFeedItemContactInteractionNew',
            contactInteraction: {
              __typename: P.union(
                'ContactInteractionFacebookMessenger',
                'ContactInteractionSms',
                'ContactInteractionWhatsapp',
                'ContactInteractionInstagram',
              ),
            },
          },
          (notification) => {
            const interactionUrl = getContactInteractionUrl(
              notification.contactInteraction.id,
            );

            const interactionPreviewClickHandler = () => {
              history.push(interactionUrl);
            };

            return match(notification.contactInteraction)
              .with(
                {
                  message: P.not(P.nullish),
                },
                (contactInteraction) => {
                  return (
                    <div>
                      <ContactInteractionPreview
                        data-lgg-id="notification-text-preview"
                        onClick={interactionPreviewClickHandler}
                      >
                        {contactInteraction.message.content}
                      </ContactInteractionPreview>
                    </div>
                  );
                },
              )
              .otherwise((contactInteraction) => {
                const attachments = contactInteraction.attachments;

                if (!attachments) {
                  return null;
                }

                const lastAttachment = attachments[0];

                const attachedImageHandler = () => ({
                  icon: 'attachedImage',
                  label: 'conversations:contactInteractionPreview.image',
                });

                const { icon, label } = matchConversationAttachmentExhaustive(
                  lastAttachment,
                  {
                    image: attachedImageHandler,
                    sticker: attachedImageHandler,
                    video: () => ({
                      icon: 'mediatypeVideo',
                      label: 'conversations:contactInteractionPreview.video',
                    }),
                    audio: () => ({
                      icon: 'attachedAudio',
                      label: 'conversations:contactInteractionPreview.audio',
                    }),
                    document: () => ({
                      icon: 'attachment',
                      label: 'conversations:contactInteractionPreview.file',
                    }),
                    location: () => ({
                      icon: 'location',
                      label: 'conversations:contactInteractionPreview.location',
                    }),
                    contacts: () => ({
                      icon: 'contact',
                      label: 'conversations:contactInteractionPreview.contacts',
                    }),
                  },
                );

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const translatedLabel = t(label);

                return (
                  <div>
                    <ContactInteractionPreviewIconContainer
                      data-lgg-id="notification-icon-preview"
                      onClick={interactionPreviewClickHandler}
                    >
                      <PreviewIcon type={icon} />
                      <PreviewIconLabel>{translatedLabel}</PreviewIconLabel>
                    </ContactInteractionPreviewIconContainer>
                  </div>
                );
              });
          },
        )
        .with(
          {
            __typename: 'NotificationFeedItemConversationNoteMention',
          },
          (notification) => {
            const interactionUrl = getInternalNoteUrl({
              conversationId: notification.conversationNote.conversation.id,
              noteId: notification.conversationNote.id,
              noteType: 'CONVERSATION_NOTE',
            });

            const interactionPreviewClickHandler = () => {
              history.push(interactionUrl);
            };

            return (
              <div>
                <ContactInteractionPreview
                  data-lgg-id="notification-text-preview"
                  onClick={interactionPreviewClickHandler}
                >
                  {notification.conversationNote.text}
                </ContactInteractionPreview>
              </div>
            );
          },
        )
        .otherwise(() => null);
    }, [getContactInteractionUrl, getInternalNoteUrl, history, notification, t]);
    const notificationOptions = useNotificationOptions({
      handleSetCleared: handleCleared,
      isCleared: notification.status === 'CLEARED',
      notification,
    });

    const moreOptionsIcon = useMemo(
      () => (
        <MoreOptionsIcon type="moreOptions" lggTestId="notification-item-more-options" />
      ),
      [],
    );

    return (
      <StyledNotificationBasedItem
        onClick={handleSetInteractedStatus}
        interactedWith={interactedWith}
        className={c({ 'interacted-with': interactedWith })}
        data-lgg-id={`notification-item-${notification.id}`}
      >
        <LggCircleIcon
          {...circleProps}
          data-lgg-id="notification-icon"
          data-icon={circleProps.icon}
        />
        <MessageContainer>
          <Message data-lgg-id="notification-message">{notificationMessage}</Message>
          {previewMessage}
          <Date data-lgg-id="notification-date">
            {formatLogDate(notification.occurredAt)}
          </Date>
        </MessageContainer>
        {breakpointUpMd ? (
          <LggOptionsDropdownButtonWithCustomTrigger
            options={notificationOptions}
            customTrigger={moreOptionsIcon}
            visibilityHandler={visibilityHandler}
          />
        ) : (
          <>
            <div onClick={() => visibilityHandler.setVisible(!visibilityHandler.visible)}>
              {moreOptionsIcon}
            </div>
            <OptionsBottomDrawer
              visible={visibilityHandler.visible}
              title={t('common:options')}
              onClose={visibilityHandler.close}
              options={notificationOptions}
            />
          </>
        )}
      </StyledNotificationBasedItem>
    );
  },
);

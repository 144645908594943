import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import { PageContainer } from 'src/components/layout/page-containers';
import { DesktopCalendarView } from 'src/components/pages/calendar/components/desktop-calendar-view';
import { MobileCalendarView } from 'src/components/pages/calendar/components/mobile/mobile-calendar-view';
import { useCalendarSelectedDay } from 'src/components/pages/calendar/components/shared/hooks';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export const CalendarPage = () => {
  const { t } = useTranslation(['calendar']);
  const breakpointUpMd = useBreakpoint(up('md'));

  useCalendarSelectedDay();
  useAddBreadcrumb(t('calendar:pageBreadcrumb'));

  return (
    <PageContainer>
      {breakpointUpMd ? <DesktopCalendarView /> : <MobileCalendarView />}
    </PageContainer>
  );
};

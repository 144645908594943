import React, { useEffect, useMemo } from 'react';
import { match } from 'ts-pattern';
import { openSignInWindow } from './actions';

type CallbackObject = {
  client: string;
  channels: string[];
  revokedChannels?: string[];
};

type QueryParameters = {
  email?: string;
  name?: string;
  state?: string;
  redirect_url?: string;
  hosting_type?: string;
  partner?: string;
  next?: string;
  plan_selection?: string;
  lang?: 'de' | 'en' | 'pt';
  connect_client_user?: string;
  client_id?: string;
};

type IAddNewResourceButton = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  partnerId: string;
  callback: ValueChanged<CallbackObject>;
  requestedNumber?: string;
  label?: React.ReactNode;
  env?: 'local' | 'staging' | 'rc' | 'prod';
  queryParameters?: QueryParameters;
};

const AddNewResourceButton = ({
  className,
  partnerId,
  callback,
  requestedNumber,
  label,
  env = 'prod',
  queryParameters,
  ...props
}: IAddNewResourceButton) => {
  const permissionURL = useMemo(() => {
    const baseUrl = match(env)
      .with('local', () => 'http://0.0.0.0:8082')
      .with('staging', () => 'https://admin.hub-staging.360dialog.io')
      .with('rc', () => 'https://rc-admin.360dialog.io')
      .otherwise(() => 'https://hub.360dialog.com');

    let tempURL = `${baseUrl}/dashboard/app/${partnerId}/permissions${
      requestedNumber ? `?number=${requestedNumber}` : ''
    }`;

    if (queryParameters) {
      const searchParams = new URLSearchParams();

      for (const [key, value] of Object.entries(queryParameters)) {
        if (value) {
          searchParams.append(key, value);
        }
      }

      const queryString = searchParams.toString();
      if (queryString) {
        // queryString could bring an empty string at this point.
        tempURL = `${tempURL}${requestedNumber ? `&` : `?`}${queryString}`;
      }
    }

    return tempURL;
  }, [env, partnerId, queryParameters, requestedNumber]);

  const getUrlParameter = (params: string, name: string) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  useEffect(() => {
    const params = window.location.search;
    const client = getUrlParameter(params, 'client');
    const channels = getUrlParameter(params, 'channels');
    const revokedChannels = getUrlParameter(params, 'revoked');

    if (client && channels) {
      const channelsArray = channels.slice(1, -1).split(',');

      const callbackObj: CallbackObject = {
        client: client,
        channels: channelsArray,
      };

      if (revokedChannels) {
        const revokedChannelsArray = revokedChannels.slice(1, -1).split(',');
        callbackObj['revokedChannels'] = revokedChannelsArray;
      }
      callback(callbackObj);
    }

    // send to parent (opener) window and close small window
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    }
  }, [callback]);

  return (
    <button
      className={className ? className : '360dialog-connect-button'}
      onClick={() =>
        openSignInWindow(permissionURL, 'connect-360dialog', window.location.origin)
      }
      {...props}
    >
      {label}
    </button>
  );
};

export default AddNewResourceButton;

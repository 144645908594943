import React, { memo } from 'react';
import styled from 'styled-components';
import { ContactInteractionDirection } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  IsomorphicAttachmentFileItemProps,
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import {
  ImageWrapper,
  ItemCaption,
  RepliedMessageRenderer,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { AttachmentPreviewModal } from 'src/components/pages/conversations/components/general/attachment-preview-modal';
import { useVisible } from 'src/hooks/use-visible';

const ImageContainer = styled(ImageWrapper)<{ direction: ContactInteractionDirection }>`
  border-radius: ${(props) =>
    props.direction === 'INBOUND' ? '6px 6px 6px 0' : '6px 6px 0 6px'};
`;

const ImageAttachmentBubble = styled(BubbleTypeAttachmentContainer)`
  padding: 4px 4px 10px 4px;
  max-width: 280px;
`;

const StyledAttachmentDateAndStatus = styled(AttachmentDateAndStatus)`
  margin-left: 5px;
`;

const StyledRepliedMessageRenderer = styled(RepliedMessageRenderer)`
  margin: -5px -5px 10px;
`;

export const ImageAttachmentItem = memo<
  IsomorphicAttachmentFileItemProps & {
    onOpenInteractionDetailsModalVisibility: VoidFunction;
    showDeliveryStatus: boolean;
  }
>(
  ({
    attachment,
    attachmentDate,
    onOpenInteractionDetailsModalVisibility,
    showDeliveryStatus,
    contactInteraction,
  }) => {
    const {
      visible: isAttachmentPreviewVisible,
      show: showPreview,
      close: closePreview,
    } = useVisible();

    const {
      filename = '',
      type,
      url,
      mime: mimeType,
      status = null,
      caption,
    } = attachment;
    const { direction } = contactInteraction;

    return (
      <ImageAttachmentBubble direction={direction}>
        <StyledRepliedMessageRenderer contactInteraction={contactInteraction} />
        <ImageContainer direction={direction} onClick={showPreview}>
          <img src={url} alt={filename ?? ''} data-lgg-id="image-attachment-item" />
        </ImageContainer>
        <AttachmentPreviewModal
          type={type}
          url={url}
          name={filename}
          mimeType={mimeType}
          visible={isAttachmentPreviewVisible}
          onClose={closePreview}
          onShowDetails={() => onOpenInteractionDetailsModalVisibility()}
          testId="conversations-attachment-preview"
        />
        {caption && (
          <ItemCaption data-lgg-id="item-caption" direction={direction}>
            <TextFormatter>{caption}</TextFormatter>
          </ItemCaption>
        )}
        <StyledAttachmentDateAndStatus
          attachmentDate={attachmentDate}
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
          floating={false}
        />
      </ImageAttachmentBubble>
    );
  },
);

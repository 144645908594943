import { Injectable, StatefulService } from 'react-service-locator';

export type HeaderAction = {
  label: string;
  icon: string;
  type: string;
  href: string;
  color?: string;
  className?: string;
  isLegacy?: boolean;
  onClick?: () => void;
};

@Injectable()
export class HeaderActionsService extends StatefulService<{ actions: HeaderAction[] }> {
  constructor() {
    super({ actions: [] });
  }

  set = (actions: HeaderAction[]) => {
    this.setState({ actions });
  };
}

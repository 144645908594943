import { useCallback, useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import { Capacitor, PermissionState } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import OneSignal from 'onesignal-cordova-plugin';

export const useNotificationPermission = () => {
  const [permissionState, setPermissionState] = useState<PermissionState | null>(null);
  const checkPermission = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    if (Capacitor.getPlatform() === 'android') {
      const result = await LocalNotifications.checkPermissions();
      setPermissionState(result.display);
    } else {
      const result = await PushNotifications.checkPermissions();
      setPermissionState(result.receive);
    }
  }, []);

  const requestPermission = useCallback(async () => {
    OneSignal.promptForPushNotificationsWithUserResponse(() => {
      void checkPermission();
    });
  }, [checkPermission]);

  const openSettings = useCallback(async () => {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      OneSignal.addPermissionObserver(() => {
        void checkPermission();
      });

      void App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          void checkPermission();
        }
      });

      void checkPermission();
    }
  }, [checkPermission]);

  return { permissionState, checkPermission, requestPermission, openSettings };
};

import React, { memo } from 'react';
import Popover, { PopoverProps } from 'antd/lib/popover';
import c from 'classnames';

export type PopoverV2Props = PopoverProps & {
  hideArrow?: boolean;
};

export const PopoverV2 = memo<PopoverV2Props>(
  ({ children, hideArrow = false, overlayClassName = '', ...rest }) => {
    return (
      <Popover
        overlayClassName={`${c({
          'lgg-popover': true,
          'arrow-hidden': hideArrow,
        })} ${overlayClassName}`}
        {...rest}
      >
        {children}
      </Popover>
    );
  },
);

import { memo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { ContactInteractionChannelAvailabilityForContact } from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { UnDecoratedTextInput } from 'src/components/general/inputs/text-input';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useVisible } from 'src/hooks/use-visible';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const OptionContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 10px 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }
`;

const DevToolsIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  svg:hover path {
    fill: ${({ theme }) => theme.colors.smalt};
  }
`;

const MUTATION_DEV_CHANNELS_WHATSAPP_CREATE_COMPANY_RESOURCE = gql`
  mutation DevChannelsWhatsappCreateCompanyResource(
    $companyId: ID!
    $conversationId: ID!
  ) {
    devChannelsWhatsappCreateCompanyResource(
      companyId: $companyId
      conversationId: $conversationId
    )
  }
`;

const MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_MESSAGE_FROM_CONTACT = gql`
  mutation DevChannelsWhatsappReceiveMessageFromContact(
    $companyId: ID!
    $conversationId: ID!
    $message: String!
    $resourceId: ID!
  ) {
    devChannelsWhatsappReceiveMessageFromContact(
      resourceId: $resourceId
      companyId: $companyId
      conversationId: $conversationId
      message: $message
    )
  }
`;

const MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_LOCATION_MESSAGE_FROM_CONTACT = gql`
  mutation DevChannelsWhatsappReceiveLocationMessageFromContact(
    $companyId: ID!
    $conversationId: ID!
    $location: JSON!
    $resourceId: ID!
  ) {
    devChannelsWhatsappReceiveLocationMessageFromContact(
      resourceId: $resourceId
      companyId: $companyId
      conversationId: $conversationId
      location: $location
    )
  }
`;

const MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_CONTACTS_MESSAGE_FROM_CONTACT = gql`
  mutation DevChannelsWhatsappReceiveLocationMessageFromContact(
    $companyId: ID!
    $conversationId: ID!
    $contacts: JSON!
    $resourceId: ID!
  ) {
    devChannelsWhatsappReceiveContactsMessageFromContact(
      resourceId: $resourceId
      companyId: $companyId
      conversationId: $conversationId
      contacts: $contacts
    )
  }
`;

const MUTATION_DEV_CREATE_CONTACT_INTERACTION_FOR_CONVERSATION = gql`
  mutation DevCreateContactInteractionForConversation(
    $conversationId: ID!
    $contactInteractionType: String!
  ) {
    devCreateContactInteractionForConversation(
      conversationId: $conversationId
      contactInteractionType: $contactInteractionType
    )
  }
`;

type AddContactInteractionModalProps = {
  channelAvailability?: ContactInteractionChannelAvailabilityForContact;
  conversationId: string;
};

export const AddContactInteractionModal = memo<AddContactInteractionModalProps>(
  ({ channelAvailability, conversationId }) => {
    const showNotification = useShowNotification();
    const { show, close, visible } = useVisible();
    const [search, setSearch] = useState('');
    const [whatsappResourceId, setWhatsappResourceId] = useState<string | null>(() => {
      if (channelAvailability?.isAvailable && channelAvailability.resources.length) {
        return channelAvailability.resources[0].id;
      }

      return null;
    });
    const { id } = useCurrentInstitution();
    const [createWhatsAppChannelResource] = useMutation(
      MUTATION_DEV_CHANNELS_WHATSAPP_CREATE_COMPANY_RESOURCE,
      {
        variables: {
          companyId: id,
          conversationId: conversationId,
        },
      },
    );

    const [simulateReceiveMessageFromContact] = useMutation(
      MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_MESSAGE_FROM_CONTACT,
    );

    const [simulateReceiveLocationMessageFromContact] = useMutation(
      MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_LOCATION_MESSAGE_FROM_CONTACT,
    );

    const [simulateReceiveContactsMessageFromContact] = useMutation(
      MUTATION_DEV_CHANNELS_WHATSAPP_RECEIVE_CONTACTS_MESSAGE_FROM_CONTACT,
    );

    const [createContactInteractionForConversation] = useMutation(
      MUTATION_DEV_CREATE_CONTACT_INTERACTION_FOR_CONVERSATION,
    );

    const createMessage = async (resourceId: string) => {
      await simulateReceiveMessageFromContact({
        variables: {
          companyId: id,
          conversationId: conversationId,
          resourceId,
          message: 'testing',
        },
        onCompleted: () => {
          showNotification({
            title: 'Dev Tools',
            message: 'Whatsapp contact interaction created',
            type: 'success',
          });
        },
      });
    };

    const createLocationMessage = async (resourceId: string) => {
      await simulateReceiveLocationMessageFromContact({
        variables: {
          companyId: id,
          conversationId: conversationId,
          resourceId,
          location: {
            latitude: 18.319736480713,
            longitude: -66.021339416504,
            name: 'Coldstone Creamery - San Juan',
            url: 'https://foursquare.com/v/50830bc7e4b05283709d6c00',
          },
        },
        onCompleted: () => {
          showNotification({
            title: 'Dev Tools',
            message: 'Whatsapp location contact interaction created',
            type: 'success',
          });
        },
      });
    };

    const createContactsMessage = async (resourceId: string) => {
      await simulateReceiveContactsMessageFromContact({
        variables: {
          companyId: id,
          conversationId: conversationId,
          resourceId,
          contacts: [
            {
              addresses: [
                {
                  city: 'Menlo Park',
                  country: 'United States',
                  country_code: 'us',
                  state: 'CA',
                  street: '1 Hacker Way',
                  type: 'WORK',
                  zip: '94025',
                },
              ],
              birthday: '2012-08-18',
              emails: [
                {
                  email: 'kfish@fb.com',
                  type: 'WORK',
                },
              ],
              ims: [
                {
                  service: 'AIM',
                  user_id: 'kfish',
                },
              ],
              name: {
                first_name: 'Kerry',
                formatted_name: 'Kerry Fisher',
                last_name: 'Fisher',
              },
              org: {
                company: 'Meta',
                department: 'WhatsApp',
              },
              phones: [
                {
                  phone: '+1 (940) 555-1234',
                  type: 'CELL',
                },
                {
                  phone: '+1 (650) 555-1234',
                  type: 'WORK',
                  wa_id: '16505551234',
                },
              ],
              urls: [
                {
                  url: 'https://www.facebook.com',
                  type: 'WORK',
                },
              ],
            },
          ],
        },
        onCompleted: () => {
          showNotification({
            title: 'Dev Tools',
            message: 'Whatsapp contacts contact interaction created',
            type: 'success',
          });
        },
      });
    };

    const createLocationMessageWithAddress = async (resourceId: string) => {
      await simulateReceiveLocationMessageFromContact({
        variables: {
          companyId: id,
          conversationId: conversationId,
          resourceId,
          location: {
            latitude: 18.319736480713,
            longitude: -66.021339416504,
            address: 'Some address',
            name: 'Coldstone Creamery - San Juan',
            url: 'https://foursquare.com/v/50830bc7e4b05283709d6c00',
          },
        },
        onCompleted: () => {
          showNotification({
            title: 'Dev Tools',
            message: 'Whatsapp location contact interaction created',
            type: 'success',
          });
        },
      });
    };

    const createWhatsAppInteraction = async (
      messageCallback: (string) => Promise<void>,
    ) => {
      if (!whatsappResourceId) {
        const result = await createWhatsAppChannelResource();

        const resourceId =
          result.data.devChannelsWhatsappCreateCompanyResource.resource.id;

        if (result.data.devChannelsWhatsappCreateCompanyResource.resource.id) {
          setWhatsappResourceId(resourceId);
          await messageCallback(resourceId);
        }
      } else {
        await messageCallback(whatsappResourceId);
      }
    };

    const createContactInteraction = async (
      contactInteractionType:
        | 'phoneInboundUnansweredWithDialStepMenu'
        | 'phoneInboundUnansweredWithDialStepMessage'
        | 'phoneInboundUnansweredWithDialStepDial'
        | 'phoneInboundUnansweredWithDialStepVoicemailWithRecording'
        | 'phoneInboundUnansweredWithDialStepVoicemailWithoutRecording'
        | 'phoneInboundUnansweredWithoutDialStep'
        | 'phoneInboundOtherStatusWithDialStepMenu'
        | 'phoneInboundOtherStatusWithDialStepMessage'
        | 'phoneInboundOtherStatusWithDialStepDial'
        | 'phoneInboundOtherStatusWithDialStepVoicemailWithRecording'
        | 'phoneInboundOtherStatusWithDialStepVoicemailWithoutRecording'
        | 'phoneInboundOtherStatusWithoutDialStep'
        | 'phoneOutboundWithCancelReason'
        | 'phoneOutboundUnansweredWithBusyReason'
        | 'phoneOutboundUnansweredWithFailedReason'
        | 'phoneOutboundUnansweredWithOtherReason'
        | 'phoneOutboundUnansweredWithoutReason'
        | 'phoneOutboundWithoutStatus'
        | 'phoneOutboundAnswered',
    ) => {
      await createContactInteractionForConversation({
        variables: {
          conversationId: conversationId,
          contactInteractionType,
        },
        onCompleted: () => {
          showNotification({
            title: 'Dev Tools',
            message: 'Contact interaction created',
            type: 'success',
          });
        },
      });
    };

    const options = [
      {
        title: 'Add WhatsApp interaction',
        handler: () => createWhatsAppInteraction(createMessage),
      },
      {
        title: 'Add WhatsApp Location interaction',
        handler: () => createWhatsAppInteraction(createLocationMessage),
      },
      {
        title: 'Add WhatsApp Contacts interaction',
        handler: () => createWhatsAppInteraction(createContactsMessage),
      },
      {
        title: 'Add WhatsApp Location interaction with address',
        handler: () => createWhatsAppInteraction(createLocationMessageWithAddress),
      },
      {
        title: 'Add Phone Call Inbound Unanswered With Dial Step Menu',
        handler: () => createContactInteraction('phoneInboundUnansweredWithDialStepMenu'),
      },
      {
        title: 'Add Phone Call Inbound Unanswered With Dial Step Message',
        handler: () =>
          createContactInteraction('phoneInboundUnansweredWithDialStepMessage'),
      },
      {
        title: 'Add Phone Call Inbound Unanswered With Dial Step Dial',
        handler: () => createContactInteraction('phoneInboundUnansweredWithDialStepDial'),
      },
      {
        title:
          'Add Phone Call Inbound Unanswered With Dial Step Voicemail with Recording',
        handler: () =>
          createContactInteraction(
            'phoneInboundUnansweredWithDialStepVoicemailWithRecording',
          ),
      },
      {
        title:
          'Add Phone Call Inbound Unanswered With Dial Step Voicemail without Recording',
        handler: () =>
          createContactInteraction(
            'phoneInboundUnansweredWithDialStepVoicemailWithoutRecording',
          ),
      },
      {
        title: 'Add Phone Call Inbound Unanswered Without Dial Step',
        handler: () => createContactInteraction('phoneInboundUnansweredWithoutDialStep'),
      },
      {
        title: 'Add Phone Call Inbound No Status With Dial Step Menu',
        handler: () =>
          createContactInteraction('phoneInboundOtherStatusWithDialStepMenu'),
      },
      {
        title: 'Add Phone Call Inbound No Status With Dial Step Message',
        handler: () =>
          createContactInteraction('phoneInboundOtherStatusWithDialStepMessage'),
      },
      {
        title: 'Add Phone Call Inbound No Status With Dial Step Dial',
        handler: () =>
          createContactInteraction('phoneInboundOtherStatusWithDialStepDial'),
      },
      {
        title: 'Add Phone Call Inbound No Status With Dial Step Voicemail with Recording',
        handler: () =>
          createContactInteraction(
            'phoneInboundOtherStatusWithDialStepVoicemailWithRecording',
          ),
      },
      {
        title:
          'Add Phone Call Inbound No Status With Dial Step Voicemail without Recording',
        handler: () =>
          createContactInteraction(
            'phoneInboundOtherStatusWithDialStepVoicemailWithoutRecording',
          ),
      },
      {
        title: 'Add Phone Call Inbound No Status Without Dial Step',
        handler: () => createContactInteraction('phoneInboundOtherStatusWithoutDialStep'),
      },
      {
        title: 'Add Phone Call Outbound Unanswered With cancel reason',
        handler: () => createContactInteraction('phoneOutboundWithCancelReason'),
      },
      {
        title: 'Add Phone Call Outbound Unanswered With busy reason',
        handler: () => createContactInteraction('phoneOutboundUnansweredWithBusyReason'),
      },
      {
        title: 'Add Phone Call Outbound Unanswered With failed reason',
        handler: () =>
          createContactInteraction('phoneOutboundUnansweredWithFailedReason'),
      },
      {
        title: 'Add Phone Call Outbound Unanswered With no answer reason',
        handler: () => createContactInteraction('phoneOutboundUnansweredWithOtherReason'),
      },
      {
        title: 'Add Phone Call Outbound Unanswered Without reason',
        handler: () => createContactInteraction('phoneOutboundUnansweredWithoutReason'),
      },
      {
        title: 'Add Phone Call Outbound Without status',
        handler: () => createContactInteraction('phoneOutboundWithoutStatus'),
      },
      {
        title: 'Add Phone Call Outbound Answered',
        handler: () => createContactInteraction('phoneOutboundAnswered'),
      },
    ];

    const filteredOptions = options.filter((option) => {
      const tokens = option.title.split(' ');
      return tokens
        .map((token) => token.toLowerCase())
        .some((token) => token.includes(search.toLowerCase()));
    });

    return (
      <>
        <DevToolsIcon onClick={show} type="debug" />
        <LggModal onClose={close} visible={visible} closeIcon={<div />}>
          <ModalContainer>
            <UnDecoratedTextInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
            <div style={{ height: '10px' }} />
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <OptionContainer
                  key={option.title}
                  onClick={() => {
                    void option.handler();
                    close();
                  }}
                >
                  {option.title}
                </OptionContainer>
              ))
            ) : (
              <div>No results</div>
            )}
          </ModalContainer>
        </LggModal>
      </>
    );
  },
);

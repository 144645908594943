import React, { memo, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import {
  BackLink,
  FormContainer,
  SubmitButton,
} from 'src/components/pages/account/recover/components/shared';
import { AccountTextInput } from 'src/components/pages/account/shared';
import { ApiService } from 'src/services/http/api.service';

export const RecoverUsernameForm = memo(() => {
  const { t } = useTranslation(['account', 'common']);
  const history = useHistory();
  const showNotification = useShowNotification();

  const schema = useMemo(
    () =>
      zod.object({
        email: zod
          .string()
          .nonempty(t('common:validations.fieldRequired'))
          .email(t('common:validations.invalidEmail')),
      }),
    [t],
  );

  type FormValues = zod.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const apiService = useService(ApiService);
  const { triggerError } = useErrorHandling();

  const onSubmit = async ({ email }: FormValues) => {
    try {
      await apiService.post<any, { email: string }>(
        '/account/recover-usernames/initiate',
        { email },
      );

      showNotification({
        type: 'success',
        title: t('account:recoverPage.messages.emailSent'),
      });

      history.push('/account/login');
    } catch (e) {
      triggerError(e);
    }
  };

  return (
    <FormContainer data-lgg-id="recover-username-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <AccountTextInput
              data-lgg-id="email"
              autoComplete="email"
              label={t('common:email')}
              placeholder={t('common:enterEmail')}
              error={errors.email?.message}
              {...field}
            />
          )}
        />
        <SubmitButton data-lgg-id="submit" type="submit" loading={isSubmitting}>
          {t('account:recoverPage.recoverUsername')}
        </SubmitButton>
      </form>
      <BackLink />
    </FormContainer>
  );
});

import React, { useLayoutEffect } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';
import { AlignMiddle } from 'src/components/general/align-middle';
import { Icon } from 'src/components/general/icon';

export function useHandleDataTable(content: HTMLDivElement) {
  useLayoutEffect(() => {
    const dataTable = content.getElementsByClassName('table');

    if (!dataTable.length) {
      return;
    }

    const sortHeaderLink = dataTable[0].getElementsByClassName(
      'yammon-table-column-link',
    );

    //Table header
    Array.from(sortHeaderLink).forEach((link: Element) => {
      const sortImage = link.getElementsByTagName('img')[0];
      let sortDirection = 'default';

      if (sortImage) {
        const imageSource = sortImage.getAttribute('src');
        sortDirection = imageSource && imageSource.indexOf('asc') >= 0 ? 'asc' : 'desc';
        sortImage.remove();
      }

      render(
        <AlignMiddle style={{ display: 'table' }}>
          <span style={{ display: 'table-cell' }}>{link.innerHTML}</span>
          <SortContainer>
            <SortArrowContainer>
              <SortArrow
                active={sortDirection === 'desc'}
                style={{ transform: 'rotate(180deg)' }}
                type="sort"
              />
            </SortArrowContainer>
            <SortArrowContainer>
              <SortArrow active={sortDirection === 'asc'} type="sort" />
            </SortArrowContainer>
          </SortContainer>
        </AlignMiddle>,
        link,
      );
    });

    document
      .querySelectorAll('.yammon-table-column-last .btn-group .btn')
      .forEach((actionButton: Element) => {
        actionButton.classList.remove('btn-default');
        actionButton.classList.remove('btn-xs');
        actionButton.classList.add('lgg-icon-only');

        actionButton.querySelectorAll('.caret, .fa, span').forEach((el) => {
          el.remove();
        });

        render(<Icon type="moreOptions" />, actionButton);
      });
  }, [content]);
}

const SortContainer = styled.div`
  padding-left: 10px;
  display: table-cell;
  vertical-align: middle;
`;

const SortArrowContainer = styled.div`
  line-height: 0;
  margin: 2px 0;
  height: 4px;
`;

const SortArrow = styled(Icon)<{ active: boolean }>`
  color: ${(props) =>
    props.active ? 'rgba(152, 169, 188, 0.6)' : 'rgba(152, 169, 188, 0.3)'} !important;
`;

import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import useModal from 'antd/lib/modal/useModal';
import notification from 'antd/lib/notification';
import { UnregisterCallback } from 'history';
import styled from 'styled-components';
import { ModalErrorContent } from 'src/components/error-boundary/helpers';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { ForbiddenErrorPage } from 'src/components/pages/errors/forbidden-error-page';
import { InternalErrorPage } from 'src/components/pages/errors/internal-error-page';
import { NotFoundPage } from 'src/components/pages/errors/not-found-page';
import { ErrorService } from 'src/services/error.service';
import { isApiErrorData, isHttpApiError } from 'src/services/http/helpers';
import { SessionService } from 'src/services/session.service';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import { AuthorizationError } from 'src/utils/errors/authorization-error';
import { DesktopOnlyPageError } from 'src/utils/errors/desktop-only-page-error';
import { NetworkError } from 'src/utils/errors/network-error';
import { NotFoundError } from 'src/utils/errors/not-found-error';
import { ServerError } from 'src/utils/errors/server-error';

export const globalErrorNotificationKey = 'global-error-notification';

const ErrorId = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  margin-top: 10px;
  text-align: left;
`;

export type TriggerErrorOptions = { isNavigation: boolean };

const ErrorBoundaryContext = React.createContext({
  resetError: () => {},
  triggerError: (error: any, options?: TriggerErrorOptions) => {},
});

export const useErrorHandling = () => {
  return React.useContext(ErrorBoundaryContext);
};

type ErrorBoundaryState = {
  error: any;
  sentryId: string | null;
} & TriggerErrorOptions;

export const ErrorBoundary: React.FC = ({ children }) => {
  const errorService = useService(ErrorService);
  const history = useHistory();
  return (
    <InnerErrorBoundary errorService={errorService} history={history}>
      {children}
    </InnerErrorBoundary>
  );
};

type ErrorBoundaryProps = {
  errorService: ErrorService;
  history: RouteComponentProps['history'];
};

class InnerErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  historyUnregisterCallback: UnregisterCallback;
  defaultState = { error: null, isNavigation: false, sentryId: null };

  constructor(props: any) {
    super(props);
    this.state = this.defaultState;
  }

  private logToSentry(error: any) {
    return this.props.errorService.reportError(error);
  }

  triggerError = (error: any, options?: TriggerErrorOptions) => {
    const sentryId = this.logToSentry(error);
    this.setState({ error, isNavigation: options?.isNavigation ?? false, sentryId });
  };

  resetError = () => this.setState(this.defaultState);

  componentDidCatch(error, errorInfo) {
    const sentryId = this.logToSentry(error);
    this.setState({ error, sentryId, isNavigation: true });
  }

  componentDidMount() {
    this.historyUnregisterCallback = this.props.history.listen(() => {
      if (this.state.error) {
        this.resetError();
      }
    });
  }

  componentWillUnmount() {
    this.historyUnregisterCallback();
  }

  render() {
    const { error, isNavigation, sentryId } = this.state;

    return (
      <ErrorBoundaryHelper
        triggerError={this.triggerError}
        resetError={this.resetError}
        error={error}
        sentryId={sentryId}
        isNavigation={isNavigation}
      >
        {this.props.children}
      </ErrorBoundaryHelper>
    );
  }
}

const ErrorBoundaryHelper = memo<
  ErrorBoundaryState & {
    resetError: VoidFunction;
    triggerError: (error: any, options?: TriggerErrorOptions) => void;
    children: React.ReactNode;
  }
>(({ triggerError, resetError, error, isNavigation, children, sentryId }) => {
  const sessionService = useService(SessionService, () => []);
  const { t } = useTranslation(['errors']);
  const showNotification = useShowNotification();
  const [modal, contextHolder] = useModal();
  const renderCurrentUi = useCallback(
    () => (
      <ErrorBoundaryContext.Provider value={{ triggerError, resetError }}>
        {children}
        {contextHolder}
      </ErrorBoundaryContext.Provider>
    ),
    [children, contextHolder, resetError, triggerError],
  );

  const checkFullPageErrorInstance = (error: any) => {
    //JavaScript base error object
    if (error instanceof Error && error.constructor.name === 'Error') {
      return true;
    }

    const errorInstances = [
      //Application errors
      NetworkError,
      AuthorizationError,
      ServerError,

      //JavaScript core error objects
      //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#error_types
      EvalError,
      RangeError,
      ReferenceError,
      SyntaxError,
      TypeError,
      URIError,
      AggregateError,
    ];

    return errorInstances.some((c) => error instanceof c);
  };

  const displayFullPageError = isNavigation && checkFullPageErrorInstance(error);

  useEffect(() => {
    if (
      !error ||
      displayFullPageError ||
      error instanceof NotFoundError ||
      error instanceof AuthenticationError
    ) {
      return;
    }

    notification.close(globalErrorNotificationKey);

    if (error instanceof NetworkError) {
      showNotification({
        type: 'error',
        title: t('errors:httpErrors.0.title'),
        message: t('errors:httpErrors.0.message'),
        key: globalErrorNotificationKey,
        customIcon: 'networkError',
      });

      return;
    } else if (error instanceof DesktopOnlyPageError) {
      modal.error({
        className: 'error-boundary-modal',
        icon: null,
        closable: true,
        closeIcon: <Icon type="close" />,
        okButtonProps: {
          disabled: true,
        },
        centered: true,
        cancelButtonProps: {
          disabled: true,
        },
        maskClosable: true,
        content: (
          <ModalErrorContent
            title={t('errors:sectionUnavailable.sectionUnavailableInMobile.title')}
            description={t(
              'errors:sectionUnavailable.sectionUnavailableInMobile.message',
            )}
            icon="pageError"
          />
        ),
      });

      return;
    } else {
      let code: string | null = null;

      if (isHttpApiError(error)) {
        code = error.response.code;
      } else if (isApiErrorData(error)) {
        code = error.code;
      }

      const message = (
        <>
          <span>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              t([
                `errors:apiErrors.${code?.replace(':', '.')}`,
                'errors:notification.message',
              ])
            }
          </span>
          <ErrorId>Ref: {sentryId}</ErrorId>
        </>
      );

      showNotification({
        type: 'error',
        title: t('errors:notification.title'),
        message,
        duration: 0,
        key: globalErrorNotificationKey,
      });
    }

    resetError();
  }, [displayFullPageError, error, modal, resetError, sentryId, showNotification, t]);

  if (!error) {
    return renderCurrentUi();
  }

  if (error instanceof AuthenticationError) {
    void sessionService.logout();
    return null;
  }

  if (error instanceof NotFoundError) {
    return <NotFoundPage />;
  }

  if (!displayFullPageError) {
    return renderCurrentUi();
  }

  if (error instanceof NetworkError) {
    window.location.reload();
    return null;
  }

  if (error instanceof AuthorizationError) {
    return <ForbiddenErrorPage />;
  }

  return <InternalErrorPage sentryId={sentryId} />;
});

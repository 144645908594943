import React, { memo, useCallback, Fragment } from 'react';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { AdaptiveOptionItem } from 'src/components/general/items/adaptive-option-item';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';

const OptionsDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 1px;
  margin: 10px 0;
  width: 100%;
`;

type HandleOnClick = (onClick: VoidFunction, closeOnClick?: boolean) => void;

const optionRenderer = (params: {
  option: DrawerSelectableOption | DrawerOption;
  handleOnClick: HandleOnClick;
  iconColorOverride?: string;
  selectedValue: string | null;
}) => {
  const { option, handleOnClick, iconColorOverride, selectedValue } = params;

  if ('value' in option) {
    const { onClick, value, closeOnClick, ...rest } = option;

    return (
      <AdaptiveOptionItem
        key={value}
        onClick={(e) => handleOnClick(() => onClick?.(e), closeOnClick)}
        selected={selectedValue ? selectedValue === value : false}
        iconColorOverride={iconColorOverride}
        {...rest}
      />
    );
  } else {
    const { label, onClick, closeOnClick, ...rest } = option;
    return (
      <AdaptiveOptionItem
        key={label}
        onClick={(e) => handleOnClick(() => onClick?.(e), closeOnClick)}
        label={label}
        iconColorOverride={iconColorOverride}
        {...rest}
      />
    );
  }
};

const GroupItem = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 15px;
  font-style: normal;
  line-height: 19px;
  margin: 0 10px;
  padding: 10px;
`;

type BottomDrawerItemsRendererProps = {
  options:
    | Array<DrawerSelectableOption | DrawerOption>
    | Record<string, Array<DrawerSelectableOption | DrawerOption>>;
  onClose: VoidFunction;
  selectedValue: string | null;
  iconColorOverride?: string;
};

export const BottomDrawerItemsRenderer = memo<BottomDrawerItemsRendererProps>(
  ({ options, onClose, selectedValue, iconColorOverride }) => {
    const handleOnClick = useCallback(
      (onClick: VoidFunction, closeOnClick: boolean = true) => {
        onClick();

        if (closeOnClick) {
          onClose();
        }
      },
      [onClose],
    );

    if (Array.isArray(options)) {
      return (
        <ArrayItemsRenderer
          options={options}
          onClose={onClose}
          selectedValue={selectedValue}
          iconColorOverride={iconColorOverride}
          handleOnClick={handleOnClick}
        />
      );
    }

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {Object.entries(options)
          .filter(([, groupOptions]) => groupOptions.length)
          .map(([group, groupOptions]) => {
            return (
              <Fragment key={group}>
                <GroupItem>{group}</GroupItem>
                {groupOptions.map((option) =>
                  optionRenderer({
                    option,
                    handleOnClick,
                    iconColorOverride,
                    selectedValue,
                  }),
                )}
              </Fragment>
            );
          })}
        <SafeAreaBottom />
      </div>
    );
  },
);

const ArrayItemsRenderer = memo<
  Omit<BottomDrawerItemsRendererProps, 'options'> & {
    options: Array<DrawerSelectableOption | DrawerOption>;
    handleOnClick: HandleOnClick;
  }
>(({ options, selectedValue, iconColorOverride, handleOnClick }) => {
  const groupByEnabledOptions = groupBy(
    options.map((option) => ({
      ...option,
      disabled: option.disabled ?? false,
    })),
    'disabled',
  );

  const hasDisabledOptions = groupByEnabledOptions['true']?.length > 0;
  const optionsRenderer = useCallback(
    (options: Array<DrawerSelectableOption | DrawerOption>) =>
      options.map((option) =>
        optionRenderer({ option, handleOnClick, iconColorOverride, selectedValue }),
      ),
    [handleOnClick, iconColorOverride, selectedValue],
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {groupByEnabledOptions['false'] && optionsRenderer(groupByEnabledOptions['false'])}
      {hasDisabledOptions && <OptionsDivider />}
      {groupByEnabledOptions['true'] && optionsRenderer(groupByEnabledOptions['true'])}
      <SafeAreaBottom />
    </div>
  );
});

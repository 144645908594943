import React, { ReactNode } from 'react';
import AntDrawer, { DrawerProps } from 'antd/lib/drawer';
import { only, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';

const StyledScrollbar = styled(Scrollbar)`
  & > div:first-child {
    padding: 15px 20px;
  }

  .ant-drawer-bottom & > div {
    padding: 20px;
  }
`;

export function LegacyDrawer(props: DrawerProps & { children?: ReactNode }) {
  return (
    <StyledDrawer closable={true} {...props} closeIcon={<Icon type="close" />}>
      <StyledScrollbar>{props.children}</StyledScrollbar>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(AntDrawer)`
  background-color: ${({ theme }) => theme.colors.overlayColor};

  .ant-drawer-header {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGrey2};
    border-bottom: none;
    display: flex;
    height: 62px;
    min-height: 62px;
    justify-content: space-between;
    padding: 0 0 0 20px;
  }

  .ant-drawer-title {
    color: ${({ theme }) => theme.colors.darkCarbon};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 1.6;
    text-align: left;
  }

  .ant-drawer-close {
    color: ${({ theme }) => theme.colors.base.lightBlueGrey};
    position: relative;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    border-radius: 8px 8px 0 0;
    box-shadow: none;
  }

  .ant-drawer-mask {
    display: none;
    background-color: rgba(121, 135, 151, 0.7);
  }

  ${only('sm')} {
    .ant-drawer-mask {
      display: block;
    }
  }

  &.ant-drawer-right.ant-drawer-open {
    width: 0%;
  }

  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 14px 51px 0 rgba(0, 0, 0, 0.14);
  }

  &.ant-drawer.ant-drawer-bottom .ant-drawer-content {
    background: #ffffff 0 0 no-repeat padding-box;
    border-radius: 8px 8px 0 0;
  }

  .ant-drawer-footer {
    border-top: 0;
    position: relative;
    border-top: 1px solid #e9eef2;
  }

  .ant-drawer-footer::before {
    content: '';
    display: sticky;
    position: absolute;
    left: 0;
    top: -66px;
    width: 100%;
    height: 65px;
    pointer-events: none;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0 0
      no-repeat padding-box;
  }

  &.ant-drawer-right .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-footer {
    padding: 20px 15px;
  }

  &.ant-drawer-bottom .ant-drawer-footer {
    border-top: 1px solid rgba(218, 221, 226, 0.58);
  }

  &.ant-drawer-bottom .ant-drawer-header {
    background-color: ${(props: any) => props.theme.colors.base.lightGrey2};
  }

  &.ant-drawer-bottom .ant-drawer-header,
  &.ant-drawer-bottom .ant-drawer-content {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &.ant-drawer-bottom .ant-drawer-content-wrapper {
    max-height: calc(100vh - 10px);
  }

  &.ant-drawer-bottom .ant-drawer-footer {
    padding: 15px 20px;
  }

  ${up('md')} {
    .ant-drawer-header {
      background-color: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid #f0f0f0;
      min-height: 139px;
      padding: 20px;
    }

    .ant-drawer-title {
      width: 100%;
    }

    .ant-drawer-close {
      padding: 30px 20px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .ant-drawer-footer {
      padding: 20px;
    }
  }
`;

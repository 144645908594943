import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { ButtonV2 } from '../button/lgg-button';
import { Icon } from '../icon';

const InAppNotificationContainer = styled(FlexRow)`
  padding: 6px 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  cursor: pointer;
`;

const NotificationContent = styled(FlexRow)`
  align-items: center;
`;

const PrefixIcon = styled(Icon)<{ $fillColor: ColorPaletteItem }>`
  margin-right: 10px;
  position: relative;
  top: -1px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ $fillColor, theme }) => theme.colors[$fillColor]};
    }
  }
`;

const SuffixIcon = styled(Icon)`
  svg {
    width: 10px;
    height: 10px;

    path {
      fill: ${({ theme }) => theme.colors.secondaryCoral};
    }
  }
`;

const NotificationText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryCoral};
  line-height: 14px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.regular};
  margin-right: 10px;

  ${up('md')} {
    margin-right: 0;
    line-height: 17px;
    font-size: 14px;
  }
`;

const NotificationButton = styled(ButtonV2)`
  font-family: ${({ theme }) => theme.font.regular};

  &,
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.secondaryCoral};

    svg path {
      fill: ${({ theme }) => theme.colors.secondaryCoral};
    }
  }
`;

type InAppNotificationProps = {
  icon: string;
  variant: 'error';
  title: string;
  onClick: VoidFunction;
  testId?: string;
};

export const InAppNotification = ({
  icon,
  variant,
  title,
  onClick,
  testId,
}: InAppNotificationProps) => {
  let fillColor: ColorPaletteItem;
  const breakpointUpMd = useBreakpoint(up('md'));

  switch (variant) {
    case 'error': {
      fillColor = 'secondaryCoral';
    }
  }

  return breakpointUpMd ? (
    <NotificationButton
      size="regular"
      variant="defaultWhite"
      onClick={onClick}
      data-lgg-id={testId}
    >
      <NotificationContent>
        <PrefixIcon type={icon} $fillColor={fillColor} />
        {title}
      </NotificationContent>
    </NotificationButton>
  ) : (
    <InAppNotificationContainer onClick={onClick} data-lgg-id={testId}>
      <NotificationContent>
        <PrefixIcon type={icon} $fillColor={fillColor} />
        <NotificationText>{title}</NotificationText>
      </NotificationContent>
      <SuffixIcon type="arrowNextNoPadding" />
    </InAppNotificationContainer>
  );
};

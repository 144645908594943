import { memo } from 'react';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import styled from 'styled-components';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import { PopoverV2 } from 'src/components/general/display/popover';
import { LggUserAvatar } from 'src/components/general/display/user-avatar';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useVisible } from 'src/hooks/use-visible';

const UserPopoverContainer = styled(FlexRow)`
  padding: 20.5px;
  min-width: 231px;
`;

const StyledLggUserAvatar = styled(LggUserAvatar)`
  width: 40px;
  height: 40px;
  margin-right: 13px;
`;

const UserPopoverName = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.36px;
  text-align: left;
  color: ${({ theme }) => theme.colors.smalt};
`;

const UserPopoverRole = styled.p`
  margin: 0;
  line-height: 15px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
`;

const UserInfoContainer = styled(FlexColumn)`
  justify-content: center;
`;

const LoadingIconContainer = styled(FlexRow)`
  justify-content: center;
  width: 100%;
`;

type LggUserPopoverProps = {
  children: React.ReactNode;
  user?: User;
  loading?: boolean;
};

export const LggUserPopover = memo<LggUserPopoverProps>(
  ({ user, loading = false, children }) => {
    const { visible, show, close } = useVisible();

    const getPopoverContent = () => {
      if (loading) {
        return (
          <LoadingIconContainer>
            <LoadingOutlined />
          </LoadingIconContainer>
        );
      }

      if (user) {
        return (
          <>
            <StyledLggUserAvatar user={user} />
            <UserInfoContainer>
              <UserPopoverName data-lgg-id="lgg-user-popover-username">
                {user.fullName}
              </UserPopoverName>
              <UserPopoverRole>{user.role.name}</UserPopoverRole>
            </UserInfoContainer>
          </>
        );
      }

      return null;
    };

    return (
      <PopoverV2
        destroyTooltipOnHide
        align={{
          offset: [0, 0],
        }}
        visible={visible}
        // TODO: Find right value, using this temporarily to make it visible on contact modal
        zIndex={ModalsZIndex}
        content={
          <UserPopoverContainer data-lgg-id="lgg-user-popover">
            {getPopoverContent()}
          </UserPopoverContainer>
        }
        trigger="hover"
      >
        <span onMouseOver={show} onMouseLeave={close}>
          {children}
        </span>
      </PopoverV2>
    );
  },
);

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { AnchorButton, LinkButton } from 'src/components/general/button/lgg-button';
import { HollowLayout } from 'src/components/layout/hollow-layout';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const cardStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 2px 80px 0 #2526310d;
`;

const Card = styled.div`
  ${cardStyle};
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  overflow: hidden;
  padding: 40px 20px 20px;

  ${up('md')} {
    flex-direction: row;
    margin-top: 70px;
    padding: 119px 80px 80px 80px;
  }

  @media (min-width: 1009px) {
    margin: 0 auto;
    max-width: 969px;
  }
`;

const Headline = styled.span`
  color: ${({ theme }) => theme.colors.darkCarbon};
  display: block;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 58px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.16px;
  line-height: 1;
  margin-top: 15px;
  position: relative;
  text-align: center;

  ${up('md')} {
    font-size: 80px;
    letter-spacing: -1.6px;
    margin-top: 64px;
    text-align: left;
  }
`;

const ErrorTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbonBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 1.6;
  margin-top: 5px;
  position: relative;
  text-align: center;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.lightGrey6};
    font-size: 30px;
    letter-spacing: -0.6px;
    line-height: 1.57;
    margin-top: 10px;
    text-align: left;
  }
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.62;
  margin: 10px 0 0;
  position: relative;
  text-align: center;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.lightGrey6};
    font-size: 18px;
    line-height: 1.67;
    opacity: 0.75;
    text-align: left;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  width: 100%;

  > * + * {
    margin-left: 20px;
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > svg {
    height: 240px;
    width: 100%;
    max-width: 240px;
  }

  ${up('lg')} {
    > svg {
      height: 262px;
      width: 262px;
    }
  }
`;

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  ${up('md')} {
    align-items: flex-start;
    margin-left: 79px;
  }
`;

const buttonStyles = css`
  font-size: 13px;

  ${up('md')} {
    font-size: 14px;
    padding: 0 15px;
    width: unset;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  ${buttonStyles};
`;

const StyledAnchorButton = styled(AnchorButton)`
  ${buttonStyles};
`;

const HeadlineBg = styled.span`
  color: #f3f5f799;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 200px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -4px;
  line-height: 1;
  position: absolute;
  text-align: left;
`;

const ErrorId = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-top: 15px;
`;

const BodyContent = styled.div<{ mdMarginBottom: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${up('md')} {
    align-items: flex-start;
    margin-bottom: ${({ mdMarginBottom }) => mdMarginBottom};
  }
`;

type GenericErrorPageProps = {
  customFooter?: React.ReactNode;
  headline?: number | string | null;
  errorId?: string;
  message: string;
  showBgHeadline?: boolean;
  title: string;
  image: React.ReactNode;
};

export const GenericErrorPage = memo<GenericErrorPageProps>(
  ({
    customFooter,
    headline,
    errorId,
    message,
    showBgHeadline = true,
    title,
    image,
    ...rest
  }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['common', 'errors']);
    const effectiveHeadline = headline || t('errors:internalError.headline');

    return (
      <HollowLayout {...rest}>
        <Card>
          <ImageContainer>{image}</ImageContainer>
          <ContentContainer>
            {breakpointUpMd && showBgHeadline && (
              <HeadlineBg>{effectiveHeadline}</HeadlineBg>
            )}
            <Headline>{effectiveHeadline}</Headline>
            <ErrorTitle>{title}</ErrorTitle>
            <BodyContent mdMarginBottom={errorId ? '30px' : '50px'}>
              <ErrorMessage>{message}</ErrorMessage>
              {errorId && <ErrorId>{errorId}</ErrorId>}
            </BodyContent>
            {customFooter ? (
              customFooter
            ) : (
              <ButtonsRow>
                <StyledLinkButton to="/">{t('common:returnToHome')}</StyledLinkButton>
                <StyledAnchorButton href="mailto:it@leadgogo.com" variant="tertiary">
                  {t('common:contactSupport')}
                </StyledAnchorButton>
              </ButtonsRow>
            )}
          </ContentContainer>
        </Card>
      </HollowLayout>
    );
  },
);

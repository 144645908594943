import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { ConversationsFilters } from 'src/services/conversation.service';

const StyledNoResults = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-top: 100px;

  ${up('md')} {
    padding-top: 70px;
  }
`;

const Circle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  display: flex;
  height: 90px;
  justify-content: center;
  width: 90px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  text-align: center;
`;

const Message = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.43;
  margin-top: 10px;
  max-width: 263px;
  text-align: center;
`;

const NoResultsIcon = styled(Icon)`
  svg {
    width: 30.8px;
    height: 30px;
  }
`;

type NoResultsProps = {
  filters: ConversationsFilters;
};

export const NoResults = memo<NoResultsProps>(({ filters }) => {
  const { t } = useTranslation();

  const resolveCopies = (): { title: string; message: string } => {
    if (filters.assignees.length || filters.contactStagesIds.length) {
      return {
        title: t('conversations:noResults.upsNothingHere'),
        message: t('conversations:noResults.noConversationsForFilterCriteria'),
      };
    } else if (filters.keyword) {
      return {
        title: t('conversations:noResults.upsNoResultsHere'),
        message: t('conversations:noResults.noConversationsForSearchCriteria'),
      };
    } else {
      const { conversationStatus } = filters;

      if (conversationStatus === 'IS_CLOSED') {
        return {
          title: t('conversations:noResults.noClosedMessagesTitle'),
          message: t('conversations:noResults.noClosedMessagesMessage'),
        };
      } else if (conversationStatus === 'IS_MARKED') {
        return {
          title: t('conversations:noResults.noMarkedMessagesTitle'),
          message: t('conversations:noResults.noMarkedMessagesMessage'),
        };
      } else if (conversationStatus === 'IS_REPLY_PENDING') {
        return {
          title: t('conversations:noResults.noNotRepliedMessagesTitle'),
          message: t('conversations:noResults.noNotRepliedMessagesMessage'),
        };
      } else if (conversationStatus === 'IS_SNOOZED') {
        return {
          title: t('conversations:noResults.noSnoozedMessagesTitle'),
          message: t('conversations:noResults.noSnoozedMessagesMessage'),
        };
      } else {
        return {
          title: t('conversations:noResults.noNewConversations'),
          message: t('conversations:noResults.noWorriesStartInteracting'),
        };
      }
    }
  };

  const { title, message } = resolveCopies();

  return (
    <StyledNoResults data-lgg-id="conversations-no-results-container">
      <Circle>
        <NoResultsIcon type="conversations" />
      </Circle>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </StyledNoResults>
  );
});

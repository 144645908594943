import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import Dropdown from 'antd/lib/dropdown/dropdown';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  Query,
  QueryContactInteractionRecentAttachmentsForUserArgs,
  ContactInteractionAttachmentFile,
  ContactInteractionAttachmentFileType,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Tooltip } from 'src/components/general/display/tooltip';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  ContactInteractionCameraUploadInput,
  ContactInteractionFileUploadInput,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-attachment-upload-inputs';
import { MenuOption } from 'src/components/pages/conversations/components/contact-interactions/shared';
import { DrawerHeaderBackIcon } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useVisible } from 'src/hooks/use-visible';

const CONTACT_INTERACTION_RECENT_ATTACHMENTS_FOR_USER = gql`
  query contactInteractionRecentAttachmentsForUser($institutionId: Int!) {
    contactInteractionRecentAttachmentsForUser(institutionId: $institutionId) {
      ... on ContactInteractionAttachment {
        id
        createdAt
      }
      ... on ContactInteractionAttachmentFile {
        type
        mime
        filename
        originalFilename
        url
        length
      }
    }
  }
`;

const RecentFilesContainer = styled.div`
  width: 100%;

  ${up('md')} {
    max-height: 290px;
    width: 240px;
  }
`;

const RecentFilesHeaderBackIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 10px;

  svg {
    height: 15px;
    width: 10px;
  }
`;

const RecentFilesHeaderTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  height: 15px;
  line-height: 17px;
  margin: 0;
`;

const RecentFilesHeader = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  height: 47px;
  margin: -5px -5px 0;
  padding: 15px 10px;
`;

const RecentFilesList = styled(Scrollbar)`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  padding: 10px;

  ${up('md')} {
    max-height: 244px;
    padding: 10px 5px 5px;
  }

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RecentFileContainer = styled(FlexColumn)`
  border-radius: 5px;
  cursor: pointer;
  height: 52px;
  justify-content: center;
  padding: 5px;
  width: 100%;

  ${up('md')} {
    height: 42px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }
`;

const RecentFileTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 1px;
  }
`;

const RecentFileSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  margin: 0;

  ${up('md')} {
    font-size: 10px;
    line-height: 12px;
  }
`;

type RecentFileProps = {
  file: ContactInteractionAttachmentFile;
  onClick: VoidFunction;
};

const RecentFile = memo<RecentFileProps>(({ file, onClick }) => {
  const { filename = '', originalFilename } = file;
  const extension = filename?.split('.').pop() ?? '';
  const date = new Date();
  const { formatRelativeDate } = useFormatDate();

  return (
    <RecentFileContainer onClick={onClick} data-lgg-id="contact-interaction-recent-file">
      <RecentFileTitle data-lgg-id="contact-interaction-recent-file-title">
        {originalFilename ?? filename}
      </RecentFileTitle>
      <RecentFileSubtitle>{`${extension.toUpperCase()} - ${formatRelativeDate(
        date,
      )}`}</RecentFileSubtitle>
    </RecentFileContainer>
  );
});

const AttachIcon = styled(Icon)`
  cursor: pointer;

  &.active,
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }

  svg {
    height: 18px;
    width: 18px;

    ${up('md')} {
      height: 16px;
      width: 16px;
    }
  }
`;

const AttachmentOptionContainer = styled.div`
  height: 16px;
  width: 16px;
`;

const EmptyRecentFilesMessage = styled(FlexRow)`
  align-items: center;
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  justify-content: center;
  margin: 15px;
`;

const StyledDropdownOption = styled(MenuOption)`
  & + & {
    margin-top: 2px;
  }

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

export type InputUploadFileStatus = 'VALIDATING' | 'UPLOADING' | 'UPLOADED' | 'ERROR';

export type InputUploadFile = {
  file: File;
  id: string;
  url: string | null;
  isRecentFile: boolean;
  attachmentType: ContactInteractionAttachmentFileType | null;
  status: InputUploadFileStatus;
};

type AttachmentOptionProps = {
  setAttachmentList: Dispatch<SetStateAction<InputUploadFile[]>>;
};

export const ContactInteractionsInputAreaAttachmentOptions = memo<AttachmentOptionProps>(
  ({ setAttachmentList }) => {
    const attachmentOptionVisibilityHandler = useVisible();
    const recentFilesVisibilityHandler = useVisible(false);
    const { id: institutionId } = useCurrentInstitution();
    const [recentFiles, setUserRecentFiles] = useState<
      ContactInteractionAttachmentFile[] | undefined
    >(undefined);
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['conversations']);
    const inputRef = useRef<HTMLInputElement>(null);
    const tooltipVisibilityHandler = useVisible();
    const handleGraphQLError = useHandleGraphQLError();
    const showNotification = useShowNotification();

    const { refetch: refetchRecentFiles } = useQuery<
      Pick<Query, 'contactInteractionRecentAttachmentsForUser'>,
      QueryContactInteractionRecentAttachmentsForUserArgs
    >(CONTACT_INTERACTION_RECENT_ATTACHMENTS_FOR_USER, {
      variables: {
        institutionId,
      },
      onCompleted: ({ contactInteractionRecentAttachmentsForUser }) => {
        const files = contactInteractionRecentAttachmentsForUser.filter(
          (files) => files.__typename === 'ContactInteractionAttachmentFile',
        ) as ContactInteractionAttachmentFile[];
        setUserRecentFiles(files);
      },
      onError: handleGraphQLError,
    });

    useEffect(() => {
      if (recentFilesVisibilityHandler.visible) {
        void refetchRecentFiles();
      }
    }, [recentFilesVisibilityHandler.visible, refetchRecentFiles]);

    const recentFilesBackButtonHandler = useCallback(() => {
      recentFilesVisibilityHandler.setVisible(false);
      attachmentOptionVisibilityHandler.setVisible(true);
    }, [attachmentOptionVisibilityHandler, recentFilesVisibilityHandler]);

    const isAttachmentIconActive =
      attachmentOptionVisibilityHandler.visible || recentFilesVisibilityHandler.visible;

    const attachmentsOptions = useMemo(
      () => [
        {
          icon: 'upload',
          'data-lgg-id': 'contact-interaction-message-input-upload-file-option',
          label: t('conversations:messageInput.options.attachments.uploadFile'),
          onClick: () => {
            inputRef.current?.click();
            tooltipVisibilityHandler.close();
            attachmentOptionVisibilityHandler.close();
          },
        },
        {
          icon: 'recentFile',
          'data-lgg-id': 'contact-interaction-message-input-upload-recent-files-option',
          label: t('conversations:messageInput.options.attachments.recentFiles'),
          onClick: () => {
            recentFilesVisibilityHandler.setVisible(true);
            attachmentOptionVisibilityHandler.setVisible(false);
            tooltipVisibilityHandler.close();
          },
        },
      ],
      [
        attachmentOptionVisibilityHandler,
        recentFilesVisibilityHandler,
        t,
        tooltipVisibilityHandler,
      ],
    );

    const recentFilesContent = useMemo(
      () => (
        <RecentFilesContainer>
          {breakpointUpMd && (
            <RecentFilesHeader>
              <RecentFilesHeaderBackIcon
                type="arrowBackNoPadding"
                onClick={recentFilesBackButtonHandler}
              />
              <RecentFilesHeaderTitle>
                {t('conversations:messageInput.options.attachments.recentFiles')}
              </RecentFilesHeaderTitle>
            </RecentFilesHeader>
          )}
          {recentFiles && recentFiles.length > 0 ? (
            <RecentFilesList data-lgg-id="contact-interaction-recent-files-list">
              {recentFiles.map((recentFile) => (
                <RecentFile
                  file={recentFile}
                  key={recentFile.id}
                  onClick={() => {
                    const {
                      id,
                      url,
                      filename = '',
                      originalFilename,
                      length = 0,
                      mime,
                    } = recentFile;
                    const date = new Date();
                    // TODO
                    const file = new File([], originalFilename ?? filename ?? 'TBD', {
                      type: mime ?? 'TBD', // TODO
                      lastModified: date.getDate(),
                    });

                    Object.defineProperty(file, 'size', { value: length });

                    const attachment: InputUploadFile = {
                      file,
                      id,
                      url,
                      isRecentFile: true,
                      attachmentType: null,
                      status: 'VALIDATING',
                    };

                    setAttachmentList((attachmentList) => {
                      const fileAlreadyAdded = attachmentList.find(
                        (file) => file.id === attachment.id,
                      );

                      if (fileAlreadyAdded) {
                        showNotification({
                          title: t(
                            'conversations:messageInput.options.attachments.errorMessages.recentFileAlreadyAdded',
                          ),
                          type: 'warning',
                        });

                        return attachmentList;
                      } else {
                        return [...attachmentList, attachment];
                      }
                    });

                    recentFilesVisibilityHandler.setVisible(false);
                  }}
                />
              ))}
            </RecentFilesList>
          ) : (
            <EmptyRecentFilesMessage>
              {t('conversations:messageInput.options.attachments.noRecentFilesFound')}
            </EmptyRecentFilesMessage>
          )}
        </RecentFilesContainer>
      ),
      [
        breakpointUpMd,
        recentFiles,
        recentFilesBackButtonHandler,
        recentFilesVisibilityHandler,
        setAttachmentList,
        showNotification,
        t,
      ],
    );

    return (
      <>
        <AttachmentOptionContainer>
          <ContactInteractionFileUploadInput
            inputRef={inputRef}
            setAttachmentList={setAttachmentList}
          />
          {breakpointUpMd ? (
            <>
              <Dropdown
                overlayClassName="context-menu"
                trigger={['click']}
                placement="topLeft"
                visible={recentFilesVisibilityHandler.visible}
                align={{
                  offset: [-18, -10],
                }}
                overlay={recentFilesContent}
              >
                <span></span>
              </Dropdown>
              <Dropdown
                overlayClassName="context-menu"
                trigger={['click']}
                placement="topLeft"
                visible={
                  !recentFilesVisibilityHandler.visible &&
                  attachmentOptionVisibilityHandler.visible
                }
                onVisibleChange={attachmentOptionVisibilityHandler.setVisible}
                align={{
                  offset: [-18, -10],
                }}
                overlay={
                  <>
                    {attachmentsOptions.map(({ label, icon, onClick, ...rest }) => {
                      return (
                        <StyledDropdownOption
                          key={label}
                          label={label}
                          icon={icon}
                          onClick={onClick}
                          {...rest}
                        />
                      );
                    })}
                  </>
                }
              >
                <Tooltip
                  title={t('conversations:messageInput.options.attachments.attachFile')}
                  trigger={['hover']}
                  visible={
                    isAttachmentIconActive ? false : tooltipVisibilityHandler.visible
                  }
                >
                  <span
                    onMouseEnter={() => {
                      tooltipVisibilityHandler.show();
                    }}
                    onMouseLeave={() => {
                      tooltipVisibilityHandler.close();
                    }}
                  >
                    <AttachIcon
                      type="attach"
                      lggTestId="contact-interaction-message-input-attach-option"
                      className={c({
                        active: isAttachmentIconActive,
                      })}
                    />
                  </span>
                </Tooltip>
              </Dropdown>
            </>
          ) : (
            <>
              <AttachIcon
                type="attach"
                onClick={attachmentOptionVisibilityHandler.show}
              />
              <OptionsBottomDrawer
                visible={attachmentOptionVisibilityHandler.visible}
                title={t('conversations:messageInput.options.attachments.attachFile')}
                onClose={attachmentOptionVisibilityHandler.close}
                options={attachmentsOptions}
              />
              <BottomDrawer
                visible={recentFilesVisibilityHandler.visible}
                title={t('conversations:messageInput.options.attachments.recentFiles')}
                onClose={recentFilesVisibilityHandler.close}
                fullHeight
                leading={<DrawerHeaderBackIcon onClick={recentFilesBackButtonHandler} />}
              >
                {recentFilesContent}
              </BottomDrawer>
            </>
          )}
        </AttachmentOptionContainer>
        {!breakpointUpMd && (
          <ContactInteractionCameraUploadInput
            onChangeHandler={(file) => {
              const attachment: InputUploadFile = {
                id: uuid(),
                status: 'VALIDATING',
                file,
                isRecentFile: false,
                attachmentType: null,
                url: null,
              };

              setAttachmentList((attachmentList) => [...attachmentList, attachment]);
            }}
          />
        )}
      </>
    );
  },
);

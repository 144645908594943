import { createGlobalStyle } from 'styled-components';
import closeIcon from 'src/assets/icons/icon_close_alt.svg?url';
import { fadeInAnimation, slideLeftAnimation } from 'src/theme/animations';

export const CalendarStyleOverride = createGlobalStyle`
.fc {
  height: 100%;

  .fc-view {
    &.fc-timegrid,
    &.fc-daygrid {
      border-radius: 6px;
    }
  }

  .fc-scrollgrid table,
  .fc-timegrid-body,
  .fc-timegrid .fc-daygrid-body {
    width: 100% !important;
  }

  .fc-timegrid-slots {
    &:before {
      content: '';
      height: 25px;
      display: block;
      border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
    }
  }

  .fc-timegrid-col-frame {
    min-height: calc(100% - 25px) !important;
  }

  .fc-timegrid-axis-frame {
    align-items: center;
    justify-content: center;
  }

  .fc-timegrid-slot-label-frame {
    position: relative;
    top: -15px;
    right: 7px;
    background: white;
  }

  .fc-timegrid-slots table colgroup col,
  .fc-timegrid-cols table colgroup col,
  .fc-scrollgrid-sync-table colgroup col {
    width: 54px !important;
  }

  .fc-daygrid-more-link.fc-more-link {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }

  .fc-popover-body,
  .fc-scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0;
    min-height: 100% !important;
  }
  
  .fc-theme-standard td,
  .fc-timegrid-slot {
    border-color: ${({ theme }) => theme.colors.koala};
    height: 28.5px;
  }
  
  .fc-scrollgrid-section > td {
    border-right: none;
    border-bottom: none;
  }

  td.fc-timegrid-divider {
    height: 2px;
    border-color: ${({ theme }) => theme.colors.casper};
    background: ${({ theme }) => theme.colors.casper};
    border: none;
  }

  .fc-popover {
    ${fadeInAnimation};
    box-shadow: 0 20px 40px 0 rgba(91, 101, 112, 0.2);
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.koala};
    max-height: 310px;
    max-width: 200px;
    width: 100%;
    z-index: 1;

    .fc-popover-header {
      background: transparent;
      padding: 20px 10px 10px;

      .fc-popover-title {
        color: ${({ theme }) => theme.colors.smalt};
        font-family: ${({ theme }) => theme.font.regular};
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }

      .fc-popover-close {
        color: ${({ theme }) => theme.colors.flint};
        font-size: 20px;
        opacity: 1;
        background-image: url(${closeIcon});
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;

        &::before {
          content: '';
        }
      }
    }

    .fc-popover-body {
      min-width: unset;
      overflow-y: scroll;
      min-height: 100px;
      max-height: 250px;

      & > * {
        position: unset;
      }
    }
  }

  .fc-scrollgrid-section-header {
    .fc-col-header {
      thead tr th {
        height: 61px;
        padding: 0;
    
        .fc-scrollgrid-sync-inner {
          display: flex; 
          height: 100%;
          align-items:center;
          justify-content: center;

          .fc-col-header-cell-cushion {
            padding: 13px 0px 11px;
          }
        }
      }
    }

    & > th {
        background: ${({ theme }) => theme.colors.white};
        border: unset;
        padding: unset;

        .fc-scroller {
            overflow: hidden !important;
        }

        & > .fc-scroller-harness .fc-col-header  {
            width: 100% !important;
            background: ${({ theme }) => theme.colors.white};

            & .fc-col-header-cell.fc-day {
                background: ${({ theme }) => theme.colors.white};
                border: unset;
            }
        }
    }
  }

  .fc-timegrid-col-events {
    margin: 0;

    .fc-timegrid-event-harness {
      border-bottom: 1px solid transparent;
    }
  }

  .fc-timegrid-event {
    margin: 0;
    box-shadow: none;

    .fc-event-main {
      padding: 0;
    }
  }
  
  .fc-event.fc-daygrid-event {
    margin: 0;
    border-radius: 0;
    padding: 0;
    
    &,
    &:hover {
      color: inherit;
      background: transparent;
    }


    .fc-daygrid-event-dot {
      display: none;
    }
  }

  // Month view styles
  .fc-dayGridMonth-view {
    .fc-daygrid-day-bottom {
      margin: 5px !important;
      padding: 0;
    }

    .fc-scrollgrid {
      border: none;
  
      .fc-scrollgrid-section-body > td {
        border: none;
  
        .fc-scroller {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          
          &::-webkit-scrollbar {
            display: none;
          }
  
          .fc-daygrid-body {
            width: 100% !important;
            border-top: 1px solid ${({ theme }) => theme.colors.koala};
          }
        }
      }
    }
    
    .fc-scrollgrid-sync-table {
      height: unset !important;
      width: 100% !important;
      background: ${({ theme }) => theme.colors.white};

      > tbody {
        &,
        > tr {
          background: ${({ theme }) => theme.colors.white};
        }
      }
    }

    .fc-day {
      ${slideLeftAnimation};
    }

    .fc-daygrid-day {
      height: 160px;
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.koala};
      position: relative;
      cursor: pointer;
  
      .fc-daygrid-day-top {
        margin-top: 5px;
        margin-right: 5px;
      }
  
      .fc-daygrid-day-number {
        color: ${({ theme }) => theme.colors.smalt};
        font-family: ${({ theme }) => theme.font.medium};
        font-size: 12px;
        height: 22px;
        letter-spacing: 0.36px;
        line-height: 14px;
        margin-bottom: 5px;
        text-align: center;
        width: 22px;
      }
  
      &.fc-day-other {
        .fc-daygrid-day-top {
          opacity: 1;
        }
        .fc-daygrid-day-number {
          color: ${({ theme }) => theme.colors.casper};
        }
      }
    
      &.fc-day-today {
        background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
  
        .fc-daygrid-day-number {
          background: ${({ theme }) => theme.colors.gogo};
          border-radius: 50%;
          color: ${({ theme }) => theme.colors.white};
          font-family: ${({ theme }) => theme.font.bold};
        }
      }
    }
  }
  
  // Week view styles
  .fc-timeGridWeek-view, .fc-timeGridDay-view {
    .fc-scrollgrid table {
      &.fc-scrollgrid-sync-table {
        min-height: 18px;
      }
    }
    
    .fc-day {
      ${slideLeftAnimation};
    }

    .fc-daygrid-day-top {
      display: none;
    }

    
    .fc-daygrid-day-events {
      max-height: 80px;
      overflow-y: scroll;

      & {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    .fc-scrollgrid {
      .fc-scrollgrid-section:first-child {
        td {
          border-bottom: none;
          border-color: ${({ theme }) => theme.colors.koala};
        }
      }
      border: none;
    }
    
    .fc-scrollgrid-section.fc-scrollgrid-section-header {
      .fc-col-header {
        border-bottom: 1px solid ${({ theme }) => theme.colors.koala};

        .fc-timegrid-axis {
          background: ${({ theme }) => theme.colors.white};
          border: none;
        }
      }
    }

    .fc-scrollgrid-section.fc-scrollgrid-section-body {
      .fc-timegrid-body {
        .fc-timegrid-slots {
          border-top: 1px solid ${({ theme }) => theme.colors.koala};
          
          .fc-timegrid-slot.fc-timegrid-slot-minor {
            border: none;
          }

          .fc-timegrid-slot-lane {
            cursor: pointer;
          }
        }
      }

      .fc-daygrid-body {
        background: ${({ theme }) => theme.colors.white};
        
        .fc-daygrid-day {
          border-color: ${({ theme }) => theme.colors.koala};
          background: ${({ theme }) => theme.colors.white};
        }

        .fc-timegrid-slot {
          border-color: ${({ theme }) => theme.colors.koala};
        }

        .fc-timegrid-axis .fc-timegrid-axis-frame {
          background: ${({ theme }) => theme.colors.white};
        }
      }
    }

    .fc-timegrid-cols {
      .fc-timegrid-col {
        border-color: ${({ theme }) => theme.colors.koala};
        background: ${({ theme }) => theme.colors.white};
      }

      & > table {
        &,
        & tbody > tr {
          background: ${({ theme }) => theme.colors.white};
        }
      }
    }

    &.fc-timeGridWeek-view {
      .fc-scrollgrid-section.fc-scrollgrid-section-body {
        .fc-timegrid-cols .fc-timegrid-col,
        .fc-daygrid-body .fc-daygrid-day {
          &.fc-day-today {
            background: ${({ theme }) => theme.colors.secondaryTopaz10};
          }
        }
      }
    }

    .fc-timegrid-col-events {
      // The fullCalendar library does not provide a way to know if an event overlaps another but
      // when this happens they create an "stack" of events with an incremented z-index value
      // if the element is not the first element of the stack, we add a white border to avoid
      // visual merging of the events.

      .fc-timegrid-event-harness {
        border: 1px solid ${({ theme }) => theme.colors.white};

        &[style*="z-index: 1;"] {
          border-color: transparent;
          border-style: solid;
          border-width: 0 0 1px 0;
        }
      }
    }
  }
}

.calendar-event-popover {
  &.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    left: 52px;
  }
}
`;

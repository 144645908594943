import {
  ContactInteractionUnion,
  ContactNote,
  ContactTimelineContactInteraction,
  ContactTimelineUnion,
  ConversationItemUnion,
  ConversationNote,
} from '@lgg/isomorphic/types/__generated__/graphql';

const KB_IN_A_BYTE = 0.001;

export const getDirectionFromContactInteraction = (
  contactInteraction:
    | ContactInteractionUnion
    | ConversationNote
    | ContactNote
    | Exclude<ContactTimelineUnion, ContactTimelineContactInteraction>,
) => {
  return 'direction' in contactInteraction
    ? contactInteraction.direction
    : contactInteraction.__typename === 'ContactInteractionFacebookAd'
    ? 'INBOUND'
    : 'OUTBOUND';
};

export const getFileSizeLabel = (byteValue: number) => {
  return `${(KB_IN_A_BYTE * byteValue).toFixed(2)} KB`;
};

export const getConversationItemUserId = (
  interaction: ConversationItemUnion,
): number | null => {
  if (interaction.__typename.startsWith('ContactTimeline')) {
    return null;
  }

  if (interaction.__typename === 'ConversationNote') {
    return interaction.createdBy.id;
  }

  if (interaction.__typename === 'ContactNote') {
    return interaction.user?.id || null;
  }

  if ('participatingEntity' in interaction && interaction.participatingEntity) {
    if (interaction.participatingEntity.type === 'AGENT') {
      return Number(interaction.participatingEntity.id);
    }
  }

  return null;
};

export const formatAudioDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
};

import axios from 'axios';

export const downloadFile = async ({
  fileUrl,
  fileName,
}: {
  fileUrl: string;
  fileName?: string;
}): Promise<void> => {
  // Ref: https://medium.com/charisol-community/downloading-resources-in-html5-a-download-may-not-work-as-expected-bf63546e2baa
  const fileBlob = (await axios.get(fileUrl, { responseType: 'blob' })).data;

  const fileBlobUrl = URL.createObjectURL(fileBlob);
  const newFileUrl = fileBlobUrl;

  const blobLink = document.createElement('a');
  blobLink.href = newFileUrl;
  blobLink.style.display = 'none';
  blobLink.setAttribute('download', fileName ?? 'file');
  // Append to html link element page
  document.body.appendChild(blobLink);

  // Start download
  blobLink.click();
  //Releases the object URL
  setTimeout(() => URL.revokeObjectURL(fileBlobUrl), 100);
  // Clean up and remove the link
  document.body.removeChild(blobLink);
};

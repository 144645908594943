import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Error500 } from 'src/assets/error/500.svg';
import { GenericErrorPage } from 'src/components/pages/errors/generic-error-page';

export type InternalErrorPageProps = {
  sentryId: string | null;
  errorCode?: number | null;
};

export const InternalErrorPage = memo<InternalErrorPageProps>(
  ({ sentryId, errorCode }) => {
    const { t } = useTranslation(['errors']);

    return (
      <GenericErrorPage
        data-lgg-id="error-page-500"
        headline={errorCode}
        image={<Error500 />}
        title={t('errors:internalError.title')}
        message={t('errors:internalError.message')}
        errorId={`Ref: ${sentryId}`}
        showBgHeadline={errorCode !== null}
      />
    );
  },
);

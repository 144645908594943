import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { useParams } from 'react-router-dom';
import { up, only } from 'styled-breakpoints';
import styled from 'styled-components';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactInformationSection } from 'src/components/pages/conversations/components/contact-information/contact-information-section';
import { ContactInteractionsSection } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-section';
import { ConversationsHome } from 'src/components/pages/conversations/components/conversations-home';
import { ConversationsSection } from 'src/components/pages/conversations/components/conversations/conversation-section/conversations-section';
import { FreeWhileInBetaBanner } from 'src/components/pages/conversations/components/free-while-in-beta-banner';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useSidebar } from 'src/hooks/use-sidebar';

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 100%;

  ${up('md')} {
    padding: 30px;
    width: 100%;
  }
`;

const Dashboard = styled(FlexRow)`
  height: 100%;
  overflow: hidden;
  width: 100%;

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
  }
`;

const LeftRail = styled(FlexColumn)`
  flex: 1;
  min-width: 0;

  ${up('lg')} {
    border-right: 1px solid ${({ theme }) => theme.colors.koala};
    max-width: 375px;
  }
`;

const RightRail = styled(FlexColumn)<{ visible: boolean }>`
  flex: 1;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  ${up('lg')} {
    border-left: 1px solid ${({ theme }) => theme.colors.koala};
    max-width: 276px;
  }
`;

const MainContent = styled(FlexColumn)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

export const Conversations = memo(() => {
  const { t } = useTranslation(['conversations']);
  useAddBreadcrumb(t('conversations:pageBreadcrumb'));
  const { getFeatureFlag } = useAuthorization();

  const { conversationId, contactInteractionId } = useParams<{
    conversationId?: string;
    contactInteractionId?: string;
  }>();
  const breakpointOnlyMd = useBreakpoint(only('md'));
  const breakpointUpMd = useBreakpoint(up('md'));
  const breakpointUpLg = useBreakpoint(up('lg'));
  const breakpointUpXl = useBreakpoint(up('xl'));
  const { isSidebarFixed } = useSidebar();
  const {
    selectedConversationId,
    selectConversation,
    clearConversationSelection,
    showContactInformation,
    setShowContactInformation,
  } = useConversation();
  const [isRightRailVisible, setIsRightRailVisible] = useState<boolean>(false);
  const { ref: mainContainerRef } = useResizeDetector({
    onResize: (mainContainerWidth) => {
      if (mainContainerWidth) {
        setIsRightRailVisible(mainContainerWidth >= 1070);
      }
    },
  });

  useEffect(() => {
    if (breakpointUpXl && !isSidebarFixed) {
      setShowContactInformation(false);
    }
  }, [breakpointUpXl, isSidebarFixed, setShowContactInformation]);

  const handleOnLoadConversation = useCallback(
    (conversation: Conversation) => {
      if (conversationId) {
        selectConversation({
          conversationId: conversation.id,
          contactId: conversation.contact.id,
        });
      }
    },
    [conversationId, selectConversation],
  );

  const leftRail = useMemo(() => {
    if (breakpointOnlyMd) {
      if (showContactInformation) {
        return <ContactInformationSection />;
      } else if (selectedConversationId) {
        return (
          <ContactInteractionsSection
            contactInteractionId={contactInteractionId}
            onLoadConversation={handleOnLoadConversation}
            conversationId={selectedConversationId}
            showContactDetailsHint={true}
          />
        );
      }
    }

    return <ConversationsSection contactInteractionId={contactInteractionId} />;
  }, [
    breakpointOnlyMd,
    contactInteractionId,
    handleOnLoadConversation,
    selectedConversationId,
    showContactInformation,
  ]);

  const mainContent = useMemo(() => {
    if (showContactInformation && !isRightRailVisible) {
      return <ContactInformationSection />;
    }

    if (selectedConversationId === null) {
      return <ConversationsHome />;
    }

    return (
      <ContactInteractionsSection
        contactInteractionId={contactInteractionId}
        onLoadConversation={handleOnLoadConversation}
        conversationId={selectedConversationId}
        showContactDetailsHint={breakpointUpMd && !isRightRailVisible}
      />
    );
  }, [
    breakpointUpMd,
    contactInteractionId,
    handleOnLoadConversation,
    isRightRailVisible,
    selectedConversationId,
    showContactInformation,
  ]);

  useEffect(() => {
    if (conversationId) {
      selectConversation({ conversationId });
    } else {
      clearConversationSelection();
    }
  }, [clearConversationSelection, conversationId, selectConversation]);

  return (
    <>
      {!getFeatureFlag('ux-redesign-v2.5') && <FreeWhileInBetaBanner />}
      <MainContainer ref={mainContainerRef}>
        <Dashboard>
          <LeftRail>{leftRail}</LeftRail>
          {breakpointUpLg && <MainContent>{mainContent}</MainContent>}
          {selectedConversationId && (
            <RightRail visible={isRightRailVisible}>
              <ContactInformationSection showHeader={false} />
            </RightRail>
          )}
        </Dashboard>
      </MainContainer>
    </>
  );
});

import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { SelectOption } from 'src/components/general/inputs/select/select';

export const userToSelectOptionMapper = (user: User): SelectOption<number> => ({
  value: user.id,
  label: user.fullName,
  chip: {
    text: user.avatar.initials,
    color: user.avatar.color,
  },
});

import { gql, useQuery } from '@apollo/client';
import {
  Campaign,
  CampaignWhereInput,
  Query,
  QueryCampaignsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const GET_CAMPAIGNS = gql`
  query GetCampaigns($institutionId: Int!, $where: CampaignWhereInput) {
    campaigns(institutionId: $institutionId, where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type UseCampaignsReturn = {
  loading: boolean;
  campaigns: Campaign[];
};

export const useCampaigns = (where?: CampaignWhereInput): UseCampaignsReturn => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { loading, data } = useQuery<
    Pick<Query, 'campaigns'>,
    Partial<QueryCampaignsArgs>
  >(GET_CAMPAIGNS, {
    variables: {
      institutionId: currentInstitution.id,
      where,
    },
    onError: handleGraphQLError,
  });

  return {
    campaigns: getNodesFromConnection(data?.campaigns),
    loading,
  };
};

import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { EventBottomDrawer } from 'src/components/pages/calendar/components/mobile/event-bottom-drawer';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

const CalendarItemContainer = styled(FlexRow)`
  margin: 2px 0 2px 0;
  padding: 8px 20px 8px 0;
  position: relative;

  &.current-day {
    background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
  }

  .events-container {
    flex: 1;
    min-width: 0;
  }
`;

const NoEventsItem = styled.div`
  align-items: center;
  align-self: start;
  border-left: 2px solid ${({ theme }) => theme.colors.flint};
  color: ${({ theme }) => theme.colors.smalt};
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 34px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 15px;
  opacity: 0.5;
  padding-left: 10px;
  text-align: left;
`;

const ItemDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.koala};
  height: 1px;
`;

const EventContainer = styled(FlexColumn)<{
  $color: ColorPaletteItem;
  $borderColor: ColorPaletteItem;
  $backgroundColor?: ColorPaletteItem;
}>`
  border-left: 2px solid ${({ theme, $borderColor }) => theme.colors[$borderColor]};
  padding-left: 10px;

  & + & {
    margin-top: 15px;
  }

  ${({ $backgroundColor, theme }) => {
    if ($backgroundColor) {
      return `
        background-color: ${theme.colors[$backgroundColor]};
        display: flex;
        height: 22px;
        justify-content: center;
      `;
    }
  }};

  .top-row {
    align-items: center;
    display: flex;
    opacity: 0.75;

    .hour {
      color: ${({ $color, theme }) => theme.colors[$color]};
      font-family: ${({ theme }) => theme.font.regular};
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 15px;
      text-align: left;
    }

    .contact-icon {
      svg {
        height: 12px;
        margin-left: 4px;
        width: 12px;

        path {
          fill: ${({ $color, theme }) => theme.colors[$color]};
        }
      }
    }
  }

  .title {
    color: ${({ $color, theme }) => theme.colors[$color]};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ $backgroundColor }) => {
      if (!$backgroundColor) {
        return `
          margin-bottom: 3px;
          margin-top: 5px;
        `;
      }
    }}
  }
`;

const DayContainer = styled(FlexColumn)`
  align-self: flex-start;
  padding: 0 18px;
  position: sticky;
  top: 0;
  width: 61px;
  transition: padding-top 0.1s ease-in-out;

  &.floating {
    padding-top: 10px;
  }
`;

const DayLabel = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
`;

const DayNumber = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 17px;
  padding: 4px;
  text-align: center;
`;

const DayStickyContainer = memo<{
  dateString: string;
  virtuosoScroller: HTMLElement | Window | null;
}>(({ dateString, virtuosoScroller }) => {
  const { formatDate } = useFormatDate();
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = itemRef.current;

    if (!element || !virtuosoScroller) {
      return;
    }

    const callBack = () => {
      if (element.offsetTop > 8) {
        element.classList.add('floating');
      } else {
        element.classList.remove('floating');
      }
    };

    virtuosoScroller.addEventListener('scroll', callBack);

    return () => virtuosoScroller.removeEventListener('scroll', callBack);
  }, [dateString, virtuosoScroller]);

  return (
    <DayContainer ref={(ref) => (itemRef.current = ref)}>
      <DayLabel>{formatDate(dateString, 'ddd')}</DayLabel>
      <DayNumber>{formatDate(dateString, 'DD')}</DayNumber>
    </DayContainer>
  );
});

const ScheduleItem = memo<{ schedule: Schedule; onClick: VoidFunction }>(
  ({ schedule, onClick }) => {
    const { formatDate } = useFormatDate();
    const { startAt, title, contact, isAllDay, endAt } = schedule;

    return match(isAllDay)
      .with(true, () => (
        <EventContainer
          onClick={onClick}
          $color="secondaryPeriwinkleDark"
          $borderColor="secondaryPeriwinkleDark"
          $backgroundColor="secondaryPeriwinkle30"
        >
          <span className="title">{title}</span>
        </EventContainer>
      ))
      .with(false, () => {
        const eventHours = (() => {
          const startAtFormatted = formatDate(startAt, 'h:mma');

          if (endAt) {
            const endAtFormatted = formatDate(endAt, 'h:mma');

            return `${startAtFormatted} - ${endAtFormatted}`;
          }

          return startAtFormatted;
        })();

        return (
          <EventContainer
            onClick={onClick}
            $color="secondaryPeriwinkleDark"
            $borderColor="secondaryPeriwinkle60"
          >
            <FlexRow className="top-row">
              <span className="hour">{eventHours}</span>
              {contact && <Icon className="contact-icon" type="contact" />}
            </FlexRow>
            <span className="title">{title}</span>
          </EventContainer>
        );
      })
      .exhaustive();
  },
);

const TaskItem = memo<{ task: TaskEvent; onClick: VoidFunction }>(({ onClick, task }) => {
  const { formatDate } = useFormatDate();
  const { dueAt, title, contact } = task;

  return (
    <EventContainer
      $color="secondaryMintDark"
      $borderColor="secondaryMint60"
      onClick={onClick}
    >
      <FlexRow className="top-row">
        <span className="hour">{formatDate(dueAt, 'h:mma')}</span>
        {contact && <Icon className="contact-icon" type="contact" />}
      </FlexRow>
      <span className="title">{title}</span>
    </EventContainer>
  );
});

const EventItem = memo<{ event: TaskEvent | Schedule }>(({ event }) => {
  const visibilityHandler = useVisible();

  const item = match(event)
    .with({ __typename: 'Task' }, (task) => (
      <TaskItem key={task.id} task={task} onClick={visibilityHandler.show} />
    ))
    .with({ __typename: 'Schedule' }, (schedule) => (
      <ScheduleItem
        key={schedule.id}
        schedule={schedule}
        onClick={visibilityHandler.show}
      />
    ))
    .exhaustive();

  return (
    <>
      {item}
      <EventBottomDrawer visibilityHandler={visibilityHandler} event={event} />
    </>
  );
});

export const CalendarItem = memo<{
  currentDateString: string;
  dateString: string;
  virtuosoScroller: HTMLElement | Window | null;
  events: (TaskEvent | Schedule)[];
}>(({ dateString, events, currentDateString, virtuosoScroller }) => {
  const { t } = useTranslation(['calendar']);
  const { isLastDayOfMonth } = useDateHelpers();
  const sortedEvents = sortBy(events, (event) => {
    if (event.__typename === 'Schedule') {
      return event.isAllDay ? '1' : event.startAt;
    } else {
      return event.dueAt;
    }
  });

  return (
    <>
      <CalendarItemContainer
        className={c({
          'day-item': true,
          'current-day': dateString === currentDateString,
        })}
        data-day={dateString}
      >
        <DayStickyContainer virtuosoScroller={virtuosoScroller} dateString={dateString} />
        <FlexColumn className="events-container">
          {match(sortedEvents)
            .with([], P.nullish, () => (
              <NoEventsItem>{t('calendar:noEventsPlanned')}</NoEventsItem>
            ))
            .otherwise((event) =>
              event.map((event) => <EventItem key={event.id} event={event} />),
            )}
        </FlexColumn>
      </CalendarItemContainer>
      {!isLastDayOfMonth(new Date(dateString)) && <ItemDivider />}
    </>
  );
});

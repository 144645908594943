import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactNote,
  ConversationNote,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BubbleDivider,
  InternalNoteType,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { LexicalViewer } from 'src/components/pages/conversations/components/general/lexical-editor/lexical-viewer';

const Note = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  display: block;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 18px;
  margin-bottom: 5px;

  &,
  .editor-paragraph {
    font-size: 13px;
    line-height: 15px;

    ${up('md')} {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const NoteTitleDivider = styled(BubbleDivider)`
  margin: 10px -15px 10px;
`;

const NoteTitleContainer = styled(FlexRow)`
  align-items: center;
`;

const NoteTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
`;

const NoteTitleIcon = styled(Icon)`
  margin-right: 6px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors.secondaryGoldDark};
    }
  }
`;

type NoteItemProps = {
  internalNote: ConversationNote | ContactNote;
  conversationId: string;
  isMentionsFeatureEnabled?: boolean;
};

export const NoteItem = memo<NoteItemProps>(
  ({ internalNote, conversationId, isMentionsFeatureEnabled = false }) => {
    const { createdAt, text, id } = internalNote;
    const { t } = useTranslation('conversations');

    const noteContent = useMemo(() => {
      return match(internalNote)
        .with({ __typename: 'ConversationNote' }, ({ text, rawNote }) => {
          return isMentionsFeatureEnabled && rawNote ? (
            <LexicalViewer jsonString={rawNote} />
          ) : (
            <TextFormatter>{text}</TextFormatter>
          );
        })
        .with({ __typename: 'ContactNote' }, ({ text }) => (
          <TextFormatter>{text}</TextFormatter>
        ))
        .exhaustive();
    }, [internalNote, isMentionsFeatureEnabled]);

    const testId = useMemo(() => {
      return match(internalNote)
        .with(
          { __typename: 'ConversationNote' },
          () => 'contact-interaction-conversation-note',
        )
        .with({ __typename: 'ContactNote' }, () => 'contact-interaction-contact-note')
        .exhaustive();
    }, [internalNote]);

    const noteTitle = useMemo(() => {
      const titleConfig = match(internalNote)
        .with({ __typename: 'ContactNote' }, () => ({
          icon: 'contact',
          labelKey: 'contactInteractionBubble.note.contactNote' as const,
        }))
        .with({ __typename: 'ConversationNote' }, () => ({
          icon: 'conversation',
          labelKey: 'contactInteractionBubble.note.conversationNote' as const,
        }))
        .exhaustive();

      return (
        <NoteTitleContainer>
          <NoteTitleIcon
            type={titleConfig.icon}
            data-lgg-id="contact-interaction-note-item-title-icon"
          />
          <NoteTitle data-lgg-id="contact-interaction-note-item-title">
            {t(titleConfig.labelKey)}
          </NoteTitle>
        </NoteTitleContainer>
      );
    }, [internalNote, t]);

    const noteType: InternalNoteType = useMemo(() => {
      return match(internalNote)
        .with({ __typename: 'ContactNote' }, () => 'CONTACT_NOTE' as const)
        .with({ __typename: 'ConversationNote' }, () => 'CONVERSATION_NOTE' as const)
        .exhaustive();
    }, [internalNote]);

    return (
      <MessageBubbleWithDirection
        testId={testId}
        direction="OUTBOUND"
        registrationType="MANUAL"
        forceMessageCaret
        id={id}
        noteType={noteType}
        details={{
          interactionId: id,
          conversationId,
          message: text,
        }}
        createdAt={createdAt}
        backgroundColor="secondaryGold30"
      >
        <>
          {noteTitle}
          <NoteTitleDivider direction="OUTBOUND" />
          <Note data-lgg-id="contact-interaction-note-item-text">{noteContent}</Note>
        </>
      </MessageBubbleWithDirection>
    );
  },
);

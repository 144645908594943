import { useEffect, useState } from 'react';

export const useDebouncedState = <T>(state: T, delay = 500) => {
  const [debouncedState, setDebouncedState] = useState<T>(state);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedState(state);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [state, delay]);

  return debouncedState;
};

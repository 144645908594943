import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { only, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { AddNewOptionIcon } from 'src/components/general/display/add-new-context-menu';
import { ContextMenuItem } from 'src/components/general/display/context-menu';
import { AddButtonDesktop } from 'src/components/layout/authenticated-layout/header/add-new/desktop';
import { useEntityCreatedNotification } from 'src/components/layout/authenticated-layout/header/add-new/hooks/useEntityCreatedNotification';
import { AddButtonMobile } from 'src/components/layout/authenticated-layout/header/add-new/mobile';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { useRefreshContact } from 'src/hooks/gql/use-refresh-contact';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHeaderActions } from 'src/hooks/use-header-actions';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useUrls } from 'src/hooks/use-urls';
import { AuthorizationService } from 'src/services/authorization.service';
import { CalendarService } from 'src/services/calendar.service';

const usePermissionsMap = () => {
  const { getFeatureFlag, hasPermission } = useAuthorization();
  const state = useServiceSelector(AuthorizationService, (s) => s.state);

  const permissionsMap: { [key: string]: boolean } = useMemo(() => {
    const hasCrmFF = getFeatureFlag('crm');

    return {
      addContact: hasPermission('cms.client.access'),
      addTask: hasCrmFF && hasPermission('cms.task.access'),
      addSchedule: hasCrmFF && hasPermission('cms.schedule.access'),
      // addCompany: institution.type === "Organization" && hasPermission("organization:company.manage"),
      // addOrganization: institution.type === "Agency" && hasPermission("agency:organization.manage")
    };
    // Method ref is persisted even when the service state changed, this mapping
    // needs to be computed each time the state changes, so we need to listen to the state too
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFeatureFlag, hasPermission, state]);

  return permissionsMap;
};

export const useHasAddButtonAuthorization = () => {
  const permissionsMap = usePermissionsMap();

  for (const key in permissionsMap) {
    if (permissionsMap[key]) {
      return true;
    }
  }

  return false;
};

export type AddButtonProps = {
  menuActions: ContextMenuItem[];
  extraActions: ContextMenuItem[];
};

export const useGetOverlayMenuOptions = () => {
  const headerActions = useHeaderActions();
  const { t } = useTranslation(['header']);
  const currentInstitution = useCurrentInstitution();
  const institutionUrl = useInstitutionUrl();
  const permissionsMap = usePermissionsMap();
  const currentUrlPath = window.location.pathname;

  const selectedDay = useServiceSelector(CalendarService, (s) => {
    return (s.state.selectedDay ?? new Date()).toISOString();
  });
  const { getLegacyAddTaskUrlWithDate, getLegacyAddScheduleUrlWithDate } = useUrls();

  const getOptionRedirectHandler = useCallback(
    (url: string, isLegacy: boolean = false, onClick?: () => void) => {
      if (isLegacy && url.indexOf('#/') !== -1) {
        const modalUrl = url.substring(url.indexOf('#/') + 1);

        return { onClick: () => openRemoteModalWrapper(modalUrl) };
      }

      if (onClick) {
        return { onClick };
      }

      return { to: url };
    },
    [],
  );

  const getIconForAction = useCallback((iconName: string) => {
    return match(iconName)
      .with('whatsapp', () => 'whatsapp')
      .with('instagram', () => 'instagram')
      .otherwise(() => 'organization');
  }, []);

  const isCompanyInstitutionType = currentInstitution.type === 'Company';

  const result = useMemo(
    () => ({
      menuActions: [
        ...headerActions.map((action, index) => ({
          key: `overlay-header-menu-option-${index}`,
          label: action.label,
          icon: (
            <AddNewOptionIcon
              backgroundColor="koala"
              iconColor={action.icon ? 'smalt' : undefined}
              icon={getIconForAction(action.icon)}
            />
          ),
          testId: `overlay-header-menu-option-${index}`,
          ...getOptionRedirectHandler(action.href, action.isLegacy, action.onClick),
        })),
      ],
      extraActions: [
        ...(permissionsMap.addCompany
          ? [
              {
                key: 'overlay-header-menu-option-add-company',
                label: t('header:addNew.company'),
                icon: (
                  <AddNewOptionIcon
                    backgroundColor="lightAccentGreen"
                    iconColor="secondaryTopazDark"
                    icon="company"
                  />
                ),
                testId: 'overlay-header-menu-option-add-company',
                ...getOptionRedirectHandler(`${institutionUrl}/companies/edit`),
              },
            ]
          : []),
        ...(permissionsMap.addOrganization
          ? [
              {
                key: 'overlay-header-menu-option-add-organization',
                label: t('header:addNew.organization'),
                icon: (
                  <AddNewOptionIcon
                    backgroundColor="lightAccentGreen"
                    iconColor="smalt"
                    icon="organization"
                  />
                ),
                testId: 'overlay-header-menu-option-add-organization',
                ...getOptionRedirectHandler(`${institutionUrl}/organizations/edit`),
              },
            ]
          : []),
        ...(isCompanyInstitutionType && permissionsMap.addContact
          ? [
              {
                key: 'overlay-header-company-menu-option-add-contact',
                label: t('header:addNew.contact'),
                icon: (
                  <AddNewOptionIcon
                    backgroundColor="secondaryGold20"
                    iconColor="secondaryGoldDark"
                    icon="contact18"
                  />
                ),
                testId: 'overlay-header-menu-option-add-contact',
                ...getOptionRedirectHandler(
                  `${currentUrlPath}#${institutionUrl}/cms/_modals/client/edit/?next: activity&stage: lead`,
                  true,
                ),
              },
            ]
          : []),
        ...(isCompanyInstitutionType && permissionsMap.addTask
          ? [
              {
                key: 'overlay-header-company-menu-option-add-task',
                label: t('header:addNew.task'),
                icon: (
                  <AddNewOptionIcon
                    backgroundColor="secondaryMint20"
                    iconColor="secondaryMintDark"
                    icon="task18"
                  />
                ),
                testId: 'overlay-header-menu-option-add-task',
                ...getOptionRedirectHandler(
                  `${currentUrlPath}#${getLegacyAddTaskUrlWithDate(selectedDay)}`,
                  true,
                ),
              },
            ]
          : []),
        ...(isCompanyInstitutionType && permissionsMap.addSchedule
          ? [
              {
                key: 'overlay-header-company-menu-option-add-schedule',
                label: t('header:addNew.schedule'),
                icon: (
                  <AddNewOptionIcon
                    backgroundColor="periWinkle20"
                    iconColor="periWinkleDark"
                    icon="schedule18"
                  />
                ),
                testId: 'overlay-header-menu-option-add-schedule',
                ...getOptionRedirectHandler(
                  `${currentUrlPath}#${getLegacyAddScheduleUrlWithDate(selectedDay)}`,
                  true,
                ),
              },
            ]
          : []),
      ],
    }),
    [
      headerActions,
      permissionsMap.addCompany,
      permissionsMap.addOrganization,
      permissionsMap.addContact,
      permissionsMap.addTask,
      permissionsMap.addSchedule,
      t,
      getOptionRedirectHandler,
      institutionUrl,
      isCompanyInstitutionType,
      currentUrlPath,
      getLegacyAddTaskUrlWithDate,
      selectedDay,
      getLegacyAddScheduleUrlWithDate,
      getIconForAction,
    ],
  );

  return result;
};

export const AddButton = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { extraActions, menuActions } = useGetOverlayMenuOptions();
  const { onCreateContactHandler, onCreateTaskHandler, onCreateAppointmentHandler } =
    useEntityCreatedNotification();

  useRefreshContact(onCreateContactHandler);
  useRefreshAppointment(onCreateAppointmentHandler);
  useRefreshTask(onCreateTaskHandler);

  if (extraActions.length > 0 || menuActions.length > 0) {
    return (
      <StyledAddButton>
        {breakpointUpMd ? (
          <AddButtonDesktop extraActions={extraActions} menuActions={menuActions} />
        ) : (
          <AddButtonMobile extraActions={extraActions} menuActions={menuActions} />
        )}
      </StyledAddButton>
    );
  }

  return <></>;
});

const StyledAddButton = styled.span`
  .ant-btn {
    ${up('md')} {
      width: 44px;
      height: 44px;
    }

    ${only('sm')} {
      width: 30px;
      height: 30px;
    }

    box-shadow: 0 12px 20px #8bd8bf38;
    border-radius: 26px;
    color: white;
    border: none;

    ${up('md')} {
      margin: 18px 20px;
    }

    ${only('sm')} {
      margin: 15px;
    }

    outline: none;
    padding: 0;
    text-align: center;
  }

  .ant-btn .anticon {
    ${up('md')} {
      vertical-align: -4px;
    }

    ${only('sm')} {
      vertical-align: 1px;
    }
  }

  .ant-btn .anticon svg {
    ${up('md')} {
      width: 18px;
      height: 18px;
    }

    ${only('sm')} {
      width: 16px;
      height: 16px;
    }
  }
`;

import { memo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { FlexRow } from 'src/components//layout/flex-row';
import { TableColumns } from 'src/components/general/display/table';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { parseQueryParams } from 'src/utils/parse-query-params';
import { stringifyQueryParams } from 'src/utils/stringify-query-params';

export type BasePageQueryParams<
  ViewCode,
  T = Map<string, number | string | number[] | string[]>,
> = {
  table?: string;
  institution_id?: string;
  after?: string;
  before?: string;
  institution_type?: string;
  q?: {
    advanced: T;
  };
  'view-code'?: 'custom' | ViewCode;
};

export type TableSortData = {
  key: string;
  direction: SortDirection;
};

export type SortOrder = 'ascend' | 'descend' | undefined;

export type SorterData<T> = {
  column: TableColumns<T>;
  columnKey: string;
  field: string;
  order: SortOrder;
};

export const getSortDirectionByOrder = (order: SortOrder) =>
  order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined;

export const getDefaultSortOrder = (isActive: boolean, sortOrder?: string) =>
  isActive && sortOrder ? (sortOrder === 'ASC' ? 'ascend' : 'descend') : null;

export const tableLayoutViewButtonStyles = css`
  &,
  &:active,
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

export type TableLayoutFilterProps<T> = {
  visible: boolean;
  onClose: VoidFunction;
  filters?: Partial<T>;
};

const HeaderContainer = styled(FlexColumn)`
  padding: 20px;

  ${up('md')} {
    padding: 0;
  }
`;

const HeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderBottomContainer = styled(FlexRow)`
  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderInnerContainer = styled(FlexRow)`
  & > *:not(:last-child) {
    margin-right: 10px;

    ${up('md')} {
      margin-right: 20px;
    }
  }
`;

export const MoreOptionsIcon = styled(Icon)`
  position: relative;
  left: -5px;

  svg {
    height: 16px;
    margin: 0 5px;
    width: 4px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

export const TableColumnTitle = styled.span`
  white-space: nowrap;
`;

type TableLayoutHeaderProps = {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
  bottomContent?: JSX.Element;
};

export const TableLayoutHeader = memo<TableLayoutHeaderProps>(
  ({ leftContent, rightContent, bottomContent }) => {
    return (
      <HeaderContainer>
        <HeaderTopContainer>
          <HeaderInnerContainer>{leftContent}</HeaderInnerContainer>
          <HeaderInnerContainer>{rightContent}</HeaderInnerContainer>
        </HeaderTopContainer>
        {bottomContent && <HeaderBottomContainer>{bottomContent}</HeaderBottomContainer>}
      </HeaderContainer>
    );
  },
);

export const useTableSubmitHandler = <T,>(params: {
  onClose: VoidFunction;
  addViewCode?: boolean;
}) => {
  const location = useLocation();
  const history = useHistory();
  const { institutionId } = useParams<{ institutionId: string }>();
  const { onClose, addViewCode = true } = params;

  return (formValues: T) => {
    const queryParams = parseQueryParams(location.search);
    const sortParam = queryParams['table'] ? { table: queryParams['table'] } : {};

    const objectParams: T = {
      institution_id: institutionId,
      institution_type: 'company',
      ...(addViewCode ? { 'view-code': 'custom' } : {}),
      ...sortParam,
      q: {
        advanced: {
          ...formValues,
        },
      },
    } as T;

    const queryString = stringifyQueryParams(objectParams);
    const newUrl = `${location.pathname}?${queryString}`;
    onClose();
    history.push(newUrl);
  };
};

export const StyledTableFiltersForm = styled.form`
  & > * {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 35px;
    }
  }
`;

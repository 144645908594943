import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactInteractionUnion,
  ContactInteractionPhoneCall,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionDetailItem } from 'src/components/domain/contact-interaction/contact-interaction-detail-item';
import { FlexColumn } from 'src/components/layout/flex-column';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useTimeHelpers } from 'src/hooks/use-time-helpers';
import { useUrls } from 'src/hooks/use-urls';

const DetailItemsContainer = styled(FlexColumn)`
  padding: 5px 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  margin-bottom: 10px;

  & > span {
    line-height: 15px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  ${up('md')} {
    padding: 0 5px;
    border: none;
    margin: 0;
  }
`;

const ListenRecordingOption = styled(Link)`
  &,
  &:visited,
  &:hover {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

type ActivityPopoverDetailsProps = {
  contactInteraction: ContactInteractionUnion;
};

export const ActivityPopoverDetails = ({
  contactInteraction,
}: ActivityPopoverDetailsProps) => {
  const { t } = useTranslation(['activity', 'common']);
  const { convertSecondsToDuration } = useTimeHelpers();
  const { getLegacyContactInteractionDepartmentModalUrl, getContactInteractionUrl } =
    useUrls();
  const { id, department, source, campaign } = contactInteraction;
  const isCallInteraction =
    contactInteraction.__typename === 'ContactInteractionPhoneCall';

  const getCallDurationValue = (contactInteraction: ContactInteractionPhoneCall) => {
    const { callDetail, dialStatus, recording } = contactInteraction;

    if (!callDetail?.duration) {
      return null;
    }

    const { duration } = callDetail;

    const durationSuffix = recording ? (
      <ListenRecordingOption
        data-lgg-id="activity-details-popover-listen-recording-option"
        to={getContactInteractionUrl(id)}
      >
        {dialStatus === 'ANSWERED'
          ? t('activity:activityDetailsPopover.items.duration.suffixes.listenCall')
          : t('activity:activityDetailsPopover.items.duration.suffixes.listenVoicemail')}
      </ListenRecordingOption>
    ) : (
      t('activity:activityDetailsPopover.items.duration.suffixes.noAudio')
    );

    return (
      <span>
        {convertSecondsToDuration(duration)}
        {` - `}
        {durationSuffix}
      </span>
    );
  };

  return (
    <DetailItemsContainer>
      {isCallInteraction && contactInteraction.callDetail?.duration && (
        <>
          <ContactInteractionDetailItem
            title={t('activity:activityDetailsPopover.items.duration.title')}
            value={getCallDurationValue(contactInteraction)}
            data-lgg-id="activity-detail-duration"
          />
        </>
      )}
      {contactInteraction.contact.assignee && (
        <>
          <ContactInteractionDetailItem
            title={t('activity:activityDetailsPopover.items.assignedTo')}
            value={contactInteraction.contact.assignee?.fullName}
            data-lgg-id="activity-detail-assigned-to"
          />
        </>
      )}
      {isCallInteraction && contactInteraction.participatingEntity && (
        <>
          <ContactInteractionDetailItem
            title={t('activity:activityDetailsPopover.items.attendedBy')}
            value={contactInteraction.participatingEntity.label}
            data-lgg-id="activity-detail-attended-by"
          />
        </>
      )}
      <ContactInteractionDetailItem
        title={t('activity:activityDetailsPopover.items.department')}
        value={department ? department.name : t('common:none')}
        onEdit={() => {
          openRemoteModalWrapper(
            getLegacyContactInteractionDepartmentModalUrl(contactInteraction.id),
          );
        }}
        data-lgg-id="activity-detail-department"
      />
      {campaign && (
        <ContactInteractionDetailItem
          title={t('activity:activityDetailsPopover.items.campaign')}
          value={campaign.name}
          data-lgg-id="activity-detail-campaign"
        />
      )}
      {source && (
        <ContactInteractionDetailItem
          title={t('activity:activityDetailsPopover.items.source')}
          value={source.name}
          data-lgg-id="activity-detail-source"
        />
      )}
      <ContactInteractionDetailItem
        title={t('activity:activityDetailsPopover.items.id')}
        value={id}
        data-lgg-id="activity-detail-id"
      />
    </DetailItemsContainer>
  );
};

import React, { memo, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import {
  ModalContent,
  ModalMessage,
  ModalTitle,
} from 'src/components/general/modals/modals.shared';

const ModalButton = styled(LggButton)`
  margin: 20px 15px;
  width: calc(100% - 40px);
`;

type AlertModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  title: string;
  message: string;
  icon: React.ReactNode;
  button: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    text: string;
  };
};

export const AlertModal = memo<AlertModalProps>(
  ({ visible, button, onClose, title, message, icon }) => {
    return (
      <LggModal
        maskClosable={false}
        visible={visible}
        onClose={onClose}
        closeIcon={<div />}
        width="calc(100% - 40px)"
      >
        <ModalContent>
          {icon}
          <ModalTitle>{title}</ModalTitle>
          <ModalMessage>{message}</ModalMessage>
          <ModalButton onClick={button.onClick}>{button.text}</ModalButton>
        </ModalContent>
      </LggModal>
    );
  },
);

import Collapse from 'antd/lib/collapse';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

export const StyledCollapse: StyledComponent<typeof Collapse, DefaultTheme> = styled(
  Collapse,
)`
  background-color: transparent;
  border-radius: 6px;
  border: none;
  margin: 10px;

  &.ant-collapse .ant-collapse-item .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.accentBlue030};
    border-radius: 6px;
    padding: 10px;
    transition: background-color 300ms, border-radius: 300ms;
  }

  &.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.lightGrey2};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-item.ant-collapse-item-active {
    box-shadow: 0 15px 34px 0 #0000000f;
  }

  .ant-collapse-content {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey1}
  }

  & .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow .anticon {
    transform: rotate(180deg);
  }

  & .ant-collapse-content.ant-collapse-content-active,
  & .ant-collapse-content.ant-collapse-content-inactive {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-collapse-content.ant-collapse-content-active {
    overflow: hidden;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-item.ant-collapse-item-active {
    position: relative;
  }

  .ant-collapse-content.ant-collapse-content-active::after {
    content: "";
    display: sticky;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 35px;
    pointer-events: none;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0 0 no-repeat padding-box;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

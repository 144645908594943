import { useService } from 'react-service-locator';
import { AuthorizationService } from 'src/services/authorization.service';

export function useAuthorization() {
  const { hasPermission, requirePermission, getFeatureFlag } = useService(
    AuthorizationService,
    (s) => [s.state?.permissions, s.state?.featureFlags],
  );

  return { hasPermission, requirePermission, getFeatureFlag };
}

import React from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';

export const openRemoteModalWrapper = debounce(
  (url: string) => {
    openRemoteModal({
      data: { url },
    });
  },
  500,
  {
    leading: true,
    trailing: false,
  },
);

const StyledOpenLegacyRemoteModalLink = styled.div`
  cursor: pointer;
  width: max-content;
`;

type OpenLegacyRemoteModalLinkProps = {
  url: string;
  disableLink?: boolean;
};

export const OpenLegacyRemoteModalLink: React.FC<OpenLegacyRemoteModalLinkProps> = ({
  url,
  children,
  disableLink = false,
  ...rest
}) => (
  <StyledOpenLegacyRemoteModalLink
    {...rest}
    onClick={() => {
      if (!disableLink) {
        openRemoteModalWrapper(url);
      }
    }}
  >
    {children}
  </StyledOpenLegacyRemoteModalLink>
);

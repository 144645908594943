import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ReactComponent as ArrowBack } from 'src/assets/icons/arrow_back.svg';
import { LggButton } from 'src/components/general/button/lgg-button';

const BackContainer = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 29px;
`;

const BackLabel = styled.span`
  color: ${({ theme }) => theme.colors.globalBlue};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38;
  margin-left: 10px;
  text-align: left;
`;

export const FormContainer = styled.div`
  ${up('md')} {
    padding: 0 105px;
  }
`;

export const BackLink = memo(() => {
  const { t } = useTranslation(['account']);

  return (
    <BackContainer to="/account/login">
      <ArrowBack />
      <BackLabel>{t('account:recoverPage.backToSignIn')}</BackLabel>
    </BackContainer>
  );
});

export const SubmitButton = styled(LggButton)`
  margin-top: 8px;
`;

import React, { memo } from 'react';
import AntDrawer, { DrawerProps } from 'antd/lib/drawer';
import styled from 'styled-components';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { useBottomDrawerHeight } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { disabledUserSelectGlobalStyle } from 'src/theme/globals/user-select-global-style';

const StyledDrawerHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  display: flex;
  height: 70px;
  padding: 23px 20px;
`;

const DrawerTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 24px;
  text-align: left;
`;

const StyledCloseIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const HeaderLessCloseIcon = styled(StyledCloseIcon)`
  position: absolute;
  top: 26px;
  right: 20px;
  z-index: 1;
`;

const HeaderBackIcon = styled(Icon)`
  cursor: pointer;
  padding: 10px;
  margin: 0 5px 0 -10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

export const DrawerHeaderBackIcon = memo<{ onClick: VoidFunction }>(({ onClick }) => (
  <HeaderBackIcon type="iconBackTop" onClick={onClick} />
));

type DrawerHeaderProps = {
  onClose?: VoidFunction;
  extraContent?: React.ReactNode;
  title: string;
  onBack?: VoidFunction;
};

const DrawerHeader = memo<DrawerHeaderProps>(
  ({ onClose, onBack, title, extraContent }) => {
    return (
      <StyledDrawerHeader>
        {onBack && <DrawerHeaderBackIcon onClick={onBack} />}
        <DrawerTitle>{title}</DrawerTitle>
        <Expand />
        {extraContent}
        {onClose && <StyledCloseIcon onClick={onClose} type="close" />}
      </StyledDrawerHeader>
    );
  },
);

const StyledScrollbar = styled(Scrollbar)`
  display: flex;
  flex-direction: column;
`;

const StyledDrawer = styled(AntDrawer)`
  ${disabledUserSelectGlobalStyle}
  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
  }

  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-close {
    display: none;
  }

  &.ant-drawer .ant-drawer-mask {
    background-color: ${({ theme }) => theme.colors.storm + 'b3'};
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-footer {
    border: none;
    padding: 0;
  }
`;

export type BottomDrawerProps = Omit<DrawerProps, 'title' | 'onClose'> & {
  children?: React.ReactNode;
  fullHeight?: boolean;
  onClose: VoidFunction;
  title: string;
  includeHeader?: boolean;
  headerExtraContent?: React.ReactNode;
  addScrollbarToBody?: boolean;
  onBack?: VoidFunction;
  hideCloseIcon?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  contentPadding?: number;
};

const BottomDrawerContentContainer = styled.div<{ padding: number }>`
  padding: ${({ padding }) => `${padding}px`};
`;

export const BottomDrawer = memo<BottomDrawerProps>(
  ({
    title,
    children,
    onClose,
    fullHeight,
    height,
    includeHeader = true,
    headerExtraContent,
    addScrollbarToBody = true,
    onBack,
    hideCloseIcon = false,
    onVisibleChange,
    contentPadding = 10,
    ...props
  }) => {
    return (
      <StyledDrawer
        {...props}
        height={fullHeight ? 'calc(100% - 5px)' : height ? height : 'auto'}
        onClose={onClose}
        closable
        afterVisibleChange={onVisibleChange}
        push={false}
        title={
          includeHeader ? (
            <DrawerHeader
              title={title}
              onClose={hideCloseIcon ? undefined : onClose}
              extraContent={headerExtraContent}
              onBack={onBack}
            />
          ) : (
            <>
              {hideCloseIcon ? null : (
                <HeaderLessCloseIcon onClick={onClose} type="close" />
              )}
            </>
          )
        }
        closeIcon={null}
        placement="bottom"
      >
        <BottomDrawerContentContainer
          padding={contentPadding}
          className="drawer-content-container"
        >
          {addScrollbarToBody ? <Scrollbar>{children}</Scrollbar> : children}
        </BottomDrawerContentContainer>
      </StyledDrawer>
    );
  },
);

type CustomTitleBottomDrawerProps = Omit<BottomDrawerProps, 'title'> & {
  title: React.ReactNode;
};

export const CustomTitleBottomDrawer = memo<CustomTitleBottomDrawerProps>(
  ({ title, children, onClose, fullHeight, addScrollbarToBody, height, ...props }) => {
    const calculatedHeight = useBottomDrawerHeight({ fullHeight });

    return (
      <StyledDrawer
        placement="bottom"
        {...props}
        height={height ?? calculatedHeight}
        onClose={onClose}
        closable={true}
        title={<StyledDrawerHeader>{title}</StyledDrawerHeader>}
        closeIcon={null}
      >
        <StyledScrollbar>{children}</StyledScrollbar>
      </StyledDrawer>
    );
  },
);

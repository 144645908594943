import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { MenuItem } from 'src/components/domain/users/components/profile-settings-modal/components/menu-item';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import {
  SettingsOptionsKeys,
  useProfileSettingsOptions,
} from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings-options';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';

const StyledModal = styled(LggModal)`
  height: 100%;
  max-width: 650px;
  max-height: 603px;

  .ant-modal-content {
    border-radius: 6px;
    max-width: none;
    max-height: none;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    height: 100%;
    min-height: 474px;
    padding: unset;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  height: 64px;
  padding: 20px 20px 19px 20px;
  background: ${({ theme }) => theme.colors.lightGrey2};
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.carbon};
  margin-bottom: unset;
`;

const CloseIcon = styled(Icon)`
  align-self: center;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.base.lightBlueGrey};
    }
  }

  ${up('md')} {
    && {
      margin-left: 20px;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    min-width: 250px;
    padding: 20px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: unset;

    .ant-tabs-tab-btn {
      width: 100%;
    }
  }

  .ant-tabs-nav .ant-tabs-nav-list {
    gap: 5px;
  }

  .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-content-holder {
    height: 100%;
    min-height: 474px;
  }

  .ant-tabs-content {
    padding: 20px;
    margin-bottom: 15px;
  }

  .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
    padding: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    -webkit-text-stroke: unset;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

export const ProfileSettingsModalDesktopView = memo(() => {
  const { visibilityHandler } = useProfileSettings();
  const { currentView, setCurrentView, settingsOptions, formId } =
    useProfileSettingsOptions('personalInformation');

  const { t } = useTranslation(['user']);

  return (
    <StyledModal
      data-lgg-id="profile-settings-modal"
      visible={visibilityHandler.visible}
      centered
      width={650}
      footer={
        currentView !== 'facebookSetting' && (
          <FooterContainer>
            <ButtonV2
              size="small"
              variant="default"
              data-lgg-id="cancel-button"
              form={formId}
              type="reset"
            >
              {t('user:profileSettings.buttons.cancel')}
            </ButtonV2>
            <ButtonV2
              size="small"
              variant="primary"
              data-lgg-id="submit-button"
              form={formId}
              type="submit"
            >
              {t('user:profileSettings.buttons.save')}
            </ButtonV2>
          </FooterContainer>
        )
      }
      onClose={visibilityHandler.close}
      afterClose={() => setCurrentView('personalInformation')}
    >
      <FlexColumn>
        <ModalHeader>
          <Title data-lgg-id="profile-settings-modal-title">
            {t('user:profileSettings.title')}
          </Title>
          <CloseIcon
            onClick={visibilityHandler.close}
            type="close"
            lggTestId="contact-modal-close-icon"
          />
        </ModalHeader>
        <StyledTabs
          data-lgg-id="profile-settings-tabs"
          activeKey={currentView}
          onChange={(activeKey) => setCurrentView(activeKey as SettingsOptionsKeys)}
          tabPosition="left"
        >
          {Object.entries(settingsOptions).map(([key, { icon, title, content }]) => (
            <Tabs.TabPane
              key={key}
              tab={
                <MenuItem
                  key={key}
                  icon={icon}
                  title={title}
                  isActive={currentView === key}
                />
              }
            >
              {content}
            </Tabs.TabPane>
          ))}
        </StyledTabs>
      </FlexColumn>
    </StyledModal>
  );
});

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const BadgeIcon = styled(Icon)`
  margin-right: 4px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const BadgeContent = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.colors.flint};
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;

  strong {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

const BaseBadgeContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.alabaster};
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 7px 8px;

  ${BadgeIcon} {
    svg path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }
`;

type BaseBadgeProps = {
  icon?: string;
  badgeContent: React.ReactElement | string;
  onClick?: VoidFunction;
};

export const BaseBadge = ({ icon, badgeContent, ...rest }: BaseBadgeProps) => {
  return (
    <BaseBadgeContainer {...rest}>
      {icon ? <BadgeIcon className="badge-icon" type={icon} /> : null}
      <BadgeContent className="badge-content">{badgeContent}</BadgeContent>
    </BaseBadgeContainer>
  );
};

import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  LabelContainer,
  PhoneNumberSelectProps,
  ResourceSelector,
  SelectContainer,
  SelectExcerpt,
  SelectText,
} from 'src/components/domain/contacts/call-contact-modal/selects/shared';
import { LggSelectableOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { Select } from 'src/components/general/inputs/select/select';
import { UnDecoratedTextInput } from 'src/components/general/inputs/text-input';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const SearchBar = styled(UnDecoratedTextInput)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};

  & {
    input {
      border: none;
      font-family: ${({ theme }) => theme.font.regular};
      font-size: 12px;
      line-height: 15px;

      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }
`;

type ResourceFromPhoneNumberSelectProps = PhoneNumberSelectProps & {
  onChange?: (selectedValue: string) => void;
};

export const ResourceFromPhoneNumberSelect = memo<ResourceFromPhoneNumberSelectProps>(
  ({ excerpt, label, options, selectedResource, onChange, ...rest }) => {
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['contacts', 'common']);
    const [search, setSearch] = useState<string>('');

    const dropdownButton = (
      <ResourceSelector
        className="resource-selector"
        onClick={visibilityHandler.show}
        isActive={visibilityHandler.visible}
        size="small"
        variant="default"
        children={options.find((option) => option.value === selectedResource)?.label}
        {...rest}
      />
    );

    const filteredOptions = useMemo(() => {
      if (!breakpointUpMd) {
        return options;
      }

      return search
        ? options.filter((option) => {
            const searchValue = search.toLowerCase();

            return `${option.label}${option.subTitle ?? ''}`
              .toLowerCase()
              .includes(searchValue);
          })
        : options;
    }, [breakpointUpMd, options, search]);

    return (
      <>
        <SelectContainer>
          <LabelContainer>
            <SelectText>{label}</SelectText>
            {excerpt && <SelectExcerpt>{excerpt}</SelectExcerpt>}
          </LabelContainer>
          {breakpointUpMd ? (
            <LggSelectableOptionsDropdownButtonWithCustomTrigger
              options={filteredOptions}
              children={dropdownButton}
              selectedValue={selectedResource}
              size="small"
              variant="default"
              visibilityHandler={visibilityHandler}
              customDropdownProps={{
                overlayStyle: {
                  minWidth: '200px',
                },
                onVisibleChange: (visible) => {
                  if (search && visible) {
                    setSearch('');
                  }
                },
              }}
              leading={
                <SearchBar
                  placeholder={t('common:search')}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  data-lgg-id={`${rest['data-lgg-id']}-search-input`}
                />
              }
            />
          ) : (
            <>
              <Select
                name="conversations-filters-channel-select"
                label={t('contacts:callContactModal.selectNumber')}
                placeholder="placeholder"
                value={options.find((option) => option.value === selectedResource)}
                onChange={(option) => {
                  if (option) {
                    onChange?.(option?.value);
                  }
                }}
                triggerOverride={dropdownButton}
                isClearable={false}
                isMulti={false}
                options={options}
              />
            </>
          )}
        </SelectContainer>
      </>
    );
  },
);

import { match } from 'ts-pattern';
import { localeUtils, UserLocale } from '../i18n/locale-utils';
import { Emotion } from '../types/__generated__/graphql';

const emotionEmojiMap: Record<Emotion, string> = {
  AMAZED: '😁',
  ANGRY: '😡',
  APPRECIATIVE: '🥰',
  BETRAYED: '😠',
  DELIGHTED: '🤩',
  DISAPPOINTED: '😞',
  DISCONTENTED: '😕',
  DISGUSTED: '😠',
  DISMAYED: '😩',
  DISPLEASED: '😟',
  DISSATISFIED: '😤',
  EXCITED: '😀',
  FRUSTRATED: '😖',
  GLAD: '😃',
  GRATEFUL: '🙂',
  HAPPY: '😊',
  IMPATIENT: '😓',
  IMPRESSED: '😍',
  INDIGNANT: '😫',
  IRRITATED: '😵',
  LOYAL: '☺️',
  OUTRAGED: '🤬',
  PLEASED: '😌',
  REGRETFUL: '😑',
  RESENTFUL: '😒',
  SARCASTIC: '🙄',
  SATISFIED: '😊',
  SURPRISED: '😝',
  THANKFUL: '🤗',
  THRILLED: '🥳',
};

const translations = {
  en: {
    common: {
      amazed: 'Amazed',
      angry: 'Angry',
      appreciative: 'Appreciative',
      betrayed: 'Betrayed',
      delighted: 'Delighted',
      disappointed: 'Disappointed',
      discontented: 'Discontented',
      disgusted: 'Disgusted',
      dismayed: 'Dismayed',
      displeased: 'Displeased',
      dissatisfied: 'Dissatisfied',
      excited: 'Excited',
      frustrated: 'Frustrated',
      glad: 'Glad',
      grateful: 'Grateful',
      happy: 'Happy',
      impatient: 'Impatient',
      impressed: 'Impressed',
      indignant: 'Indignant',
      irritated: 'Irritated',
      loyal: 'Loyal',
      outraged: 'Outraged',
      pleased: 'Pleased',
      regretful: 'Regretful',
      resentful: 'Resentful',
      sarcastic: 'Sarcastic',
      satisfied: 'Satisfied',
      surprised: 'Surprised',
      thankful: 'Thankful',
      thrilled: 'Thrilled',
    },
  },
  es: {
    common: {
      amazed: 'Asombrado',
      angry: 'Enojado',
      appreciative: 'Agradecido',
      betrayed: 'Traicionado',
      delighted: 'Contento',
      disappointed: 'Decepcionado',
      discontented: 'Descontento',
      disgusted: 'Disgustado',
      dismayed: 'Construccionado',
      displeased: 'Disgustado',
      dissatisfied: 'Insatisfecho',
      excited: 'Entusiasmado',
      frustrated: 'Frustrado',
      glad: 'Contento',
      grateful: 'Agradecido',
      happy: 'Feliz',
      impatient: 'Impaciente',
      impressed: 'Impresionado',
      indignant: 'Indignado',
      irritated: 'Irritado',
      loyal: 'Leal',
      outraged: 'Indignado',
      pleased: 'Complacido',
      regretful: 'Arrepentido',
      resentful: 'Resentido',
      sarcastic: 'Sarcástico',
      satisfied: 'Satisfecho',
      surprised: 'Sorprendido',
      thankful: 'Agradecido',
      thrilled: 'Encantado',
    },
  },
};

export const getEmotionsResolver = (locale: UserLocale) => {
  const { t } = localeUtils({ translations, locale });

  const getEmotionEmoji = (emotion: Emotion) => emotionEmojiMap[emotion];

  const getEmotionLabel = (emotion: Emotion) => {
    return match(emotion)
      .with('AMAZED', () => t('amazed'))
      .with('ANGRY', () => t('angry'))
      .with('APPRECIATIVE', () => t('appreciative'))
      .with('BETRAYED', () => t('betrayed'))
      .with('DELIGHTED', () => t('delighted'))
      .with('DISAPPOINTED', () => t('disappointed'))
      .with('DISCONTENTED', () => t('discontented'))
      .with('DISGUSTED', () => t('disgusted'))
      .with('DISMAYED', () => t('dismayed'))
      .with('DISPLEASED', () => t('displeased'))
      .with('DISSATISFIED', () => t('dissatisfied'))
      .with('EXCITED', () => t('excited'))
      .with('FRUSTRATED', () => t('frustrated'))
      .with('GLAD', () => t('glad'))
      .with('GRATEFUL', () => t('grateful'))
      .with('HAPPY', () => t('happy'))
      .with('IMPATIENT', () => t('impatient'))
      .with('IMPRESSED', () => t('impressed'))
      .with('INDIGNANT', () => t('indignant'))
      .with('IRRITATED', () => t('irritated'))
      .with('LOYAL', () => t('loyal'))
      .with('OUTRAGED', () => t('outraged'))
      .with('PLEASED', () => t('pleased'))
      .with('REGRETFUL', () => t('regretful'))
      .with('RESENTFUL', () => t('resentful'))
      .with('SARCASTIC', () => t('sarcastic'))
      .with('SATISFIED', () => t('satisfied'))
      .with('SURPRISED', () => t('surprised'))
      .with('THANKFUL', () => t('thankful'))
      .with('THRILLED', () => t('thrilled'))
      .exhaustive();
  };

  return {
    getEmotionEmoji,
    getEmotionLabel,
    resolveEmotion: (emotion: Emotion) => {
      return {
        emoji: getEmotionEmoji(emotion),
        label: getEmotionLabel(emotion),
      };
    },
  };
};

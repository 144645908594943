import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { Task } from '@lgg/isomorphic/types/__generated__/graphql';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { useOpenCallContactModal } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';
import { VisibilityHandler } from 'src/hooks/use-visible';

type TaskOptionsBottomDrawerProps = {
  onClose: VoidFunction;
  selectedTask: Task | null;
  visible: boolean;
  mobileStatusOptionsVisibilityHandler: VisibilityHandler;
};

export const TaskOptionsBottomDrawer = memo<TaskOptionsBottomDrawerProps>(
  ({ onClose, selectedTask, visible, mobileStatusOptionsVisibilityHandler }) => {
    const { t } = useTranslation(['tasks']);
    const { getContactModalUrl, getTaskModalUrl } = useUrls();
    const breakpointUpMd = useBreakpoint(up('md'));
    const openCallContactModal = useOpenCallContactModal();
    const history = useHistory();
    const options = useMemo(() => {
      const options = [
        {
          onClick: () => {
            if (selectedTask) {
              history.push(getTaskModalUrl(selectedTask.id));
              onClose();
            }
          },
          label: t('tasks:taskOptions.viewTask'),
          icon: 'task18',
          'data-lgg-id': 'task-option-view-task',
        },
        {
          onClick: () => {
            mobileStatusOptionsVisibilityHandler.setVisible(true);
            onClose();
          },
          label: t('tasks:taskOptions.updateStatus'),
          icon: 'updateStatus',
          'data-lgg-id': 'task-option-update-status',
        },
      ];

      if (selectedTask?.contact) {
        options.push({
          onClick: () => {
            if (selectedTask.contact) {
              history.push(getContactModalUrl(selectedTask?.contact?.id));
              onClose();
            }
          },
          label: t('tasks:taskOptions.viewContact'),
          icon: 'contact',
          'data-lgg-id': 'task-option-view-contact',
        });

        options.push({
          onClick: () => {
            if (selectedTask.contact) {
              void openCallContactModal(selectedTask.contact.id);
              onClose();
            }
          },
          label: t('tasks:taskOptions.callContact'),
          icon: 'call',
          'data-lgg-id': 'task-option-call-contact',
        });
      }

      return options;
    }, [
      t,
      selectedTask,
      history,
      getTaskModalUrl,
      onClose,
      mobileStatusOptionsVisibilityHandler,
      getContactModalUrl,
      openCallContactModal,
    ]);

    return (
      <OptionsBottomDrawer
        onClose={onClose}
        title={t('tasks:taskOptions.title')}
        visible={
          !breakpointUpMd && visible && !mobileStatusOptionsVisibilityHandler.visible
        }
        options={options}
      />
    );
  },
);

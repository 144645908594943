import { up } from 'styled-breakpoints';
import styled from 'styled-components';

const BasePageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 100%;
`;

export const TableLayoutPageContainer = styled(BasePageContainer)`
  ${up('md')} {
    padding: 30px 30px 0;
  }
`;

export const PageContainer = styled(BasePageContainer)`
  ${up('md')} {
    padding: 30px;
    width: 100%;
  }
`;

import { match } from 'ts-pattern';
import { Task } from '@lgg/isomorphic/types/__generated__/graphql';

export const getTaskCategoryTransKey = (category: Task['category']) => {
  return match(category)
    .with('TO-DO', () => 'tasks:category.toDo' as const)
    .with('EMAIL', () => 'tasks:category.email' as const)
    .with('CALL', () => 'tasks:category.call' as const)
    .otherwise(() => undefined);
};

import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import {
  ContactInteractionAttachmentFile,
  ContactInteractionDirection,
  LeadHistoryLogStatus,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { DeliveryStatusIcon } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { ContactInteractionsWithAttachmentUnion } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';

export type IsomorphicAttachmentFileItemProps = {
  attachment: ContactInteractionAttachmentFile;
  attachmentDate: string;
  contactInteraction: ContactInteractionsWithAttachmentUnion;
};

export const PlayIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.halfWhite};
  border-radius: 50%;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  z-index: 3;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const AttachmentOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: inherit;

  background: linear-gradient(
    to bottom,
    transparent 44%,
    ${({ theme }) => theme.colors.carbon}
  );
`;

const AttachmentDate = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.flint};
`;

export const FloatingContainer = styled(FlexRow)<{ floating?: boolean }>`
  align-items: center;

  ${({ floating }) =>
    floating
      ? `position: absolute;
    bottom: 10px;
    left: 15px;
    z-index: 3;`
      : `
    margin-top: 5px;`}
`;

type AttachmentDateAndStatusProps = {
  attachmentDate: string;
  attachmentStatus: LeadHistoryLogStatus | null;
  showStatus?: boolean;
  floating?: boolean;
};

export const AttachmentDateAndStatus = ({
  attachmentDate,
  attachmentStatus,
  floating = false,
  showStatus = false,
  ...props
}: AttachmentDateAndStatusProps) => {
  return (
    <FloatingContainer {...props} floating={floating}>
      <AttachmentDate>{attachmentDate}</AttachmentDate>
      {showStatus && attachmentStatus ? (
        <DeliveryStatusIcon messageStatus={attachmentStatus} />
      ) : null}
    </FloatingContainer>
  );
};

export const BubbleTypeAttachmentContainer = styled.div<{
  direction: ContactInteractionDirection;
}>`
  padding: 15px 15px 10px;

  ${({ direction }) =>
    direction === 'INBOUND'
      ? css`
          background-color: ${({ theme }) => theme.colors.porcelain};

          ${up('md')} {
            background-color: ${({ theme }) => theme.colors.white};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.topaz20};
        `}
`;

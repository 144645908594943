import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { ConcreteInstitutionInterface } from '@lgg/isomorphic';
import { Tooltip } from 'src/components/general/display/tooltip';
import {
  InstitutionAvatar,
  InstitutionNameTextStyle,
} from 'src/components/layout/authenticated-layout/sidebar/institution-chooser/shared';
import { useInstitutionPathPrefix } from 'src/hooks/use-institution-path-prefix';

const InstitutionItemRow = styled(Row)`
  height: 50px;
  padding: 0 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey2};
  }
`;

const SecondaryInstitutionName = styled.span`
  ${InstitutionNameTextStyle};
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const InstitutionItem = memo<{ institution: ConcreteInstitutionInterface }>(
  ({ institution }) => {
    const { prefix: institutionUrl } = useInstitutionPathPrefix({
      id: institution.id,
      type: 'Company',
    });

    return (
      <NavLink to={institutionUrl} data-lgg-id={`institution-item-${institution.id}`}>
        <InstitutionItemRow align="middle" wrap={false}>
          <Col style={{ marginRight: '10px' }}>
            <InstitutionAvatar />
          </Col>
          <Tooltip placement="top" title={institution.name}>
            <SecondaryInstitutionName>{institution.name}</SecondaryInstitutionName>
          </Tooltip>
        </InstitutionItemRow>
      </NavLink>
    );
  },
);

import { useEffect } from 'react';
import { ContactNote } from '@lgg/isomorphic/types/__generated__/graphql';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { isRefreshDataEventType } from 'src/types/type-guards';

export const useRefreshContactNotes = ({ onRefresh }: UseRefreshProps) => {
  useEffect(() => {
    const handleRefresh = async (e, body) => {
      if (isRefreshDataEventType<ContactNote>(body, 'note')) {
        const { id, action } = body.entity;

        await onRefresh({ id, action });
      }
    };

    window.jQuery(window).on('refreshData', handleRefresh);

    return () => window.jQuery(window).off('refreshData', handleRefresh);
  }, [onRefresh]);
};

import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { StatusBar } from '@capacitor/status-bar';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import App from 'src/App';
import { oneSignalInit } from 'src/onesignal-init';
import reportWebVitals from 'src/reportWebVitals';

if (Capacitor.isNativePlatform()) {
  // Lock screen orientation to portrait
  void ScreenOrientation.lock({ orientation: 'portrait' });

  // Notify CapacitorUpdater that the app is ready to check for updates
  void CapacitorUpdater.notifyAppReady();

  // This is needed to display content under transparent status bar (Android only)
  // ref: https://capacitorjs.com/docs/apis/status-bar#example
  void StatusBar.setOverlaysWebView({ overlay: true });

  oneSignalInit();
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>, // TODO: re-enable
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import fnv1a from '@sindresorhus/fnv1a';
import i18next, { i18n, Resource, ResourceKey, TFuncKey, TOptions } from 'i18next';
import { IsEqual } from 'type-fest';
import { dayjs } from '../dayjs';
import { DefaultLanguage, defaultLanguage } from './defaults';
import { StrictDefaultNS, StrictResource } from './strict-resource';
import { SupportedLanguagesForI18n } from './supported-langs';

export type UserLocale = {
  language: SupportedLanguagesForI18n;
  timeZone: string;
};

const cache: Record<string, { [k in SupportedLanguagesForI18n]?: i18n }> = {};

export function localeUtils<
  T extends StrictResource | Resource,
  TKeys = IsEqual<T[DefaultLanguage][StrictDefaultNS], ResourceKey> extends true
    ? string
    : T[DefaultLanguage][StrictDefaultNS] extends ResourceKey
    ? TFuncKey<StrictDefaultNS, undefined, T[DefaultLanguage]>
    : string,
>({
  translations,
  locale: { language, timeZone },
}: {
  translations: T;
  locale: UserLocale;
}): {
  t: (key: TKeys | TKeys[], options?: TOptions) => string;
  formatDate: (date: string, format: string) => string;
} {
  const hash = fnv1a.bigInt(JSON.stringify(translations), { size: 64 }).toString();
  let translationsMap = cache[hash];
  if (!translationsMap) {
    const default18nextInstance = i18next.createInstance({
      initImmediate: false, // https://www.i18next.com/overview/configuration-options#initimmediate
      resources: translations as Resource,
      fallbackLng: defaultLanguage,
      fallbackNS: ['common'],
    });
    void default18nextInstance.init();
    translationsMap = { [defaultLanguage]: default18nextInstance };
    cache[hash] = translationsMap;
  }
  let languageInstance = translationsMap[language];
  if (!languageInstance) {
    const default18nextInstance = translationsMap[defaultLanguage];
    languageInstance = default18nextInstance!.cloneInstance({ lng: language });
    translationsMap[language] = languageInstance;
  }
  const formatDate = (date: string, format: string) => {
    return dayjs(date)
      .tz(timeZone)
      .locale(languageInstance!.resolvedLanguage ?? defaultLanguage)
      .format(format);
  };
  const t = languageInstance!.t.bind(languageInstance!);
  return {
    t,
    formatDate,
  };
}

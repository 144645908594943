import { css } from 'styled-components';
import EmojiPickerClearSearchIcon from 'src/assets/icons/emoji-picker-clear-search-icon.svg?url';
import EmojiPickerSearchIcon from 'src/assets/icons/emoji-picker-search-icon.svg?url';
import emojiPickerIcons from 'src/assets/icons/emoji-picker.svg?url';

export const emojiPickerGlobalStyle = css`
  // These styles are used to reduce the waiting time when you try to show the emoji picker.
  // Right now the library renders all the emojis and it's causing a bit of delay when opening/closing the modal.
  .unfocused .EmojiPickerReact {
    .epr-body .epr-emoji-list > .epr-emoji-category:not(:nth-child(-n + 2)) {
      display: none;
    }

    .epr-body
      .epr-emoji-list
      > .epr-emoji-category
      .epr-emoji-category-content
      > button:not(:nth-child(-n + 40)) {
      display: none;
    }
  }

  aside.EmojiPickerReact {
    --epr-active-skin-hover-color: ${({ theme }) => theme.colors.topaz20};
    --epr-category-label-padding: 15px;
    --epr-category-navigation-button-size: 30px;
    --epr-emoji-padding: 6px;
    --epr-emoji-size: 22px;
    --epr-header-padding: 15px 10px;
    --epr-hover-bg-color: ${({ theme }) => theme.colors.topaz20};
    --epr-search-border-color: ${({ theme }) => theme.colors.koala};
    --epr-search-input-bg-color: ${({ theme }) => theme.colors.white};
    --epr-search-input-border-radius: 4px;
    --epr-search-input-height: 30px;
    --epr-search-input-padding: 0 36px;
    --epr-search-input-placeholder-color: ${({ theme }) => theme.colors.geyser};
    --epr-skin-tone-size: 16px;
    --epr-emoji-hover-color: ${({ theme }) => theme.colors.topaz20};

    &.epr-main {
      border: none !important;

      &::after {
        background-image: linear-gradient(
          to bottom,
          transparent,
          ${({ theme }) => theme.colors.white} 88%
        );
        bottom: 0;
        content: '';
        height: 22px;
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    button.epr-emoji.epr-emoji-has-variations:after {
      pointer-events: none;
    }

    &.epr-search-active {
      .epr-header {
        .epr-skin-tones {
          display: none;
        }
      }
    }

    .epr-header {
      display: flex;
      flex-direction: column-reverse;

      .epr-category-nav {
        padding: 10px 15px 0px;

        & > button.epr-cat-btn {
          background-image: url(${emojiPickerIcons});
        }

        button {
          border-bottom: 2px solid ${({ theme }) => theme.colors.white};
          height: 32px;

          &:first-child {
            display: none;
          }

          &.epr-active {
            border-bottom: 2px solid ${({ theme }) => theme.colors.gogo};
          }

          &:before {
            display: none;
          }
        }
      }

      .epr-header-overlay {
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.koala};
        height: 34px;
        margin: 9px 15px 10px;
        padding: 0;
        position: relative;

        .epr-search-container {
          color: ${({ theme }) => theme.colors.geyser};
          font-family: ${({ theme }) => theme.font.regular};
          font-size: 13px;
          line-height: 15px;

          .epr-search {
            color: ${({ theme }) => theme.colors.smalt};
            font-family: ${({ theme }) => theme.font.regular};
            font-size: 13px;
            border-color: ${({ theme }) => theme.colors.white};
            line-height: 15px;
          }

          .epr-icn-search {
            background-image: url(${EmojiPickerSearchIcon});
            background-size: 16px;
            height: 16px;
            left: 10px;
            width: 16px;
          }

          .epr-icn-clear-search {
            background-image: none;
          }

          button.epr-btn-clear-search {
            &,
            &:focus,
            &:hover {
              background-image: url(${EmojiPickerClearSearchIcon});
              background-size: 14px;
              background: none;
              height: 14px;
              width: 14px;
            }
          }
        }

        .epr-skin-tones.epr-horizontal {
          right: 10px;

          .epr-skin-tone-select {
            button {
              height: 12px;
              top: 1px;
              width: 12px;

              &.epr-active {
                padding: 5px;
              }
            }
          }

          .epr-tone:hover {
            box-shadow: none;
          }

          &.epr-open {
            flex-basis: 115px !important;

            .epr-skin-tone-select {
              button {
                &:nth-child(1) {
                  transform: translateX(0px) !important;
                }

                &:nth-child(2) {
                  transform: translateX(-20px) !important;
                }

                &:nth-child(3) {
                  transform: translateX(-40px) !important;
                }

                &:nth-child(4) {
                  transform: translateX(-60px) !important;
                }

                &:nth-child(5) {
                  transform: translateX(-80px) !important;
                }

                &:nth-child(6) {
                  transform: translateX(-100px) !important;
                }

                &.epr-active {
                  box-shadow: none;
                  outline: 1px solid ${({ theme }) => theme.colors.gogo};

                  :after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .epr-body {
      border-top: 1px solid ${({ theme }) => theme.colors.koala};
      position: relative;

      .epr-emoji-list {
        margin-bottom: 10px;
      }

      li.epr-emoji-category {
        & > .epr-emoji-category-label {
          color: ${({ theme }) => theme.colors.carbon};
          font-family: ${({ theme }) => theme.font.medium};
          font-weight: 500;
          line-height: 17px;
          size: 14px;
          top: -1px;
        }

        & > .epr-emoji-category-content {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

          button {
            border-radius: 4px;
            margin-bottom: 10px;
            margin: 0 auto;

            &.epr-emoji {
              &:focus > * {
                background-color: ${({ theme }) => theme.colors.topaz20};
              }
            }
          }
        }

        &[data-name='suggested'] {
          .epr-emoji-category-content {
            & > button:nth-child(n + 7) {
              display: none;
            }
          }
        }
      }
    }

    .epr-preview {
      border-top: none;
      height: 40px;
      position: absolute;
      right: -6px;
      top: 96px;

      & > *:first-child {
        display: none;
      }

      .epr-preview-emoji-label {
        color: ${({ theme }) => theme.colors.flint};
        font-family: ${({ theme }) => theme.font.regular};
        font-size: 11px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

import { useService } from 'react-service-locator';
import { InstitutionInterface } from '@lgg/isomorphic';
import { SessionService } from 'src/services/session.service';

export function useDefaultInstitution(): InstitutionInterface {
  const sessionService = useService(SessionService, (s) => [
    s.state.sessionData?.user.institution,
  ]);

  const institution = sessionService.state.sessionData?.user.institution;

  if (!institution) {
    throw new Error('Could not obtain an institution');
  }

  // From the API a row with `0` as primary key, can not be populated, that's why we are
  // mocking the `0` institution, which is `System` type.
  if (institution.id === 0) {
    return {
      ...institution,
      type: 'System',
    } as any; // TODO: remove this temporary hack
  }

  return institution;
}

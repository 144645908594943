import { gql } from '@apollo/client';
import { Task } from '@lgg/isomorphic/types/__generated__/graphql';

export const CALENDAR_EVENTS_QUERY = gql`
  query calendarEvents($institutionId: Int!, $where: CalendarEventsWhereInput!) {
    calendarEvents(institutionId: $institutionId, where: $where) {
      totalCount
      nodes {
        ... on Schedule {
          id
          title
          description
          startAt
          isAllDay
          endAt
          status
          contact {
            id
          }
          company {
            id
          }
          attendees {
            ... on ScheduleAttendee {
              id
            }
            ... on ScheduleContactAttendee {
              contact {
                id
                label
              }
            }
            ... on ScheduleUserAttendee {
              user {
                fullName
              }
            }
            ... on ScheduleEmailAttendee {
              email
            }
          }
          user {
            id
            fullName
          }
        }
        ... on Task {
          id
          title
          description
          dueAt
          category
          taskStatus: status
          reminderAt
          contact {
            id
            label
          }
          company {
            id
          }
        }
      }
    }
  }
`;

export type TaskEvent = Omit<Task, 'status'> & {
  taskStatus: Task['status'];
};

import { css } from 'styled-components';

export const disabledUserSelectGlobalStyle = css`
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none; /* Mozzilla */
  user-select: none; /* Standard syntax */
`;

export const enabledUserSelectGlobalStyle = css`
  -webkit-user-select: text; /* Safari */
  -ms-user-select: text; /* IE 10 and IE 11 */
  -moz-user-select: text; /* Mozzilla */
  user-select: text; /* Standard syntax */
`;

import { memo } from 'react';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Icon } from 'src/components/general/icon';

export type LggCircleIconProps = {
  bgColor: ColorPaletteItem;
  iconColor: ColorPaletteItem;
  $width?: string;
  $height?: string;
  icon: string;
};

const CircleIcon = styled.div<Omit<LggCircleIconProps, 'icon'>>`
  align-items: center;
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;

  svg {
    height: ${({ $height }) => $height ?? '18px'};
    width: ${({ $width }) => $width ?? '18px'};

    path {
      fill: ${({ theme, iconColor }) => theme.colors[iconColor]};
    }
  }
`;

export const LggCircleIcon = memo<LggCircleIconProps>(
  ({ icon, bgColor, iconColor, ...rest }) => {
    return (
      <CircleIcon bgColor={bgColor} iconColor={iconColor} {...rest}>
        <Icon type={icon} />
      </CircleIcon>
    );
  },
);

type RefreshLeadEventBody = {
  triggerEvent: string;
  data: {
    lead: {
      id: string;
    };
  };
};

type RefreshDataEventType<T> = {
  triggerEvent: string;
  entity: {
    id: number;
    type: T;
    action?: 'update' | 'create';
  } & {
    [key: string]: T & {
      displayName?: string;
    };
  };
};

export const isRefreshLeadEventBody = (body: any): body is RefreshLeadEventBody => {
  return 'triggerEvent' in body && 'data' in body && 'lead' in body.data;
};

export const isRefreshDataEventType = <T>(
  body: any,
  type: string,
): body is RefreshDataEventType<T> => {
  return (
    'triggerEvent' in body &&
    'entity' in body &&
    'type' in body.entity &&
    body.entity.type === type
  );
};

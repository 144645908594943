import { useDateHelpers } from 'src/hooks/use-date-helpers';

export const SNOOZED_UNTIL_DATE_FORMAT = 'MMM D, h:mm A';

export const useSnoozeUntilDates = () => {
  const { addMinutes, addHours, startOfTomorrow, set, nextMonday, addDays } =
    useDateHelpers();

  const getSnoozeDateUntil30Minutes = (baseDate?: Date) => {
    return addMinutes(baseDate ?? new Date(), 30);
  };

  const getSnoozeDateUntilNHours = (hours: number, baseDate?: Date) => {
    return addHours(baseDate ?? new Date(), hours);
  };

  const getSnoozeDateUntilTomorrow = (baseDate?: Date) => {
    const result = set(baseDate ? addDays(baseDate, 1) : startOfTomorrow(), {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 8,
      useTimezone: true,
    });

    return result;
  };

  const getSnoozeDateUntilNextWeek = (baseDate?: Date) => {
    return set(nextMonday(baseDate ?? new Date()), {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 8,
    });
  };

  return {
    getSnoozeDateUntil30Minutes,
    getSnoozeDateUntilNHours,
    getSnoozeDateUntilTomorrow,
    getSnoozeDateUntilNextWeek,
  };
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useService } from 'react-service-locator';
import Animate from 'rc-animate';
import { Icon } from 'src/components/general/icon';
import {
  SidebarMenuItem,
  SidebarSubMenu,
} from 'src/components/layout/authenticated-layout/sidebar/menu/menu-items';
import {
  SidebarMenuProps,
  StyledMenu,
} from 'src/components/layout/authenticated-layout/sidebar/menu/shared';
import { useAuthorization } from 'src/hooks/use-authorization';
import { SidebarService } from 'src/services/sidebar.service';

export const OrganizationMenu = memo<SidebarMenuProps>((props) => {
  const { hasPermission } = useAuthorization();

  const sidebarService = useService(SidebarService, (s) => [s.state.isCollapsed]);
  const {
    state: { isCollapsed: sidebarIsCollapsed },
  } = sidebarService;

  const { url: institutionUrl } = useRouteMatch();
  const { t } = useTranslation(['common']);

  return (
    <StyledMenu {...props}>
      {hasPermission('organization:company.access') && (
        <SidebarMenuItem
          icon="companies"
          to={`${institutionUrl}/companies/`}
          key={`${institutionUrl}/companies/`}
          label={t('common:sidebar.companies')}
        />
      )}
      {hasPermission('organization:access') && (
        <SidebarSubMenu
          key={`${institutionUrl}/reports/`}
          to={`${institutionUrl}/reports/`}
          subMenuSelectedKeys={props.selectedKeys}
          icon="reports"
          title={
            <React.Fragment>
              <span>{t('common:sidebar.reports')}</span>
              <Animate transitionName="ant-fade">
                {!sidebarIsCollapsed && <Icon type="dropdown" className="lgg-arrow" />}
              </Animate>
            </React.Fragment>
          }
        >
          <SidebarMenuItem
            to={`${institutionUrl}/reports/usage/`}
            key={`${institutionUrl}/reports/usage/`}
            label={t('common:sidebar.usage')}
          />
        </SidebarSubMenu>
      )}
      {hasPermission('organization:resource.access') && (
        <SidebarMenuItem
          icon="tracking"
          to={`${institutionUrl}/resources/`}
          key={`${institutionUrl}/resources/`}
          label={t('common:sidebar.resources')}
        />
      )}
      {hasPermission('organization:account.access') && (
        <SidebarMenuItem
          icon="accounts"
          to={`${institutionUrl}/accounts/`}
          key={`${institutionUrl}/accounts/`}
          label={t('common:sidebar.accounts')}
        />
      )}
      {hasPermission('organization:manage') && (
        <SidebarMenuItem
          icon="settings"
          to={`${institutionUrl}/settings/`}
          key={`${institutionUrl}/settings/`}
          label={t('common:sidebar.settings')}
        />
      )}
    </StyledMenu>
  );
});

import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ActiveOptionBadge } from 'src/components/general/feedback/active-option-badge';
import { Icon } from 'src/components/general/icon';

export const NotificationBadge = styled(ActiveOptionBadge)`
  margin-left: 20px;

  ${up('md')} {
    margin-left: 32px;
    margin-right: 10px;
  }

  .ant-badge-dot {
    width: 18px;
    height: 18px;
    border: 2px solid ${({ theme }) => theme.colors.gogo};

    ${up('md')} {
      width: 19px;
      height: 19px;
      border: 2px solid ${({ theme }) => theme.colors.white};
    }
  }

  &.dot {
    .ant-badge-dot {
      width: 11px;
      height: 11px;
    }
  }

  .ant-badge-count {
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 10px;
    line-height: 12px;
    height: 15px;
    min-width: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    outline: 2px solid ${({ theme }) => theme.colors.gogo};
    background: ${({ theme }) => theme.colors.monk};
    box-shadow: none;
    padding: 0 2px;

    .ant-scroll-number-only,
    .ant-scroll-number-only-unit {
      height: 100%;
    }

    .ant-scroll-number-only-unit {
      line-height: normal;
    }

    ${up('md')} {
      outline-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const NotificationIconContainer = styled.div`
  svg {
    cursor: pointer;
    // Lottie adds a padding that can't be removed, scaling is needed to keep the desired size.
    scale: 1.3;

    path {
      fill: ${({ theme }) => theme.colors.white};
      stroke: ${({ theme }) => theme.colors.white};

      ${up('md')} {
        fill: ${({ theme }) => theme.colors.flint};
        stroke: ${({ theme }) => theme.colors.flint};
      }
    }
  }
`;

export const ExtraMobileHeaderIcon = styled(Icon)`
  margin-right: 20px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

export const ExtraDesktopHeaderIcon = styled(Icon)`
  margin-right: 12px;
  cursor: pointer;
  padding: 0 8px;

  svg {
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

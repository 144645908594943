import React, { memo } from 'react';
import styled from 'styled-components';
import {
  AlertType,
  getAlertIconByType,
  successAlertStyles,
  warningAlertStyles,
  infoAlertStyles,
  errorAlertStyles,
} from 'src/components/general/feedback/shared';
import { Icon } from 'src/components/general/icon';

const Text = styled.span`
  color: ${({ theme }) => theme.colors.carbonBlue};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.29;
  margin-left: 15px;
  text-align: left;
`;

const ToastContainer = styled.div<Pick<ToastProps, 'type'>>`
  align-items: center;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 10px 20px 0 #727d8e33;
  display: flex;
  flex-direction: row;
  padding: 14px 15px;

  ${({ type }) => type === 'success' && successAlertStyles};
  ${({ type }) => type === 'error' && errorAlertStyles};
  ${({ type }) => type === 'info' && infoAlertStyles};
  ${({ type }) => type === 'warning' && warningAlertStyles};
`;

type ToastProps = {
  children: React.ReactNode;
  type: AlertType;
};

export const Toast = memo<ToastProps>(({ type, children, ...rest }) => {
  return (
    <ToastContainer {...rest} type={type}>
      <Icon type={getAlertIconByType(type)} />
      <Text>{children}</Text>
    </ToastContainer>
  );
});

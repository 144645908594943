import { useState } from 'react';

export type VisibilityHandler = {
  show: VoidFunction;
  close: VoidFunction;
  visible: boolean;
  setVisible: ValueChanged<boolean>;
};

export const useVisible = (defaultValue: boolean = false): VisibilityHandler => {
  const [visible, setVisible] = useState(defaultValue);

  const show = () => setVisible(true);
  const close = () => setVisible(false);

  return {
    show,
    close,
    visible,
    setVisible,
  };
};

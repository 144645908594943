import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { CONTACT_BLOCKING_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { isRefreshDataEventType } from 'src/types/type-guards';

export const GET_CONTACT = gql`
  query GetContact($id: Int!) {
    contact(id: $id) {
      id
      createdAt
      firstName
      fullName
      fullAddress
      lastContactInteraction {
        id
        occurredAt
      }
      interest
      label
      lastName
      primaryEmail
      primaryPhone {
        national
        e164
      }
      secondaryPhone {
        national
        e164
      }
      updatedAt
      stage {
        id
        name
        slug
      }
      status {
        id
        name
      }
      tags {
        id
        name
        isActive
      }
      assignee {
        id
        fullName
      }
    }
  }
`;

export const GET_CONTACT_BLOCKED = gql`
  ${CONTACT_BLOCKING_FIELDS}
  query GetContact($id: Int!) {
    contact(id: $id) {
      id
      ...ContactBlockingFieldsFragment
    }
  }
`;

export const useRefreshContact = ({ onRefresh }: UseRefreshProps) => {
  useEffect(() => {
    const handleRefresh = (e, body) => {
      if (isRefreshDataEventType<Contact>(body, 'lead')) {
        const { id, action, contact } = body.entity;

        onRefresh({
          id,
          action,
          entityFullName: contact?.displayName,
        });
      }
    };

    window.jQuery(window).on('refreshLead', handleRefresh);

    return () => window.jQuery(window).off('refreshLead', handleRefresh);
  }, [onRefresh]);
};

import {
  useContactTags,
  UseContactTagsReturn,
} from 'src/components/domain/contacts/hooks/use-contact-tags';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseContactTagListForSelectReturn = {
  loadingTagOptions: boolean;
  tagOptions: SelectOption<number>[];
} & Pick<UseContactTagsReturn, 'refetch' | 'loadMore' | 'hasNextPage'>;

export const useContactTagListForSelect = (): UseContactTagListForSelectReturn => {
  const { tags, loading, refetch, loadMore, hasNextPage } = useContactTags();

  const tagOptions = tags ? tags.map(entityToSelectOptionMapper) : [];

  return {
    tagOptions,
    loadingTagOptions: loading,
    refetch,
    loadMore,
    hasNextPage,
  };
};

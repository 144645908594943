import React from 'react';
import { render } from 'react-dom';
import { Icon } from 'src/components/general/icon';

export function renderButtonContent(button: Element, hideText: boolean = false) {
  const iconsMapping: any = {
    'lg-icon-circle-add': 'add',
    export: 'share',
    contact: 'add',
    task: 'add',
    schedule: 'add',
    campaign: 'add',
    'add-new-organization': 'add',
    'circle-add': 'add',
    group: 'group',
  };

  const showCaret: boolean = button.getAttribute('data-toggle') === 'dropdown';

  if (button.getElementsByClassName('lg-icon-circle-add').length) {
    button.classList.add('btn-success');
  } else {
    button.classList.remove('btn-success');
    button.classList.add('lgg-btn-white');
  }

  const buttonText = button.textContent?.trim();
  let iconType: string | null = null;
  const faIcon = button.getElementsByClassName('fa');

  if (faIcon.length) {
    faIcon[0]
      .getAttribute('class')
      ?.split(' ')
      .forEach((className: string) => {
        if (iconsMapping[className] !== undefined) {
          iconType = iconsMapping[className];
        }
      });
  }

  const icons = button.getElementsByTagName('i');

  if (!iconType && icons.length) {
    icons[0].classList.forEach((icon) => {
      if (icon.indexOf('lg-icon') !== -1) {
        iconType = iconsMapping[icon.replace('lg-icon-', '')];
      }
    });
  }

  if (buttonText === 'Export') {
    iconType = 'exportbtn';
  }

  if (buttonText === 'Import') {
    iconType = 'import';
  }

  if (iconType && hideText) {
    button.classList.add('lgg-icon-only');
  }

  if (button.classList.contains('previous')) {
    iconType = 'arrowright';
  }

  const buttonContent = (
    <React.Fragment>
      {iconType && <Icon className={!hideText ? 'lgg-icon-left' : ''} type={iconType} />}
      {!hideText && <span>{buttonText}</span>}
      {button.classList.contains('next') && (
        <Icon type="arrowright" style={{ marginLeft: 10 }} />
      )}
      {!hideText && showCaret && <Icon type="dropdown" className="lgg-icon-right" />}
    </React.Fragment>
  );

  render(buttonContent, button);
}

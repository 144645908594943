import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import {
  Contact,
  ContactInteractionChannelSlug,
  ConversationItemUnion,
  Query,
  QueryContactArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { InputUploadFile } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-area-attachment-options';
import { GET_CONTACT_CONTACT_INFORMATION } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import {
  ContactInteractionInputAreaMode,
  ContactPhoneOption,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export type ContactInteractionInputManagerContextProps = {
  conversationId: string | null;
  contact: Contact;
  messageValues: {
    message: string;
    rawMessage: string;
  };
  setMessageValues: Dispatch<
    SetStateAction<{
      message: string;
      rawMessage: string;
    }>
  >;
  attachmentList: InputUploadFile[];
  setAttachmentList: Dispatch<SetStateAction<InputUploadFile[]>>;
  hasFocus: boolean;
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  clearMessageInputStates: VoidFunction;
  contactPhoneNumbers: ContactPhoneOption[];
  setContactPhoneNumbers: Dispatch<SetStateAction<ContactPhoneOption[]>>;
  selectedInteraction?: ConversationItemUnion;
  fetchingContactPhoneNumbers: boolean;
  inputAreaMode: ContactInteractionInputAreaMode;
  setInputAreaMode: Dispatch<SetStateAction<ContactInteractionInputAreaMode>>;
  selectedChannelSlug: ContactInteractionChannelSlug | null;
  setSelectedChannelSlug: Dispatch<SetStateAction<ContactInteractionChannelSlug | null>>;
};

export const ContactInteractionInputManagerContext =
  React.createContext<ContactInteractionInputManagerContextProps>({
    contact: {} as Contact,
    conversationId: null,
    messageValues: {
      message: '',
      rawMessage: '',
    },
    setMessageValues: () => {},
    attachmentList: [],
    setAttachmentList: () => {},
    hasFocus: false,
    setHasFocus: () => {},
    clearMessageInputStates: () => {},
    contactPhoneNumbers: [],
    setContactPhoneNumbers: () => {},
    selectedInteraction: undefined,
    fetchingContactPhoneNumbers: false,
    inputAreaMode: ContactInteractionInputAreaMode.Reply,
    setInputAreaMode: () => {},
    selectedChannelSlug: null,
    setSelectedChannelSlug: () => {},
  });

type ContactInteractionInputManagerProps = {
  children: React.ReactNode;
  contact: Contact;
  conversationId: string;
  selectedInteraction?: ConversationItemUnion;
};

export const ContactInteractionInputManager = memo(
  ({
    children,
    contact,
    conversationId,
    selectedInteraction,
  }: ContactInteractionInputManagerProps) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const [messageValues, setMessageValues] = useState<{
      message: string;
      rawMessage: string;
    }>({
      message: '',
      rawMessage: '',
    });
    const [attachmentList, setAttachmentList] = useState<InputUploadFile[]>([]);
    const [hasFocus, setHasFocus] = useState(false);
    const [contactPhoneNumbers, setContactPhoneNumbers] = useState<ContactPhoneOption[]>(
      [],
    );
    const [inputAreaMode, setInputAreaMode] = useState<ContactInteractionInputAreaMode>(
      ContactInteractionInputAreaMode.Reply,
    );
    const [selectedChannelSlug, setSelectedChannelSlug] =
      useState<ContactInteractionChannelSlug | null>(null);

    const clearMessageInputStates = useCallback(() => {
      setMessageValues({
        message: '',
        rawMessage: '',
      });
      setAttachmentList([]);

      if (!breakpointUpMd) {
        setHasFocus(false);
      }
    }, [breakpointUpMd]);

    const [getContactPhoneNumbers, { loading: fetchingContactPhoneNumbers }] =
      useLazyQuery<Pick<Query, 'contact'>, QueryContactArgs>(
        GET_CONTACT_CONTACT_INFORMATION,
        {
          onCompleted: ({ contact }) => {
            const { primaryPhone, secondaryPhone } = contact;
            const contactPhoneNumbers: ContactPhoneOption[] = [];

            if (primaryPhone) {
              contactPhoneNumbers.push({
                ...primaryPhone,
                type: 'PRIMARY',
              });
            }

            if (secondaryPhone) {
              contactPhoneNumbers.push({
                ...secondaryPhone,
                type: 'SECONDARY',
              });
            }

            setContactPhoneNumbers(contactPhoneNumbers);
          },
        },
      );

    useEffect(() => {
      const fetchContactPhoneNumbers = async (contactId: number) => {
        await getContactPhoneNumbers({
          variables: {
            id: contactId,
          },
        });
      };

      if (contact.id) {
        void fetchContactPhoneNumbers(contact.id);
      }
    }, [contact.id, getContactPhoneNumbers]);

    return (
      <ContactInteractionInputManagerContext.Provider
        value={{
          contact,
          conversationId,
          messageValues,
          setMessageValues,
          attachmentList,
          setAttachmentList,
          hasFocus,
          setHasFocus,
          clearMessageInputStates,
          contactPhoneNumbers,
          setContactPhoneNumbers,
          selectedInteraction,
          fetchingContactPhoneNumbers,
          inputAreaMode,
          setInputAreaMode,
          selectedChannelSlug,
          setSelectedChannelSlug,
        }}
      >
        {children}
      </ContactInteractionInputManagerContext.Provider>
    );
  },
);

import React, { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  Query,
  QuerySchedulesArgs,
  Schedule,
  ScheduleConnection,
  SchedulePriority,
  ScheduleWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  LggDropdownButtonWithoutOverlay,
  LggSelectableOptionsDropdownButton,
} from 'src/components/general/button/dropdown-button';
import { ExportResultsButton } from 'src/components/general/button/export-results-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { GraphqlTablePagination } from 'src/components/general/display/graphql-table-pagination';
import { InAppNotification } from 'src/components/general/display/in-app-notification';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import {
  TableBodyPlaceholder,
  TableLayoutPlaceholder,
} from 'src/components/general/feedback/loading-placeholders';
import {
  BasePageQueryParams,
  getSortDirectionByOrder,
  SorterData,
  tableLayoutViewButtonStyles,
  TableSortData,
} from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { TableLayoutPageContainer } from 'src/components/layout/table-layout-page-container';
import { PriorityOptionsDrawerStyle } from 'src/components/pages/appointments/components/appointment-priority-picker';
import {
  AppointmentsFilters,
  AppointmentsFiltersFormValues,
  defaultAppointmentsFilters,
} from 'src/components/pages/appointments/components/appointments-filters';
import { AppointmentsTable } from 'src/components/pages/appointments/components/appointments-table';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import {
  LEGACY_DATE_PARAM_FORMAT,
  useLegacyParamsForDashboardQuery,
  usePatchRawQueryParams,
  viewCodeQueryParamKey,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { usePushUrlWithViewCode } from 'src/hooks/use-push-url-with-view-code';
import { useVisible } from 'src/hooks/use-visible';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';
import { parseQueryParams } from 'src/utils/parse-query-params';

const GET_APPOINTMENTS_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  query GetSchedules(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $where: ScheduleWhereInput
    $orderBy: ScheduleOrderByInput
  ) {
    schedules(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          title
          priority
          status
          startAt
          isAllDay
          contact {
            id
            label
            interest
            primaryEmail
            primaryPhone {
              national
              e164
            }
            tags {
              id
              name
              isActive
            }
            status {
              id
              name
            }
            stage {
              id
              name
              slug
            }
          }
          user {
            id
            fullName
            avatar {
              initials
              color
            }
            role {
              id
              name
            }
          }
        }
      }
    }
    overdueAppointmentsCount: schedules(
      institutionId: $institutionId
      where: {
        status: {
          _in: [RESCHEDULED, SCHEDULED]
        },
        startAt: { _lte: "${new Date().toISOString()}" }
      }
    ) {
      totalCount
    }
  }
`;

type AppointmentsPageAdvancedQueryParams = Omit<
  AppointmentsFiltersFormValues,
  'agent' | 'group'
> & {
  agent?: string;
  group?: string;
};

type AppointmentsPageSortQueryParams = {
  table?: string;
};

export type AppointmentsPageQueryParams = BasePageQueryParams<
  'upcoming' | 'past' | 'overdue' | 'canceled' | 'no-show',
  AppointmentsPageAdvancedQueryParams
>;

enum AppointmentStatusResolver {
  RESCHEDULED = 'RESCHEDULED',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  'NO-SHOW' = 'NO_SHOW',
}

enum AppointmentsViewCodeFilter {
  Upcoming = 'upcoming',
  Past = 'past',
  Overdue = 'overdue',
  Canceled = 'canceled',
  No_Show = 'no-show',
  Custom = 'custom',
}

const APPOINTMENTS_QUERY_PAGE_SIZE = 50;

const HeaderContainer = styled(FlexColumn)`
  padding: 20px;

  ${up('md')} {
    padding: 0;
  }
`;

const HeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderBottomContainer = styled(FlexRow)`
  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderInnerContainer = styled(FlexRow)`
  & > *:not(:last-child) {
    margin-right: 10px;

    ${up('md')} {
      margin-right: 20px;
    }
  }
`;

const DesktopViewButton = styled(LggSelectableOptionsDropdownButton)`
  ${tableLayoutViewButtonStyles}
`;

const MobileViewButton = styled(LggDropdownButtonWithoutOverlay)`
  ${tableLayoutViewButtonStyles}
`;

const filtersStateResolver = (
  queryParams: AppointmentsPageQueryParams,
): Partial<AppointmentsFiltersFormValues> | undefined => {
  if (!queryParams.q?.advanced) {
    return undefined;
  }

  const { advanced } = queryParams.q;

  const values: Partial<AppointmentsFiltersFormValues> = {};

  if (advanced.date_from) {
    values.date_from = advanced.date_from;
  }

  if (advanced.date_to) {
    values.date_to = advanced.date_to;
  }

  if (advanced.title) {
    values.title = advanced.title;
  }

  if (advanced.status) {
    values.status = advanced.status;
  }

  if (advanced.priority) {
    values.priority = advanced.priority;
  }

  if (advanced.agent) {
    values.agent = parseInt(advanced.agent);
  }

  if (advanced.group) {
    values.group = parseInt(advanced.group);
  }

  return values;
};

const sortInputResolver = (sortData: TableSortData) => {
  const { key, direction = 'ASC' } = sortData;

  switch (key) {
    case 'contact': {
      return {
        contact: {
          firstName: direction,
        },
      };
    }
    case 'user': {
      return {
        user: {
          firstName: direction,
        },
      };
    }
    default: {
      return {
        [key]: direction,
      };
    }
  }
};

const useWhereInputResolver = (viewCode: AppointmentsPageQueryParams['view-code']) => {
  const { startOfDay, endOfDay, parseISO } = useDateHelpers();
  const nowDate = useMemo(
    () => new Date(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewCode],
  );

  return (params: AppointmentsPageQueryParams) => {
    const whereClauses: ScheduleWhereInput[] = [];

    if (params['view-code'] === 'upcoming' && params.q?.advanced) {
      const { advanced } = params.q;

      if (!advanced.status || !advanced.date_from) {
        return undefined;
      }

      whereClauses.push({
        startAt: {
          _gte: nowDate,
        },
        status: {
          _in: advanced.status.map((status) => AppointmentStatusResolver[status]),
        },
      });

      whereClauses.push({
        startAt: {
          _gte: startOfDay(parseISO(advanced.date_from)),
        },
        isAllDay: {
          _eq: true,
        },
        status: {
          _in: advanced.status.map((status) => AppointmentStatusResolver[status]),
        },
      });

      return { _or: whereClauses };
    }

    if (params['view-code'] === 'past' && params.q?.advanced) {
      const { advanced } = params.q;

      if (!advanced.date_to) {
        return undefined;
      }

      whereClauses.push({
        startAt: {
          _lt: nowDate,
        },
        isAllDay: {
          _eq: false,
        },
      });

      whereClauses.push({
        startAt: {
          _lt: startOfDay(parseISO(advanced.date_to)),
        },
        isAllDay: {
          _eq: true,
        },
      });

      return { _or: whereClauses };
    }

    if (params.q?.advanced) {
      const { advanced } = params.q;

      if (advanced.date_from) {
        whereClauses.push({
          startAt: {
            _gte: startOfDay(parseISO(advanced.date_from)),
          },
        });
      }

      if (advanced.date_to) {
        whereClauses.push({
          startAt: {
            _lte: endOfDay(parseISO(advanced.date_to)),
          },
        });
      }

      if (advanced.title) {
        whereClauses.push({
          title: {
            _contains: advanced.title,
          },
        });
      }

      if (advanced.status) {
        whereClauses.push({
          status: {
            _in: advanced.status.map((status) => AppointmentStatusResolver[status]),
          },
        });
      }

      if (advanced.priority) {
        whereClauses.push({
          priority: {
            _eq: advanced.priority as SchedulePriority,
          },
        });
      }

      if (advanced.agent) {
        whereClauses.push({
          user: {
            id: {
              _eq: parseInt(advanced.agent),
            },
          },
        });
      }

      if (advanced.group) {
        whereClauses.push({
          user: {
            groupRelation: {
              id: {
                _eq: parseInt(advanced.group),
              },
            },
          },
        });
      }
    }

    return whereClauses.length ? { _and: whereClauses } : undefined;
  };
};

const useViewCodeResolver = (viewCode: AppointmentsPageQueryParams['view-code']) => {
  const { formatDate } = useFormatDate();

  const nowDate = useMemo(
    () => new Date(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewCode],
  );
  const now = useMemo(
    () => formatDate(nowDate, LEGACY_DATE_PARAM_FORMAT, true),
    [formatDate, nowDate],
  );

  return (rawQueryParams: AppointmentsPageQueryParams): AppointmentsPageQueryParams => {
    const viewCode = rawQueryParams[viewCodeQueryParamKey];
    let advanceParams: AppointmentsPageAdvancedQueryParams = {};
    const sortingParams: AppointmentsPageSortQueryParams = {};

    switch (viewCode) {
      case 'upcoming': {
        advanceParams = {
          status: ['RESCHEDULED', 'SCHEDULED'],
          date_from: now,
        };
        sortingParams.table = 'sort|startAt|direction|ASC';
        break;
      }
      case 'past':
        advanceParams = {
          date_to: now,
        };
        sortingParams.table = 'sort|startAt|direction|DESC';

        break;
      case 'overdue': {
        advanceParams = {
          status: ['RESCHEDULED', 'SCHEDULED'],
          date_to: now,
        };
        sortingParams.table = 'sort|startAt|direction|DESC';
        break;
      }
      case 'canceled': {
        advanceParams = {
          status: ['CANCELLED'],
        };
        sortingParams.table = 'sort|startAt|direction|DESC';
        break;
      }
      case 'no-show': {
        advanceParams = {
          status: ['NO-SHOW'],
        };
        sortingParams.table = 'sort|startAt|direction|DESC';
        break;
      }
      case 'custom':
        break;
    }

    const params = {
      ...sortingParams,
      ...rawQueryParams,
    };

    if (Object.keys(advanceParams)) {
      return {
        ...params,
        q: {
          advanced: advanceParams,
        },
      };
    } else {
      return params;
    }
  };
};

export const AppointmentsPage = memo(() => {
  const { t } = useTranslation(['appointments', 'common']);
  useAddBreadcrumb(t('appointments:pageBreadcrumb'));
  const { id: institutionId } = useCurrentInstitution();
  const institutionUrl = useInstitutionUrl();
  const handleGraphQLError = useHandleGraphQLError();
  const rawQueryParams = usePatchRawQueryParams<
    AppointmentsPageAdvancedQueryParams,
    AppointmentsPageQueryParams['view-code']
  >({ defaultViewCode: 'upcoming' });
  const viewCode = rawQueryParams['view-code'];
  const viewCodeResolver = useViewCodeResolver(viewCode);
  const breakpointUpMd = useBreakpoint(up('md'));
  const viewCodeDropdownVisibilityHandler = useVisible();
  const filtersVisibilityHandler = useVisible();
  const location = useLocation();

  const { pushUrlWithViewCode } =
    usePushUrlWithViewCode<AppointmentsPageQueryParams['view-code']>();
  const whereInputResolver = useWhereInputResolver(viewCode);

  const {
    variables,
    sortData,
    hasActiveFilters,
    handleSortChange,
    handleNextPage,
    handlePreviousPage,
    filters,
    legacyFiltersQueryString,
  } = useLegacyParamsForDashboardQuery({
    pageSize: APPOINTMENTS_QUERY_PAGE_SIZE,
    defaultFilters: defaultAppointmentsFilters,
    dateFilterKeys: ['date_from', 'date_to'],
    defaultSortData: {
      key: 'startAt',
      direction: 'ASC',
    },
    rawQueryParams,
    viewCodeResolver,
    whereInputResolver,
    sortInputResolver,
    filtersStateResolver,
  });

  const {
    data: appointmentsData,
    loading: loadingAppointments,
    refetch: refetchAppointmentsData,
    error,
    networkStatus,
  } = useQuery<
    Pick<Query, 'schedules'> & { overdueAppointmentsCount: ScheduleConnection },
    Partial<QuerySchedulesArgs>
  >(GET_APPOINTMENTS_QUERY, {
    variables: {
      ...variables,
      institutionId,
    },
    onError: handleGraphQLError,
  });

  useEffect(() => {
    // This effect sets the sort order for the appointments in the url based on view-code
    // This keeps the sort order when filtering the list, since the view-code will change to "custom"
    const search = location.search;
    const queryParams = parseQueryParams(search);
    const sortParam = queryParams['table'];

    if (!sortParam) {
      const view = queryParams['view-code'];
      const sortDirection =
        Boolean(view) && view !== AppointmentsViewCodeFilter.Upcoming ? 'DESC' : 'ASC';

      handleSortChange({ columnKey: 'startAt', sortDirection });
    }
  }, [handleSortChange, location.search]);

  const appointmentsIds = getNodesFromConnection(appointmentsData?.schedules).map(
    (node) => node.id,
  );

  const onRefreshHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id, action } = params;

        if (action === 'create' || appointmentsIds?.includes(id)) {
          await refetchAppointmentsData();
        }
      },
    };
  }, [appointmentsIds, refetchAppointmentsData]);

  useRefreshAppointment(onRefreshHandler);

  const overdueAppointmentsCount =
    appointmentsData?.overdueAppointmentsCount.totalCount ?? 0;

  const selectedViewCodeDescription = useMemo(() => {
    let text: string;

    switch (viewCode) {
      case 'upcoming': {
        text = t('appointments:viewFilter.options.upcoming');
        break;
      }
      case 'past': {
        text = t('appointments:viewFilter.options.past');
        break;
      }
      case 'overdue': {
        text = t('appointments:viewFilter.options.overdue');
        break;
      }
      case 'no-show': {
        text = t('appointments:viewFilter.options.noShow');
        break;
      }
      case 'canceled': {
        text = t('appointments:viewFilter.options.canceled');
        break;
      }
      case 'custom': {
        text = t('common:custom');
        break;
      }
    }

    return text;
  }, [t, viewCode]);

  const isFetchingMore = networkStatus === NetworkStatus.setVariables;
  const isRefetching = networkStatus === NetworkStatus.refetch;

  if (loadingAppointments && !appointmentsData && !isRefetching) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  if (error) return <div>error</div>;
  if (!appointmentsData) return null;

  const {
    schedules: { totalCount, pageInfo },
  } = appointmentsData;
  const appointments = getNodesFromConnection(appointmentsData.schedules);

  const applyOverdueFilter = () => {
    pushUrlWithViewCode(AppointmentsViewCodeFilter.Overdue);
  };

  const viewOptions = [
    {
      label: t('appointments:viewFilter.options.upcoming'),
      'data-lgg-id': 'appointments-page-view-button-option-upcoming',
      value: AppointmentsViewCodeFilter.Upcoming,
      onClick: () => {
        pushUrlWithViewCode(AppointmentsViewCodeFilter.Upcoming);
      },
    },
    {
      label: t('appointments:viewFilter.options.past'),
      'data-lgg-id': 'appointments-page-view-button-option-past',
      value: AppointmentsViewCodeFilter.Past,
      onClick: () => {
        pushUrlWithViewCode(AppointmentsViewCodeFilter.Past);
      },
    },
    {
      label: t('appointments:viewFilter.options.overdue'),
      'data-lgg-id': 'appointments-page-view-button-option-overdue',
      value: AppointmentsViewCodeFilter.Overdue,
      onClick: applyOverdueFilter,
    },
    {
      label: t('appointments:viewFilter.options.canceled'),
      'data-lgg-id': 'appointments-page-view-button-option-canceled',
      value: AppointmentsViewCodeFilter.Canceled,
      onClick: () => {
        pushUrlWithViewCode(AppointmentsViewCodeFilter.Canceled);
      },
    },
    {
      label: t('appointments:viewFilter.options.noShow'),
      'data-lgg-id': 'appointments-page-view-button-option-no-show',
      value: AppointmentsViewCodeFilter.No_Show,
      onClick: () => {
        pushUrlWithViewCode(AppointmentsViewCodeFilter.No_Show);
      },
    },
  ];

  const hasNotifications = overdueAppointmentsCount > 0;
  const appointmentNotification =
    viewCode !== AppointmentsViewCodeFilter.Overdue && hasNotifications ? (
      <InAppNotification
        icon="schedule18"
        testId="appointments-page-overdue-notification"
        onClick={applyOverdueFilter}
        title={t(
          `appointments:overdueNotification.${breakpointUpMd ? 'titleShort' : 'title'}`,
          {
            appointmentsCount: overdueAppointmentsCount.toLocaleString(),
          },
        )}
        variant="error"
      />
    ) : null;

  const viewButton = breakpointUpMd ? (
    <DesktopViewButton
      visibilityHandler={viewCodeDropdownVisibilityHandler}
      data-lgg-id="appointments-page-view-button"
      selectedValue={viewCode}
      customDropdownProps={{
        overlayStyle: {
          minWidth: '141px',
        },
      }}
      options={viewOptions}
      variant="defaultWhite"
      size="regular"
    >
      {selectedViewCodeDescription}
    </DesktopViewButton>
  ) : (
    <>
      <MobileViewButton
        data-lgg-id="appointments-page-view-button"
        variant="defaultWhite"
        size="regular"
        onClick={() =>
          viewCodeDropdownVisibilityHandler.setVisible(
            !viewCodeDropdownVisibilityHandler.visible,
          )
        }
        isActive={viewCodeDropdownVisibilityHandler.visible}
      >
        {selectedViewCodeDescription}
      </MobileViewButton>
      <SelectableOptionsDrawer
        title={selectedViewCodeDescription}
        options={viewOptions}
        selectedValue={viewCode}
        onClose={viewCodeDropdownVisibilityHandler.close}
        visible={viewCodeDropdownVisibilityHandler.visible}
      />
    </>
  );

  return (
    <TableLayoutPageContainer data-lgg-id="appointments-page-container">
      <HeaderContainer>
        <HeaderTopContainer>
          <HeaderInnerContainer>
            {viewButton}
            {breakpointUpMd && appointmentNotification}
          </HeaderInnerContainer>
          <HeaderInnerContainer>
            <FiltersButton
              filtersVisibilityHandler={filtersVisibilityHandler}
              hasActiveFilters={hasActiveFilters}
              data-lgg-id="appointments-page-filters-button"
            />
            <ExportResultsButton
              testId="appointments-page-export"
              requestUrl={`${institutionUrl}cms/appointments/`}
              params={legacyFiltersQueryString}
              hideShareOption
            />
          </HeaderInnerContainer>
        </HeaderTopContainer>
        <HeaderBottomContainer>
          <PaginationInfo
            total={totalCount}
            entityName={t('appointments:appointment', {
              count: totalCount,
            })}
            testId="appointments-page-pagination"
          />
        </HeaderBottomContainer>
      </HeaderContainer>
      {isFetchingMore ? (
        <TableBodyPlaceholder />
      ) : (
        <>
          {!breakpointUpMd && appointmentNotification}
          <AppointmentsTable
            appointments={appointments}
            refetchQueriesHandler={async () => {
              await refetchAppointmentsData();
            }}
            sortData={sortData}
            onChange={(pagination, filters, sorter) => {
              const { columnKey, order } = sorter as SorterData<Schedule>;
              const sortDirection = getSortDirectionByOrder(order);
              handleSortChange({ columnKey, sortDirection });
            }}
          />
          <PriorityOptionsDrawerStyle />
          <GraphqlTablePagination
            previousPageHandler={handlePreviousPage}
            nextPageHandler={handleNextPage}
            pageInfo={pageInfo}
          />
          <AppointmentsFilters
            visible={filtersVisibilityHandler.visible}
            onClose={filtersVisibilityHandler.close}
            filters={filters}
          />
        </>
      )}
    </TableLayoutPageContainer>
  );
});

import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { Query, QueryContactArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { NotificationContext } from 'src/components/general/feedback/hooks/use-show-notification';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactActionIcons } from 'src/components/pages/contacts/components/contact-action-icons';
import { GET_CONTACT } from 'src/hooks/gql/use-refresh-contact';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useUrls } from 'src/hooks/use-urls';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

// Shows "Entity created" snackbar after confirmation from the backend that a Contact, Task, or Appointment was added.
export const useEntityCreatedNotification = () => {
  const { getContactModalUrl, getTaskModalUrl, getScheduleModalUrl } = useUrls();
  const { openNotification } = useContext(NotificationContext);
  const { t } = useTranslation(['header']);
  const [getContact] = useLazyQuery<Pick<Query, 'contact'>, QueryContactArgs>(
    GET_CONTACT,
  );

  const onCreateContactHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { action, id, entityFullName } = params;
        const createdContact = await getContact({ variables: { id } });
        const contactPhone = createdContact.data?.contact.primaryPhone?.national;

        if (action === 'create') {
          openNotification({
            title: t('header:notifications.titles.contact'),
            message: entityFullName,
            type: 'success',
            actionElements: (
              <>
                <StyledLink
                  to={getContactModalUrl(id)}
                  data-lgg-id="view-contact-notification-action"
                >
                  {t('header:notifications.actions.viewContact')}
                </StyledLink>
                <FlexRow>
                  <ContactActionIcons
                    contactId={id}
                    iconSize={15}
                    contactPhone={contactPhone}
                    iconColorOverride="gogo"
                  />
                </FlexRow>
              </>
            ),
          });
        }
      },
    };
  }, [getContact, openNotification, t, getContactModalUrl]);

  const onCreateTaskHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { action, id, entityTitle } = params;

        if (action === 'create') {
          openNotification({
            title: t('header:notifications.titles.task'),
            message: entityTitle,
            type: 'success',
            actionElements: (
              <StyledLink
                to={getTaskModalUrl(id)}
                data-lgg-id="view-task-notification-action"
              >
                {t('header:notifications.actions.viewTask')}
              </StyledLink>
            ),
          });
        }
      },
    };
  }, [openNotification, getTaskModalUrl, t]);

  const onCreateAppointmentHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { action, id, entityTitle } = params;

        if (action === 'create') {
          openNotification({
            title: t('header:notifications.titles.appointment'),
            message: entityTitle,
            type: 'success',
            actionElements: (
              <StyledLink
                to={getScheduleModalUrl(id)}
                data-lgg-id="view-appointment-notification-action"
              >
                {t('header:notifications.actions.viewAppointment')}
              </StyledLink>
            ),
          });
        }
      },
    };
  }, [openNotification, getScheduleModalUrl, t]);

  return {
    onCreateContactHandler,
    onCreateTaskHandler,
    onCreateAppointmentHandler,
  };
};

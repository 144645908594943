import { gql } from '@apollo/client';

const USER_FIELDS = gql`
  fragment UserFieldsFragment on User {
    id
    firstName
    lastName
    avatar {
      initials
      avatarUrl
    }
    email
    primaryPhone {
      e164
    }
    phoneExt
    alternativePhone {
      e164
    }
    phone2Ext
    isFacebookLinked
  }
`;

export const GET_USER_QUERY = gql`
  ${USER_FIELDS}
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFieldsFragment
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  ${USER_FIELDS}
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      ...UserFieldsFragment
    }
  }
`;

export const UPDATE_PROFILE_PHOTO_MUTATION = gql`
  mutation UpdateProfilePhoto($input: UserPhotoUpdateInput!) {
    updateProfilePhoto(input: $input) {
      id
      avatar {
        initials
        avatarUrl
      }
    }
  }
`;

export const DELETE_PROFILE_PHOTO_MUTATION = gql`
  mutation DeleteProfilePhoto($input: UserDeletePhotoInput!) {
    deleteProfilePhoto(input: $input) {
      id
      avatar {
        initials
        avatarUrl
      }
    }
  }
`;

export const UPDATE_LANGUAGE_PREFERENCE_MUTATION = gql`
  mutation UpdateLanguagePreference($input: UserLanguagePreferenceUpdateInput!) {
    updateLanguagePreference(input: $input) {
      id
      language
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UserPasswordUpdateInput!) {
    updatePassword(input: $input) {
      success
    }
  }
`;

export const GENERATE_PRESIGNED_URL = gql`
  mutation GeneratePresignedUrl($input: GeneratePresignedUrlInput!) {
    userGeneratePresignedUrl(input: $input) {
      url
      fields {
        key
        value
      }
    }
  }
`;

export const DEAUTHORIZE_FACEBOOK_MUTATION = gql`
  mutation DeauthorizeFacebook {
    deauthorizeFacebook {
      id
      isFacebookLinked
    }
  }
`;

export const slideDownAnimation = `
  position: relative;

  @keyframes slide-down {
    from {
      opacity: 0;
      bottom: 20px;
    }
    to {
      opacity: 1;
      bottom: 0;
    }
  }

  animation: slide-down 0.2s linear;
`;

export const slideUpAnimation = `
  position: relative;
  
  @keyframes slide-up {
    from {
      opacity: 0;
      top: 20px;
    }
    to {
      opacity: 1;
      top: 0;
    }
  }

  animation: slide-up 0.2s linear;
`;

export const slideLeftAnimation = `
  position: relative;

  @keyframes slide-left {
    from {
      opacity: 0;
      right: -20px;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }

  animation: slide-left 0.2s linear;
`;

export const fadeInAnimation = `
  @keyframes fade-in-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fade-in-animation 0.2s linear;`;

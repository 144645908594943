import React, { memo } from 'react';
import styled from 'styled-components';
import { TextInput, TextInputProps } from 'src/components/general/inputs/text-input';

export const AccountTextInputContainer = styled.div`
  margin: 1px 0;

  & + & {
    margin-top: 3px;
  }
`;

export const AccountTextInput = memo<TextInputProps>((props) => (
  <AccountTextInputContainer>
    <TextInput {...props} />
  </AccountTextInputContainer>
));

import React, { memo } from 'react';
import { HollowLayout } from 'src/components/layout/hollow-layout';
import { ChangePasswordForm } from 'src/components/pages/account/change-password/components/change-password-form';

export const ChangePasswordPage = memo(() => {
  return (
    <HollowLayout>
      <ChangePasswordForm />
    </HollowLayout>
  );
});

import React, { memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { match, Pattern } from 'ts-pattern';
import {
  Contact,
  Query,
  QueryConversationArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ModalsZIndex } from 'src/components/constants';
import { ContactAddNewDrawer } from 'src/components/domain/contacts/contact-add-new/contact-add-new';
import {
  ContactModalOpenLegacyCallModalIcon,
  ContactModalTabs,
  ContactModalViewProps,
  ContactOptionsDisplayProps,
  MoreOptionsIcon,
  tabsKeyMapping,
  useContactOptions,
} from 'src/components/domain/contacts/contact-modal/contact-modal.shared';
import { ContactModalLoadingView } from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { showContactStatusModal } from 'src/components/domain/contacts/contact-status-modal';
import { LggUserAvatar } from 'src/components/general/display/user-avatar';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { BottomDrawerItemsRenderer } from 'src/components/general/drawer/bottom/bottom-drawer-item-renderer';
import { OptionsBottomDrawer } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BlockContactModal,
  useContactBlock,
} from 'src/components/pages/contacts/components/contact-block';
import { LegacyContactTagsSelector } from 'src/components/pages/conversations/components/contact-information/legacy-contact-tags-selector';
import { useGetConversationStateDrawerOptions } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-modal';
import { ContactActionsIcon } from 'src/components/pages/conversations/components/contact-interactions/shared';
import { SnoozeConversationModal } from 'src/components/pages/conversations/components/conversations/snooze-conversation-modal/snooze-conversation-modal';
import { DrawerHeaderBackIcon } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useConversationSetClosed } from 'src/components/pages/conversations/hooks/use-conversation-set-closed';
import { useConversationSetMarked } from 'src/components/pages/conversations/hooks/use-conversation-set-marked';
import { useConversationUnsnooze } from 'src/components/pages/conversations/hooks/use-conversation-unsnooze';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const ConversationOptionsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.porcelain};
  margin-top: 10px;
  padding-top: 20px;
`;

const ConversationOptionsTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 15px;
  letter-spacing: -0.3px;
  line-height: 18px;
  margin: 0 20px 15px;
`;

const CONVERSATION_FOR_OPTIONS_QUERY = gql`
  query GetConversation($id: String!) {
    conversation(id: $id) {
      id
      isMarked
      isSnoozed
      isOpen
      isStarted
    }
  }
`;

const ContactOptionsDrawer = memo<
  ContactOptionsDisplayProps & { conversationId?: string }
>(({ visibilityHandler, contact, conversationId }) => {
  const { t } = useTranslation(['contacts']);
  const {
    visible: snoozeConversationModalVisible,
    close: closeSnoozeConversationModal,
    show: showSnoozeConversationModal,
  } = useVisible();
  const conversationSetClosed = useConversationSetClosed();
  const conversationSetMarked = useConversationSetMarked();
  const { changeBlockStatus } = useContactBlock({ contact });
  const blockContactModalVisibility = useVisible();

  const contactOptions = useContactOptions({
    contact,
    onBlockContactOptionClicked: async () => {
      await changeBlockStatus(blockContactModalVisibility);
    },
  });

  const { unsnoozeConversation } = useConversationUnsnooze();
  const params = useParams<{
    tab?: string;
    contactInteractionId?: string;
    conversationId?: string;
  }>();

  const atConversationsTab = useMemo(() => params.tab === 'conversations', [params.tab]);

  const handleGraphQLError = useHandleGraphQLError();

  const { data: conversationQueryData } = useQuery<
    Pick<Query, 'conversation'>,
    QueryConversationArgs
  >(CONVERSATION_FOR_OPTIONS_QUERY, {
    fetchPolicy: 'cache-only',
    skip: !atConversationsTab || !conversationId,
    variables: conversationId
      ? {
          id: conversationId,
        }
      : undefined,
    onError: handleGraphQLError,
  });

  const conversationOptions = useGetConversationStateDrawerOptions({
    conversation: conversationQueryData?.conversation ?? undefined,
    setClosedClickHandler: async (id, isOpen) => {
      await conversationSetClosed({
        conversationId: id,
        isClosed: isOpen,
      });
    },
    setMarkedClickHandler: (id, isMarked) => {
      void conversationSetMarked({
        conversationId: id,
        isMarked: !isMarked,
      });
    },
    setUnsnoozeClickHandler: async (id, isSnoozed) => {
      if (isSnoozed) {
        await unsnoozeConversation({
          variables: {
            conversationId: id,
          },
        });
      } else {
        void showSnoozeConversationModal();
      }
    },
  });

  if (!contactOptions) {
    return null;
  }

  return (
    <>
      <OptionsBottomDrawer
        title={t('contacts:contactActionOptions.title')}
        onClose={visibilityHandler.close}
        visible={visibilityHandler.visible}
        options={contactOptions}
        bottomItem={
          conversationQueryData?.conversation?.isStarted && conversationOptions ? (
            <ConversationOptionsContainer>
              <ConversationOptionsTitle>{`${t(
                'contacts:contactActionOptions.subTitles.conversation',
              )}:`}</ConversationOptionsTitle>
              <BottomDrawerItemsRenderer
                options={conversationOptions}
                onClose={visibilityHandler.close}
                selectedValue={null}
              />
            </ConversationOptionsContainer>
          ) : undefined
        }
      />
      <SnoozeConversationModal
        zIndex={ModalsZIndex}
        visible={snoozeConversationModalVisible}
        onClose={closeSnoozeConversationModal}
        conversationId={conversationId}
      />
      <BlockContactModal
        visibilityHandler={blockContactModalVisibility}
        contact={contact}
        zIndex={ModalsZIndex}
      />
    </>
  );
});

const StyledContactActionsIcon = styled(ContactActionsIcon)`
  margin-left: 10px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ContactDetailLabel = styled.span`
  color: ${({ theme }) => theme.colors.raven};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  text-align: left;
`;

const ContactDetailValue = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: right;
  flex: 1;
`;

const ContactDetailCtaLabel = styled(ContactDetailValue)`
  color: ${({ theme }) => theme.colors.gogo};
`;

const StyledContactDetailsHomeView = styled(FlexColumn)`
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContactDetailRow = styled(FlexRow)`
  min-height: 48px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.porcelain};
  }

  .select-wrapper {
    width: unset;
  }
`;

const ContactStage = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.steel};
  text-transform: uppercase;
`;

const ContactStatus = styled(ContactStage)`
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
  text-transform: none;
`;

const StageColumn = styled(FlexColumn)`
  justify-content: center;
`;

const StyledStageIcon = styled(ContactStageIcon)`
  height: 24px;
  width: 24px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const AssigneeRow = styled(FlexRow)`
  justify-content: center;
  align-items: center;
`;

const StyledUserAvatar = styled(LggUserAvatar)`
  margin-right: 10px;
  height: 24px;
  width: 24px;
`;

const ViewAllDetailsRow = styled(FlexRow)`
  justify-content: space-between;
  height: 38px;
  align-items: center;
  padding-right: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.porcelain};
`;

const ViewAllDetailsLabel = styled(ContactDetailLabel)`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
`;

const StyledLegacyTagsSelector = styled(LegacyContactTagsSelector)`
  .lgg-select__control {
    border: unset;
    padding: 10px 0;
  }

  .lgg-select__value-container {
    justify-content: flex-end;
  }

  .lgg-select__placeholder {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

type ContactDetailsMobileProps = {
  contact: Contact;
  display?: 'brief' | 'full';
};

export const ContactDetailsMobile = memo<ContactDetailsMobileProps>(
  ({ contact, display = 'brief' }) => {
    const { t } = useTranslation(['contacts', 'common']);
    const { getFeatureFlag } = useAuthorization();
    const { getLegacyContactInterestModalUrl, getLegacyContactAssigneeModalUrl } =
      useUrls();
    const {
      interest,
      assignee,
      primaryPhone,
      primaryEmail,
      tags,
      fullAddress,
      id: contactId,
    } = contact;

    return (
      <>
        {getFeatureFlag('crm') && (
          <ContactDetailRow onClick={() => showContactStatusModal(contactId)}>
            <ContactDetailLabel>{t('contacts:information.status')}</ContactDetailLabel>
            <FlexRow>
              <StyledStageIcon slug={contact.stage.slug} name={contact.status.name} />
              <StageColumn>
                <ContactStage>{contact.stage.slug}</ContactStage>
                <ContactStatus>{contact.status.name}</ContactStatus>
              </StageColumn>
            </FlexRow>
          </ContactDetailRow>
        )}
        {getFeatureFlag('crm') && (
          <ContactDetailRow
            onClick={() =>
              openRemoteModalWrapper(getLegacyContactInterestModalUrl(contactId))
            }
          >
            <ContactDetailLabel>{t('contacts:information.interest')}</ContactDetailLabel>
            {interest ? (
              <ContactDetailValue>{interest}</ContactDetailValue>
            ) : (
              <ContactDetailCtaLabel>{t('common:add')}</ContactDetailCtaLabel>
            )}
          </ContactDetailRow>
        )}
        <ContactDetailRow
          onClick={() =>
            openRemoteModalWrapper(getLegacyContactAssigneeModalUrl(contactId))
          }
        >
          <ContactDetailLabel>{t('contacts:information.assignedTo')}</ContactDetailLabel>
          {assignee ? (
            <AssigneeRow>
              <StyledUserAvatar user={assignee} />
              <ContactDetailValue>{assignee.fullName}</ContactDetailValue>
            </AssigneeRow>
          ) : (
            <ContactDetailCtaLabel>
              {t('contacts:information.assign')}
            </ContactDetailCtaLabel>
          )}
        </ContactDetailRow>
        {display === 'full' && (
          <>
            {primaryPhone && (
              <ContactDetailRow>
                <ContactDetailLabel>
                  {t('contacts:information.phoneNumber')}
                </ContactDetailLabel>
                <ContactDetailValue>{primaryPhone.national}</ContactDetailValue>
              </ContactDetailRow>
            )}
            {primaryEmail && (
              <ContactDetailRow>
                <ContactDetailLabel>{t('contacts:information.email')}</ContactDetailLabel>
                <ContactDetailValue>{primaryEmail}</ContactDetailValue>
              </ContactDetailRow>
            )}
            {fullAddress && (
              <ContactDetailRow>
                <ContactDetailLabel>
                  {t('contacts:information.address')}
                </ContactDetailLabel>
                <ContactDetailValue>{fullAddress}</ContactDetailValue>
              </ContactDetailRow>
            )}
            <ContactDetailRow>
              <ContactDetailLabel>{t('contacts:information.tags')}</ContactDetailLabel>
              <StyledLegacyTagsSelector selectedTags={tags} contactId={contactId} />
            </ContactDetailRow>
          </>
        )}
      </>
    );
  },
);

type ContactDetailsHomeViewProps = {
  contact: Contact;
};

export const ContactDetailsHomeView = memo<ContactDetailsHomeViewProps>(({ contact }) => {
  const { t } = useTranslation(['contacts']);
  const history = useHistory();
  const { getContactModalUrl } = useUrls();

  return (
    <StyledContactDetailsHomeView>
      <ContactDetailsMobile contact={contact} />
      <ViewAllDetailsRow
        onClick={() => history.replace(getContactModalUrl(contact.id, 'contact-details'))}
      >
        <ViewAllDetailsLabel>
          {t('contacts:information.viewAllDetails')}
        </ViewAllDetailsLabel>
        <Icon type="arrowRightWide" />
      </ViewAllDetailsRow>
    </StyledContactDetailsHomeView>
  );
});

const TabsContainer = styled.div`
  height: 100%;
`;

type MobileViewProps = ContactModalViewProps;

export const ContactModalMobileView = memo<MobileViewProps>(
  ({ contact, onClose, loading }) => {
    const addNewDrawerVisibility = useVisible();
    const moreOptionsDrawerVisibility = useVisible();
    const tabsRef = useRef<HTMLDivElement | null>(null);
    const { blockedIndicator } = useContactBlock({ contact });
    const previousTabKey = useRef(1);
    const handleAutoScroll = (textKey: string) => {
      const key = tabsKeyMapping[textKey];

      if (!key && !tabsRef.current) {
        return;
      }

      const tabList = tabsRef.current?.querySelector(
        '.ant-tabs-nav-list',
      ) as HTMLDivElement;

      if (!tabList) {
        return;
      }

      const translateRegex = /translate\(-?(\d*)px, \d*px\)/;
      const matches = tabList.style.transform.match(translateRegex);

      if (!matches) {
        return;
      }

      const tabWrapWidth = tabList.parentElement?.clientWidth ?? 0;
      const tabListWidth = tabList.clientWidth;
      const maxOffset = tabListWidth - tabWrapWidth;

      const tabNavs = tabList.querySelectorAll('.ant-tabs-tab');
      const tabsOffset = [...tabNavs].map(
        (e) => (e as HTMLDivElement).offsetLeft + (e as HTMLDivElement).clientWidth,
      );

      const setOffset = (offset: number) => {
        setTimeout(() => {
          tabList.style.transform = `translate(${offset}px, 0px)`;
        }, 300);
        previousTabKey.current = key;
      };

      const isLeadingTab = key <= 2;
      const isTrailingTab = key >= tabNavs.length - 1;

      // If the new tab is in the first two spots, scroll all the way to the left
      if (isLeadingTab) {
        setOffset(0);
        return;
      }

      // If the new tab is in the last two spots, scroll all the way to the right
      if (isTrailingTab) {
        setOffset(-maxOffset);
        return;
      }

      const currentTabOffset = tabsOffset[key - 1];

      let newOffset;

      // If the current item offset is greater than the visible space, subtract the visible space to get the offset
      // otherwise, subtract the previous tab offset to get the offset
      if (currentTabOffset > tabWrapWidth) {
        newOffset = Math.abs(currentTabOffset - tabWrapWidth);
      } else {
        newOffset = Math.max(currentTabOffset - (tabsOffset[key - 2] ?? 0), 0);
      }

      // Add extra space depending on if the user is going to the left or right
      if (previousTabKey.current < key) {
        newOffset = newOffset + 30;
      } else {
        newOffset = newOffset - 30;
      }

      newOffset = -Math.min(newOffset, maxOffset);

      setOffset(newOffset);
    };

    return (
      <>
        <BottomDrawer
          fullHeight={true}
          visible={true}
          title={contact?.label ?? ''}
          subTitle={blockedIndicator}
          onClose={onClose}
          showCloseIcon={false}
          addScrollbarToBody={false}
          trailing={
            contact && (
              <FlexRow>
                {contact.primaryPhone && (
                  <ContactModalOpenLegacyCallModalIcon
                    contact={contact}
                    data-lgg-id="contact-modal-header-phone-call"
                  />
                )}
                <StyledContactActionsIcon
                  type="contactActions"
                  onClick={addNewDrawerVisibility.show}
                />
                <MoreOptionsIcon
                  type="moreOptionsWithPadding"
                  onClick={moreOptionsDrawerVisibility.show}
                />
              </FlexRow>
            )
          }
          leading={<DrawerHeaderBackIcon onClick={onClose} />}
        >
          <TabsContainer ref={tabsRef}>
            {match({ contact, loading })
              .with({ contact: Pattern.not(Pattern.nullish) }, ({ contact }) => (
                <ContactModalTabs contact={contact} onChange={handleAutoScroll} />
              ))
              .with({ loading: true }, () => <ContactModalLoadingView />)
              .otherwise(() => null)}
          </TabsContainer>
        </BottomDrawer>
        {contact && (
          <>
            <ContactAddNewDrawer
              contactId={contact.id}
              visible={addNewDrawerVisibility.visible}
              onClose={addNewDrawerVisibility.close}
            />
            <ContactOptionsDrawer
              contact={contact}
              visibilityHandler={moreOptionsDrawerVisibility}
              conversationId={contact.lastConversation.id}
            />
          </>
        )}
      </>
    );
  },
);

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

export const useFormatLastInteractionDate = () => {
  const { t } = useTranslation(['conversations']);
  const { formatDate } = useFormatDate();
  const { differenceInDays, isToday, isYesterday } = useDateHelpers();

  const getRelativeDate = useCallback(
    (dateString: string) => {
      const date = new Date(dateString);
      const diffInDays = differenceInDays(new Date(), date);
      let relativeDate: string;

      if (isToday(date)) {
        relativeDate = t('conversations:lastInteractionDate.today');
      } else if (isYesterday(date)) {
        relativeDate = t('conversations:lastInteractionDate.yesterday');
      } else if (diffInDays < 7) {
        relativeDate = t('conversations:lastInteractionDate.daysAgo', {
          days: diffInDays,
        });
      } else if (diffInDays > 12) {
        relativeDate = formatDate(date, 'MM/DD/YY');
      } else {
        relativeDate = t('conversations:lastInteractionDate.oneWeekAgo');
      }

      return relativeDate;
    },
    [differenceInDays, formatDate, isToday, isYesterday, t],
  );

  const formatLastInteractionDateWithTime = useCallback(
    (dateString: string, dateFormat: string = 'hh:mm A') => {
      const relativeDate = getRelativeDate(dateString);
      const formattedTime = formatDate(dateString, dateFormat);

      return `${relativeDate}, ${formattedTime}`;
    },
    [formatDate, getRelativeDate],
  );

  const formatLastInteractionDate = useCallback(
    (dateString: string) => {
      const suffix = getRelativeDate(dateString);

      return `${t('conversations:lastInteractionDate.lastInteraction')} ${suffix}`;
    },
    [t, getRelativeDate],
  );

  return {
    formatLastInteractionDate,
    formatLastInteractionDateWithTime,
  };
};

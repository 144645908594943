import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { ConfirmationModal } from 'src/components/general/modals/confirmation-modal';
import { Center } from 'src/components/layout/center';
import { FlexRow } from 'src/components/layout/flex-row';
import { useNotificationPermission } from 'src/hooks/push-notifications/use-notification-permission';
import { usePushNotificationSnoozedUntil } from 'src/hooks/push-notifications/use-push-notification-snoozed-until';
import { useVisible } from 'src/hooks/use-visible';

const PushNotificationBannerIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: ${({ theme }) => theme.colors.secondaryGoldDark};
    }
  }
`;

const PushNotificationPermissionDeniedBannerContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryGold10};
  border-bottom: ${({ theme }) => theme.colors.secondaryGold30} 1px solid;
  height: 38px;
  padding: 0 15px 0 20px;
`;

const ArrowIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const MessageContainer = styled.div`
  color: ${({ theme }) => theme.colors.storm};
  flex: 1;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;

  span.cta {
    font-family: ${({ theme }) => theme.font.bold};
    text-decoration: underline;
  }
`;

const IconContainer = styled(Center)`
  background-color: ${({ theme }) => theme.colors.secondaryGold20};
  border-radius: 50%;
  height: 70px;
  width: 70px;
`;

const StyledIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.secondaryGoldDark};
  }
`;

type PushNotificationPermissionDeniedBannerProps = {
  openSettings: VoidFunction;
};

const PushNotificationPermissionDeniedBanner =
  memo<PushNotificationPermissionDeniedBannerProps>(({ openSettings }) => {
    const { visible, show, close } = useVisible();
    const { t } = useTranslation(['notifications']);

    return (
      <>
        <PushNotificationPermissionDeniedBannerContainer>
          <PushNotificationBannerIcon type="notification" />
          <MessageContainer>
            <Trans
              i18nKey="notifications:pushNotifications.permissionOff"
              components={[<span className="cta" onClick={show} />]}
            />
          </MessageContainer>
          <ArrowIcon type="arrowNextNoPadding" />
        </PushNotificationPermissionDeniedBannerContainer>
        <ConfirmationModal
          visible={visible}
          onClose={close}
          title={t('notifications:pushNotifications.turnOnNotificationsModal.title')}
          message={t('notifications:pushNotifications.turnOnNotificationsModal.message')}
          icon={
            <IconContainer>
              <StyledIcon type="notification" />
            </IconContainer>
          }
          buttons={{
            ok: {
              text: t(
                'notifications:pushNotifications.turnOnNotificationsModal.buttons.ok.action',
              ),
              onClick: openSettings,
            },
            cancel: {
              text: t(
                'notifications:pushNotifications.turnOnNotificationsModal.buttons.cancel.action',
              ),
            },
          }}
        />
      </>
    );
  });

const PushNotificationSnoozedBannerContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryGold10};
  border-bottom: ${({ theme }) => theme.colors.secondaryGold30} 1px solid;
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 38px;
  letter-spacing: normal;
  line-height: normal;
  padding: 0 20px;
  text-align: left;
  white-space: pre-wrap;

  span {
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

type PushNotificationSnoozedBannerProps = {
  showDndBottomDrawer: VoidFunction;
};

const PushNotificationSnoozedBanner = memo<PushNotificationSnoozedBannerProps>(
  ({ showDndBottomDrawer }) => {
    const snoozedUntil = usePushNotificationSnoozedUntil();

    if (!snoozedUntil) {
      return null;
    }

    return (
      <PushNotificationSnoozedBannerContainer onClick={showDndBottomDrawer}>
        <PushNotificationBannerIcon type="notificationMuted" />
        <Trans
          i18nKey="notifications:globalOptions.doNotDisturb.bannerWithFormat"
          values={{
            date: snoozedUntil,
          }}
          components={{ strong: <span /> }}
        />
      </PushNotificationSnoozedBannerContainer>
    );
  },
);

export const PushNotificationBanner = memo<PushNotificationSnoozedBannerProps>(
  ({ showDndBottomDrawer }) => {
    const { permissionState, openSettings } = useNotificationPermission();
    const hasPushNotificationPermission =
      permissionState === null || permissionState === 'granted';

    if (!hasPushNotificationPermission) {
      return <PushNotificationPermissionDeniedBanner openSettings={openSettings} />;
    }

    // Only show the snoozed banner if the user has push notification permission
    return <PushNotificationSnoozedBanner showDndBottomDrawer={showDndBottomDrawer} />;
  },
);

import React from 'react';
import { DecoratorNode, LexicalNode, NodeKey, SerializedTextNode } from 'lexical';
import { TextFormatter } from 'src/components/general/text-formatter';

export class TextNodeResolver extends DecoratorNode<JSX.Element> {
  __text: string;

  static getType(): string {
    return 'text';
  }

  static clone(node: TextNodeResolver): TextNodeResolver {
    return new TextNodeResolver(node.__text);
  }

  constructor(text: string, key?: NodeKey) {
    super(key);

    this.__text = text;
  }

  createDOM(): HTMLElement {
    const container = document.createElement('span');

    container.setAttribute('data-lgg-id', 'resolved-text-node');

    return container;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return <TextFormatter>{this.__text}</TextFormatter>;
  }

  static importJSON(textNode: SerializedTextNode): LexicalNode {
    return new TextNodeResolver(textNode.text);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      text: this.__text,
    };
  }
}

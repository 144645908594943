import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const ApplyButton = styled(LggButton)`
  font-size: 14px;
`;

export const Footer = (props: { onSave: () => void; onReset: () => void }) => {
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <Row gutter={20}>
      {!breakpointUpMd && (
        <Col span={12}>
          <LggButton onClick={props.onReset} variant="tertiary">
            <span>{t('common:filters.actionReset')}</span>
          </LggButton>
        </Col>
      )}
      <Col span={breakpointUpMd ? 24 : 12}>
        <ApplyButton onClick={props.onSave}>
          <span>
            {t(
              breakpointUpMd
                ? 'common:filters.actionsApplyFilters'
                : 'common:filters.actionsApply',
            )}
          </span>
        </ApplyButton>
      </Col>
    </Row>
  );
};

import { useMemo } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export function useCalculatedUISizes() {
  const breakpointUpMd = useBreakpoint(up('md'));

  return useMemo(() => {
    const sidebarWidth = 242;
    const sidebarCollapsedWidth = breakpointUpMd ? 70 : 0;
    const contentWithSidebarWidth = window.innerWidth - sidebarWidth;
    const contentWithSidebarCollapsedWidth = window.innerWidth - sidebarCollapsedWidth;
    const contentWithoutSidebarWidth = window.innerWidth;

    return {
      sidebarSize: { width: sidebarWidth },
      sidebarCollapsedSize: { width: sidebarCollapsedWidth },
      contentWithSidebarSize: { width: contentWithSidebarWidth },
      contentWithSidebarCollapsedSize: { width: contentWithSidebarCollapsedWidth },
      contentWithoutSidebarSize: { width: contentWithoutSidebarWidth },
    };
  }, [breakpointUpMd]);
}

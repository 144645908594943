import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import {
  Query,
  QueryResourceWhatsappArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { TableLayoutPlaceholder } from 'src/components/general/feedback/loading-placeholders';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  PageContainer,
  TableLayoutPageContainer,
} from 'src/components/layout/page-containers';
import {
  ExternalWhatsappLinkButton,
  WhatsappSyncButton,
} from 'src/components/pages/whatsapp-channel/components/shared';
import { useHandleSyncWhatsappTemplates } from 'src/components/pages/whatsapp-channel/shared/hooks';
import { RESOURCE_WHATSAPP_DETAIL_QUERY } from 'src/components/pages/whatsapp-channel/shared/queries';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const PageHeader = styled(FlexRow)`
  height: 108px;
  padding: 30px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  justify-content: space-between;
`;

const PageBody = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  min-height: 600px;
`;

const HeaderWhatsappIcon = styled(Icon)`
  margin-right: 15px;

  svg {
    height: 48px;
    width: 48px;
  }
`;

const ChannelInfoContainer = styled(FlexColumn)`
  justify-content: center;
`;

const ChannelLabel = styled.span`
  line-height: 19px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.smalt};
`;

const ChannelNumber = styled.span`
  line-height: 17px;
  margin: 5px 0 0;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.flint};
`;

const HeaderLeftContent = styled(FlexRow)`
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0;
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0 30px;
    margin: 0;

    &::before {
      border-bottom: none;
    }

    .ant-tabs-ink-bar {
      background-color: ${({ theme }) => theme.colors.gogo};
      height: 2px;
    }

    .ant-tabs-tab {
      color: ${({ theme }) => theme.colors.flint};
      font-family: ${({ theme }) => theme.font.regular};
      font-size: 14px;
      line-height: 17px;
      padding: 20px 0 11px;
      text-transform: capitalize;

      &.ant-tabs-tab-active {
        font-family: ${({ theme }) => theme.font.medium};
        letter-spacing: -0.14px;

        .ant-tabs-tab-btn {
          color: ${({ theme }) => theme.colors.gogo};
          -webkit-text-stroke: unset;
        }
      }

      & + .ant-tabs-tab {
        margin: 0 0 0 40px;
      }
    }
  }
`;

const WhatsappDetailsTableHeader = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.alabaster};
  padding: 16px 30px;
  border-color: ${({ theme }) => theme.colors.koala};
  border-style: solid;
  border-width: 1px 0 1px;
  align-items: center;
  justify-content: space-between;
  max-height: 56px;

  .title {
    line-height: 17px;
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.carbon};
  }
`;

type WhatsappDetailsTableRow = {
  title: string;
  value: string;
  testId: string;
  extra?: React.ReactElement;
};

type WhatsappDetailsTableProps = {
  header: {
    title: string;
    extra?: React.ReactElement;
  };
  details: WhatsappDetailsTableRow[];
};

const WhatsappChannelDetailTitle = styled.span`
  line-height: 15px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.smalt};
`;

const WhatsappChannelDetailValue = styled.span`
  line-height: 15px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.flint};
`;

const StyledWhatsappDetailsTable = styled(Table)`
  .ant-table-tbody {
    transition: none;

    & > tr.ant-table-row:hover {
      & > td {
        background: none;
      }
    }

    .ant-table-row {
      &:last-child {
        .ant-table-cell {
          border-bottom: none;
          padding-bottom: 40px;
        }
      }

      .ant-table-cell {
        padding: 15px;

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }
` as typeof Table;

const WhatsappChannelDetails = ({ header, details }: WhatsappDetailsTableProps) => {
  const columns: ColumnsType<WhatsappDetailsTableRow> = [
    {
      dataIndex: 'title',
      key: 'title',
      render: (text) => <WhatsappChannelDetailTitle>{text}</WhatsappChannelDetailTitle>,
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => (
        <WhatsappChannelDetailValue data-lgg-id={record.testId}>
          {text}
        </WhatsappChannelDetailValue>
      ),
    },
  ];
  return (
    <FlexColumn>
      <WhatsappDetailsTableHeader>
        <span className="title">{header.title}</span>
        <span>{header.extra}</span>
      </WhatsappDetailsTableHeader>
      <StyledWhatsappDetailsTable
        columns={columns}
        dataSource={details}
        showHeader={false}
        pagination={false}
      />
    </FlexColumn>
  );
};

type ChannelTabKey = 'details' | 'templates';

export const WhatsappResourceDetailsPage = () => {
  const { t } = useTranslation(['whatsappChannel', 'common']);
  useAddBreadcrumb(t('whatsappChannel:pages.channelDetails.pageBreadcrumb'));
  const { resourceId } = useParams<{ resourceId?: string }>();
  const [selectedTab, setSelectedTab] = useState<ChannelTabKey>('details');
  const handleGraphQLError = useHandleGraphQLError();
  const { formatSimpleRelativeDate } = useFormatDate();

  const { data: resourceWhatsappData, loading: loadingResourceWhatsappData } = useQuery<
    Pick<Query, 'resourceWhatsapp'>,
    QueryResourceWhatsappArgs
  >(RESOURCE_WHATSAPP_DETAIL_QUERY, {
    variables: {
      id: Number(resourceId),
    },
    onError: (error) => handleGraphQLError(error, { isNavigation: true }),
  });

  const { handleSyncTemplates, isSyncingTemplates } = useHandleSyncWhatsappTemplates({
    resourceId: Number(resourceId),
    // TODO: Finish this implementation once the templates tab is implemented
    onCompleted: async () => {
      // Success
    },
    onUpdateError: () => {
      // error
    },
  });

  const syncWithMetaButton = useMemo(() => {
    return (
      <WhatsappSyncButton
        testId="whatsapp-sync-button"
        isSyncing={isSyncingTemplates}
        onClick={handleSyncTemplates}
      />
    );
  }, [handleSyncTemplates, isSyncingTemplates]);

  if (!resourceWhatsappData || loadingResourceWhatsappData) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  const { resource, waba, createdAt } = resourceWhatsappData.resourceWhatsapp;

  const channelLabel = resource?.label ?? resource?.alias;
  const manageProfileUrlPhoneNumber = resource.phoneNumber.e164.replace('+', '');

  return (
    <PageContainer data-lgg-id="whatsapp-resource-details-page">
      <PageHeader>
        <FlexRow>
          <HeaderWhatsappIcon type="whatsappFilled" />
          <ChannelInfoContainer>
            <ChannelLabel data-lgg-id="whatsapp-resource-label">
              {channelLabel ?? resource.phoneNumber.national}
            </ChannelLabel>
            {channelLabel && (
              <ChannelNumber data-lgg-id="whatsapp-resource-number">
                {resource.phoneNumber.national}
              </ChannelNumber>
            )}
          </ChannelInfoContainer>
        </FlexRow>
        <HeaderLeftContent>{syncWithMetaButton}</HeaderLeftContent>
      </PageHeader>
      <PageBody>
        <StyledTabs
          activeKey={selectedTab}
          onChange={(key) => {
            const tabKey = key as ChannelTabKey;

            setSelectedTab(tabKey);
          }}
        >
          <Tabs.TabPane key="details" tab="details">
            <WhatsappChannelDetails
              header={{
                title: t(
                  'whatsappChannel:pages.channelDetails.detailTabs.details.detailsTable.title',
                ),
                extra: (
                  // TODO: Move button to profile tab
                  <ExternalWhatsappLinkButton
                    data-lgg-id="whatsapp-resource-manage-button"
                    isLoading={loadingResourceWhatsappData}
                    text={t('whatsappChannel:manageProfileButton.label')}
                    url={`https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${waba}&phone_number=${manageProfileUrlPhoneNumber}&childRoute=PHONE_PROFILE%2FPROFILE`}
                    variant="tertiary"
                    testId="manage-whatsapp-profile-details"
                  />
                ),
              }}
              details={[
                {
                  title: t(
                    'whatsappChannel:pages.channelDetails.detailTabs.details.detailsTable.items.creationDate',
                  ),
                  testId: 'whatsapp-resource-creation-date',
                  value: formatSimpleRelativeDate(createdAt),
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="templates" tab="templates">
            TBD
          </Tabs.TabPane>
        </StyledTabs>
      </PageBody>
    </PageContainer>
  );
};

import React, { memo, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { LexicalEditor as LexicalEditorType } from 'lexical';
import {
  Mutation,
  MutationConversationSendFacebookMessageArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { CustomEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/custom-editor';
import {
  CustomEditorProps,
  useHandleReplyError,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/helpers';
import { CONVERSATION_SEND_FACEBOOK_MESSAGE } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { useAuthorization } from 'src/hooks/use-authorization';

export const FacebookMessengerEditor = memo<CustomEditorProps>(
  ({ handleFetchNewMessageCompleted }) => {
    const { messageValues, attachmentList, conversationId } = useContext(
      ContactInteractionInputManagerContext,
    );

    const handleReplyError = useHandleReplyError();
    const { getFeatureFlag } = useAuthorization();
    const isAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-265_como-usuario-quiero-poder-adjuntar-archivos-de-modo-que-pueda-compartir-con-mi-cliente-detalles-rele',
    );
    const isSmsAndFacebookMessengerAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-1887_deshabilitar-attachments-para-sms-y-facebook-messenger',
    );
    const isAiToolsFeatureEnabled = getFeatureFlag('AI');

    const [sendFacebookMessage, { loading: isSendingFacebookMessage }] = useMutation<
      Pick<Mutation, 'conversationSendFacebookMessage'>,
      MutationConversationSendFacebookMessageArgs
    >(CONVERSATION_SEND_FACEBOOK_MESSAGE, {
      onError: handleReplyError,
    });

    const handleSendMessage = useCallback(
      async (editor: LexicalEditorType) => {
        if (!conversationId || isSendingFacebookMessage) {
          return;
        }

        let commonVariables: Pick<
          MutationConversationSendFacebookMessageArgs,
          'conversationId' | 'message' | 'attachments'
        > = {
          conversationId,
          message: messageValues.message ?? '',
          attachments: [],
        };

        const attachments = attachmentList.map(({ url }) => ({
          s3UploadedUrl: url ?? '',
        }));

        if (isAttachmentsFeatureEnabled && attachments.length > 0) {
          commonVariables = {
            ...commonVariables,
            attachments,
          };
        }

        await sendFacebookMessage({
          variables: {
            ...commonVariables,
          },
          onCompleted: async ({ conversationSendFacebookMessage: data }) => {
            const interactionId = data.interaction?.id;

            if (!interactionId) {
              return;
            }

            await handleFetchNewMessageCompleted({
              editor,
              where: {
                contactInteractionId: {
                  eq: Number(interactionId),
                },
              },
            });
          },
        });
      },
      [
        isSendingFacebookMessage,
        conversationId,
        messageValues.message,
        attachmentList,
        isAttachmentsFeatureEnabled,
        sendFacebookMessage,
        handleFetchNewMessageCompleted,
      ],
    );

    return (
      <CustomEditor
        handleSubmission={handleSendMessage}
        isSubmitting={isSendingFacebookMessage}
        bottomContent={{
          visible: true,
        }}
        pluginConfig={{
          emojiPicker: true,
          messageAttachments:
            isAttachmentsFeatureEnabled &&
            isSmsAndFacebookMessengerAttachmentsFeatureEnabled,
          aiTools: isAiToolsFeatureEnabled,
        }}
        isInputEnabled
      />
    );
  },
);

import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactStatus,
  Query,
  QueryContactInteractionChannelsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCampaignListForSelect } from 'src/components/domain/campaign/hooks/use-campaign-list-for-select';
import { useActiveDepartmentSelectOptions } from 'src/components/domain/departments/hooks/use-active-department-select-options';
import { LggBaseDropdown } from 'src/components/general/button/dropdown-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { ActiveOptionBadge } from 'src/components/general/feedback/active-option-badge';
import { Icon } from 'src/components/general/icon';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { ContactTagSelect } from 'src/components/pages/conversations/components/contact-information/legacy-contact-tags-selector';
import { ButtonsBar } from 'src/components/pages/conversations/components/general/buttons-bar';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useVisible } from 'src/hooks/use-visible';

const IconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-right: 8px;
  padding-left: 15px;

  ${up('md')} {
    padding-right: 9px;
  }
`;

const FormInputsContainer = styled.div`
  > * {
    margin-top: 18px;

    ${down('md')} {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const StyledBottomDrawer = styled(BottomDrawer)`
  .scrollbar {
    padding: 18px 20px 0;
  }
`;

const StyledButtonsBar = styled(ButtonsBar)`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};

  ${up('md')} {
    border-top: unset;
    justify-content: flex-end;
    padding: 15px 0 0;

    > * {
      font-size: 12px;
      padding: 0 15px;
      width: unset;
    }

    > * + * {
      margin-left: 15px;
    }
  }
`;

const OverlayTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  display: block;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 18px;
  text-align: left;
`;

const StyledBadge = styled(ActiveOptionBadge)`
  .ant-badge-dot {
    right: -4px;
    top: -3px;
  }
`;

const FilterIcon = styled(Icon)`
  svg {
    width: 18px;
    height: 18px;

    ${up('md')} {
      width: 16px;
      height: 16px;
    }
  }
`;

const FormOverlay = styled.div`
  padding: 20px 20px 15px;
  width: 296px;
`;

export const CONTACT_FILTER_OPTIONS = gql`
  query GetContactFilterOptions($institutionId: Int!) {
    contactInteractionChannels(institutionId: $institutionId) {
      slug
      name
    }
    contactStages {
      id
      name
      slug
      statuses {
        id
        name
      }
    }
  }
`;

export type OnApplyFiltersParams = {
  channelsSlugs: string[];
  contactStagesIds: string[];
  contactStatusesIds: string[];
  contactTagsIds: number[];
  departmentIds: number[];
  contactCampaignsIds: number[];
};

type ContactFiltersProps = {
  onApply: (filters: OnApplyFiltersParams) => void;
  selectedStages: string[];
  selectedStatuses: string[];
  selectedChannels: string[];
  selectedContactTags: number[];
  selectedDepartmentIds: number[];
  selectedCampaignIds: number[];
};

export const ContactFilters = memo<ContactFiltersProps>(
  ({
    onApply,
    selectedStages,
    selectedStatuses,
    selectedChannels,
    selectedContactTags,
    selectedDepartmentIds,
    selectedCampaignIds,
  }) => {
    const { t } = useTranslation(['conversations', 'common']);
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));
    const [channelsSlugs, setChannelsSlugs] = useState<string[]>(selectedChannels);
    const [stagesIds, setStagesIds] = useState<string[]>(selectedStages);
    const [statusesIds, setStatusesIds] = useState<string[]>(selectedStatuses);
    const [contactTagsIds, setContactTagsIds] = useState<number[]>(selectedContactTags);
    const [departmentIds, setDepartmentIds] = useState<number[]>(selectedDepartmentIds);
    const [contactCampaignsIds, setContactContactCampaignsIds] =
      useState<number[]>(selectedCampaignIds);
    const { id: currentInstitutionId } = useCurrentInstitution();
    const handleGraphQLError = useHandleGraphQLError();
    const { data, loading } = useQuery<
      Pick<Query, 'contactInteractionChannels'> & Pick<Query, 'contactStages'>,
      QueryContactInteractionChannelsArgs
    >(CONTACT_FILTER_OPTIONS, {
      variables: {
        institutionId: currentInstitutionId,
      },
      onError: handleGraphQLError,
    });
    const { contactStages: stages, contactInteractionChannels: channels } =
      loading || !data ? { contactStages: [], contactInteractionChannels: [] } : data;
    const hasActiveFilters = useMemo(() => {
      return [
        selectedChannels,
        selectedStages,
        selectedStatuses,
        selectedContactTags,
        selectedDepartmentIds,
        selectedCampaignIds,
      ].some((filter) => Boolean(filter.length));
    }, [
      selectedCampaignIds,
      selectedChannels,
      selectedContactTags,
      selectedDepartmentIds,
      selectedStages,
      selectedStatuses,
    ]);
    const statuses = stages.reduce<ContactStatus[]>((acc, curr) => {
      return [...acc, ...curr.statuses];
    }, []);
    const { loadingDepartmentOptions, departmentOptions } =
      useActiveDepartmentSelectOptions();
    const { campaignOptions, loadingCampaignOptions } = useCampaignListForSelect({
      status: {
        _eq: 'ACTIVE',
      },
    });

    const handleReset = () => {
      const defaultFilters = {
        channelsSlugs: [],
        contactStagesIds: [],
        contactStatusesIds: [],
        contactTagsIds: [],
        departmentIds: [],
        contactCampaignsIds: [],
      };

      setChannelsSlugs(defaultFilters.channelsSlugs);
      setStagesIds(defaultFilters.contactStagesIds);
      setStatusesIds(defaultFilters.contactStatusesIds);
      setContactTagsIds(defaultFilters.contactTagsIds);
      setDepartmentIds(defaultFilters.departmentIds);
      setContactContactCampaignsIds(defaultFilters.contactCampaignsIds);

      onApply(defaultFilters);
      visibilityHandler.close();
    };

    const handleApply = () => {
      onApply({
        channelsSlugs,
        contactStagesIds: stagesIds,
        contactStatusesIds: statusesIds,
        contactTagsIds: contactTagsIds,
        departmentIds,
        contactCampaignsIds,
      });
      visibilityHandler.close();
    };

    const setSelectedStages = (values: string[]) => {
      setStagesIds(values);
    };

    const setSelectedStatuses = (values: string[]) => {
      setStatusesIds(values);
    };

    const iconContainer = (
      <IconContainer
        onClick={!breakpointUpMd ? visibilityHandler.show : undefined}
        data-lgg-id="conversations-filters-trigger-icon"
      >
        <StyledBadge dot={hasActiveFilters}>
          <FilterIcon lggTestId="conversation-filters" type="conversationFilters" />
        </StyledBadge>
      </IconContainer>
    );

    const formInputs = (
      <FormInputsContainer>
        <Select
          name="conversations-filters-channel-select"
          label={t('conversations:conversationsFilters.channel.label')}
          placeholder={t('conversations:conversationsFilters.channel.placeholder')}
          isMulti
          value={channels
            .filter((channel) => channelsSlugs.includes(channel.slug))
            .map((channel) => ({
              label: channel.name,
              value: channel.slug,
            }))}
          onChange={(options) => setChannelsSlugs(options.map((option) => option.value))}
          options={channels.map((channel) => ({
            label: channel.name,
            value: channel.slug,
          }))}
        />
        <Select
          name="conversations-filters-department-select"
          label={t('conversations:conversationsFilters.department.label')}
          placeholder={t('conversations:conversationsFilters.department.placeholder')}
          isLoading={loadingDepartmentOptions}
          options={departmentOptions}
          isMulti
          value={departmentOptions
            .filter((department) => departmentIds.includes(department.value))
            .map((department) => ({
              label: department.label,
              value: department.value,
            }))}
          onChange={(options) => setDepartmentIds(options.map((option) => option.value))}
        />
        <Select
          name="conversations-filters-stage-select"
          label={t('conversations:conversationsFilters.contactStage.label')}
          placeholder={t('conversations:conversationsFilters.contactStage.placeholder')}
          value={stages
            .filter((stage) => stagesIds.includes(stage.id))
            .map((stage) => ({
              label: stage.name,
              value: stage.id,
            }))}
          isMulti
          onChange={(options) => setSelectedStages(options.map((option) => option.value))}
          options={stages.map((stage) => ({
            label: stage.name,
            value: stage.id,
          }))}
        />
        <Select
          name="conversations-filters-status-select"
          label={t('conversations:conversationsFilters.contactStatus.label')}
          placeholder={
            stagesIds.length
              ? t('conversations:conversationsFilters.contactStatus.placeholder')
              : t('conversations:conversationsFilters.contactStatus.disabledPlaceholder')
          }
          isMulti
          isDisabled={!stagesIds.length}
          value={
            statusesIds
              .map((id) => statuses.find((status) => status.id === id))
              .map((status) =>
                status
                  ? {
                      label: status.name,
                      value: status.id,
                    }
                  : null,
              )
              .filter(Boolean) as SelectOption<string>[]
          }
          onChange={(options) =>
            setSelectedStatuses(options.map((option) => option.value))
          }
          options={stages
            .filter((stage) => stagesIds.includes(stage.id))
            .map((stage) => ({
              label: stage.name,
              options: stage.statuses.map((status) => ({
                label: status.name,
                value: status.id,
              })),
            }))}
        />
        <ContactTagSelect
          isMulti
          name="conversations-filters-tag-select"
          label={t('conversations:conversationsFilters.contactTags.label')}
          placeholder={t('conversations:conversationsFilters.contactTags.placeholder')}
          selectedTagsIds={contactTagsIds}
          onChange={(options) => {
            setContactTagsIds(options.map((option) => option.value));
          }}
        />
        <Select
          name="conversations-filters-contact-campaign-select"
          label={t('conversations:conversationsFilters.contactCampaign.label')}
          placeholder={t(
            'conversations:conversationsFilters.contactCampaign.placeholder',
          )}
          isLoading={loadingCampaignOptions}
          options={campaignOptions}
          isMulti
          value={campaignOptions
            .filter((campaign) => contactCampaignsIds.includes(campaign.value))
            .map((campaign) => ({
              label: campaign.label,
              value: campaign.value,
            }))}
          onChange={(options) =>
            setContactContactCampaignsIds(options.map((option) => option.value))
          }
        />
      </FormInputsContainer>
    );

    const buttonsBar = (
      <StyledButtonsBar
        primaryCallback={handleApply}
        secondaryCallback={handleReset}
        primaryText={t('common:filters.actionsApply')}
        secondaryText={t('common:filters.actionReset')}
        primaryButtonTestId="conversations-filters-apply"
        secondaryButtonTestId="conversations-filters-reset"
      />
    );

    if (breakpointUpMd) {
      return (
        <>
          <LggBaseDropdown
            overlayClassName="filters-form-overlay"
            customDropdownProps={{
              align: {
                offset: [1, 10],
              },
              placement: 'bottomRight',
            }}
            visibilityHandler={visibilityHandler}
            overlay={
              <FormOverlay data-lgg-id="conversations-filters-overlay">
                <OverlayTitle>
                  {t('conversations:conversationsFilters.title')}
                </OverlayTitle>
                {formInputs}
                {buttonsBar}
              </FormOverlay>
            }
          >
            {iconContainer}
          </LggBaseDropdown>
        </>
      );
    }

    return (
      <>
        {iconContainer}
        {!breakpointUpMd && (
          <StyledBottomDrawer
            fullHeight
            title={t('conversations:conversationsFilters.title')}
            visible={visibilityHandler.visible}
            onClose={visibilityHandler.close}
            footer={buttonsBar}
          >
            {formInputs}
          </StyledBottomDrawer>
        )}
      </>
    );
  },
);

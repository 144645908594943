import { match } from 'ts-pattern';
import {
  ScheduleContactAttendee,
  ScheduleEmailAttendee,
  ScheduleUserAttendee,
  ScheduleAttendeesUnion,
} from '../types/__generated__/graphql';

type MatchScheduleAttendeeHandler<Result> = {
  contactAttendee: (recipient: ScheduleContactAttendee) => Result;
  emailAttendee: (recipient: ScheduleEmailAttendee) => Result;
  userAttendee: (recipient: ScheduleUserAttendee) => Result;
};

export const matchScheduleAttendeeExhaustive = <Result>(
  attendee: ScheduleAttendeesUnion,
  handler: MatchScheduleAttendeeHandler<Result>,
) => {
  return match(attendee)
    .with({ __typename: 'ScheduleContactAttendee' }, handler.contactAttendee)
    .with({ __typename: 'ScheduleEmailAttendee' }, handler.emailAttendee)
    .with({ __typename: 'ScheduleUserAttendee' }, handler.userAttendee)
    .run();
};

import { gql } from '@apollo/client';
import { CORE_ATTACHMENTS_FIELDS } from 'src/components/providers/apollo-provider-provider';

export const CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT = gql`
  query ContactInteractionChannelAvailabilityForContact($contactId: Int!) {
    contactInteractionChannelAvailabilityForContact(contactId: $contactId) {
      channel {
        slug
        name
      }
      resources {
        ... on ContactInteractionChannelResource {
          id
          address
          displayName
        }
        ... on ContactInteractionChannelPhoneResource {
          phoneNumber {
            national
          }
        }
        ... on ContactInteractionChannelSmsResource {
          phoneNumber {
            national
          }
          unavailableReasonCode
        }
      }
      lastUsedResourceId
      lastInteractionContactAddress
      isAvailable
      lastInteractionDate
      unavailableReasonCode
      resourceToContactAddressAvailability {
        resourceId
        contactAddress
        expiresAt
        unavailableReasonCode
      }
    }
  }
`;

export const CONVERSATION_SEND_SMS = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  mutation ConversationSendSms(
    $conversationId: ID!
    $message: String!
    $fromResourceId: Int
    $attachments: [ConversationSendContactInteractionFileAttachmentInput!]
  ) {
    conversationSendSms(
      conversationId: $conversationId
      message: $message
      fromResourceId: $fromResourceId
      attachments: $attachments
    ) {
      interaction {
        id
        message {
          content
          status
        }
        ...ContactInteractionAttachmentsFragment
      }
    }
  }
`;

export const CONVERSATION_SEND_FACEBOOK_MESSAGE = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  mutation ConversationSendFacebookMessage(
    $conversationId: ID!
    $message: String!
    $attachments: [ConversationSendContactInteractionFileAttachmentInput!]
  ) {
    conversationSendFacebookMessage(
      conversationId: $conversationId
      message: $message
      attachments: $attachments
    ) {
      interaction {
        id
        message {
          content
          status
        }
        direction
        ...ContactInteractionAttachmentsFragment
      }
    }
  }
`;

export const ADD_INTERNAL_NOTE = gql`
  mutation AddConversationNote(
    $conversationId: String!
    $noteText: String!
    $rawNoteText: String!
    $mentions: [Int!]
  ) {
    conversationAddInternalNote(
      conversationId: $conversationId
      noteText: $noteText
      rawNoteText: $rawNoteText
      mentions: $mentions
    ) {
      note {
        id
        text
        rawNote
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const CONVERSATION_SEND_WHATSAPP_MESSAGE = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  mutation conversationSendWhatsappMessage(
    $conversationId: ID!
    $message: String!
    $resourceId: ID!
    $contactPhoneNumber: String
    $attachments: [ConversationSendContactInteractionFileAttachmentInput!]
  ) {
    conversationSendWhatsappMessage(
      conversationId: $conversationId
      message: $message
      resourceId: $resourceId
      contactPhoneNumber: $contactPhoneNumber
      attachments: $attachments
    ) {
      interaction {
        id
        message {
          id
          content
          status
        }
        direction
        ...ContactInteractionAttachmentsFragment
      }
    }
  }
`;

export const CONVERSATION_SEND_INSTAGRAM_MESSAGE = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  mutation conversationSendInstagramMessage(
    $conversationId: ID!
    $message: String!
    $resourceId: ID!
    $attachments: [ConversationSendContactInteractionFileAttachmentInput!]
  ) {
    conversationSendInstagramMessage(
      conversationId: $conversationId
      message: $message
      resourceId: $resourceId
      attachments: $attachments
    ) {
      interaction {
        id
        message {
          id
          content
          status
        }
        direction
        ...ContactInteractionAttachmentsFragment
      }
    }
  }
`;

export const GET_CONTACT_CONTACT_INFORMATION = gql`
  query GetContactContactInformation($id: Int!) {
    contact(id: $id) {
      id
      primaryPhone {
        national
        e164
      }
      secondaryPhone {
        national
        e164
      }
    }
  }
`;

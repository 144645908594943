import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationConversationSetMarkedArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { publishEvent } from 'src/utils/events/pub-sub';

const CONVERSATION_SET_MARKED = gql`
  mutation ConversationSetMarked($conversationId: String!, $isMarked: Boolean!) {
    conversationSetMarked(conversationId: $conversationId, isMarked: $isMarked) {
      conversation {
        id
        isMarked
      }
    }
  }
`;

type ConversationSetMarkedVariables = { conversationId: string; isMarked: boolean };

export const useConversationSetMarked = () => {
  const { t } = useTranslation(['conversations']);
  const showNotification = useShowNotification();
  const [mutate] = useMutation<
    Pick<Mutation, 'conversationSetMarked'>,
    MutationConversationSetMarkedArgs
  >(CONVERSATION_SET_MARKED);

  return async ({ isMarked, conversationId }: ConversationSetMarkedVariables) => {
    await mutate({
      variables: { conversationId, isMarked },
      onError: () => {
        const message = isMarked
          ? 'conversations:notifications.mutations.changeMarkedStatus.toMarked.error'
          : 'conversations:notifications.mutations.changeMarkedStatus.toUnmarked.error';

        showNotification({
          type: 'error',
          title: t(message),
        });
      },
      onCompleted: () => {
        publishEvent('CONVERSATION_UPDATED', { id: conversationId });

        const message = isMarked
          ? 'conversations:notifications.mutations.changeMarkedStatus.toMarked.success'
          : 'conversations:notifications.mutations.changeMarkedStatus.toUnmarked.success';

        showNotification({
          type: 'success',
          title: t(message),
        });
      },
    });
  };
};

import { match } from 'ts-pattern';
import {
  ContactInteractionAttachmentContacts,
  ContactInteractionAttachmentFile,
  ContactInteractionAttachmentLocation,
  ContactInteractionAttachmentUnion,
} from '../types/__generated__/graphql';

export const attachmentPatterns = {
  image: { __typename: 'ContactInteractionAttachmentFile', type: 'IMAGE' } as const,
  sticker: { __typename: 'ContactInteractionAttachmentFile', type: 'STICKER' } as const,
  video: { __typename: 'ContactInteractionAttachmentFile', type: 'VIDEO' } as const,
  audio: { __typename: 'ContactInteractionAttachmentFile', type: 'AUDIO' } as const,
  document: { __typename: 'ContactInteractionAttachmentFile', type: 'DOCUMENT' } as const,
  location: { __typename: 'ContactInteractionAttachmentLocation' } as const,
  contacts: { __typename: 'ContactInteractionAttachmentContacts' } as const,
};

type MatchConversationAttachmentHandler<Result> = {
  image: (attachment: ContactInteractionAttachmentFile) => Result;
  sticker: (attachment: ContactInteractionAttachmentFile) => Result;
  video: (attachment: ContactInteractionAttachmentFile) => Result;
  audio: (attachment: ContactInteractionAttachmentFile) => Result;
  document: (attachment: ContactInteractionAttachmentFile) => Result;
  location: (attachment: ContactInteractionAttachmentLocation) => Result;
  contacts: (attachment: ContactInteractionAttachmentContacts) => Result;
};

export const matchConversationAttachmentExhaustive = <Result>(
  attachment: ContactInteractionAttachmentUnion,
  handler: MatchConversationAttachmentHandler<Result>,
) => {
  return match(attachment)
    .with(attachmentPatterns.image, handler.image)
    .with(attachmentPatterns.sticker, handler.sticker)
    .with(attachmentPatterns.video, handler.video)
    .with(attachmentPatterns.audio, handler.audio)
    .with(attachmentPatterns.document, handler.document)
    .with(attachmentPatterns.location, handler.location)
    .with(attachmentPatterns.contacts, handler.contacts)
    .run();
};

import { useCallback } from 'react';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

// https://day.js.org/docs/en/parse/string-format#list-of-all-recognized-separator-characters
// Need to use _ instead of "+" since its not a
// valid separator for parsing.
const FILTER_DATE_FORMAT = 'YYYY-MM-DD_hh:mm_A';

export const START_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+12:00+AM]';

export const END_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+11:59+PM]';

export const useFilterHelpers = () => {
  const { parseDate } = useDateHelpers();
  const { formatDate } = useFormatDate();

  const encodeStartFilterDate = useCallback(
    (date: Date) => formatDate(date, START_FILTER_DATE_FORMAT, false),
    [formatDate],
  );

  const encodeFilterEndDate = useCallback(
    (date: Date) => formatDate(date, END_FILTER_DATE_FORMAT, false),
    [formatDate],
  );

  const decodeFilterDate = useCallback(
    (encodedDate?: string) => {
      return encodedDate
        ? parseDate(encodedDate?.replaceAll(' ', '+'), FILTER_DATE_FORMAT)
        : new Date();
    },
    [parseDate],
  );

  return {
    decodeFilterDate,
    encodeFilterEndDate,
    encodeStartFilterDate,
  };
};

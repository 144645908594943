import styled from 'styled-components';

type OverlayProps = {
  color?: string;
};

export const Overlay = styled.div<OverlayProps>`
  background-color: ${({ theme, color }) => color || theme.colors.overlayColor};
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 940;
`;

import React, { memo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ChangePasswordPage } from 'src/components/pages/account/change-password/change-password-page';
import { RecoverCredentialsPage } from 'src/components/pages/account/recover/recover-credentials-page';
import { SignInPage } from 'src/components/pages/account/sign-in/sign-in-page';

export const UnauthenticatedRouter = memo(() => {
  const location = useLocation();

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/account/login" />
      </Route>
      <Route path="/account/password/recover" exact>
        <Redirect to={`/account/change-password${location.search}`} />
      </Route>
      <Route path="/account/login">
        <SignInPage />
      </Route>
      <Route path="/account/recover">
        <RecoverCredentialsPage />
      </Route>
      <Route path="/account/change-password">
        <ChangePasswordPage />
      </Route>
      <Route path="*">
        <Redirect
          to={`/account/login?target=${encodeURI(
            location.pathname + location.search + location.hash,
          )}`}
        />
      </Route>
    </Switch>
  );
});

import { gql } from '@apollo/client';

export const ACTIVATE_WHATSAPP_RESOURCE_MUTATION = gql`
  mutation ActivateWhatsappResource(
    $code: String!
    $waba: String!
    $phoneNumberId: String!
  ) {
    activateWhatsappResource(code: $code, waba: $waba, phoneNumberId: $phoneNumberId) {
      success
    }
  }
`;

export const UPDATE_WHATSAPP_TEMPLATES_SYNC_MUTATION = gql`
  mutation UpdateWhatsappTemplatesSync($resourceId: Int!) {
    updateWhatsappTemplatesSync(resourceId: $resourceId) {
      success
    }
  }
`;

export const RESOURCE_WHATSAPP_QUERY = gql`
  query GetResourceWhatsapp($id: Int!) {
    resourceWhatsapp(id: $id) {
      id
      waba
    }
  }
`;

export const RESOURCE_WHATSAPP_DETAIL_QUERY = gql`
  query GetResourceWhatsapp($id: Int!) {
    resourceWhatsapp(id: $id) {
      id
      waba
      resource {
        id
        alias
        label
        phoneNumber {
          national
          international
          e164
        }
      }
      createdAt
      isActive
    }
  }
`;

export const WHATSAPP_RESOURCES_QUERY = gql`
  query GetWhatsappResources(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    whatsappResources(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          resource {
            id
            alias
            label
            ... on ResourcePhoneNumber {
              phoneNumber {
                national
                international
              }
            }
          }
          createdAt
          isActive
        }
      }
    }
  }
`;

export const WHATSAPP_RESOURCE_SET_ACTIVE_MUTATION = gql`
  mutation WhatsappResourceSetActive($resourceId: Int!, $isActive: Boolean!) {
    resourceWhatsappSetActive(resourceId: $resourceId, isActive: $isActive) {
      resource {
        id
      }
    }
  }
`;

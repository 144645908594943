import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Space from 'antd/lib/space';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const Container = styled.div`
  margin: 30px;
  .table-top {
    width: 100%;
  }
`;

const WhiteBox = styled.div`
  width: 100%;
  border-radius: 6px;
  margin: 30px 0;
  padding: 30px 20px;
  background: white;
`;

const TableRows = memo<{ isHeader?: boolean }>(({ isHeader = false }) => {
  return (
    <Row align="middle" justify="space-between">
      <TableCols isHeader={isHeader} />
      <TableCols isHeader={isHeader} />
      <TableCols isHeader={isHeader} />
    </Row>
  );
});

const TableCols = memo<{ isHeader?: boolean }>(({ isHeader = false }) => {
  return (
    <Col span={7}>
      <Skeleton
        title={{ width: '100%', style: { maxWidth: isHeader ? '200px' : '350px' } }}
        paragraph={false}
        active
      />
    </Col>
  );
});

const TableViewLoading = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <>
      <Row align="middle">
        <Col flex="auto">
          <Skeleton title={{ width: 200 }} paragraph={false} active />
        </Col>

        <Col flex="100px" style={{ textAlign: 'right' }}>
          <Skeleton.Button
            active
            style={{ width: breakpointUpMd ? '100px' : '50px' }}
            size="small"
          />
        </Col>
      </Row>
      <Row align="middle">
        {!breakpointUpMd && (
          <WhiteBox>
            {[...Array(6)].map((v, index) => (
              <Skeleton key={index} title={{ width: '100%' }} paragraph={false} active />
            ))}
          </WhiteBox>
        )}
        {breakpointUpMd && (
          <WhiteBox>
            <TableRows isHeader={true} />
            {[...Array(9)].map((v, index) => (
              <TableRows key={index} />
            ))}
          </WhiteBox>
        )}
      </Row>
      <Row align="middle">
        <Space>
          <Skeleton.Button active style={{ width: '100px' }} size="small" />
          <Skeleton.Button active style={{ width: '100px' }} size="small" />
        </Space>
      </Row>
    </>
  );
});

const FormViewLoading = memo(() => {
  return (
    <WhiteBox>
      <Row align="middle">
        <Skeleton title={{ width: '200px' }} paragraph={false} active />

        {[...Array(4)].map((v, index) => (
          <React.Fragment key={index}>
            <Skeleton title={{ width: '75px' }} paragraph={false} active />
            <Skeleton.Input
              style={{ width: '250px', marginBottom: '10px' }}
              active
              size="small"
            />
          </React.Fragment>
        ))}
      </Row>
      <Row align="middle" style={{ marginTop: '20px' }}>
        <Space>
          <Skeleton.Button active style={{ width: '100px' }} size="small" />
          <Skeleton.Button active style={{ width: '100px' }} size="small" />
        </Space>
      </Row>
    </WhiteBox>
  );
});

export const LegacyViewLoading = memo(() => {
  const location = useLocation();
  let viewType: JSX.Element;

  if (location.pathname.includes('/edit/')) {
    viewType = <FormViewLoading />;
  } else {
    viewType = <TableViewLoading />;
  }

  return <Container>{viewType}</Container>;
});

import { css } from 'styled-components';

export type AlertType = 'success' | 'error' | 'info' | 'warning';

export const successAlertStyles = css`
  background-color: #f0fbf5;
  border-color: #81e5af;
`;

export const errorAlertStyles = css`
  background-color: #fef3f4;
  border-color: #fda1a6;
`;

export const infoAlertStyles = css`
  background-color: #eff9fb;
  border-color: #75cfe2;
`;

export const warningAlertStyles = css`
  background-color: #fff7e1;
  border-color: #ffd566;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const getAlertIconByType = (type: AlertType) => {
  switch (type) {
    case 'success':
      return 'successAlertIcon';
    case 'error':
      return 'errorAlertIcon';
    case 'info':
      return 'infoAlertIcon';
    case 'warning':
      return 'warningAlertIcon';
  }
};

import React from 'react';
import styled from 'styled-components';
import { FlexRow } from 'src/components/layout/flex-row';

const MessageTypeSelectorContainer = styled(FlexRow)`
  min-height: 35px;
  max-height: 45px;
  padding: 0 20px;
  background-color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  align-items: center;
  justify-content: space-between;
`;

const MessageTypeOptionsContainer = styled(FlexRow)`
  height: 100%;
  align-items: center;
  margin-bottom: -1px;
`;

type MessageTypeSelectorProps = {
  leading: React.ReactNode;
  trailing: React.ReactNode | null;
};

export const ContactInteractionInputAreaHeader = ({
  leading,
  trailing = null,
}: MessageTypeSelectorProps) => {
  return (
    <MessageTypeSelectorContainer>
      <MessageTypeOptionsContainer>{leading}</MessageTypeOptionsContainer>
      {trailing}
    </MessageTypeSelectorContainer>
  );
};

import React, { useState } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useLongPress } from 'use-long-press';
import {
  disabledUserSelectGlobalStyle,
  enabledUserSelectGlobalStyle,
} from 'src/theme/globals/user-select-global-style';

const Wrapper = styled.span`
  border-radius: inherit;
  ${disabledUserSelectGlobalStyle}

  ${up('md')} {
    ${enabledUserSelectGlobalStyle}
  }
`;

type Props = {
  onLongPress: VoidFunction;
  onClick: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  children: React.ReactNode;
  threshold?: number;
};

export const LongPressWrapper = ({
  onLongPress,
  onClick,
  threshold = 400,
  children,
}: Props) => {
  const [wasLongPressTriggered, setWasLongPressTriggered] = useState(false);

  const bind = useLongPress(
    () => {
      setWasLongPressTriggered(true);
      onLongPress();
    },
    {
      threshold,
      cancelOnMovement: true,
      onStart: () => {
        setWasLongPressTriggered(false);
      },
      onFinish: (e) => {
        setWasLongPressTriggered(true);
      },
      onCancel: (e) => {
        setWasLongPressTriggered(false);
      },
    },
  );

  return (
    <Wrapper
      {...bind()}
      onClick={(e) => {
        if (!wasLongPressTriggered) {
          onClick(e);
        }
      }}
    >
      {children}
    </Wrapper>
  );
};

import { Service } from 'react-service-locator';

const done = Symbol();
const promiseError = Symbol();

@Service({ scope: 'transient' })
export class SuspenseService {
  private _promise: Promise<void> | null = null;
  private _lastDeps: any[] | null = null;

  get promise() {
    if (!this._promise) {
      throw new Error('Promise not set yet.');
    }
    return this._promise;
  }

  private depsChanged(newDeps: any[]) {
    return (
      this._lastDeps === null ||
      this._lastDeps.length !== newDeps.length ||
      this._lastDeps.some((ld, i) => !Object.is(ld, newDeps[i]))
    );
  }

  private reset = () => {
    this._promise = null;
  };

  suspendWith = (fn: () => Promise<void>, deps?: any[]) => {
    if (typeof deps !== 'undefined') {
      if (this.depsChanged(deps)) {
        this.reset();
      }
      this._lastDeps = deps;
    }
    if (!this._promise) {
      const promise = fn();
      promise
        .catch((error) => {
          promise[promiseError] = error;
        })
        .finally(() => {
          promise[done] = true;
        });
      this._promise = promise;
    }
    if (this._promise[promiseError]) {
      throw this._promise[promiseError];
    }
    if (!this._promise[done]) {
      throw this._promise;
    }
  };
}

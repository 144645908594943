import React, { memo } from 'react';
import styled from 'styled-components';
import { parseLinksAndEscapeText } from 'src/utils/parse-links-and-escape-text';

const StyledTextFormatter = styled.span`
  white-space: pre-wrap;

  a {
    color: ${({ theme }) => theme.colors.gogo};
  }

  code {
    color: inherit;
  }
`;

type TextFormatterParserProps = {
  children: string | null;
};

export const TextFormatter = memo<TextFormatterParserProps>(({ children, ...rest }) => {
  if (!children) {
    return null;
  }

  const sanitizedHtml = parseLinksAndEscapeText(children);

  return (
    <StyledTextFormatter {...rest} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
});

import { useContactSources } from 'src/components/domain/contacts/hooks/use-contact-sources';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseContactSourceListForSelectReturn = {
  loadingSourceOptions: boolean;
  sourceOptions: SelectOption<number>[];
};

export const useContactSourceListForSelect = (): UseContactSourceListForSelectReturn => {
  const { sources, loading } = useContactSources();

  return {
    sourceOptions: sources ? sources.map(entityToSelectOptionMapper) : [],
    loadingSourceOptions: loading,
  };
};

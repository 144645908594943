import Layout from 'antd/lib/layout';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';

export const StyledSider: StyledComponent<typeof Layout.Sider, DefaultTheme> = styled(
  Layout.Sider,
)`
  background: ${(props: any) => props.theme.colors.base.global};
  height: 100%;
  overflow: hidden;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  /* Hide ellipsis when collapsed to prevent seeing animation of three dots when opening */

  & .ant-menu-inline-collapsed > .ant-menu-item {
    text-overflow: initial;
  }

  & .ant-menu-inline-collapsed {
    width: 70px;
  }

  & .ant-menu-inline-collapsed > .ant-menu-item,
  &
    .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  &
    .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  & .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 22px !important;
  }

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    height: 0;
  }

  .lgg-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -3px;
    margin-right: 20px !important;
  }

  .ant-menu-submenu-open .lgg-arrow {
    transform: rotate(180deg) translateX(2px);
  }

  .sidebar-menu {
    width: 100% !important;
  }

  ${up('md')} {
    height: 100vh;
    position: absolute;
    z-index: 950;
  }

  ${up('xl')} {
    &.fixed-sidebar {
      position: relative;
    }

    .sidebar-floating & {
      z-index: ${ModalsZIndex + 1};
    }
  }
`;

import { useEffect } from 'react';
import { useService } from 'react-service-locator';
import { HeaderAction, HeaderActionsService } from 'src/services/header-actions.service';

export function useSetHeaderActions(actions: HeaderAction[]) {
  const { set } = useService(HeaderActionsService, () => []);
  useEffect(() => {
    set(actions);
    return () => {
      set([]);
    };
  }, [set, actions]);
}

import { useService } from 'react-service-locator';
import { SidebarService } from 'src/services/sidebar.service';

export function useSidebar() {
  const {
    state: { isCollapsed, isFixed },
    toggleSidebar,
    openSidebar,
    closeSidebar,
    fixSideBar,
  } = useService(SidebarService, (s) => [s.state.isCollapsed, s.state.isFixed]);
  return {
    isSidebarCollapsed: isCollapsed,
    isSidebarFixed: isFixed,
    toggleSidebar,
    openSidebar,
    closeSidebar,
    fixSideBar,
  };
}

import { v4 as uuid } from 'uuid';
import { BuildInfo } from 'src/services/app-status.service';
import { GenericHttpError } from 'src/services/http/generic-http.service';

type ApiErrorData = {
  category: string;
  code: string;
};

type HttpApiError = GenericHttpError<ApiErrorData>;

export const isApiErrorData = (data: any): data is ApiErrorData => {
  return data && 'category' in data && 'code' in data;
};

export const isHttpApiError = (error: any): error is HttpApiError => {
  return error && error instanceof GenericHttpError && isApiErrorData(error.response);
};

export const host = () => {
  return new URL(`${import.meta.env.VITE_WEB_API_BASE_URL}`).host;
};

export const apiUrl = () => {
  return `${import.meta.env.VITE_WEB_API_BASE_URL}/v1`;
};

export function requestIdentifyingHeaders(params: { build: BuildInfo }) {
  const {
    build: { version, date },
  } = params;
  return {
    'lgg-app-id': 'user-web-app',
    'lgg-build-version': version,
    'lgg-build-date': date,
    'lgg-request-id': uuid(),
    'lgg-referer': window.location.href,
  };
}

import { RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { LexicalEditor } from 'lexical';
import { match } from 'ts-pattern';
import {
  ContactInteractionChannelAvailabilityForContact,
  ContactInteractionChannelResourceUnion,
  ConversationItemsWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import {
  ContactPhoneOption,
  getChannelErrorMessage,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

export type CustomEditorProps = {
  handleFetchNewMessageCompleted: ({
    editor,
    where,
  }: {
    editor?: LexicalEditor;
    where: ConversationItemsWhereInput;
  }) => Promise<void>;
  channelAvailability?: ContactInteractionChannelAvailabilityForContact;
  availabilityMessageRef?: RefObject<HTMLSpanElement>;
};

export const mapChannelResourcesToOptions = ({
  resources,
  onClick,
  testIdGenerator,
}: {
  resources: ContactInteractionChannelResourceUnion[];
  testIdGenerator: (id: string | number) => string;
  onClick: (resource: ContactInteractionChannelResourceUnion) => void;
}): DrawerSelectableOption[] => {
  return resources.map((resource) => {
    const { displayName, address, id: resourceId } = resource;
    const phoneNumber =
      resource.__typename === 'ContactInteractionChannelPhoneResource'
        ? resource.phoneNumber
        : null;

    return {
      label: phoneNumber?.national ?? address,
      onClick: () => onClick(resource),
      subTitle: displayName ?? undefined,
      value: resourceId,
      'data-lgg-id': testIdGenerator(resourceId),
    };
  });
};

export const useMapContactAddressesToOptions = () => {
  const { t } = useTranslation(['common']);

  const mapContactAddressesToOptions = useCallback(
    ({
      phoneOptions,
      onClick,
    }: {
      phoneOptions: ContactPhoneOption[];
      onClick: (phoneOption: ContactPhoneOption) => void;
    }): DrawerSelectableOption[] => {
      return phoneOptions.map((phoneOption) => {
        const { national, type } = phoneOption;
        const optionSubtitle = match(type)
          .with('PRIMARY', () => t('common:primaryPhone'))
          .with('SECONDARY', () => t('common:secondaryPhone'))
          .exhaustive();

        return {
          label: national,
          onClick: () => onClick(phoneOption),
          subTitle: optionSubtitle,
          value: type,
          'data-lgg-id': `contact-phone-option-${type}`,
        };
      });
    },
    [t],
  );

  return { mapContactAddressesToOptions };
};

export const useHandleReplyError = () => {
  const { t } = useTranslation(['errors']);
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();

  const handleReplyError = (error: ApolloError) => {
    const [firstError] = error.graphQLErrors;

    if (firstError?.extensions?.code.startsWith('CHANNELS:')) {
      showNotification({
        type: 'error',
        title: t('errors:notification.title'),
        message: getChannelErrorMessage(firstError.extensions.code),
      });
    } else {
      handleGraphQLError(error);
    }
  };

  return handleReplyError;
};

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { $nodesOfType, LexicalEditor as LexicalEditorType } from 'lexical';
import {
  Mutation,
  MutationConversationAddInternalNoteArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { CustomEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/custom-editor';
import { CustomEditorProps } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/helpers';
import { ADD_INTERNAL_NOTE } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { ContactInteractionInputAreaMode } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { UserMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

export const InternalNoteEditor = memo<CustomEditorProps>(
  ({ handleFetchNewMessageCompleted }) => {
    const { messageValues, conversationId, inputAreaMode } = useContext(
      ContactInteractionInputManagerContext,
    );
    const handleGraphQLError = useHandleGraphQLError();
    const { t } = useTranslation(['conversations']);

    const [addInternalNote, { loading: isAddingInternalNote }] = useMutation<
      Pick<Mutation, 'conversationAddInternalNote'>,
      Partial<MutationConversationAddInternalNoteArgs>
    >(ADD_INTERNAL_NOTE, {
      onError: (error) => handleGraphQLError(error),
    });

    const handleSendInternalNote = useCallback(
      async (editor: LexicalEditorType) => {
        if (!conversationId || isAddingInternalNote) {
          return;
        }

        let userMentionsIds: number[] = [];

        editor.update(() => {
          const userMentionNodes = $nodesOfType(UserMentionNode);

          userMentionsIds = userMentionNodes.map((mentionNode) =>
            Number(mentionNode.userId),
          );
        });

        await addInternalNote({
          variables: {
            conversationId,
            noteText: messageValues.message,
            rawNoteText: messageValues.rawMessage,
            mentions: userMentionsIds,
          },
          onCompleted: async ({ conversationAddInternalNote }) => {
            const { note } = conversationAddInternalNote;

            await handleFetchNewMessageCompleted({
              editor,
              where: {
                conversationNoteId: {
                  eq: note.id,
                },
              },
            });
          },
        });
      },
      [
        isAddingInternalNote,
        conversationId,
        addInternalNote,
        messageValues.message,
        messageValues.rawMessage,
        handleFetchNewMessageCompleted,
      ],
    );

    const isInputEnabled = useMemo(
      () => inputAreaMode === ContactInteractionInputAreaMode.InternalNote,
      [inputAreaMode],
    );

    return (
      <CustomEditor
        handleSubmission={handleSendInternalNote}
        isSubmitting={isAddingInternalNote}
        bottomContent={{
          visible: true,
        }}
        forceFocus
        placeholderOverride={t(
          'conversations:messageInput.modes.internalNote.inputPlaceholder',
        )}
        pluginConfig={{
          emojiPicker: true,
          mentions: true,
        }}
        isInputEnabled={isInputEnabled}
      />
    );
  },
);

import React from 'react';
import { get } from 'lodash';
import { ConversationItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { getContactInteractionCreationDate } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/shared';
import {
  getConversationItemUserId,
  getDirectionFromContactInteraction,
} from 'src/components/pages/conversations/components/contact-interactions/items/helpers';
import { MessageGroupLabel } from 'src/components/pages/conversations/components/contact-interactions/items/message-group-label';
import { useFormatDate } from 'src/hooks/use-format-date';

export const useContactInteractionMessageSenderGroups = (params: {
  contactInteractions: ConversationItemUnion[];
  contactLabel: string;
}) => {
  const { contactInteractions, contactLabel } = params;
  const { formatRelativeGroupDate } = useFormatDate();

  return contactInteractions.reduce<{ [id: string]: JSX.Element }>((acc, node, index) => {
    const currentContactInteraction = contactInteractions[index];
    const nextContactInteraction = get(contactInteractions, index + 1);

    const currentDate = formatRelativeGroupDate(
      getContactInteractionCreationDate(currentContactInteraction),
    );

    const groupLabelBuilder = () => (
      <MessageGroupLabel
        contactLabel={contactLabel}
        contactInteraction={currentContactInteraction}
      />
    );

    if (nextContactInteraction) {
      const nextItemDirection =
        getDirectionFromContactInteraction(nextContactInteraction);
      const currentItemDirection = getDirectionFromContactInteraction(
        currentContactInteraction,
      );
      const nextDate = formatRelativeGroupDate(
        getContactInteractionCreationDate(nextContactInteraction),
      );

      if (
        nextContactInteraction.__typename !== currentContactInteraction.__typename ||
        nextItemDirection !== currentItemDirection ||
        nextDate !== currentDate ||
        getConversationItemUserId(currentContactInteraction) !==
          getConversationItemUserId(nextContactInteraction)
      ) {
        return { ...acc, [node.id]: groupLabelBuilder() };
      }
    } else {
      return { ...acc, [node.id]: groupLabelBuilder() };
    }

    return acc;
  }, {});
};

import Lottie from 'lottie-react';
import loadingAnimation from 'src/assets/animations/select_spinner.json';

export const LoadingSpinner = (props: { size?: string }) => {
  const { size = '16px' } = props;

  return (
    <Lottie
      animationData={loadingAnimation}
      loop={true}
      style={{ height: size, width: size }}
    />
  );
};

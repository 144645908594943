import React, { memo } from 'react';
import styled from 'styled-components';
import { RotatingLoadingIcon } from 'src/components/general/feedback/rotating-loading-icon';

const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 63px;
  justify-content: center;
`;

type ListLoadingItemProps = {
  visible: boolean;
};

export const ListLoadingItem = memo<ListLoadingItemProps>(({ visible, ...rest }) => {
  if (!visible) {
    return null;
  }

  return (
    <LoaderContainer {...rest}>
      <RotatingLoadingIcon />
    </LoaderContainer>
  );
});

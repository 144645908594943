import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { ConversationItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionInputAreaMode } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { $createMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { useQueryParams } from 'src/hooks/use-query-params';

type ReplyDetectorPluginProps = {
  selectedInteraction?: ConversationItemUnion;
  setInputAreaMode: ValueChanged<ContactInteractionInputAreaMode>;
  setHasFocus: ValueChanged<boolean>;
  inputAreaMode: ContactInteractionInputAreaMode;
};

export const ReplyDetectorPlugin = ({
  selectedInteraction,
  setHasFocus,
  setInputAreaMode,
  inputAreaMode,
}: ReplyDetectorPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const queryParams = useQueryParams();
  const replyDetected = queryParams['reply'] === 'true';
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!replyDetected) {
      return;
    }

    if (inputAreaMode === ContactInteractionInputAreaMode.InternalNote) {
      history.push(location.pathname);

      if (!selectedInteraction) {
        return;
      }

      editor.update(() => {
        if (
          selectedInteraction &&
          selectedInteraction.__typename === 'ConversationNote'
        ) {
          const parentNode = $getRoot().getFirstChild()?.replace($createParagraphNode());
          const mentionNode = $createMentionNode(
            selectedInteraction.createdBy.fullName,
            selectedInteraction.createdBy.id.toString(),
          );

          parentNode?.append(mentionNode);
          parentNode?.append($createTextNode(' '));
          parentNode?.selectEnd();

          setHasFocus(true);
          editor.focus();
        }
      });
    } else {
      setInputAreaMode(ContactInteractionInputAreaMode.InternalNote);
    }
  }, [
    editor,
    history,
    inputAreaMode,
    location.pathname,
    queryParams,
    replyDetected,
    selectedInteraction,
    setHasFocus,
    setInputAreaMode,
  ]);

  return null;
};

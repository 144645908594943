import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getRoot,
  $setSelection,
  COMMAND_PRIORITY_HIGH,
  createCommand,
  LexicalCommand,
  LexicalEditor,
} from 'lexical';
import { ContactInteractionInputAreaMode } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';

export const RESET_EDITOR_COMMAND: LexicalCommand<VoidFunction> = createCommand();

type InputAreaModeChangePluginProps = {
  inputAreaMode: ContactInteractionInputAreaMode;
  resetEditorStateHandler: (editor: LexicalEditor) => void;
  setHasFocus: ValueChanged<boolean>;
};

export const InputAreaModeChangePlugin = ({
  resetEditorStateHandler,
  inputAreaMode,
  setHasFocus,
}: InputAreaModeChangePluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      RESET_EDITOR_COMMAND,
      (onUpdate) => {
        editor.update(() => {
          const root = $getRoot();
          const paragraph = $createParagraphNode();
          root.clear();
          root.append(paragraph);
          $setSelection(null);
          editor.blur();
          onUpdate?.();
        });

        return true;
      },
      COMMAND_PRIORITY_HIGH,
    );
  });

  useEffect(() => {
    setHasFocus(false);
    resetEditorStateHandler(editor);

    // Automatically focus the editor when switching to internal note mode
    // since it doesn't have unfocused mode
    if (inputAreaMode === ContactInteractionInputAreaMode.InternalNote) {
      editor.focus();
      setHasFocus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputAreaMode]);

  return null;
};

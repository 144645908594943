import { css } from 'styled-components';

export const legacyModalGlobalStyle = css`
  //Legacy dialogs height
  .bootstrap-dialog:not(.actionsheet-modal)
    .modal-dialog:not(#crm-contact-modal)
    .modal-content {
    overflow: scroll;
    padding-top: 65px;
    padding-bottom: 75px;
    margin-top: ${({ theme }) => theme.insets.top + 10}px;
    max-height: calc(
      100vh - 40px - ${({ theme }) => theme.insets.top}px -
        ${({ theme }) => theme.insets.bottom}px
    );
  }

  // Legacy bottom sheet dialogs extra bottom padding
  .bootstrap-dialog.actionsheet-modal
    .modal-dialog:not(#crm-contact-modal)
    .modal-content
    .actionsheet-content {
    padding-bottom: ${({ theme }) => theme.insets.bottom}px;
  }

  // Legacy dialog Header to float attached to top
  .bootstrap-dialog
    .modal-dialog:not(#crm-contact-modal)
    .modal-content
    .modal-header:first-child {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  // Legacy dialog footer to float attached to bottom
  .bootstrap-dialog .modal-dialog .modal-content .modal-footer:last-child {
    position: fixed;
    background: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  // Legacy contact modal height
  @media (max-width: 767px) {
    #crm-contact-modal.modal-dialog {
      margin-top: ${({ theme }) => theme.insets.top + 10}px;
      min-height: unset;
      height: calc(100vh - ${({ theme }) => theme.insets.top + 10}px);
    }

    #crm-contact-app #crm-contact-modal-actions {
      min-height: unset;
      height: unset;
      position: fixed;
      padding-bottom: ${({ theme }) => 20 + theme.insets.bottom}px;
    }
  }
`;

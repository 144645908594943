import { useEffect } from 'react';

export const useInvertScrolling = (params: {
  scrollerElement: HTMLElement | Window | null;
  isScrollerAvailable: boolean;
}) => {
  const { scrollerElement, isScrollerAvailable } = params;

  useEffect(() => {
    if (!scrollerElement) {
      return;
    }

    const invertedWheelEvent = (e: Event) => {
      if (e instanceof WheelEvent) {
        e.preventDefault();
        (scrollerElement as HTMLDivElement).scrollTop += -e.deltaY;
      }
    };

    scrollerElement.addEventListener('wheel', invertedWheelEvent, {
      passive: false,
    });

    return () => scrollerElement.removeEventListener('wheel', invertedWheelEvent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollerAvailable]);
};

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationConversationUnsnoozeArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { CONVERSATION_UNSNOOZE } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-list.query';
import { publishEvent } from 'src/utils/events/pub-sub';

export const useConversationUnsnooze = () => {
  const showNotification = useShowNotification();
  const { t } = useTranslation(['conversations']);

  const [unsnoozeConversation, { loading: isDiscardingSnooze }] = useMutation<
    Pick<Mutation, 'conversationUnsnooze'>,
    MutationConversationUnsnoozeArgs
  >(CONVERSATION_UNSNOOZE, {
    onCompleted: ({ conversationUnsnooze: { conversation } }) => {
      const { id } = conversation;

      publishEvent('CONVERSATION_UPDATED', { id });

      showNotification({
        type: 'success',
        customIcon: 'snooze',
        title: t(
          'conversations:notifications.mutations.changeSnoozeStatus.toUnSnoozed.success',
        ),
      });
    },
    onError: () => {
      showNotification({
        type: 'error',
        customIcon: 'snooze',
        title: t(
          'conversations:notifications.mutations.changeSnoozeStatus.toUnSnoozed.error',
        ),
      });
    },
  });

  return { unsnoozeConversation, isDiscardingSnooze };
};

import { set } from 'lodash';
import markdownIt from 'markdown-it';

const enable = ['linkify', 'link', 'emphasis', 'strikethrough', 'backticks'];
const md = markdownIt('zero', { breaks: false, linkify: true }).enable(enable);

// https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
const defaultRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']);
  } else {
    set(tokens, `${idx}.attrs.${aIndex}.${1}`, '_blank');
  }

  return defaultRender(tokens, idx, options, env, self);
};

export const parseLinksAndEscapeText = (text: string) => {
  return md.renderInline(text);
};

import { useCallback, useMemo } from 'react';
import { InstitutionInterface } from '@lgg/isomorphic';
import { addLeadingSlash } from 'src/utils/add-leading-slash';
import { getInstitutionPathPrefix } from 'src/utils/get-institution-path-prefix';

export function useInstitutionPathPrefix(institution: {
  id: number;
  type: InstitutionInterface['type'];
}) {
  const { id, type } = institution;
  const prefix = useMemo(() => getInstitutionPathPrefix(type, id), [id, type]);
  const addPrefix = useCallback(
    (subpath: string) => `${prefix}${addLeadingSlash(subpath)}`,
    [prefix],
  );
  const value = useMemo(() => ({ prefix, addPrefix }), [prefix, addPrefix]);

  return value;
}

import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import {
  ContactSetBlockedInput,
  Mutation,
  MutationContactSetBlockedArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { CONTACT_BLOCKING_FIELDS } from 'src/components/providers/apollo-provider-provider';

const CONTACT_SET_BLOCKED = gql`
  ${CONTACT_BLOCKING_FIELDS}
  mutation ContactSetBlocked($input: ContactSetBlockedInput!) {
    contactSetBlocked(input: $input) {
      contact {
        id
        ...ContactBlockingFieldsFragment
      }
    }
  }
`;

export const useContactSetBlocked = () => {
  const { t } = useTranslation(['contacts']);
  const showNotification = useShowNotification();
  const [mutate] = useMutation<
    Pick<Mutation, 'contactSetBlocked'>,
    MutationContactSetBlockedArgs
  >(CONTACT_SET_BLOCKED);

  return async (input: ContactSetBlockedInput) => {
    const { isBlocked } = input;

    await mutate({
      variables: { input },
      onError: () => {
        const message = isBlocked
          ? 'contacts:blockContact.notifications.block.error'
          : 'contacts:blockContact.notifications.unblock.error';

        showNotification({
          type: 'error',
          title: t(message),
        });
      },
      onCompleted: () => {
        const message = isBlocked
          ? 'contacts:blockContact.notifications.block.success'
          : 'contacts:blockContact.notifications.unblock.success';

        showNotification({
          type: 'success',
          title: t(message),
        });
      },
    });
  };
};

import React, { memo, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import {
  FormContainer,
  BackLink,
  SubmitButton,
} from 'src/components/pages/account/recover/components/shared';
import { AccountTextInput } from 'src/components/pages/account/shared';
import { ApiService } from 'src/services/http/api.service';

export const ResetPasswordForm = memo(() => {
  const { t } = useTranslation(['common', 'account']);
  const history = useHistory();
  const showNotification = useShowNotification();
  const apiService = useService(ApiService);

  const schema = useMemo(
    () =>
      zod.object({
        username: zod.string().nonempty(t('common:validations.fieldRequired')),
      }),
    [t],
  );

  type FormValues = zod.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const { triggerError } = useErrorHandling();

  const onSubmit = async ({ username }: FormValues) => {
    try {
      await apiService.post<any, { username: string }>(
        `/account/recover-password/initiate`,
        {
          username,
        },
      );

      showNotification({
        type: 'success',
        title: t('account:recoverPage.messages.emailSent'),
      });

      history.push('/account/login');
    } catch (e) {
      triggerError(e);
    }
  };

  return (
    <FormContainer data-lgg-id="reset-password-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <AccountTextInput
              autoComplete="username"
              label={t('common:username')}
              data-lgg-id="username"
              placeholder={t('common:enterUsername')}
              error={errors.username?.message}
              {...field}
            />
          )}
        />
        <SubmitButton data-lgg-id="submit" type="submit" loading={isSubmitting}>
          {t('account:recoverPage.resetPassword')}
        </SubmitButton>
      </form>
      <BackLink />
    </FormContainer>
  );
});

type ConversationPromotionalVideoEmbedProps = {
  width?: string;
  height?: string;
};

export const ConversationPromotionalVideoEmbed = ({
  width = '100%',
  height = '100%',
}: ConversationPromotionalVideoEmbedProps) => (
  <iframe
    width={width}
    height={height}
    src="https://www.youtube.com/embed/_Qpiw82YnxQ"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

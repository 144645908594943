import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomLocationState } from 'src/components/routing/lgg-switch';

export const useModalUrl = (params: { backgroundLocation: string }) => {
  const { backgroundLocation } = params;
  const history = useHistory<CustomLocationState>();
  const location = history.location;
  const shouldRedirect = !location.state?.background;

  useEffect(() => {
    if (shouldRedirect) {
      history.replace({
        pathname: `${location.pathname}`,
        state: {
          background: {
            pathname: backgroundLocation,
            state: {
              modalDefaultBackground: true,
            },
          },
          modalRoutes: [{ ...location }],
        },
      });
    }
  }, [backgroundLocation, history, location, shouldRedirect]);

  const goBack = useCallback(() => {
    const backgroundLocation = location.state?.background;
    const modalDefaultBackground = backgroundLocation.state?.modalDefaultBackground;
    const modalRoutesCount = location.state?.modalRoutes.length;

    if (modalDefaultBackground && modalRoutesCount === 1) {
      history.push(backgroundLocation.pathname);
    } else {
      history.goBack();
    }
  }, [history, location]);

  return { goBack };
};

import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  ContactInteractionAttachmentFile,
  ContactInteractionAttachmentUnion,
  ContactInteractionDirection,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchConversationAttachmentExhaustive } from '@lgg/isomorphic/utils/match-conversation-attachment';
import { useOutstandingItem } from 'src/components/general/display/inverted-virtuoso';
import { AudioAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/audio-attachment-item';
import { ContactsAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/contacts-attachment-item';
import { FileAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/file-attachment-item';
import { ImageAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/image-attachment-item';
import { LocationAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/location-attachment-item';
import { VideoAttachmentItem } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/video-attachment-item';
import {
  ContactInteractionDetails,
  ContactInteractionDetailsCaretVariant,
  ContactInteractionDetailsPopoverData,
} from 'src/components/pages/conversations/components/contact-interactions/items/contact-interaction-details';
import {
  ciHighlightedAnimation,
  messageBubbleMaxWidthStyle,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { ContactInteractionsWithAttachmentUnion } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

enum ContactInteractionAttachmentTestId {
  IMAGE = 'contact-interaction-attachment-image',
  VIDEO = 'contact-interaction-attachment-video',
  DOCUMENT = 'contact-interaction-attachment-document',
  AUDIO = 'contact-interaction-attachment-audio',
  LOCATION = 'contact-interaction-attachment-location',
  CONTACTS = 'contact-interaction-attachment-contacts',
}

const AttachmentContainer = styled.div<{
  direction: ContactInteractionDirection;
  addBubbleStyles?: boolean;
}>`
  ${messageBubbleMaxWidthStyle};
  margin-top: 5px;
  position: relative;
  width: ${(props) => (props.addBubbleStyles ? '100%' : 'max-content')};

  ${(props) =>
    props.direction === 'INBOUND' ? 'margin-right: auto;' : 'margin-left: auto;'}
  .attachment {
    max-width: 260px;
    max-height: 195px;
  }
`;

const AttachmentWrapper = styled.div<{
  direction: ContactInteractionDirection;
}>`
  border-radius: ${(props) =>
    props.direction === 'INBOUND' ? '8px 8px 8px 0' : '8px 8px 0 8px'};
  overflow: hidden;
  cursor: pointer;

  &.ci-highlighted {
    animation: ${ciHighlightedAnimation} 1.4s 4;
  }
`;

export type AttachmentItemProps = {
  attachment: ContactInteractionAttachmentUnion;
  detailsPopoverProps: ContactInteractionDetailsPopoverData;
  showDeliveryStatus: boolean;
  contactInteraction: ContactInteractionsWithAttachmentUnion;
};

export const AttachmentItem = memo<AttachmentItemProps>(
  ({ attachment, detailsPopoverProps, showDeliveryStatus, contactInteraction }) => {
    const { createdAt, direction, registrationType } = contactInteraction;
    const { formatDate } = useFormatDate();
    const attachmentDate = formatDate(new Date(createdAt), 'h:mm a');

    const [isPopoverOpened, setIsPopoverOpened] = useState<boolean>(false);

    const {
      visible: isInteractionDetailsModalVisible,
      setVisible: setInteractionDetailsModalVisibility,
    } = useVisible();
    const openInteractionDetailsModalVisibility = useCallback(() => {
      setInteractionDetailsModalVisibility(true);
      setIsPopoverOpened(true);
    }, [setInteractionDetailsModalVisibility, setIsPopoverOpened]);

    const { containerRef } = useOutstandingItem({
      visible: isPopoverOpened,
    });

    const type = matchConversationAttachmentExhaustive(attachment, {
      audio: (attachment) => attachment.type,
      image: (attachment) => attachment.type,
      sticker: (attachment) => attachment.type,
      video: (attachment) => attachment.type,
      document: (attachment) => attachment.type,
      location: () => 'LOCATION',
      contacts: () => 'CONTACTS',
    });

    const addBubbleStyles = ['AUDIO', 'DOCUMENT'].includes(type);
    const bubbleStylesCaretResolver = () =>
      direction === 'INBOUND'
        ? ContactInteractionDetailsCaretVariant.WHITE
        : ContactInteractionDetailsCaretVariant.BLUE;

    const caretVariant: ContactInteractionDetailsCaretVariant =
      matchConversationAttachmentExhaustive(attachment, {
        audio: bubbleStylesCaretResolver,
        image: bubbleStylesCaretResolver,
        sticker: bubbleStylesCaretResolver,
        video: bubbleStylesCaretResolver,
        document: bubbleStylesCaretResolver,
        location: bubbleStylesCaretResolver,
        contacts: bubbleStylesCaretResolver,
      });

    const resolveAttachmentItem = useCallback(() => {
      const baseAttachmentProps = {
        direction,
        attachmentDate,
      };

      const imageAttachmentHandler = (attachment: ContactInteractionAttachmentFile) => (
        <ImageAttachmentItem
          {...baseAttachmentProps}
          contactInteraction={contactInteraction}
          onOpenInteractionDetailsModalVisibility={openInteractionDetailsModalVisibility}
          attachment={attachment}
          showDeliveryStatus={showDeliveryStatus}
        />
      );

      return matchConversationAttachmentExhaustive(attachment, {
        audio: (attachment) => (
          <AudioAttachmentItem
            {...baseAttachmentProps}
            contactInteraction={contactInteraction}
            attachment={attachment}
            showDeliveryStatus={showDeliveryStatus}
          />
        ),
        image: imageAttachmentHandler,
        sticker: imageAttachmentHandler,
        video: (attachment) => (
          <VideoAttachmentItem
            {...baseAttachmentProps}
            contactInteraction={contactInteraction}
            onOpenInteractionDetailsModalVisibility={
              openInteractionDetailsModalVisibility
            }
            attachment={attachment}
            showDeliveryStatus={showDeliveryStatus}
          />
        ),
        document: (attachment) => (
          <FileAttachmentItem
            {...baseAttachmentProps}
            contactInteraction={contactInteraction}
            attachment={attachment}
            showDeliveryStatus={showDeliveryStatus}
          />
        ),
        location: (attachment) => (
          <LocationAttachmentItem
            {...baseAttachmentProps}
            contactInteraction={contactInteraction}
            attachment={attachment}
            showDeliveryStatus={showDeliveryStatus}
          />
        ),
        contacts: (attachment) => (
          <ContactsAttachmentItem
            {...baseAttachmentProps}
            contactInteraction={contactInteraction}
            attachment={attachment}
            showDeliveryStatus={showDeliveryStatus}
            onDetailsVisibilityChange={(isVisible: boolean) => {
              setIsPopoverOpened(isVisible);
            }}
          />
        ),
      });
    }, [
      contactInteraction,
      attachment,
      attachmentDate,
      direction,
      openInteractionDetailsModalVisibility,
      showDeliveryStatus,
    ]);

    const item = resolveAttachmentItem();

    if (!item) {
      return null;
    }

    return (
      <AttachmentContainer
        direction={direction}
        addBubbleStyles={addBubbleStyles}
        data-lgg-id={ContactInteractionAttachmentTestId[type]}
        ref={containerRef}
      >
        <AttachmentWrapper
          direction={direction}
          data-id={`attachment-${attachment.id}`}
          data-id-ci={`attachment-for-${contactInteraction.id}`}
        >
          <ContactInteractionDetails
            registrationType={registrationType}
            details={detailsPopoverProps}
            caretVariantOverride={caretVariant}
            direction={direction}
            setVisibility={(isVisible) => {
              setInteractionDetailsModalVisibility(isVisible);
              setIsPopoverOpened(isVisible);
            }}
            visible={isInteractionDetailsModalVisible}
            onClose={() => {
              setInteractionDetailsModalVisibility(false);
              setIsPopoverOpened(false);
            }}
          >
            {item}
          </ContactInteractionDetails>
        </AttachmentWrapper>
      </AttachmentContainer>
    );
  },
);

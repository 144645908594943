import React from 'react';
import AntTable, {
  ColumnType as AntColumnType,
  TableProps as AntTableProps,
} from 'antd/lib/table';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { TableBodyPlaceholder } from 'src/components/general/feedback/loading-placeholders';

const StyledTable = styled(AntTable)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};

  ${up('md')} {
    border-top: 0;
    border-radius: 6px;
  }

  .ant-table-thead {
    height: 70px;

    .ant-table-cell {
      border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
      background-color: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.font.medium};
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 13px;
      letter-spacing: 0.33px;
      text-align: left;
      color: ${({ theme }) => theme.colors.flint};

      &.ant-table-column-has-sorters {
        &:hover {
          background: inherit;
        }
      }
    }

    .ant-table-column-sorter {
      margin-left: 10px;
      margin-top: 0;
      height: 100%;

      &.ant-table-column-sorter-full {
        display: flex;
      }

      .ant-table-column-sorter-up + .ant-table-column-sorter-down {
        margin-top: -3px;
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        svg {
          font-size: 8px;
          transform: scale(1.455, 1.207);

          path {
            fill: ${({ theme }) => theme.colors.geyser};
            opacity: 0.5;
          }
        }

        &.active {
          svg path {
            opacity: 1;
          }
        }
      }
    }
  }

  .ant-table-thead .ant-table-cell {
    &:first-child {
      padding-left: 20px;

      ${up('md')} {
        padding-left: 30px;
      }

      & .ant-table-column-sorters {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-right: 20px;

      ${up('md')} {
        padding-right: 30px;
      }

      & .ant-table-column-sorters {
        padding-right: 0;
      }
    }
  }

  .ant-table-tbody {
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.storm};

    .ant-table-row {
      height: 60px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};

      &:hover {
        background-color: ${({ theme }) => theme.colors.alabaster};
      }

      .ant-table-cell {
        border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
        padding: 15px 20px;

        ${up('md')} {
          padding: 12px 15px;
        }

        &:first-child {
          padding-left: 20px;

          ${up('md')} {
            padding-left: 30px;
          }
        }

        &:last-child {
          padding-right: 20px;

          ${up('md')} {
            padding-right: 30px;
          }
        }

        &.ant-table-column-sort {
          background: inherit;
        }
      }
    }
  }
` as typeof AntTable;

const ControlledWidthContainer = styled.div<{
  maxWidth?: string;
  minWidth?: string;
  wrapContent?: boolean;
}>`
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  min-width: ${({ minWidth }) => minWidth ?? 'unset'};

  ${({ wrapContent }) =>
    !wrapContent &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
`;

export type TableColumns<T> = AntColumnType<T> & {
  contentMaxWidth?: string;
  contentMinWidth?: string;
  wrapContent?: boolean;
};

export type TableProps<T> = Omit<AntTableProps<T>, 'columns' | 'loading'> & {
  columns: TableColumns<T>[];
  loading: boolean;
};

export const Table = <T extends object = any>({
  columns,
  loading = false,
  ...rest
}: TableProps<T>) => {
  const updatedColumns: AntColumnType<T>[] = columns?.map((column) => {
    const {
      contentMaxWidth,
      contentMinWidth,
      wrapContent = false,
      render: defaultRender,
      ...columnConfig
    } = column;

    return {
      ...columnConfig,
      render: (value, record, index) => (
        <ControlledWidthContainer
          maxWidth={contentMaxWidth}
          minWidth={contentMinWidth}
          wrapContent={wrapContent}
        >
          {defaultRender ? defaultRender(value, record, index) : value}
        </ControlledWidthContainer>
      ),
    };
  });

  return loading ? (
    <TableBodyPlaceholder />
  ) : (
    <StyledTable {...rest} columns={updatedColumns} />
  );
};

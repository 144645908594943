import React, { memo } from 'react';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { ContactInteractionDetailFooterOption } from 'src/components/pages/conversations/components/contact-interactions/items/contact-interaction-details';
import { useVisible } from 'src/hooks/use-visible';

const MUTATION_DEV_CHANNELS_WHATSAPP_UPDATE_LEADHISTORY_LOG_STATUS = gql`
  mutation DevChannelsWhatsappUpdateLeadHistoryLogStatus(
    $status: devWhatsappMessageStatus!
    $leadHistoryLogId: ID!
  ) {
    devChannelsWhatsappUpdateLeadHistoryLogStatus(
      status: $status
      leadHistoryLogId: $leadHistoryLogId
    )
  }
`;

const StatusOption = styled.div`
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  padding: 10px;
`;

type ChangeContactInteractionStatusModalProps = {
  contactInteractionLogId: string;
  onClick: () => void;
};

export const ChangeContactInteractionStatusModal =
  memo<ChangeContactInteractionStatusModalProps>(
    ({ contactInteractionLogId, onClick }) => {
      const { show, close, visible } = useVisible();
      const showNotification = useShowNotification();
      const [updateStatus] = useMutation(
        MUTATION_DEV_CHANNELS_WHATSAPP_UPDATE_LEADHISTORY_LOG_STATUS,
      );

      const statuses = [
        { status: 'sent', label: 'Sent' },
        { status: 'delivered', label: 'Delivered' },
        { status: 'read', label: 'Read' },
        { status: 'failed', label: 'Failed' },
      ];

      return (
        <>
          <ContactInteractionDetailFooterOption
            onClick={() => {
              show();
              onClick();
            }}
            data-lgg-id="contact-interaction-details-debug-notification-button"
          >
            <Icon type="debug" />
            Change status
          </ContactInteractionDetailFooterOption>
          <LggModal onClose={close} visible={visible} closeIcon={<div />}>
            <Title>Change status to:</Title>
            {statuses.map(({ status, label }) => (
              <StatusOption
                key={status}
                onClick={() => {
                  void updateStatus({
                    variables: {
                      status,
                      leadHistoryLogId: Number(contactInteractionLogId),
                    },
                    onCompleted: () => {
                      showNotification({
                        title: 'Dev Tools',
                        message: 'Whatsapp contact interaction status updated!',
                        type: 'success',
                      });
                    },
                  });
                  close();
                }}
              >
                {label}
              </StatusOption>
            ))}
          </LggModal>
        </>
      );
    },
  );

import { useMemo } from 'react';
import { groupBy, ValueIteratee } from 'lodash';

export const useContactInteractionsDateGroupHeader = <TData>(props: {
  data: TData[] | undefined;
  groupResolver: ValueIteratee<TData>;
  getItemId: (item: TData) => string | number;
}) => {
  const { data, groupResolver, getItemId } = props;
  const dataGroups = useMemo(() => groupBy(data, groupResolver), [data, groupResolver]);
  const groups = useMemo(() => Object.keys(dataGroups), [dataGroups]);
  const groupsIndexes = useMemo(
    () =>
      Object.fromEntries(
        groups.map((key) => {
          const group = dataGroups[key];

          return [getItemId(group[group.length - 1]), key];
        }),
      ),
    [dataGroups, getItemId, groups],
  );

  return {
    groups,
    groupsIndexes,
  };
};

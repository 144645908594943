export function useLegacyFiltersFromUrl(): string[] {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce((values: string[], [key, value]): string[] => {
      if (key.indexOf('q[advanced]') !== 0 || value === '') {
        return values;
      }

      values.push(value);
      return values;
    }, []);
}

import React, { createContext, ReactNode, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { useVisible, VisibilityHandler } from 'src/hooks/use-visible';

interface ProfileSettingsContextProps {
  visibilityHandler: VisibilityHandler;
  showCancelConfirm: (isDirty: boolean, reset: () => void) => void;
}

export const ProfileSettingsContext = createContext<
  ProfileSettingsContextProps | undefined
>(undefined);

export const ProfileSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const visibilityHandler = useVisible();
  const showConfirmationModal = useShowConfirmationModal();
  const { t } = useTranslation(['user']);

  const showCancelConfirm = useCallback(
    (isDirty: boolean, reset: () => void) => {
      if (!isDirty) {
        visibilityHandler.close();
        return;
      }

      showConfirmationModal({
        title: t('user:profileSettings.confirmModal.title'),
        message: t('user:profileSettings.confirmModal.content'),
        confirmButtonText: t('user:profileSettings.confirmModal.buttons.ok'),
        confirmButtonType: 'delete',
        testId: 'cancel-profile-settings-confirmation-modal',
        onConfirm: () => {
          reset();
          visibilityHandler.close();
        },
      });
    },
    [t, showConfirmationModal, visibilityHandler],
  );

  return (
    <ProfileSettingsContext.Provider value={{ visibilityHandler, showCancelConfirm }}>
      {children}
    </ProfileSettingsContext.Provider>
  );
};

export const useProfileSettings = () => {
  const profileSettings = useContext(ProfileSettingsContext);

  if (profileSettings === undefined) {
    throw new Error('useProfileSettings must be used within a ProfileSettingsProvider');
  }

  return profileSettings;
};

import React, { memo, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { zodResolver } from '@hookform/resolvers/zod';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import * as zod from 'zod';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Card } from 'src/components/general/display/card';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { AccountTextInput } from 'src/components/pages/account/shared';
import { useQueryParams } from 'src/hooks/use-query-params';
import { ApiService } from 'src/services/http/api.service';

const SubmitButton = styled(LggButton)`
  margin-top: 22px;
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.colors.globalBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38;
  margin-top: 30px;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.globalBlue};
  }

  ${up('md')} {
    text-align: left;
  }
`;

const StyledCard = styled(Card)`
  ${({ theme }) => theme.cardsTheme.default};
  margin: 0 20px;
  padding: 30px 20px;

  ${up('md')} {
    margin: 0 auto;
    max-width: 570px;
    padding: 50px 105px 57px;
  }
`;

const CardTitle = styled.h1`
  color: ${({ theme }) => theme.colors.darkCarbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.48px;
  line-height: 1.33;
  margin-bottom: 30px;
  text-align: center;

  ${up('md')} {
    font-size: 26px;
  }
`;

export const ChangePasswordForm = memo(() => {
  const { t } = useTranslation(['common', 'account']);
  const history = useHistory();
  const showNotification = useShowNotification();

  const schema = useMemo(
    () =>
      zod
        .object({
          password: zod.string().regex(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/,
            t('account:validations.invalidPassword', {
              label: t('account:recoverPage.newPassword'),
            }),
          ),
          confirmPassword: zod.string(),
        })
        .refine(({ password, confirmPassword }) => password === confirmPassword, {
          message: t('account:validations.passwordMismatch'),
          path: ['confirmPassword'],
        }),
    [t],
  );

  type FormValues = zod.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const queryParams = useQueryParams();
  const apiService = useService(ApiService);
  const { triggerError } = useErrorHandling();

  const onSubmit = async ({ password }: FormValues) => {
    const requestKey = queryParams['key']?.toString();
    const requestUsername = queryParams['username']?.toString();

    try {
      if (requestUsername && requestKey) {
        await apiService.post<
          any,
          { username: string; 'new-password': string; key: string }
        >(`/account/recover-password/finalize`, {
          'new-password': password,
          key: requestKey,
          username: requestUsername,
        });

        showNotification({
          type: 'success',
          title: t('account:recoverPage.messages.passwordUpdated'),
        });

        history.push('/account/login');
      }
    } catch (e) {
      triggerError(e);
    }
  };

  return (
    <StyledCard>
      <CardTitle>{t('account:recoverPage.changeYourPassword')}</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <AccountTextInput
              label={t('account:recoverPage.newPassword')}
              placeholder={t('account:recoverPage.newPassword')}
              data-lgg-id="password"
              type="password"
              error={errors.password?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <AccountTextInput
              type="password"
              label={t('account:recoverPage.confirmNewPassword')}
              data-lgg-id="confirm-password"
              placeholder={t('account:recoverPage.confirmNewPassword')}
              error={errors.confirmPassword?.message}
              {...field}
            />
          )}
        />
        <SubmitButton type="submit" data-lgg-id="submit" loading={isSubmitting}>
          {t('account:recoverPage.changeYourPassword')}
        </SubmitButton>
      </form>
    </StyledCard>
  );
});

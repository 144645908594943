import { useHistory, useLocation } from 'react-router-dom';
import { viewCodeQueryParamKey } from './use-legacy-params-for-dashboard-query';

export const usePushUrlWithViewCode = <T>() => {
  const history = useHistory();
  const location = useLocation();

  const pushUrlWithViewCode = (viewCode: T) => {
    history.push(`${location.pathname}?${viewCodeQueryParamKey}=${viewCode}`);
  };

  return { pushUrlWithViewCode };
};

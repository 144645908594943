import { Trans } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactPhoneType,
  PhoneNumber,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';

export type ContactPhoneOption = PhoneNumber & { type: ContactPhoneType };

export enum ContactInteractionInputAreaMode {
  Reply = 'Reply',
  InternalNote = 'InternalNote',
}

export const InputAreaContent = styled(FlexColumn)`
  position: relative;
  min-height: 50px;
  max-height: 100%;
  height: max-content;
  padding-bottom: 5px;

  ${up('md')} {
    min-height: 54px;
  }
`;

export const InputAreaContainer = styled(FlexColumn)<{
  backgroundColor?: ColorPaletteItem;
}>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.white};
  max-height: 100%;
  // Using '6' so it can be over the contact-interaction-list sticky header
  z-index: 6;
  padding-bottom: ${({ theme }) => theme.insets.bottom}px;

  &.full-height {
    height: calc(100% - ${({ theme }) => theme.insets.top + 5}px);
    position: fixed;
    width: 100%;
    top: ${({ theme }) => theme.insets.top + 5}px;

    &,
    & > :first-child {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    ${InputAreaContent} {
      height: 100%;
      justify-content: space-between;
    }
  }
`;

type ChannelError =
  | 'CHANNELS:FACEBOOK_ACCESS_TOKEN_EXPIRED'
  | 'CHANNELS:FACEBOOK_MESSENGER_SEND_TIME_EXPIRED'
  | 'CHANNELS:FACEBOOK_MESSENGER_PAGE_PUBLISHING_AUTHORIZATION_NEEDED'
  | 'CHANNELS:INVALID_DESTINATION_PHONE_NUMBER';

export const getChannelErrorMessage = (code: ChannelError) => {
  switch (code) {
    case 'CHANNELS:FACEBOOK_ACCESS_TOKEN_EXPIRED': {
      return <Trans i18nKey="errors:channelErrors.facebook.accessTokenExpired" />;
    }
    case 'CHANNELS:FACEBOOK_MESSENGER_SEND_TIME_EXPIRED': {
      return (
        <>
          <Trans i18nKey="errors:channelErrors.facebook.sendTimeExpired.message" />
          <a
            href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/#standard_messaging"
            target="_blank"
            rel="noreferrer"
          >
            <Trans i18nKey="errors:channelErrors.facebook.sendTimeExpired.linkText" />
          </a>
        </>
      );
    }
    case 'CHANNELS:FACEBOOK_MESSENGER_PAGE_PUBLISHING_AUTHORIZATION_NEEDED': {
      return (
        <>
          <Trans i18nKey="errors:channelErrors.facebook.pagePublishingAuthorizationNeeded.message" />
          <a
            href="https://www.facebook.com/help/1939753742723975"
            target="_blank"
            rel="noreferrer"
          >
            <Trans i18nKey="errors:channelErrors.facebook.pagePublishingAuthorizationNeeded.linkText" />
          </a>
        </>
      );
    }
    case 'CHANNELS:INVALID_DESTINATION_PHONE_NUMBER': {
      return <Trans i18nKey="errors:channelErrors.sms.invalidDestinationPhoneNumber" />;
    }
    default: {
      return <Trans i18nKey="errors:channelErrors.default" />;
    }
  }
};

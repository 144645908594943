import { CampaignWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { useCampaigns } from 'src/components/domain/campaign/hooks/use-campaigns';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseCampaignListForSelectReturn = {
  loadingCampaignOptions: boolean;
  campaignOptions: SelectOption<number>[];
};

export const useCampaignListForSelect = (
  where?: CampaignWhereInput,
): UseCampaignListForSelectReturn => {
  const { campaigns, loading } = useCampaigns(where);

  return {
    campaignOptions: campaigns ? campaigns.map(entityToSelectOptionMapper) : [],
    loadingCampaignOptions: loading,
  };
};

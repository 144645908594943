import React from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { StyledButtonV2 } from 'src/components/general/button/lgg-button';
import { Select } from 'src/components/general/inputs/select/select';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { Icon } from '../../icon';

export const InputContainer = styled(FlexRow)`
  flex-wrap: wrap;
  flex: 1;
  height: max-content;
  max-height: 105px;

  & > * {
    margin-bottom: 15px;

    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      flex: 1;

      &:last-child {
        &:nth-child(3) {
          margin-left: 15px;
        }
      }
    }
  }

  ${up('md')} {
    flex-wrap: nowrap;
    position: relative;

    & > * {
      margin: 0px 5px 0px 0px;
      flex: unset;

      &:not(:first-child) {
        flex: unset;

        &:last-child {
          &:nth-child(3) {
            margin-left: 0;
          }
        }
      }

      &:only-child {
        flex: 1;
        max-width: unset;
      }
    }
  }
`;

const FooterButton = styled(StyledButtonV2)`
  line-height: 17px;
  font-size: 14px;

  ${up('md')} {
    height: 30px;
    width: 62px;
    line-height: 14px;
    font-size: 12px;
  }
`;

const ActionButtonsContainer = styled(FlexRow)`
  padding: 15px 20px;
  width: 100%;

  ${FooterButton} {
    flex: 1;
  }

  ${FooterButton} + ${FooterButton} {
    margin-left: 20px;
  }

  ${up('md')} {
    justify-content: flex-end;
    padding: 0;
    width: max-content;

    ${FooterButton} {
      flex: unset;
    }

    ${FooterButton} + ${FooterButton} {
      margin-left: 10px;
    }
  }
`;

const CustomDatePickerFooterContainer = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: space-between;

  ${up('md')} {
    justify-content: flex-end;
    padding: 11px;
  }
`;

const DatePreviewContainer = styled(FlexRow)`
  align-items: center;
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
  width: 100%;

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
`;

const PreviewCalendarIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

export const CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT = 'MMM D, YYYY';

type CustomPickerDatePreviewProps = {
  previewText: string;
  'data-lgg-id': string;
};

export const CustomPickerDatePreview = ({
  previewText,
  ...rest
}: CustomPickerDatePreviewProps) => {
  return (
    <DatePreviewContainer {...rest}>
      <PreviewCalendarIcon type="calendarNoPadding" />
      {previewText}
    </DatePreviewContainer>
  );
};

type CustomDatePickerPopoverFooterProps = {
  topContent?: JSX.Element | null;
  onClear: VoidFunction;
};

export const CustomDatePickerPopoverFooter = ({
  topContent,
  onClear,
}: CustomDatePickerPopoverFooterProps) => {
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <CustomDatePickerFooterContainer>
      {topContent}
      <ActionButtonsContainer>
        <FooterButton
          variant="default"
          type="button"
          size="regular"
          data-lgg-id="clear-custom-date-filter"
          onClick={onClear}
        >
          {t('common:clear')}
        </FooterButton>
        <FooterButton
          type="submit"
          variant="primary"
          size="regular"
          data-lgg-id="apply-custom-date-filter"
        >
          {breakpointUpMd ? t('common:done') : t('common:set')}
        </FooterButton>
      </ActionButtonsContainer>
    </CustomDatePickerFooterContainer>
  );
};

export const CustomDatePickerSelect = styled(Select)`
  .lgg-select__control {
    height: 38px;
    max-height: 38px;
    min-height: 34px;

    ${up('md')} {
      height: 34px;
      padding: 7px 0px 7px 10px;
    }
  }

  .lgg-select__indicators {
    padding: 0 5px;

    ${up('md')} {
      padding: 0 10px 0 0px;
    }
  }

  ${up('md')} {
    max-width: 120px;

    .lgg-select__menu {
      width: 160px;
    }
  }
` as typeof Select;

export const CustomDatePickerInputPlaceholder = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
  opacity: 0.7;
`;

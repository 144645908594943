import { memo, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, DROP_COMMAND, PASTE_COMMAND } from 'lexical';
import { v4 as uuid } from 'uuid';
import { InputUploadFile } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-area-attachment-options';

type DragDropPluginProps = {
  onFileDropped: ValueChanged<InputUploadFile[]>;
};

const handlePasteDnDFile = async (
  e: DragEvent | ClipboardEvent,
): Promise<InputUploadFile[] | undefined> => {
  e.preventDefault();
  const fileList =
    e instanceof ClipboardEvent ? e.clipboardData?.files : e.dataTransfer?.files;

  if (fileList) {
    const filesCount = fileList.length;
    const files: InputUploadFile[] = [];

    for (let i = 0; i < filesCount; i++) {
      const currentFile = fileList.item(i);
      if (currentFile) {
        files.push({
          file: currentFile,
          id: uuid(),
          attachmentType: null,
          url: null,
          status: 'VALIDATING',
          isRecentFile: false,
        });
      }
    }

    return files;
  }
};

export const PasteDnDFilePlugin = memo<DragDropPluginProps>(({ onFileDropped }): null => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const dropCommandCleanUp = editor.registerCommand(
      DROP_COMMAND,
      (e) => {
        void (async () => {
          const files = await handlePasteDnDFile(e);

          if (files) {
            onFileDropped(files);
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW,
    );

    const pasteCommandCleanUp = editor.registerCommand(
      PASTE_COMMAND,
      (e) => {
        void (async () => {
          if (!(e instanceof ClipboardEvent)) {
            return;
          }

          const files = await handlePasteDnDFile(e);

          if (files) {
            onFileDropped(files);
          }
        })();
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      dropCommandCleanUp();
      pasteCommandCleanUp();
    };
  }, [editor, onFileDropped]);

  return null;
});

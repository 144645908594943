import React, { memo, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useService } from 'react-service-locator';
import { zodResolver } from '@hookform/resolvers/zod';
import Animate from 'rc-animate';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import * as zod from 'zod';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Card } from 'src/components/general/display/card';
import { Toast } from 'src/components/general/feedback/toast';
import { Checkbox } from 'src/components/general/inputs/checkbox';
import { AccountTextInput } from 'src/components/pages/account/shared';
import { useQueryParams } from 'src/hooks/use-query-params';
import { SessionService } from 'src/services/session.service';
import { AuthenticationError } from 'src/utils/errors/authentication-error';

const SubmitButton = styled(LggButton)`
  margin-top: 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 6px;

  span:not(.ant-checkbox) {
    line-height: 20px;
  }

  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-inner::after {
    width: 30%;
    left: 28%;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.colors.globalBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38;
  margin-top: 30px;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.globalBlue};
  }

  ${up('md')} {
    text-align: left;
  }
`;

const StyledCard = styled(Card)`
  background-color: transparent;
  border-radius: unset;
  box-shadow: none;
  padding: 24px 30px 51px;
  z-index: 1;
  height: 100%;

  ${up('sm')} {
    margin: 0 auto;
    max-width: 315px;
    padding: 24px 0 51px;
    width: 100%;
  }

  ${up('md')} {
    ${({ theme }) => theme.cardsTheme.default};
    margin: 0 auto;
    max-width: 570px;
    padding: 50px 105px 57px;
    height: auto;
  }
`;

const CardTitle = styled.h1`
  color: ${({ theme }) => theme.colors.darkCarbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.48px;
  line-height: 1.33;
  margin-bottom: 30px;
  text-align: left;

  ${up('sm')} {
    text-align: center;
  }

  ${up('md')} {
    font-size: 26px;
    text-align: center;
    font-family: ${({ theme }) => theme.font.regular};
  }
`;

const StyledToast = styled(Toast)`
  box-shadow: unset;
  margin-bottom: 20px;
`;

export const SignInForm = memo(() => {
  const { t } = useTranslation(['common', 'account']);
  const { triggerError } = useErrorHandling();
  const schema = useMemo(
    () =>
      zod.object({
        username: zod.string().nonempty(t('common:validations.fieldRequired')),
        password: zod.string().nonempty(t('common:validations.fieldRequired')),
        staySignedIn: zod.boolean(),
      }),
    [t],
  );

  type FormValues = zod.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const sessionService = useService(SessionService);
  const [serverError, setServerError] = useState<String | null>(null);

  const queryParams = useQueryParams();

  const onSubmit = async ({ username, password, staySignedIn }: FormValues) => {
    setServerError(null);

    try {
      if (username === 'unkle') {
        throw new Error('error from frontend');
      }
      await sessionService.login(username, password, { staySignedIn });
      const target = queryParams['target'] ? (queryParams['target'] as string) : '/';
      window.location.assign(target);
    } catch (e) {
      if (e instanceof AuthenticationError && e.data) {
        switch (e.data.code) {
          case 'AUTHENTICATION:INACTIVE_ACCOUNT':
            return setServerError(t('account:signInPage.errors.inactiveAccount'));
          case 'AUTHENTICATION:INVALID_CREDENTIALS':
            return setServerError(t('account:signInPage.errors.invalidCredentials'));
        }
      }

      triggerError(e);
    }
  };

  return (
    <StyledCard>
      <CardTitle>{t('account:signInPage.signIn')}</CardTitle>
      <Animate transitionName="ant-fade">
        {serverError ? (
          <StyledToast data-lgg-id="error-container" key="server-error" type="error">
            {serverError}
          </StyledToast>
        ) : null}
      </Animate>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <AccountTextInput
              data-lgg-id="username"
              autoComplete="username"
              autoCapitalize="off"
              label={t('common:username')}
              placeholder={t('common:enterUsername')}
              error={errors.username?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <AccountTextInput
              data-lgg-id="password"
              type="password"
              autoComplete="current-password"
              label={t('common:password')}
              placeholder={t('common:enterPassword')}
              error={errors.password?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="staySignedIn"
          defaultValue={true}
          render={({ field }) => (
            <StyledCheckbox
              data-lgg-id="stay-signed-in"
              checked={field.value}
              onChange={(e) => setValue('staySignedIn', e.target.checked)}
            >
              {t('account:signInPage.staySignedIn')}
            </StyledCheckbox>
          )}
        />
        <SubmitButton data-lgg-id="submit" type="submit" loading={isSubmitting}>
          {t('account:signInPage.signIn')}
        </SubmitButton>
      </form>
      <ForgotPasswordLink to="/account/recover" data-lgg-id="recover-link">
        {t('account:signInPage.forgotPasswordOrUsername')}
      </ForgotPasswordLink>
    </StyledCard>
  );
});

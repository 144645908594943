import { useContactStageStatuses } from 'src/components/domain/contacts/hooks/use-contact-stage-statuses';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { stageToStatusSelectGroupOptionMapper } from 'src/components/general/inputs/select/mappers/stage-to-status-select-group-option-mapper';
import {
  GroupedSelectOption,
  SelectOption,
} from 'src/components/general/inputs/select/select';

type UseContactStageStatusOptionsReturn = {
  loading: boolean;
  stageOptions?: SelectOption<string>[];
  statusGroupOptions?: GroupedSelectOption<string>[];
};

export const useContactStageStatusOptions = (): UseContactStageStatusOptionsReturn => {
  const { stages, loading } = useContactStageStatuses();

  if (loading) return { loading };

  const stageOptions = stages ? stages.map(entityToSelectOptionMapper) : [];
  const statusGroupOptions = stages
    ? stages.map(stageToStatusSelectGroupOptionMapper)
    : [];

  return {
    stageOptions,
    statusGroupOptions,
    loading,
  };
};

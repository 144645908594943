import { orderBy } from 'lodash';
import { ContactRelatedNoteUnion } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortContactRelatedNotes = (notes: ContactRelatedNoteUnion[]) => {
  return orderBy(
    notes,
    [
      (note) => {
        return new Date(note.createdAt).getTime();
      },
      (note) => {
        return note.id;
      },
    ],
    ['desc', 'asc'],
  );
};

import React, { memo, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { LggButton } from 'src/components/general/button/lgg-button';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import {
  ModalContent,
  ModalMessage,
  ModalTitle,
} from 'src/components/general/modals/modals.shared';
import { FlexRow } from 'src/components/layout/flex-row';

const ModalButton = styled(LggButton)`
  flex: 1;

  & + & {
    margin-left: 15px;
  }
`;

const StyledModalMessage = styled(ModalMessage)`
  padding-bottom: 30px;
`;

const ButtonsBar = styled(FlexRow)`
  width: 100%;
  padding: 15px 20px;
`;

type ConfirmationModalProps = {
  visible: boolean;
  onClose: VoidFunction;
  title: string;
  message: string;
  icon: React.ReactNode;
  buttons: {
    ok: {
      onClick: MouseEventHandler<HTMLButtonElement>;
      text: string;
    };
    cancel: {
      onClick?: MouseEventHandler<HTMLButtonElement>;
      text: string;
    };
  };
};

export const ConfirmationModal = memo<ConfirmationModalProps>(
  ({ visible, buttons, onClose, title, message, icon }) => {
    return (
      <LggModal
        maskClosable={false}
        visible={visible}
        onClose={onClose}
        closeIcon={<div />}
        width="calc(100% - 40px)"
      >
        <ModalContent>
          {icon}
          <ModalTitle>{title}</ModalTitle>
          <StyledModalMessage>{message}</StyledModalMessage>
          <ButtonsBar>
            <ModalButton
              onClick={(e) => {
                onClose();

                if (buttons.cancel.onClick) {
                  buttons.cancel.onClick(e);
                }
              }}
              variant="tertiary"
            >
              {buttons.cancel.text}
            </ModalButton>
            <ModalButton
              onClick={(e) => {
                onClose();
                buttons.ok.onClick(e);
              }}
            >
              {buttons.ok.text}
            </ModalButton>
          </ButtonsBar>
        </ModalContent>
      </LggModal>
    );
  },
);

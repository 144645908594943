import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ContactInteractionAttachmentFileType } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggAudioPlayer } from 'src/components/general/display/lgg-audio-player';
import { MediaPreviewModal } from 'src/components/general/display/media-preview-modal';

type AttachmentItemProps = {
  type: ContactInteractionAttachmentFileType;
  url: string;
  name?: string | null;
  visible: boolean;
  onClose: VoidFunction;
  onShowDetails?: VoidFunction;
  className?: string;
  mimeType?: string | null;
  testId?: string;
};

const VideoPlayer = styled.video`
  margin-bottom: -1px;
  max-height: 80vh;
`;

const AudioPlayerContainer = styled.div`
  width: 255px;

  & > * {
    margin: 0;
  }

  ${up('md')} {
    width: 371px;
  }
`;

const DocumentContainer = styled.div`
  width: 90vh;
  height: 85vh;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledImage = styled.img`
  max-height: 80vh;
`;

export const AttachmentPreviewModal = memo<AttachmentItemProps>(
  ({ name, url, type, onClose, onShowDetails, visible, className, testId }) => {
    const resolveAttachmentPreviewItem = () => {
      switch (type) {
        case 'IMAGE':
        case 'STICKER': {
          return (
            <StyledImage src={url} alt={name ?? ''} data-lgg-id={`${testId}-image`} />
          );
        }
        case 'VIDEO': {
          return (
            <VideoPlayer autoPlay controls data-lgg-id={`${testId}-video`}>
              <source src={url} />
            </VideoPlayer>
          );
        }
        case 'AUDIO': {
          return (
            <AudioPlayerContainer>
              <LggAudioPlayer url={url} testId={testId} allowDownload />
            </AudioPlayerContainer>
          );
        }
        case 'DOCUMENT': {
          return (
            <DocumentContainer>
              <iframe
                src={url}
                data-lgg-id={`${testId}-document`}
                title={`${testId}-document`}
              />
            </DocumentContainer>
          );
        }
      }
    };

    return (
      <MediaPreviewModal
        visible={visible}
        onClose={onClose}
        onShowDetails={onShowDetails}
        className={className}
      >
        {resolveAttachmentPreviewItem()}
      </MediaPreviewModal>
    );
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { WhatsappTemplate } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const WhatsappTemplateListItemContainer = styled(FlexColumn)`
  border-top: 1px solid ${({ theme }) => theme.colors.porcelain};
  cursor: pointer;
  padding: 10px;

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: inherit;
    padding: 5px;
  }
`;

const TemplateListItemTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 17px;
  margin: 0 5px 0 0;
`;

const TemplateListItemPreview = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin-top: 10px;
`;

const TemplateLanguageTitle = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
  text-transform: capitalize;
`;

const TemplateLanguageValue = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
`;

const WhatsappTemplateListItemHeader = styled.div`
  display: flex;
  flex-direction: column;

  ${up('md')} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const UnavailableTemplateTag = styled(Tag)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryGold10};
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryGold60};
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 8px;
  height: 12px;
  letter-spacing: -0.16px;
  line-height: 10px;
  padding: 1px 4px;
  text-transform: uppercase;
`;

const WhatsappTemplateTitleContainer = styled(FlexRow)`
  align-items: center;
  margin: 0 0 6px 0;

  ${up('md')} {
    margin: 0;
  }
`;

const LanguageContainer = styled(FlexRow)``;

const WhatsappTemplateListItemInnerContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid white;
  padding: 15px;

  &.selected {
    background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
    border: 1px solid ${({ theme }) => theme.colors.secondaryTopaz60};
  }

  &.unavailable {
    ${TemplateListItemTitle},
    ${TemplateListItemPreview},
    ${LanguageContainer} {
      opacity: 0.55;
    }
  }
`;

const TemplateStatusMessageContainer = styled(FlexRow)`
  margin-top: 10px;
  align-items: center;
`;

const TemplateStatusMessageIcon = styled(Icon)`
  margin-right: 5px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const TemplateStatusMessageText = styled.span`
  font-size: 11px;
  line-height: 13px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.secondaryGoldDark};

  ${up('md')} {
    color: ${({ theme }) => theme.colors.storm};
    line-height: 10px;
  }
`;

const TemplateStatusMessage = ({ message, ...rest }: { message: string }) => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <TemplateStatusMessageContainer {...rest}>
      {breakpointUpMd && <TemplateStatusMessageIcon type="warningAlertIcon" />}
      <TemplateStatusMessageText>{message}</TemplateStatusMessageText>
    </TemplateStatusMessageContainer>
  );
};

type WhatsappTemplateListItemProps = {
  selected?: boolean;
  onClick?: VoidFunction;
  template: WhatsappTemplate;
};

export const WhatsappTemplateListItem = ({
  selected = false,
  onClick,
  template,
}: WhatsappTemplateListItemProps) => {
  const { t } = useTranslation(['common', 'conversations']);

  const getLanguageNameFromAbbreviation = (abbreviation: string) => {
    switch (abbreviation) {
      case 'en':
      case 'en_GB':
      case 'en_US': {
        return t('common:languages.english');
      }
      case 'es':
      case 'es_AR':
      case 'es_ES':
      case 'es_MX': {
        return t('common:languages.spanish');
      }
      default: {
        return '';
      }
    }
  };

  const templatePreview =
    template.components.find(
      (component) => component.__typename === 'WhatsappTemplateComponentBody',
    )?.text ?? '';

  const { isValid, invalidReasonCode } = template.status;

  const showUnsupportedError = invalidReasonCode === 'COMPONENTS_NOT_SUPPORTED';

  const unavailable = !isValid;

  return (
    <WhatsappTemplateListItemContainer
      onClick={unavailable ? undefined : onClick}
      data-lgg-id={`whatsapp-template-list-item-${template.id}`}
    >
      <WhatsappTemplateListItemInnerContainer className={c({ selected, unavailable })}>
        <WhatsappTemplateListItemHeader>
          <WhatsappTemplateTitleContainer>
            <TemplateListItemTitle data-lgg-id="whatsapp-template-item-title">
              {template.name}
            </TemplateListItemTitle>
            {unavailable ? (
              // TODO: Add the warning message at the button of the list item, more context is needed to finish that.
              // Ex: How many cases should we handle? how many tags should we display?
              <UnavailableTemplateTag data-lgg-id="whatsapp-template-unavailable-tag">
                {t('common:notAvailable')}
              </UnavailableTemplateTag>
            ) : null}
          </WhatsappTemplateTitleContainer>
          <LanguageContainer>
            <TemplateLanguageTitle>{`${t('common:language')}:`}</TemplateLanguageTitle>
            <TemplateLanguageValue data-lgg-id="whatsapp-template-item-language">
              {getLanguageNameFromAbbreviation(template.language)}
            </TemplateLanguageValue>
          </LanguageContainer>
        </WhatsappTemplateListItemHeader>
        <TemplateListItemPreview data-lgg-id="whatsapp-template-item-preview">
          {templatePreview}
        </TemplateListItemPreview>
        {showUnsupportedError && (
          <TemplateStatusMessage
            data-lgg-id="template-error-unsupported-image-or-button-message"
            message={t(
              'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.templateErrorMessages.unsupportedImagesOrInteractiveButtons',
            )}
          />
        )}
      </WhatsappTemplateListItemInnerContainer>
    </WhatsappTemplateListItemContainer>
  );
};

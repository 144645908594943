import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import styled, { css } from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { useContactAddNewOptions } from 'src/components/domain/contacts/contact-add-new/contact-add-new';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import { LinkButton } from 'src/components/general/button/lgg-button';
import { AddNewContextMenu } from 'src/components/general/display/add-new-context-menu';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { useOpenCallContactModal } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useUrls } from 'src/hooks/use-urls';

const sharedStyling = css`
  height: 30px;
  background: transparent;

  &,
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
`;

export const LinkIconButton = styled(LinkButton)<{
  iconSize?: number;
  iconColor?: ColorPaletteItem;
}>`
  ${sharedStyling}
  svg {
    width: ${({ iconSize }) => (iconSize ? iconSize : 18)}px;
    height: ${({ iconSize }) => (iconSize ? iconSize : 18)}px;
    path {
      fill: ${({ iconColor, theme }) =>
        iconColor ? theme?.colors[iconColor] : theme?.colors.monk};
    }
  }

  &,
  &:not([disabled]):hover {
    svg path {
      fill: ${({ iconColor, theme }) =>
        iconColor ? theme?.colors[iconColor] : theme?.colors.monk};
    }
  }
`;

const BottomOptionIconButton = styled(IconButtonV2)<{
  iconColor?: ColorPaletteItem;
  iconSize?: number;
}>`
  ${sharedStyling}
  svg {
    width: ${({ iconSize }) => (iconSize ? iconSize : 18)}px;
    height: ${({ iconSize }) => (iconSize ? iconSize : 18)}px;
    path {
      fill: ${({ iconColor, theme }) =>
        iconColor ? theme?.colors[iconColor] : theme?.colors.monk};
    }
  }

  &,
  &:not([disabled]):hover {
    svg path {
      fill: ${({ iconColor, theme }) =>
        iconColor ? theme?.colors[iconColor] : theme?.colors.monk};
    }
  }
`;

type ContactActionIconsProps = {
  contactId: number;
  iconSize?: number;
  contactPhone?: string;
  iconColorOverride?: ColorPaletteItem;
};

export const ContactActionIcons = memo<ContactActionIconsProps>(
  ({ contactId, iconSize, contactPhone, iconColorOverride }) => {
    const { getContactModalUrl } = useUrls();
    const { getFeatureFlag } = useAuthorization();
    const hasCrmFeatureFlag = getFeatureFlag('crm');
    const { t } = useTranslation(['contacts']);
    const openCallContactModal = useOpenCallContactModal();
    const contactAddNewOptions = useContactAddNewOptions({ contactId });

    const callContactOption = useMemo(
      () => (
        // This <span/> is needed to show the tooltip when the button is disabled
        <span>
          <BottomOptionIconButton
            data-lgg-id="contact-preview-call-contact"
            iconSize={iconSize}
            iconColor={iconColorOverride}
            icon="call"
            size="small"
            variant="defaultWhite"
            disabled={!contactPhone}
            onClick={() => openCallContactModal(contactId)}
          />
        </span>
      ),
      [contactId, contactPhone, iconColorOverride, iconSize, openCallContactModal],
    );

    return (
      <>
        <Tooltip
          title={
            contactPhone
              ? t('contacts:contactActionsTooltips.callContact')
              : t('contacts:contactActionsTooltips.noNumberToCall')
          }
          align={{ offset: [0, 5] }}
        >
          {callContactOption}
        </Tooltip>
        <LinkIconButton
          data-lgg-id="contact-preview-show-conversation"
          variant="defaultWhite"
          iconColor={iconColorOverride}
          to={getContactModalUrl(contactId, 'conversations')}
        >
          <Icon type="conversations" />
        </LinkIconButton>
        {hasCrmFeatureFlag && (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <AddNewContextMenu
                groups={[
                  (contactAddNewOptions ?? [])?.map((option) => {
                    const testId = option['data-lgg-id'] ?? '';

                    return {
                      ...option,
                      testId,
                      key: testId,
                    };
                  }),
                ]}
              />
            }
          >
            <span>
              <BottomOptionIconButton
                data-lgg-id="contact-preview-contact-actions"
                icon="contactActions"
                size="small"
                variant="defaultWhite"
                iconColor={iconColorOverride ?? 'cosmo'}
              />
            </span>
          </Dropdown>
        )}
      </>
    );
  },
);

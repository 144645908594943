import { useEffect } from 'react';
import { unsubscribe } from 'pubsub-js';
import {
  EventCallback,
  EventDataMapping,
  subscribeToEvent,
} from 'src/utils/events/pub-sub';

export const useSubscribeToEvent = <T extends keyof EventDataMapping>(options: {
  topic?: T;
  callback?: EventCallback<T>;
  skip?: boolean;
}) => {
  const { topic, callback, skip = false } = options;
  const pubSubTopic = topic as string;
  const pubSubCallback = callback as (topic: string, data: EventDataMapping[T]) => {};

  useEffect(() => {
    if (skip) {
      return;
    }

    const subscriptionToken = subscribeToEvent(pubSubTopic, pubSubCallback);

    return () => {
      unsubscribe(subscriptionToken);
    };
  }, [pubSubTopic, pubSubCallback, skip]);
};

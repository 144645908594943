import { useEffect } from 'react';
import { useServiceSelector } from 'react-service-locator';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { SessionService } from 'src/services/session.service';

export const useOnesignalSetUserId = () => {
  const userId = useServiceSelector(SessionService, ({ data: { user } }) => {
    return user.id;
  });

  useEffect(() => {
    if (!Capacitor.isNativePlatform() || !userId) {
      return;
    }

    OneSignal.setExternalUserId(userId.toString(), (results) => {
      console.debug('OneSignal: setExternalUserId', results);
    });

    return () => {
      OneSignal.removeExternalUserId((results) => {
        console.debug('OneSignal: removeExternalUserId', results);
      });
    };
  }, [userId]);
};

import { orderBy } from 'lodash';
import { ContactTimelineUnion } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortContactTimeline = (contactTimelines: ContactTimelineUnion[]) => {
  return orderBy(
    contactTimelines,
    [
      (contactTimeline) => {
        return new Date(contactTimeline.occurredAt).getTime();
      },
      (contactTimeline) => {
        return contactTimeline.id;
      },
    ],
    ['desc', 'asc'],
  );
};

import { useCallback } from 'react';
import {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
} from '@apollo/client/core';
import { ObservableQueryFields } from '@apollo/client/react/types/types';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

type FetchMoreFunction<TData, TVariables> = ObservableQueryFields<
  TData,
  TVariables
>['fetchMore'];

type Options<TVariables, TData> = FetchMoreQueryOptions<TVariables, TData> &
  FetchMoreOptions<TData, TVariables>;

type UseFetchMoreWithErrorHandlingResult<TVariables, TData> = (
  options: Options<TVariables, TData>,
) => Promise<ApolloQueryResult<TData> | void>;

export const useFetchMoreWithErrorHandling = <TData, TVariables>(
  fetchMore: FetchMoreFunction<TData, TVariables>,
): UseFetchMoreWithErrorHandlingResult<TVariables, TData> => {
  const handleGraphQLError = useHandleGraphQLError();

  return useCallback(
    (options: Options<TVariables, TData>) => fetchMore(options).catch(handleGraphQLError),
    [fetchMore, handleGraphQLError],
  );
};

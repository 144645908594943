import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import c from 'classnames';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import Lottie from 'lottie-react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { TextTransformationFormatType } from '@lgg/isomorphic/types/__generated__/graphql';
import fireworksAnimation from 'src/assets/animations/lgg_fireworks.json';
import loadingAnimation from 'src/assets/animations/lgg_loader.json';
import wandAnimation from 'src/assets/animations/lgg_wand.json';
import { ModalsZIndex } from 'src/components/constants';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { PopoverV2 } from 'src/components/general/display/popover';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useApplyAiTextTransformation } from 'src/components/pages/conversations/hooks/use-ai-text-transformation';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useVisible } from 'src/hooks/use-visible';

const AiToolsPickerIcon = styled(Icon)`
  &.enabled {
    &.active,
    &:hover {
      svg path {
        stroke: ${({ theme }) => theme.colors.smalt};
      }
    }
  }

  &:not(.enabled) {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LoadingAnimation = styled(Lottie)`
  height: 54px;
  opacity: 0.2;
  position: absolute;
  width: 54px;
`;

const WandAnimation = styled(Lottie)`
  height: 22px;
  position: absolute;
  width: 22px;
`;

const FireworksAnimation = styled(Lottie)`
  height: 115px;
  width: 115px;

  &:first-child {
    left: 10px;
    transform: rotate(30deg);
  }

  &:last-child {
    transform: rotate(-30deg);
  }
`;

const SuccessAnimationsContainer = styled(FlexRow)`
  justify-content: space-between;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const LoadingAnimationsContainer = styled(FlexColumn)`
  align-items: center;
  display: flex;
  height: 442px;
  justify-content: center;
  position: relative;

  ${up('md')} {
    height: 100%;
  }
`;

const GeneratedText = styled(Scrollbar)`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 21px;
  min-height: 115px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    max-height: 197px;
  }
`;

const GeneratedTextResultFooter = styled(FlexRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: space-between;
  padding: 20px 15px;

  ${up('md')} {
    padding: 15px;
  }
`;

const GeneratedTextResultFooterButton = styled(ButtonV2)`
  ${up('md')} {
    height: 34px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const GeneratedTextResultBody = styled.div`
  height: 100%;
  position: relative;
`;

const GeneratedTextResultContainer = styled(FlexColumn)`
  height: 371px;

  ${up('md')} {
    height: 100%;
  }
`;

const AiHeaderIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const TextTransformOperationContainer = styled.div`
  height: 100%;
`;

const PopoverContentWrapper = styled(FlexColumn)`
  width: 320px;
  height: 310px;
`;

const GeneratedTextResultHeaderContainer = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: space-between;
  padding: 17px 15px 12px;
`;

const HeaderText = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
`;

const PopoverCloseIcon = styled(Icon)`
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const DesktopPopoversContainer = styled.div`
  position: relative;
`;

type GeneratedTextResultProps = {
  generatedText: string;
  onReplace: VoidFunction;
  onCancel: VoidFunction;
};

const GeneratedTextResult = ({
  generatedText,
  onCancel,
  onReplace,
}: GeneratedTextResultProps) => {
  const { t } = useTranslation(['conversations']);

  return (
    <GeneratedTextResultContainer data-lgg-id="generated-text-container">
      <GeneratedText data-lgg-id="ai-generated-text-preview">
        {generatedText}
      </GeneratedText>
      <GeneratedTextResultFooter>
        <GeneratedTextResultFooterButton
          size="regular"
          variant="default"
          onClick={onCancel}
        >
          {t('conversations:messageInput.options.aiTools.operationResultOptions.cancel')}
        </GeneratedTextResultFooterButton>
        <GeneratedTextResultFooterButton
          size="regular"
          variant="primary"
          onClick={onReplace}
          data-lgg-id="replace-generated-text-button"
        >
          {t(
            'conversations:messageInput.options.aiTools.operationResultOptions.replaceText',
          )}
        </GeneratedTextResultFooterButton>
      </GeneratedTextResultFooter>
    </GeneratedTextResultContainer>
  );
};

export const AiToolsPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation(['conversations']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const [applyTextTransform, { loading: performingTextTransform }] =
    useApplyAiTextTransformation();
  const isAiToolPickerVisibilityHandler = useVisible();
  const operationResultVisibilityHandler = useVisible();
  const { id: currentInstitutionId } = useCurrentInstitution();
  const handleGraphQlError = useHandleGraphQLError();
  const [generatedText, setGeneratedText] = useState<string>('');
  const [selectedOperationType, setSelectedOperationType] =
    useState<TextTransformationFormatType | null>(null);
  const [currentMessage, setCurrentMessage] = useState<string>('');

  useEffect(() => {
    const removeTextContentListener = editor.registerTextContentListener(
      (textContent) => {
        setCurrentMessage(() => textContent);
      },
    );

    return removeTextContentListener;
  }, [editor]);

  const isOptionEnabled = useMemo(() => currentMessage.length > 0, [currentMessage]);

  const updateInputText = useCallback(
    (newMessage: string) => {
      editor.update(() => {
        const newTextContentNode = $createTextNode(newMessage);

        const parentNode = $getRoot().getFirstChild()?.replace($createParagraphNode());

        parentNode?.append(newTextContentNode);
        parentNode?.selectEnd();
      });
    },
    [editor],
  );

  const getOperationTitle = useCallback(
    (operation: TextTransformationFormatType) => {
      return match(operation)
        .with('SPELL_CORRECTION', () =>
          t('conversations:messageInput.options.aiTools.fixSpellingAndGrammar'),
        )
        .with('TEXT_TONE_PROFESSIONAL', () =>
          t('conversations:messageInput.options.aiTools.textToneProfessional'),
        )
        .exhaustive();
    },
    [t],
  );

  const performTransformOperation = useCallback(
    async (operation: TextTransformationFormatType) => {
      operationResultVisibilityHandler.show();
      setSelectedOperationType(operation);

      await applyTextTransform({
        variables: {
          formatType: operation,
          institutionId: currentInstitutionId,
          message: currentMessage,
        },
        onCompleted: ({ applyTextTransformation }) => {
          setGeneratedText(applyTextTransformation.transformedMessage);
          isAiToolPickerVisibilityHandler.close();
        },
        onError: (error) => {
          operationResultVisibilityHandler.close();
          handleGraphQlError(error);
        },
      });
    },
    [
      applyTextTransform,
      currentInstitutionId,
      currentMessage,
      handleGraphQlError,
      isAiToolPickerVisibilityHandler,
      operationResultVisibilityHandler,
    ],
  );

  const aiToolsOptions: DrawerOption[] = useMemo(
    () => [
      {
        label: getOperationTitle('TEXT_TONE_PROFESSIONAL'),
        'data-lgg-id': 'ai-text-tone-professional-option',
        onClick: async () => {
          await performTransformOperation('TEXT_TONE_PROFESSIONAL');
        },
      },
      {
        label: getOperationTitle('SPELL_CORRECTION'),
        'data-lgg-id': 'ai-spell-correction-option',
        onClick: async () => {
          await performTransformOperation('SPELL_CORRECTION');
        },
      },
    ],
    [getOperationTitle, performTransformOperation],
  );

  const triggerIcon = useMemo(() => {
    return (
      <AiToolsPickerIcon
        type="aiWand"
        lggTestId="input-area-ai-tool-picker-trigger"
        className={c({
          enabled: isOptionEnabled,
          active: isAiToolPickerVisibilityHandler.visible,
        })}
        onClick={() => {
          if (isOptionEnabled) {
            isAiToolPickerVisibilityHandler.setVisible(
              !isAiToolPickerVisibilityHandler.visible,
            );
          }
        }}
      />
    );
  }, [isAiToolPickerVisibilityHandler, isOptionEnabled]);

  const loadingContent = useMemo(
    () => (
      <LoadingAnimationsContainer>
        <LoadingAnimation animationData={loadingAnimation} loop />
        <WandAnimation animationData={wandAnimation} loop />
      </LoadingAnimationsContainer>
    ),
    [],
  );

  const successAnimation = useMemo(
    () => (
      <SuccessAnimationsContainer>
        <FireworksAnimation animationData={fireworksAnimation} loop={false} />
        <FireworksAnimation animationData={fireworksAnimation} loop={false} />
      </SuccessAnimationsContainer>
    ),
    [],
  );

  const operationResultContent = useMemo(() => {
    if (!selectedOperationType) {
      return null;
    }

    return (
      <>
        <TextTransformOperationContainer>
          {performingTextTransform ? (
            loadingContent
          ) : (
            <>
              <GeneratedTextResultBody>
                {successAnimation}
                <GeneratedTextResult
                  generatedText={generatedText}
                  onCancel={operationResultVisibilityHandler.close}
                  onReplace={() => {
                    updateInputText(generatedText);
                    operationResultVisibilityHandler.close();
                    isAiToolPickerVisibilityHandler.close();
                  }}
                />
              </GeneratedTextResultBody>
            </>
          )}
        </TextTransformOperationContainer>
      </>
    );
  }, [
    selectedOperationType,
    generatedText,
    performingTextTransform,
    operationResultVisibilityHandler,
    loadingContent,
    successAnimation,
    updateInputText,
    isAiToolPickerVisibilityHandler,
  ]);

  return (
    <>
      {breakpointUpMd ? (
        <DesktopPopoversContainer>
          <LggOptionsDropdownButtonWithCustomTrigger
            options={aiToolsOptions}
            customTrigger={triggerIcon}
            loading={!isOptionEnabled}
            visibilityHandler={isAiToolPickerVisibilityHandler}
            overlayTestId="ai-options-container"
          />
          <PopoverV2
            content={
              <PopoverContentWrapper>
                {!performingTextTransform && selectedOperationType ? (
                  <GeneratedTextResultHeaderContainer>
                    <FlexRow>
                      <AiHeaderIcon type="iconAi" />
                      <HeaderText>{getOperationTitle(selectedOperationType)}</HeaderText>
                    </FlexRow>
                    <PopoverCloseIcon
                      type="close"
                      onClick={operationResultVisibilityHandler.close}
                    />
                  </GeneratedTextResultHeaderContainer>
                ) : null}
                {operationResultContent}
              </PopoverContentWrapper>
            }
            hideArrow
            destroyTooltipOnHide
            placement="topLeft"
            align={{ offset: [-15, 0] }}
            trigger={['click']}
            visible={operationResultVisibilityHandler.visible}
            zIndex={ModalsZIndex}
            onVisibleChange={(visible) => {
              operationResultVisibilityHandler.setVisible(visible);
            }}
          >
            <span></span>
          </PopoverV2>
        </DesktopPopoversContainer>
      ) : (
        <>
          {triggerIcon}
          <OptionsBottomDrawer
            visible={isAiToolPickerVisibilityHandler.visible}
            title={t('conversations:messageInput.options.aiTools.title')}
            onClose={isAiToolPickerVisibilityHandler.close}
            options={aiToolsOptions}
          />
          <BottomDrawer
            visible={operationResultVisibilityHandler.visible}
            onClose={operationResultVisibilityHandler.close}
            destroyOnClose
            title={selectedOperationType ? getOperationTitle(selectedOperationType) : ''}
            showHeader={!performingTextTransform}
            leading={<AiHeaderIcon type="iconAi" />}
            height="442px"
          >
            {operationResultContent}
          </BottomDrawer>
        </>
      )}
    </>
  );
};

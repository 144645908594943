import React, {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Radio, RadioChangeEvent, Space, Tag } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Query, QueryContactArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { NewCustomerNotificationModal } from 'src/components/pages/contacts/components/new-customer-notification-modal';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { GET_CONTACT } from 'src/hooks/gql/use-refresh-contact';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';
import { LegacyApiService } from 'src/services/http/legacy-api.service';

const GET_CONTACT_WITH_STAGES = gql`
  query GetContactWithStages($id: Int!) {
    contactStages {
      id
      slug
      name
      statuses {
        id
        name
        description
        winPercentage
      }
    }
    contact(id: $id) {
      id
      label
      stage {
        id
        slug
      }
      status {
        id
      }
    }
  }
`;

const StageRadioGroup = styled(Radio.Group)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  display: flex;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    padding: 20px;
  }

  .ant-radio-button-wrapper {
    align-items: center;
    color: ${({ theme }) => theme.colors.flint};
    display: flex;
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 14px;
    font-weight: 500;
    height: 55px;
    justify-content: center;
    letter-spacing: -0.28px;
    line-height: 17px;
    margin: 0 !important;
    padding: 0 15px;
    width: 100%;

    &:not(:first-child) .ant-radio-button::before {
      display: none;
    }

    &:not(.ant-radio-button-wrapper-checked) + .ant-radio-button-wrapper-checked {
      margin-left: -1px;
    }

    &.ant-radio-button-wrapper-checked {
      border: none;
      box-shadow: unset;
      overflow: hidden;

      &:not(.ant-radio-button-wrapper-disabled):hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    :not(.ant-radio-button-wrapper-disabled):hover &:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .ant-radio-button-wrapper:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  .ant-radio-button-wrapper:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const StatusRadioButton = styled(Radio.Button)<{ accentColor: ColorPaletteItem }>`
  margin: 0 !important;

  &.ant-radio-button-wrapper {
    border-color: ${({ theme }) => theme.colors.koala};
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 14px;
    height: 50px;
    letter-spacing: -0.28px;
    line-height: 17px;

    ${up('md')} {
      height: 60px;
    }

    &:not(:first-child)::before {
      background-color: ${({ theme }) => theme.colors.koala};
    }
  }

  &.ant-radio-button-wrapper-checked {
    color: ${({ theme }) => theme.colors.white};

    &:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: unset;
    }

    .ant-radio-button-checked {
      background-color: ${({ theme, accentColor }) => theme.colors[accentColor]};
    }
  }
`;

const StageRadioGroupSpace = styled(Space)`
  width: 100%;
`;

const SelectedStageOptionIcon = styled(Icon)`
  border-radius: 50%;
  display: none;
  padding: 5px;

  svg {
    position: relative;
    top: 0.5px;
    transform: scale(1.3);

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const StageStatusRadioButton = styled(Radio.Button)`
  display: flex;
  justify-content: space-between;
`;

const StageStatusRadioButtonContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;

  .title {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 13px;
    letter-spacing: -0.13px;
    line-height: 15px;
    margin: 0 0 2px;
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 11px;
    line-height: 13px;
    margin: 0;
  }
`;

const StageRadioGroupContainer = styled(Radio.Group)<{ accentColor: ColorPaletteItem }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-radio-button-wrapper {
    background-color: ${({ theme }) => theme.colors.porcelain};
    border: 1px solid transparent;
    color: unset;
    height: 54px;
    margin: 0 !important;
    width: 100%;

    & > * {
      height: 100%;
      width: 100%;
    }

    &:hover {
      color: unset;
    }

    &:last-child {
      border-radius: 6px;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: rgba(152, 169, 188, 0.07);
      border-color: ${({ theme, accentColor }) => theme.colors[accentColor]};
      border-right-color: ${({ theme, accentColor }) => theme.colors[accentColor]};
      box-shadow: unset;
      overflow: hidden;

      ${SelectedStageOptionIcon} {
        background-color: ${({ theme, accentColor }) => theme.colors[accentColor]};
        display: flex;
        height: 18px;
        width: 18px;
      }

      &:not(
          [class*=' ant-radio-button-wrapper-disabled']
        ).ant-radio-button-wrapper:first-child {
        border-right-color: ${({ theme, accentColor }) => theme.colors[accentColor]};
      }
    }
  }
`;

const ModalHeaderContainer = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.porcelain};
  height: 70px;
  justify-content: center;
  padding: 15px 20px 15.8px;

  ${up('md')} {
    height: 80px;
    padding: 17px 20px;
  }
`;

const ModalFooterContainer = styled(FlexRow)`
  justify-content: flex-end;
  padding: 15px 20px 14px 20px;

  ${up('md')} {
    padding: 15px 20px;
  }

  & > * {
    flex: unset;
    font-size: 13px;
    line-height: 15px;
    width: 100%;

    ${up('md')} {
      font-size: 12px;
      height: 34px;
      line-height: 14px;
      width: max-content;
    }

    &:first-child {
      margin-right: 20px;
    }
  }
`;

const ModalHeaderTitle = styled.p`
  margin: 0 0 0.2px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.carbon};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%; // Prevents the text from coliding with the close icon
  overflow: hidden;

  ${up('md')} {
    margin: 0;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.48px;
  }
`;

const ModalHeaderSubTitle = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.flint};

  ${up('md')} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const StageRadioGroupsContainer = styled.div`
  padding: 16px 20px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
`;

const StageRadioGroupsTitle = styled.p`
  margin: 0 0 5px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.flint};
`;

const StageStatusOptionTitleContainer = styled(FlexRow)`
  height: 15px;
`;

const StageOptionTag = styled(Tag)<{ background: ColorPaletteItem }>`
  background: ${({ background, theme }) => theme.colors[background]};
  border-radius: 3px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 7px;
  font-stretch: normal;
  font-style: normal;
  height: 14px;
  letter-spacing: -0.14px;
  line-height: 9px;
  margin-left: 5px;
  padding: 3px;
  text-transform: uppercase;
`;

const getAccentColorBySlug = (slug: string): ColorPaletteItem => {
  switch (slug) {
    case 'LEAD':
      return 'gold';
    case 'OPPORTUNITY':
      return 'topaz';
    case 'CUSTOMER':
      return 'mint';
    default:
      return 'white';
  }
};

type StageRadioGroupProps = {
  children: ReactNode;
  value: string;
  accentColor: ColorPaletteItem;
  onChange: (e: RadioChangeEvent) => void;
};

const StatusRadioGroup = memo<PropsWithChildren<StageRadioGroupProps>>(
  ({ children, value, accentColor, onChange }) => (
    <StageRadioGroupContainer value={value} accentColor={accentColor} onChange={onChange}>
      <StageRadioGroupSpace direction="vertical" size={10}>
        {children}
      </StageRadioGroupSpace>
    </StageRadioGroupContainer>
  ),
);

type StageStatusOptionProps = {
  title: string;
  description: string;
  value: string;
  isWon: boolean;
  isLost: boolean;
};

const StageStatusOption = ({
  title,
  description,
  value,
  isLost,
  isWon,
}: StageStatusOptionProps) => {
  const { t } = useTranslation(['contacts']);

  return (
    <StageStatusRadioButton value={value}>
      <StageStatusRadioButtonContent data-lgg-id={`contact-stage-option-${value}`}>
        <div>
          <StageStatusOptionTitleContainer>
            <p className="title">{title}</p>
            {(isWon || isLost) && (
              <StageOptionTag background={isWon ? 'secondaryMint80' : 'secondaryCoral80'}>
                {isWon
                  ? t('contacts:contactStatusModal.stageStatus.won')
                  : t('contacts:contactStatusModal.stageStatus.lost')}
              </StageOptionTag>
            )}
          </StageStatusOptionTitleContainer>
          <p className="subtitle">{description}</p>
        </div>
        <SelectedStageOptionIcon type="checkSave" />
      </StageStatusRadioButtonContent>
    </StageStatusRadioButton>
  );
};

export const showContactStatusModal = (contactId: number) => {
  window.dispatchEvent(
    new CustomEvent('showContactStatusModal', {
      detail: {
        contactId,
      },
    }),
  );
};

export const EventTriggeredContactStatusModal = () => {
  const [contactId, setContactId] = useState<number | undefined>(undefined);
  const { getFeatureFlag } = useAuthorization();
  const { getLegacyContactStatusModalUrl } = useUrls();

  useEffect(() => {
    const handleShowContactStatusModal = (e) => {
      if (e?.detail.contactId) {
        if (getFeatureFlag('ux-redesign-v2.5')) {
          setContactId(e.detail.contactId);
        } else {
          openRemoteModalWrapper(
            `${getLegacyContactStatusModalUrl(e?.detail.contactId)}?legacy_view=1`,
          );
        }
      }
    };

    window.addEventListener('showContactStatusModal', handleShowContactStatusModal);

    return () =>
      window.removeEventListener('showContactStatusModal', handleShowContactStatusModal);
  }, [contactId, getFeatureFlag, getLegacyContactStatusModalUrl]);

  return contactId ? (
    <ContactStatusModal
      contactId={contactId}
      onClose={() => {
        setContactId(undefined);
      }}
    />
  ) : null;
};

type ContactStatusModalProps = {
  contactId: number;
  onClose: VoidFunction;
};

const ContactStatusModal = memo<ContactStatusModalProps>(({ contactId, onClose }) => {
  const [stage, setStage] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const statusModalVisibilityHandler = useVisible(true);
  const breakpointUpMd = useBreakpoint(up('md'));
  const newCustomerNotificationVisibilityHandler = useVisible();
  const { triggerError } = useErrorHandling();
  const handleGraphQLError = useHandleGraphQLError();
  const { t } = useTranslation(['contacts']);
  const institutionUrl = useInstitutionUrl();
  const currentInstitution = useCurrentInstitution();
  const legacyApiService = useService(LegacyApiService);
  const contactWithStagesQuery = useQuery<
    Pick<Query, 'contactStages'> & Pick<Query, 'contact'>,
    QueryContactArgs
  >(GET_CONTACT_WITH_STAGES, {
    onCompleted: ({ contact }) => {
      setStage(contact.stage.id);
      setStatus(contact.status.id);
    },
    variables: {
      id: contactId,
    },
    onError: handleGraphQLError,
  });

  const closeStatusModalAndDestroy = useCallback(() => {
    statusModalVisibilityHandler.close();
    onClose();
  }, [onClose, statusModalVisibilityHandler]);

  const [refetchContact] = useLazyQuery<Pick<Query, 'contact'>, QueryContactArgs>(
    GET_CONTACT,
    {
      onCompleted: ({ contact }) => {
        if (contact?.stage.slug === 'CUSTOMER') {
          statusModalVisibilityHandler.close();
          newCustomerNotificationVisibilityHandler.show();
        } else {
          closeStatusModalAndDestroy();
        }
      },
      onError: handleGraphQLError,
    },
  );

  if (contactWithStagesQuery.loading) return <>Loading...</>;
  if (!contactWithStagesQuery.data) {
    return null;
  }

  const { contact, contactStages } = contactWithStagesQuery.data;

  const disableUpdateButton =
    !status || (contact.status.id === status && contact.stage.id === stage);

  const selectedStage = contactStages.find((contactStage) => contactStage.id === stage);

  if (!selectedStage) {
    return <div>error</div>;
  }

  const onSubmit = async () => {
    try {
      if (contact) {
        await legacyApiService.post(
          `${institutionUrl}cms/_modals/client/status/?institution_type=${currentInstitution.type}&institution_id=${currentInstitution.id}&lead_id=${contact?.id}`,
          {
            'lead_form_status[stage_id]': stage,
            'lead_form_status[status_id]': status,
          },
          {
            asForm: true,
            headers: {
              'x-legacy-page': 'true',
              'X-Requested-With': 'XMLHttpRequest',
            },
          },
        );

        await refetchContact({
          variables: {
            id: contact?.id,
          },
        });
      }
    } catch (e) {
      triggerError(e);
    }
  };

  const handleStageChanged = (e: RadioChangeEvent) => {
    setStage(e.target.value);
    setStatus('');
  };

  const handleStatusChanged = (e: RadioChangeEvent) => setStatus(e.target.value);

  const modalContent = (
    <>
      <StageRadioGroup value={stage} size="large" onChange={handleStageChanged}>
        {contactStages.map((contactStage) => (
          <StatusRadioButton
            value={contactStage.id}
            accentColor={getAccentColorBySlug(contactStage.slug)}
            key={contactStage.slug}
          >
            <span data-lgg-id={`contact-status-option-${contactStage.id}`}>
              {contactStage.name}
            </span>
          </StatusRadioButton>
        ))}
      </StageRadioGroup>
      <StageRadioGroupsContainer>
        <StageRadioGroupsTitle>
          {`${t('contacts:contactStatusModal.selectStatus')}:`}
        </StageRadioGroupsTitle>
        {selectedStage ? (
          <StatusRadioGroup
            onChange={handleStatusChanged}
            value={status}
            accentColor={getAccentColorBySlug(selectedStage.slug)}
            key={contact.stage.slug}
          >
            {selectedStage.statuses.map(({ id, name, description, winPercentage }) => (
              <StageStatusOption
                value={id}
                title={name}
                description={description}
                isLost={winPercentage === 0}
                isWon={winPercentage === 100}
                key={`${selectedStage.slug}-${id}`}
                data-lgg-id={`contact-stage-option-${id}`}
              />
            ))}
          </StatusRadioGroup>
        ) : null}
      </StageRadioGroupsContainer>
      <ModalFooterContainer>
        <ButtonV2 variant="default" onClick={closeStatusModalAndDestroy} size="regular">
          {t('contacts:contactStatusModal.cancelOption')}
        </ButtonV2>
        <ButtonV2
          variant="primary"
          data-lgg-id="contact-status-modal-update-status-button"
          disabled={disableUpdateButton}
          onClick={onSubmit}
          size="regular"
        >
          {breakpointUpMd
            ? t('contacts:contactStatusModal.updateOption.desktop')
            : t('contacts:contactStatusModal.updateOption.mobile')}
        </ButtonV2>
      </ModalFooterContainer>
    </>
  );

  const newCustomerModal = (
    <NewCustomerNotificationModal
      visible={newCustomerNotificationVisibilityHandler.visible}
      afterClose={closeStatusModalAndDestroy}
      onClose={newCustomerNotificationVisibilityHandler.close}
    />
  );

  let modal: React.ReactNode;

  if (breakpointUpMd) {
    modal = (
      <LggModal
        data-lgg-id="contact-stage-status-modal"
        visible={statusModalVisibilityHandler.visible}
        onClose={closeStatusModalAndDestroy}
        maskTransitionName=""
      >
        <ModalHeaderContainer>
          <ModalHeaderTitle>{contact?.label}</ModalHeaderTitle>
          <ModalHeaderSubTitle>
            {t('contacts:contactStatusModal.contactStatus')}
          </ModalHeaderSubTitle>
        </ModalHeaderContainer>
        {modalContent}
      </LggModal>
    );
  } else {
    modal = (
      <BottomDrawer
        visible={statusModalVisibilityHandler.visible}
        title={contact.label}
        subTitle={t('contacts:contactStatusModal.contactStatus')}
        onClose={closeStatusModalAndDestroy}
      >
        {modalContent}
      </BottomDrawer>
    );
  }

  return (
    <>
      {modal}
      {newCustomerModal}
    </>
  );
});

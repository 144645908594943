import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Icon } from 'src/components/general/icon';

const StyledCircularAlignedIcon = styled.div<{
  $backgroundColor: ColorPaletteItem;
  $iconColor?: ColorPaletteItem;
}>`
  height: 18px;
  position: relative;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: '';
    background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor]};
    border-radius: 50%;
    height: 24px;
    position: absolute;
    width: 24px;
    display: block;
    z-index: 1;
    right: 5px;
    bottom: 0px;

    ${up('md')} {
      height: 22px;
      width: 22px;
    }
  }

  .lgg-icon {
    z-index: 2;
  }

  svg {
    height: 18px;
    width: 18px;

    ${({ $iconColor }) =>
      $iconColor &&
      css`
        path {
          fill: ${({ theme }) => theme.colors[$iconColor]};
        }
      `}
  }
`;

export type CircularAlignedIconProps = {
  icon: string;
  backgroundColor: ColorPaletteItem;
  iconColor?: ColorPaletteItem;
};

export const CircularAlignedIcon = memo<CircularAlignedIconProps>(
  ({ icon, backgroundColor, iconColor, ...rest }) => {
    return (
      <StyledCircularAlignedIcon
        {...rest}
        $iconColor={iconColor}
        $backgroundColor={backgroundColor}
      >
        <Icon type={icon} />
      </StyledCircularAlignedIcon>
    );
  },
);

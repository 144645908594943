import { match } from 'ts-pattern';
import { getDatesDifferenceInUnits } from '@lgg/isomorphic/i18n/date-utils';

export const getTaskReminderTransKey = (dueAt: Date, reminderAt?: Date) => {
  const differenceInMinutes = reminderAt
    ? getDatesDifferenceInUnits(reminderAt, dueAt).minutes
    : undefined;

  return match(differenceInMinutes)
    .with(undefined, () => 'tasks:emailReminder.noReminder' as const)
    .with(0, () => 'tasks:emailReminder.atTheTimeOfTheEvent' as const)
    .with(5, () => 'tasks:emailReminder.5minsBefore' as const)
    .with(15, () => 'tasks:emailReminder.15minsBefore' as const)
    .with(30, () => 'tasks:emailReminder.30minsBefore' as const)
    .with(60, () => 'tasks:emailReminder.1hourBefore' as const)
    .with(120, () => 'tasks:emailReminder.2hoursBefore' as const)
    .with(1440, () => 'tasks:emailReminder.1dayBefore' as const)
    .with(2880, () => 'tasks:emailReminder.2daysBefore' as const)
    .with(10080, () => 'tasks:emailReminder.1weekBefore' as const)
    .otherwise(() => undefined);
};

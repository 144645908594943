import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { gql } from '@apollo/client';
import {
  Query,
  QuerySchedulesArgs,
  Schedule,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { sortAppointments } from 'src/components/domain/appointments/helpers/sort-appointments';
import { AppointmentItem } from 'src/components/domain/contacts/contact-modal/tabs/appointments/contact-modal-appointment-item';
import {
  ContactModalTabProps,
  ContactModalLoadingView,
  ContactModalTabEmptyView,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useDeleteAppointment } from 'src/hooks/gql/use-delete-appointment';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';

export const GET_CONTACT_APPOINTMENT_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  # noinspection GraphQLUnresolvedReference,GraphQLSchemaValidation
  query GetAppointments(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $contactId: Float!
  ) {
    schedules(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: { contact: { id: { _eq: $contactId } } }
      orderBy: { createdAt: DESC }
    ) @connection(key: "infinite-scroll") {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          title
          status
          description
          startAt
          createdAt
          attendees {
            ... on ScheduleAttendee {
              id
            }
            ... on ScheduleContactAttendee {
              contact {
                label
              }
            }
            ... on ScheduleUserAttendee {
              user {
                fullName
              }
            }
            ... on ScheduleEmailAttendee {
              email
            }
          }
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 50;

type ContactModalAppointmentsTabProps = ContactModalTabProps;

export const ContactModalAppointmentsTab = memo<ContactModalAppointmentsTabProps>(
  ({ contactId, virtuosoRef }) => {
    const handleGraphQLError = useHandleGraphQLError();
    const { id: companyId } = useCurrentInstitution();
    const { t } = useTranslation(['contacts']);
    const [deleteAppointment] = useDeleteAppointment();

    const {
      handleLoadBottom,
      firstItemIndex,
      loadingMoreBottom,
      handleLoadTop,
      loading,
      nodes,
    } = useInfiniteListQuery<
      Pick<Query, 'schedules'>,
      Partial<QuerySchedulesArgs> & { contactId: number },
      Schedule
    >({
      queryOptions: {
        query: GET_CONTACT_APPOINTMENT_QUERY,
        getNodeIdCallback: (notification) => notification?.id,
        variables: {
          first: PAGE_SIZE,
          institutionId: companyId,
          contactId,
        },
        getEdgesCallback: (data) => data?.schedules.edges ?? [],
        getPageInfoCallback: (data) => data?.schedules.pageInfo,
        onError: handleGraphQLError,
        queryPageSize: PAGE_SIZE,
        sortNodesCallback: sortAppointments,
      },
      pollingOptions: {
        interval: 30000,
      },
    });

    const onRefreshHandler = useMemo<UseRefreshProps>(() => {
      return {
        onRefresh: async (params) => {
          const { action } = params;

          if (action === 'create') {
            await handleLoadTop({ forceLoadMore: true });
          }
        },
      };
    }, [handleLoadTop]);

    useRefreshAppointment(onRefreshHandler);

    if (loading) {
      return (
        <ContactModalLoadingView data-lgg-id="contact-modal-appointments-tab-loading" />
      );
    }

    if (!nodes.length) {
      return (
        <ContactModalTabEmptyView
          data-lgg-id="contact-modal-appointments-tab-empty"
          title={t('contacts:detailsModal.tabs.schedules.emptyList.title')}
          message={t('contacts:detailsModal.tabs.schedules.emptyList.message')}
          iconConfig={{ type: 'embedded', icon: 'tabSchedules' }}
        />
      );
    }

    return (
      <Virtuoso
        ref={virtuosoRef}
        data-lgg-id="contact-modal-appointments-tab-list"
        data={nodes}
        endReached={() => handleLoadBottom()}
        firstItemIndex={firstItemIndex}
        components={{
          Footer: () => (
            <ListLoadingItem
              data-lgg-id="contact-modal-appointments-tab-loading-more"
              visible={loadingMoreBottom}
            />
          ),
        }}
        itemContent={(index, appointment) => {
          return (
            <AppointmentItem
              appointment={appointment}
              onAppointmentDelete={({ appointmentId }) => {
                void deleteAppointment({
                  variables: {
                    input: {
                      scheduleId: appointmentId,
                      institutionId: companyId,
                    },
                  },
                  update(cache) {
                    const normalizedId = cache.identify({
                      id: appointmentId,
                      __typename: 'Schedule',
                    });
                    cache.evict({ id: normalizedId });
                    cache.gc();
                  },
                });
              }}
            />
          );
        }}
      />
    );
  },
);

import { useCallback, useMemo, useRef, useState } from 'react';
import { useServiceSelector } from 'react-service-locator';
import { FetchPolicy, useQuery } from '@apollo/client';
import { TypedDocumentNode } from '@apollo/client/core';
import { ApolloError } from '@apollo/client/errors';
import { first, last } from 'lodash';
import { PageInfo } from '@lgg/isomorphic/types/__generated__/graphql';
import { useFetchMoreWithErrorHandling } from 'src/hooks/use-fetch-more-with-error-handling';
import { useInterval } from 'src/hooks/use-interval';
import { useSubscribeToEvent } from 'src/hooks/use-subscribe-to-event';
import { ConnectionService } from 'src/services/connection.service';
import {
  ConversationUpdatedData,
  EventDataMapping,
  ShowUnblockContactModal,
} from 'src/utils/events/pub-sub';

type EventTopic = keyof EventDataMapping;

type UseInfiniteListQuery<TData, TVariables, TRecord, TEventTopic extends EventTopic> = {
  queryOptions: {
    variables: TVariables;
    getNodeIdCallback: (record: TRecord | undefined) => string | number | undefined;
    loadMoreVariables?: TVariables;
    query: TypedDocumentNode<TData, TVariables>;
    getEdgesCallback: (data: TData | undefined) => Edge<TRecord>[] | undefined;
    getPageInfoCallback: (data: TData | undefined) => PageInfo | undefined;
    sortNodesCallback?: (data: TRecord[]) => TRecord[];
    queryPageSize: number;
    isReversedList?: boolean;
    fetchPolicy?: FetchPolicy;
    notifyOnNetworkStatusChange?: boolean;
    onCompleted?: (data: TData) => void;
    onError: (error: ApolloError) => void;
    skip?: boolean;
  };
  updateHandlerOptions?: {
    computeUpdateHandlerWhere: (
      variables: TVariables,
      eventData: TEventTopic extends 'CONVERSATION_UPDATED'
        ? ConversationUpdatedData
        : ShowUnblockContactModal,
    ) => TVariables;
    updateHandlerTopic: TEventTopic;
  };
  pollingOptions?: {
    interval: number;
    pollingDirection?: 'TOP' | 'BOTTOM';
    onPollingResult?: VoidFunction;
    beforePolling?: (data: TData) => Promise<boolean>;
  };
};

export const useInfiniteListQuery = <
  TData,
  TVariables,
  TRecord,
  TEventTopic extends EventTopic = any,
>(
  props: UseInfiniteListQuery<TData, TVariables, TRecord, TEventTopic>,
) => {
  const {
    queryOptions: {
      variables,
      query,
      notifyOnNetworkStatusChange,
      getPageInfoCallback,
      getEdgesCallback,
      getNodeIdCallback,
      isReversedList,
      sortNodesCallback,
      queryPageSize,
      fetchPolicy,
      onCompleted,
      onError,
      skip = false,
    },
    updateHandlerOptions,
    pollingOptions,
  } = props;
  const isOnline = useServiceSelector(
    ConnectionService,
    (service) => service.state.isOnline,
  );

  const referenceNode = useRef<string | number | null | undefined>(null);
  const [loadingMoreTop, setLoadingMoreTop] = useState(false);
  const [loadingMoreBottom, setLoadingMoreBottom] = useState(false);
  const [hasNewItem, setHasNewItem] = useState<boolean>(false);
  const clearHasNewItem = useCallback(() => setHasNewItem(false), []);
  const { loading, data, refetch, fetchMore, networkStatus, previousData, error } =
    useQuery<TData, TVariables>(query, {
      variables,
      notifyOnNetworkStatusChange: notifyOnNetworkStatusChange ?? false,
      fetchPolicy,
      onError,
      onCompleted,
      skip,
    });

  const edges = useMemo(() => getEdgesCallback(data) ?? [], [data, getEdgesCallback]);
  const nodes = edges.map((c) => c.node) ?? [];
  const sortedNodes = sortNodesCallback ? sortNodesCallback(nodes) : nodes;
  const pageInfo = getPageInfoCallback(data);
  const fetchMoreWithErrorHandling = useFetchMoreWithErrorHandling<TData, TVariables>(
    fetchMore,
  );
  const loadMoreVariables = props.queryOptions.loadMoreVariables ?? variables;

  const handleLoadTop = async (params?: { forceLoadMore?: boolean }) => {
    const firstCursor = first(edges)?.cursor;

    const skipLoadMore =
      !isOnline || !firstCursor || loadingMoreTop || !pageInfo?.hasPreviousPage;

    if ((skipLoadMore && !params?.forceLoadMore) || !isOnline) {
      return;
    }

    setLoadingMoreTop(true);

    await fetchMoreWithErrorHandling({
      variables: {
        ...loadMoreVariables,
        last: queryPageSize,
        before: firstCursor,
        first: null,
        after: null,
      },
    });

    setLoadingMoreTop(false);
  };

  const handleLoadBottom = useCallback(async () => {
    const lastCursor = last(edges)?.cursor;
    const skipLoadMore =
      !isOnline || !lastCursor || loadingMoreBottom || !pageInfo?.hasNextPage;

    if (skipLoadMore) {
      return;
    }

    setLoadingMoreBottom(true);

    await fetchMoreWithErrorHandling({
      variables: {
        ...loadMoreVariables,
        after: lastCursor,
        first: queryPageSize,
        before: null,
        last: null,
      },
    });

    setLoadingMoreBottom(false);
  }, [
    edges,
    fetchMoreWithErrorHandling,
    isOnline,
    loadMoreVariables,
    loadingMoreBottom,
    pageInfo,
    queryPageSize,
  ]);

  const pollingHandler = useCallback(async () => {
    if (!isOnline || !pollingOptions || !data) {
      return;
    }

    const { pollingDirection = 'TOP', onPollingResult, beforePolling } = pollingOptions;

    const topPolling = pollingDirection === 'TOP';

    let referenceNode;

    if (isReversedList && topPolling) {
      referenceNode = last(sortedNodes);
    } else if (isReversedList && !topPolling) {
      referenceNode = first(sortedNodes);
    } else if (topPolling) {
      referenceNode = first(sortedNodes);
    } else {
      referenceNode = last(sortedNodes);
    }

    const nodeId = getNodeIdCallback(referenceNode);
    const node = edges.find((edge) => getNodeIdCallback(edge?.node) === nodeId);
    const cursor = node?.cursor;

    if (!cursor) {
      await fetchMoreWithErrorHandling({ variables: loadMoreVariables });
      return;
    }

    const paginationVariables =
      topPolling && !isReversedList
        ? { before: cursor, last: queryPageSize, first: null, after: null }
        : {
            before: null,
            last: null,
            first: queryPageSize,
            after: cursor,
          };

    if (beforePolling && !(await beforePolling(data))) {
      return;
    }

    const result = await fetchMoreWithErrorHandling({
      variables: {
        ...loadMoreVariables,
        ...paginationVariables,
      },
    });

    // If the polling result is empty (this can happen due to being offline), we skip the polling
    if (!result) {
      return;
    }

    const pollingEdges = getEdgesCallback(result.data) ?? [];

    if (!pollingEdges.length) {
      return;
    }

    setHasNewItem(true);
    if (onPollingResult) {
      onPollingResult();
    }
  }, [
    isOnline,
    pollingOptions,
    data,
    isReversedList,
    getNodeIdCallback,
    edges,
    queryPageSize,
    fetchMoreWithErrorHandling,
    loadMoreVariables,
    getEdgesCallback,
    sortedNodes,
  ]);

  useInterval(pollingHandler, pollingOptions?.interval ?? 0);

  const conversationUpdatedHandler = useCallback(
    async (topic, eventData) => {
      if (!updateHandlerOptions || !data) {
        return;
      }

      const { computeUpdateHandlerWhere } = updateHandlerOptions;
      const nextCursor = getPageInfoCallback(data)?.nextCursor;
      const variablesWithId = computeUpdateHandlerWhere(variables, eventData);

      // If `nextCursor` is not available, we fetch using the initial query
      if (!nextCursor) {
        await fetchMoreWithErrorHandling({
          variables: variablesWithId,
        });
        return;
      }

      // If `nextCursor` is available we use it to verify if the updated
      // conversation is part of the current loaded dataset
      await fetchMoreWithErrorHandling({
        variables: {
          ...variablesWithId,
          before: nextCursor,
        },
      });
    },
    [
      data,
      fetchMoreWithErrorHandling,
      getPageInfoCallback,
      updateHandlerOptions,
      variables,
    ],
  );

  useSubscribeToEvent(
    updateHandlerOptions
      ? {
          topic: updateHandlerOptions.updateHandlerTopic,
          callback: conversationUpdatedHandler,
        }
      : { skip: true },
  );

  const computeFirstItemIndex = useCallback(() => {
    const getReferenceNodeIndexInList = () =>
      sortedNodes.findIndex((node) => getNodeIdCallback(node) === referenceNode.current);

    // Set the reference node once, when the data is available
    if (sortedNodes.length && !referenceNode.current) {
      referenceNode.current = getNodeIdCallback(first(sortedNodes));
    }

    // Try to find reference node index in the list
    let referenceNodeIndexInList = getReferenceNodeIndexInList();

    // If it is not found, it was removed, so we need to refresh the reference node
    // to be the first item on the list and get its index
    if (referenceNodeIndexInList === -1) {
      referenceNode.current = getNodeIdCallback(first(sortedNodes));
      referenceNodeIndexInList = getReferenceNodeIndexInList();
    }

    // The first item index would be calculated based on the position of the reference node
    // and the items that comes before it
    return sortedNodes.slice(0, referenceNodeIndexInList).length;
  }, [getNodeIdCallback, sortedNodes]);

  return {
    loading,
    loadingMoreBottom,
    loadingMoreTop,
    pageInfo,
    data,
    handleLoadBottom: isReversedList ? handleLoadTop : handleLoadBottom,
    handleLoadTop: isReversedList ? handleLoadBottom : handleLoadTop,
    nodes: sortedNodes,
    refetch,
    firstItemIndex: Math.round(Number.MAX_SAFE_INTEGER / 2) - computeFirstItemIndex(),
    networkStatus,
    hasNewItem,
    fetchMore: fetchMoreWithErrorHandling,
    setHasNewItem,
    clearHasNewItem,
    previousData,
    error,
  };
};

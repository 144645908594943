import axios from 'axios';

export type PresignedPost = {
  url: string;
  fields: { key: string; value: string }[];
};

const createFormData = (file: File, presignedPost: PresignedPost): FormData => {
  const formData = new FormData();
  formData.append('Content-Type', file.type);

  presignedPost.fields.forEach(({ key, value }) => {
    formData.append(key, value);
  });

  formData.append('file', file);

  return formData;
};

const handleUploadProgress =
  (onUploadProgress?: (progress: number) => void) => (event: ProgressEvent) => {
    if (onUploadProgress && event.total) {
      const progress = Math.round((100 * event.loaded) / event.total);
      onUploadProgress(progress);
    }
  };

export const uploadFileToS3 = async (
  file: File,
  presignedPost: PresignedPost,
  onUploadProgress?: (progress: number) => void,
): Promise<string | null> => {
  const formData = createFormData(file, presignedPost);

  const { headers } = await axios.post(presignedPost.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: handleUploadProgress(onUploadProgress),
  });

  return headers['location'] ? decodeURIComponent(headers['location']) : null;
};

import React, { memo } from 'react';
import { ContactInteractionDirection } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggAudioPlayer } from 'src/components/general/display/lgg-audio-player';
import {
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
  IsomorphicAttachmentFileItemProps,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import { RepliedMessageRenderer } from 'src/components/pages/conversations/components/contact-interactions/items/shared';

type AudioAttachmentItemProps = IsomorphicAttachmentFileItemProps & {
  direction: ContactInteractionDirection;
  showDeliveryStatus: boolean;
};

export const AudioAttachmentItem = memo<AudioAttachmentItemProps>(
  ({ attachment, direction, attachmentDate, showDeliveryStatus, contactInteraction }) => {
    const { status = null, url } = attachment;

    return (
      <BubbleTypeAttachmentContainer direction={direction}>
        <RepliedMessageRenderer contactInteraction={contactInteraction} />
        <LggAudioPlayer url={url} testId="attachment-audio" allowDownload />
        <AttachmentDateAndStatus
          attachmentDate={attachmentDate}
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
        />
      </BubbleTypeAttachmentContainer>
    );
  },
);

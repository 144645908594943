import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from 'react-service-locator';
import Skeleton from 'antd/lib/skeleton';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LegacyView } from 'src/components/pages/legacy/components/legacy-view/legacy-view';
import { LegacyViewLoading } from 'src/components/pages/legacy/components/legacy-view/legacy-view-content/loading';
import { LegacyData } from 'src/components/pages/legacy/legacy-data.type';
import { usePromiseBuilder } from 'src/hooks/use-promise-builder';
import { BreadcrumbsService } from 'src/services/breadcrumbs.service';
import { GenericHttpError } from 'src/services/http/generic-http.service';
import { LegacyApiService } from 'src/services/http/legacy-api.service';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import { AuthorizationError } from 'src/utils/errors/authorization-error';
import { BadRequestError } from 'src/utils/errors/bad-request-error';
import { NetworkError } from 'src/utils/errors/network-error';
import { NotFoundError } from 'src/utils/errors/not-found-error';
import { ServerError } from 'src/utils/errors/server-error';

export const LegacyPage = memo(() => {
  const location = useLocation();

  //Workaround for Safari <= 14, for some unknown reason some legacy objects are being converted to undefined
  if (
    !window['YAMMON'] ||
    !window['Faye'] ||
    !window['jQuery'] ||
    !window['Vue'] ||
    !window['BootstrapDialog']
  ) {
    window.location.reload();
    return <div>Reload legacy assets...</div>;
  }

  return <LegacyPageWrapper key={`${location.key}`} />;
});

const LegacyPageWrapper = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const legacyApiService = useService(LegacyApiService);
  const breadcrumbsService = useService(BreadcrumbsService, () => []);
  const { triggerError } = useErrorHandling();

  useEffect(() => {
    breadcrumbsService.set([
      {
        breadcrumbName: 'loading', // antd uses this for key
        render: () => <Skeleton title={{ width: 150 }} paragraph={false} active />,
      },
    ]);

    return () => breadcrumbsService.clear();
  }, [breadcrumbsService, location.pathname, location.search]);

  const makeLocationChangedRequest = useCallback(async () => {
    const { data } = await legacyApiService.get<LegacyData>(
      `${location.pathname}${
        location.search ? `${location.search}&` : '?'
      }cacheBuster=${Math.random()}`,
    );

    const ymLegacyError = data.ymLegacyError;

    if (ymLegacyError) {
      const statusCode = parseInt(ymLegacyError.error);
      let error;

      switch (statusCode) {
        case 0:
          error = new NetworkError(ymLegacyError);
          break;
        case 400:
          error = new BadRequestError(ymLegacyError);
          break;
        case 401:
          error = new AuthenticationError(ymLegacyError);
          break;
        case 403:
          error = new AuthorizationError(ymLegacyError);
          break;
        case 404:
          error = new NotFoundError(ymLegacyError);
          break;
        default: {
          if (statusCode >= 500 && statusCode <= 599) {
            error = new ServerError(ymLegacyError);
          }

          error = new GenericHttpError(statusCode, ymLegacyError);
        }
      }

      triggerError(error, { isNavigation: true });
    }

    return data;
  }, [legacyApiService, location.pathname, location.search, triggerError]);

  const [promise, setPromise] = useState<Promise<LegacyData>>(() =>
    makeLocationChangedRequest(),
  );
  const isFirst = useRef<boolean>(true);
  useEffect(() => {
    if (isFirst) {
      isFirst.current = false;
    } else {
      setPromise(makeLocationChangedRequest());
    }
  }, [makeLocationChangedRequest]);

  return usePromiseBuilder({
    promise,
    waiting: () => <LegacyViewLoading />,
    resolved: (result) => {
      const { ymLegacySection, redirect } = result;

      if (redirect) {
        const parsedUrl = new URL(redirect);

        //External redirect
        if (
          parsedUrl.host !== new URL(window.location.href).host &&
          parsedUrl.host !== new URL(import.meta.env.VITE_USER_WEB_APP_BASE_URL).host
        ) {
          window.location.replace(redirect);
          return <div>Redirecting to {redirect}</div>;
        }

        //Internal redirect
        const redirectUri = `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
        history.replace(redirectUri);
        return <div>Redirecting to {redirectUri}</div>;
      }

      if (ymLegacySection) {
        return (
          <LegacyView setLegacyRequestPromise={setPromise} viewData={ymLegacySection} />
        );
      }

      throw new NotFoundError();
    },
  });
});

import dayjs from 'dayjs';

const getDatesDifferenceInUnits = (startDate: Date, endDate: Date = new Date()) => {
  const differenceInSeconds = dayjs(endDate).diff(startDate, 'seconds');
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const days = Math.floor(differenceInMinutes / (60 * 24));
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes;
  const seconds = differenceInSeconds;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const getDateRangeDuration = (startDate: Date, endDate: Date = new Date()) => {
  const differenceInMinutes = getDatesDifferenceInUnits(startDate, endDate).minutes;
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;

  return {
    hours,
    minutes,
  };
};

export { getDateRangeDuration, getDatesDifferenceInUnits };

import { useState, useEffect, useCallback } from 'react';
import i18n from 'src/i18n/init';

export const useI18n = (initialLng?: string) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    initialLng ?? i18n.language,
  );

  useEffect(() => {
    const initializeI18n = async () => {
      await i18n.changeLanguage(currentLanguage);
    };

    void initializeI18n();
  }, [currentLanguage]);

  const changeLanguage = useCallback(
    async (lng: string) => {
      if (lng !== currentLanguage) {
        await i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
      }
    },
    [currentLanguage],
  );

  return {
    currentLanguage,
    changeLanguage,
  };
};

import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import { Query, QueryUserGroupsArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import { useSetFormFieldValue } from 'src/components/filters/helpers';
import {
  EndDateFilter,
  ReactFilters,
  StartDateFilter,
} from 'src/components/filters/react-filters';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import {
  StyledTableFiltersForm,
  TableLayoutFilterProps,
  useTableSubmitHandler,
} from 'src/components/general/table-helpers';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const GET_TASK_USER_GROUPS_QUERY = gql`
  query GetTaskUserGroups($institutionId: Int!) {
    userGroups(institutionId: $institutionId, orderBy: { name: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const schema = zod.object({
  due_at_from: zod.string().nullish(),
  due_at_to: zod.string().nullish(),
  title: zod.string().optional(),
  status: zod.array(zod.string()).nullish(),
  category: zod.string().nullish(),
  agent: zod.number().nullish(),
  group: zod.number().nullish(),
});

export type TasksFiltersFormValues = zod.infer<typeof schema>;

export const defaultTasksFilters: TasksFiltersFormValues = {
  due_at_from: null,
  due_at_to: null,
  title: '',
  status: [],
  category: null,
  agent: null,
  group: null,
};

export const TaskFilters = memo<TableLayoutFilterProps<TasksFiltersFormValues>>(
  ({ visible, filters, onClose }) => {
    const { t } = useTranslation(['common', 'tasks']);
    const { institutionId } = useParams<{ institutionId: string }>();
    const history = useHistory();
    const location = useLocation();
    const submitHandler = useTableSubmitHandler({ onClose });
    const { data: userGroupsData, loading: loadingUserGroups } = useQuery<
      Pick<Query, 'userGroups'>,
      Partial<QueryUserGroupsArgs>
    >(GET_TASK_USER_GROUPS_QUERY, {
      variables: {
        institutionId: Number.parseInt(institutionId),
      },
    });

    const userGroups = getNodesFromConnection(userGroupsData?.userGroups);
    const userGroupsOptions = userGroups.map(entityToSelectOptionMapper);

    const form = useForm<TasksFiltersFormValues>({
      resolver: zodResolver(schema),
      defaultValues: filters,
    });

    const { control, handleSubmit, setValue, reset } = form;

    const { setFormFieldValue } = useSetFormFieldValue<TasksFiltersFormValues>({
      defaultValues: defaultTasksFilters,
      setValue,
    });

    useEffect(() => {
      if (filters) {
        reset(filters);
      }
    }, [filters, reset]);

    const taskStatusOptions: SelectOption<string>[] = [
      {
        label: t('tasks:filters.status.options.yetToStart'),
        value: 'YET-TO-START',
      },
      {
        label: t('tasks:filters.status.options.inProgress'),
        value: 'IN-PROGRESS',
      },
      {
        label: t('tasks:filters.status.options.completed'),
        value: 'COMPLETED',
      },
    ];

    const taskCategoryOptions: SelectOption<string>[] = [
      {
        label: t('tasks:filters.category.options.call'),
        value: 'CALL',
      },
      {
        label: t('tasks:filters.category.options.email'),
        value: 'EMAIL',
      },
      {
        label: t('tasks:filters.category.options.toDo'),
        value: 'TO_DO',
      },
    ];

    return (
      <ReactFilters
        form={form}
        onSave={() => {
          void handleSubmit(submitHandler)();
        }}
        onReset={() => {
          onClose();
          reset(defaultTasksFilters);
          history.push(location.pathname);
        }}
        filters={filters}
        visible={visible}
        onClose={onClose}
        testId="tasks-filters"
      >
        <StyledTableFiltersForm>
          <StartDateFilter
            name="due_at_from"
            data-lgg-id="tasks-filters-field-due-from"
            label={t('tasks:filters.dueFrom.title')}
          />
          <EndDateFilter
            name="due_at_to"
            data-lgg-id="tasks-filters-field-due-to"
            label={t('tasks:filters.dueTo.title')}
          />
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <TextInput
                data-lgg-id="tasks-filters-field-title"
                autoCapitalize="off"
                label={t('tasks:filters.taskTitle.title')}
                reserveErrorArea={false}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="status"
            render={({ field }) => {
              const selectedOptions =
                taskStatusOptions.filter(({ value: optionValue }) => {
                  return field.value?.includes(optionValue);
                }) ?? null;

              return (
                <Select
                  name="tasks-filters-field-status"
                  value={selectedOptions}
                  isMulti
                  options={taskStatusOptions}
                  isSearchable={false}
                  label={t('tasks:filters.status.title')}
                  onChange={(option) =>
                    setFormFieldValue(
                      field.name,
                      option?.map(({ value }) => `${value}`),
                    )
                  }
                />
              );
            }}
          />
          <Controller
            control={control}
            name="category"
            render={({ field }) => {
              const selectedOption =
                taskCategoryOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select<SelectOption<string>>
                  name="tasks-filters-field-category"
                  value={selectedOption}
                  options={taskCategoryOptions}
                  isSearchable={false}
                  label={t('tasks:filters.category.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="agent"
            render={({ field }) => {
              return (
                <CompanyUsersSelect
                  name="tasks-filters-field-agent"
                  placeholder={t('common:selectOne')}
                  label={t('tasks:filters.assignedTo.title')}
                  selectedUsersIds={field.value ? [field.value] : []}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                  isMulti={false}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="group"
            render={({ field }) => {
              const selectedValue = field.value
                ? userGroups.find((campaign) => campaign.id === field.value)
                : undefined;

              const selectedOption = selectedValue
                ? entityToSelectOptionMapper(selectedValue)
                : null;

              return (
                <Select
                  name="tasks-filters-field-account-group"
                  value={selectedOption}
                  options={userGroupsOptions}
                  isLoading={loadingUserGroups}
                  placeholder={t('common:selectOne')}
                  isSearchable
                  label={t('tasks:filters.accountGroup.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
        </StyledTableFiltersForm>
      </ReactFilters>
    );
  },
);

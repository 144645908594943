import React, { memo } from 'react';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionsList } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-list';
import { ContactInteractionListHeader } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-list-header';

type ContactInteractionsSectionProps = {
  conversationId: string;
  onLoadConversation?: ValueChanged<Conversation>;
  onClose?: VoidFunction;
  contactInteractionId?: string | null;
  showContactDetailsHint?: boolean;
};

export const ContactInteractionsSection = memo<ContactInteractionsSectionProps>(
  ({
    conversationId,
    onLoadConversation,
    onClose,
    contactInteractionId,
    showContactDetailsHint,
  }) => {
    return (
      <>
        <ContactInteractionListHeader
          conversationId={conversationId}
          onClose={onClose}
          showContactDetailsHint={showContactDetailsHint}
        />
        <ContactInteractionsList
          key={conversationId + contactInteractionId}
          contactInteractionIdParam={contactInteractionId}
          conversationId={conversationId}
          onLoadConversation={onLoadConversation}
        />
      </>
    );
  },
);

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Fab } from 'src/components/general/button/icon-button';
import { ContextMenuItem } from 'src/components/general/display/context-menu';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { BottomDrawerItemsRenderer } from 'src/components/general/drawer/bottom/bottom-drawer-item-renderer';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { AddButtonProps } from 'src/components/layout/authenticated-layout/header/add-new';
import { useVisible } from 'src/hooks/use-visible';

const Divider = styled.hr`
  margin: 10px 0;
`;

export const AddButtonMobile = memo(({ menuActions, extraActions }: AddButtonProps) => {
  const { t } = useTranslation(['header']);
  const { show, close, visible } = useVisible();

  const mapContextMenuItemToDrawerOption = (item: ContextMenuItem): DrawerOption => {
    return {
      label: item.label,
      onClick: item.onClick,
      'data-lgg-id': item.testId,
      icon: item.icon,
      to: item.to,
    };
  };

  const menuActionsOptions: DrawerOption[] = menuActions.map(
    mapContextMenuItemToDrawerOption,
  );

  const extraActionsOptions: DrawerOption[] = extraActions.map(
    mapContextMenuItemToDrawerOption,
  );

  return (
    <>
      <Fab onClick={show} icon="plus" size="small" />
      <BottomDrawer
        onClose={close}
        mode="options"
        visible={visible}
        title={t('header:addNew.title')}
      >
        <BottomDrawerItemsRenderer
          options={menuActionsOptions}
          onClose={close}
          selectedValue={null}
        />
        {menuActionsOptions.length > 0 ? <Divider /> : null}
        <BottomDrawerItemsRenderer
          options={extraActionsOptions}
          onClose={close}
          selectedValue={null}
        />
      </BottomDrawer>
    </>
  );
});

import { orderBy } from 'lodash';
import { NotificationFeedItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortNotificationFeedItems = (
  notificationFeedItems: NotificationFeedItemUnion[],
) => {
  return orderBy(
    notificationFeedItems,
    [
      (notificationFeedItem) => {
        return new Date(notificationFeedItem.occurredAt).getTime();
      },
      (notificationFeedItem) => {
        return notificationFeedItem.id;
      },
    ],
    ['desc', 'asc'],
  );
};

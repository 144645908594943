import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

const DateTag = styled(Tag)`
  border-radius: 2px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  letter-spacing: -0.22px;
  line-height: 13px;
  margin: -1px 4px -1px 0px;
  padding: 2px 4px 1px;

  ${up('md')} {
    margin: 0;
  }

  &.error {
    background-color: ${({ theme }) => theme.colors.secondaryCoral10};
    border-color: ${({ theme }) => theme.colors.secondaryCoral40};
    color: ${({ theme }) => theme.colors.secondaryCoral};
  }

  &.info {
    background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
    border-color: ${({ theme }) => theme.colors.secondaryTopaz40};
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

const DynamicDateTagContainer = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.storm};
    font-size: 13px;
    line-height: 15px;
  }

  & .separator {
    color: ${({ theme }) => theme.colors.flint};

    ${up('md')} {
      display: none;
    }
  }

  &.overdue {
    color: ${({ theme }) => theme.colors.secondaryCoral};
  }
`;

const TimeContainer = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-size: 12px;
  line-height: 14px;
`;

const DateLabel = styled.span`
  text-transform: capitalize;
`;

const TableLayoutDateContainer = styled.span`
  font-family: ${({ theme }) => theme.font.regular};

  ${up('md')} {
    display: flex;
    flex-direction: column;
  }
`;

type TableLayoutDateProps = {
  isOverdue?: boolean;
  date: string;
  testId?: string;
  isAllDay?: boolean;
};

export const TableLayoutDate = memo<TableLayoutDateProps>(
  ({ isOverdue = false, date, testId, isAllDay }) => {
    const { formatSimpleRelativeDate, formatSimpleTime } = useFormatDate();
    const { t } = useTranslation(['appointments']);
    const { isToday, isYesterday, isTomorrow } = useDateHelpers();
    const formattedDate = formatSimpleRelativeDate(date);
    const currentDate = new Date();

    return (
      <TableLayoutDateContainer data-lgg-id={testId}>
        <DynamicDateTagContainer className={c({ date: true, overdue: isOverdue })}>
          {isToday(date, currentDate) ? (
            <DateTag className={c({ error: isOverdue, info: !isOverdue })}>
              {formattedDate}
            </DateTag>
          ) : (
            <>
              <DateLabel>{formattedDate}</DateLabel>
              <span className="separator">
                {isTomorrow(date, currentDate) || isYesterday(date, currentDate)
                  ? ', '
                  : ' '}
              </span>
            </>
          )}
        </DynamicDateTagContainer>
        <TimeContainer className="time">
          {isAllDay ? t('appointments:allDay') : formatSimpleTime(date)}
        </TimeContainer>
      </TableLayoutDateContainer>
    );
  },
);
